// This file contains common utility functions handling numbers

/**
 * Formats the input with minimum and maximum fraction digits
 *
 * @param {number} input number to format
 * @param {number} min minimum number of fraction digits to use
 * @param {number} max maximum number of fraction digits to use
 * @returns {string} formatted string representation of the input
 */
export function decimalFormat(input: number, min: number, max: number): string {
	const formatter = new Intl.NumberFormat(undefined, { minimumFractionDigits: min, maximumFractionDigits: max })
	return formatter.format(input)
}

/**
 * Parses the given column fraction format and returns minimum and maximum fraction digits
 *
 * @param {string} format Column format representing the number of fraction digits shown
 * @returns {[number, number] | undefined} tuple of minimum and maximum fraction digits or undefined if no fractions were specified in the format
 */
export function parseFractionFormat(format: string): [number, number] | undefined {
	const decimals = format.split('.')[1]
	if (decimals) {
		const min = decimals.indexOf('#')
		const max = decimals.length
		return [min, max]
	}
	return undefined
}

export function formatByCultureId(input: number, forcedCultureId?: string) {
	const cultureId = forcedCultureId ? forcedCultureId : navigator.language
	const formatter = new Intl.NumberFormat(cultureId, { style: 'decimal' })
	return formatter.format(input)
}
