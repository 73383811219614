import './dfxCreateWorkReportButton'
import './dfxResources'
import './dfxSerialNumbers'
import './dfxSettingsManufacturing'
import './dfxSettingsResources'
import './dfxSettingsWorkElements'
import './dfxTaskCard'
import './dfxTasks'
import './dfxTaskStatusChange'
import './dfxTraceIds'
import './dfxWorkElementData'
import './dfxWorkElementMaterials'
import './dfxWorkElementTools'
import './dfxWorkReport'
import './dfxWorks'
import './manSearchTaskButton'
import './manSearchTaskWorkTable'
import './manSelectStock'
import './openCompletedTasksButton'
import './createRepairOperModal'

const manudirModule = angular.module('manudir', ['manudir.dfxCreateWorkReportButton', 'manudir.dfxResources', 'manudir.dfxSerialNumbers', 'manudir.dfxSettingsManufacturing',
'manudir.dfxSettingsResources','manudir.dfxSettingsWorkElements','manudir.dfxTaskCard','manudir.dfxTasks','manudir.dfxTaskStatusChange','manudir.dfxTraceIds','manudir.dfxWorkElementData',
'manudir.dfxWorkElementMaterials','manudir.dfxWorkElementTools','manudir.dfxWorkReport','manudir.dfxWorks','manudir.manSearchTaskButton','manudir.manSearchTaskWorkTable',
	'manudir.manSelectStock', 'manudir.openCompletedTasksButton', 'manudir.createRepairOperModal'])
export default manudirModule