'use strict';
var enumDropModule = angular.module('dir.dfxEnumDropDown',[]);
/**
	 * @class LeanPortalSPA.directives.dfxEnumDropDown
     * @memberOf LeanPortalSPA.directives
	 * @description General enumeration drop down. Can be used alone or in two level hierachy.
     * 
     * @param {object} [childNameModel] Name model of child.
     * @param {object} [childValueModel] Value model of child.
     * @param {string} [columnLabel] Visible column name or title.
     * @param {string} [columnLabelConstId] Language constant id for visible column name or title.
     * @param {string} [displayMode='name'] Is name, id or nabbr displayed? Default is 'name'.
     * @param {object[]} enumList List of enumerations.
     * @param {boolean} [isChild] Set to true if this is a child drop down of another drop down.
     * @param {boolean} [isEditMode=true] Is the control in edit mode or read mode. Default is true.
     * @param {boolean} [isRequired] Set to true for mandatory dropd down.
     * @param {number} [matchIndex]  If enum drop down is a child of another drop down, this is the index of extra select column where parent value is found.
     * @param {object} [nameModel] Enum name is stored into this model.
	 * @param {function} [onValueChanged] Function to occur after value has changed.
     * @param {object} [parentValueModel] If enum drop down is a child of another drop down, this is the parent model value.
     * @param {object} valueModel Enum id is stored into this model.
     * 
     * @example
     *  <dfx-enum-drop-down name-model="ctrl.qualityFailureEntity.rejReasonName" 
     *                      value-model="ctrl.qualityFailureEntity.rejReason"
	 *						enum-list="ctrl.enumerations.rejReason"
	 *						is-required="true" />
     */
enumDropModule.directive('dfxEnumDropDown', function () {
    // Drop down field for enumeration.
    return {
        restrict: 'E', //E = element, A = attribute, C = class, M = comment 
        scope: {},
        controllerAs: 'ctrl',
        bindToController: {
            // See parameter descriptions above.
            childNameModel: '=?', 
            childValueModel: '=?', 
            columnLabel: '=?', 
            columnLabelConstId: '=?', 
            displayMode: '=?', 
            enumList: '=', 
            isChild: '=?', 
            isEditMode: '=?', 
            isRequired: '=?', 
            matchIndex: '=?', 
			nameModel: '=?',
			onValueChanged: '&?',
            parentValueModel: '=?', 
            valueModel: '=' 
        },
        controller: ['$scope', 'common', function ($scope, common) {
            var ctrl = this;
            var filteredList = [];

            ctrl.$onInit = function () {
                ctrl.displayMode = ctrl.displayMode || 'name';
                ctrl.isEditMode = ctrl.isEditMode === undefined ? true : ctrl.isEditMode;
                init();
            };
            

            /**
		     * @function 'WatchCollection for ctrl.enumList'
             * @description Filters enumeration list when enumeration list given as parameter changes.
		     * @memberOf LeanPortalSPA.directives.dfxEnumDropDown
             */
            $scope.$watchCollection('ctrl.enumList', function (value) {
                if (value) {
                    ctrl.filterEnums();
                    init();
                }
            });

            /**
		     * @function 'Watch for ctrl.valueModel'
             * @description Updates name model when enumeration list given as parameter changes.
		     * @memberOf LeanPortalSPA.directives.dfxEnumDropDown
             */
            $scope.$watch('ctrl.valueModel', function (value) {
                if (!value) {
                    ctrl.nameModel = null;
                }
				else {
					if (ctrl.displayMode === 'id') {
						ctrl.nameModel = ctrl.valueModel;
					}
					else {
						var returnNabbr = ctrl.displayMode === 'nabbr' ? true : false;
						ctrl.nameModel = common.getEnumNameById(ctrl.enumList, ctrl.valueModel, returnNabbr);
					}
                }
            });

            /**
             * Filters enumeration list.
             * @memberOf LeanPortalSPA.dfxEnumDropDown
             * @returns {object[]} Filtered enumeration list.
             * @memberOf LeanPortalSPA.directives.dfxEnumDropDown
             */
            ctrl.filterEnums = function () {
                filteredList = ctrl.enumList ? ctrl.enumList.slice() : [];
                if (ctrl.isChild) {
                    filteredList = [];
                    if (ctrl.parentValueModel) {
                        angular.forEach(ctrl.enumList, function (lstItem) {
                            if (lstItem && lstItem.extraSelect && lstItem.extraSelect[ctrl.matchIndex] === ctrl.parentValueModel) {
                                filteredList.push(lstItem);
                            }
                        });
                    }
                }
                return filteredList;
            };

            /**
             * Occurs when selected value is deleted.
             * @memberOf LeanPortalSPA.directives.dfxEnumDropDown
             */
            ctrl.onValueRemove = function () {
                ctrl.valueModel = null;
                ctrl.nameModel = null;
                if (ctrl.childValueModel) {
                    ctrl.childValueModel = null;
                    ctrl.childNameModel = null;
				}
				if (ctrl.onValueChanged) {
					ctrl.onValueChanged({ selectedEnum: null });
				}
            };

            /**
             * Occurs when value is selected.
             * @param {Object} selectedEnum - Selected enumeration object.
             * @param {string} selectedEnum.id - Enumeration id
             * @param {string} selectedEnum.name - Enumeration name
             * @memberOf LeanPortalSPA.directives.dfxEnumDropDown
             */
            ctrl.onValueSelect = function (selectedEnum) {
                if (ctrl.valueModel != selectedEnum.id && ctrl.childValueModel) {
                    ctrl.childValueModel = null;
                    ctrl.childNameModel = null;
                }
                ctrl.valueModel = selectedEnum.id;
				ctrl.nameModel = selectedEnum[ctrl.displayMode];
				if (ctrl.onValueChanged) {
					ctrl.onValueChanged({ selectedEnum: selectedEnum });
				}
            };

            function init() {
                // If value model has a value at the start, get name from the enum list.
				if (!ctrl.nameModel && ctrl.valueModel) {
					if (ctrl.displayMode === 'id') {
						ctrl.nameModel = ctrl.valueModel;
					}
					else {
						var returnNabbr = ctrl.displayMode == 'nabbr' ? true : false;
						ctrl.nameModel = common.getEnumNameById(ctrl.enumList, ctrl.valueModel, returnNabbr);
					}
                }
            }

        }],
        transclude: false,
        template: require('./dfxEnumDropDown.html')
    };
});
export default enumDropModule