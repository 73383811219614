'use strict';
var modalMovableModule = angular.module('dir.modalMovable',[]);
modalMovableModule.directive('modalMovable', ['$document', '$window',
	function ($document, $window) {
		return {
			restrict: 'AC',
			link: function (scope, iElement, iAttrs) {
				var startX = 0,
					startY = 0,
					x = 0,
					y = 0,
					winX = 0,
					winY = 0,
					some = 50;

				var dialogWrapper = iElement.parent();

				dialogWrapper.css({
					position: 'relative'
				});

				dialogWrapper.on('mousedown', function (event) {
					var validDragArea = false;
					var mustHit = $document[0].getElementsByClassName('form-header');
					if (mustHit && mustHit.length) {
						// more than one modal can exist
						angular.forEach(mustHit, function (el) {
							var r = el.getBoundingClientRect();
							if (r.left < event.clientX && r.right > event.clientX && r.top < event.clientY && r.bottom > event.clientY) {
								validDragArea = true;
							}
						});
					}
					else {
						validDragArea = true;
					}

					if (validDragArea) {
						// Prevent default dragging of selected content
						event.preventDefault();
						startX = event.pageX - x;
						startY = event.pageY - y;
						// define limits for moving not to lose the window
						winY = $window.innerHeight - some;
						winX = $window.innerWidth - some;

						$document.on('mousemove', mousemove);
						$document.on('mouseup', mouseup);
					}
				});

				function mousemove(event) {
					// If mouse is taken outside the window, the scrolling stops
					y = Math.min(Math.max(some, event.pageY), winY) - startY;
					x = Math.min(Math.max(some, event.pageX), winX) - startX;
					dialogWrapper.css({
						top: y + 'px',
						left: x + 'px'
					});
				}

				function mouseup() {
					$document.unbind('mousemove', mousemove);
					$document.unbind('mouseup', mouseup);
				}
			}
		};
	}]);
export default modalMovableModule
