import { formatByCultureId } from '../utils/numberUtils'

/**
 * @class LeanPortalSPA.directives.formatNumber
 * @memberOf LeanPortalSPA.directives
 * @description Formatter needed to show different value in ng-model than the actual value. This is needed e.g. in input tags when foratting a number.
 *
 */

var formatNumberModule = angular.module('dir.formatNumber', [])
formatNumberModule.directive('formatNumber', function () {
	return {
		require: 'ngModel',
		link: function (scope, elem, attrs, ngModelCtrl) {
			// formats text going to user (from model to view)
			ngModelCtrl.$formatters.push(function (number) {
				return formatByCultureId(number);
			});
		}
	};
})

export default formatNumberModule