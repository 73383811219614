'use strict';
var serviceId = 'commonDatacontext';
var commonDatacontextModule =angular.module('common').factory(serviceId, ['$rootScope', '$http', 'common', 'config', 'entityManagerFactory', 'spinner', 'usersettings', commonDatacontext]);

    function commonDatacontext($rootScope, $http, common, config, emFactory, spinner, usersettings) {
        var cdc = this;
        var events = config.events;
        var getLogFn = common.logger.getLogFn;
        var log = getLogFn('CommonDataContext');
	    var logSuccess = getLogFn(serviceId, 'success');
        var warnMsg = getLogFn('CommonDataContext', 'warning');
        var logErr = getLogFn('CommonDataContext', 'error');
        var EntityQuery = breeze.EntityQuery;
        var commonManager = emFactory.newManager('breeze/Common');
        var _hasChanges = false;
        var $q = common.$q;
        var formPromises = {};
        var formSettings = {};

        var service = {
        	activateLeanProfiling: activateLeanProfiling,
        	addOrUpdateDocumentInLocalDatabase: addOrUpdateDocumentInLocalDatabase,
        	addOrUpdateObjInLocalDatabase: addOrUpdateObjInLocalDatabase,        	
        	commonManager: commonManager,
        	ChangeStatusAndCreateChecklist: ChangeStatusAndCreateChecklist,
			clearCache: clearCache,
			clearFavourites: clearFavourites,
			confirmCheckListRows: confirmCheckListRows,
			confirmCommentsAsRead: confirmCommentsAsRead,
            deleteDocument: deleteDocument,
			DfxForm: DfxForm,
			ExecuteToolCommand: ExecuteToolCommand,
        	getAccounts: getAccounts,
        	getColumnHeaderText: getColumnHeaderText,
        	getComments: getComments,
        	getCompany: getCompany,
        	getCharts: getCharts,
        	getCheckLists: getCheckLists,
        	getCheckListLog: getCheckListLog,
			getCheckListUsers: getCheckListUsers,
        	getCostCenters: getCostCenters,
            getDefaultLabel: getDefaultLabel,
            getDefaultLabelPrinter: getDefaultLabelPrinter,
        	getDepartments: getDepartments,
        	getDocumentData: getDocumentData,
        	getDocumentFromLocalDatabase: getDocumentFromLocalDatabase,
        	getDocuments: getDocuments,
        	getDocSaveEnums: getDocSaveEnums,
        	getEmployeeList: getEmployeeList,
            getEntityManager: getEntityManager,
        	getEnumFrom: getEnumFrom,
        	getExternalFileData: getExternalFileData,
        	getFeed: getFeed,
			getFormSettings: getFormSettings,
			getFormSettingsList: getFormSettingsList,
        	getGenericDataSet: getGenericDataSet,
			getImages: getImages,
			getLocSaldo: getLocSaldo,
        	getLookups: getLookups,
			getLookupsFromForm: getLookupsFromForm,
			getModelColumnMap: getModelColumnMap,
        	getObjFromLocalDatabase: getObjFromLocalDatabase,
        	getOPUs: getOPUs,
        	getPurchaseOrders: getPurchaseOrders,
			getSubstituteUsers: getSubstituteUsers,
			getSettings: getSettings,
			getSettingsFromConfig: getSettingsFromConfig,
        	getLeanProfilingReport: getLeanProfilingReport,
        	getLeanProfilingStatus: getLeanProfilingStatus,
        	getTexts: getTexts,
			getTextSaveEnums: getTextSaveEnums,
        	getVatCodes: getVatCodes,
        	getWorkReport: getWorkReport,
			hasChanges: hasChanges,
			printLabelToFile: printLabelToFile,
			printLabelToFileMultiple: printLabelToFileMultiple,
			rejectChanges: rejectChanges,
			removeComment: removeComment,
			ReportLauncher: reportLauncher,
            saveChanges: saveChanges,
            saveDocument: saveDocument,
            saveDocumentMultiple: saveDocumentMultiple,
            setFavourite: setFavourite,
            setFavDescription: setFavDescription
		};

        init();

        $rootScope.$on('UNAUTHORIZED', function () {
        	formPromises = {};
        	formSettings = {};
        });

        return service;

		/**
		 * Activates lean form profiling
		 */
        function activateLeanProfiling() {
        	var source = 'ActivateLeanProfiling';
        	var spinnerActivated = spinner.spinnerShow(source);
        	var query;
        	query = EntityQuery
				.from('ActivateLeanProfiling')
				.using(commonManager)
                .noTracking()
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						window.location.reload();
					}
				});

        	function querySucceeded(data) {
        		log(common.$translate.instant("STRCONST.LEANPORTAL.TXT_ACTIVATE_LEAN_PROFILING") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
        		spinner.queryFinally(source, spinnerActivated)
        	}
        	function queryFailed(data) {
        		spinner.queryFinally(source, spinnerActivated)
        		usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.TXT_ACTIVATE_LEAN_PROFILING") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data.status);
        	}
        	return query;
		}

		function getSettings(forceRefresh) {
			var lst = [];
			var cacheKey = usersettings.getLocStoKey('GetAppSettings')
			if (!forceRefresh) {
				var cachePromise = common.getCachedData(cacheKey);
				if (cachePromise) {
					return cachePromise;
				}
			}

			lst = getSettingsFromConfig(cacheKey);
			return $q.when(lst);
		}

		function getSettingsFromConfig(cacheKey) {
			var source = 'GetAppSettings';
			var spinnerActivated = spinner.spinnerShow(source);
			var settingsObject;
			settingsObject = EntityQuery
				.from('GetAppSettings')
				.withParameters({})
				.using(commonManager)
				//.expand('rows')
				.noTracking()
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data && cacheKey) {
						common.setCachedData(cacheKey, data.results[0]);
					}
					return data.results[0];
				}, function () {
					common.rejectCachedData(cacheKey);
					return null;
				});
			function querySucceeded(data) {
				log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_CFGMODELS_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
				spinner.queryFinally(source, spinnerActivated)
			}
			function queryFailed(data) {
				spinner.queryFinally(source, spinnerActivated)
				usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_CFGMODELS_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
			}
			return settingsObject;
		}

    	/**
		 * Clears the HttpContext cache, same as CacheReset.txt.
		 */
        function clearCache() {
        	var source = 'ClearCache';
        	var spinnerActivated = spinner.spinnerShow(source);
        	var query;
        	query = EntityQuery
				.from('ClearCache')
				.using(commonManager)
                .noTracking()
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						window.location.reload();
					}
				});

        	function querySucceeded(data) {
        		log("Cache clear" + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
        		spinner.queryFinally(source, spinnerActivated)
        	}
        	function queryFailed(data) {
        		spinner.queryFinally(source, spinnerActivated)
        		usersettings.errHandler("Cache clear" + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data.status);
        	}
        	return query;
        }

      

        function ChangeStatusAndCreateChecklist(entityArray, parameters) {
        	var source = 'ChangeStatusAndCreateChecklist';
        	var spinnerActivated = spinner.spinnerShow(source);
        	var jsonEntityArray = common.stringifyTrackedEntities(entityArray, commonManager);
        	var query;
        	query = EntityQuery
				.from('ChangeStatusAndCreateChecklist')
				.withParameters({ entityArray: jsonEntityArray, parameters: parameters })
				.using(commonManager)
                .noTracking()
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						return data.results;
					}
				});

        	function querySucceeded(data) {
        		log("ChangeStatusAndCreateChecklist" + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
        		spinner.queryFinally(source, spinnerActivated)
        	}
        	function queryFailed(data) {
        		spinner.queryFinally(source, spinnerActivated)
        		usersettings.errHandler("ChangeStatusAndCreateChecklist" + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data.status);
        	}
        	return query;
        }
	
        function deleteDocumentFromQ(recId) {
        	const source = 'DeleteDocument';
			const spinnerActivated = spinner.spinnerShow(source);
			return $http.delete(`api/Common/DeleteDocument/${recId}`)
				.then((result) => {
					log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DOCUMENT_DELETE") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', result, true);
					spinner.queryFinally(source, spinnerActivated);
					return result.data;
				})
				.catch((err) => {
					spinner.queryFinally(source, spinnerActivated);
					usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DOCUMENT_DELETE") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', err);
				})
        }

        function deleteDocument(recId) {
        	var lst = [];
        	lst = deleteDocumentFromQ(recId);
        	return $q.when(lst);
        }

        function getAccountsFromQ(searchString) {
        	var source = 'getAccounts';
        	var spinnerActivated = spinner.spinnerShow(source);
        	var accObj;
        	accObj = EntityQuery
				.from('Account')
				.withParameters({ searchString: searchString })
				.using(commonManager)
                .noTracking()
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						return data.results;
					}
				});

        	function querySucceeded(data) {
        		log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_ACCOUNT_QUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
        		spinner.queryFinally(source, spinnerActivated)
			}
        	function queryFailed(data) {
        		spinner.queryFinally(source, spinnerActivated)
        		usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_ACCOUNT_QUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data.status);
			}
        	return accObj;
        }

        function getAccounts(searchString) {
        	var lst = [];
        	lst = getAccountsFromQ(searchString);
        	return $q.when(lst);
        }

    	/**
		 * Gets column title (language constant value) for given form and column that uses dfxColumn.
		 * @param {Form id} formId
		 * @param {Column name} columnName
		 * @returns {Column title language constant value. If form or column is not found, returns columnName parameter value as such.
		 */
        function getColumnHeaderText(formId, columnName) {
        	var headerText = null;
        	headerText = getFormSettings(formId).then(function (data) {
        		if (data && data.columnSettings && data.columnSettings[columnName] && data.columnSettings[columnName].headerText) {
        			return data.columnSettings[columnName].headerText;
        		}
        		else return columnName;
        	});
        	
        	return $q.when(headerText);
        }

		function getCommentsFromQ(ownerRecIds, offset, size, orderBy, restrictionCmd) {
        	var source = 'getComments';
        	var spinnerActivated = spinner.spinnerShow(source);
        	// Initial implementation for server based data
        	var queryPromise = EntityQuery
				.from('Comment')
				.withParameters({
					objRecIdsJson: toOwnerRecIdsJson(ownerRecIds),
					offset: offset,
					size: size,
					orderBy: orderBy,
					restrictionCmd: restrictionCmd
				})
				.using(commonManager)
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					return data.results;
				});

        	function querySucceeded(data) {
        		log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_COMMENT_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
        		spinner.queryFinally(source, spinnerActivated)
			}

        	function queryFailed(data) {
        		spinner.queryFinally(source, spinnerActivated)
        		usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_COMMENT_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
			}

        	return queryPromise;
        }

        function getCompanyFromQ() {
            var source = 'getCompany';
            var spinnerActivated = spinner.spinnerShow(source);

            var projObj;
            // Initial implementation for server based data
            projObj = EntityQuery
            .from('Company')
            .using(commonManager)
            .noTracking()			// This is needed unless we want breeze to keep tracking of portal objects...
            .execute(querySucceeded, queryFailed)
            .then(function (data) {
                if (data) {
                    // The breeze query data is really in data.results member...
                    // TODO: figure out if these services should return breeze query data or only the results...
                    return data.results;
                }
            });
            function querySucceeded(data) {
                spinner.queryFinally(source, spinnerActivated)
                log(common.$translate.instant("STRCONST.LEANPORTAL.TXT_COMPANYQUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
            }
            function queryFailed(data) {
                spinner.queryFinally(source, spinnerActivated)
                usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.TXT_COMPANYQUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
            }
            return projObj;
        }

        function getCompany() {
            var lst = [];
            var lsid = usersettings.getLocStoKey('AvailableCompanies', false, false, true);
            if ((!common.$rootScope.online || common.$rootScope.isWorkingLocally) && localStorage && localStorage.getItem(lsid)) {
                lst = JSON.parse(localStorage.getItem(lsid));
            }
            else {
                lst = getCompanyFromQ();
            }
            return $q.when(lst);
        }

    	/**
         * Gets Comments from local database with owner recIds.
         */
        function getComments(ownerRecIds, offset, size, orderBy, restrictionCmd) {
			var lst = getCommentsFromQ(ownerRecIds, offset, size, orderBy, restrictionCmd);
        	return $q.when(lst);
        }


        function removeCommentFromQ(recId) {
        	const source = 'removeComment';
			const spinnerActivated = spinner.spinnerShow(source);
			return $http.delete(`api/Common/removeComment/${recId}`)
				.then((result) => {
					log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DOCUMENT_DELETE") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', result, true);
					spinner.queryFinally(source, spinnerActivated);
					return result.data;
				})
				.catch((err) => {
					spinner.queryFinally(source, spinnerActivated);
					usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DOCUMENT_DELETE") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', err);
				})
        }

        function removeComment(recId) {
        	var lst = [];
        	lst = removeCommentFromQ(recId);
        	return $q.when(lst);
        }

		function confirmCommentsAsReadFromQ(parentRecId, recId) {
			const source = 'confirmCommentsAsRead';
			const spinnerActivated = spinner.spinnerShow(source);
			return $http.post("api/Common/confirmCommentsAsRead", JSON.stringify({ parentRecId, recId }))
				.then((result) => {
					log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_COMMENT_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', result, true);
					spinner.queryFinally(source, spinnerActivated);
					return result.data;
				})
				.catch((err) => {
					spinner.queryFinally(source, spinnerActivated);
					usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_COMMENT_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', err);
				})
		}

		function confirmCommentsAsRead(parentRecId, recId) {
			var lst = [];
			lst = confirmCommentsAsReadFromQ(parentRecId, recId);
			return $q.when(lst);
		}

        function getEnumFromQ(enumId, values, cacheKey) {
        	var source = 'getEnum';
        	var spinnerActivated = spinner.spinnerShow(source);

            // Initial implementation for server based data
        	var queryPromise = EntityQuery
				.from('EnumItems')
				.withParameters({
					enumId: enumId,
					values: values
				})
				.using(commonManager)
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (cacheKey) {
						common.setCachedData(cacheKey, data.results);
					}
					return data.results;
				});

            function querySucceeded(data) {
            	log('[' + enumId + '] ' + common.$translate.instant("STRCONST.LEANPORTAL.MSG_ENUMERATION_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
                spinner.queryFinally(source, spinnerActivated)
			}

            function queryFailed(data) {
            	spinner.queryFinally(source, spinnerActivated)
            	usersettings.errHandler('[' + enumId + '] ' + common.$translate.instant("STRCONST.LEANPORTAL.MSG_ENUMERATION_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
			}

            return queryPromise;
        }

    	/**
         * Gets enum items from local database.
         * @param {enumId enumeration id for items.} enumId 
         */
        function getEnumFrom(enumId, values) {

        	// Caching mechanism to avoid mutiple identical DB requests
        	var cacheKey = ['getEnumFrom', enumId, values];
        	var cachePromise = common.getCachedData(cacheKey);
        	if (cachePromise) {
        		return cachePromise;
        	}

        	var lst = getEnumFromQ(enumId, values, cacheKey);
        	return $q.when(lst);
        }

		function getEmployeeListFromQ(cacheKey, useUserDepRst, searchString, tagId, formId, formVersion, exactMatch, userParams) {
        	var source = 'getEmployeeList';
        	var spinnerActivated = spinner.spinnerShow(source);
        	var queryPromise = EntityQuery
				.from('Employee')
				.withParameters({
					useUserDepRst: useUserDepRst,
					searchString: searchString,
					tagId: tagId,
					formId: formId,
					formVersion: formVersion,
					exactMatch: exactMatch,
					userParams: userParams
				})
				.using(commonManager)
                .noTracking()
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						if (cacheKey) {
							common.setCachedData(cacheKey, data.results);
						}
						return data.results;
					}
				}, function () {
				    common.rejectCachedData(cacheKey);
				    return null;
				});
        	function querySucceeded(data) {
        		log(common.$translate.instant("STRCONST.LEANPORTAL.TXT_EMPLOYEEQUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
        		spinner.queryFinally(source, spinnerActivated)
        	}
        	function queryFailed(data) {
        		spinner.queryFinally(source, spinnerActivated)
        		usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.TXT_EMPLOYEEQUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
        	}
        	return queryPromise;
        }

		function getEmployeeList(useUserDepRst, searchString, tagId, force, formId, formVersion, exactMatch, userParams) {

        	// Caching mechanism to avoid mutiple identical DB requests
			var cacheKey = ['getEmployeeList', useUserDepRst, searchString, formId, formVersion, exactMatch, userParams];
			if (!force) {
				var cachePromise = common.getCachedData(cacheKey);
				if (cachePromise) {
					return cachePromise;
				}
			}

			var lst = getEmployeeListFromQ(cacheKey, useUserDepRst, searchString, tagId, formId, formVersion, exactMatch, userParams);
        	return $q.when(lst);
        }

        function getEntityManager() {
            return commonManager;
        }

        /**
		 * Gets feed with form id and owner ids.
		 * @param {number} formId - Form id used in the query.
		 * @param {string} sortCmd - OrderBy command name used in the query.
		 * @param {object} feedRestrictData - Feed owner specific extra restriction data.
		 * @param {boolean} requestedByUi - requestedByUi loading indication and GUI blocking.
         * @returns {promise} Promise with data inside.
		 */
        function getFeedFromQ(formId, sortCmd, feedRestrictData, requestedByUi) {
            var source = 'getFeed';
            var spinnerActivated = spinner.spinnerShow(source, requestedByUi);

            var queryPromise = EntityQuery
                .from('Feed')
                .withParameters({
                    formId: formId,
                    sortCmd: sortCmd,
                    feedType: feedRestrictData.feedType,
                    recId: feedRestrictData.recId,
                    parentRecId: feedRestrictData.parentRecId,
                    serialId: feedRestrictData.serialId
                })
                .using(commonManager)
                .execute(querySucceeded, queryFailed)
                .then(function (data) {
                    return data.results;
                });

            function querySucceeded(data) {
                log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_FEED_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
                spinner.queryFinally(source, spinnerActivated);
            }

            function queryFailed(data) {
                spinner.queryFinally(source, spinnerActivated);
                usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_FEED_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
            }

            return queryPromise;
        }

        /**
         * Gets feed with form id and owner ids.
		 * @param {number} formId - Form id used in the query.
		 * @param {string} sortCmd - OrderBy command name used in the query.
		 * @param {object} feedRestrictData - Feed owner specific extra restriction data.
		 * @param {boolean} requestedByUi - requestedByUi loading indication and GUI blocking.
         * @returns {promise} Promise with data inside.
         */
        function getFeed(formId, sortCmd, feedRestrictData, requestedByUi) {
            var lst = getFeedFromQ(formId, sortCmd, feedRestrictData, requestedByUi);
            return $q.when(lst);
        }

        /**
         * Gets enumerations for document saving and updating.
         * @param {string} docDefaultsKey - Key for querying DOC_DEFAULTS enumeration.
         * @returns {promise} Promise containing enumeration array.
         */
        function getDocSaveEnums(docDefaultsKey) {

            // Caching mechanism to avoid mutiple identical DB requests
            var cacheKey = ['getDocSaveEnums', docDefaultsKey];
            var cachePromise = common.getCachedData(cacheKey);
            if (cachePromise) {
                return cachePromise;
            }

        	var lst = [];
            lst = getDocSaveEnumsFromQ(cacheKey, docDefaultsKey);
        	return $q.when(lst);
        }

        /**
         * Gets enumerations for document saving and updating.
         * @param {string} cacheKey - Key which is used in caching.
         * @param {string} docDefaultsKey - Key for querying DOC_DEFAULTS enumeration.
         * @returns {promise} Promise containing enumeration array.
         */
        function getDocSaveEnumsFromQ(cacheKey, docDefaultsKey) {
        	var source = 'getDocSaveEnums';
            var spinnerActivated = spinner.spinnerShow(source);
            docDefaultsKey = docDefaultsKey === undefined ? null : docDefaultsKey;
        	var queryPromise = EntityQuery
				.from('DocSaveEnums')
                .withParameters({ docDefaultsKey: docDefaultsKey })
				.using(commonManager)
                .noTracking()
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
                    if (data && data.results && data.results[0]) {
                        if (cacheKey) {
                            common.setCachedData(cacheKey, data.results[0].Enums);
                        }
						return data.results[0].Enums;
					}
				});

            function querySucceeded(data) {
                log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DOCENUMSQUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
                spinner.queryFinally(source, spinnerActivated);
            }
            function queryFailed(data) {
                spinner.queryFinally(source, spinnerActivated);
                usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DOCENUMSQUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data.status);
            }
            return queryPromise;
        }

		 /**
         * Gets map between the given model's dbColumns and properties.
		 * @param {string} modelName - The name of the model in the models namespace eg. PurchaseInvoice.
         */
		function getModelColumnMap(modelName) {
			if (!modelName) {
				return common.$q.when(null);
			}
            var cacheKey = ['getModelColumnMap', modelName];
            var cachePromise = common.getCachedData(cacheKey);
            if (cachePromise) {
                return cachePromise;
            }
        	var source = 'getModelColumnMap';
        	var spinnerActivated = spinner.spinnerShow(source);
        	var queryPromise = EntityQuery
				.from('ModelColumnMap')
				.withParameters({ modelName: modelName })
				.using(commonManager)
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
				    if (data) {
				        if (cacheKey) {
                            common.setCachedData(cacheKey, data.results[0]);
                        }
						return data.results[0];
					}
				}, function () {
				    common.rejectCachedData(cacheKey);
				    return null;
				});

        	function querySucceeded(data) {
        		log("model column map query" + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true); //TODO: Add strConst
        		spinner.queryFinally(source, spinnerActivated)
        	}

        	function queryFailed(data) {
        		spinner.queryFinally(source, spinnerActivated)
        		usersettings.errHandler("model column map query" + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
        	}

        	return queryPromise;
        }

        function getFormSettingsFromQ(formId, lsKey, retryCount, formVersion) {
        	var source = 'getFormSettings';
        	var spinnerActivated = spinner.spinnerShow(source);
        	var queryPromise = EntityQuery
				.from('SPAFormSettings')
                .withParameters({
                    formId: formId,
                    formVersion: formVersion})
				.using(commonManager)
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {						
					    localStorage.setItem(lsKey, JSON.stringify(data.results[0]));
					    formSettings[lsKey] = data.results[0];
					    formPromises[lsKey].resolve();
						return data.results[0];
					}
				}, function (reason) {
					if (retryCount > 0 && reason && (reason != 401 && reason.status != 401)) {
						common.$timeout(function () {
							getFormSettingsFromQ(formId, lsKey, --retryCount);
						}, 200);
					}
					else {
						localStorage.setItem(lsKey, null);
						formSettings[lsKey] = null;
						formPromises[lsKey] = null;
						return $q.reject(reason);
					}
				});

        	function querySucceeded(data) {
        		log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_FORMSETTINGS_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
        		spinner.queryFinally(source, spinnerActivated)
			}

        	function queryFailed(data) {
        		spinner.queryFinally(source, spinnerActivated)
        		usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_FORMSETTINGS_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
			}

        	return queryPromise;
        }
    	/**
         * Gets FormSettings from database or from local cache.
         * @param {number} formId - Id of form whose settings will be queried.
         * @param {boolean} [force] - Always get from the database, not from cache.
         * @returns {promise} Form settings
         */
        function getFormSettings(formId, force, formVersion) {
            if (!formId) {
                var errPromise = $q.defer();
                return errPromise.reject(common.$translate.instant("STRCONST.ERROR.10002").replace('%1', 'formId'));
            }
        	var lst = null;
			var lsKey = formVersion ? usersettings.getLocStoKey('FormSettings' + '_' + formId + '_' + formVersion) : usersettings.getLocStoKey('FormSettings' + '_' + formId);
            if (force) { //force getFormSettingsFromQ.
            	delete formPromises[lsKey];
            }
            if (formSettings[lsKey] && formPromises[lsKey] && formPromises[lsKey].promise && formPromises[lsKey].promise.$$state.status == 1) { //getFormSettingsFromQ has already been resolved.
                lst = formSettings[lsKey];
            }
            else if (formPromises[lsKey]) { //getFormSettingsFromQ has been triggered but not yet resolved.
            	lst = checkFormPromises(lsKey).then(function () {
            	      return formSettings[lsKey];
            	});           	
            }
            else if (common.$rootScope.isWorkingLocally && localStorage && localStorage.getItem(lsKey)) {
        		lst = JSON.parse(localStorage.getItem(lsKey));
        	}
        	else { //getFormSettingsFromQ has not yet been triggered.
            	if (!formPromises[lsKey]) {
        			var formPromise = $q.defer();
        			formPromises[lsKey] = formPromise;
        		}
        		lst = getFormSettingsFromQ(formId, lsKey, 3, formVersion);
        	}

        	return $q.when(lst);
		}

		function getFormSettingsListFromQ(formIds) {
			spinner.spinnerShow();
			var formSetObj;
			formSetObj = EntityQuery
                .from('SPAFormSettingsList')
                .withParameters({ formIds: formIds })
				.using(commonManager)
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					spinner.spinnerHide();
					if (data) {
						formIds.forEach(function (formId) {
							var lsKey = usersettings.getLocStoKey('FormSettings' + '_' + formId);
							localStorage.setItem(lsKey, JSON.stringify(data.results[0][formId]));
							formSettings[lsKey] = data.results[0][formId];
							formPromises[formId].resolve();
						})
						return data.results[0];
					}
				}, function (reason) {
					formIds.forEach(function (formId) {
						var lsKey = usersettings.getLocStoKey('FormSettings' + '_' + formId);
						localStorage.setItem(lsKey, null);
						formSettings[lsKey] = null;
						formPromises[lsKey].reject(reason);
						return $q.reject(reason);
					})

				});

			function querySucceeded(data) {
                log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_FORMSETTINGS_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
			}

			function queryFailed(data) {
                usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_FORMSETTINGS_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
			}

			return formSetObj;
		}

		function getFormSettingsList(formIds, force) {
			var lst = {};
			if (formIds) {
				formIds.forEach(function (formId) {
					var lsKey = usersettings.getLocStoKey('FormSettings' + '_' + formId);
					if (formSettings[lsKey] && formPromises[formId] && formPromises[formId].promise && formPromises[formId].promise.$$state.status == 1) { //getFormSettingsFromQ has already been resolved.
					}
					else if (formPromises[formId]) { //getFormSettingsFromQ has been triggered but not yet resolved.
					}
					else {
						var formPromise = $q.defer();
						formPromises[formId] = formPromise;
					}

				})
				lst = getFormSettingsListFromQ(formIds);

			}
			return $q.when(lst);
		}

        function checkFormPromises(lsKey) {
        	if (formPromises[lsKey]) {
        		return $q.when(formPromises[lsKey].promise);
        	}
        	else {
        		return $q.when(true);
        	}
        }

		function confirmCheckListRowsFromQ(recIdList, status, orderId) {
			var source = 'getPurchaseOrders';
			var spinnerActivated = spinner.spinnerShow(source);
			var obj;
			obj = EntityQuery
				.from('ConfirmCheckListRows')
				.withParameters({
					recIdList: recIdList,
					status: status,
					orderId: orderId
				})
				.using(commonManager)
				.noTracking()
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						return data.results;
					}
				});

			function querySucceeded(data) {
				spinner.queryFinally(source, spinnerActivated)
			}
			function queryFailed(data) {
				spinner.queryFinally(source, spinnerActivated)
				if (data && data.message) {
					usersettings.errHandler(data.message);
				}
				else {
					usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.TXT_PURORDERQUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data.status);
				}
			}
			return obj;
		}

		function confirmCheckListRows(recIdList, status, orderId) {
			var lst = [];
			lst = confirmCheckListRowsFromQ(recIdList, status, orderId);
			return $q.when(lst);
		}

		/**
		 * Gets images from database.
		 * @param {} docLinkId 
		 * @param {} objectType 
		 * @param {} objectRecid 
		 * @param {} offset 
		 * @param {} size 
		 * @param {} docListOption
         * @param {number} [formId=0] - Form id used when setting extra restriction.
         * @param {string} [restrictionCmdArr] - Form restriction commands as an array, separated with comma.
         * @param {object} [lookupParams] - Lookup parameters for extra restriction used in the query. For example [{ "PARAM1": 'foo' }, { "PARAM2": recId }]
		 * @param {string} [docDefaultsKey] - Key for querying DOC_DEFAULTS enumeration.
		 * @returns {promise} Promise with data inside.
		 */
		function getImagesFromQ(docLinkId, objectType, objectRecid, offset, size, docListOption, formId, restrictionCmdArr, lookupParams, docDefaultsKey) {
            var source = 'getImages';
            var spinnerActivated = spinner.spinnerShow(source);
            formId = !formId ? 0 : formId;
            // Initial implementation for server based data
            var queryPromise = EntityQuery
				.from('Images')
				.using(commonManager)
                .withParameters({
                    docLinkId: docLinkId, objectType: objectType, objectRecid: objectRecid, offset: offset, size: size, docListOption: docListOption,
					formId: formId, restrictionCmdArr: restrictionCmdArr, lookupParams: JSON.stringify(lookupParams), docDefaultsKey
                })
				.noTracking()			// This is needed unless we want breeze to keep tracking of document objects...
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						// The breeze query data is really in data.results member...
						// TODO: figure out if these services should return breeze query data or only the results...
						return data.results;
					}
				});

            function querySucceeded(data) {
                log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_IMAGE_QUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
                spinner.queryFinally(source, spinnerActivated);
            }

            function queryFailed(data) {
                spinner.queryFinally(source, spinnerActivated);
                usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_IMAGE_QUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
            }

            return queryPromise;
        }

		/**
		 * Gets images either from local storage or from database.
		 * @param {} docLinkId 
		 * @param {} objectType 
		 * @param {} objectRecid 
		 * @param {} offset 
		 * @param {} size 
		 * @param {} docListOption
         * @param {number} [formId=0] - Form id used when setting extra restriction.
         * @param {string} [restrictionCmdArr] - Form restriction commands as an array, separated with comma.
         * @param {object} [lookupParams] - Lookup parameters for extra restriction used in the query. For example [{ "PARAM1": 'foo' }, { "PARAM2": recId }]
		 * @param {string} [docDefaultsKey] - Key for querying DOC_DEFAULTS enumeration.
		 * @returns {} 
		 */
        function getImages(docLinkId, objectType, objectRecid, offset, size, docListOption, formId, restrictionCmdArr, lookupParams, docDefaultsKey) {
            var lst = [];
            var lsid = usersettings.getLocStoKey('Images', false, true);
            if (common.$rootScope.isWorkingLocally && localStorage && localStorage.getItem(lsid)) {
                lst = JSON.parse(localStorage.getItem(lsid));
            }
            else {
				lst = getImagesFromQ(docLinkId, objectType, objectRecid, offset, size, docListOption, formId, restrictionCmdArr, lookupParams, docDefaultsKey);
            }
            return $q.when(lst);
        }

		/**
		 * Gets status of the profiling (started or not).
		 */
        function getLeanProfilingStatus() {
        	var source = 'getLeanProfilingStatus';
        	var spinnerActivated = spinner.spinnerShow(source);
        	var query;
        	query = EntityQuery
				.from('getLeanProfilingStatus')
				.using(commonManager)
                .noTracking()
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						return data.results[0];
					}
				});

        	function querySucceeded(data) {
        		log(common.$translate.instant("STRCONST.LEANPORTAL.TXT_GET_LEAN_PROFILING_STATUS") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
        		spinner.queryFinally(source, spinnerActivated)
        	}
        	function queryFailed(data) {
        		spinner.queryFinally(source, spinnerActivated)
        		usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.TXT_GET_LEAN_PROFILING_STATUS") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data.status);
        	}
        	return query;
        }

		/**
		 * Gets LeanProfiling report. Profiling is closed on the server after this call.
		 */
        function getLeanProfilingReport() {
        	var source = 'getLeanProfilingReport';
        	var spinnerActivated = spinner.spinnerShow(source);
        	var query;
        	query = EntityQuery
				.from('FormProfileItem')
				.using(commonManager)
                .noTracking()
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						return data.results;
					}
				});

        	function querySucceeded(data) {
        		log(common.$translate.instant("STRCONST.LEANPORTAL.TXT_GET_LEAN_PROFILING_REPORT") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
        		spinner.queryFinally(source, spinnerActivated)
        	}
        	function queryFailed(data) {
        		spinner.queryFinally(source, spinnerActivated)
        		usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.TXT_GET_LEAN_PROFILING_REPORT") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data.status);
        	}
        	return query;
        }

		function getLocSaldoFromQ(itemId, offset, size, formId, itemIdMandatory) {
			var source = 'getLocSaldo';
			var spinnerActivated = spinner.spinnerShow(source);

			var obj;
			obj = EntityQuery
				.from('LocSaldo')
				.using(commonManager)
				.withParameters({ itemId: itemId, offset: offset, size: size, formId: formId, itemIdMandatory: itemIdMandatory })
				//.noTracking()			// This is needed unless we want breeze to keep tracking of entities...
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						return data.results;
					}
				});
			function querySucceeded(data) {
				spinner.queryFinally(source, spinnerActivated)
				log(common.$translate.instant("STRCONST.LEANPORTAL.STOCK_BALANCE_QUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
			}
			function queryFailed(data) {
				spinner.queryFinally(source, spinnerActivated)
				usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.STOCK_BALANCE_QUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
			}
			return obj;
		}

		function getLocSaldo(itemId, offset, size, formId, itemIdMandatory) {
			var lst = [];
			lst = getLocSaldoFromQ(itemId, offset, size, formId, itemIdMandatory);
			return $q.when(lst);
		}

		/**
		 * Get enumerations etc. from the database.
		 * @param {Manager name (optional). If not specified common manager is used.} manager 
		 * @returns {} 
		 */
		function getLookupsFromQ(manager, cacheKey, enumArray, showSpinner) {
        	var source = 'getLookups';
			var spinnerActivated = showSpinner ? spinner.spinnerShow(source) : false;
        	var queryPromise = EntityQuery
				.from('Lookups')
				.withParameters({enumArray:enumArray})
				.using(manager ? manager : commonManager)
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						if (cacheKey) {
							common.setCachedData(cacheKey, data.results);
						}
						return data.results;
					}
				});

        	function querySucceeded(data) {
        		log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_ENUMERATION_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
        		spinner.queryFinally(source, spinnerActivated)
        	}

        	function queryFailed(data) {
        		spinner.queryFinally(source, spinnerActivated)
        		usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_ENUMERATION_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '. ' + data.status, data);
			}

        	return queryPromise;
        }

		/**
		 * Get enumerations etc. from local storage or from the database.
		 * @param {Language used for enumeration names.} language 
		 * @param {Set to true if query from database is always used.} forceRefresh 
		 * @param {Manager name (optional). If not specified common manager is used.} manager 
		 * @param {Portal name prefix (optional). No use if forceRefresh is set to true} localStoragePrefix 
		 * @param {Possibility to hide spinner (optional). Defaults to true} showSpinner
		 * @returns {}
		 */
		function getLookups(language, forceRefresh, manager, localStoragePrefix, enumArray, showSpinner) {
            var lsid = usersettings.getLocStoKey(localStoragePrefix + 'Lookups_' + language);
            var lst = [];
            if (localStorage && localStorage.getItem(lsid) && common.$rootScope.isWorkingLocally) {
                lst = JSON.parse(localStorage.getItem(lsid));
            }
            else {
                // Caching mechanism to avoid mutiple identical DB requests
                var cacheKey = ['getLookups', localStoragePrefix, language];
                if (!forceRefresh) {
                    var cachePromise = common.getCachedData(cacheKey);
                    if (cachePromise) {
                        return cachePromise;
                    }
				}
				if (showSpinner !== false)
					showSpinner = true;
				lst = getLookupsFromQ(manager, cacheKey, enumArray, showSpinner);
            }
            return $q.when(lst);
        }

    	/**
		 * Get enumerations etc. from the database.
		 * @param {string} [cacheKey] - Key for caching results.
		 * @param {int} formId - Id of form used in the query.
		 * @returns {promise} 
		 */
        function getLookupsFromFormQ(cacheKey, formId) {
        	var source = 'getLookups';
        	var spinnerActivated = spinner.spinnerShow(source);
        	var queryPromise = EntityQuery
				.from('LookupsFromForm')
				.withParameters({ formId: formId })
				.using(commonManager)
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						if (cacheKey) {
							common.setCachedData(cacheKey, data.results);
						}
						return data.results;
					}
				});

        	function querySucceeded(data) {
        		log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_ENUMERATION_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
        		spinner.queryFinally(source, spinnerActivated)
        	}

        	function queryFailed(data) {
        		spinner.queryFinally(source, spinnerActivated)
        		usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_ENUMERATION_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '. ' + data.status, data);
        	}

        	return queryPromise;
        }

        function getLookupsFromForm(language, forceRefresh, localStoragePrefix, formId) {
        	var lsid = usersettings.getLocStoKey(localStoragePrefix + 'LookupsFromForm_' + language);

        	// Caching mechanism to avoid mutiple identical DB requests
        	var cacheKey = ['getLookupsFromForm', localStoragePrefix, language, formId];
        	if (!forceRefresh) {
        		var cachePromise = common.getCachedData(cacheKey);
        		if (cachePromise) {
        			return cachePromise;
        		}
        	}

        	var lst = [];
        	if (localStorage && localStorage.getItem(lsid) && (!forceRefresh || common.$rootScope.isWorkingLocally)) {
        		lst = JSON.parse(localStorage.getItem(lsid));
        	}
        	else {
        		lst = getLookupsFromFormQ(cacheKey, formId);
        	}
        	return $q.when(lst);
        };

        function getOPUsFromQ() {
            var source = 'getOPUs';
            var spinnerActivated = spinner.spinnerShow(source);
            var projObj;

            // Initial implementation for server based data
            projObj = EntityQuery
				.from('OPUs')
				.using(commonManager)
				.noTracking()			// This is needed unless we want breeze to keep tracking of portal objects...
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
				    if (data) {
				        // The breeze query data is really in data.results member...
				        // TODO: figure out if these services should return breeze query data or only the results...
				        return data.results;
				    }
				});

            function querySucceeded(data) {
                // log(strConst.LEANPORTAL$TXT_PORTALQUERY + " " + strConst.LEANPORTAL$TXT_SUCCESS + '.', data, true);
                spinner.queryFinally(source, spinnerActivated)
            }

            function queryFailed(data) {
                spinner.queryFinally(source, spinnerActivated)
                usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.TXT_PORTALQUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
            }

            return projObj;
        }

        function getOPUs() {
            var lst = [];
            var lsid = usersettings.getLocStoKey('AvailableOPUs', false, true);
            if (common.$rootScope.isWorkingLocally && localStorage && localStorage.getItem(lsid)) {
                lst = JSON.parse(localStorage.getItem(lsid));
            }
            else {
                lst = getOPUsFromQ();
            }
            return $q.when(lst);
        }

		function getGenericDataSetFromQ(cacheKey, searchString, filterString, formId, formVersion, sortCmd, restrictionCmdArr, logicalId, lookupParams, objectName, requestedByUi, noCache) {
			var requestedByUi = requestedByUi === undefined ? true : requestedByUi;
			var source = 'getGenericDataSet';
			var spinnerActivated = spinner.spinnerShow(source, requestedByUi);
			var poObj;
			var object = objectName ? objectName : 'form: ' + formId + ', version: ' + formVersion;
			poObj = EntityQuery
				.from('GenericFormDataSet')
				.withParameters({
					searchString: searchString,
					filterString: filterString,
					formId: formId,
					formVersion: formVersion,
					sortCmd: sortCmd,
					restrictionCmdArr: restrictionCmdArr,
					logicalId: logicalId,
					lookupParams: lookupParams,
				})
				.using(commonManager)
				.noTracking(true)
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						if (requestedByUi) {
							spinner.spinnerHide();
						}
						if (cacheKey && !noCache) {
							common.setCachedData(cacheKey, data.results);
						}
						return data.results;
					}
				}, function () {
					common.rejectCachedData(cacheKey);
					return null;
				});

			function querySucceeded(data) {
				var msg = common.$translate.instant("STRCONST.LEANPORTAL.TXT_GENDATAQUERY") ? common.$translate.instant("STRCONST.LEANPORTAL.TXT_GENDATAQUERY").replace('%1', object) : object;
				log(msg + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
				spinner.queryFinally(source, spinnerActivated)
			}
			function queryFailed(data) {
				spinner.queryFinally(source, spinnerActivated);
				if (data && data.message) {
					usersettings.errHandler(data.message);
				}
				else {
					var msg = common.$translate.instant("STRCONST.LEANPORTAL.TXT_GENDATAQUERY") ? common.$translate.instant("STRCONST.LEANPORTAL.TXT_GENDATAQUERY").replace('%1', object) : object;
					usersettings.errHandler(msg + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data.status);
				}
			}
			return poObj;
		}

		function getGenericDataSet(searchString, filterString, formId, formVersion, sortCmd, restrictionCmdArr, logicalId, lookupParams, objectName, requestedByUi, noCache) {
			var requestedByUi = requestedByUi === undefined ? true : false;
			var cacheKey = null;
			var noCache = noCache
			// default for noCache is false
			if (noCache === undefined || noCache === null) { noCache = false };
			if (!noCache) {
				cacheKey = ['getGenericDataSet', formId, formVersion, searchString, filterString, lookupParams, logicalId];
				var cachePromise = common.getCachedData(cacheKey);
				if (cachePromise) {
					return cachePromise;
				}
			}
			var lst = getGenericDataSetFromQ(cacheKey, searchString, filterString, formId, formVersion, sortCmd, restrictionCmdArr, logicalId, lookupParams, objectName, requestedByUi, noCache);
			return $q.when(lst);
		}


        function getPurchaseOrdersFromQ(searchString, filterString, formId, formVersion, sortCmd, restrictionCmdArr, logicalId, noTracking, resId) {
            var source = 'getPurchaseOrders';
            var spinnerActivated = spinner.spinnerShow(source);
            formId = formId || 0;
            noTracking = (noTracking === undefined || noTracking === null ? true : noTracking);
            var poObj;
            poObj = EntityQuery
                .from('PurchaseOrder')
                .withParameters({
                    searchString: searchString,
                    filterString: filterString,
                    formId: formId,
                    formVersion: formVersion,
                    sortCmd: sortCmd,
                    restrictionCmdArr: restrictionCmdArr,
                    logicalId: logicalId,
                    resId: resId
                })
                .using(commonManager)
                .noTracking(noTracking)
                .execute(querySucceeded, queryFailed)
                .then(function (data) {
                    if (data) {
                        return data.results;
                    }
                });

            function querySucceeded(data) {
                log(common.$translate.instant("STRCONST.LEANPORTAL.TXT_PURORDERQUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
                spinner.queryFinally(source, spinnerActivated);
            }
            function queryFailed(data) {
                spinner.queryFinally(source, spinnerActivated);
                usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.TXT_PURORDERQUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data.status);
            }
            return poObj;
        }

        function getPurchaseOrders(searchString, filterString, formId, formVersion, sortCmd, restrictionCmdArr, logicalId, noTracking, resId) {
            var lst = [];
            lst = getPurchaseOrdersFromQ(searchString, filterString, formId, formVersion, sortCmd, restrictionCmdArr, logicalId, noTracking, resId);
            return $q.when(lst);
        }

        function getSubstituteUsersFromQ(searchString, filterString, formId, formVersion, sortCmd, restrictionCmdArr, logicalId, noTracking) {
            var source = 'getUserSubstitutes';
            var spinnerActivated = spinner.spinnerShow(source);
            noTracking = (noTracking == undefined || noTracking == null ? true : noTracking);
            var usObj;
            usObj = EntityQuery
				.from('SubstituteUser')
				.withParameters({
				    searchString: searchString,
				    filterString: filterString,
				    formId: formId,
				    formVersion: formVersion,
				    sortCmd: sortCmd,
				    restrictionCmdArr: restrictionCmdArr,
				    logicalId: logicalId
				})
            .using(commonManager)
           // .noTracking(noTracking) No need for tracking(?)
            .execute(querySucceeded, queryFailed)
            .then(function (data) {
                spinner.spinnerHide();
                if (data) {
                    return data.results;
                }
            });

            function querySucceeded(data) {
                spinner.queryFinally(source, spinnerActivated)
                log(common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUBSTITUTE_USER_QUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
            }
            function queryFailed(data) {
				spinner.queryFinally(source, spinnerActivated)
				if (data && data.message) {
					usersettings.errHandler(data.message, data.status);
				}
				else {
					usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUBSTITUTE_USER_QUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data.status);
				}
            }
            return usObj;
        }

        /**
        * Gets substitute users
        */
         function getSubstituteUsers(searchString, filterString, formId, formVersion, sortCmd, restrictionCmdArr, logicalId, noTracking) {
             var lst = getSubstituteUsersFromQ(searchString, filterString, formId, formVersion, sortCmd, restrictionCmdArr, logicalId, noTracking);
             return $q.when(lst);
         }

		function getTextsFromQ(ownerRecIds, textType, offset, size, sort, filterByTextLanguage, textDefaultsKey) {
        	var source = 'getTexts';
        	var spinnerActivated = spinner.spinnerShow(source);
        	offset = offset || 0;
        	size = size || 75;
        	textType = textType || 'TEXT';

        	var queryPromise = EntityQuery
				.from('Text')
				.withParameters({
					objRecIdsJson: toOwnerRecIdsJson(ownerRecIds),
					textType: textType,
					offset: offset,
					size: size,
					sort: sort,
					filterByTextLanguage: filterByTextLanguage,
					textDefaultsKey
				})
				.using(commonManager)
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
				    return data.results;
				});

            function querySucceeded(data) {
            	log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_TEXT_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
                spinner.queryFinally(source, spinnerActivated)
			}

            function queryFailed(data) {
            	spinner.queryFinally(source, spinnerActivated)
            	usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_TEXT_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
			}

            return queryPromise;
        }

		/**
		 * Gets texts from local database with owner recIds.
		 */
		function getTexts(ownerRecIds, textType, offset, size, sort, filterByTextLanguage, textDefaultsKey) {
			var lst = getTextsFromQ(ownerRecIds, textType, offset, size, sort, filterByTextLanguage, textDefaultsKey);
			return $q.when(lst);
		}

		/**
         * Gets enumerations for text saving and updating.
         * @param {string} textDefaultsKey - Key for querying TEXT_DEFAULTS enumeration.
         * @returns {promise} Promise containing enumeration array.
         */
		function getTextSaveEnums(textDefaultsKey) {

			// Caching mechanism to avoid mutiple identical DB requests
			var cacheKey = ['getTextSaveEnums', textDefaultsKey];
			var cachePromise = common.getCachedData(cacheKey);
			if (cachePromise) {
				return cachePromise;
			}

			var lst = [];
			lst = getTextSaveEnumsFromQ(cacheKey, textDefaultsKey);
			return $q.when(lst);
		}

		/**
		 * Gets enumerations for text saving and updating.
		 * @param {string} cacheKey - Key which is used in caching.
		 * @param {string} textDefaultsKey - Key for querying TEXT_DEFAULTS enumeration.
		 * @returns {promise} Promise containing enumeration array.
		 */
		function getTextSaveEnumsFromQ(cacheKey, textDefaultsKey) {
			var source = 'getTextSaveEnums';
			var spinnerActivated = spinner.spinnerShow(source);
			textDefaultsKey = textDefaultsKey === undefined ? null : textDefaultsKey;
			var queryPromise = EntityQuery
				.from('TextSaveEnums')
				.withParameters({ textDefaultsKey: textDefaultsKey })
				.using(commonManager)
				.noTracking()
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data && data.results && data.results[0]) {
						if (cacheKey) {
							common.setCachedData(cacheKey, data.results[0].Enums);
						}
						return data.results[0].Enums;
					}
				});

			function querySucceeded(data) {
				log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_TEXT_ENUMS_QUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
				spinner.queryFinally(source, spinnerActivated);
			}
			function queryFailed(data) {
				spinner.queryFinally(source, spinnerActivated);
				usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_TEXT_ENUMS_QUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data.status);
			}
			return queryPromise;
		}

        function getVatCodesFromQ(searchString) {
        	var source = 'getVatCodes';
        	var spinnerActivated = spinner.spinnerShow(source);
        	var queryPromise = EntityQuery
				.from('VatCode')
				.withParameters({searchString: searchString})
				.using(commonManager)
                .noTracking()
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						return data.results;
					}
				});

        	function querySucceeded(data) {
        		log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_VATCODE_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
        		spinner.queryFinally(source, spinnerActivated)
			}
        	function queryFailed(data) {
        		spinner.queryFinally(source, spinnerActivated)
        		usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_VATCODE_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data.status);
			}
        	return queryPromise;
        }

        function getVatCodes(searchString) {
        	var lst = [];
        	lst = getVatCodesFromQ(searchString);
        	return $q.when(lst);
        }

		function getWorkReportFromQ(empId, offset, size, statusCmd, begin_stamp, end_stamp, sort, resId, workId, operatId) {
        	var source = 'getWorkReport';
        	var spinnerActivated = spinner.spinnerShow(source);
        	var queryPromise = EntityQuery
				.from('WorkReport')
                .withParameters({
                	empId: empId,
                	offset: offset ? offset : 0,
                	size: size ? size : 1,
                	sort: sort ? sort : 'ORDER_BEGINSTAMP_DESC',
                	statusCmd: statusCmd,
                	begin_stamp: begin_stamp,
					end_stamp: end_stamp,
					resId: resId,
					workId: workId,
					operatId: operatId
                })
				.using(commonManager)
				.noTracking()			// This is needed unless we want breeze to keep tracking of portal objects...
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						return data.results;
					}
				});
        	function querySucceeded(data) {
        		log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_TIMESTAMP_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
        		spinner.queryFinally(source, spinnerActivated)
        	}
        	function queryFailed(data) {
        		spinner.queryFinally(source, spinnerActivated)
        		usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_TIMESTAMP_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
        	}
        	return queryPromise;
        }

		function getWorkReport(empId, offset, size, statusCmd, begin_stamp, end_stamp, sort, resId, workId, operatId) {
        	var lst = [];
			lst = getWorkReportFromQ(empId, offset, size, statusCmd, begin_stamp, end_stamp, sort, resId, workId, operatId);
        	return $q.when(lst);
        }

        function CheckList() {
        }

        /**
         * Form with form settings.
         * @class
         * @param {object} entity - Entity whose values are looked at.
         * @param {object} formSettings - Form settings.
         * @param {object[]} formSettings.columnSettings[] - ColumnSettings property within formSettings.
         * @param {string[]} modelColumnMap - Array of strings where key is column name and value is entity property name.
         * @param {string} formMode - Mode the form is currently. Possible values read, insertEdit, updateEdit.
         */
        function DfxForm(entity, formSettings, modelColumnMap, formMode) {
            this.entity = entity;
            this.formSettings = formSettings;
            this.modelColumnMap = modelColumnMap;
            this.formMode = formMode;
		}

		/**
		 * Form command execution call.
		 * @param {Object} optParams - Optional parameters.
		 * @param {boolean} [optParams.noToaster] - When true does not show errors and warnings as toaster.
		 * @param {number} formId - Id of form where the command is found in.
		 * @param {string} commandId - Command id defines executed DB method.
		 * @param {JSON[]} parameters - >Data parameters passed to passed to DB call
		 * @returns {promise} Promise with boolean value true if succeeded inside.
		 */
		function ExecuteToolCommand(optParams, formId, commandId, parameters) {
			spinner.spinnerShow();
			var executeObj;

			executeObj = EntityQuery
				.from('ExecuteToolCommand')
				.using(commonManager)
				.withParameters({
					formId: formId,
					commandId: commandId,
					parameterJson: JSON.stringify(parameters)
				})
				.noTracking()
				.execute(optParams && optParams.noToaster ? querySucceededNoToaster : querySucceeded, queryFailed)
				.then(function (data) {
					if (data.httpResponse && data.httpResponse.status === 202) {
						return data.httpResponse.statusText
					}
					return data && data.results && data.results[0];
				});

			function querySucceededNoToaster(data) {
				spinner.spinnerHide();
			}

			function querySucceeded(data) {
				spinner.spinnerHide();
				if (data.httpResponse && data.httpResponse.status === 202) {
					warnMsg(data.httpResponse.statusText)
				} else {
					logSuccess(common.$translate.instant("STRCONST.WEB.TXT_COMMAND") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
				}
			}

			function queryFailed(data) {
				spinner.spinnerHide();
				usersettings.errHandler(common.$translate.instant("STRCONST.WEB.TXT_COMMAND") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + ': ' + data.message, data);
			}

			return $q.when(executeObj);
		}

        function PurchaseOrder() {
        }

        function Text() {
        }

        function Document() {
        }

        function SubstituteUser() {
            this.beginDate = new Date();
            this.endDate = new Date();
        }

        function init() {

            /**
             * Returns true if entity property related to given column name has any value.
             * @param {string} columnName - Column name from column settings.
             * @returns {boolean} True if property has value, false otherwise.
            */
            DfxForm.prototype.hasColValue = function (columnName) {
                return this.modelColumnMap && this.modelColumnMap[columnName] && this.entity && this.entity[this.modelColumnMap[columnName]] && this.entity[this.modelColumnMap[columnName]] != '';
            };

            /**
             * Returns true if any visible and editable value is set on given entity columns.
             * @todo Expand this to use breeze hasChanges mechanism at least when formMode = updateEdit.
             * @returns {boolean} True if form has changed values, false otherwise. False also if entity, formSettings or columnSettings is not set or form is in read mode.
             */
            DfxForm.prototype.isFormDirty = function () {
                if (!this.entity || !this.formSettings || !this.formSettings.columnSettings || this.formMode == 'read') return false;
                var _isDirty = false;
                var _this = this;
                angular.forEach(this.formSettings.columnSettings, function (col) {
                    if (_this.hasColValue(col.columnName) && !col.hidden && ((col.insertEditable && _this.formMode == 'insertEdit') || (col.updateEditable && _this.formMode == 'updateEdit'))) {
                        _isDirty = true;
                    }
                });
                return _isDirty;
            };

            /**
             * Returns true if all required fields are filled in. If not, raises an error and returns false.
             * @returns {boolean} True if all required fields are filled, false otherwise. Returns false when formSettings or columnSettings are not set.
             */
            DfxForm.prototype.isFormRequiredFieldsFilled = function () {
                if (!this.formSettings || !this.formSettings.columnSettings) return false;
                var missingRequiredFieldNames = [];
                var _this = this;
                angular.forEach(this.formSettings.columnSettings, function (col) {
                    if (!_this.hasColValue(col.columnName) && col.mandatory && !col.hidden) {
                        missingRequiredFieldNames.push(col.headerText);
                    }
                });

                if (missingRequiredFieldNames.length > 0) {
                    usersettings.errHandler(common.$translate.instant("STRCONST.DLG.TXT_REQDATAMISSING") + ": " + missingRequiredFieldNames.join(', ') + '.');
                    return false;
                }
                return true;
            };

        	// Doc references are removed in devel
        	commonManager.metadataStore.registerEntityTypeCtor('Document', Document);

        	Document.prototype.setContentLoadingReady = function () {
        		this.contentLoadingReady = true;
        	}
        	Document.prototype.isContentLoadingReady = function () {
        		return this.contentLoadingReady;
        	}

            commonManager.metadataStore.registerEntityTypeCtor('CheckList', CheckList);
            CheckList.prototype.objRecId = function () {
                return common.toObjRecId("DLG_CHECKLIST", this.recId);
            }

            commonManager.metadataStore.registerEntityTypeCtor('PurchaseOrder', PurchaseOrder);

            PurchaseOrder.prototype.commonDataParents = function () {
            	return [common.toObjRecId("PUR_ORDER", this.recId, this.orderId)];
            }

            commonManager.metadataStore.registerEntityTypeCtor('Text', Text);

        	// API to get meaningful user value
            Text.prototype.getTextUser = function () {
            	return this.updatedByName ? this.updatedByName : this.createdByName;
            }

            commonManager.metadataStore.registerEntityTypeCtor('SubstituteUser', SubstituteUser, function (entity) {
                // Set userId value to current userId if new entity is created.
                if (!entity.userId) {
                    usersettings.getUserSettings().then(function (userdata) { entity.userId = userdata.info.userId });
                }
            });
        }

        function hasChanges() {
        	return _hasChanges || resolveHasChanges();
        }

    	/**
		 * Prints labels to file.
		 * @param {string} labelId - Label id. Enumeration id from LABEL_PRINTING enumeration
		 * @param {number} objectRecid - Printable object recid
		 * @param {string} labelPrinterId - Label printer id. Enumeration id from LABEL_PRINTER enumeration
		 * @param {number} [labelCount=1] - Number of labels to print.
		 * @param {number} [options=0] - Options number to be sent to PL/SQL procedure.
		 * @returns {Object} 
		 */
        function printLabelToFile(labelId, objectRecid, labelPrinterId, labelCount, options) {
        	labelCount = labelCount || 1;
        	options = options || 0;

        	var source = 'printLabelToFile';
        	var spinnerActivated = spinner.spinnerShow(source);

        	var queryObj = EntityQuery
				.from('printLabelToFile')
				.using(commonManager)
				.withParameters({
					labelId: labelId,
					objectRecid: objectRecid,
					labelPrinterId: labelPrinterId,
					labelCount: labelCount,
					options: options
				})
				.execute(printingSucceeded, printingFailed);

        	function printingSucceeded(result) {
        		logSuccess(common.$translate.instant("STRCONST.PUBLIC.HDR_PRINT_LABEL") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + ".", result, true);
        		spinner.queryFinally(source, spinnerActivated)
			}

        	function printingFailed(error) {
        		spinner.queryFinally(source, spinnerActivated)

        		if (error.status == 401) {
        			usersettings.redirectToLogin("logout");
        		}

        		var msg = common.$translate.instant("STRCONST.PUBLIC.HDR_PRINT_LABEL") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + ": " + error.message;
        		logErr(msg, error);
        		throw error;
        	}
        	return queryObj;
        }

    	/**
		 * Prints multiple labels to file.
		 * @param {Array of objects} [printDatas] - Array of printing data {labelId, objectRecid, labelPrinterId, labelCount = 1, options = 0}.
		 * @returns {Object}
		 */
		function printLabelToFileMultiple(printDatas) {
			// Default values
			angular.forEach(printDatas, function (printData) {
				printData.labelCount = printData.labelCount | 1;
				printData.options = printData.options | 0;
			});

			var source = 'printLabelToFileMultiple';
			var spinnerActivated = spinner.spinnerShow(source);

			var queryObj = EntityQuery
				.from('printLabelToFileMultiple')
				.using(commonManager)
				.withParameters({
					printDatasJson: JSON.stringify(printDatas)
				})
				.execute(printingSucceeded, printingFailed);

			function printingSucceeded(result) {
				logSuccess(common.$translate.instant("STRCONST.PUBLIC.HDR_PRINT_LABEL") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + ".", result, true);
				spinner.queryFinally(source, spinnerActivated)
			}

			function printingFailed(error) {
				spinner.queryFinally(source, spinnerActivated)

				if (error.status == 401) {
					usersettings.redirectToLogin("logout");
				}

				var msg = common.$translate.instant("STRCONST.PUBLIC.HDR_PRINT_LABEL") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + ": " + error.message;
				logErr(msg, error);
				throw error;
			}
			return queryObj;
		}

		/**
		 * Rejects changes for given entity or if parameter not given all entities for the manager.
		 * @param {object} [entity] - Entity whose changes are rejected. If not given, changes of all entities are rejected.
		 */
		function rejectChanges(entity) {
			if (entity) {
				if (entity.entityAspect) entity.entityAspect.rejectChanges();
			} else {
				commonManager.rejectChanges();
			}
			_hasChanges = false;			
			resolveHasChanges();
		}

		/**
		 * Calls report launcher method and returns object with report contents, content type and file name.
         * @param {number} formId - Id of form where the report command is found in.
         * @param {string} commandId - Report command id.
		 * @param {JSON[]} parameters - Data parameters (like recId, table name etc.) passed to report class.
		 * @returns {Object} Object with report contents, content type and file name
		 */
		function reportLauncher(formId, commandId, parameters) {
			spinner.spinnerShow();
			var reportObj;

			reportObj = EntityQuery
				.from('ReportLauncher')
				.using(commonManager)
                .withParameters({
                    formId: formId,
                    commandId: commandId,
					parameters: parameters
				})
				.noTracking()
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data && data.results && data.httpResponse) {
						var fileName = data.httpResponse.getHeaders()['content-disposition'];
						fileName = fileName.split('filename=')[1] || 'report.pdf';
						var docObject = {
							content: data.results,
							contentType: data.httpResponse.getHeaders()['content-type'] || 'application/pdf',
							fileName: fileName
						};
						return docObject;
					}
				});

			function querySucceeded(data) {
				spinner.spinnerHide();
				log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_REPORT_GENERATION") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
			}

			function queryFailed(data) {
				spinner.spinnerHide();
				usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_REPORT_GENERATION") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + ': ' + data.message, data);
			}

			return $q.when(reportObj);
		}

		function resolveHasChanges() {
			if (!_hasChanges) {
				_hasChanges = commonManager.getChanges('Text').length > 0;
			}
			if (!_hasChanges) {
				_hasChanges = commonManager.getChanges('Favourite').length > 0;
			}
			if (!_hasChanges) {
				_hasChanges = commonManager.getChanges('Comment').length > 0;
			}
			if (!_hasChanges) {
				_hasChanges = commonManager.getChanges('CheckList').length > 0;
			}
			if (!_hasChanges) {
				_hasChanges = commonManager.getChanges('WorkReport').length > 0;
			}
			if (!_hasChanges) {
			    _hasChanges = commonManager.getChanges('SubstituteUser').length > 0;
			}
			common.setBeforeUnloadValue(_hasChanges, 'CDC');
			return _hasChanges;
		}

		/**
		 * Saves changes.
		 * @param {Optional: entity or entities to be saved. If not given, all entities.} entity
		 * @param {Optional: showSpinner if false, the spinner is not shown.} showSpinner
		 * @returns {} 
		 */
		function saveChanges(target, showSpinner) {
			var entityArray = common.asArray(target);
			
			if (resolveHasChanges()) {
				var source = 'commonDataContext.saveChanges';
				var spinnerActivated = spinner.spinnerShow(source, showSpinner, entityArray ? entityArray : commonManager.getChanges());
				return commonManager.saveChanges(entityArray)
					.then(saveSucceeded)
					.fail(saveFailed);
			} else {
				// Our change tracking has somehow failed (or we've enabled the save button also in unchanged state)
				log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_NOTHING_TO_SAVE"));
				return $q.when(null);
			};

			function saveSucceeded(result) {
				spinner.spinnerHide(source, spinnerActivated);
				logSuccess(common.$translate.instant("STRCONST.LEANPORTAL.TXT_SAVE") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + ".", result, true);
				_hasChanges = false;
				resolveHasChanges();
				return result;
			}

			function saveFailed(error) {
				spinner.spinnerHide(source, spinnerActivated);
				usersettings.handleSaveError(common.$translate.instant("STRCONST.LEANPORTAL.TXT_SAVE") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE"), error);
				resolveHasChanges();
				throw error;
			}
        }

        /**
         * Saves document object and/or file to database.
         * @param {object} doc - Document object column values to be stored to database.
         * @param {string} doc.selWebFileLink - Operation user has selected. Currently supported operations are FILELINK and FILEDB.
         * @param {string} [doc.docLocation] - Path to file when selWebFileLink is FILELINK.
         * @param {object} [existingDocument] - Existing document object entity.
         * @param {object} [file] - Actual file to be stored to database.
         * @param {object} [parent] - Parent object of document object.
         * @param {callBackFunction} [onContentChangedCallback] - Call back function when content has changed.
         * @param {callBackFunction} [onContentEditedCallback] - Call back function when content has been edited.
         * @param {callBackFunction} [onCompletedCallback] - Call back function when operation is completed.
         * @param {object} [docSubject] - DOCSUBJECT from getDocSaveEnums, needed for doc saving etc.
         * @returns {object} Returns promise.
         * 
         * @callback {callBackFunction}
         */
        function saveDocument(doc, existingDocument, file, parent, onContentChangedCallback, onContentEditedCallback, onCompletedCallback, docSubject) {

            /* Helper function to gather relevant information needed for HttpPost. */
            function generateHttpPostParams(doc, existingDocument, parent) {
                var parentObj;
				var objectRecId;
				if (existingDocument) {
					parentObj = existingDocument;
					var recId = parentObj.recId;
					objectRecId = parentObj.objectRecId;
				}
				else if (parent && parent.objRecId) {
					// TODO: Check existence of ctrl.parent.objRecId and raise error if does not exist. Then quit.
					parentObj = parent.objRecId();
					objectRecId = parentObj.recId;
				}
				else {
					var parent = common.getCommonDataParents('Document', parent)
					if (parent && parent[0]) {
						parentObj = parent[0];
						objectRecId = parentObj.recId;
					}
				}
                var objectHeader = parentObj.objectHeader; 
                var objectRow = parentObj.objectRow;
                var objectType = parentObj.objectType;
                var postParams = {};
                /*Note that this postParams does not include selected file param as it might not been selected, it's added to postParams below where file handling routine happens.*/
                postParams = {
                    objectHeader: objectHeader,
                    objectRecId: objectRecId,
					recId: recId,					
                    objectRow: objectRow,
                    objectType: objectType,
                    title: doc.title,
                    docLocation: doc.docLocation,
                    webFileLink: doc.selWebFileLink,
                    usePrint: doc.selUsePrint,
                    docStatus: doc.selDocStatus,
                    language: doc.selLanguage,
                    copyClass: doc.selCopyClass,
                    copyType: doc.selCopyType,
                    application: doc.selApplication,
                    subject: doc.selSubject,
					subjectName: doc.selSubjectName,
					pos: doc.selPos,
                };
                return postParams;
            }

            /* Function which is responsible of sending data to endpoint and informing user about operation after server-side action. */
			function sendHttpPost(params, base64String, doc, existingDocument, onContentChangedCallback, onContentEditedCallback, onCompletedCallback, readerPromise) {
				var defer = $q.defer();
				var postData = base64String ? base64String : '';
				/*Post-message url, change this to post data to different endpoint*/
				var postUrl = "Breeze/Document/SaveDocument";
				var result = $http({
					method: 'Post',
					url: postUrl,
					data: postData,
					params: params
				}).then(function (message, status) {
					/*Content changes on create and delete operations (not when updating)*/
					if (!existingDocument) {
                        onContentChangedCallback ? onContentChangedCallback() : null;
					}
					else {
						/*This part is added because subjectName didn't get it's real new value through the same logic as the rest of the variables. This part should at some point be refactored*/
						var subjectName = "";
						for (var i = 0; i < docSubject.length; i++) {
							if (docSubject[i].id == doc.selSubject) {
								subjectName = docSubject[i].nabbr;
							}
						}

						/*Update changed doc params to existingDocument, pass it on callback which then will be updated filteredImages arr in image.js*/
						var oldDoc = existingDocument;
						oldDoc.language = doc.selLanguage;
                        oldDoc.copyClass = doc.selCopyClass;
                        oldDoc.copyType = doc.selCopyType;
                        oldDoc.application = doc.selApplication;
                        oldDoc.title = doc.title;
						oldDoc.subject = doc.selSubject;
						oldDoc.subjectName = subjectName;
						oldDoc.status = doc.selDocStatus;
						oldDoc.usePrint = doc.selUsePrint;
						common.logger.logSuccess(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DOC_ACTION") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + ".", false, false, true);
                        onContentEditedCallback ? onContentEditedCallback({ doc: oldDoc }) : null;
					}
					onCompletedCallback ? onCompletedCallback() : null;
					defer.resolve(true);
					readerPromise.resolve();
					}).catch(function (message, status) {
						var fileName = null;
						if (message && message.config && message.config.params && message.config.params.selFileName) {
							fileName = message.config.params.selFileName;
						}
						common.logger.logError(message ? message.data + ': ' + fileName : 'No error message available', false, false, true);
						defer.reject(message);
						readerPromise.reject();
				});
				return $q.when(defer.promise).then(function () {
					return true;
				}).catch(function () {
					return true;
				});
            }

            // The actual function begins.
			var promises = [];
            var readerPromise = common.$q.defer();
            var outerPromise = common.$q.defer();
            var errors = false;
			promises.push(readerPromise.promise);
            if (!existingDocument) {
                if (!doc.selWebFileLink) {
                    logErr(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DOC_CHOOSE_OPERATION") + ".", false, false, true);
                    readerPromise.reject(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DOC_CHOOSE_OPERATION") + ".");
                    errors = true;
                }
                if (!doc.docLocation && (doc.selWebFileLink === 'FILELINK')) {
                    logErr(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DOC_NO_FILELINK") + ".", false, false, true);
                    readerPromise.reject(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DOC_NO_FILELINK") + ".");
                    errors = true;
                }
                if (!file && (doc.selWebFileLink === 'FILEDB')) {
                    logErr(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DOC_CHOOSE_FILEDB") + ".", false, false, true);
                    readerPromise.reject(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DOC_CHOOSE_FILEDB") + ".");
                    errors = true;
                }
                /*Case: user has selected file*/
                if (!errors && file) {
                    var base64String;
                    var selFileName = file.name;

                    var reader = new FileReader();
                    /*Starts the filereader*/
                    reader.readAsArrayBuffer(file);

                    reader.onload = function (readerEvent) {
                        var arrayBuffer = readerEvent.target.result;
                        /*Convert arrayBuffer into base64String, Note: construct binary string as segments so IE doesn't give 'out of stack space' -error*/
                        var binary = "";
                        var bytes = new Uint8Array(arrayBuffer);
                        var length = bytes.byteLength;
                        for (var i = 0; i < length; i++) {
                            binary += String.fromCharCode(bytes[i]);
                        }
                        base64String = btoa(binary);
                    };
                    reader.onloadend = function (e) {
                        if (base64String) {
                            /*Note: empty location field if user has fill it before changing to database-saving*/
                            delete doc.docLocation;
                            var params = generateHttpPostParams(doc, existingDocument, parent);
                            if (params) {
                                params.selFileName = selFileName;
								promises.push(sendHttpPost(params, base64String, doc, existingDocument, onContentChangedCallback, onContentEditedCallback, onCompletedCallback, readerPromise));
                            }
                        }
                    };
                }
                /*Case: No file is selected, check if user intends to save only link of file*/
                if (!file && doc.selWebFileLink === 'FILELINK') {
                    var params = generateHttpPostParams(doc, existingDocument, parent);
                    if (params) {
						promises.push(sendHttpPost(params, null, doc, existingDocument, onContentChangedCallback, onContentEditedCallback, onCompletedCallback, readerPromise));
                    }
                }
            }
            /*Case: Update existing document values*/
            if (existingDocument) {
                var paramsED = generateHttpPostParams(doc, existingDocument, parent);
                if (paramsED) {
                    paramsED.selFileName = existingDocument.fileName;
					promises.push(sendHttpPost(paramsED, null, doc, existingDocument, onContentChangedCallback, onContentEditedCallback, onCompletedCallback, readerPromise));
                }
            }

            $q.all(promises).then(function (results) {
                outerPromise.resolve(results);
            })
                .catch(function (err) {
                    outerPromise.reject(err);
                });
            return outerPromise.promise;
        }

        /**
         * Saves document object and/or multiple files to database.
         * @param {object} doc - Document object column values to be stored to database.
         * @param {string} doc.selWebFileLink - Operation user has selected. Currently supported operations are FILELINK and FILEDB.
         * @param {string} [doc.docLocation] - Path to file when selWebFileLink is FILELINK.
         * @param {object} [existingDocument] - Existing document object entity.
         * @param {object[]} [files] - Array of files to be stored to database.
         * @param {object} [parent] - Parent object of document object.
         * @param {callBackFunction} [onContentChangedCallback] - Call back function when content has changed.
         * @param {callBackFunction} [onContentEditedCallback] - Call back function when content has been edited.
         * @param {callBackFunction} [onCompletedCallback] - Call back function when operation is completed.
         * @param {object} [docSubjectEnum] - DOCSUBJECT from getDocSaveEnums, needed for doc saving etc.
         * @returns {object} Returns promise.
         * 
         * @callback {callBackFunction}
         */
        function saveDocumentMultiple(doc, existingDocument, files, parent, onContentChangedCallback, onContentEditedCallback, onCompletedCallback, docSubjectEnum) {

            // Internal helper function.
            function handleFile(file) {
                return saveDocument(doc, existingDocument, file, parent, null, onContentEditedCallback, onCompletedCallback, docSubjectEnum);
            }
			// Internal helper function.
			function afterDocSaveComplete() {
				common.logger.logSuccess(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DOC_ACTION") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + ".", false, false, true);
				spinner.spinnerHide(source, spinnerActivated);
				if (existingDocument) {
					onContentEditedCallback ? onContentEditedCallback() : null;
				}
				else {
					onContentChangedCallback ? onContentChangedCallback({recId: parent.recId}) : null;
				}
			}

            // Actual code begins here.
            var source = 'saveDocumentMultiple';
            var spinnerActivated = spinner.spinnerShow(source);
            var allFilesHandledPromise = [];
            /*Multiple files selected from fileread-directive
             *Start by saving one file first and after that all the rest
             *This is because saving is asynchronous and pl/sql routine can't generate correct textIds
             *As it might be saving another file before first one finished.
             *This happens in cases where no previous docs is attached to father object.*/
			if (files && files.length > 1 && !doc.docLocation) {
				handleFile(files[0]).then(function () {
					for (var i = 1; i < files.length; i++) {
						allFilesHandledPromise.push(handleFile(files[i]));
					}
					common.$q.all(allFilesHandledPromise).then(function () {
						afterDocSaveComplete();
					}).catch(function () {
						afterDocSaveComplete();
					});
				});
			}
			/*Only one file selected, normal save routine*/
			else if (files && files[0] && files.length === 1 && !doc.docLocation) {
				allFilesHandledPromise.push(handleFile(files[0]));
				common.$q.all(allFilesHandledPromise).then(function () {
					afterDocSaveComplete();
				}).catch(function () {
					spinner.spinnerHide(source, spinnerActivated);
				});
			}			
            /*Modify existing file or file path */
            else if (existingDocument || doc.docLocation) {
				allFilesHandledPromise.push(handleFile());
				common.$q.all(allFilesHandledPromise).then(function () {
					afterDocSaveComplete();
				}).catch(function () {
					spinner.spinnerHide(source, spinnerActivated);
				});
            }
            else {
                if (doc.selWebFileLink === 'FILELINK') {
                    logErr(common.$translate.instant("STRCONST.ERROR.10002").replace('%1', common.$translate.instant("STRCONST.PUBLIC.TXT_LOCATION")), null, "dfxSaveDocument.js", true);
                }
                else {
                    logErr(common.$translate.instant("STRCONST.ERROR.10002").replace('%1', common.$translate.instant("STRCONST.PUBLIC.TXT_FILE")), null, "dfxSaveDocument.js", true);
                }
                spinner.spinnerHide(source, spinnerActivated);
            }
        }

    	/**
    	 * Sets object to favourite.
    	 * @param {} favObj 
    	 * @param {} objectType 
    	 * @returns {} 
    	 */
		function setFavourite(favObj, objectType)
		{
			var favEnt;
			if (favObj)
			{
				favEnt = getFavEnt(favObj, objectType);
				favEnt.isFavourite = favObj.f;
				favEnt.favouriteDescription = favObj.al;
			}
			return favEnt != null ? favEnt.id : null;
		}

		function setFavDescription(favObj, objectType)
		{
			var favEnt;
			if (favObj)
			{
				favEnt = getFavEnt(favObj, objectType);
				favEnt.favouriteDescription = favObj.al;
			}
			return favEnt != null ? favEnt.id : null;
		}

		function getFavEnt(favObj, objectType)
		{
			var favEnt, favEntKey;
			if (favObj)
			{
				favEntKey = favObj.fKey;
				if (favEntKey)
				{
					favEnt = commonManager.getEntityByKey('Favourite', favEntKey);
					if (favEnt && favEnt.entityState == breeze.EntityState.Detached)
					{
						favEnt = null;
					}
				}
				if (!favEnt)
				{
					favEnt = commonManager.createEntity('Favourite', { id: breeze.core.getUuid() });
					favEnt.objectType = objectType;
					favEnt.objectRecId = favObj.recId;
				}
			}
			return favEnt;
		}

		function clearFavourites() {
			var favs = commonManager.getEntities('Favourite');

			if (favs && favs.length > 0) {
				favs.forEach(function (fav) { fav.entityAspect.setDetached(); });
			}
			resolveHasChanges();
		}

    	/**
		 * Gets data for charts from database.
		 * @returns {} 
		 */
		function getChartsFromQ(formId, formVersion, sort, extraRestrictions, parameters, filterString, axisXColumn) {
			var source = 'getCharts';
			var spinnerActivated = spinner.spinnerShow(source);
			var queryPromise = EntityQuery
				.from('Charts')
				.using(commonManager)
				.withParameters({
					formId: formId,
					formVersion: formVersion,
					sort: sort,
					extraRestrictions: extraRestrictions,
					parameters: parameters,
					filterString: filterString,
					axisXColumn: axisXColumn
				})
				.noTracking()
				.execute(querySucceeded, queryFailed)			// This is needed unless we want breeze to keep tracking of document objects...
				.then(function (data) {
					if (data) {
						// The breeze query data is really in data.results member...
						// TODO: figure out if these services should return breeze query data or only the results...
						return data.results;
					}
				});


			function querySucceeded(data) {
				log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_CHART_DATA_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
				spinner.queryFinally(source, spinnerActivated)
			}

			function queryFailed(data) {
				spinner.queryFinally(source, spinnerActivated)
				usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_CHART_DATA_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
			}

			return queryPromise;
		}
		function getCharts(formId, formVersion, sort, extraRestrictions, parameters, filterString, axisXColumn) {
			var lst = getChartsFromQ(formId, formVersion, sort, extraRestrictions, parameters, filterString, axisXColumn);
			return $q.when(lst);
		}

		function getCheckListsFromQ(checkListId, requestedByUi, recId, formId, checkListIdList) {
			var source = 'getCheckLists';
			var spinnerActivated = spinner.spinnerShow(source, requestedByUi);
			var queryPromise = EntityQuery
				.from('CheckList')
				.withParameters({
					checkListId: checkListId,
					recId: recId,
					formId: formId,
					checkListIdList: checkListIdList
				})
				.using(commonManager)
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
               		if (data) {
               			return data.results;
               		}
				});
			function querySucceeded(data) {
				log(common.$translate.instant("STRCONST.LEANPORTAL.TXT_CHECKLISTQUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
				spinner.queryFinally(source, spinnerActivated)
			}

			function queryFailed(data) {
            	spinner.queryFinally(source, spinnerActivated)
            	usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.TXT_CHECKLISTQUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
			}
			return queryPromise;
		}

		function getCheckLists(checkListId, requestedByUi, recId, formId, checkListIdList) {
			var lst = [];
			lst = getCheckListsFromQ(checkListId, requestedByUi, recId, formId, checkListIdList);
			return $q.when(lst);
		}

		function getCheckListLog(checkListId) {
			var lst = [];
			lst = getCheckListLogFromQ(checkListId);
			return $q.when(lst);
		}

		function getCheckListLogFromQ(checkListId) {
			var source = 'getCheckListLog';
        	var spinnerActivated = spinner.spinnerShow(source);
			var queryPromise = EntityQuery
				.from('CheckListWithLog')
				.withParameters({ checkListId: checkListId })
				.using(commonManager)
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						return data.results;
					}
				});

			function querySucceeded(data) {
				log(common.$translate.instant("STRCONST.LEANPORTAL.TXT_CHECKLISTQUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
        		spinner.queryFinally(source, spinnerActivated)
			}
			function queryFailed(data) {
				spinner.queryFinally(source, spinnerActivated)
				usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.TXT_CHECKLISTQUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data.status);
			}
			return queryPromise;
		}

		function getCheckListUsers(recid, formId, formVersion, searchString) {
			var lst = getCheckListUsersFromQ(recid, formId, formVersion, searchString);
			return $q.when(lst);
		}

		function getCheckListUsersFromQ(recid, formId, formVersion, searchString) {
			var source = 'getCheckListUsers';
			var spinnerActivated = spinner.spinnerShow(source);
			var queryPromise = EntityQuery
				.from('GetCheckListUsers')
				.withParameters({ recid: recid, formId: formId, formVersion: formVersion, searchString: searchString })
				.using(commonManager)
                .noTracking()
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						return data.results;
					}
				});
			function querySucceeded(data) {
				log(common.$translate.instant("STRCONST.LEANPORTAL.TXT_USERQUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
				spinner.queryFinally(source, spinnerActivated)
			}
			function queryFailed(data) {
				spinner.queryFinally(source, spinnerActivated)
				usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.TXT_USERQUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data.status);
			}
			return queryPromise;
		}

		function getCostCentersFromQ(searchString) {
			var source = 'getCostCenters';
			var spinnerActivated = spinner.spinnerShow(source);
			var queryPromise = EntityQuery
               .from('costCenter')
               .using(commonManager)
				.withParameters({searchString: searchString})
               .noTracking()
               .execute(querySucceeded, queryFailed)
               .then(function (data)
               {
               	if (data)
               	{
               		return data.results;
               	}
               });
			function querySucceeded(data)
			{
				//log(strConst.LEANPORTAL$TXT_SALESLEADQUERY + " " + strConst.LEANPORTAL$TXT_SUCCESS + '.', data, true);
				spinner.queryFinally(source, spinnerActivated)
			}

			function queryFailed(data)
			{
				spinner.queryFinally(source, spinnerActivated)
				usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.TXT_SALESLEADQUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
			}
			return queryPromise;
		}

		function getCostCenters(searchString)
		{
			var lst = [];
			lst = getCostCentersFromQ(searchString);
			return $q.when(lst);
		}

        function getDefaultLabelFromQ(params) {
            var source = 'getDefaultLabel';
            var spinnerActivated = spinner.spinnerShow(source);
            var queryPromise = EntityQuery
                .from('getDefaultLabel')
                .using(commonManager)
                .withParameters({
                    application: params.application,
                    function: params.function,
                    subFunction: params.subFunction,
                    objectId1: params.objectId1,
                    objectId2: params.objectId2,
                    objectId3: params.objectId3,
                    objectId4: params.objectId4
                })
                .noTracking()
                .execute(querySucceeded, queryFailed)
                .then(function (data) {
                    if (data) {
                        return data.results;
                    }
                });
            function querySucceeded(data) {
                log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DEFAULT_LABEL_QUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + ".", data, true);
                spinner.queryFinally(source, spinnerActivated);
            }

            function queryFailed(data) {
                spinner.queryFinally(source, spinnerActivated);
                usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DEFAULT_LABEL_QUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
            }
            return queryPromise;
        }

        /**
    	 * @class LeanPortalSPA.commonDatacontext.getDefaultLabel
         * @memberOf LeanPortalSPA.commonDatacontext
		 * @description Get default label ID - id for LABEL_PRINTING.
         *
         * @param {object} params Parameters for getDefaultLabel API
         * application:	Application this is called from: MANUF_PORTAL, MATFLOW.
         * function: 	Function this is called from: OPER_START, OPER_COMPLETE, RECEIVE, STOCKFILLREQUESTS, SENDDELIVERY, ADDQUALITYFAILURE.
         * subFunction:	Subfunction i.e detailed place/label to print the label from.
         * objectId1: 	Defined by function: for MANUF_PORTAL this is RESID, for MATFLOW this is STOCK_AREA.
         * objectId2: 	Defined by function: for MANUF_PORTAL this is WORKID, for MATFLOW this is PICK_EQUIPMENTID.
         * objectId3: 	Defined by function: for MANUF_PORTAL this is OPERATID.
         * objectId4:  	Defined by function: for MANUF_PORTAL this is not in use yet.
         * options: 	Possible options as number.
		 * @returns {callback} callback returns 
         *  LABEL_PRINTING id if found
         *  null/undefined if not found or some other error.
		 */
        function getDefaultLabel(params) {
            var lst = [];
            lst = getDefaultLabelFromQ(params);
            return $q.when(lst);
        }

        function getDefaultLabelPrinterFromQ(params) {
            var source = 'getDefaultLabelPrinter';
            var spinnerActivated = spinner.spinnerShow(source);
            var queryPromise = EntityQuery
                .from('getDefaultLabelPrinter')
                .using(commonManager)
                .withParameters({
                    application: params.application,
                    function: params.function,
                    subFunction: params.subFunction,
                    labelId: params.labelId,
                    objectId1: params.objectId1,
                    objectId2: params.objectId2,
                    objectId3: params.objectId3,
                    objectId4: params.objectId4
                })
                .noTracking()
                .execute(querySucceeded, queryFailed)
                .then(function (data) {
                    if (data) {
                        return data.results;
                    }
                });
            function querySucceeded(data) {
                log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DEFAULT_LABEL_PRINTER_QUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + ".", data, true);
                spinner.queryFinally(source, spinnerActivated);
            }

            function queryFailed(data) {
                spinner.queryFinally(source, spinnerActivated);
                usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DEFAULT_LABEL_PRINTER_QUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
            }
            return queryPromise;
        }

        /**
    	 * @class LeanPortalSPA.commonDatacontext.getDefaultLabelPrinter
         * @memberOf LeanPortalSPA.commonDatacontext
		 * @description Get default printer ID - id for LABEL_PRINTERS.
         *
         * @param {object} params Parameters for getDefaultLabelPrinter API
         * application:	Application this is called from: MANUF_PORTAL, MATFLOW.
         * function: 	Function this is called from: OPER_START, OPER_COMPLETE, RECEIVE, STOCKFILLREQUESTS, SENDDELIVERY, ADDQUALITYFAILURE.
         * subFunction:	Subfunction i.e detailed place/label to print the label from.
         * labelId: 	Label id to print (can be left empty).
         * objectId1: 	Defined by function: for MANUF_PORTAL this is RESID, for MATFLOW this is STOCK_AREA.
         * objectId2: 	Defined by function: for MANUF_PORTAL this is WORKID, for MATFLOW this is PICK_EQUIPMENTID.
         * objectId3: 	Defined by function: for MANUF_PORTAL this is OPERATID.
         * objectId4:  	Defined by function: for MANUF_PORTAL this is not in use yet.
         * options: 	Possible options as number.
		 * @returns {callback} callback returns 
         *  LABEL_PRINTERS id if found 
         *  null/undefined if not found or some other error.
		 */
        function getDefaultLabelPrinter(params) {
            var lst = [];
            lst = getDefaultLabelPrinterFromQ(params);
            return $q.when(lst);
        }

		function getDepartmentsFromQ(searchString){
			var source = 'getDepartments';
			var spinnerActivated = spinner.spinnerShow(source);
			var queryPromise = EntityQuery
				.from('Department')
				.withParameters({ searchString: searchString })
				.using(commonManager)
				.noTracking()
				.execute(querySucceeded, queryFailed)
                .then(function (data) {
               		if (data)
               		{
               			return data.results;
               		}
				});
			function querySucceeded(data)
			{
				log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DEP_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
				spinner.queryFinally(source, spinnerActivated)
			}

			function queryFailed(data)
			{
				spinner.queryFinally(source, spinnerActivated)
				usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DEP_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
			}

			return queryPromise;
		}

		function getDepartments(searchString){
			var lst = [];
			lst = getDepartmentsFromQ(searchString);
			return $q.when(lst);
		}

		function getDocumentDataFromQ(request, id, responseType, asBase64, requestedByUi) {
			var source = 'getDocumentData';
			var spinnerActivated = spinner.spinnerShow(source, requestedByUi);
			var docObj;

			// Initial implementation for server based data
			docObj = EntityQuery
				.from('DocumentData')
				.using(commonManager)
				.withParameters({ request: request, id: id, responseType: responseType, asBase64: asBase64 })
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						// The breeze query data is really in data.results member...
						// TODO: figure out if these services should return breeze query data or only the results...
						return data.results;
					}
				});

			function querySucceeded(data) {
				spinner.queryFinally(source, spinnerActivated)
				log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DOCUMENT_QUERY") + " (id = " + id + ") " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
			}

			function queryFailed(data) {
				spinner.queryFinally(source, spinnerActivated)
				usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DOCUMENT_QUERY") + " (id = " + id + ") " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
			}

			return docObj;
		}

		function getDocumentData(request, id, responseType, asBase64, requestedByUi) {
			asBase64 = (asBase64 == undefined || asBase64 == null ? true : asBase64);
			var obj = [];
			/*var lsid = usersettings.getLocStoKey('DocumentData', false, true);
			if (common.$rootScope.isWorkingLocally && localStorage && localStorage.getItem(lsid)) {
				lst = JSON.parse(localStorage.getItem(lsid));
			}
			else {*/
			obj = getDocumentDataFromQ(request, id, responseType, asBase64, requestedByUi);
			//}
			return $q.when(obj);
		}

		function getDocumentsFromQ(docLinkId, ownerRecIds, offset, size, docListOption, docDefaultsKey, language) {
			var source = 'getDocuments';
			var spinnerActivated = spinner.spinnerShow(source);
			var docObj;

			// Initial implementation for server based data
			docObj = EntityQuery
				.from('Documents')
				.using(commonManager)
				.withParameters({
					docLinkId: docLinkId,
					objRecIdsJson: toOwnerRecIdsJson(ownerRecIds),
					offset: offset,
					size: size,
					docListOption: docListOption,
					docDefaultsKey,
					language
				})
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						// The breeze query data is really in data.results member...
						// TODO: figure out if these services should return breeze query data or only the results...
						return data.results;
					}
				});

			function querySucceeded(data) {
				spinner.queryFinally(source, spinnerActivated)
				log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DOCUMENT_QUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
			}

			function queryFailed(data) {
				spinner.queryFinally(source, spinnerActivated)
				usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DOCUMENT_QUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
			}

			return docObj;
		}

		function getDocuments(docLinkId, ownerRecIds, offset, size, docListOption, docDefaultsKey, language) {
			var lst = [];
			var lsid = usersettings.getLocStoKey('Documents', false, true);
			if (common.$rootScope.isWorkingLocally && localStorage && localStorage.getItem(lsid)) {
				lst = JSON.parse(localStorage.getItem(lsid));
			}
			else {
				lst = getDocumentsFromQ(docLinkId, ownerRecIds, offset, size, docListOption, docDefaultsKey, language);
			}
			return $q.when(lst);
		}

		/**
		 * Gets external file contents.
		 * @param {object} docum - Document object.
		 * @param {function} callback - Function to be called after completion.
		 * @param {boolean} allowCors - Cross origin request is allowed.
		 */
		function getExternalFileData(docum, callback, allowCors) {

			var ajax = function (src, responseType, callback) {
				var currPath = common.$location.$$protocol + '://' + common.$location.$$host;
				// To avoid cross origin errors get from current host only.
				if (currPath === src.substr(0, currPath.length) || allowCors) {
					log('Try to open connection.');
					var xhr = new XMLHttpRequest();
					xhr.open('GET', src, true);
					log('Connection opened.');
					xhr.responseType = responseType || 'text';
					// Get status of the event - cannot use try catch as cross origin error is not thrown, just returned.
					xhr.onreadystatechange = function () {
						if (xhr.readyState === 4) { // If complete.
							if (xhr.status === 200) {
								// Success.
							} else {
								log('Error in onReadyStateChange event. xhr.status: ' + xhr.status);
								callback();
							}
						}
					}
					xhr.onload = function (err) {
						if (this.status === 200) {
							callback(this.response);
						}
						else {
							log('Error in onLoad event. this.status: ' + this.status + ', err: ' + err);
						}
					};
					xhr.send();
				} else {
					log('Cross origin error. Current url = ' + currPath + ', external url = ' + src);
					callback();
				}
			};

			var src = docum.url;
			ajax(src, 'blob', function (response) {
				if (response) {
					var reader = new FileReader();
					reader.onloadend = function (e) {
						var content = e.target.result;
						docum.content = content.split(",")[1];
						docum.contentType = content.split(",")[0].split(":")[1].split(";")[0];
						docum.hasFile = true;
						callback(docum);
					};
					reader.readAsDataURL(response);
					docum.fileName = src.substring(src.lastIndexOf('/') + 1);
				} else {
					log('No response from ajax call.');
					callback(docum);
				}
			});
		}

    	/**
		 * Gets document from local database.
		 * @param {DLG_TEXT table recid of the document to be fetched.} recId 
		 * @param {What type of return value is requested: OBJECT = whole object, CONTENTASBASE64 = content in base64 format, CONTENT = content} returnType
		 * @param {Function to be called after get.} callBack 
		 * @returns {(As parameter of callBack:) if not found or some other error returns null/undefined, if found see returnType.} 
		 */
		function getDocumentFromLocalDatabase(recId, returnType, callBack) {
			var localDb = usersettings.localDb;
			var dbEntryKeyTable = 'DLG_TEXT';
			var dbEntryKey = dbEntryKeyTable + '_' + recId;

			localDb.get(dbEntryKey, { attachments: true }, function (error, response) {
				if (error) {
					//usersettings.errHandler(strConst.PUBLIC$ERR_SHELLEXECUTE_2);
					callBack();
				} else {
					if (returnType.toUpperCase() === 'OBJECT') {
						callBack(response);
					}
					else if (response.hasFile && response._attachments && response.fileName && response.contentType) {
						if (returnType.toUpperCase() === 'CONTENTASBASE64') {
							callBack('data:' + response.contentType + ';base64,' + response.content);
						} else {
							callBack(response.content);
						}
					} else {
						callBack(response.content);
					}
				}
			});
		}

    	/**
		 * Adds or updates document entry to local database.
		 * @param {Document to be added or updated} docum 
		 * @param {Function to be called after run} callback
		 * @returns {void} 
		 */
		function addOrUpdateDocumentInLocalDatabase(docum, callbackMain) {
			var localDb = usersettings.localDb;
			var dbEntryKeyTable = 'DLG_TEXT';
			var dbEntryKey;

			// Update document entry in local database. Does not matter if it has not been stored before.
			function updateDocum(documentEntry) {
				// Add or update document entry to database.
				localDb.put(documentEntry, function callback(err, result) {
					if (!err) {
						// log('Successfully posted a doc.');
						// If the document entry contains an attachment, store it to database.
						if (documentEntry.hasFile && documentEntry.fileName && documentEntry.content && documentEntry.contentType) {
							localDb.putAttachment(documentEntry._id, documentEntry.fileName, result.rev, documentEntry.content, documentEntry.contentType).then(function (result) {
								// log('Attachment added.');
								callbackMain ? callbackMain() : null;
							}).catch(function (err) {
								usersettings.errHandler(err.message + (err.reason ? ': ' + err.reason : null), err);
								callbackMain ? callbackMain() : null;
							});
						} else {
							callbackMain ? callbackMain() : null;
						}
					} else {
						usersettings.errHandler(err.message, err);
						callbackMain ? callbackMain() : null;
					}
				});

			}

			// Construct document object to be stored to database.
			if (docum != null && docum.recId != null) {
				dbEntryKey = dbEntryKeyTable + '_' + docum.recId;
				var documentEntry = {
					_id: dbEntryKey,
					_rev: null,
					title: docum.title,
					createStamp: docum.createStamp,
					updateStamp: docum.updateStamp,
					objectType: docum.objectType,
					objectRecId: docum.objectRecId,
					hasFile: docum.hasFile,
					fileName: docum.fileName,
					contentType: docum.contentType,
					content: docum.content,
					url: docum.url
				};

				// Check if document is stored already. If it is, get revision.
				localDb.get(dbEntryKey).then(function (doc) {
					documentEntry._rev = doc._rev;
					updateDocum(documentEntry);
				}).catch(function (err) {
					if (err.status === 404) { // Document not found, add it without revision.
						updateDocum(documentEntry);
					} else { // Some other/real error.
						usersettings.errHandler(err.message, err);
						callbackMain ? callbackMain() : null;
					}
				});
			}
		};

    	/**
        * Adds or updates object entry to local database.
        * @param {unique identifier of an object, RECID, etc..} 
        * @param {Object to be added or updated} 
        * @returns {void} 
        */
		function addOrUpdateObjInLocalDatabase(dbEntryKey, object) {
			var localDb = usersettings.localDb;
			var entryKey = dbEntryKey;
			/*Construct object to be stored to database.*/
			if (object != null && entryKey != null) {
				var objEntry = {
					_id: entryKey,
					_rev: null,
					object: object
				};
				/* Check if object is stored already. If it is, get revision.*/
				localDb.get(entryKey, 'OBJECT', function (err, obj) {
					if (err && err.status === 404) {
						/*Object not found, put without revision*/
						return localDb.put(objEntry, function (err, response) {
							if (err) { usersettings.errHandler(err.message, err); }
							// handle response
						});
					}
					if (obj) {
						objEntry._rev = obj._rev;
						localDb.put(objEntry, function (err, response) {
							if (err) { usersettings.errHandler(err.message, err); }
							// handle response
						});
					}
				});
			}
		}

    	/**
		 * Gets object from local database.
		 * @param {dbEntryKey of the object to be fetched.} dbEntryKey 
		 * @param {What type of return value is requested: OBJECT = whole object} returnType
		 * @param {Function to be called after get.} callBack 
		 * @returns {(As parameter of callBack:) if not found or some other error returns null/undefined, if found see returnType.} 
		 */
		function getObjFromLocalDatabase(dbEntryKey, returnType, callBack) {
			var localDb = usersettings.localDb;
			localDb.get(dbEntryKey, function (error, response) {
				if (error) {
					//usersettings.errHandler(strConst.PUBLIC$ERR_SHELLEXECUTE_2);
					callBack();
				} else {
					if (returnType.toUpperCase() === 'OBJECT') {
						callBack(response);
					}
					else {
						callBack(response.content);
					}
				}
			});
		}
    }

	/**
	 * The BLC is intrested only from objectType and recId
	 */
    function toOwnerRecIdsJson(from) {
    	var ownerRecIds = [];
    	from.forEach(function (owner) {
    		ownerRecIds.push({
    			objectType: owner.objectType,
    			recId: owner.recId,
    			childObjectType: owner.childObjectType ? owner.childObjectType : ''
    		});
    	});
    	var objRecIdsJson = JSON.stringify(ownerRecIds);
    	return objRecIdsJson;
    }

	/**
	 * Returns Json object with objectType and ids.
	 * @param {} from 
     * @returns {} Json object
	 */
    function toOwnerIdsJson(from) {
    	if (from) {
    		var ownerIds = [];
    		from.forEach(function (owner) {
    			ownerIds.push({
    				objectType: owner.objectType,
    				objectId1: owner.objectId1,
    				objectId2: owner.objectId2,
    				objectId3: owner.objectId3
    			});
    		});
    		var objIdsJson = JSON.stringify(ownerIds);
    		return objIdsJson;
    	}
    	else return null;
    }
export default commonDatacontextModule;
