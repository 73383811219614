'use strict';
var sortColModule = angular.module('dir.dfxSortCol',[]);
sortColModule.directive('dfxSortCol', function () { //OBS: Deprecated, use dfxColumn instead.
	// Provides sorting for table column by clicking the header text.
	// Obs. The row population requires following orderBy definition
	//      The logic relies in the certain scope hierarchy, which is probably
	//		not the most robust solution!  
	//		E.g. current solution required some tweaking when ngIf is used!
	//		If we can obtain the correct scope in some better way, the  
	//		directive should be "fixed"! (KKo 8.7.2016)
	// ng-repeat="man in tasks | orderBy:sortType"
	// Optional: caller may have sortTypeDefault variables for default sorting, like ['itemId']. When reverse (descending) sorting is needed, precede value with minus sign, like ['-itemId'].
	// Optional: sorting can be defined multi-column by defining an array, like ['itemId','-startDate'].
	// Usage:
	// <th dfx-sort-col="workId">@StrConst.GetString("LEANPORTAL.COL_WORKID")</th>
	return {
		restrict: 'A', //E = element, A = attribute, C = class, M = comment 
		transclude: true,
		scope: true,
		controllerAs: 'ctrl',
		controller: ['$scope', 'common', function ($scope, common) {
			var ctrl = this;
			$scope.targetScope = getTargetScope($scope);

			function getTargetScope(scope) {
				if (!scope.$parent) {
					// Shouldn't happen!
					return scope;
				}
				// exit recursion when sortType isn't defined any more
				if (scope.$parent.sortType !== undefined) {
					return getTargetScope(scope.$parent);
				}
				return scope;
			}


			/**
			 * Click event handler for sortable column.
			 * @param {} event 
			 * @returns {} 
			 */
			ctrl.clicked = function (event) {
				var p = $scope.targetScope;
				// Multicolumn sorting.
				if (event.ctrlKey) {
					event.preventDefault();
					// If it is not a list, make it list.
					if (!Array.isArray(p.sortType)) p.sortType = [p.sortType];
					// If it is in the list, reverse list item.
					for (var i = 0; i < p.sortType.length; i++) {
						if (p.sortType[i] === $scope.sortId) {
							p.sortType[i] = '-' + $scope.sortId;
							return;
						}
						else if (p.sortType[i] === '-' + $scope.sortId) {
							p.sortType[i] = $scope.sortId;
							return;
						}
					}
					// If it is not included in the list, add it.
					p.sortType.push($scope.sortId);

				} else { // Single column sorting.
					if (ctrl.sortIcon() === 2) {
						p.sortType = p.sortTypeDefault.slice();
					} else if (ctrl.sortIcon() === 1) {
						p.sortType = ['-' + $scope.sortId];
					}
					else {
						p.sortType = [$scope.sortId];
					}
				}
			};

			/**
			 * Return int for sorting icon: 0 = no icon, 1 = normal/ascending, 2 = reverse/descending
			 * @returns {} 
			 */
			ctrl.sortIcon = function () {
				var p = $scope.targetScope;
				if (p.sortType) {
					if (p.sortType === $scope.sortId) return 1;
					for (var i = 0; i < p.sortType.length; i++) {
						if (p.sortType[i] === $scope.sortId) return 1;
						if (p.sortType[i] === '-' + $scope.sortId) return 2;
					}
				}
				return 0;
			};

			/**
			 * Returns column title.
			 * @returns {} 
			 */
			ctrl.title = function () {
				var p = $scope.targetScope;
				var hint = common.$translate.instant("STRCONST.LEANPORTAL.TXT_CLICK_TO_SORT_TOOLTIP") + '\n' + common.$translate.instant("STRCONST.LEANPORTAL.COL_GRP_SORT") + ': ';
				var pattern = /-/ig;
				return hint + p.sortType.toString().replace(pattern, '(' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_DESCENDING").toLowerCase() + ') '); // TODO: Use language constants for column names/object properties.
			}
		}],
		link: function ($scope, iElement, attrs) {
			$scope.sortId = attrs["dfxSortCol"];
		},
		template: require('./dfxSortCol.html')
	}
});
export default sortColModule