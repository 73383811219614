
    'use strict';
    var controllerId = 'checklistctrl';

    function getStatusEnumItem(list, status) {
    	var ret = null;
    	list.forEach(function (enumItem) {
    		if (enumItem.id == status) {
    			ret = enumItem;
    		}
    	});
    	return ret;
    }

    function isStatusChangeOk(checklist, newStatus, enumList) {
    	var statusEnum = getStatusEnumItem(enumList, checklist.status);
    	var updateControl = statusEnum && statusEnum.extraSelect && statusEnum.extraSelect.length > 1 ? statusEnum.extraSelect[1] : 0;

    	if (checklist.status == 'DONE' || checklist.status == newStatus || updateControl == 2) { // TODO: Support for updateControl = 1: should ask user if really wants to proceed with status change.
    		return false;
    	}
    	if (newStatus == 'WIP') {
    		return checklist.canStatusChangeToWip;
    	}
    	if (newStatus == 'INTER') {
    		return checklist.canStatusChangeToInter;
    	}
    	if (newStatus == 'DONE') {
    		return checklist.canStatusChangeToDone;
    	}
    	return false;
    }
	// Provides implementation for checklist.
	// Requires: formId used by dfx-column and data of (checklist)objects to loop.
	// Example:
	// HTML:
	// <dfx-check-list form-id="25117" check-list-id="ctrl."></dfx-check-list>
	var checklistModule=angular.module('checkdir.dfxCheckList',[])
checklistModule.directive('dfxCheckList', ['common', 'commonDatacontext', 'usersettings', function (common, commonDatacontext, usersettings)
    {
		return {
			restrict: 'E', //E = element, A = attribute, C = class, M = comment 
			scope: {},
			controllerAs: 'ctrl',
			bindToController: {
				formId: '=',
				usePlSql: '=',
				checkListId: '=?',
				checkListIdList: '=?',
				onContentChangedCallback: '&',
				onControllerCallback: '&'
			},
			link: function ($scope, element, attrs) {
				$scope.allowStatusChange = 'allowStatusChange' in attrs;
			},
			controller: controllerId,
			transclude: true,
			template: function (tElement, tAttrs) {
				return tAttrs.template ? require(`./${tAttrs.template.replace('.html', '')}.html`) : require('./checklist.html') || require('./checklist.html');
			},
		}
	}]);

checklistModule.controller(controllerId, ['$scope', 'commonDatacontext', 'common', 'usersettings', checklist]);

	function checklist($scope, commonDatacontext, common, usersettings) {
        var ctrl = this;

        // API offered for the client with onControllerCallback
        var service = {
            triggerLoading: getCheckList
        };

        ctrl.$onInit = function () {
            ctrl.underProgress = null;
            ctrl.checkListStatus = [];
            ctrl.onControllerCallback({ childCtrl: service });
        };
		

		$scope.$watch('ctrl.checkListId', function (checkListId) {
			if (checkListId) {
				getCheckList();
			}
			else {
				ctrl.data = [];
				ctrl.onContentChangedCallback({ count: ctrl.data.length });
			}
		});

		$scope.$watch('ctrl.checkListIdList', function (checkListIdList) {
			if (checkListIdList) {
				getCheckList();
			}
			else {
				ctrl.data = [];
				ctrl.onContentChangedCallback({ count: ctrl.data.length });
			}
		});
		

		/*Confirm checklist task done by checkbox-click*/
		ctrl.confirmRowWithCheckbox = function (row) {
			if (row && !row.isReadOnly) {
				if (row.isChecked == 0) {
					/*Set as done*/
					row.status = 'DONE';
				}
				else {
					/*Uncheck task, set status to def in checkliststatus enum*/
					var defStatus = common.getEnumDefValue(ctrl.checkListStatus, true);
					row.status = defStatus;
				}
				ctrl.onContentChangedCallback({ recIdList: row.recIdList, status: row.status });
			}
		}


		function getCheckListEnums() {
			// value03 contains the color definition
			commonDatacontext.getEnumFrom('CHECKLISTSTATUS_001').then(function (data) {
				if (data) {
					ctrl.checkListStatus = data;
				}
			});
		}

		ctrl.onStatusChanged = function (canceled, succeeded) {
			if (canceled || succeeded) {
				ctrl.selectedRow = null;
			}
		}

		ctrl.getNameFromId = function (id) {
			if (id) {
				return common.getEnumNameById(ctrl.checkListStatus, id)
			}
		}


		/**
		 * Return color code from the enum item index 0 <=> value03
		 */
		ctrl.getStatusColorCode = function (status) {
			var ret = 'checklist-status-color-value-undefined';
			if (ctrl.checkListStatus) {
				var statusEnum = getStatusEnumItem(ctrl.checkListStatus, status);
				ret = statusEnum ? 'checklist-status-color-value-' + statusEnum.extraSelect[0] : 'undefined';
			}
			return ret;
		}

		function getCheckList() {
			if ((ctrl.checkListId && ctrl.underProgress !== ctrl.checkListId) || (ctrl.checkListIdList && ctrl.underProgress !== ctrl.checkListIdList)) {
				ctrl.underProgress = ctrl.checkListId;
				// Two alternative reading APIs exist:
				//	- First one uses PL/SQL: DLG_CHECKLIST_001.GETALLWITHLOG (PUR circulation list)
				//  - Second one uses form settings (WOR: task/todo list)
				if (ctrl.usePlSql) {
					commonDatacontext.getCheckListLog(ctrl.checkListId).then(function (data) {
						if (data) {
							ctrl.data = data;
							ctrl.onContentChangedCallback({ count: data.length });
						}
						ctrl.underProgress = null;
					});
				}
				else {
					commonDatacontext.getCheckLists(ctrl.checkListId, null, 0, ctrl.formId, ctrl.checkListIdList).then(function (data) {
						if (data) {
							ctrl.data = data;
							ctrl.onContentChangedCallback({ count: data.length });
						}
						ctrl.underProgress = null;
					});
				}
			}
		}

		/**
		 * Return true if status change is allowed and possible
		 */
		ctrl.isEditMode = function (row) {
			if ($scope.allowStatusChange && ctrl.selectedRow === row) {
				return isStatusChangeOk(row, 'WIP', ctrl.checkListStatus) || isStatusChangeOk(row, 'DONE', ctrl.checkListStatus)
			}
			return false;
		}

		/**
		 * Toggle row selection
		 */
		ctrl.onToggleRow = function (row) {
			ctrl.selectedRow = ctrl.selectedRow === row ? null : row;
		}

		usersettings.getLoginPromise().then(function () {
			init();
		});

		function init() {
			getCheckListEnums();
			getCheckList();
		}
	}

	/**
	 * Directive providing status change button for checklist
	 */
	checklistModule.directive('dfxChecklistStatusChange', function () {
		// Provides buttons for status change
		// Usage:
		// <td class="col-md-2" dfx-checklits-status-change task="ctrl.currentTask" on-status-change-callback="ctrl.onStatusChanged(checklist)" />
		return {
			restrict: 'EA', //E = element, A = attribute, C = class, M = comment 
			scope: {},
			bindToController: {
				checklist: '=',
				checkLogReasons: '=?', // Enumeration list for checklog reasons.
				isStandAlone: '=?',
				showMask: '=?', // Show bits: 0 = no comment, no checklog reason (default); 1 = show checklog reason when status INTER; 2 = show comment when status INTER
				statusList: '=',
				onStatusChangedCallback: '&',
				onCancelCallback: '&'
			},
			controller: ['$scope', 'common', 'commonDatacontext', function ($scope, common, commonDatacontext) {
                var ctrl = this;

                ctrl.$onInit = function () {
                    ctrl.isStandAlone = ctrl.isStandAlone == undefined ? false : ctrl.isStandAlone;
                    ctrl.WIP = getStatusEnumItem(ctrl.statusList, 'WIP');
                    ctrl.INTER = getStatusEnumItem(ctrl.statusList, 'INTER');
                    ctrl.DONE = getStatusEnumItem(ctrl.statusList, 'DONE');
                    if (typeof ctrl.showMask == 'undefined') {
                        ctrl.showMask = 0;
                    }
                    ctrl.showCheckLogReasonINTER = (ctrl.showMask & 1) > 0;
                    ctrl.showCommentINTER = (ctrl.showMask & 2) > 0;
                };

				ctrl.clicked = function (newStatus) {
					ctrl.newStatus = newStatus;
					common.openModal($scope, require('./changeCheckListStatus.html'), onOk, onCancel);
				}

				function onOk() {
					// Save status change
					ctrl.checklist.status = ctrl.newStatus.id;
					ctrl.checklist.checkLogReasonName = common.getEnumNameById(ctrl.checkLogReasons, ctrl.checklist.checkLogReason);
					commonDatacontext.saveChanges(ctrl.checklist).then(function (saveResult) {
							ctrl.onStatusChangedCallback({ succeeded: true, checklistItem: ctrl.checklist });
						},
						function (error) {
							ctrl.onStatusChangedCallback({ succeeded: false });
						}
					);
				}
				function onCancel() {
					ctrl.onCancelCallback();
				}
				ctrl.isChangeOk = function (newStatus) {
					return isStatusChangeOk(ctrl.checklist, newStatus, ctrl.statusList);
				}

			}],
			controllerAs: 'ctrl',
			template: require('./checklistStatusChangeButtons.html')
		}
	});


export default checklistModule


