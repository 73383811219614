'use strict';
var favouriteModule = angular.module('dir.dfxFavourite',[]);
/**
	 * Favourite adding/removal to model object  as table data 
	 */
favouriteModule.directive('dfxFavourite', function () {
    // Provides Favourites selection to model object.
    // Usage:
    // <dfx-favourite model="m" object-type="'DLG_RESOURCE'" on-changed-callback="ctrl.favStatusChanged(model)"></dfx-favourite>
    return {
        restrict: 'E', //E = element, A = attribute, C = class, M = comment 
        scope: {},
        controllerAs: 'ctrl',
        bindToController: {
            model: '=',
            objectType: '=',
            onChangedCallback: '&'
        },
        controller: ['$scope', 'commonDatacontext', function ($scope, commonDatacontext) {
            var ctrl = this;

            ctrl.toggleFav = function () {
                ctrl.model.f = !ctrl.model.f;
                ctrl.model.fKey = commonDatacontext.setFavourite(ctrl.model, ctrl.objectType);
                if (commonDatacontext.hasChanges()) {
					commonDatacontext.saveChanges().then(function (saveResult) {
						if (saveResult && saveResult.entities) {
							for (var i = 0; i < saveResult.entities.length; i++) {
								if (saveResult.entities[i].objectRecId == ctrl.model.recId) {
									ctrl.model.fKey = saveResult.entities[i].id; 
								}
							}
						}
                        ctrl.onChangedCallback({ model: ctrl.model });
                    },
                    function (error) {
                        //errMsg(error);
                    });
                }
            };
        }],
		template: require('./dfxFavourite.html')
		
    };
});
export default favouriteModule