'use strict';

// Define the common module 
// Contains services:
//  - common
//  - logger
//  - spinner
var commonModule = angular.module('common', []);

// Must configure the common service and set its 
// events via the commonConfigProvider
commonModule.provider('commonConfig', function () {
	this.config = {
		// These are the properties we need to set
		//controllerActivateStartedEvent: '',
		//controllerActivateSuccessEvent: '',
		//spinnerToggleEvent: ''
	};

	this.$get = function () {
		return {
			config: this.config
		};
	};
});

commonModule.factory('common',
	['$compile', '$location', '$q', '$rootScope', '$timeout', 'commonConfig', 'logger', '$http', '$route', '$routeParams', 'routes', '$window', '$filter', '$uibModal', '$injector', '$translate', '$sce', '$ocLazyLoad', common]);

function common($compile, $location, $q, $rootScope, $timeout, commonConfig, logger, $http, $route, $routeParams, routes, $window, $filter, $uibModal, $injector, $translate, $sce, $ocLazyLoad) {
	var throttles = {};
	var cachedData = []; // associative array for cached data    	
	var portalInfo = []; //topnav controllers portalInfo for common use.
	var preventMovement = false;
	var lastGridId = null;
	var lastFilterFields = [];
	var tabsAvailablePromise = $q.defer();
	var loadedPDFJS = null;
	// Used is several places <=> let's keep the definition in one place to avoid typos

	var commonDataEnum = {
		ALARM: { bit: 1, paramName: 'Alarm', name: "" },
		COMMENT: { bit: 2, paramName: 'Comment', name: "" },
		DOCUMENT: { bit: 4, paramName: 'Document', name: "" },
		IMAGE: { bit: 8, paramName: 'Image', name: "" },
		INFO: { bit: 16, paramName: 'Info', name: "" },
		TEXT: { bit: 32, paramName: 'Text', name: "" }
	};

	$translate(['STRCONST.PUBLIC.TXT_ALARM', 'STRCONST.PUBLIC.HDR_COMMENT', 'STRCONST.LEANPORTAL.HDR_DOCUMENTS', 'STRCONST.LEANPORTAL.HDR_IMAGES', 'STRCONST.PUBLIC.TXT_INFOTEXTS', 'STRCONST.LEANPORTAL.HDR_TEXTS']).then(function (translations) {
		commonDataEnum.ALARM.name = translations['STRCONST.PUBLIC.TXT_ALARM']
		commonDataEnum.COMMENT.name = translations['STRCONST.PUBLIC.HDR_COMMENT']
		commonDataEnum.DOCUMENT.name = translations['STRCONST.LEANPORTAL.HDR_DOCUMENTS']
		commonDataEnum.IMAGE.name = translations['STRCONST.LEANPORTAL.HDR_IMAGES']
		commonDataEnum.INFO.name = translations['STRCONST.PUBLIC.TXT_INFOTEXTS']
		commonDataEnum.TEXT.name = translations['STRCONST.LEANPORTAL.HDR_TEXTS']

	})
	//keeps track of any pending changes in data context througout the app
	var dcPendingChanges = {
		QDC: { hasChanges: false }, //QualityDataContext
		MDC: { hasChanges: false }, //ManDataContext
		CDC: { hasChanges: false }, //CommonDataContext
		PDC: { hasChanges: false }, //PurchaseDataContext
		TDC: { hasChanges: false } // TravelDataContext
	};

	var service = {
		// common angular dependencies
		$broadcast: $broadcast,
		$location: $location,
		$q: $q,
		$rootScope: $rootScope,
		$route: $route,
		$routeParams: $routeParams,
		$timeout: $timeout,
		$translate: $translate,
		$uibModal: $uibModal,
		$http: $http,
		$window: $window,
		$filter: $filter,
		// generic
		activateController: activateController,
		adjustTime: adjustTime,
		appSettingReady: appSettingReady,
		asArray: asArray,
		b64toBlob: b64toBlob,
		b64DecodeUnicode: b64DecodeUnicode,
		b64EncodeUnicode: b64EncodeUnicode,
		changeNegativeChar: changeNegativeChar,
		commonDataEnum: commonDataEnum,
		createSearchThrottle: createSearchThrottle,
		dblToLocalStr: dblToLocalStr,
		debouncedThrottle: debouncedThrottle,
		downloadFile: downloadFile,
		executePDFJS: executePDFJS,
		filterEnumsByValueFld: filterEnumsByValueFld,
		formatString: formatString,
		elementValueHasRealChanges: elementValueHasRealChanges,
		getAppSetting: getAppSetting,
		getBeforeUnloadValue: getBeforeUnloadValue,
		getCachedData: getCachedData,
		getCommonDataOwnerTags: getCommonDataOwnerTags,
		getCommonDataParamName: getCommonDataParamName,
		getCommonDataParents: getCommonDataParents,
		getCommonDataTypeMask: getCommonDataTypeMask,
		getCommonDataTypes: getCommonDataTypes,
		getCommonDataTypeTags: getCommonDataTypeTags,
		getDayMonthFormat: getDayMonthFormat,
		getEnumDefaultRow: getEnumDefaultRow,
		getEnumDefValue: getEnumDefValue,
		getEnumNameById: getEnumNameById,
		getEnumRowById: getEnumRowById,
		getLastPortalId: getLastPortalId,
		getModulesForCurrentTab: getModulesForCurrentTab,
		getIdFromLookupStatus: getIdFromLookupStatus,
		getIdFromLookupPhase: getIdFromLookupPhase,
		getLastFilterFields: getLastFilterFields,
		getLastGridId: getLastGridId,

		getParamValFromUrlParams: getParamValFromUrlParams,
		getSubTabs: getSubTabs,
		getTabs: getTabs,
		getTabsPromise: getTabsPromise,
		hasPermission: hasPermission,
		helpFileExists: helpFileExists,
		initializeTypeaheadData: initializeTypeaheadData,
		isNegativeString: isNegativeString,
		isNumber: isNumber,
		isPdf: isPdf,
		launchmodal: launchmodal,
		loadPDFJS: loadPDFJS,
		logger: logger, // for accessibility
		longDateFormat: longDateFormat,
		longTimeFormat: longTimeFormat,
		openHelpFile: openHelpFile,
		openCommonData: openCommonData,
		openModal: openModal,
		openModalMessage: openModalMessage,
		openPortalSettings: openPortalSettings,
		openurlintab: openurlintab,
		redirectFromTabUrl: redirectFromTabUrl,
		rejectCachedData: rejectCachedData,
		removeCommasDecimals: removeCommasDecimals,
		replaceCommasDecimals: replaceCommasDecimals,
		replaceDotDecimals: replaceDotDecimals,
		revertChangesToProperty: revertChangesToProperty,
		routes: routes,
		setBeforeUnloadValue: setBeforeUnloadValue,
		setCachedData: setCachedData,
		setLastGridId: setLastGridId,
		setLastFilterFields: setLastFilterFields,
		setLastPortalId: setLastPortalId,
		setPortalInfo: setPortalInfo,
		setTypeheadColumnData: setTypeheadColumnData,
		showMomentDateAndMonth: showMomentDateAndMonth,
		strToDbl: strToDbl,
		stringifyTrackedEntities: stringifyTrackedEntities,
		textContains: textContains,
		toObjId: toObjId,
		toObjRecId: toObjRecId,
		getThousandSeparator: getThousandSeparator,
		removeEmptySpace: removeEmptySpace,
		removeCommas: removeCommas,
		resetCachedData: resetCachedData,
		triggerResizeEvent: triggerResizeEvent,
		unique: unique,
		getCurrentPortal: getCurrentPortal,

	};
	return service;



	function getAppSetting(key, defval) {
		var appSettings = configObject;
		if (key in appSettings) {
			return appSettings[key];
		}
		else {
			return defval;
		}
	}
	function appSettingReady() {
		return $q.when($rootScope.appSettings.then(function (data) {
			return data
		}))
	}

	function activateController(promises, controllerId) {
		var data = { controllerId: controllerId };
		$broadcast(commonConfig.config.controllerActivateStartedEvent, data);
		return $q.all(promises).then(function (eventArgs) {
			$broadcast(commonConfig.config.controllerActivateSuccessEvent, data);
		}, function (eventArgs) {
			$broadcast(commonConfig.config.controllerActivateSuccessEvent, data);
		});
	}

	/**
	 * @param {object if array, this will be returned, otherwise this is added to array, which is returned} object 
	 */
	function asArray(object) {
		var array = null;
		if (object) {
			if (object instanceof Array) {
				array = object;
			}
			else {
				array = [];
				array.push(object);
			}
		}
		return array;
	}

	/**
	 * Adjusts the time 
	 * DB saves the time in local time
	 * Browser expects that the time is given in UTC
	 * @param {toDb if true convert from browser's UTC time to local time. If false convert from local time to browser's UTC time.} toDb 
	 * @param {date to be converted (default = false)} date 
	 * @returns {} 
	 */
	function adjustTime(date, toDb) {
		var toDb_ = (typeof (toDb) == 'undefined' ? false : toDb);
		return new Date(date.getTime() + (toDb_ ? -1 : 1) * (date.getTimezoneOffset() * 60000));
	}

	function b64toBlob(b64Data, contentType, sliceSize) {
		contentType = contentType || '';
		sliceSize = sliceSize || 512;

		var byteCharacters = atob(b64Data);
		var byteArrays = [];

		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);

			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			var byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}

		var blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}

	/**
	 * Converts given encoded string to original string.
	 * See https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
	 * @param {string} str - encoded string to be converted
	 * @return {string}
	 */
	function b64DecodeUnicode(str) {
		// Going backwards: from bytestream, to percent-encoding, to original string.
		return decodeURIComponent(atob(str).split('').map(function (c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));
	}

	/**
	 * Converts given base64 string to ascii by first escaping the string with UTF-8 and then encoding it.
	 * Can be used to avoid characters out of Latin1 range exception.
	 * See https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
	 * @param {string} str - base64 string to be converted
	 * @return {string}
	 */
	function b64EncodeUnicode(str) {
		// first we use encodeURIComponent to get percent-encoded UTF-8,
		// then we convert the percent encodings into raw bytes which
		// can be fed into btoa.
		return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
			function toSolidBytes(match, p1) {
				return String.fromCharCode('0x' + p1);
			}));
	}

	function $broadcast() {
		return $rootScope.$broadcast.apply($rootScope, arguments);
	}

	function createSearchThrottle(viewmodel, list, filteredList, filter, delay) {
		// After a delay, search a viewmodel's list using 
		// a filter function, and return a filteredList.

		// custom delay or use default
		delay = +delay || 300;
		// if only vm and list parameters were passed, set others by naming convention 
		if (!filteredList) {
			// assuming list is named sessions, filteredList is filteredSessions
			filteredList = 'filtered' + list[0].toUpperCase() + list.substr(1).toLowerCase(); // string
			// filter function is named sessionFilter
			filter = list + 'Filter'; // function in string form
		}

		// create the filtering function we will call from here
		var filterFn = function () {
			// translates to ...
			// vm.filteredSessions 
			//      = vm.sessions.filter(function(item( { returns vm.sessionFilter (item) } );
			viewmodel[filteredList] = viewmodel[list].filter(function (item) {
				return viewmodel[filter](item);
			});
		};

		return (function () {
			// Wrapped in outer IFFE so we can use closure 
			// over filterInputTimeout which references the timeout
			var filterInputTimeout;

			// return what becomes the 'applyFilter' function in the controller
			return function (searchNow) {
				if (filterInputTimeout) {
					$timeout.cancel(filterInputTimeout);
					filterInputTimeout = null;
				}
				if (searchNow || !delay) {
					filterFn();
				} else {
					filterInputTimeout = $timeout(filterFn, delay);
				}
			};
		})();
	}

	function debouncedThrottle(key, callback, delay, immediate) {
		// Perform some action (callback) after a delay. 
		// Track the callback by key, so if the same callback 
		// is issued again, restart the delay.

		var defaultDelay = 1000;
		delay = delay || defaultDelay;
		if (throttles[key]) {
			$timeout.cancel(throttles[key]);
			throttles[key] = undefined;
		}
		if (immediate) {
			return callback();
		} else {
			throttles[key] = $timeout(callback, delay);
			return throttles[key];
		}
	}

	/**
	 * Opens up given url in new tab.
	 * Note: function name is in lower case so that it can be called from portal tab via redirectFromTabUrl.
	 * @param {string} url - Url to be launched.
	 */
	function openurlintab(url) {
		if (url) {
			var a = window.document.createElement("a");
			a.href = url;
			a.target = '_blank';
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	}

	/**
	 * Creates object which can be downloaded as file.
	 * @param {} fileContents 
	 * @param {} fileType 
	 * @param {} fileName 
	 * @returns {} 
	 */
	function downloadFile(fileContent, fileContentType, fileName, url, hasFile) {

		/*Check if fileContent references to file in folder and open URL in new tab*/
		if (!hasFile && url) {
			openurlintab(url);
		}
		else {
			var blob = new Blob([b64toBlob(fileContent, fileContentType)]);
			if (window.navigator.msSaveOrOpenBlob)  // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
				window.navigator.msSaveOrOpenBlob(blob, fileName);
			else {
				var a = window.document.createElement("a");
				a.href = window.URL.createObjectURL(blob, { type: fileContentType });
				a.download = fileName;
				document.body.appendChild(a);
				a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
				document.body.removeChild(a);
			}
		}
	};

	/*Checks element's entity for real changes and calls check for changes from dataContext*/
	function elementValueHasRealChanges(entity, propertyNames, elem, resolveHasChanges) {
		var noRealChanges = false;
		var origProperites = getOriginalValuesProperties(entity, propertyNames);
		var str = origProperites.join(" "); //get a string from properties.
		angular.forEach(propertyNames, function (propertyName) {
			if (noRealChanges || entity.getProperty(propertyName) == ' ' || entity.entityAspect.originalValues[propertyName] === entity.getProperty(propertyName)
				|| str == ' ' && elem.value == '') {
				revertChangesToProperty(entity, propertyName);
				//no real changes, reset property state for all listed properties.
				noRealChanges = true;
			}
		});
		//no real changes found, calls resolveHasChanges from data Context
		if (noRealChanges) {
			resolveHasChanges();
		}

		function getOriginalValuesProperties(entity, propertyNames) {
			var properites = [];
			angular.forEach(propertyNames, function (propertyName) {
				var property = entity.entityAspect.originalValues[propertyName];
				properites.push(property);
			});
			return properites;
		}
	}

	/**
	 * Return promise for requested data, that will hold in cached for given time
	 * @param {fetchData key to identify the data (parameters for BLC call)} fetchData 
	 * @param {agingDelay delay in seconds, default 60s used if not given} agingDelay 
	 */
	function getCachedData(fetchData, agingDelay) {
		var lsKey = getCachedDataKey(fetchData);
		var lst = null;
		if (agingDelay === undefined) {
			agingDelay = 60;
		}

		// If 1) data is fot fetched yet or 2) data is aged, then DB access in needed <=> return null
		var cache = cachedData[lsKey];
		if (!cache || isAged(cache)) {
			cachedData[lsKey] = {
				deferred: $q.defer(),
				stamp: new Date().getTime(),
				agingDelay: agingDelay,
				data: null
			}
			return null;
		}
		else if (isPending(cache)) {
			return cache.deferred.promise;
		}
		else {
			lst = cache.data;
			return $q.when(lst);
		}

		// Some auxiliary APIs
		function isAged(cache) {
			var aged = new Date().getTime() > (cache.stamp + cache.agingDelay * 1000);
			return aged;
		}
		function isPending(cache) {
			return cache.deferred.promise.$$state.status == 0;
		}
	}
	/**
	 * Generate key to access cached data
	 */
	function getCachedDataKey(arr) {
		var ret = '';
		for (var i = 0; i < arr.length; i++) {
			if (arr[i]) {
				ret = ret.concat(arr[i] + '_');
			}
		}
		return ret;
	}

	/**
	 * Return tag used by Settings dialog to control common data parametrization:
	 * visibility/layout/...
	 */
	function getCommonDataOwnerTags() {
		return ['TaskView', 'RsrcView', 'TaskMaterialView', 'QualityView'];
	}

	/**
	 * Generates parameter name used to handle paersonal setting parameters.
	 * @param {owner indicates the place where Common Data is shown.} owner 
	 * @param {type defines the data type.} type 
	 * @returns {} 
	 */
	function getCommonDataParamName(owner, type) {
		return 'commonDataView_' + owner + '_' + (typeof (type) == 'undefined' ? 'Visible' : type);
	}

	/**
	 * Return supported common data types. Return in same order than the tabs are viewed in dfxCommonDataView directive
	 */
	function getCommonDataTypes() {
		return [commonDataEnum.DOCUMENT, commonDataEnum.IMAGE, commonDataEnum.TEXT, commonDataEnum.COMMENT];
	}

	/**
	 * Return bit mask corresponding to visible types for giwen owner
	 */
	function getCommonDataTypeMask(usersettings, owner) {
		var ret = 0;
		getCommonDataTypes().forEach(function (e) {
			ret = ret | getMaskBit(usersettings, owner, e);
		});
		if (ret == 0) {
			ret = commonDataEnum.COMMENT.bit;
		}
		return ret;
	}

	/**
	 * Return data parent from given data by folowin three step logic
	 * 1) data type specific definition from model (dynamic from web form)
	 * 2) common definition from model (dynamic from web form)
	 * 3) common definition from prototype (static)
	 * @param {string} dataType: Alarm/Comment/Document/Image/Info 
	 * !! Obs. defines the property, so this case sensitive
	 * @param {object} object object
	 */
	function getCommonDataParents(dataType, object) {
		var ret = [];
		if (object) {
			var propertyValue = object['dataParents' + dataType];
			if (!propertyValue && dataType === 'Image') {
				// Image is also a document, so if no Image definition is given, try Document
				propertyValue = object['dataParentsDocument'];
			}
			if (!propertyValue) {
				propertyValue = object['dataParentsDefault'];
			}
			if (propertyValue && propertyValue.length) {
				if (propertyValue === '---') {
					// valid case no parent data defined
				}
				else {
					var objects = propertyValue.split('|');
					angular.forEach(objects, function (obj) {
						var fields = obj ? obj.split(';') : [];
						if (obj.replace(/\s/g, '').length === 0) {
							// allow empty after ot between '|' 
						}
						else if (fields.length === 3) {
							ret.push(toObjRecId(fields[0], fields[1], fields[2]));
						}
						else {
							// error in definition
							logger.logError('Invalid parent object definition ' + dataType + ' ' + obj, propertyValue, 'common.getCommonDataParents', true, 'error');
						}
					});
				}
			}
			else if (object.commonDataParents && object.commonDataParents()) {
				ret = object.commonDataParents();
			}
			else if (object.objRecId && object.objRecId()) {
				ret = [object.objRecId()];
			}
		}
		return ret;
	}

	function getMaskBit(usersettings, owner, e) {
		return usersettings.getPersonalSettingValue(getCommonDataParamName(owner, e.paramName), true) ? e.bit : 0;
	}

	/**
	 * Return supported common data type tags
	 */
	function getCommonDataTypeTags() {
		var ret = [];
		getCommonDataTypes().forEach(function (t) {
			ret.push(t.paramName);
		});
		return ret;
	}


	/**
	 * Filter for filtering enumerations with value field values.
	 * @param {Index for value field in extra select list.} valueFldIndex 
	 * @param {Value field value used in comparison.} valueFldValue 
	 * @returns {} 
	 */
	function filterEnumsByValueFld(valueFldIndex, valueFldValue) {
		return function (enumRow) {
			if (enumRow.extraSelect && enumRow.extraSelect[valueFldIndex] == valueFldValue) {
				return true;
			}
			return false;
		};
	};

	/**
	 * Returns enumeration default row or null if not set.
	 * @param {Enumeration list} lst 
	 * @returns {Default enumeration row or null if not found} 
	 */
	function getEnumDefaultRow(lst) {
		var retVal = null;
		angular.forEach(lst, function (row) {
			if (row.isDefault)
				retVal = row;
		});
		return retVal;
	};

	/**
	 * Gets default value (id) for given enumeration list. If not found returns null or if returnFirst = true returns first value.
	 * @param {Enumeration list} lst 
	 * @param {If set to true returns id of first item if default value not set.} returnFirst 
	 * @returns {Enumeration id or null} 
	 */
	function getEnumDefValue(lst, returnFirst) {
		if (!lst) return null;
		var defRow = getEnumDefaultRow(lst);
		if (defRow && defRow.id) {
			return defRow.id;
		} else if (returnFirst && lst[0] && lst[0].id) {
			return lst[0].id;
		}
		return null;
	}

	/**
	 * Returns enumeration name from given enumeration list by using given id for searching.
	 * @param {Enumeration list} lst 
	 * @param {Enumeration id} id 
	 * @param {If true, returns nabbr instead of name} returnNabbr
	 * @returns {Enumeration item row} 
	 */
	function getEnumNameById(lst, id, returnNabbr) {
		var enumRow = getEnumRowById(lst, id);
		if (enumRow) {
			if (returnNabbr) return enumRow.nabbr;
			return enumRow.name;
		}
		return null;
	};

	/**
	 * Returns enumeration row from given list by using given id for searching.
	 * @param {Enumeration list} lst 
	 * @param {Enumeration id} id 
	 * @returns {Enumeration item row} 
	 */
	function getEnumRowById(lst, id) {
		var retVal = null;
		angular.forEach(lst, function (row) {
			if (row.id == id) {
				retVal = row;
			}
		});
		return retVal;
	};

	/**
	 * Returns previous ui-grid filter terms or searchString
	 * @param {USERID_PORTALID_TABID} key
	 * @param {OBJARR or FIELDS or SEARCHSTRING} requestType
	 */
	function getLastFilterFields(key, requestType) {
		if (key && lastFilterFields[key]) {
			if (requestType == 'FIELDS') {
				return lastFilterFields[key].stringifiedFields;
			}
			else if (requestType == 'OBJARR') {
				return lastFilterFields[key].objArr;
			}
			else if (requestType == 'SEARCHSTRING') {
				return lastFilterFields[key].searchString;
			}
		}
		else {
			return null
		}
	}

	function getLastGridId() {
		return lastGridId;
	}

	// Gets last selected portal id from local storage if exists.
	function getLastPortalId(lsKey) {
		if (lsKey && localStorage && localStorage.getItem(lsKey)) {
			var lst = JSON.parse(localStorage.getItem(lsKey));
			if (lst && lst.lastPortalId) {
				return lst.lastPortalId;
			}
		}
		return null;
	}

	function getCurrentPortal() {
		return $route.current.$$route.portalId;
	}

	/**
	 * Gets modules for current tab and stores them to ctrl.modules property.
	 * @param {List of portal settings} portals
	 * @returns {List of modules.}
	 */
	function getModulesForCurrentTab(portals) {
		var modules = [];

		// Get current portal id.
		var portalId = $route.current.portalId;

		// Get tabs for current portal.
		var tabs = [];
		for (var i = 0; i < portals.length; i++) {
			if (portals[i] && portals[i].portalId == portalId) {
				tabs = (portals[i].tabs);
				break;
			}
		}

		// Get modules for current tab by using current route.
		for (var i = 0; i < tabs.length; i++) {
			if (tabs[i] && tabs[i].url == $route.current.originalPath) {
				modules = (tabs[i].modules);
				break;
			}
			// Check sub tabs.
			for (var j = 0; j < tabs[i].subTabs.length; j++) {
				if (tabs[i].subTabs[j] && tabs[i].subTabs[j].url == $route.current.originalPath) {
					modules = (tabs[i].subTabs[j].modules);
					break;
				}
			}
		}
		return modules;
	}

	function getIdFromLookupStatus(list, name) {
		if (list && name) {
			var result;
			for (var i = 0; i < list.length; i++) {
				var nabbr = list[i].nabbr;
				var isMatch = textContains(nabbr, name);
				if (isMatch) {
					result = list[i].id;
					return result;
				}
			}
		}
		return '';
	}

	function getIdFromLookupPhase(list, name) {
		if (list && name) {
			var result;
			for (var i = 0; i < list.length; i++) {
				var nabbr = list[i].nabbr;
				var isMatch = textContains(nabbr, name);
				if (isMatch) {
					result = list[i].id;
					return result;
				}
			}
		}
		return '';
	}

	/**
	 * Returns parameter value from url parameters or null if parameter with given name is not found.
	 * @param {Parameter name to be searched for, e.g. orderId, invoiceId.} paramName
	 * @returns {Parameter value.}
	 */
	function getParamValFromUrlParams(paramName) {
		var urlParams = $route.current.params;
		if (urlParams && urlParams[paramName]) {
			return urlParams[paramName];
		}
		return null;
	}

	/**
	 * Gets sub tabs for portal tab whose ids are given as parameters. If tab is a sub tab, returns it's parents sub tabs (siblings of the requested sub tab).
	 * @param {Id of portal whose tabs sub tabs are wanted.} portalId
	 * @param {Id of tab whose sub tabs are wanted.} tabId
	 * @param {Url of tab whose sub tabs are wanted.} tabUrl
	 * @returns {First match's sub tabs as list or empty list if not found.}
	 */
	function getSubTabs(portalId, tabId, tabUrl) {
		var subTabs = [];
		var tabs = getTabs(portalId);
		if (tabs) {
			// Find correct tab from tab list, get sub tabs and mark current tab as active.
			for (var i = 0; i < tabs.length; i++) {
				if ((tabId && tabs[i].tabId == tabId) || (tabUrl && tabs[i].url == tabUrl)) {
					subTabs = tabs[i].subTabs;
					tabs[i].isActive = true;
					break;
				}
				// Check sub tabs. If found, select the main tab (= parent tab).
				for (var j = 0; j < tabs[i].subTabs.length; j++) {
					if ((tabId && tabs[i].subTabs[j].tabId == tabId) || (tabUrl && tabs[i].subTabs[j].url == tabUrl)) {
						subTabs = tabs[i].subTabs;
						tabs[i].isActive = true;
						break;
					}
				}
			}

			// Mark current sub tab active.
			for (var j = 0; j < subTabs.length; j++) {
				subTabs[j].isActive = (subTabs[j].url == $route.current.originalPath ? true : false);
			}
		}
		return subTabs;
	}

	/**
	 * Gets tabs for portal whose id is given as parameter.
	 * @param {Id of portal whose tabs are wanted.} portalId
	 * @returns {Tabs as list or empty list if not found.} 
	 */
	function getTabs(portalId) {
		var tabs = [];
		if (portalInfo) {
			// Find correct portal from portalinfo.
			for (var i = 0; i < portalInfo.length; i++) {
				if (portalInfo[i] && portalInfo[i].portalId == portalId) {
					tabs = (portalInfo[i].tabs);
				}
			}
			// Mark current tab active.
			for (var j = 0; j < tabs.length; j++) {
				tabs[j].isActive = (tabs[j].url == $route.current.$$route.originalPath || tabs[j].subTabs.some(subTab => subTab.url == $route.current.originalPath) ? true : false);
			}
		}
		return tabs;
	}

	// The promise is resolved after tabs has been set into portalinfo
	function getTabsPromise() {
		if (portalInfo && portalInfo.length > 0) {
			return $q.when(true);
		}
		else {
			return $q.when(tabsAvailablePromise.promise);
		}
	}

	function hasPermission(permId, hasFlags) {
		return DFX_HasPermission(permId, hasFlags);

		function DFX_HasPermission(permId, hasFlags) {
			var permFlags = 0;
			if (permId == null || permId == "" || permId == "NOCHECK")
				return true;

			if (permId == "CHECK") {
				if (hasFlags > 0)
					return true;
				else
					return false;
			}

			if (permId == "R_FORM")
				permFlags = 1;
			else if (permId == "R_SELECT")
				permFlags = 2;
			else if (permId == "R_INSERT")
				permFlags = 4;
			else if (permId == "R_UPDATE")
				permFlags = 8;
			else if (permId == "R_DELETE")
				permFlags = 16;
			else if (permId == "R_TOOL1")
				permFlags = 32;
			else if (permId == "R_TOOL2")
				permFlags = 64;
			else if (permId == "R_TOOL3")
				permFlags = 128;
			else if (permId == "R_TOOL_MAINUSER")
				permFlags = 256;
			else if (permId == "R_TOOL_SYSDBA")
				permFlags = 512;
			else if (permId == "R_TOOL4")
				permFlags = 1024;
			else if (permId == "R_TOOL5")
				permFlags = 2048;
			else if (permId == "R_TOOL6")
				permFlags = 4096;
			else if (permId == "R_TOOL7")
				permFlags = 8192;
			else if (permId == "R_TOOL8")
				permFlags = 16384;
			else if (permId == "R_TOOL9")
				permFlags = 32768;
			else
				permFlags = permId;

			if ((permFlags & hasFlags) > 0)
				return true;
			else
				return false;
		}
	}

	function helpFileExists(docPath) {
		var docPathExist = eval('typeof ' + 'docPath') != 'undefined' ? docPath : null;
		return (docPathExist && docPathExist != '');
	}

	function isNumber(val) {
		// negative or positive        	
		return !isNaN(parseFloat(val)) && isFinite(val);;
	}

	/**
	 * Checks if given file is a pdf file.
	 * @param {} fileName 
	 * @returns {} 
	 */
	function isPdf(fileName) {
		if (fileName) {
			var extension = '.PDF';
			var tempName = fileName.toUpperCase();
			return tempName.indexOf(extension, tempName.length - extension.length) !== -1;
		}
	}

	function openHelpFile(docPath) {
		window.open(docPath);
	}

	/**
	 * Open dialog with common idthk data tabs.
	 * @param {scope } scope 
	 * @param {usersettings initializing viewed data tabs} usersettings 
	 * @param {object for which the data fields are opened for} object 
	 * @param {owner the place where the dialog is opened from (parameterizing)} owner 
	 */
	function openCommonData(scope, usersettings, object, owner, size) {
		// must be supported and visible
		if (getCommonDataOwnerTags().indexOf(owner) == -1 || !usersettings.getPersonalSettingValue(getCommonDataParamName(owner), true)) {
			return;
		}
		scope.commonDataObject = object;
		scope.commonDataVisibleMask = getCommonDataTypeMask(usersettings, owner);
		var modalInstance = $uibModal.open({
			animation: true,
			backdrop: false,
			template: require('./directives/commonDataDialog.html'),
			controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
				$scope.onCancel = function () {
					$uibModalInstance.dismiss('cancel');
				};
			}],
			size: size,
			scope: scope
		});
	}

	async function loadPDFJS() {
		try {
			if (isTextSupported()) {
				if (!loadedPDFJS) {
					const PDFJS = await import('pdfjs-dist');
					const { default: PDFJSWorker } = await import('pdfjs-dist/build/pdf.worker');
					if (typeof window !== "undefined" && "Worker" in window) {
						PDFJS.GlobalWorkerOptions.workerPort = new PDFJSWorker();
					}
					loadedPDFJS = PDFJS;
				}
				return loadedPDFJS;
			} else {
				throw new Error('Not supported');
			}
		} catch (error) {
			console.log('Failed to load PDFJS', error.message);
		}
	}

	/**
	 *  Check if browser supports Ekko Lightbox or PDFJS
	 *  */
	function isTextSupported() {

		// At least IE6
		var isIE = /*@cc_on!@*/false || !!document.documentMode;

		// Edge 20+
		var isEdge = !isIE && !!window.StyleMedia;

		return !isIE && !isEdge;
	}

	/**
	 * Open dialog with given template
	 * @param {scope } scope 
	 * @param {templateUrl}  templateUrl
	 * @param {onOk Call back API when OK is clicked. If null OK button is not available. } onOk 
	 * @param {onCancel Call back API when Cancel is clicked. If null Cancel button is not available. } onCancel 
	 * @param {size } Size of the modal window. Possible values 'sm', 'md', 'lg', 'xl' and 'xxl'.
	 * @param {enableBackdrop} Allow outside click to close modal automatically (defaults to false).
	 */
	function executePDFJS(data, file, commonDatacontext, mywindow) {

		function checkdata(data) {
			if (data) {
				// If content does not exist yet, try to get it.
				if (!data.content) {
					commonDatacontext.getDocumentData(null, data.recId, 'CONTENT', true, true).then(function (datas) {
						data.content = datas[0];
						downloadFile(data.content, data.contentType, data.fileName, data.url, data.hasFile);
					});
				}
				else { // Content exists, just download file.
					downloadFile(data.content, data.contentType, data.fileName, data.url, data.hasFile);
				}
			}
		}
		var newdata = data
		// Internal function to do the actual modal opening.
		function startPDFJS(file) {
			if (newdata.contentType == "application/pdf") {
				// atob() is used to convert base64 encoded PDF to binary-like data.
				// (See also https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/
				// Base64_encoding_and_decoding.)
				var binaryData = atob(newdata.content);

				//Convert binary data to typed Arrays for improved memory usage
				//https://mozilla.github.io/pdf.js/api/draft/global.html#DocumentInitParameters
				var binaryLength = binaryData.length;
				var pdfData = new Uint8Array(new ArrayBuffer(binaryLength));

				for (var i = 0; i < binaryLength; i++) {
					pdfData[i] = binaryData.charCodeAt(i);
				}

				loadPDFJS().then((PDFJS) => {
					// Using DocumentInitParameters object to load binary data.
					var loadingTask = PDFJS.getDocument({ data: pdfData });
					loadingTask.promise.then(function (pdf) {
						console.log('PDF loaded');
						// Fetch the first page
						var numPages = 0;
						numPages = pdf.numPages;
						var i = 0
						var allPagesPromises = [];
						if (mywindow != null) {
							var container = mywindow.document.getElementById(file);
						}
						else {
							var container = document.getElementById(file);
						}

						for (i = 1; i <= numPages; i++) {
							allPagesPromises.push(pdf.getPage(i).then(function (data) { return data }))
						}
						$q.all(allPagesPromises).then(function (pages) {

							handlePages(pages, 0)
						})

						function handlePages(pages, i) {
							console.log('Page loaded');

							var page = pages[i]
							var scale = 1.2;
							if (mywindow != null) {
								var canvas = mywindow.document.createElement('canvas');
								canvas.classList.add('dfx-pdf-canvas');
							}
							else {
								var canvas = document.createElement('canvas');
							}

							var viewport = page.getViewport({ scale });
							container.appendChild(canvas)
							// Prepare canvas using PDF page dimensions
							var context = canvas.getContext('2d');
							canvas.height = viewport.height;
							canvas.width = viewport.width;
							// Render PDF page into canvas context
							var renderContext = {
								canvasContext: context,
								viewport: viewport
							};
							var renderTask = page.render(renderContext);
							var completeCallback = renderTask._internalRenderTask;
							completeCallback._useRequestAnimationFrame = false;
							renderTask.promise.then(function () {
								i++
								if (i < numPages) {
									handlePages(pages, i)
								}

								console.log('Page rendered');
							}, function (data) {
								console.log("ERROR")
							});
						};
					}, function (reason) {
						// PDF loading error
						console.error(reason);
					});
				});
			}
			else {
				var prefix = 'data:' + newdata.contentType + ';base64,';
				$('#leandoc').ekkoLightbox({ remote: prefix + newdata.content, title: (newdata.title ? newdata.title : newdata.fileName) });
			}
		}

		// For supported file types open modal.
		if (newdata && ((newdata.subject && newdata.subject == 'PICTURE' && newdata.contentType != 'application/pdf') || (newdata.contentType && newdata.contentType == 'application/pdf' || isTextSupported() && (newdata.contentType == 'text/plain')))) {
			// If content does not exist get it first.
			if (!newdata.content) {
				commonDatacontext.getDocumentData(null, newdata.recId, 'CONTENT', true, true).then(function (data) {
					newdata.content = data[0];
					startPDFJS(file);
				});
			}
			else { // Content exists, just open modal.
				startPDFJS(file);
			}
		} // For non-supported files call download function.
		else checkdata(data)

	}

	/**
	 * Open dialog with given template
	 * @param {Object} scope 
	 * @param {string} templateUrl
	 * @param {?onOkCallback} onOk - Call back API when OK is clicked. If null OK button is not available.
	 * @param {?onCancelCallback} onCancel - Call back API when Cancel is clicked. If null Cancel button is not available.
	 * @param {?string} size - Size of the modal window. Possible values 'sm', 'md', 'lg', 'xl' and 'xxl'. See modal-xl css class for example.
	 * @param {?boolean} [enableBackdrop=false] - Allow outside click to close modal automatically (defaults to false).
	 */
	function openModal($scope, templateUrl, onOk, onCancel, size, enableBackdrop) {
		var buttonMask = (onOk ? 0x1 : 0) | (onCancel ? 0x2 : 0);
		// Verify from the operator so that he'll realize that move is happening
		return $uibModal.open({
			animation: true,
			backdrop: enableBackdrop || false,
			template: templateUrl,
			controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
				$scope.onCancel = function () {
					$uibModalInstance.dismiss('cancel');
					if (onCancel) {
						onCancel();
					}
				};
				$scope.onApply = function () {
					// Keep modal open if onOk callback returns false, otherwise dismiss modal instance
					// Added support for callback to return promise (L22155 8.2.1019)
					var closeModal = onOk();
					if (closeModal) {
						// can be promise or boolean true
						$q.when(closeModal).then(function (value) {
							if (value !== false)
								$uibModalInstance.close();
						});
					}
					else if (closeModal !== false) {
						$uibModalInstance.close();
					}
				};
			}],
			scope: $scope,
			size: size
		});
	}

	/**
	 * Open dialog with given title and message
	 * @param {Object} scope 
	 * @param {string} title 
	 * @param {string}  message - for which the data fields are opened for 
	 * @param {?onOkCallback} onOk - Call back API when OK is clicked. If null OK button is not available.
	 * @param {?onCancelCallback} onCancel - Call back API when Cancel is clicked. If null Cancel button is not available.
	 * @param {?string} okBtnText - Text appearing on ok button.
	 * @param {?string} cancelBtnText - Text appearing on cancel button.
	 * @param {?string} size - Size of the modal window. Possible values 'sm', 'md', 'lg', 'xl' and 'xxl'. See modal-xl css class for example.
	 * @param {?boolean} [enableBackdrop=false] - Allow outside click to close modal automatically (defaults to false).
	 * @param {?number} buttonMask - Button visibility bits: 1: ok/apply; 2: cancel; 4: close; If not set onOk and onCancel are used for checking button visibility.
	 */
	function openModalMessage($scope, title, message, onOk, onCancel, okBtnText, cancelBtnText, size, enableBackdrop, buttonMask) {
		var buttonMask = buttonMask || (onOk ? 0x1 : 0) | (onCancel ? 0x2 : 0);
		var okButtonText = (okBtnText ? '" ok-btn-text="\'' + okBtnText + '\'"' : '');
		var cancelButtonText = (cancelBtnText ? '" cancel-btn-text="\'' + cancelBtnText + '\'"' : '');
		// Verify from the operator so that he'll realize that move is happening
		return $uibModal.open({
			animation: true,
			backdrop: enableBackdrop || false,
			template: '<dfx-modal title="\'' + title + '\'" button-mask="' + buttonMask + '"' + okButtonText + cancelButtonText + '>' + message + '</dfx-modal>',
			controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
				$scope.onCancel = function () {
					$uibModalInstance.dismiss('cancel');
					if (onCancel) {
						onCancel();
					}
				};
				$scope.onApply = function () {
					$uibModalInstance.close();
					onOk();
				};
			}],
			scope: $scope,
			size: size
		});
	}

	function openPortalSettings(scope, parameterSets) {
		scope.portalSettings = parameterSets;
		var modalInstance = $uibModal.open({
			animation: true,
			backdrop: false,
			template: require('../services/directives/settingsDialog.html'),
			controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
				$scope.onCancel = function () {
					$uibModalInstance.dismiss('cancel');
				};
			}],
			scope: scope
		});
	}

	/**
	 * Launches modal whose id is given as parameter.
	 * @param {string} params - Id of modal that should be launched.
	 */
	function launchmodal(params) {
		if (params && params.toLowerCase() == 'newlead') {
			if (!$injector.has('salesservice')) {
				import('../sales/salesModule').then(function (module) {
					$ocLazyLoad.load(module.default).then(openImported)
				})
			} else {
				openImported();
			}
		}
		if (params && params.toLowerCase() == 'qualityfailure') {
			var qfService = $injector.get('qualityfailureservice');
			if (angular.isDefined(qfService.openQualityFailureModal)) {
				qfService.openQualityFailureModal();
			}
		}

		// Timestamps. Separate modal name and formId from each other.
		const paramsExtended = params.split('&');
		// TODO: Simplify imports to single module inside portal directory.
		if (params && paramsExtended[0] && paramsExtended[0].toLowerCase() == 'timestamps') {
			import("../timestamps/components/TimeStampsEntryModal.tsx").then(function (module) {
				$ocLazyLoad.load(module.timestampsModule).then(function () {
					import('../timestamps/timestampsportalModule').then(function (module) {
						$ocLazyLoad.load(module.default).then(function () {
							var timestampsService = $injector.get('timestampsmodalservice');
							if (angular.isDefined(timestampsService.openTimeStampsEntryModal)) {
								timestampsService.openTimeStampsEntryModal(paramsExtended.length > 1 && !isNaN(paramsExtended[1]) ? parseInt(paramsExtended[1]) : 0);
							}
						})
					})
				})
			})
		}

		function openImported() {
			var service = $injector.get('salesservice');
			if (angular.isDefined(service.openSalesLeadModal)) {
				service.openSalesLeadModal();
			}
		}
	}

	/**
	 * Redirects user to the url which is given in portal tab url.
	 * @param {string} tabType - Portal tab type.
	 * @param {string} tabUrl - Portal tab url.
	 * @param {Object} scope - Caller scope.
	 * @param {boolean} excludeWildCardChar - Excludes '*' catch all-char from tab-url.
	 */
	function redirectFromTabUrl(tabType, tabUrl, scope, excludeWildCardChar) {
		// MENU type means any given url as such.
		if (tabType && tabType == 'MENU') {
			// If tab url starts with javascript: then try to launch the function.
			if (tabUrl.toLowerCase().substring(0, 11) == 'javascript:') {
				var arr = tabUrl.toLowerCase().substring(11).split('?');
				if (arr[0] && this.hasOwnProperty(arr[0])) {
					var params = tabUrl.substring(tabUrl.indexOf('?') + 1);
					this[arr[0]](params ? params : null);
				}
				else {
					alert('Cannot find function ' + tabUrl);
				}
			}
			else {
				$window.location.href = tabUrl;
			}
		} // Other types, typically LIST or DATA, contain route.
		else {
			if (excludeWildCardChar) {
				tabUrl = tabUrl.replace('*', '');
			}
			$location.url(tabUrl);
		}
	}

	// Stores last selected portal id to local storage.
	function setLastPortalId(lsKey, isValidKey, portalId) {
		var lst = {};
		// Try to find old local storage value with given key.
		if (localStorage && localStorage.getItem(lsKey)) {
			lst = JSON.parse(localStorage.getItem(lsKey));
			if (lst) {
				lst.lastPortalId = portalId;
				localStorage.setItem(lsKey, JSON.stringify(lst));
			}
		}
		else if (isValidKey) { // Old local storage value not found, store new one if given key is valid.
			lst.lastPortalId = portalId;
			localStorage.setItem(lsKey, JSON.stringify(lst));
		}
	}

	/**
	*Funtions responcible for getting and setting preventMovement value
	*/
	function getBeforeUnloadValue() {
		return preventMovement;
	}
	//get information about pending changes from dataContexts
	function setBeforeUnloadValue(val, source) {
		if (dcPendingChanges.hasOwnProperty(source)) {
			dcPendingChanges[source].hasChanges = val;
		}
		else if (source) {
			dcPendingChanges[source] = { hasChanges : val }
		}
		for (var i in dcPendingChanges) {
			//if any of the listed dataContext controllers has changes, prevent movement. 
			if (dcPendingChanges[i].hasChanges == true) {
				preventMovement = true;
				break;
			}
			else preventMovement = false;
		}
	}



	/*Function responsible for setting typeahead-objects so that exact matches are shown correctly in typeahead-template
	 *Params: 
	 *objects      -> Array of typeahead objects
	 *sortId       -> Unique identifier of given object (for example 'ID')
	 *searchString -> Typically user typed input which the search matches, transformed to uppercase
	 *doFilter	   -> Filters object array, uses searchString for this
	 *doSort       -> Sorts object array, uses sortId for this. SortOrder: 1)exact matches if found, 2)with given id 
	 *example var matchingRows = common.initializeTypeaheadData(myObjectArray, 'id', 'myproject', false, true)
	 */
	function initializeTypeaheadData(objects, sortId, searchString, doFilter, doSort) {
		searchString = searchString ? searchString.toUpperCase() : null;
		/*Set exact matches*/
		for (var i = 0; i < objects.length; i++) {
			/*Reset possible previous 'isExactMatch'-attributes as not all the queries are done to database*/
			objects[i].isExactMatch = false;
			objects[i].isFirstExactMatch = false;
			objects[i].isLastExactMatch = false;
			if (objects[i][sortId] == searchString) {
				objects[i].isExactMatch = true;
			}
		}
		if (doFilter && searchString) {
			objects = $filter('filter')(objects, searchString);
		}
		/*Sort array that exactMatches are first and with given sortId*/
		if (doSort && sortId) {
			objects.sort(function (a, b) {
				if (a[sortId] && b[sortId]) {
					/*Sort exact matches first into array*/
					if (a.isExactMatch && !b.isExactMatch) {
						return -1;
					}
					else if (b.isExactMatch && !a.isExactMatch) {
						return 1;
					}
					return a[sortId].localeCompare(b[sortId]);
				}
			});
		}
		/*Test if there is any exact matches*/
		if (objects.length >= 1 && objects[0].isExactMatch) {
			/*Typeahead template uses isFirstExactMatch to decide when to show additional header*/
			objects[0].isFirstExactMatch = true;
			/*Find how many exact matches (for example activities in project)*/
			for (var i = 0; i < objects.length; i++) {
				if (!objects[i].isExactMatch) {
					var last = i - 1;
					break;
				}
			}
			if (last || last == 0) {
				/*Typeahead template uses isLastExactMatch to decide when to show additional header*/
				objects[last].isLastExactMatch = true;
			}
		}
		return objects;
	}

	function setTypeheadColumnData(objects, nameCallback, emphasize, margin, maxWidth, appendMoreTag, notificationText, notificationTextTooltip) {
		var maxCharPerColumn = [];
		if (typeof margin === 'undefined' || margin === null) {
			margin = 1;
		}
		var objIndex = 0;
		// Requests the names and calculates max length
		objects.forEach(function (object) {
			objIndex++;
			object['totalWidth'] = 0;
			var columns = [];
			object['typeheadColumns'] = columns;
			object.template = "";
			var name = null;
			var index = 0;
			object.template = "";
			do {
				name = nameCallback(index, object);
				if (name !== null) {
					//console.log('objIndex: ' + objIndex +' column: ' + index + ' , name: ' + name);
					if (typeof maxCharPerColumn[index] == 'undefined') {
						maxCharPerColumn[index] = ('' + name).length;
					}
					maxCharPerColumn[index] = Math.max(maxCharPerColumn[index], ('' + name).length);
					if (maxWidth && typeof maxWidth[index] != 'undefined') {
						maxCharPerColumn[index] = Math.min(maxCharPerColumn[index], maxWidth[index])
					}
					// left & width properties will be filled
					columns.push({
						name: name,
						emphasize: emphasize && emphasize.length > index ? emphasize[index] : false
					});
					index++;
				}
			} while (name !== null);
		});
		// Calculate and set total width for all objects
		var totalWidth = 0;
		maxCharPerColumn.forEach(function (w) {
			totalWidth += (w + margin);
		});
		objects.forEach(function (object) {
			object['totalWidth'] = totalWidth;
			object['showMoreRows'] = false;
		});
		// Sets the left & width properties for all objects
		var left = 0;
		for (var i = 0; i < maxCharPerColumn.length; i++) {
			var width = maxCharPerColumn[i];
			objects.forEach(function (object) {
				object['typeheadColumns'][i]['left'] = left;
				object['typeheadColumns'][i]['width'] = width;
			});
			left += (width + margin);
		}

		//Create html template here instead of using ng-repeat in dfxTypeheadColumns to fix performance issues.
		objects.forEach(function (object) {
			object.typeheadColumns.forEach(function (column) {
				object.template += '<span class="dfx-typeahead-col' + (column.emphasize ? ' dfx-emphasize' : '') + '"style="margin-left:' + column.left + 'em' + '; max-width: ' + column.width + 'em' + '">' + column.name + '</span>';
			})
			object.template = $sce.trustAsHtml(object.template);
		})

		// If the viewed array contains more items than typehead shows
		// we will show the text indicating that more specific filter string
		// is needed 
		if (appendMoreTag && objects.length > 0) {
			objects[objects.length - 1]['showMoreRows'] = true;
		}

		// Add notificationText as last object if given
		if (notificationText && objects[objects.length - 1] && notificationText.length > 0) {
			objects[objects.length - 1]['notificationText'] = notificationText;
			if (notificationTextTooltip && notificationTextTooltip.length > 0) {
				objects[objects.length - 1]['notificationTextTooltip'] = notificationTextTooltip;
			}
			// If there's no objects on the list, but the restriction is active
		} else if (notificationText && notificationText.length > 0 && objects[0] && objects.length === 0) {
			objects[0]['notificationText'] = notificationText;
			if (notificationTextTooltip && notificationTextTooltip.length > 0) {
				objects[objects.length - 1]['notificationTextTooltip'] = notificationTextTooltip;
			}
		}
	}

	// https://github.com/moment/moment/issues/3341
	function showMomentDateAndMonth(locDate) {
		if (locDate) {
			return locDate.replace(new RegExp('[^\.]?' + moment().format('YYYY') + '.?'), '')
		}
		return locDate
	}

	function textContains(text, searchText) {
		if (text === undefined || text === null || searchText === undefined || searchText === null) {
			return false;
		}
		return text && -1 !== text.toLowerCase().indexOf(searchText.toLowerCase());
	}

	function unique(array, key) {
		key = (key ? key : 'id');
		var unique = {};
		var uniqueList = [];
		for (var i = 0; i < array.length; i++) {
			var value = key instanceof Array ? getValue(array[i], key) : array[i][key];
			if (typeof unique[value] == "undefined") {
				unique[value] = "";
				uniqueList.push(array[i]);
			}
		}
		return uniqueList;
		function getValue(item, keyArr) {
			var ret = [];
			keyArr.forEach(function (k) {
				ret.push(item[k]);
			});
			return JSON.stringify(ret)
		}
	}

	//formats number into a localFloatFormat
	//TODO: For now accepts only 6 decimals. More than 6 will cause the value to turn into 'xe-7' format
	function dblToLocalStr(num) {
		if (num || num == 0) {
			if (typeof num == 'number') {
				num = num.toPrecision();
			}
			//clean string from any existing character so ToLocaleString function works
			var sep = getThousandSeparator();
			var neg = isNegativeString(num);
			var temp;

			temp = removeEmptySpace(num); //remove all spaces

			if (sep == ',') {
				temp = removeCommas(temp);
			}
			else {
				temp = replaceCommasDecimals(temp); //replaces commas with dots
			}
			if (neg) {
				temp = changeNegativeChar(temp);
			}
			num = !isNaN(temp) ? parseFloat(temp).toLocaleString(lang, { maximumFractionDigits: 20, minimumFractionDigits: 0 }) : 0;
			return num;
		}
	}
	//formats float number from string to Double for DB saving
	function strToDbl(num) {
		if (num) {
			//clean string from any existing character 
			var sep = getThousandSeparator();
			var neg = isNegativeString(num);
			var temp;

			temp = removeEmptySpace(num); //remove all spaces

			if (sep == ',') {
				temp = removeCommas(temp);
			}
			else {
				temp = replaceCommasDecimals(temp); //removes comma thousandSeparator()
			}
			if (neg) {
				temp = changeNegativeChar(temp);
			}
			num = parseFloat(temp);
			return num;
		}
	}

	//Returns localised thousand separator char
	function getThousandSeparator() {
		var temp = 1000;
		var sep = temp.toLocaleString(lang);
		sep = sep.substring(1, 2);
		return sep;
	}

	function removeEmptySpace(x) {
		if (x) {
			var temp = x.replace(/\s+/g, '');
			return temp;
		}
		return '';
	}

	function removeCommas(x) {
		if (x) {
			var temp = x.replace(/,/g, '');
			return temp;
		}
		return '';
	}

	function changeNegativeChar(str) {
		var temp = str;
		if (str && isNegativeString(str)) {
			var length = temp.length;
			temp = temp.substring(1, length);
			temp = '-' + temp;
			return temp;
		}
		return '';
	}
	function removeCommasDecimals(str) {
		if (str) {
			for (var i = 0; i < str.length; i++) {
				if (str.charAt(i) == ',' && i > 0) {
					var temp = str.substring(0, i);
					return temp;
				}
			}
		}
		return str;
	}
	function replaceCommasDecimals(str) {
		if (str) {
			if (str.indexOf(',') > -1) {
				str = str.replace(',', '.');
			}
		}
		return str;
	}
	function revertChangesToProperty(entity, propertyName) {
		if (entity.entityAspect.originalValues.hasOwnProperty(propertyName)) {
			var origValue = entity.entityAspect.originalValues[propertyName];

			entity.setProperty(propertyName, origValue);
			delete entity.entityAspect.originalValues[propertyName];

			if (Object.getOwnPropertyNames(entity.entityAspect.originalValues).length === 0) {
				entity.entityAspect.setUnchanged();
			}
		}
	}
	function replaceDotDecimals(str) {
		if (str) {
			if (str.indexOf('.') > -1) {
				str = str.replace('.', ',');
			}
		}
		return str;
	}

	function isNegativeString(str) {
		if (str) {
			var char = String(str).charAt(0);
			if (char == '?' || char == '-') {
				return true;
			}
		}
		return false;
	}

	/**
	 * Formats string. Based on breeze's inner __FormatString function
	 * format("a %1 and a %2", "cat", "dog") -> "a cat and a dog"
	 */
	function formatString(string) {
		var args = arguments;
		var pattern = RegExp("%([1-" + (arguments.length - 1) + "])", "g");
		return string.replace(pattern, function (match, index) {
			return args[index];
		});
	}

	/**
	 * Reject cached data. Used eg. in case of unauthorized query to prevent eternally pending promise.
	 */
	function rejectCachedData(cacheKey) {
		var cache = cachedData[getCachedDataKey(cacheKey)];
		if (cache) {
			cache.deferred.reject("query failed");
			cachedData[getCachedDataKey(cacheKey)] = null;
		}
	}

	/**
	 * Clear caching structure
	 */
	function resetCachedData() {
		cachedData = [];
	}

	/**
	 * This will set the actual data to caching structure
	 */
	function setCachedData(fetchData, data) {
		var cache = cachedData[getCachedDataKey(fetchData)];
		if (cache) {
			cache.data = data;
			cache.stamp = new Date().getTime();
			cache.deferred.resolve(cache.data);
		}
	}

	/**
	 * Stores ui-grid table filter and searchString values. 
	 * @param {USERID_PORTALID_TABID} key
	 * @param {ui-grid table filters} objArr
	 * @param {ui-grid stringified table filters} stringifiedFields
	 * @param {search field} searchString
	 */
	function setLastFilterFields(key, objArr, stringifiedFields, searchString) {
		/*if lastFilterFields exists for given key keep it as it is otherwise set it empty arr*/
		lastFilterFields[key] = lastFilterFields[key] ? lastFilterFields[key] : [];
		if (key && objArr && stringifiedFields) {
			lastFilterFields[key].objArr = objArr;
			lastFilterFields[key].stringifiedFields = stringifiedFields;
		}
		if (key && (searchString || searchString == '')) {
			lastFilterFields[key].searchString = searchString;
		}
	}

	function setLastGridId(obj) {
		if (obj) {
			lastGridId = obj;
		}
	}

	// Stores portalInfo into common variable.
	function setPortalInfo(portalinfo) {
		portalInfo = portalinfo;
		tabsAvailablePromise.resolve();
	}

	function stringifyTrackedEntities(entityArray, manager) {
		var exportObj = manager.exportEntities(entityArray, { asString: false, includeMetadata: false });
		var entityArrayExport = exportObj.entityGroupMap[Object.keys(exportObj.entityGroupMap)[0]].entities;
		return JSON.stringify(entityArrayExport);
	}
	/**
	 * Constructs class used by idthk-common data reading
	 * @param {objectType } objectType 
	 * @param {objectId1 } objectId1
	 * @param {objectId2 } objectId2
	 * @param {objectId3 } objectId3
	 */
	function toObjId(objectType, objectId1, objectId2, objectId3) {
		return {
			objectType: objectType,
			objectId1: objectId1,
			objectId2: objectId2,
			objectId3: objectId3
		};
	}

	/**
	 * Constructs class used by idthk-common data reading
	 * @param {objectType } objectType 
	 * @param {recId } recId 
	 * @param {name } name 
	 * @param {objectHeader } objectHeader 
	 * @param {objectRow } objectRow 
	 * @param {lst } lst ?? 
	 */
	function toObjRecId(objectType, recId, name, objectHeader, objectRow) {
		return {
			recId: recId,
			objectType: objectType,
			objectHeader: objectHeader,
			objectRow: objectRow,
			name: name,
			lst: []
		};
	}

	/**
	 * Gets locale data from moment library. 
	 */
	function getLocaleData() {
		var lng = (lang ? lang : 'en');
		moment.locale(lng);
		return moment.localeData();
	}

	/**
	 * Returns long date format either in moment or angular format.
	 * @param {boolean} forAngularFormat - Set to true if you want to have angular format instead of moment format.
	 * @returns {string} Date format.
	 */
	function longDateFormat(forAngularFormat) {
		var longDateFmt = getLocaleData() ? getLocaleData()._longDateFormat.L : 'MM/DD/YYYY';
		if (forAngularFormat)
			return longDateFmt.replace('DD', 'dd').replace('YYYY', 'yyyy');
		else
			return longDateFmt;
	}

	/**
	 * Returns long time format.
	 * @returns {string} Time format.
	 */
	function longTimeFormat() {
		return getLocaleData() ? getLocaleData()._longDateFormat.LT : 'h:mm A';
	}

	function getDayMonthFormat() {
		// Get locale data
		var localeData = moment.localeData();
		var format = localeData.longDateFormat('L')
		// Check locale format and strip year
		if (format.match(/.YYYY/g)) {
			format = format.replace(/.YYYY/, '');
		}
		if (format.match(/YYYY./g)) {
			format = format.replace(/YYYY./, '');
		}

		return format;
	}

	/**
	 * Used to trigger resize event used by dfxFillHeight to manipulate layout
	 * @param {delay } delay in milliseconds
	 */
	function triggerResizeEvent(delay) {
		$timeout(function () {
			if (typeof (Event) === 'function') {
				// modern browsers
				$window.dispatchEvent(new Event('resize'));
			} else {
				//This will be executed on old browsers and especially IE
				var resizeEvent = $window.document.createEvent('UIEvents');
				resizeEvent.initUIEvent('resize', true, false, $window, 0);
				$window.dispatchEvent(resizeEvent);
			}
		}, delay ? delay : 0);
	}
}

export default commonModule;