var elementDataModule=angular.module('manudir.dfxWorkElementData',[])
elementDataModule.directive('dfxWorkElementData', ['common', 'workelements_service', 'usersettings', function (common, workelements_service, usersettings) {
    return {
        restrict: 'E',
        scope: {},
        controllerAs: 'ctrl',
        bindToController: {
            workElement: '=',
            workOperation: '=',
            resource: '=',
            editAllowed: '=',
            headerMask: '=?', // 0 = No additional elements after header. 1 = Check box after header (default). 2 = Icon after header.
            materialLayout: '=?' // DYNAMIC/TABLE
        },
        controller: ['$scope', function ($scope) {
            var ctrl = this;

            ctrl.$onInit = function () {
                ctrl.scrollToRecId = 0;
                ctrl.state = workelements_service.getState();
                ctrl.workElementArr = [];
                ctrl.adjustTime = common.adjustTime;
                ctrl.headerMask = ctrl.headerMask != undefined ? ctrl.headerMask : 1;
            };
            

            $scope.$watch('ctrl.workElement.recId', function () {
                initWorkElementData();
            });

            function initWorkElementData() {
                if (ctrl.workElement && ctrl.workElement.childElements) {
                    ctrl.workElementArr = ctrl.workElement.childElements.length > 0 ? ctrl.workElement.childElements : [ctrl.workElement];
                    ctrl.scrollToRecId = ctrl.workElementArr[0].recId;
                    common.$timeout(function () { ctrl.scrollToRecId = null; }, 100);

                    angular.forEach(ctrl.workElementArr, function (we) {
                        delete we.isPanelOpen;
                        if (ctrl.state.collapseElementWithNoInputDefault === 'COLLAPSE') {
                            if (workelements_service.getDataRowCount(we, ['QLT', 'MATERIAL', 'TOOL'], false) > 0) {
                                togglePanel(we);
                            }
                        }
                        else {
                            togglePanel(we);
                        }
                    });
                }
            }

            /**
             * Initializes hidePicture variable.
             * @param {object} we - workElement
             */
            ctrl.initHidePicture = function (we) {
                // If non null enumeration value is defined, it will rule the behaviour
                if (ctrl.state.collapseElementPicturesDefault) {
                    we.hidePicture = ctrl.state.collapseElementPicturesDefault === 'COLLAPSE';
                }
                else {
                    we.hidePicture = !usersettings.getPersonalSettingValue('expandWorkElemImages', true);
                }
            };

            /**
			 * Returns check box value.
			 * @param {LeanPortalSPA.dfxWorkElementData} we - Work element group entity.
			 * @return {?boolean} Check box value.
             * @memberOf LeanPortalSPA.dfxWorkElementData
			 */
            ctrl.getCheckBoxValue = function (we) {
                return (ctrl.headerMask & 1) ? we.ready : null;
            };

            /**
             * Returns css class for collapsable panel header based on work element data.
             * @param {LeanPortalSPA.dfxWorkElementData} we - Work element group entity.
             * @return {string} Css class name.
             * @memberOf LeanPortalSPA.dfxWorkElementData
             */
            ctrl.getCollapsablePanelCustomHeaderClass = function (we) {
                // Header mask needs to be set 2 in order to show an icon.
                if (we && ctrl.headerMask & 2) {
                    // If group does not have any fillable child elements, return info.
                    var dataRows = workelements_service.getWorkElementData('QLT', we).length + workelements_service.getWorkElementData('MATERIAL', we).length;
                    if (dataRows === 0) return 'dfx-work-element-group-header__info';
                    var target = 'MISSING_MANDATORY';
                    var missing = workelements_service.getDataCount('QLT', we, false, target) + workelements_service.getDataCount('MATERIAL', we, false, target);
                    if (missing === 0) return 'dfx-work-element-group-header__all-ready';
                }
                // Else return empty string.
                return '';
            };

			/**
			 * Queries data defined by type for given work element
			 */
            ctrl.getData = function (type, we) {
                return workelements_service.getWorkElementData(type, we);
            }

			ctrl.getQltEditMode = function (we) {
				if (typeof we.qltEditAllowed == 'undefined')
					return ctrl.editAllowed ? 2 : 0; // Old functionality used if model doesn't support property (form not available)
				return we.qltEditAllowed ? 2 : 0;
			};

            function getMissingMandatoryData(we) {
                var matDataMissing = false;
                var toolDataMissing = false;
                var missingMatData = '<div>' + common.$translate.instant("STRCONST.REP.TXT_MATERIALS") + ":" + '</div>';
                var missingToolData = '<div>' + common.$translate.instant("STRCONST.WOR.HDR_MAT_TOOLS") + ":" + '</div>';

                angular.forEach(workelements_service.getWorkElementData('MATERIAL', we), function (mat) {
                    var data = '';
                    if (mat.serialIdMandatory && !mat.serialId) {
                        data += common.$translate.instant("STRCONST.PUBLIC.TXT_SERIALID");
                    }
                    if (mat.traceMandatory && !mat.itemSpec) {
                        data += (data.length ? ', ' : '') + common.$translate.instant("STRCONST.WOR.TXT_ITEMSPEC");
                    }
                    if (mat.dynamicData) {
                        angular.forEach(mat.dynamicData, function (qltData) {
                            if (qltData.mandatory && qltData.valueMissing()) {
                                data += (data.length ? ', ' : '') + qltData.name;
                            }
                        });
                    }
                    if (data.length) {
                        missingMatData += '<div>' + mat.itemId + ' ' + mat.name + ':\t' + data + '</div>';
                        matDataMissing = true;
                    }
                });
                angular.forEach(workelements_service.getWorkElementData('TOOL', we), function (toolRow) {
                    var data = '';
                    if (toolRow.toolIdMandatory && !toolRow.deviceId) {
                        data += (data.length ? ', ' : '') + common.$translate.instant("STRCONST.WOR.HDR_USED_TOOLID");
                    }
                    if (data.length) {
                        missingToolData += '<div>' + toolRow.toolId + ' ' + toolRow.name + ':\t' + data + '</div>';
                        toolDataMissing = true;
                    }
                });
                if (matDataMissing || toolDataMissing) {
                    return (matDataMissing ? missingMatData + '<br>' : '') + (toolDataMissing ? missingToolData : '');
                }
                return null
            }

			/**
			 * Queries data defined by type for given work element
			 */
            ctrl.hasData = function (type, we) {
                var hasData = workelements_service.hasWorkElementData(type, we);
                if (hasData && (type == 'DOC' || type == 'PICTURE')) {
                    // If content is missing the don't show
                    angular.forEach(workelements_service.getWorkElementData(type, we), function (doc) {
                        if (!doc.isContentLoadingReady()) {
                            hasData = false;
                        }
                    });
                }
                return hasData;
            }

            /*CheckBox click event callback, Sets WorkElement(child) ready state according to checkbox value and calls saveChanges()*/
            ctrl.markWorkElementReady = function (value, we) {
                var missingData = getMissingMandatoryData(we);
                if (value && missingData) {
                    common.openModalMessage($scope, common.$translate.instant("STRCONST.DLG.TXT_REQDATAMISSING") + "!", missingData,
                        function () { }, null, common.$translate.instant("STRCONST.PUBLIC.BTN_OK"));
                }
                else {
                    we.ready = value;
                    togglePanel(we);
                }
            }

            /*Refresh comments for workelements. ToDo: Current implementation fetches all data and not just comments..*/
            ctrl.refreshComments = function () {
                workelements_service.getData('workelements_tab', true);
            }
            /*helper function to control if panel should be open/closed*/
            function togglePanel(we) {
                if (we) {
                    we.isPanelOpen = we.ready ? false : true;
                }
            }
        }],
        template: require('./dfxWorkElementData.html')
    }
}]);
export default elementDataModule
