'use strict';

var typeAheadModule = angular.module('dir.dfxTypeAhead',[]);
/**
	 * Generic type ahead selection that uses given form&version to fetch items from the DB
	 */
typeAheadModule.directive('dfxTypeAhead', function () {
	return {
		restrict: 'E',
		scope: {},
		controllerAs: 'ctrl',
		bindToController: {
			autoSelectSingleMatch: '=?',	//auto select typeahead value if the search matches exactly one row.
			focusTrigger: '=?',		// focus the typeahead input when trigger changes.
			formId: '=?',			// defines the form that will be used for getting selectables
			formVersion: '=',		// defines the form version Toiminnot/SELECT_
			sortCmd: '=?',		    // parametes for GenericFormDataSet
            restrictionCmdArr: '=?',// parameter for GenericFormDataSet
            logicalId: '=?',        // parameter for GenericFormDataSet
			lookupParams: '=?',     // parameter for GenericFormDataSet
			dbFilterColumns: '=?',	// Field definitions used to filter columns when user types filtering string (used in sql query). Can be used to separate similar column names in different tables. If this is left blank filterColumns is used instead. 
			filterColumns: '=',		// Field definitions used to define viewed columns (= typeahead visible columns).
			filterColumnMaxWidths: '=?', // Type ahead column max widths list.
			showInUi: '=',			// Fields shown in the input field when selection is made
			targetEntity: '=?',		// Used in initialization and when selection is done (requires corresponding propertyMap)
			placeholder: '=?',		// Placeholder for the input.
			propertyMap: '=?',		// Matches the column in targetEntity to the column value in selectable item
			parentProperty: '=?',   // Property whose change will trigger update. Used in parent - child relation or some other two level hierarchies.
			selectId: '=?',         // JSON must match propertyMap ID definition
			showItems: '=?',		// Number of items shown in type ahead
			typeAheadWaitMs: '=?',	// delay before filterin starts
			title: '=?',		    // title shown as tooltip. OBSOLETE: use titleText instead (title behaves badly e.g. when control is disabled) 
			titleText: '=?',		// title shown as tooltip
			disable: '=?',
            customClass: '=?',
			appendBody: '=?',
			valueList: '=?',
			openDropdownOnFocus: '=?',	//automatically open dropdown on focus (default true).
			onSelectionCallback: '&',
			onControllerCallback: '&'
		},
		controller: ['$scope', 'commonDatacontext', 'common', function ($scope, commonDatacontext, common) {
            var ctrl = this;
            var dbFilterColumns;
            var filterColumns;
            var searchColumns;
            var showInUI;
            var propertyMap = [];
            ctrl.itemList = [];
            ctrl.lastFetchFilter = '';
            var resolvePromise;
			

            ctrl.$onInit = function () {
                ctrl.openDropdownOnFocus = ctrl.openDropdownOnFocus == undefined ? true : ctrl.openDropdownOnFocus;
                if (ctrl.showItems === undefined) {
                    ctrl.showItems = 14;
                }
                if (ctrl.typeAheadWaitMs === undefined) {
                    ctrl.typeAheadWaitMs = 500;
				}			
                // TODO: This hard coded data can be replaced with data from form settings! 
                // !!! OBS. propertyMap should be given as parameter while it relates to targetEntity!!!
                dbFilterColumns = ctrl.dbFilterColumns || ctrl.filterColumns; // Filter columns when user types filtering string
                filterColumns = ctrl.filterColumns; // Define viewed columns
                searchColumns = getSearchColumns(filterColumns);
                showInUI = ctrl.showInUi;

                // Matches the column in targetEntity and to the column in selectable item
                // When item is selected in the list the target object is filled with given column datas
                // !!!Examples use ResourceTask as target object!!!
                // 1.item: property name in target object
                // 2.item: property name in selectable item
                // 3.item: true if the property is used in matching
                propertyMap = ctrl.propertyMap ? ctrl.propertyMap :
                              ctrl.formId == 29066 ? [['EMP_ID', 'EMPID', true], ['EMP_NAME', 'NAME', false]] :
                              ctrl.formId == 29015 ? [['ITEMID', 'ITEMID', true], ['ITEMNAME', 'NABBR', false]] :
                              ctrl.formId == 29056 ? [['DRAWING', 'DRAWING', true], ['DRAWING_REVISION', 'DRAWING_REVISION', true]] :
                              [];

				activate();
				ctrl.onControllerCallback({ childCtrl: service });
            };

			$scope.$watchGroup(['ctrl.selectId', 'ctrl.targetEntity'], function (newVals, oldVals) {
				if (ctrl.targetEntity && ctrl.targetEntity.entityType && ctrl.modelColumnMap === undefined) {
					ctrl.modelColumnMap = [];
                    commonDatacontext.getModelColumnMap(ctrl.targetEntity.entityType.shortName).then(function (columnMap) {
                        ctrl.modelColumnMap = columnMap;
                        defineSelection();
                    });
				}
				else {
					defineSelection();
				}
			});

			$scope.$watch('ctrl.parentProperty', function (newValue, oldValue) {
				if (newValue != oldValue) {
					defineSelection();
				}
			});
			$scope.$watch('ctrl.disable', function (newValue, oldValue) {
				if (newValue != oldValue) {
					triggerLoading();
				}
			});
			$scope.$watch('ctrl.lookupParams', function (newValue, oldValue) {
				if (newValue != oldValue) {
					triggerLoading();
				}
			});

			$scope.$watch('ctrl.formVersion', function (newValue, oldValue) {
				if (newValue != oldValue) {
					triggerLoading();
				}
			});

			$scope.$watch('ctrl.valueList', function (newValue, oldValue) {
				if (newValue != oldValue) {
					activate();
				}
			});

			function triggerLoading(discardCache) {
				if (!ctrl.disable) {
					if (!ctrl.formSettings) {
						activate();
					}
					else {
						getTypeAheadData(null, false, discardCache);
					}
				}
			}


			/**
             * This will dig the the property values from the entity or typeahead selection item
             */
			function getIdValuesFromObject(from, isTargetEntity, allValues) {
				var values = [];
				var properties = [];
				angular.forEach(propertyMap, function (p) {
					if (allValues || p[2]) {
						var propName = p[isTargetEntity ? 0 : 1];
						if (isTargetEntity) {
							propName = ctrl.modelColumnMap[propName];
						}
						values.push(from[propName]);
					}
				});
				return values;
			}
			/**
             * Selects correct item from the list
             * First go through the items that are read at start up. 
             * If not found then fetch exact item
             */
			function defineSelection() {
				ctrl.initLoad.then(function () {
					var values = null;
					if (ctrl.targetEntity && ctrl.modelColumnMap) {
						values = getIdValuesFromObject(ctrl.targetEntity, true);
					}
					else if (ctrl.selectId) {
						values = getIdValuesFromObject(ctrl.selectId, false);
					}

					if (values && values.length > 0) {
						for (var i = 0; i < ctrl.itemList.length; i++) {
							if (values[0] === null) {
								ctrl.itemSelected(values[0]);
								return;
							}
							else {
								var cmpTo = getIdValuesFromObject(ctrl.itemList[i], false);
								if (JSON.stringify(values) === JSON.stringify(cmpTo)) {
									ctrl.itemSelected(ctrl.itemList[i]);
									generateUiName(ctrl.curSelection);
									return;
								}
							}
						}
                        // No matches found, create a filter string and search from database.
                        var filterString = generateFilterString(values);

                        if (Object.keys(filterString).length > 0) {
                            getMatch(filterString, values);
						}
					};
				});
            }

            /**
             * Create filter string using propertyMap.
             * @param {any} values
             */
            function generateFilterString(values) {
                var filterString = {};
                var index = 0;
                angular.forEach(propertyMap, function (p) {
                    if (p[2]) {
                        if (index < values.length && values[index]) {
                            for (var i = 0; i < filterColumns.length; i++) {
                                if (filterColumns[i] === p[1]) {
                                    filterString[filterColumns[i]] = values[index];
                                    break;
                                }
                            }
                        }
                        index++;
                    }
                });
                return filterString;
            }

			/**
             * Strip array of table column names without table reference
             */
			function getSearchColumns(colArr) {
				var ret = [];
				angular.forEach(colArr, function (e) {
					ret.push(e.indexOf('.') > -1 ? e.split('.')[1] : e);
				});

				return ret;
            }
            /**
             * Get matching data from database.
             * @param {any} filterString - Filter to be used in search.
             * @param {any} values - Values to compare to if more than one result.
             */
            function getMatch(filterString, values) {
                commonDatacontext.getGenericDataSet(null, JSON.stringify(filterString), ctrl.formId, ctrl.formVersion, ctrl.sortCmd, ctrl.restrictionCmdArr, ctrl.logicalId, ctrl.lookupParams, ctrl.formSettings.formName).then(function (data) {
                    var item = data && data[0] && data[0].datatable ? data[0].datatable : [];
                    if (item.length == 1) {
                        ctrl.itemSelected(item[0]);
                        ctrl.curSelection = item[0];
                        generateUiName(ctrl.curSelection);
                    } else if (item.length > 1) {
                        for (var i = 0; i < item.length; i++) {
                            var cmp = getIdValuesFromObject(item[i], false);
                            if (JSON.stringify(values) === JSON.stringify(cmp)) {
                                ctrl.itemSelected(item[i]);
                                ctrl.curSelection = item[i];
                                generateUiName(ctrl.curSelection);
                            }
                        }
                    }
                });
            }

			/**
             * Gets type ahead items from local database.
             * Return promise that does actual reading
             */
			function getTypeAheadData(filterString, requestedByUi, discardCache) {
				var searchString = null;
				var useFilter = filterString && filterString != '' && filterString != ' ' ? filterString : '';
				if (useFilter) {
				    searchString = '%' + useFilter + '%';
				}
				ctrl.lastFetchFilter = useFilter;
				if (ctrl.valueList != undefined) { 
					ctrl.itemList = ctrl.valueList;
					return ctrl.itemList;
				}
				else {

				
				//common.logger.logSuccess('Kantahaku filter stringillä: ' + ctrl.lastFetchFilter, null, null, true);
					return commonDatacontext.getGenericDataSet(searchString, null, ctrl.formId, ctrl.formVersion, ctrl.sortCmd, ctrl.restrictionCmdArr, ctrl.logicalId, ctrl.lookupParams, ctrl.formSettings.formName, requestedByUi, discardCache).then(function (data) {
					ctrl.itemList = data && data[0] && data[0].datatable ? data[0].datatable : [];
					resolvePromise();
					return ctrl.itemList;
				});
				}
				
			}

			function generateUiName(item) {
				var name = '';
				angular.forEach(showInUI, function (col) {
					if (item[col]) {
						name += ((name.length ? ' ' : '') + item[col]);
					}
				});
				item.UI_NAME = name;
			}

			/**
             * Filters ctrl.itemList with given string
             * @param {searchFilter used in matching} searchFilter
             */
			ctrl.filteredItemList = function (searchFilter) {
				var matching = [];
				var showMoreItemsText = false;
				// null, '' and ' ' are interpreted as no filter string
				var useInSearch = searchFilter && searchFilter != '' && searchFilter != ' ' ? searchFilter : '';
				for (var i = 0; i < ctrl.itemList.length ; i++) {
					var item = ctrl.itemList[i];
					if (useInSearch.length) {
						for (var colInd = 0; colInd < searchColumns.length; colInd++) {
							if (item[searchColumns[colInd]] && common.textContains(item[searchColumns[colInd]], useInSearch)) {
								matching.push(item);
								break;
							}
						}
					}
					else {
						matching.push(item);
					}
					// Enough matching items were found => no need to continue
					if (matching.length == ctrl.showItems) {
						showMoreItemsText = (i < ctrl.itemList.length - 1);
						break;
					}
				}
				// Filtering criteria has changed. More reading might be needed.
				if (useInSearch != ctrl.lastFetchFilter) {
					var triggerReading = true;
					// if lastFetchFilter is part of new  useInSearch <=> more precise filtering
					if (ctrl.lastFetchFilter.length == 0 || useInSearch.indexOf(ctrl.lastFetchFilter) !== -1) {
						// read more if less than showItems and more items exist
						triggerReading = matching.length < ctrl.showItems && hasMoreDataInDb();
					}
					if (triggerReading) {
						return getTypeAheadData(useInSearch).then(function (data) {
							return ctrl.filteredItemList(useInSearch);
						});
					}
				}

				common.setTypeheadColumnData(matching, getTypeheadName, [true], 0, ctrl.filterColumnMaxWidths, showMoreItemsText);
				// Generate name shown when the selection is done
				angular.forEach(matching, function (item) {
					generateUiName(item);
				});
				if (matching.length == 1 && ctrl.autoSelectSingleMatch) {
					ctrl.itemSelected(matching[0]); 
				}

				return matching;
			}

			/**
             * Provides data for typehead columns
             */
			function getTypeheadName(column, item) {
				return column < searchColumns.length ? item[searchColumns[column]] ? item[searchColumns[column]] : '' : null;
			}

			/**
             * The form returns only predefined amount of rows. 
             * Read more if the data is filtered under the viewed amount
             */
			function hasMoreDataInDb() {
				if (ctrl.valueList != undefined) {
					return false;
				}
				else {
					var cmp = ctrl.formSettings.maxRows || 50; //use 50 if maxRows is not specified.
					return ctrl.itemList.length == cmp + 1;
				}
                
			}
			 
			/**
             * User was selected
             * @param item 
             */
			ctrl.itemSelected = function (item) {
				ctrl.curSelection = item;
				// fill the values to the entity
				if (ctrl.targetEntity) {
					angular.forEach(propertyMap, function (p) {
						ctrl.targetEntity[ctrl.modelColumnMap[p[0]]] = (item === null ? null : item[p[1]]);
					});
				}
				ctrl.onSelectionCallback({ value: item });
			}

			/**
             * Clear selection
             */
			ctrl.removeSelection = function () {
				ctrl.itemSelected(null);
			}

			var service = {
				removeSelection: ctrl.removeSelection,
				triggerLoading: triggerLoading
			};

			// This will figure out what is shown in selection box&list and to what data is used to filter the list
			function checkFormVersionDefinitions() {
				if (ctrl.formVersion && ctrl.formSettings.formVersions[ctrl.formVersion]) {
					var fields = [];
					angular.forEach(ctrl.formSettings.formVersions[ctrl.formVersion], function (field) {
						fields.push(field);
					});
					fields.sort(function (a, b) {
						return a.Index < b.Index ? -1 : a.Index > b.Index ? 1 : 0;
					});

					// IsReturnable == false <=> shown in List and used in filter, but not shown in UI
					// filter: shown in slection list
					// search: typed text will be matched to these
					// ui: shown in selection box
					var addToFilter = !filterColumns || !filterColumns.length;
					var addToSearch = !searchColumns || !searchColumns.length;
					var addToUi = !showInUI || !showInUI.length;
					if (addToFilter || addToSearch || addToUi) {
						if (addToFilter) filterColumns = [];
						if (addToSearch) searchColumns = [];
						if (addToUi) showInUI = [];
						angular.forEach(fields, function (field) {
							if (!field.IsHidden) {
								if (addToFilter) {
									filterColumns.push(field.ColumnName);
								}
								if (addToSearch) {
									searchColumns.push(field.ColumnName);
								}
								if (addToUi && field.IsReturnable) {
									showInUI.push(field.ColumnName);
								}
							}
						});
					}
				}
			}

			function activate() {
				if (ctrl.formId > 0 || ctrl.valueList) {
					ctrl.initLoad = new Promise(function (resolve, reject) {
						resolvePromise = resolve;
						if (ctrl.formId > 0) {
						commonDatacontext.getFormSettings(ctrl.formId, false).then(function (data) {
                            ctrl.formSettings = data;
							checkFormVersionDefinitions();
                            if (!ctrl.disable) {
                                getTypeAheadData(null, false);
                            } else {
                                resolvePromise();
                            }
							});
						}
						else {
							getTypeAheadData(null, false);
							resolvePromise();
						}
					});
				}
			}
		}
		],
		template: require('./dfxTypeAhead.html')
	};
});
export default typeAheadModule