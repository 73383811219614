
    'use strict';

    var serviceId = "model";

    var modelModule= angular.module('common').factory(serviceId, model);

    function model() {

    	// Define the functions and properties to reveal.
    	var entityNames = {
    		workTimeBatch: 'WorkTimeBatch',
    		workTime: 'WorkTime',
			favourite: 'Favourite'
    	};

        var service = {
        	configureMetaDataStore: configureMetaDataStore,
        	//createNulls: createNulls,
        	entityNames: entityNames,
        	//extendMetadata: extendMetadata
        };

        return service;

        function configureMetaDataStore(metadataStore) {
        	//registerWorkTimeBatch(metadataStore);
        	//registerWorkTime(metadataStore);
        	//modelValidation.createAndRegister(entityNames);
        }

		/*
        function extendMetadata(metadataStore) {
        	modelValidation.applyValidators(metadataStore);
        }
		*/

    }

export default modelModule