
	'use strict';
	var controllerId = 'baseController';
	var signalrRsrc = null;
	var basecontrollerModule= angular.module('common').controller(controllerId, ['$scope', 'common', 'signalRManager', 'usersettings', '$location', basecontroller]);

	/**
	 * Base controller contains some common functionality needed by other controllers.
	 * The idea is to minimize boilerplate coding. The functionality can be appended to
	 * another controller with:
	 *     	angular.extend(this, $controller('baseController', { $scope: $scope }));
	 *
	 * Implemented functionality
	 * - authorized login support
	 * - SignalR monitoring
	 * 
	 * SignalR usage 
	 * - initialize with ctrl.base.initSignalR with listener functionality
	 * - change target object with ctrl.base.signalrTargetChanged
	 * 
	 */
	function basecontroller($scope, common, signalRManager, usersettings, $location) {
		var ctrl = this;
		var onSignalrChange = null;
		var showOnSignalrChangeMissing = true;
		var hub;
		var getLogFn = common.logger.getLogFn;
		var log = getLogFn(controllerId);
		var logSuccess = getLogFn(controllerId, 'success');
		var warnMsg = getLogFn(controllerId, 'warning');
		var logErr = getLogFn(controllerId, 'error');

		var base = {
			companyOpuChanged: false,
			obsolete: false,	// route changed and hence this controller is osolete
			// API
			activate: activate,
			initSignalR: initSignalR,
			signalrTargetChanged: signalrTargetChanged,
			Target: Target
		}

		ctrl.base = base;

		$scope.$watchGroup([function () {
			return signalRManager.getLastCallback().counter;
		}], function (newValue, oldValue) { // Trigger on signalR event
			var cb = signalRManager.getLastCallback();
			if (newValue >= 0 && oldValue >= 0 && newValue !== oldValue && cb && cb.result) {
				if (onSignalrChange) {
					onSignalrChange(cb.result);
				}
				else if (showOnSignalrChangeMissing) {
					logErr("Controller is missing onSignalrChange implementation!");
					showOnSignalrChangeMissing = false;
				}
			}
		});

		/** Constructor for a target for SignalR listening. Ex: listener for changes on WORK level in COMMENTS for WORKELEMENT
		 * @param  {parent listener object, on which level listener is set globally. Required. Ex: 'WOR_WORK'} listenerObject 
		 * @param  {prentObject's recId. Required. Ex: <work_recId>} listenerObjectRecId
		 * @param  {id of parent's object. Required. Ex: <workId>} listenerObjectHdr 
		 * @param  {object whose changes are moitored. Required. Ex: 'DLG_COMMENT'} eventSourceObject 
		 * @param  {department} dep
		 * @param  {distinction for chancges being monitored. Not required. Ex: 'WORK_ELEMENT'} eventSourceSpecifier
		 */
		function Target(object, recId, hdr, source, dep, specifier) {
			this.listenerObject = object;
			this.listenerObjectRecid = recId ? recId : 0;
			this.listenerObjectHdr = hdr;
			this.eventSourceObject = source;
			this.eventSourceSpecifier = specifier;
			this.dep = dep;
		}


		function initSignalR(listener) {
			onSignalrChange = listener;
			hub = signalRManager.backEndFactory(signalRManager.defaultServer, 'commonHub', { "key": "value" }); //create the hubProxy with state params
			hub.connectionReady.then(function (data) {
				hub.on('broadcastChange', function (targetId) {//subscribe to the broadcastChange event
					//do something (currently handled elsewhere through signalRManager.getLastCallback())
				});
				hub.on('broadcastStop', function (message) {
					hub.connection.stop();
					console.log("[SignalR] " + message);
				});
			});
		}

		function signalrTargetChanged(targets, prevTargets) {
			if (hub) {
				hub.connectionReady.then(function (data) {
					if (base.obsolete) {
						return;
					}
					angular.forEach(targets, function (target) {
						hub.invoke('setListener', JSON.stringify(target), function () { }); //invoke the setListener() function on commonHub						
						angular.forEach(prevTargets, function (prevTarget) {
							if (prevTarget && target !== prevTarget) {
								hub.invoke('leaveGroup', JSON.stringify(prevTarget), function () { }); //invoke the setListener() function on commonHub								
							}
						});
					});
				});
			}
		}

		$scope.$on('COMPANYOPUCHANGES', function () {
			base.companyOpuChanged = true;
		});

		/**
		 * Controller initialisation. Will be called from extended controller.
		 */
		function activate() {
			var promises = [];
			var ret = true;
			promises.push(usersettings.getUserSettings().then(function (data) {
				if (!data || !data.info || !data.info.userId) {
					usersettings.redirectToLogin();
					ret = false;
				} else {
					usersettings.authorizeHeader();
				}
			}, function () {
				usersettings.redirectToLogin();
				ret = false;
				base.companyOpuChanged = false;
			}));
			return common.$q.all(promises).then(function () {
				// trigger event to calculate height for dfx-fill-height
				common.triggerResizeEvent();
				// Second event after delay, while some times the calculation is not correct righ on time
				// Obs. dfx-fill-height and all of these triggerResizeEvent should be removed when full flex solution is ready / KKo
				common.triggerResizeEvent(5000);
				return ret;
			});
		}
	}

export default basecontrollerModule
