'use strict';
var commentButtonModule = angular.module('dir.dfxAddCommentButton',[]);
/**
	 * Simple Add comment dialog. Comment addition containing also existing comments can be found in separate addComment directive.
	 */
var addCommentButtonCtrlId = 'addCommentButton';
commentButtonModule.directive('dfxAddCommentButton', [function () {
    return {
        restrict: 'E',
        scope: {},
        controllerAs: 'ctrl',
        bindToController: {
            parent: '=',
            onAddedCallback: "&"
        },
        controller: ['$scope', '$uibModal', function addCommentButton($scope, $uibModal) {
            var ctrl = this;

            ctrl.openModal = function () {
                $scope.parent = ctrl.parent;
                $scope.onAddedCallback = ctrl.onAddedCallback;
                var modalInstance = $uibModal.open({
                    animation: true,
                    backdrop: false,
                    size: 'md',
                    template: require('./dfxAddCommentModal.html'),
                    controller: ['$scope', 'commonDatacontext', '$uibModalInstance', function ($scope, commonDatacontext, $uibModalInstance) {
                        var ctrl = this;
                        $scope.onCancel = function () {
                            $uibModalInstance.dismiss('cancel');
                        };
						$scope.onApply = function () {
							if (ctrl.newComment && ctrl.newComment.length > 0) {
								commonDatacontext.saveChanges(createEntity(ctrl.newComment)).then(function () {
                                    $uibModalInstance.close();
                                    $scope.onAddedCallback();
                                });
                            }
                        }
                        function createEntity(value) {
                            return commonDatacontext.commonManager.createEntity('Comment', {
                                commentText: value,
                                objectType: ctrl.parent.objRecId().objectType,
                                objectRecId: ctrl.parent.objRecId().recId
                            });
                        }
                    }],
                    controllerAs: 'ctrl',
                    bindToController: {
                        parent: '=',
                        onAddedCallback: "&"

                    },
                    scope: $scope
                });
            };
        }],
        template: require('./dfxAddCommentButton.html')
    }
}]);
export default commentButtonModule