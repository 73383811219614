// Multiple Quality Data "groups"
var qualityDatasModule=angular.module('quadir.dfxQualityDatas',[])
qualityDatasModule.directive('dfxQualityDatas', [function () {
    return {
        restrict: 'E',
        scope: {},
        controller: 'qualitydatas',
        controllerAs: 'ctrl',
        bindToController: {
            parent: '=?',
            formIds: '=?',
            filterValue: '=?',
            showItemInTitle: '=',
            qualityDataList: '=?',
            showEmpId: '=?',
            allowCopy: '=?',
            rowBasedUnlocking: '=?',
            allowConfirm: '=?',
			inputElemClass: '=?', // Css classes to be added to input, textarea, select etc.
			usePivot: '=?', // Use header/subheader type rows to pivot normal rows into columns.
			hideTitleStatus: '=?',
			restrictionCmdArr: '=?',
            onLoadingReadyCallback: '&',
            onControllerCallback: '&'
        },
        template: require('./qualitydatas.html')
    };
}]);

qualityDatasModule.controller('qualitydatas', ['$scope', 'common', 'qualityDatacontext', 'usersettings', qualitydatas]);

function qualitydatas($scope, common, qualityDatacontext, usersettings) {
    var ctrl = this;

    $scope.$on('REJECTCHANGES', function (event, args) {
        if (args.param === true) {
            qualityDatacontext.rejectChanges();
        }
    });


    // Quality datas for given object
    ctrl.qtyDatas = [];
    ctrl.rowData = [];
    ctrl.qltDataStatusList = [];
	ctrl.childControllers = [];

	// Grouping: show all similar groups in one collapsable panel with prev and next buttons (true) OR show each group in separate collapsable panel (false).
	// Array of quality data groups. Used when qtyData.useGrouping = true.
	var qtyDataGroups = [];

    // Pager
    ctrl.currentPage = 1;
    ctrl.rowsPerPage = 10;
    ctrl.maxPages = 12;

    // API offered for the client with onControllerCallback
    var service = {
        getMissingMandatoryDataMsg: getMissingMandatoryDataMsg,
		triggerLoading: triggerLoading,
        hasChanges: hasChanges
    };
    var formId = 0;
	var rowsFormId = 0;
	var restrictionCmdArr = undefined;

	// Buttons for each group. GroupId as index, buttons as value.
	ctrl.collapsablePanelToolsArray = []; 

    ctrl.$onInit = function () {
        ctrl.onControllerCallback({ childCtrl: service });
        if (ctrl.formIds) {
            formId = ctrl.formIds['qualityDatas'] ? ctrl.formIds['qualityDatas'] : 0;
            rowsFormId = ctrl.formIds['qualityDataRows'] ? ctrl.formIds['qualityDataRows'] : 0;
        }
        if (typeof ctrl.filterValue === 'undefined') {
            ctrl.filterValue = '';
		}
		if (ctrl.restrictionCmdArr) restrictionCmdArr = ctrl.restrictionCmdArr;
		ctrl.usePivot = ctrl.usePivot;
		ctrl.hideTitleStatus = ctrl.hideTitleStatus;
    };

    // Filtering function
    function searchFilter(item) {
        return ctrl.filterValue == '' || common.textContains(item.itemId, ctrl.filterValue) || common.textContains(item.nameTrans, ctrl.filterValue) || common.textContains(item.groupId, ctrl.filterValue) || common.textContains(item.groupName, ctrl.filterValue);
    }

	$scope.$watch('ctrl.filterValue', function (value) {
		ctrl.filteredQtyDatas = getFilteredQualityDatas(ctrl.qtyDatas).filter(searchFilter);
    });

    /**
     * Monitor owner objects
     */
    $scope.$watch('ctrl.parent', function (newValue) {
        if (newValue !== ctrl.prev) {
            ctrl.prev = newValue;
            getQualityDatas();
        }
    });

    /**
    * QualityData list has changed (usually outside of the directive).
    */
    $scope.$watchCollection('ctrl.qualityDataList', function (value) {
        if (value) {
            ctrl.qtyDatas = [];
            ctrl.rowData = [];
            getQualityDatas();
        }
    });

    /**
     * Loops through quality data rows and checks if mandatory data or comments are missing. 
     * @param {boolean} returnCountOnly - Set to true if count of missing mandatory elements is wanted instead of string list.
     * @returns {(string|number)} Message listing or number of missing mandatory data and comments.
     */
    function getMissingMandatoryDataMsg(returnCountOnly) {
        var msg = '';
        var count = 0;
        angular.forEach(ctrl.childControllers, function (childCtrl) {
            if (childCtrl && childCtrl.getMissingMandatoryDataMsg) {
                if (returnCountOnly) {
                    count += childCtrl.getMissingMandatoryDataMsg(true, returnCountOnly);
                }
                else {
                    msg += (msg == '' ? '' : ' ') + childCtrl.getMissingMandatoryDataMsg(true);
                }
            }
        });
        return returnCountOnly ? count : msg;
    }

	function triggerLoading() {
		getQualityDatas(false, true);
	}

    /**
     * Gets quality datas for given object. 
     * @returns 
     */
    function getQualityDatas(requestedByUi, force) {
        if (!ctrl.parent) {
            ctrl.childControllers = [];
			ctrl.qtyDatas.slice(0);
			qtyDataGroups = [];
            ctrl.contentAvailable = false;
            ctrl.onLoadingReadyCallback({ count: 0 });
            return;
        }
        if (ctrl.qualityDataList) {
            handleQualityDatas(ctrl.qualityDataList);
		} else {
			// If true, then discard hard coded restrictions and use restriction command(s) instead
			var useUserParams = restrictionCmdArr ? true : false;
			qualityDatacontext.getQualityDatas(ctrl.parent, requestedByUi, formId, rowsFormId, force, restrictionCmdArr, useUserParams).then(function (data) {
                handleQualityDatas(data);
            });
        }
	}

	/**
	 * Constructs filtered list of given qualityData list.
	 * If grouping is used constructs also an array of tools for collapsable panel and another array for each quality data group.
	 * @param {object[]} data - Array of quality datas.
	 * @returns {object[]} If grouping is not used, returns given list unaltered. If grouping is used, returns the last entry of each group.
	 */
	function getFilteredQualityDatas(data) {
		var tempArray = [];
		ctrl.collapsablePanelToolsArray = [];
		qtyDataGroups = [];
		angular.forEach(data, function (qtyData) {
			if (qtyData.useGrouping) {
				// Store each group into a two dimensional array, groupId as key, group members as another array inside the group array.
				var key = qtyData.groupId;
				if (!qtyDataGroups[key]) {
					qtyDataGroups[key] = [];
				}
				qtyDataGroups[key].push(qtyData);
				// If there are more than one group member, add buttons to button array.
				if (qtyDataGroups[key].length > 1 && !ctrl.collapsablePanelToolsArray[key]) {
					ctrl.collapsablePanelToolsArray[key] = [
						{
							id: 'PREV_GROUP',
							name: '<',
							toolTip: common.$translate.instant("STRCONST.LEANPORTAL.BTN_WIZPREV"),
							disabled: false,
							location: 'header'
						},
						{
							id: 'NEXT_GROUP',
							name: '>',
							toolTip: common.$translate.instant("STRCONST.LEANPORTAL.BTN_WIZNEXT"),
							disabled: true,
							location: 'header'
						}
					];
				}
			}
		});
		// From each group we show only one group member at the time. So we need to store only one to the filtered list.
		// If grouping is not used for the group, group is stored to filtered list.
		angular.forEach(data, function (qtyData) {
			var key = qtyData.groupId;
			var group = qtyDataGroups[key];
			// Take the last one.
			if (!qtyData.useGrouping || (group && qtyData == group[group.length - 1])) {
				tempArray.push(qtyData);
			}
		});
		return tempArray;
	}

    function handleQualityDatas(data) {
        if (data) {
            ctrl.childControllers = [];
            ctrl.qtyDatas = data;
			ctrl.filteredQtyDatas = getFilteredQualityDatas(data);
            calcAndInformReadyStatus();
            ctrl.contentAvailable = data.length > 0;
        }
    }

    ctrl.getTitle = function (qtyData) {
		var ret = qtyData.groupName;
		if (qtyData.useGrouping && ctrl.qtyDatas && ctrl.qtyDatas.length > 1 && qtyData.dataId) {
			ret += ' (' + qtyData.dataId + ')';
		}
		if (ctrl.qltDataStatusList && !ctrl.hideTitleStatus) {
            ret += ' - ' + common.getEnumNameById(ctrl.qltDataStatusList, qtyData.status);
        }
        return ret;
    };

    function calcAndInformReadyStatus() {
        if (!ctrl.qtyDatas)
            return;
        var itemCount = 0;
        var readyCount = 0;
		angular.forEach(ctrl.qtyDatas, function (qtyData) {
			var items = ctrl.usePivot ? qtyData.qualityDataRows.filter(function (row) {
				return row.dataType != 'HEADER' && row.dataType != 'SUBHEADER'
			}).length : qtyData.getRowCount();
            var ready = qtyData.getReadyCount();
            itemCount += items;
            readyCount += ready;
            var isOpenPrev;
            if (ctrl.rowData[qtyData.dataId]) {
                isOpenPrev = ctrl.rowData[qtyData.dataId].isOpen;
            }
            ctrl.rowData[qtyData.dataId] = {
                count: items,
                readyCount: ready,
                // keep the open status iva available. Otherwise open if values are missing?
                isOpen: (isOpenPrev !== undefined ? isOpenPrev : items != ready)
            }
        });
        ctrl.onLoadingReadyCallback({ count: itemCount, readyCount: readyCount });
    }

    ctrl.onSaveCallback = function (qtyData) {
        refreshData(qtyData);
    };

    function hasChanges() {
        var ret = false;
        angular.forEach(ctrl.qtyDatas, function (qtyData) {
            if (qtyData.entityAspect.entityState === breeze.EntityState.Modified) {
                ret = true;
            }
            angular.forEach(qtyData.qualityDataRows, function (row) {
                if (row.entityAspect.entityState === breeze.EntityState.Modified) {
                    ret = true;
                }
            });
        });
        return ret;
    }

    function initLookups() {
        return qualityDatacontext.getLookups(lang, false).then(function (data) {
            if (data && data[0] && data[0].Enums && data[0].Enums["QLTDATASTATUS"]) {
                ctrl.qltDataStatusList = data[0].Enums["QLTDATASTATUS"];
            }
            return data;
        });
    }

    /**
     * One of child QualityDatas has made copy of itself
     */
	ctrl.qualityDataCopied = function (qltDataCopy, qltDataFrom) {
		if (qltDataFrom.useGrouping) {
			// Add the new one to the end of qtyDatas, update filtered list and inform parent about the change.
			ctrl.qtyDatas.push(qltDataCopy);
			ctrl.filteredQtyDatas = getFilteredQualityDatas(data);
			calcAndInformReadyStatus();
		}
		else {
			// QualityData is replacing the one from which the copy was made of
			for (var i = 0; i < ctrl.qtyDatas.length; i++) {
				if (ctrl.qtyDatas[i].recId == qltDataFrom.recId) {
					ctrl.qtyDatas.splice(i, 1, qltDataCopy);
					break;
				}
			}
		}
        ctrl.rowData[qltDataCopy.dataId] = {
            count: qltDataCopy.getRowCount(),
            readyCount: qltDataCopy.getReadyCount(),
            isOpen: true
        };
        ctrl.rowData[qltDataFrom.dataId].isOpen = false;
    };

    function refreshData(qtyData) {
        qualityDatacontext.getQualityData(qtyData.recId, true).then(function (data) {
            // Replace existing qtyData with the one fetched from DB
            if (data && data.length) {
                for (var i = 0; i < ctrl.qtyDatas.length; i++) {
                    if (ctrl.qtyDatas[i].recId == data[0].recId) {
                        ctrl.qtyDatas[i] = data[0];
                        break;
                    }
                }
            }
            calcAndInformReadyStatus();
        });
    }

	/**
	 * Handles panel tool click.
	 * @param {object} qtyData
	 */
	ctrl.handleCollapsablePanelToolClick = function (qtyData, id) {
		var key = qtyData.groupId;
		var group = qtyDataGroups[key];
		if (group) {
			var i = group.indexOf(qtyData);
			if (id === 'PREV_GROUP') {
				if (i > 0) {
					// In filtered qtyDatas replace current group member with previous group member.
					ctrl.filteredQtyDatas[ctrl.filteredQtyDatas.indexOf(qtyData)] = group[i - 1];
					// Preserve collapsable panel opennes.
					ctrl.rowData[group[i - 1].dataId].isOpen = ctrl.rowData[qtyData.dataId];
					ctrl.collapsablePanelToolsArray[key][1].disabled = false;
				}
				if (i <= 1) {
					ctrl.collapsablePanelToolsArray[key][0].disabled = true;
				}
			}
			else if (id === 'NEXT_GROUP') {
				if (i < group.length - 1) {
					// In filtered qtyDatas replace current group member with next group member.
					ctrl.filteredQtyDatas[ctrl.filteredQtyDatas.indexOf(qtyData)] = group[i + 1];
					// Preserve collapsable panel opennes.
					ctrl.rowData[group[i + 1].dataId].isOpen = ctrl.rowData[qtyData.dataId];
					ctrl.collapsablePanelToolsArray[key][0].disabled = false;
				}
				if (i >= group.length - 2) {
					ctrl.collapsablePanelToolsArray[key][1].disabled = true;
				}
			}
		}
	}

    /**
      * Child module controller registers itself for easier manipulation
      */
    ctrl.registerChildController = function (childCtrl) {
        ctrl.childControllers.push(childCtrl);
    };

    activate();

    function activate() {
        initLookups();
    }
}
export default qualityDatasModule