'use strict'
/**
* Trace ID Directive & Controller
*/
var traceIdCtrlId = 'traceidctrl';
var traceIdsModule=angular.module('manudir.dfxTraceIds',[])
traceIdsModule.directive('dfxTraceIds', [function () {
	return {
		restrict: 'E',
		scope: {},
		controller: traceIdCtrlId,
		controllerAs: 'ctrl',
		bindToController: {
			taskMaterial: '=',
			allowExcess: '=?',
			onRequestToggle: '&',
			onSavedCallback: '&',
			onRemovedCallback: '&',
			onControllerCallback: '&'
		},
		template: require('./dfxTraceIds.html')
	}
}]);
traceIdsModule.controller(traceIdCtrlId, ['$scope', 'manDatacontext', 'common', 'usersettings', traceids])
function traceids($scope, manDatacontext, common, usersettings) {
	var ctrl = this;
	var fetchBatches = true;
	var readingBatchesPromise;

	ctrl.newStockBatch = null;
	ctrl.itemsInTypeAhead = 14;
	ctrl.stockBatches = [];
	ctrl.showInputFieldId = true;
	ctrl.showInputFieldName = true;

	$scope.closeEnum = {
		ALWAYS: 0,
		NEVER: 1,
		IFALL: 2
	};

	// API offered for the client with onControllerCallback
	var service = {
		cancelChanges: cancelChanges,
		getErrorMessage: getErrorMessage,
		preUpdate: preUpdate,
		saveChanges: saveChanges
	};

	function cancelChanges() {
		ctrl.cancelClicked(ctrl.newStockBatch);
	}

	function getErrorMessage() {
		return ctrl.getErrorMessage();
	}

	function saveChanges() {
		ctrl.saveChanges(ctrl.newStockBatch);
	}

	ctrl.$onInit = function () {
		ctrl.onControllerCallback({ childCtrl: service });
		init();
	}

	/**
	 * Cancel the changes 
	 */
	ctrl.cancelClicked = function (existingEntity) {
		if (existingEntity) {
			manDatacontext.rejectChanges(existingEntity);
		}
		ctrl.onRequestToggle();
	}

	/**
	 * If the focus is lost and batch is not defined, we will detach the entity
	 */
	ctrl.verifyValidEntity = function () {
		// Check if valid entity <=> batch is selected
		if (ctrl.newStockBatch && ctrl.newStockBatch.itemSpec) {
			manDatacontext.rejectChanges(ctrl.newSerialNumber);
			ctrl.newStockBatch = null;
		}
	}
	ctrl.filteredList = function (searchFilter) {
		return readStockBatches().then(function () {
			var ret = ctrl.stockBatches;
			if (searchFilter && searchFilter != '' && searchFilter != ' ') {
				var cmp = searchFilter.toLowerCase();
				var matching = [];
				for (var i = 0; i < ctrl.stockBatches.length; i++) {
					var e = ctrl.stockBatches[i];
					if (e.stockId.toLowerCase().indexOf(cmp) > -1 ||
						e.stockLoc.toLowerCase().indexOf(cmp) > -1 ||
						e.itemSpec.toLowerCase().indexOf(cmp) > -1) {
						matching.push(e);
					}
				}
				ret = matching;
			}
			var appendMoreTag = false;
			if (ret.length > ctrl.itemsInTypeAhead) {
				ret = ret.slice(0, ctrl.itemsInTypeAhead);
				appendMoreTag = true;
			}
			common.setTypeheadColumnData(ret, getBatchTypeheadName, [false, false, true], 0, null, appendMoreTag);

			return ret;
		});
	}

	/**
	 * Provides data for typehead columns
	 */
	function getBatchTypeheadName(column, stockBatch) {
		var ret = null;
		if (column < 4) {
			if (column == 0) {
				ret = stockBatch.stockId;
			}
			else if (column == 1) {
				ret = stockBatch.stockLoc;
			}
			else if (column == 2) {
				ret = stockBatch.itemSpec;
			}
			else if (column == 3) {
				ret = stockBatch.qty + ' ' + stockBatch.unit;
			}
			if (typeof ret === 'undefined' || ret === null) {
				ret = '';
			}
		}
		return ret;
	}

	/**
	 * Add is available if all stock batches aren't added yet
	 */
	ctrl.isAddAvailable = function () {
		return ctrl.taskMaterial.getBatchCount() < ctrl.taskMaterial.qty || ctrl.allowExcess;
	}

	/**
	 * This "detaching" is needed while the ctrl.newStockBatch is used as a model for qty
	 * Some how a null value is set when the UI is change, which will mark entity changed.
	 */
	function preUpdate() {
		ctrl.newStockBatch = null;
		// after update we'll need to update the situation
		fetchBatches = true;
	}

	/**
	 * Read Possible STock Batches from the DB
	 */
	function readStockBatches() {
		if (readingBatchesPromise)
			return readingBatchesPromise.promise;
		if (ctrl.taskMaterial.itemId && fetchBatches) {
			readingBatchesPromise = common.$q.defer();
			manDatacontext.getStockBatches(ctrl.taskMaterial.itemId, ctrl.taskMaterial.workId).then(function (data) {
				ctrl.stockBatches = data;
				fetchBatches = false;
				readingBatchesPromise.resolve();
				readingBatchesPromise = null;
			});
			return readingBatchesPromise.promise;
		}
		else {
			var promise = common.$q.defer()
			promise.resolve();
			return promise.promise;
		}
	}

	/**
	 * Delete selected stock batch
	 */
	ctrl.removeStockBatch = function (target) {
		if (confirm(common.$translate.instant("STRCONST.WOR.QRY_DELETE_TRACEID_SELECTED"))) {
			target.entityAspect.setDeleted();
			manDatacontext.saveChanges(target).then(
				function (saveResult) {
					ctrl.onRemovedCallback({ succeeded: true });
					fetchBatches = true;
				},
				function (error) {
					target.entityAspect.setUnchanged();
					ctrl.onRemovedCallback({ succeeded: false });
				});
		}
	}

	/**
	 * Save changes to DB (add/update)
	 * @param {closeRow defines if the edit mode should exited} closeRow
	 */
	ctrl.saveChanges = function (closeRow) {

		var errorMessage = getErrorMessage();
		if (errorMessage) {
			usersettings.errHandler(errorMessage);
			return;
		}

		var close = closeRow == $scope.closeEnum.ALWAYS ? true :
			closeRow == $scope.closeEnum.NEVER ? false :
				ctrl.taskMaterial.getBatchCount(true) >= ctrl.taskMaterial.qty;

		// And execute saving for the changed entities
		if (ctrl.newStockBatch && ctrl.newStockBatch.itemSpec) {
			var saveTarget = ctrl.newStockBatch;
			preUpdate();
			manDatacontext.saveChanges(saveTarget).then(
				function (saveResult) {
					if (close) {
						ctrl.onRequestToggle();
					}
					ctrl.newStockBatch = null;
					ctrl.currentSelection = null;
					ctrl.onSavedCallback({ succeeded: true });
					fetchBatches = true;
				},
				function (error) {
					ctrl.onSavedCallback({ succeeded: false });
				});
		}
		else if (close) {
			ctrl.onRequestToggle();
		}
	}

	ctrl.getQtyToolTip = function () {
		var errorMessage = ctrl.getErrorMessage();
		return errorMessage ? errorMessage : common.$translate.instant("STRCONST.PUBLIC.TXT_QTY");
	}

	ctrl.getErrorMessage = function () {
		var message = null;
		var batch = ctrl.newStockBatch;
		if (batch) {
			var bNegative = batch.qty <= 0;
			var bOverBatchQty = batch.origBatchQty && batch.qty > batch.origBatchQty;
			var bOverPlannedhQty = ctrl.taskMaterial.qty < ctrl.taskMaterial.getBatchCount() + batch.qty;
			if (bNegative || bOverBatchQty || (bOverPlannedhQty && !ctrl.allowExcess)) {
				// %VALUE% must be between the values of %minValue% and %maxValue%
				message = common.$translate.instant("STRCONST.LEANPORTAL.VALIDATE_RANGE");
				var qtyText = common.$translate.instant("STRCONST.PUBLIC.TXT_QTY");
				var itemText = common.$translate.instant("STRCONST.PUBLIC.TXT_ITEM");
				var materialsText = common.$translate.instant("STRCONST.LEANPORTAL.TAB_MATERIALS");
				message = message.replace("%VALUE%", materialsText + ' ' + itemText.toLowerCase() + ' ' + batch.itemId + ': ' + qtyText);
				message = message.replace("%minValue%", '0');
				var max = batch.origBatchQty;
				if (!ctrl.allowExcess) {
					max = Math.min(max, ctrl.taskMaterial.qty - ctrl.taskMaterial.getBatchCount());
				}
				message = message.replace("%maxValue%", max);
			}
		}
		return message;
	}


	/**
	 * The stock batch is selected => copy values to entity
	 */
	ctrl.stockBatchSelected = function (batch) {
		if (!ctrl.newStockBatch) {
			ctrl.newStockBatch = manDatacontext.getEntityManager().createEntity('MaterialStockBatch', {
				needId: ctrl.taskMaterial.needId,
				taskMaterialRecId: ctrl.taskMaterial.recId
			});
		}
		ctrl.newStockBatch.stockId = batch.stockId;
		ctrl.newStockBatch.stockLoc = batch.stockLoc;
		ctrl.newStockBatch.itemSpec = batch.itemSpec;
		ctrl.newStockBatch.qualitySpec = batch.qualitySpec;
		ctrl.newStockBatch.qty = Math.min(ctrl.taskMaterial.qty - ctrl.taskMaterial.getBatchCount(), batch.qty);
		ctrl.newStockBatch.workId = batch.workId;
		ctrl.currentSelection = ctrl.newStockBatch.getName();
		// these are not entity properties, but needed for validity checking & possible error message
		ctrl.newStockBatch.origBatchQty = batch.qty;
		ctrl.newStockBatch.itemId = batch.itemId;
	}

	function init() {
		readStockBatches();
	}
}
export default traceIdsModule