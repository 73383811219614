/**
	  * Completed Tasks dialog Directive & Controller
	  */
var openCompletedTasksCtrlId = 'openCompletedTasks';
var tasksButtonModule=angular.module('manudir.openCompletedTasksButton',[])
tasksButtonModule.directive('openCompletedTasksButton', [function () {
	return {
		restrict: 'E',
		scope: {},
		controller: openCompletedTasksCtrlId,
		controllerAs: 'ctrl',
		bindToController: {
			resource: '='
		},
		template: require('./openCompletedTasksButton.html')
	}
}]);
tasksButtonModule.controller(openCompletedTasksCtrlId, ['$scope', '$uibModal', openCompletedTasks]);
function openCompletedTasks($scope, $uibModal) {
	var ctrl = this;

	/**
	 * Opens Dialog to view completed tasks
	 */
	ctrl.openCompletedTasksModal = function () {
		$scope.modalTitle = ctrl.resource.resId + '  ' + ctrl.resource.name + ' - ' + common.$translate.instant("STRCONST.PUBLIC.TXT_COMPLETED");
		var modalInstance = $uibModal.open({
			animation: true,
			backdrop: false,
			windowClass: 'app-modal-window',
			template: require('./modalCompletedTasks.html'),
			controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
				$scope.onApply = function () {
					$uibModalInstance.close();
				};
				$scope.onCancel = function () {
					$uibModalInstance.close();
				};
			}],
			scope: $scope
		});
	};
}
export default tasksButtonModule