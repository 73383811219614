'use strict';
var sideBarModule = angular.module('dir.dfxHideableSideBar',[]);
sideBarModule.directive('dfxHideableSideBar', function () {
    // Opens and closes the hideable panel to side.
    // Usage:
    //  isOpen defines the initial open/close status
    //  title defines the title shown in the "button"
    // Optional:
    //  Style classes can be overwritten fully or partially by defining customClass
    // Example:
    // HTML:
    // <dfx-hideable-bar custom-class="'resource'" is-open="ctrl.isOpen" title="'@StrConst.GetString("XXX")'">
    // Content here
    // </dfx-hideable-bar>
    // CSS:
    //  .dfx-hideable-bar.resource .dfx-navbar {
    //      width: 28em;
    //  }
    return {
        restrict: 'EA', //E = element, A = attribute, C = class, M = comment 
        scope: {},
        controllerAs: 'ctrl',
        bindToController: {
            isOpen: '=',
            title: '=',
            customClass: '@?'   // CSS: .hideable-leftbar-button.Xxx
        },
        controller: ['$scope', function ($scope) {
            var ctrl = this;
            ctrl.alignLeft = true;
            $scope.alignRight = function () {
                ctrl.alignLeft = false;
            }
        }],
        transclude: true,
        link: function ($scope, iElement, attrs) {
            if (typeof attrs["right"] !== 'undefined') {
                $scope.alignRight();
            }
        },
        template: require('./dfxHideableSideBar.html')
    }
});
export default sideBarModule