'use strict';
var controllerId = 'imagectrl';
var imageModule = angular.module('imagedir.dfxCarousel', [])
/**
 * @class LeanPortalSPA.directives.dfxCarousel
 * @memberOf LeanPortalSPA.directives
 * @description Lean image directive.
 **/
imageModule.directive('dfxCarousel', [function () {
	return {
		restrict: 'E',
		scope: {},
		controller: controllerId,
		controllerAs: 'ctrl',
		bindToController: {
			// array of objects with commonDataParents() API, that returns an array of actual owners 
			// Usually the API returns only the object itself, but e.g. with ResourceTask it will 
			// return both work and operation
			imageParents: '=',
			// single object with objectType, recId and name properties <=> no title heaser shown
			parent: '=',
			docDefaultsEnumKey: '=?', // DOC_DEFAULTS enumeration key(value01). If not found, portal id is used as key. Enumeration is used for form id and version (access rights, restrictions) and sort order.
			formId: '=?', // Form id. Currently used in extra restriction setting only.
			restrictionCmdArr: '=?', // Form restriction commands as an array, separated with comma.
			imagesArr: '=?',	// provide images from outside, no reading done if defined
			// filter by object
			filterBy: '=imageFilter',
			//How many pictures are in the view, 1 -> default one picture in view and carousel to switch them
			//									 2, or more -> pictures are shown below each other and no carousel control
			limitTo: '=?',
			lookupParams: '=?', // Lookup parameters for extra restriction used in the query. For example [{ "PARAM1": 'foo' }, { "PARAM2": recId }]
			// defines the image height: unit is view height full decades 10,20,...,90
			heightPercentage: '=?',
			isEditable: '=?',	// Can image be modified
			useMultipleFilters: '=?',
			//docListOptions: 0 = All, 1 = No pictures, 2 = Only pictures (default).
			docListOption: '=?',
			disableEdit: '=?',
			viewMode: '=?', //Available modes CAROUSEL -> Default, shows only one picture with carousel controls
			//				  LIST     -> Pictures are shown in columns, use limitTo attribute to control number of pictures in row.
			onHeightChangedCallback: '&',
			onContentChangedCallback: '&',
			onControllerCallback: '&'
		},
		template: require('./carousel.html')
	};
}]);

imageModule.controller(controllerId, ['$scope', 'common', 'commonDatacontext', image]);

function image($scope, common, commonDatacontext) {
	var ctrl = this;
	var showIndexes = [];
	$scope.active = 0;
	// ctrl.objects: array of objects, that will provide possible image parents with imageParents API
	ctrl.objects = [];
	ctrl.contentAvailable = false;
	ctrl.filteredImages = [];
	//[objectType, recId] => [imgData1, imgData2,...] 
	ctrl.parentToImages = [];
	ctrl.imageInterval = 5000;
	ctrl.noWrapImages = false;
	ctrl.isReady = false;

	// API offered for the client with onControllerCallback
	var service = {
		triggerLoading: activate
	};

	ctrl.$onInit = function () {
		ctrl.docDefaultsEnumKey === undefined ? ctrl.docDefaultsEnumKey = common.$route.current.portalId : null;
		ctrl.heightPercentage = ctrl.heightPercentage ? ctrl.heightPercentage : 40;
		ctrl.isEditable = (ctrl.isEditable || ctrl.isEditable == false) ? ctrl.isEditable : true;
		ctrl.useMultipleFilters = ctrl.useMultipleFilters ? true : false;
		ctrl.limitTo = ctrl.limitTo ? ctrl.limitTo : 1;
		ctrl.docListOption = (ctrl.docListOption || ctrl.docListOption == 0) ? ctrl.docListOption : 2;
		ctrl.disableEdit = ctrl.disableEdit ? ctrl.disableEdit : false;
		ctrl.viewMode = ctrl.viewMode ? ctrl.viewMode : 'CAROUSEL';
		ctrl.onControllerCallback({ childCtrl: service });

		activate();
	};



	/**
	 * ctrl.objects contains Array of parents with [objectType, recId]
	 */
	$scope.$watchCollection('ctrl.imageParents', function (value) {
		// duplicate original array
		if (ctrl.imageParents) {
			ctrl.objects = ctrl.imageParents.slice(0);
			getImages();
		}
	});
	$scope.$watch('ctrl.parent', function (parent) {
		if (ctrl.parent) {
			ctrl.objects = [ctrl.parent];
			ctrl.parentToImages = [];
			getImages();
		}
	});

	/**
	 * ctrl.filterBy contains object which is used for filtering
	 */
	$scope.$watch('ctrl.filterBy', function (value) {
		filterImages();
	});

	function addToImages(objectType, recId, doc) {
		var key = toKey(objectType, recId);
		var imageArr = ctrl.parentToImages[key];
		if (imageArr == null) {
			imageArr = [];
			ctrl.parentToImages[key] = imageArr;
		}
		imageArr.push(doc);
	}

	function filterImages() {
		// if single parent is used, it will be used as a filter
		// if multiple parents are given, the the filter should come from outside
		var filterBy = ctrl.useMultipleFilters ? ctrl.filterBy : ctrl.parent || ctrl.filterBy;
		ctrl.filteredImages = [];
		if (filterBy) {
			if (ctrl.useMultipleFilters) {
				for (var i = 0; i < filterBy.length; i++) {
					var f = common.getCommonDataParents('Image', filterBy[i]);
					var imageArr = ctrl.parentToImages[toKey(f[0].objectType, f[0].recId)];
					if (imageArr) {
						ctrl.filteredImages = ctrl.filteredImages.concat(imageArr);
					}
				}
			}
			else {
				angular.forEach(common.getCommonDataParents('Image', filterBy), function (parent) {
					var imageArr = ctrl.parentToImages[toKey(parent.objectType, parent.recId)];
					if (imageArr) {
						// and concatenate to viewed array
						ctrl.filteredImages = ctrl.filteredImages.concat(imageArr);
					}
				});
			}
		}
		ctrl.contentAvailable = ctrl.filteredImages.length > 0;
		ctrl.imageInterval = 0;
		ctrl.onContentChangedCallback({ count: ctrl.filteredImages.length });
		/*Set first picture as starting point for showIndexes*/
		for (var j = 0; j < ctrl.filteredImages.length; j++) {
			if (!ctrl.isPdf(ctrl.filteredImages[j].fileName)) {
				showIndexes = [j];
				break;
			}
		}
	}

	function toKey(objectType, recId) {
		return objectType + recId;
	}

	ctrl.getContent = function (image, active) {
		if (showIndexes.indexOf(active) === -1) {
			showIndexes.push(active);
		}
		return image.content;
	};

	ctrl.show = function (index) {
		return showIndexes.indexOf(index) >= 0;
	};

	$scope.showAsPdf = function (image) {
		if (!image.showAsPdf) {
			return false;
		}
		else {
			common.loadPDFJS().then((PDFJS) => {
				var loadingTask = PDFJS.getDocument({ data: image.pdfData, cMapUrl: 'Scripts/pdfjs/web/cmaps/', cMapPacked: true });
				loadingTask.promise.then(function (pdf) {
					console.log('PDF loaded');

					// Fetch the first page
					var pageNumber = 1;
					pdf.getPage(pageNumber).then(function (page) {
						console.log('Page loaded');

						var scale = 1.2;
						var viewport = page.getViewport({ scale });

						// Prepare canvas using PDF page dimensions
						var canvas = document.getElementById(image.recId);
						var context = canvas.getContext('2d');
						canvas.height = viewport.height;
						canvas.width = viewport.width;

						// Render PDF page into canvas context
						var renderContext = {
							canvasContext: context,
							viewport: viewport
						};
						var renderTask = page.render(renderContext);
						renderTask.promise.then(function () {
							console.log('Page rendered');
						});
					});
				}, function (reason) {
					// PDF loading error
					console.error(reason);
				});
			});
		}
		return true;
	};


	ctrl.getHeight = function () {
		return 'dfx-height-percent-' + ctrl.heightPercentage;
	};

	ctrl.getHeightTitle = function (add) {
		return '. ' + common.$translate.instant("STRCONST.PUBLIC.TXT_CURRENT") + ' ' + common.$translate.instant("STRCONST.PUBLIC.TXT_VALUE").toLowerCase() + ': ' + ctrl.heightPercentage + '% (' +
			(add ? common.$translate.instant("STRCONST.PUBLIC.TXT_MAX") + ' 90%)' : common.$translate.instant("STRCONST.PUBLIC.TXT_MIN") + ' 10%)');
	};

	ctrl.changeHeight = function (add, image) {
		/*Does zoom-toggle apply to one image or container*/
		if (image) {
			var ratio = 10; //percents to which we grow or shrink one picture
			for (var i = 0; i < ctrl.filteredImages.length; i++) {
				/*We're using (image)objects custom property 'height' to store temporary effect of zoom buttons, defaults to even size pictures on load*/
				ctrl.filteredImages[i].height = !ctrl.filteredImages[i].hasOwnProperty('height') ? 100 / ctrl.limitTo : ctrl.filteredImages[i].height;
				if (add) {
					if (ctrl.filteredImages[i].recId == image.recId) {
						ctrl.filteredImages[i].height += ratio;
					}
					else {
						ctrl.filteredImages[i].height -= (ratio / (ctrl.limitTo - 1));
					}
				}
				else if (!add) {
					if (ctrl.filteredImages[i].recId == image.recId) {
						ctrl.filteredImages[i].height -= ratio;
					}
					else {
						ctrl.filteredImages[i].height += (ratio / (ctrl.limitTo - 1));
					}
				}
			}
		}
		else {
			if (add && ctrl.heightPercentage < 90) {
				ctrl.heightPercentage += 10;
				ctrl.onHeightChangedCallback({ value: ctrl.heightPercentage });
			}
			if (!add && ctrl.heightPercentage > 10) {
				ctrl.heightPercentage -= 10;
				ctrl.onHeightChangedCallback({ value: ctrl.heightPercentage });
			}
		}
	};

	ctrl.getCssForImg = function (rowSize) {
		var margin = 2; //margin of single picture, evaluates to %
		var width = ((100 / rowSize) - (margin * 2)) + '%'; //count width according to how many pictures in a row.
		return { 'width': width, 'margin': margin + '%' };
	};

	/**
	 * Gets images for imageParents. Obs. filterImages images must be called in
	 * all possible exits for the changes to take place
	 */
	function getImages() {
		if (typeof ctrl.imagesArr !== 'undefined' && ctrl.parent && ctrl.parent.objRecId) {
			angular.forEach(ctrl.imagesArr, function (image) {
				addToImages(image.objectType, image.objectRecId, image);
			});
			filterImages();
			return;
		}
		// collect all DB activity to promises array and execute filtering when ready
		var promises = [];
		angular.forEach(ctrl.objects, function (object) {
			angular.forEach(common.getCommonDataParents('Image', object), function (imageParent) {
				var key = toKey(imageParent.objectType, imageParent.recId);
				if (ctrl.parentToImages[key] == null) {
					ctrl.parentToImages[key] = [];
					promises.push(commonDatacontext.getImages(null, imageParent.objectType, imageParent.recId, 0, 100, ctrl.docListOption, ctrl.formId, ctrl.restrictionCmdArr, ctrl.lookupParams, ctrl.docDefaultsEnumKey).then(function (documents) {
						var subPromises = documents.map(function (docum) {
							var currDoc = docum;
							var imageDataPromise = common.$q.defer();

							// If document does not have attachment, try to get external file.
							if (!(docum.hasFile && docum.fileName && docum.contentType)) {
								commonDatacontext.getExternalFileData(docum, function (data) {
									if (currDoc.content == null && data) {
										//currDoc.content = 'file:' + data.url;
										currDoc.content = data.url;
									}
									imageDataPromise.resolve(currDoc);
								});
							} else { // Get file attachment contents first and store it with the document.
								commonDatacontext.getDocumentData(null, docum.recId, 'CONTENT', true, true).then(function (data) {
									if (!data || !data.length || !data[0]) {
										var msg = common.$translate.instant("STRCONST.PUBLIC.MSG_LOADING_FILECONTENT_FAILED") + ' ' + docum.fileName;
										common.logger.logError(msg, false, false, true);
									}
									else if (!ctrl.isPdf(currDoc.fileName)) {
										currDoc.content = 'data:' + currDoc.contentType + ';base64,' + data[0];
									}
									else {
										currDoc.showAsPdf = true;
										currDoc.pdfData = atob(data);
									}
									imageDataPromise.resolve(currDoc);
								});
							}

							return imageDataPromise.promise.then(function (doc) {
								return doc;
							});
						});
						return common.$q.all(subPromises).then(function (documents) {
							angular.forEach(documents, function (doc) {
								addToImages(doc.objectType, doc.objectRecId, doc);
							})
						});
					}))
				}
				//Image queried before
				else if (ctrl.parentToImages[key] != null) {
					promises.push(common.$q.when(true));
				}
			});
		});
		if (promises && promises.length) {
			common.$q.all(promises).then(function () {
				filterImages();
				ctrl.isReady = true;
			});
		}
	}
	ctrl.openModal = function (image) {
		common.executePDFJS(image, "img", commonDatacontext)
		common.openModal($scope, require('./dfxImageCanvas.html'), null, null, null, true);
	};

	/*IE-pdf handling*/
	ctrl.openPdfDoc = function (image) {
		window.navigator.msSaveOrOpenBlob(image.content, image.fileName);
	};

	/**
	 * Checks if given file is a pdf file.
	 * @param {string} fileName - Name of file.
	 * @returns {boolean} Returns true for pdf files.
	 */
	ctrl.isPdf = function (fileName) {
		return common.isPdf(fileName);
	};

	/* Click preview image to open normal size image */
	ctrl.openPicture = function (event, image) {
		if (image.showAsPdf) {
			ctrl.openModal(image);
		}
		else {
			event.preventDefault();
			$(event.currentTarget).ekkoLightbox();
		}


	};

	ctrl.updateDoc = function (doc) {
		if (doc && doc.recId) {
			for (var j = 0; j < ctrl.filteredImages.length; j++) {
				if (ctrl.filteredImages[j].recId == doc.recId) {
					ctrl.filteredImages[j] = doc;
				}
			}
		}
	};

	ctrl.removeDocFromArr = function (doc) {
		var removed = false;
		if (doc && doc.recId) {
			for (var j = 0; j < ctrl.filteredImages.length; j++) {
				if (ctrl.filteredImages[j].recId == doc.recId) {
					ctrl.filteredImages.splice(j, 1);
					removed = true;
				}
			}
		}
		if (removed) {
			ctrl.onContentChangedCallback({ count: ctrl.filteredImages.length });
		}
	};

	$scope.$on('COMPANYOPUCHANGES', function () {
		activate();
	});

	function activate() {
		// reset when UI is activated
		ctrl.parentToImages = [];
		getImages();
	}
}

imageModule.directive('dfxPdfCanvas', [function () {
	function link(scope, element, attrs) {
		scope.showAsPdf(scope.image);
	}
	return {
		restrict: 'A',
		link: link
	};
}]);

imageModule.controller(controllerId, ['$scope', 'common', 'commonDatacontext', image]);
export default imageModule