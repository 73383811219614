// Directive containing documents for one object
// If documents are shown for multiple objects, then the UI contains collapsable panel as separator
var objectDocsModule = angular.module('docdir.dfxObjectDocs',[])
objectDocsModule.directive('dfxObjectDocs', [function () {
    return {
        restrict: 'E',
        scope: {},
        controller: ['$scope', 'common','commonDatacontext', function ($scope, common, commonDatacontext) {
            var ctrl = this;

            ctrl.$onInit = function () {
                ctrl.filteredDocs = ctrl.docParent.docs;
                if (typeof $scope.iconAndTitleOnly === 'undefined') {
                    $scope.iconAndTitleOnly = false;
                }

                if (typeof $scope.limitTo === 'undefined') {
                    $scope.limitTo = false;
                }

                if (typeof ctrl.filterValue === 'undefined') {
                    ctrl.filterValue = '';
                }
            };

			ctrl.deleteDocument = function (doc) {
				if (confirm(common.$translate.instant("STRCONST.LEANPORTAL.QRY_DELETE_DOCUMENT"))) {
					commonDatacontext.deleteDocument(doc.recId).then(function (data) {
						if (data) {
							common.logger.logSuccess(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DOC_ACTION") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + ".", false, false, true);
							ctrl.removeDocFromArr(doc.recId);
						}
					});
				}
			};

			/**
			 * Helper function which updates given document in the document array.
			 * @param {object} doc - Document
			 */
			ctrl.removeDocFromArr = function (recId) {
				var removed = false;
				if (recId) {
					for (var i = 0; i < ctrl.docParent.docs.length; i++) {
						if (ctrl.docParent.docs[i].recId === recId) {
							ctrl.docParent.docs.splice(i, 1);
							removed = true;
							break;
						}
					}
				}
				if (removed) {
					ctrl.onContentChangedCallback({ recId: recId });
				}
			};

            ctrl.otherUserDocument = function (doc) {
                if (doc.createdBy != ctrl.currentUser.userId)
                    return doc;
            }

            ctrl.currentUserDocument = function (doc) {
                if (doc.createdBy == ctrl.currentUser.userId)
                    return doc;
            }

            ctrl.getCssForDoc = function (rowSize) {
                var margin = 2; //margin of single picture, evaluates to %
                var width = ((100 / rowSize) - (margin * 2)) + '%'; //count width according to how many pictures in a row.
                //var height = (width / 16) * 9 + '%'; A good height should be calculated
                return { 'width': width, 'margin': margin + '%' };
            }


            /**
             * Used to choose between different FontAwesome icons depending on the document contentType
             * @param {object} doc - Document entity.
             * @param {boolean} [setLarge=false] - Use large icon.
             * @returns {string} Returns css class.
             */
            ctrl.getFaIcon = function (doc, setLarge) {
                var lg = setLarge ? ' fa-3x' : '';
                // PDF files
                if (doc.contentType === "application/pdf") {
                    return 'far fa-file-pdf' + lg;
                }
                // Microsoft word, docx files
                else if (doc.contentType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                    return 'far fa-file-word' + lg;
                }
                // Microsoft excel, sheet files
                else if (doc.contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                    return 'far fa-file-excel' + lg;
                }
                else { // Plain text files and all other files.
                    return 'far fa-file' + lg;
                }
            };

            ctrl.isNewIconDisplayed = function (doc) {
                if (!(ctrl.newIconCutoff && ctrl.newIconCutoff != '' && ctrl.newIconCutoff >= 0)) return false;
                return moment.utc(doc.updateStamp || doc.createStamp).add(ctrl.newIconCutoff, 'days').isAfter(moment.utc());
            }

            ctrl.newIconTitle = function () {
                return common.$translate.instant("STRCONST.PUBLIC.TXT_MODIFIED_LESS_THAN_DAYS").replace('%1', ctrl.newIconCutoff);
            }

            // Filtering function
            function searchFilter(item) {
                return ctrl.filterValue == '' || common.textContains(item.title, ctrl.filterValue) || common.textContains(item.subjectName, ctrl.filterValue);
            }

            $scope.$watch('ctrl.filterValue', function (value) {
                ctrl.filteredDocs = ctrl.docParent.docs.filter(searchFilter);
            });

            $scope.$watchCollection('ctrl.docParent.docs', function (value) {
                ctrl.filteredDocs = ctrl.docParent.docs.filter(searchFilter);
            });

        }],
        controllerAs: 'ctrl',
        bindToController: {
            docParent: '=',
			formId: '=',
			formVersion: '=?',
            filterValue: '=?', // documents are filtered by this value
            iconAndTitleOnly: '=?',
            limitTo: '=?',
            newIconCutoff: '=?', // How many days 'New' icon will be displayed. Default = 0.
            onClickedCallback: '&',
            onOpenInTabCallback: '&',
            onContentChangedCallback: '&',
            onContentEditedCallback: '&',
            currentUser: '=?',
			supplierSecondTable: '=?',
			textAttachment: '=?',
            template: '=?',
        },

        template: function (tElement, tAttrs) {
            return tAttrs.template ? require(`./${tAttrs.template.replace('.html', '')}.html`) : require('./dfxObjectDocs.html') || require('./dfxObjectDocs.html');
        },
    }
}]);
export default objectDocsModule