var controllerId = 'datepickerCtrl';
var app = angular.module('common');

app.filter('monthName', [function () {
	return function (monthNumber) { //1 = January
		var monthNames = ['STRCONST.PUBLIC.TXT_MONTH_NAME_1', 'STRCONST.PUBLIC.TXT_MONTH_NAME_2', 'STRCONST.PUBLIC.TXT_MONTH_NAME_3', 'STRCONST.PUBLIC.TXT_MONTH_NAME_4', 'STRCONST.PUBLIC.TXT_MONTH_NAME_5', 'STRCONST.PUBLIC.TXT_MONTH_NAME_6',
			'STRCONST.PUBLIC.TXT_MONTH_NAME_7', 'STRCONST.PUBLIC.TXT_MONTH_NAME_8', 'STRCONST.PUBLIC.TXT_MONTH_NAME_9', 'STRCONST.PUBLIC.TXT_MONTH_NAME_10', 'STRCONST.PUBLIC.TXT_MONTH_NAME_11', 'STRCONST.PUBLIC.TXT_MONTH_NAME_12'];
		var monthNumb = monthNumber.getMonth();
		

		return monthNames[monthNumb];
	}
}]);

app.filter('monthNames', [function () {
	return function (monthNumber) { //1 = January
		var monthNames = ['STRCONST.PUBLIC.TXT_MONTH_NAME_1', 'STRCONST.PUBLIC.TXT_MONTH_NAME_2', 'STRCONST.PUBLIC.TXT_MONTH_NAME_3', 'STRCONST.PUBLIC.TXT_MONTH_NAME_4', 'STRCONST.PUBLIC.TXT_MONTH_NAME_5', 'STRCONST.PUBLIC.TXT_MONTH_NAME_6',
			'STRCONST.PUBLIC.TXT_MONTH_NAME_7', 'STRCONST.PUBLIC.TXT_MONTH_NAME_8', 'STRCONST.PUBLIC.TXT_MONTH_NAME_9', 'STRCONST.PUBLIC.TXT_MONTH_NAME_10', 'STRCONST.PUBLIC.TXT_MONTH_NAME_11', 'STRCONST.PUBLIC.TXT_MONTH_NAME_12'];
		var monthNumb = monthNumber.date.getMonth();

		return monthNames[monthNumb];
	}
}]);

app.filter('dayName', [function () {
	return function (dayNumber) { //1 = January
		var dayNames = ['STRCONST.BALANCER.IDS_DAY_MON', 'STRCONST.BALANCER.IDS_DAY_TUE', 'STRCONST.BALANCER.IDS_DAY_WED', 'STRCONST.BALANCER.IDS_DAY_THU', 'STRCONST.BALANCER.IDS_DAY_FRI', 'STRCONST.BALANCER.IDS_DAY_SAT', 'STRCONST.BALANCER.IDS_DAY_SUN'];
		return (dayNames[dayNumber])
	


	}
}]);

app.controller(controllerId, ['$scope', 'common', function ($scope, common) {
    var ctrl = this;

    ctrl.$onInit = function () {
        // This is needed for Breeze entities
        ctrl.tempValue = ctrl.dateValue;
    };
    $scope.$watch('ctrl.tempValue', function (value) {
    	if (value != ctrl.dateValue) {
    		ctrl.dateValue = ctrl.tempValue;
    		if (typeof ctrl.onChangedCallback !== 'undefined') {
    			ctrl.onChangedCallback({ value: ctrl.dateValue });
    		}
    	}
    });

    $scope.$watch('ctrl.dateValue', function (value) {
    	if (value != ctrl.tempValue) {
    		ctrl.tempValue = value;
    		if (typeof ctrl.onChangedCallback !== 'undefined') {
    			ctrl.onChangedCallback({ value: value });
    		}
    	}
    });

	// TODO: Currently entering date manually (without calendar) or removing manually entered date does not work: such date is not saved to database or removed from the field in the UI. 
    // You have to handle it in the controller, like in sales portal.

	$scope.today = function() {
		$scope.dt = new Date();
	};
	$scope.today();

	$scope.showWeeks = true;
	$scope.toggleWeeks = false;

	$scope.clear = function() {
		$scope.dt = null;
	};

	$scope.disabled = false;

	$scope.open = function($event) {
		$event.preventDefault();
		$event.stopPropagation();

		if ($event.target && $event.target.attributes && $event.target.attributes[2] && $event.target.attributes[2].value == 'close()') {
			$scope.opened = false;
		} else {
			$scope.opened = true;
		}
	};

	$scope.dateOptions = {
		yearFormat: "'yyyy'",
		startingDay: 1,
		dateDisabled: false
	};

	$scope.format = common.longDateFormat(true).replace('dd', 'd!').replace('MM', 'M!');
	$scope.placeholderFormat = common.longDateFormat(true);

}]);
export default app