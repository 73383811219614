'use strict';
var techDatasModule = angular.module('dir.dfxTechDatas',[]);
techDatasModule.directive('dfxTechDatas', function () {
	return {
		restrict: 'E',
		scope: {},
		controllerAs: 'ctrl',
		replace: true,
		bindToController: {
			data: '='
		},
		controller: ['$scope', function ($scope) {
			var ctrl = this;
		}],
		template: require('./dfxTechDatas.html')
	};
});
export default techDatasModule
