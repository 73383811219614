
/**
  * Login To Resource Directive & Controller
  */
var createWorkReportCtrlId = 'createWorkReportctrl';
var reportButtonModule=angular.module('manudir.dfxCreateWorkReportButton',[])
reportButtonModule.directive('dfxCreateWorkReportButton', [function () {
	return {
		restrict: 'E',
		scope: {},
		controller: createWorkReportCtrlId,
		controllerAs: 'ctrl',
		bindToController: {
			resId: '=',
			workId: '=?',
			operatId: '=?',
			layout: '=?',
			workReportLayout: '=?',
			buttonText: '=?',
			modalTitle: '=?',
			modalSize: '=?'
		},
		template: require('./createWorkReportButton.html')
	}
}]);
reportButtonModule.controller(createWorkReportCtrlId, ['$scope', 'manDatacontext', 'commonDatacontext', 'common', 'usersettings', '$uibModal', createWorkReport]);
function createWorkReport($scope, manDatacontext, commonDatacontext, common, usersettings, $uibModal) {
	var ctrl = this;
	ctrl.formId = 25116;
	ctrl.dfxGridOptions = { columnDefs: [], enableFiltering: false };
	ctrl.onWrGridApi = function (gridApi) {
		ctrl.dfxGridOptions.data = ctrl.wrGridData;
	}
	// Meaningful only when time stamps are used: Apply is enabled even when user has not logged in
	ctrl.allowOperWithoutUserLogin = true;
	ctrl.disableApplyButton = false;
    ctrl.reasonTypes = null;

    ctrl.$onInit = function () {
        if (!ctrl.workReportLayout) {
            if (ctrl.resId && !ctrl.workId && !ctrl.operatId) {
                ctrl.workReportLayout = 'LOG_RES';
            }
            else {
                ctrl.workReportLayout = 'DEFAULT';
            }
		}
		ctrl.buttonText = ctrl.buttonText ? ctrl.buttonText : 'STRCONST.TIM.GRP_RESOURCE_STAMP';
    };

	function initLookups() {
		return manDatacontext.getLookups(lang, false).then(function (data) {
			// Apply button is available, even when the use employee is not logged in
			if (data && data[0] && data[0].Enums && data[0].Enums["MANUFPORTAL_DEFAULTS"]) {
				// MANUFPORTAL_DEFAULTS value01 and value02 dictate how time stamp is used
				var defaults = data[0].Enums["MANUFPORTAL_DEFAULTS"];
				var enumItem_TIM = defaults ? common.getEnumRowById(defaults, 'TIM') : null;
				if (enumItem_TIM && enumItem_TIM.extraSelect && enumItem_TIM.extraSelect.length >= 2) {
					ctrl.allowOperWithoutUserLogin = (defaults[0].extraSelect[1] == 'NOT_MANDATORY');
					ctrl.showLastOutWarning = (defaults[0].columns['VALUE03'] == 'LAST_OUT_WARNING');
				}
				ctrl.reasonTypes = data[0].Enums["REASONTYPE"];
			}
			return data;
		})
	}

	/**
	 * Open login dialog for Table and Task View
	 */
	ctrl.createWorkReport = function (resId) {
		initLookups().then(function () { openDialog(); });

		function openDialog() {
			ctrl.dfxGridOptions.data = [];
			// Create new entity 
			ctrl.workReport = commonDatacontext.commonManager.createEntity('WorkReport');
			ctrl.workReport.resId = resId;
			ctrl.workReport.workId = ctrl.workId;
			ctrl.workReport.operatId = ctrl.operatId;
			if (ctrl.workReportLayout != "LOG_OPER_OUT") {
				var lastUserInfo = usersettings.getLastUserInfo();
				ctrl.workReport.empId = lastUserInfo ? lastUserInfo.empId : '';
				ctrl.workReport.empName = lastUserInfo ? lastUserInfo.empName : '';
			}
			if (ctrl.workReportLayout == "LIST" || (ctrl.workReportLayout == "LOG_OPER_OUT" && ctrl.showLastOutWarning)) {
				commonDatacontext.getWorkReport(null, null, 75, 'SELECT_EMP_OPERAT', null, null, null, ctrl.workReport.resId, ctrl.workReport.workId, ctrl.workReport.operatId).then(function (data) {
					ctrl.dfxGridOptions.data = data;
					ctrl.wrGridData = data;
				});
			}
			var modalInstance = $uibModal.open({
				animation: true,
				backdrop: false,
				template: require('./createWorkReportModal.html'),
				controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
					$scope.onApply = function () {
						usersettings.setLastUserInfo(ctrl.workReport.empId, ctrl.workReport.empName);
						// Save TIM changes <=> workReport
						commonDatacontext.saveChanges(ctrl.workReport).then(function (saveResult) {
							if (ctrl.workId && ctrl.operatId) {
								manDatacontext.getResourceTask(ctrl.workId, ctrl.operatId) //refresh resourceTask as changes might have happened to e.g. status.
							}
							$uibModalInstance.close();
						},
							function (error) { }
						);
					};
					$scope.onCancel = function () {
                        commonDatacontext.rejectChanges(ctrl.workReport);
						$uibModalInstance.dismiss('cancel');
					};
				}],
				scope: $scope,
				size: ctrl.modalSize
			});
		}
	}
	/**
	 * Disable Apply button if Time Stamps are used
	 */
	ctrl.setDisableApplyButton = function (value) {
		ctrl.disableApplyButton = value;
	}
}
export default reportButtonModule