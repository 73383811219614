'use strict';
var columnModule = angular.module('dir.dfxColumn',[]);
// Directive for displaying form columns, also supports sorting. 
// Usage examples:
// <dfx-column  form-id="@formId" column-name="'CURRENCY'" column-type="'header'" order="'currency'" by="order"></dfx-column>
// <dfx-column form-id="@formId" column-name="'CURRENCY'">{{invoice.currency}}</dfx-column>
columnModule.directive('dfxColumn', ['$compile', '$templateCache', '$timeout', 'common', 'usersettings', 'commonDatacontext', function dfxColum($compile, $templateCache, $timeout, common, usersettings, commonDatacontext) {
	// Make given element hidden.
	function hideElement(element) {
		if (element && element[0]) {
			/*Setting element outerHtml gives invalid state error in IE*/
			//element[0].outerHTML = '';
			element[0].classList.add('dfx-hide');
		}
	}

	function alignElement(element, colSettings, isDataCell) {
		if (element && element[0] && colSettings.align) {
			element[0].style.textAlign = colSettings.align.toLowerCase();
			isDataCell ? null : element[0].style.marginRight = "0.5em";
			isDataCell ? null : element[0].style.display = "inline-block";
		} else if (element && element[0] && colSettings.dataTypeOrig == 'NUMBER' && colSettings.columnName != "QTY") {
			element[0].style.textAlign = 'right';
            isDataCell ? null : element[0].style.marginRight = "0.5em";
			isDataCell ? null : element[0].style.display = "inline-block";
		}
    }

    /**
     * Checks if given mode is read, form is in read mode or column settings is read only.
     * @param {string} mode - Document mode
     * @param {object} formSettings - Current form settings
     * @param {object} columnSettings - Current column settings
     * @returns {boolean} Returns true for read only, false otherwise.
     */
    function isReadOnly(mode, formSettings, columnSettings) {
        if (mode === 'read') return true;
        if (!formSettings.updateable) return true;
        if (columnSettings.updateEditable && mode === 'updateEdit') return false;
        if (columnSettings.insertEditable && mode === 'insertEdit') return false;
        return true;
    }

    /**
     * Checks column attributes and if valueListDataValue is set, maps them to strings.
     * @param {object} columnSettings - Current column settings
     * @returns {string} name, nabbr or id. If not set, returns name.
     */
    function getEnumDisplayMode(columnSettings) {
        var displayModes = ['nabbr', 'id', 'name'];
        if (columnSettings.valueListDataValue) return displayModes[columnSettings.valueListDataValue];
        return 'name';
    }

    /**
     * Get template from templateCache
     * @param {string} name - Name of the template
     * @returns {object} HTML template.
     */
    function getTemplate(name) {
        return $templateCache.get('dfxColumn/' + name + '.html');
    }
    
	//helper method for handling the settings 
    function handleSettings(scope, element, transclude) {
		var colSettings = scope.formSettings.columnSettings[scope.columnName];
		if (colSettings) {
			if (colSettings.hidden) {
				hideElement(element);
			}
			else {
				//get pre compiled templates for improved rendering performance.
				if (!colSettings.hidden && (scope.columnType === 'header' || scope.columnType === 'modalCell' || scope.columnType === 'dataFormCell' || scope.columnType === 'modalCellNumber' || scope.columnType === 'dataFormCellInline')) {

					transclude(function (transcludeEl) {
                        if (transcludeEl.length > 0) {
                            $compile(getTemplate(scope.columnType + 'Transclude'))(scope, function (clonedElement) { //use clone of the pre compiled template.
                                clonedElement.append(transcludeEl);
                                element.append(clonedElement);
                            });
                            if (scope.columnType == 'header') {
                                $compile(getTemplate('sortIcon'))(scope, function (clonedElement) {
                                    element.append(clonedElement);
                                    alignElement(element, colSettings);
                                });
                            }
                        }
                        else {
                            var templateName = scope.columnType;
							if (scope.columnType === 'modalCell' || scope.columnType === 'modalCellNumber') {
                                scope.getIsDisabled = function (mode, formSettings, colSettings) {
                                    return isReadOnly(mode, formSettings, colSettings);
                                };
                                colSettings.multilineCount > 0 ? templateName += 'Multiline' : null;
                                colSettings.isEnumItemId ? templateName += 'Enum' : null;
                                colSettings.controlType.toUpperCase() === 'ECHECKBOX' ? templateName += 'Checkbox' : null;
                            }
                            if (scope.columnType === 'dataFormCell') {
                                scope.getIsEditable = function (mode, formSettings, colSettings) {
                                    return !isReadOnly(mode, formSettings, colSettings);
                                };
                                colSettings.multilineCount > 0 ? templateName += 'Multiline' : null;
                                colSettings.isEnumItemId ? templateName += 'Enum' : null;
							}
							if (scope.columnType === 'dataFormCellInline') {
								scope.getIsEditable = function (mode, formSettings, colSettings) {
									return !isReadOnly(mode, formSettings, colSettings);
								};
							}
                            $compile(getTemplate(templateName))(scope, function (clonedElement) {
                                element.append(clonedElement);
								alignElement(element, colSettings, (scope.columnType === 'modalCell' || scope.columnType === 'dataFormCell' || scope.columnType === 'dataFormCellInline'));
                                if (scope.columnType === 'modalCell' && isReadOnly(scope.mode, scope.formSettings, colSettings)) {
                                    var el;
                                    if (colSettings.multilineCount > 0) { // Textarea
                                        //el = document.getElementById(scope.columnName); // TODO Make this work.
                                        //el ? el.disabled = true : "";
                                    }
                                    else {
                                        el = element.find('input');
                                        el[0] ? el[0].disabled = true : "";
                                    }
                                    var elBut = element.find('button');
                                    elBut && elBut[0] ? elBut.addClass('disabled') : null;
                                }
                            });
                        }
                    });
                    var lbl = element.find('label');
					if (lbl && colSettings.mandatory && (scope.columnType === 'modalCell' || scope.columnType === 'dataFormCell' || scope.columnType === 'dataFormCellInline') && scope.mode !== 'read') {
                        lbl.addClass('required');
                    }
                    if (lbl && lbl[0] && colSettings.toolTip) {
                        lbl[0].title = colSettings.toolTip;
                    }
                    colSettings.isEnumItemId ? scope.enumDisplayMode = getEnumDisplayMode(colSettings) : null;
				}
				if (!colSettings.hidden && scope.columnType == 'input' || scope.columnType == 'readOnlyFld' || scope.columnType == 'label' || scope.columnType == 'restriction' || scope.columnType == 'dataCell') {
					transclude(function (transcludeEl) {
                        if (scope.columnType == 'restriction') {
                            $compile(getTemplate('plainHeader'))(scope, function (clonedElement) {
								clonedElement.append(transcludeEl);
								element.append(clonedElement);
								alignElement(element, colSettings);
							});
						}
                        else if (scope.columnType == 'dataCell') {
                            $compile(getTemplate('dataCell'))(scope, function (clonedElement) {
								clonedElement.append(transcludeEl);
								element.append(clonedElement);
								if (!colSettings.updateEditable) {
									var el = element.find('input');
									el[0] ? el[0].disabled = true : "";
									var elBut = element.find('button');
									if (elBut && elBut[0] && !colSettings.updateEditable) {
										elBut.addClass('disabled');
									}
								}
								else {
									alignElement(element, colSettings);
								}
								if (!colSettings.hidden && colSettings.mandatory && ((colSettings.insertEditable && scope.mode == 'insertEdit') || (colSettings.updateEditable && scope.mode == 'updateEdit'))) {
									scope.isEditable = true;
								}
							});
						}
						else {
                            $compile(getTemplate('tableCell'))(scope, function (clonedElement) {
								clonedElement.append(transcludeEl);
								element.append(clonedElement);
								if (!colSettings.updateEditable && scope.columnType == "input") {
									var el = element.find('input');
									el[0] ? el[0].disabled = true : "";
								}
								if (!colSettings.updateEditable && scope.columnType == "input") {
									var el = element.find('button');
									if (el && el[0] && !colSettings.updateEditable) {
										el.addClass('disabled');
									}
								}
								alignElement(element, colSettings);
							});
						}
					});
				}
				if (!colSettings.hidden && scope.columnType == 'text') {
                    $compile(getTemplate('text'))(scope, function (clonedElement) {
						element.append(clonedElement);
						alignElement(element, colSettings);

						if (colSettings.mandatory && ((colSettings.insertEditable && scope.mode == 'insertEdit') || (colSettings.updateEditable && scope.mode == 'updateEdit'))) {
							scope.isEditable = true;
						}
					});
                }
			}
		}
		else { // If column settings were not found, it is safer to hide element.
			hideElement(element);
		}
	}
	return {
		restrict: 'E',
		transclude: true,
		scope: {
			force: '=?',
			formId: '=?',		// Alternative to formSettings
			formVersion: '=?',
			formSettings: '=?',	// Alternative to formId (+formVersion) 
			columnName: '=',
            columnType: '=',
            enumList: '=?',
            mode: '=?', // read (default), insertEdit or updateEdit
            nameModel: '=?',
            order: '=?',
            onValueChange: '&?', // Currently supported in modal checkbox.
			by: '=?',
			sortTypeDefault: '=?',
            headerTitle: '=?',
            unit: '=?', // Value unit. Some column types show unit after the value.
            valueModel: '=?'
		},
		compile: function (element, attributes) { //use compile instead of link especially in performance heavy directives. 
			return {
				pre: function (scope, element, attributes, controller, transclude) {
					var executeDirective = function () {
                        usersettings.getLoginPromise().then(function () {
                            scope.mode = scope.mode || 'read';
							scope.isEditable = false; // false by default. Finally set in handleSettings.

							if (scope.formSettings) {
								handleSettings(scope, element, transclude);
							}
							else {
								commonDatacontext.getFormSettings(scope.formId, scope.force, scope.formVersion).then(function (data) {
									if (data) {
										scope.formSettings = data;
										handleSettings(scope, element, transclude);
									}
									else { // If no form settings found, hide element.
										hideElement(element);
									}
								},
									function (data) { // Form settings query resulted to an error.
										scope.formSettings = null;
										hideElement(element);
									});
							}

                            scope.onClick = function (event) {
                                if (typeof scope.order === 'undefined') {
                                    return;
                                }
                                else {
                                    if (event && event.ctrlKey && scope.by) { // Multi column sorting.
                                        event.preventDefault(); //ctrl click creates new tab by default
                                        if (!angular.isArray(scope.by)) {
                                            scope.by = [scope.by];
                                        }
                                        // If it is in the list, reverse list item.
                                        for (var i = 0; i < scope.by.length; i++) {
                                            if (scope.by[i] === scope.order) {
                                                scope.by[i] = '-' + scope.order;
                                                return;
                                            }
                                            else if (scope.by[i] === '-' + scope.order) {
                                                scope.by[i] = scope.order;
                                                return;
                                            }
                                        }
                                        scope.by.push(scope.order);
                                    }
                                    else { // Single column sorting.
                                        if (scope.sortIcon() === 2) {
                                            if (scope.sortTypeDefault) {
                                                scope.by = scope.sortTypeDefault.slice();
                                            }
                                            else {
                                                scope.by = null;
                                            }
                                        } else if (scope.sortIcon() === 1) {
                                            scope.by = ['-' + scope.order];
                                        }
                                        else {
                                            scope.by = [scope.order];
                                        }
                                    }
                                }
                            };

                            scope.onChange = function () {
                                if (this.onValueChange) {
                                    this.onValueChange();
                                }
                            };

							scope.sortIcon = function () {
								if (scope.by) {
									if (scope.by === scope.order) return 1;
									for (var i = 0; i < scope.by.length; i++) {
										if (scope.by[i] === scope.order) return 1;
										if (scope.by[i] === '-' + scope.order) return 2;
									}
								}
								return 0;
							};

							scope.title = function () {
								if (scope.headerTitle) {
									return scope.headerTitle;
								}
								if (scope.formSettings && scope.formSettings.columnSettings && scope.formSettings.columnSettings[scope.columnName]) {
									return scope.formSettings.columnSettings[scope.columnName].headerText;
								}
								/*if (scope.by) {
									var hint = strConst.LEANPORTAL$TXT_CLICK_TO_SORT_TOOLTIP + '\n' + strConst.LEANPORTAL$COL_GRP_SORT + ': ';
									var pattern = /-/ig;
									return hint + scope.by.toString().replace(pattern, '(' + strConst.LEANPORTAL$TXT_DESCENDING.toLowerCase() + ') '); // TODO: Use language constants for column names/object properties.
								}*/
								return "";
							}
						});
					}
					//$timeout(executeDirective, 0); //Moves the directive execution after DOM rendering in event queue
					executeDirective();

				},
				post: function (scope, element, attributes, controller, transcludeFn) {

				}
			}
		}
	}
}]);
export default columnModule