'use strict';
import './libModule.js';
import 'ocLazyLoad'; //This is standard module import in ES6. TODO: Refactor to use ES6 import for all dependencies.
import './services/entityManagerFactory';
import './common/common';
import './services/commonDatacontext'
import './services/usersettings'
import './services/hiDatacontext'
import './services/settings'
import './services/model'
import './services/signalR'
import './services/dfxGrid'
import './services/directives.js'
import './layout/home'
import './layout/maintenance'
import './layout/login'
import './layout/portaltab'
import './layout/startup'
import './layout/shell'
import './layout/topnav'
import './common/basecontroller'
import './common/logger'
import './common/spinner'
import './common/comment/comment'
import './common/feed/feed'
import './common/user/substitutes'
import commonModuleExtension, { config, events, remoteServiceName } from './config.js'
import './calendar'
import './config.exceptionHandler'
import './hourinput/singleworktime'
import './hourinput/resourceworktime'
import './common/feed/feedtab'
import './common/directives/directivesModule'
import './common/image/directives/imageDirectivesModule'
import './common/document/directives/documentDirectivesModule'
import './common/checklist/directives/checklistDirectivesModule'
import './common/text/directives/textDirectivesModule'
import './manufacturing/directives/manufacturingDirectivesModule'
import './quality/directives/qualityDirectivesModule'
import "./directivesTemplates"
import PouchDB from 'pouchdb-browser'

var templates = angular.module('templates', []);
var app = angular.module('app', [
		'entityManager',
		'dir',
		'docdir',
		'checkdir',
		'imagedir',
		'textdir',
		'manudir',
		'quadir',
		// Angular modules 
		require('angular-animate'),        // animations
		require('angular-route'),          // routing
		require('angular-sanitize'),       // sanitizes html bindings (ex: sidebar.js)

		// Custom modules 
		'common',           // common functions, logger, spinner
		//'common.bootstrap', // bootstrap dialog wrapper functions

		// 3rd Party Modules
		require('angular-ui-bootstrap'),      // ui-bootstrap (ex: carousel, pagination, dialog)
		'ui.bootstrap.tpls',
		require('angular-ui-grid'),
		'ui.grid.edit',
		'ui.grid.expandable',
		'ui.grid.selection',
		'ui.grid.autoResize',
		'ui.grid.cellNav',
		'ui.grid.exporter',
		'ui.grid.grouping',
		'ui.grid.pinning',
		'breeze.directives', // breeze validation directive (zValidate)
		'ngzWip',            // local storage and WIP module
		require('angular-cookies'),
		'pascalprecht.translate',
		require('angular-dynamic-locale'),
		'oc.lazyLoad'
	]).config(function ($compileProvider) {
        $compileProvider.debugInfoEnabled(false);
		$compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|s?ftp|mailto|tel|file|):|(data:image)/);
	}).config(function (tmhDynamicLocaleProvider) {
		tmhDynamicLocaleProvider.localeLocationPattern('Scripts/i18n/angular-locale_{{locale}}.js');
	}).config(['$qProvider', function ($qProvider) {
		$qProvider.errorOnUnhandledRejections(false);
	}]);
	// Configure the zStorage and zStorageWip services via zStorageConfig
app.config(['zStorageConfigProvider', function (cfg) {
	cfg.config = {
		// zStorage
		enabled: true,
		key: 'LeanPortalSPA',
		events: events.storage,
		appErrorPrefix: config.appErrorPrefix,
		version: config.version,
		// zStorageWip
		wipKey: 'LeanPortalSPA.wip',
		newGuid: breeze.core.getUuid
	};
}]);

	app.provider('getAppSettings', function getAppSettingsProvider() {
		this.getAppSetting = function (key, defval) {
			var appSettings = configObject;
			if (key in appSettings) {
				return appSettings[key];
			}
			else {
				return defval;
			}
		}
		this.$get = [ function getAppSettingsFactory() {

			return new getAppSettings();
		}];
	})
	var applicationId;
	var supportedLanguages;
	var useUserDefaultLanguage;
	var maintenanceBreakMode;
	var forcedCultureIdList;
	var defaultFallbackLanguage;
	var maintenanceBreakStart;
	var maintenanceBreakDuration;
	app.config(["getAppSettingsProvider", function (getAppSettingsProvider) {
			applicationId = getAppSettingsProvider.getAppSetting("DbAlias", "");
			supportedLanguages = getAppSettingsProvider.getAppSetting("supportedLanguages", "EN");
			useUserDefaultLanguage = getAppSettingsProvider.getAppSetting("UseUserDefaultLanguage", "0");
			maintenanceBreakMode= getAppSettingsProvider.getAppSetting("MaintenanceBreakMode", "0");
			maintenanceBreakStart= getAppSettingsProvider.getAppSetting("MaintenanceBreakStart", "");
			maintenanceBreakDuration = getAppSettingsProvider.getAppSetting("MaintenanceBreakDuration", "0");
			useUserDefaultLanguage = getAppSettingsProvider.getAppSetting("UseUserDefaultLanguage", "0");
			forcedCultureIdList = getAppSettingsProvider.getAppSetting("forcedCultureIdList", "0");
			defaultFallbackLanguage = getAppSettingsProvider.getAppSetting("DefaultLanguage", "EN");
	}]);
	app.factory('strConstLoader', ['entityManagerFactory', '$rootScope', function (emFactory, $rootScope) {
		var EntityQuery = breeze.EntityQuery;
		var commonManager = emFactory.newManager('breeze/Common');
		var userData = { oauth: null, info: null };
		function getLocStoKey(key, noUserId, opulist, companylist, userlist, selEmployee) {
			if (userData && userData.info && userData.info.userId && !noUserId) {
				if (opulist) {
					return applicationId + '_' + userData.info.company + '_' + userData.info.userId + '_' + key;
				}
				else if (companylist) {
					return applicationId + '_' + userData.info.userId + '_' + key;
				}
				else if (userlist) {
					return applicationId + '_' + userData.info.company + '_' + key;
				}
				else if (userData.info.useOPU) {
					return applicationId + '_' + userData.info.company + '_' + userData.info.opu + '_' + userData.info.userId + '_' + key;
				}
				else if (selEmployee) {
					return applicationId + '_' + userData.info.company + '_' + userData.info.userId + '_' + selEmployee + '_' + key;
				}
				else {
					return applicationId + '_' + userData.info.company + '__' + userData.info.userId + '_' + key;
				}
			} else {
				return applicationId + '_' + key;
			}
		}
		var localDb = new PouchDB(getLocStoKey('SPA', true), { auto_compaction: true });
		function addOrUpdateObjInLocalDatabase(dbEntryKey, object) {
			
			var entryKey = dbEntryKey;
			/*Construct object to be stored to database.*/
			if (object != null && entryKey != null) {
				var objEntry = {
					_id: entryKey,
					_rev: null,
					object: object
				};
				/* Check if object is stored already. If it is, get revision.*/
				localDb.get(entryKey, 'OBJECT', function (err, obj) {
					if (err && err.status === 404) {
						/*Object not found, put without revision*/
						return localDb.put(objEntry, function (err, response) {
							
							// handle response
						});
					}
					if (obj) {
						objEntry._rev = obj._rev;
						localDb.put(objEntry, function (err, response) {
							
							// handle response
						});
					}
				});
			}
		}
		function getStrConsts(languages) {
			var ajaxAdapter = breeze.config.getAdapterInstance('ajax');
			ajaxAdapter.requestInterceptor = function (requestInfo) {
				if (requestInfo.config.url.indexOf("StrConsts") != -1) {
					requestInfo.config.headers = { "Cache-Control": "max-age=99999", "Pragma": "" };
				}
			};

			var source = 'getStrConsts';
			var queryPromise = EntityQuery
				.from('StrConsts')
				.withParameters({
					languages: languages
				})
				.noTracking()
				.using(commonManager)
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if ($rootScope.isWorkingLocally) {
						addOrUpdateObjInLocalDatabase("constants", data.results[0])
						return localDb.allDocs({ include_docs: true }).then(function (result) {
							var constants = result.rows[0].doc.object
							return constants;

						});
					}
					else {
						return data.results[0]
					}
					
					
					

				});

            function querySucceeded(data) {
			}

			function queryFailed(data) {

			}

			return queryPromise;

		}
		// return loaderFn
		return function (options) {
			return getStrConsts(options.key).then(function (data) {
				return data[options.key];
			}, function (err) {
				return (options.key)
			});
		};
	}]);
	app.config(['$translateProvider', function ($translateProvider) {
		var availableLanguages = [];
		if (supportedLanguages) {
			availableLanguages = supportedLanguages.toLowerCase().split(';');
		}
		//Register languages defined in web.config for $translate.
		// Culture remapping here

		var mappings = {};
		forcedCultureIdList = forcedCultureIdList.split(';');
		for (var i = 0; i < forcedCultureIdList.length; i++) {
			var part = forcedCultureIdList[i].split('=');
			var firstPart = part[0].split('-')[0] + '*';
			var lastPart = part[1].split('-')[0];
            mappings[firstPart] = lastPart;
		}
		mappings['en*'] = 'en';
		mappings['fi*'] = 'fi';
		mappings['sv*'] = 'se';
		mappings['*'] = availableLanguages[1];
		$translateProvider.registerAvailableLanguageKeys(availableLanguages, mappings);

        $translateProvider.fallbackLanguage(defaultFallbackLanguage.toLowerCase()); //Fallback language if all else fails.		
        if (availableLanguages && availableLanguages.length === 1) {
            $translateProvider.preferredLanguage(availableLanguages[0]); //Forced language.
            browserPreferredLanguage = $translateProvider.preferredLanguage();
        }
        else {
            $translateProvider.determinePreferredLanguage(); //Determines the users language primarily based on browser language.
            // Don't set browserPreferredLanguage here as IE does it incorrectly. Trust the value set in index.cshtml.
        }
		$translateProvider.useLoader('strConstLoader'); //Load string constants from JSON query		
		$translateProvider.useSanitizeValueStrategy('escape'); //Prevent HTML-injections
        if (useUserDefaultLanguage === '1') {
			$translateProvider.useLocalStorage(); //Remembers language choice based on local storage.
        }        
	}]);

	// Handle routing errors and success events
	app.run(['$route', '$rootScope', 'common', 'usersettings', function ($route, $rootScope, common, usersettings) {
		// Include $route to kick start the router.
		var getLogFn = common.logger.getLogFn;
		var log = getLogFn('App');
        var warnMsg = getLogFn('App', 'warning');

        /**
         * Helper function for setting document (whole browser page) title.
         * @param {object} route - Route whose title function or title is used.
         */
        function setDocumentTitleForRoute(route) {
            var title = null;
			if (route && route.__proto__) {
                try {
                    // Try to get portal name first.
                    if (route.portalNameFunc && eval(route.portalNameFunc) && eval(route.portalNameFunc).indexOf('STRCONST.', 0) < 0) {
                        title = eval(route.portalNameFunc);
                    }
                    // Try to get tab name next.
                    if (route.titleFunc && eval(route.titleFunc) && eval(route.titleFunc).indexOf('STRCONST.', 0) < 0) {
                        title = (title && title !== eval(route.titleFunc) ? title + ' / ' : '') + eval(route.titleFunc) + ' - ';
                    }
                } catch (e) {
                    //
                }
                // If no title set this far, try to get from title.
                !title && route.title ? title = route.title + ' - ' : null;
            }
            title ? $rootScope.title = title : null;
        }

        $rootScope.$on('$translateChangeSuccess', function () {
            if (common && common.$route && common.$route.current) {
                setDocumentTitleForRoute(common.$route.current);
            }
        });

		$rootScope.$on('$routeChangeSuccess', function (event, current, previous) {
			if (current.__proto__) {
                setDocumentTitleForRoute(current);
				if ($route.current.portalId) {
					var lsKey = usersettings.getLocStoKey('PersonalSettings');
					common.setLastPortalId(lsKey, lsKey !== usersettings.getLocStoKey('PersonalSettings', true), current.__proto__.portalId);
					common.$broadcast('PORTALCHANGE', { portalId: current.__proto__.portalId });
				}
			}
		});

        $rootScope.$on('$routeChangeStart', function (event, next, previous) {
            if (common.$rootScope.online && !common.$rootScope.isWorkingLocally) {
				if (next.__proto__) {
					var nextPath = next.__proto__.originalPath;
					$rootScope.$evalAsync(function () {
						if (nextPath == $route.routes[nextPath].originalPath && next.__proto__.portalId) {
							usersettings.checkAccessRight(next.__proto__).then(function (accessRight) {
								if (accessRight == false) {
									warnMsg(common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NOT_AVAILABLE"));
									usersettings.getPath(next.__proto__.portalId);
								}
								else {
									/*Stores route params into variable in common service, used to highlight previous selected row in ui-grid*/
									var obj = next.params;
									if (obj && Object.keys(obj).length > 0) {
										common.setLastGridId(obj);
									}
								}
							});
						}
					});
				}
			}
        });

        $rootScope.$on('$locationChangeStart', function (event, newUrl) {
            /* If maintenance is active, redirect user to the maintenance page. */
            if (maintenanceBreakMode == 2) {
                common.$broadcast('MAINTENANCE');
                common.$location.path("/maintenance");
            } else if (maintenanceBreakMode == 1 && moment.utc(maintenanceBreakStart, 'YYYYMMDDHHmmss').isValid()) {
                var time = moment.utc(maintenanceBreakStart, 'YYYYMMDDHHmmss');
                if (moment().utc().isBetween(time, moment.utc(time).add(maintenanceBreakDuration, 'm'))) {
                    common.$broadcast('MAINTENANCE');
                    common.$location.path("/maintenance");
                }
            }
        });
	}]);


	app.run(['$rootScope', 'commonDatacontext', function($rootScope, commonDatacontext) {
		$rootScope.appSettings = commonDatacontext.getSettings()
	}])

	app.run(['$window', '$rootScope', function ($window, $rootScope) {
		$rootScope.online = navigator.onLine;
		$window.addEventListener("offline", function () {
			$rootScope.$apply(function () {
				$rootScope.online = false;
			});
		}, false);
		$window.addEventListener("online", function () {
			$rootScope.$apply(function () {
				$rootScope.online = true;
			});
		}, false);

		$rootScope.isWorkingLocally = false;
		var lsid = applicationId + '_IsWorkingLocally';
		if (localStorage && localStorage.getItem(lsid)) {
			$rootScope.isWorkingLocally = JSON.parse(localStorage.getItem(lsid));
		}

	}]);

	app.config([
		'$httpProvider', '$provide', function ($httpProvider, $provide) {
			// register the interceptor as a service
			$provide.factory('dlgHttpInterceptor', function ($q) {
				return {
					'response': function (response) {
						return response;
					}
				};
            });
            $provide.factory('maintenanceInterceptor', function ($q) {
                return {
                    'responseError': function (response) {
                        if (response.status === 503) {
                            var data = JSON.parse(response.data);
                            maintenanceBreakMode = data.maintenanceBreakMode;
                            maintenanceBreakStart = data.maintenanceBreakStart;
                            maintenanceBreakDuration = data.maintenanceBreakDuration;
                        }
                        return $q.reject(response);
                    }
                };
            });

			$httpProvider.interceptors.push('dlgHttpInterceptor', 'maintenanceInterceptor');
			// IE caching caused problems with subsequential API calls <=> disable caching
			if (!$httpProvider.defaults.headers.get) {
				$httpProvider.defaults.headers.get = {};
			}
			$httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
			$httpProvider.defaults.headers.get['Pragma'] = 'no-cache';
		}
	]);

	app.run(["$templateCache", function ($templateCache) {
		$templateCache.put("dfxTypeheadColumns.html", require("./common/directives/dfxTypeheadColumns.html"))
		$templateCache.put("dfxTypeheadPopup.html", require("./common/directives/dfxTypeheadPopup.html"))
		// Replace typeahead-popup with custom one.
		$templateCache.put("uib/template/typeahead/typeahead-popup.html", $templateCache.get("dfxTypeheadPopup.html"));
	}]);

	app.filter('nonEmpty', function () {
		return function (object) {
			return !!(object && Object.keys(object).length > 0);
		};
	});

	app.filter('dfxNumber', ['common', function (common) {
		return function (object) {
			return common.dblToLocalStr(object);
		};
	}]);

//this could be used to automatically import all js files automatically but we lose code splitting ability.
//function importAll(r) {
//	r.keys().forEach(r);
//}
//importAll(require.context("./", true, /^(?!.*(?:dist|node_modules|hourinput.js$)).*\.js$/));
export { app };
