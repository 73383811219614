
	'use strict';
	var controllerId = 'startup';
	var startupModule= angular.module('common').controller(controllerId, ['$scope', 'config', 'common', 'usersettings', '$location', startup])

	function startup($scope, config, common, usersettings, $location) {
        var getLogFn = common.logger.getLogFn;
        var log = getLogFn(controllerId);
        
        activate();

        function activate() {
        	usersettings.getUserSettings().then(function (data) {
        	    if (!data || !data.info || !data.info.userId) {
        			usersettings.redirectToLogin(false);
        		} else {
        			usersettings.authorizeHeader();
        			common.activateController(controllerId).then(function () {
        			    // Redirect to last portal or default portal.
        			    usersettings.getPath();
        				
        			});
        		}
        	}, function () {
        		usersettings.redirectToLogin();
        	});
        }
    }
export default startupModule