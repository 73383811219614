'use strict';
var dataIconModule = angular.module('dir.dfxCommonDataIcon',[]);
/**
 * Common data icon
 */
dataIconModule.directive('dfxCommonDataIcon', function () {
    // Provides Favourites selection to model object.
    // Usage:
    // <dfx-favourite model="m" object-type="'DLG_RESOURCE'" on-changed-callback="ctrl.favStatusChanged(model)"></dfx-favourite>
    return {
        restrict: 'E', //E = element, A = attribute, C = class, M = comment 
        scope: {},
        controllerAs: 'ctrl',
        bindToController: {
            ownerTag: '=',
            modelObject: '=',
            dialogSize: '=?'
        },
        controller: ['$scope', 'common', 'usersettings', function ($scope, common, usersettings) {
            var ctrl = this;

            ctrl.clicked = function () {
                common.openCommonData($scope, usersettings, ctrl.modelObject, ctrl.ownerTag, ctrl.dialogSize);
            }

            ctrl.getCount = function () {
                return typeof ctrl.modelObject.idthcCount === 'undefined' ? 0 : ctrl.modelObject.idthcCount;
            }
        }],
        template: require('./dfxCommonDataIcon.html')
    };
});
export default dataIconModule