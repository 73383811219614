
	'use strict';
	var controllerId = 'home';
	var homeModule=angular.module('common').controller(controllerId, ['$scope', 'usersettings', home]);

	function home($scope, usersettings) {

		/* ---------------  Properties ---------------- */
		var ctrl = this;
		var companyOpuChanged = false;
		ctrl.portals = [];

		// Pager
		ctrl.currentPage = 1;
		ctrl.rowsPerPage = 10;
		ctrl.maxPages = 12;

		/**
		 * Changes portal to portal whose id is given as parameters.
		 * @param {Id of portal to change to.} portalId
		 */
		ctrl.changePortal = function (portalId) {
			usersettings.changePortal(portalId);
		}

		/* ----------  Watches and broadcasts --------- */

		$scope.$on('COMPANYOPUCHANGES', function () {
			companyOpuChanged = true;
			activate();
		});

		$scope.$on('PORTALCHANGE', function (event, args) {
			/* Get portal list from usersettings. */
			ctrl.portals = usersettings.getPortalInfo();
		});

		/* ---------------- Functions ----------------- */

		function activate() {
			usersettings.getUserSettings().then(function (data) {
				if (!data || !data.info || !data.info.userId) {
					usersettings.redirectToLogin();
				}
				ctrl.portals = usersettings.getPortalInfo();
			}, function () {
				usersettings.redirectToLogin();
				companyOpuChanged = false;
			});
		}

		activate();
	}
export default homeModule
