'use strict';
var settingsLaunchModule = angular.module('dir.dfxSettingsLaunch',[]);
settingsLaunchModule.directive('dfxSettingsLaunch', function () {
    return {
        restrict: 'E', //E = element, A = attribute, C = class, M = comment 
        scope: {},
        controllerAs: 'ctrl',
        bindToController: {
            settings: '='
        },
        controller: ['$scope', 'common', function ($scope, common) {
            var ctrl = this;
            ctrl.open = function () {
                common.openPortalSettings($scope, ctrl.settings);
            }
        }],
        template: require('./dfxSettingsLaunch.html')
    }
});
export default settingsLaunchModule