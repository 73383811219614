import PouchDB from 'pouchdb-browser'

'use strict';
var serviceId = 'usersettings';
var usersettingsModule = angular.module('common').factory(serviceId, ['common', 'entityManagerFactory', 'spinner', '$uibModalStack', '$httpParamSerializerJQLike' , usersettings]);
	
function usersettings(common, emFactory, spinner, $uibModalStack, $httpParamSerializerJQLike) {
		var applicationId = common.getAppSetting("DbAlias", "");
		var $q = common.$q;
		var $http = common.$http;
		var getLogFn = common.logger.getLogFn;
		var isSSOUser;
		var localDb = new PouchDB(getLocStoKey('SPA', true), { auto_compaction: true });
		var log = getLogFn(serviceId);
		var logSuccess = getLogFn(serviceId, 'success')
		var errMsg = getLogFn(serviceId, 'error');
		var warnMsg = getLogFn('Usersettings', 'warning');
		var EntityQuery = breeze.EntityQuery;
		var commonManager = emFactory.newManager('breeze/Common');
		var loggingOut = false;
		var userDefCompany = undefined;
		var companyStatus = false;
		var loginDeferred = $q.defer();
		var passwordExpiring = false;
		var pathInfo = {};
		var portalInfo;
		var sessionEndedMsgGiven = false;
		var sessionWarningMsgGiven = false;
		var sessionLastRefreshTime;
		var sessionPollingActivated = false;
		var userData = { oauth: null, info: null };
		var service = {
			authorizeHeader: authorizeHeader,
			changeLanguage: changeLanguage,
			changeOPU: changeOPU,
			changePassword: changePassword,
			changePortal: changePortal,
            checkAccessRight: checkAccessRight,
            checkPermissionToForm: checkPermissionToForm,
            checkTopBannerVisibility: checkTopBannerVisibility,
			errHandler: errHandler,
			getChangeCompany: getChangeCompany,
			getDefCompany: getDefCompany,
			getGridFilterKey: getGridFilterKey,
			getLastUserInfo: getLastUserInfo,
			getLocStoKey: getLocStoKey,
			getLoginPromise: getLoginPromise,
			getManuals: getManuals,
			getOAuthData: getOAuthData,
			getPath: getPath,
			getPersonalSettingValue: getPersonalSettingValue,
			getPortalInfo: getPortalInfo,
			getPortalRoute: getPortalRoute,
			getPortals: getPortals,
			getSessionId: getSessionId,
			getSetCompanyStatus: getSetCompanyStatus,
			getUserSettings: getUserSettings,
			handleSaveError: handleSaveError,
			localDb: localDb,
			login: login,
			loginWithSessionId: loginWithSessionId,
			loginWithTagId: loginWithTagId,
			logoff: logoff,
			redirectToLogin: redirectToLogin,
			redirectToLoginReact: redirectToLoginReact,
			setElementsSize: setElementsSize,
			setLastUserInfo: setLastUserInfo,
			setPersonalSettingValue: setPersonalSettingValue,
			setUserSettingValue: setUserSettingValue
			// Maintain alphabetical order please.
		};

		return service;


		// Common Data Visibility is managed in two levels
		// 1) From the Owner one can control if Common Data is available
		// 2) In Common Data context one can control which data is visible
		// Change in one place can have effect in other <=> all data's are unticked
		// the whole visibility is removed
		// Owner defined data visible <=> all datas are marked visible
		// Param name format:
		// commonDataView_<TaskView>_Visible: true/false
		// commonDataView_<TaskView>_<Comment>: true/false
		// TODO: Move this to some other location. Does not work here - $scope cannot be set in service.
		$scope.$on('PORTALSETTINGSCHANGED', function (event, args) {
			var discardOwn = '';
			if (args.model && (args.value != undefined)) {
				common.getCommonDataOwnerTags().forEach(function (o) {
					var viewVisibility = common.getCommonDataParamName(o);
					if (args.model == viewVisibility && discardOwn != viewVisibility) {
						if (args.value) {
							common.getCommonDataTypeTags().forEach(function (d) {
								var param = common.getCommonDataParamName(o, d);
								usersettings.setPersonalSettingValue(param, args.value);
								// Tick all possible common datas visible
								common.$broadcast('PORTALSETTINGSCHANGED', { model: param, value: args.value });
							});
						}
					}

					else {
						common.getCommonDataTypeTags().forEach(function (d) {
							// Check is the last one was unticked
							if (args.model == common.getCommonDataParamName(o, d) && !args.value && areAllUnticked(o)) {
								var param = common.getCommonDataParamName(o);
								discardOwn = param;
								usersettings.setPersonalSettingValue(param, args.value);
								// Tick all possible common datas visible
								common.$broadcast('PORTALSETTINGSCHANGED', { model: param, value: args.value });
							}
						});
					}
				});
			}
			function areAllUnticked(owner) {
				var ret = true;
				common.getCommonDataTypeTags().forEach(function (d) {
					if (usersettings.getPersonalSettingValue(common.getCommonDataParamName(owner, d), true)) {
						ret = false;
					}
				});
				return ret;
			}
		});

		function clearUserData() {
			userData.oauth = null;
            userData.info = null;
            loginDeferred.reject();
			loginDeferred = $q.defer();
			userDefCompany = false;  
		}

		function login(userId, password, offlineLoginEnabled, changeNewPassword) {
			passwordExpiring = false;
			toastr.clear();
			let postData = {
				grant_type: 'password',
				username: userId,
				password,
			};
			if (changeNewPassword) {
				postData = {
					...postData,
					changeNewPassword
				};
			}

			var result = $http({
				method: 'POST',
				url: 'token',
				data: $httpParamSerializerJQLike(postData),
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			}).then(function (data) {
				var config = data.config;
				var oAuthData = data.data;
				var headers = data.headers;
				var status = data.status;
				document.execCommand('ClearAuthenticationCache', 'true'); //IE authentication header fix
				authorizeHeader(oAuthData);
				if (userId == "SSO-user") {
					isSSOUser = true;
					var realUserId = userId;
					userData.oauth = oAuthData;
					getUserInfoFromQ().then(
						function (data) {
							realUserId = data.userId;
							initUserInfo(realUserId, oAuthData, true).then(
								function (data) {
									getDefCompany().then(function (data) {
										if (data) {
											logSuccess(common.$translate.instant("STRCONST.PUBLIC.MSG_SUCCESSFUL_LOGIN"), data.message, true);
											getPath(null, true);
											common.$broadcast('USERSETTINGS');
										}
										else {
											logSuccess(common.$translate.instant("STRCONST.PUBLIC.MSG_SUCCESSFUL_LOGIN"), data, true);
											companyStatus = true;
											common.$location.path('/login');
										}
									},
										function (data) {
										});
								},
								function (data) {
									// Something failed with user info retrieval
									errMsg(common.$translate.instant("STRCONST.PUBLIC.ERR_UNSUCCESSFUL_LOGIN") + ': ' + errorMessage);
								});
						},
						function (data) {
							isSSOUser = false;
						});
				}
				else {
					isSSOUser = false;
					// Reset session timeout messages.
					sessionEndedMsgGiven = false;
					sessionWarningMsgGiven = false;
					sessionPollingActivated = false;
					initUserInfo(userId, oAuthData, true).then(
						function (data) {
							if (changeNewPassword) {
								logSuccess(common.$translate.instant("STRCONST.PUBLIC.MSG_PASSWORD_CHANGED"));
							}
							getDefCompany().then(function (data) {
								if (data) {
									logSuccess(common.$translate.instant("STRCONST.PUBLIC.MSG_SUCCESSFUL_LOGIN"), data, true);
									if (!passwordExpiring) {
										passwordExpiring = false;
										getPath(null, true);
									}
									common.$broadcast('USERSETTINGS');
								}
								else {
									logSuccess(common.$translate.instant("STRCONST.PUBLIC.MSG_SUCCESSFUL_LOGIN"), data, true);
									companyStatus = true;
									common.$broadcast('SELECTCOMPANY');
								}
							},
								function (data) {
								});
						},
						function (data) {
							// Something failed with user info retrieval
							errMsg(common.$translate.instant("STRCONST.PUBLIC.ERR_UNSUCCESSFUL_LOGIN") + ': ' + errorMessage);
						});
				}
			}).catch(function (data, status, headers, config) {
				/* Check if password has expired.
                 * ErrCode: 10131:Password has expired
                 */
				var errCode = data.error_description;
				if (errCode == '10131') {
					common.$broadcast('PASSWORDEXPIRED');
					return;
				}
				if (offlineLoginEnabled) {
					initUserInfo(null, null, false).then(
						function (data) {
							log(common.$translate.instant("STRCONST.PUBLIC.MSG_SUCCESSFUL_LOGIN") + ' (Offline)');
							getPath(null, true);
							common.$broadcast('USERSETTINGS');
						},
						function (data) {
							errMsg(common.$translate.instant("STRCONST.PUBLIC.ERR_UNSUCCESSFUL_LOGIN") + ' (Offline):' + data.message);
						});
				} else {
					if (userId != "SSO-user") {
						errMsg(common.$translate.instant("STRCONST.PUBLIC.ERR_UNSUCCESSFUL_LOGIN"));
					}
					clearUserData();
					redirectToLogin(true);
				}
			});
			return $q.when(result);
		}

		function loginWithTagId(tagId) {
			var userid = "TAGID-user";
			return login(userid, tagId);
		}

		function changeLanguage(lang) {
			const source = "ChangeLanguage";
			const spinnerActivated = spinner.spinnerShow(source);
			return $http.post('api/Common/ChangeLanguage', JSON.stringify(lang))
				.then(({ data }) => {
					log("changeLanguage" + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
					spinner.queryFinally(source, spinnerActivated);
					return data;
				})
				.catch((err) => {
					spinner.queryFinally(source, spinnerActivated)
					usersettings.errHandler("changeLanguage" + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', err);
				})
		}

		function getManualsFromQ(language, cacheKey) {
			var source = 'GetManuals';
			var spinnerActivated = spinner.spinnerShow(source);
			var query;
			query = EntityQuery
				.from('GetManuals')
				.withParameters({ language: language })
				.using(commonManager)
				.noTracking()
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data && cacheKey) {
						common.setCachedData(cacheKey, data.results);
					}
					if (data) {
						return data.results;
					}
				}, function () {
					common.rejectCachedData(cacheKey);
					return null;
				});

			function querySucceeded(data) {
				log("getManuals" + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
				spinner.queryFinally(source, spinnerActivated);
			}
			function queryFailed(data) {
				spinner.queryFinally(source, spinnerActivated);
				usersettings.errHandler("getManuals" + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data.status);
			}
			return query;
		}

		function getManuals(language) {
			var cacheKey = getLocStoKey('Manuals_' + language);
			var cachePromise = common.getCachedData(cacheKey);
			if (cachePromise) {
				return $q.when(cachePromise);
			}
			var lst = [];
			if (language) {
				lst = getManualsFromQ(language, cacheKey);
			}
			return $q.when(lst);
		}

		function getOAuthData() {
			if (userData) {
				return userData.oauth;
			}
			return null;
		}

		function authorizeHeader(oAuthData) {
			var oAuthData = oAuthData || getOAuthData();
			if (oAuthData && oAuthData.access_token) {
				var accessToken = oAuthData.access_token;
				$http.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
				loggingOut = false;
			}
		}

		function getUserSettings() {
			if (!userData || !userData.oauth) {
				var companyNotSelected = false;
				var lsKey = getLocStoKey('NoCompSelected', true);
				if (localStorage && localStorage.getItem(lsKey)) {
					var lsInfo = JSON.parse(localStorage.getItem(lsKey));
					if (lsInfo && lsInfo === true) {
						companyNotSelected = true;
					}
				}
				if (companyNotSelected) {
					companyStatus = true;
					return $q.when(null);
				}
				else {
					return initUserInfo();
				}
			} else {
				return $q.when(userData);
			}
		}

		//The promise is resolved after login success. Use for waiting on succesful login eg. before starting query. TODO: Most broadcasts should probably be refactored to this approach.
		function getLoginPromise() {
			return $q.when(loginDeferred.promise);			
		}

		/**
		 * Gets and returns named property value from personal setting local storage variable. If not found, uses given default.
		 * @param {string} propertyName - Property name inside local storage person settings variable.
		 * @param {string} [defValue] - If property is not found, returns this value.
		 * @returns {string} Named property value or default value.
		 */
		function getPersonalSettingValue(propertyName, defValue) {
			var lsid = getLocStoKey('PersonalSettings');
			var val = defValue;
			if (localStorage && localStorage.getItem(lsid)) {
				var personalSettings = JSON.parse(localStorage.getItem(lsid));
				if (personalSettings.hasOwnProperty(propertyName)) {
					val = personalSettings[propertyName];
				}
			}
			return val;
		}

		function getSessionId() {
			var sessionId = EntityQuery
				.from('SessionId')
				.using(commonManager)
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						// The breeze query data is really in data.results member...
						// TODO: figure out if these services should return breeze query data or only the results...
						return data.results[0];	// return only the first element
					}
				});
			function querySucceeded(data) { }
			function queryFailed(data) {
				errHandler("", data)
			}
			return $q.when(sessionId);
		}

		/// Currently only supports current user...
		function getUserInfoFromQ(userId) {
			var userInfo;

			// Initial implementation for server based data
			// TODO: 
			userInfo = EntityQuery
				.from('CurrentUser')
				.withParameters({ userId: userId })
				.using(commonManager)
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						// ErrCode: 10130: Password will expire soon.
						if (data.results[0].loginWarningCode && data.results[0].loginWarningCode == '10130' &&
							data.results[0].loginWarningMessage && data.results[0].loginWarningMessage) {
							passwordExpiring = true;
							common.$broadcast('PASSWORDEXPIRING', data.results[0].loginWarningMessage);
						}

						// The breeze query data is really in data.results member...
						// TODO: figure out if these services should return breeze query data or only the results...
						return data.results[0];	// return only the first element
					}
				});

			function querySucceeded(data) {
				if (userId != undefined)
					log('[' + userId + '] ' + common.$translate.instant("STRCONST.PUBLIC.MSG_USERSETTINGS_REFRESHED"), data, true);
			}

			function queryFailed(data) {
				errHandler('[' + userId + '] ' + common.$translate.instant("STRCONST.PUBLIC.ERR_USERSETTINGS_REFRESH_FAILED"), data);
			}
			return userInfo;
		}

		function initUserInfo(userid, oAuthData, forceRefresh) {
				var supportedLanguages = common.getAppSetting("supportedLanguages", "EN");
				var useUserDefaultLanguage = common.getAppSetting("UseUserDefaultLanguage", "0");
				var languageSelectorEnabled = common.getAppSetting("LanguageSelectorEnabled", "1");
				var defaultFallbackLanguage = common.getAppSetting("DefaultLanguage", "EN");
			
			function getLanguage(data) {
				var availableLanguages = [];
				availableLanguages = supportedLanguages.split(';');
				var language = data.paramLanguage;
				if (language) {
					if (availableLanguages.indexOf(language.toUpperCase()) < 0) {
						language = defaultFallbackLanguage;
					}
					var needUsersettingsBroadcast = language.toLowerCase() != common.$translate.use().toLowerCase();
					common.$translate.use(language.toLowerCase()).then(function () {
						if (needUsersettingsBroadcast) {
							common.$broadcast('USERSETTINGS');
						}
						loginDeferred.resolve();
					}, function () {
							loginDeferred.resolve();
					}
						, function () {
							loginDeferred.resolve();
						});
				}
				else if (useUserDefaultLanguage == 1) {
					language = common.$translate.use().toUpperCase();
					if (languageSelectorEnabled && languageSelectorEnabled === '1') {
						changeLanguage(language).then(function () { //change user $LANGUAGE$ param.
							common.$translate.use(language.toLowerCase()).then(function () { //set used language	
								loginDeferred.resolve();
							});
						}
							, function () {
								loginDeferred.resolve();
							}
							, function () {
								loginDeferred.resolve();
							});
					}
					else {
						common.$translate.use(language.toLowerCase()).then(function () {
							loginDeferred.resolve();
						}, function () {
								loginDeferred.resolve();
						}
							, function () {
								loginDeferred.resolve();
							});
					}
				}
				else if (useUserDefaultLanguage == 2) {
					language = data.defaultLanguage;
					if (language) {
						if (availableLanguages.indexOf(language.toUpperCase()) < 0) {
							language = defaultFallbackLanguage.toLowerCase();
						}
						common.$translate.use(language.toLowerCase()).then(function () {
							loginDeferred.resolve();
						}, function () {
								loginDeferred.resolve();
						}
							, function () {
								loginDeferred.resolve();
							});
					}
				}
			}
			var lukey = getLocStoKey('LastUserInfo', true);
			var lstg;
			var company = "";
			var opu = "";
			var empId = "";
			var empName = "";

			common.resetCachedData();
			if (!userid) {
				if (localStorage && localStorage.getItem(lukey)) {
					userid = JSON.parse(localStorage.getItem(lukey)).userId;
					isSSOUser = JSON.parse(localStorage.getItem(lukey)).isSSOUser;
					company = JSON.parse(localStorage.getItem(lukey)).company;
					opu = JSON.parse(localStorage.getItem(lukey)).opu;
					empId = JSON.parse(localStorage.getItem(lukey)).empId;
					empName = JSON.parse(localStorage.getItem(lukey)).empName;
				}
				if (!userid) {
					return $q.reject();
				}
			}
			userid = userid.toUpperCase();
			var luinfo = { "userId": userid, "isSSOUser": isSSOUser, "company": company, "opu": opu, "empId": empId, "empName": empName };
			localStorage.setItem(lukey, JSON.stringify(luinfo));
			if (userid != null) {
				var lsid = getLocStoKey(company + '_' + opu + '_' + userid + '_UserSettings', true);

				if (localStorage && localStorage.getItem(lsid) && !forceRefresh) {
					lstg = JSON.parse(localStorage.getItem(lsid));

					if (lstg && lstg.oauth && lstg.info) {
						userData = lstg;
						authorizeHeader(lstg.oauth);
						// Fill the user info 
						return getUserInfoFromQ(userid).then(
							function (data) {
								userData.info = data;
								localStorage.setItem(getLocStoKey('UserSettings'), JSON.stringify(userData));
								setElementsSizeDefault();
								setElementsSize(getPersonalSettingValue('elementSize'));
								resetSessionTimeout();
								luinfo.userId = data.userId;
								luinfo.isSSOUser = isSSOUser;
								luinfo.opu = data.opu;
								luinfo.company = data.company;
								luinfo.empid = data.empId;
								luinfo.empName = data.empName;
								localStorage.setItem(lukey, JSON.stringify(luinfo));
								getLanguage(data);
								return $q.when(userData);
							},
							function (data) {
								// TODO: Figure out how we prevent multiple API hits when the user just isn't initialized!
								clearUserData();
							});
					}
				}
				userData.oauth = oAuthData;
				authorizeHeader();
				// Fill the user info 
				return getUserInfoFromQ(userid).then(
					function (data) {
						userData.info = data;
						localStorage.setItem(getLocStoKey('UserSettings'), JSON.stringify(userData));
						setElementsSizeDefault();
						setElementsSize(getPersonalSettingValue('elementSize'));
						resetSessionTimeout();
						luinfo.userId = data.userId;
						luinfo.isSSOUser = isSSOUser;
						luinfo.opu = data.opu;
						luinfo.company = data.company;
						luinfo.empid = data.empId;
						luinfo.empName = data.empName;
						localStorage.setItem(lukey, JSON.stringify(luinfo));
						getLanguage(data);

					},
					function (data) {
						// TODO: Figure out how we prevent multiple API hits when the user just isn't initialized!
						clearUserData();
					});
			}

			return $q.when(userData);
		}

		function logoff(language, noMsg) {
			// TODO: Figure out correct order for clearing stuff..
			// TODO: Decide what should be removed when user logs off. How to remove all personal stuff if needed?
			// TODO: Call API /breeze/Logoff which clears the server side token authentication etc.

			/* Clear local storage only if working online.*/
			if (common.$rootScope.online && !common.$rootScope.isWorkingLocally) {
				var lukey = getLocStoKey('LastUserInfo', true);
				localStorage.removeItem(lukey);
				if (userData && userData.info && userData.info.userId) {
					localStorage.removeItem(getLocStoKey('UserSettings'));
				}
				if (language) {
					localStorage.removeItem(getLocStoKey('Lookups_' + language));
				}
				localStorage.removeItem('NG_TRANSLATE_LANG_KEY');
				// Clear app vm data
				clearUserData();
				if (!noMsg) {
					log(common.$translate.instant("STRCONST.PUBLIC.MSG_USERSETTINGS_CLEARED"));
				}
			}
			return $q.when();
		}

		/* Returns local storage key which comprises of prefix database_company_opu_userid, or database_company__userid if useOpu = false, and key which is given as parameter. 
         * applicationId is taken from web.config in index.cshtml.
         * Parameter noUserId is set if user id is not wanted even if it exists.
		 * Parameter selEmployee: Separates current user and selected user/employee by adding extra level into lsKey. 
         */
		function getLocStoKey(key, noUserId, opulist, companylist, userlist, selEmployee) {
			if (userData && userData.info && userData.info.userId && !noUserId) {
				if (opulist) {
					return applicationId + '_' + userData.info.company + '_' + userData.info.userId + '_' + key;
				}
				else if (companylist) {
					return applicationId + '_' + userData.info.userId + '_' + key;
				}
				else if (userlist) {
					return applicationId + '_' + userData.info.company + '_' + key;
				}
				else if (userData.info.useOPU) {
					return applicationId + '_' + userData.info.company + '_' + userData.info.opu + '_' + userData.info.userId + '_' + key;
				}
				else if (selEmployee) {
					return applicationId + '_' + userData.info.company + '_' + userData.info.userId + '_' + selEmployee + '_' + key;
				}
				else {
					return applicationId + '_' + userData.info.company + '__' + userData.info.userId + '_' + key;
				}
			} else {
				return applicationId + '_' + key;
			}
		}

		function getSetCompanyStatus(bool) {
			if (bool != undefined) {
				companyStatus = bool;
			}
			return companyStatus;
		}

		function redirectToLogin(logout) {
			var userid = "SSO-user";
			var password = "";
			var ssoEnabled = common.getAppSetting("ssoEnabled", "0");
			var anonUserEnabled = common.getAppSetting("anonUserEnabled", "0");
			
			if (!logout && anonUserEnabled == '1')
			{
				userid = "ANON-user";
				login(userid, password || "").then(loginSucceeded, continueToLogin);
			}
			else if (!logout && ssoEnabled == '1') {
				document.execCommand('ClearAuthenticationCache', 'false'); //IE authentication header fix
				var result = $http({ //do dummy http request to re-negotiate windows authentication
					method: 'GET',
					url: 'windowsLogin'
				}).then(function () {
					login(userid, password || "").then(loginSucceeded, continueToLogin);
				}, continueToLogin);
			}
			else {
				savePath();
				continueToLogin();
			}

			function loginSucceeded() {
				isSSOUser = true;
				savePath();
			}

			function continueToLogin() {
				isSSOUser = false;
				common.$timeout(function () {
					common.$location.path('/login');
					common.$broadcast('UNAUTHORIZED');
				}, 1);
			}
		}

		 function redirectToLoginReact(logout) {
			var userid = "SSO-user";
			var password = "";
			var ssoEnabled = common.getAppSetting("ssoEnabled", "0");
			var anonUserEnabled = common.getAppSetting("anonUserEnabled", "0");

			if (!logout && anonUserEnabled == '1') {
				userid = "ANON-user";
				return login(userid, password || "").then(loginSucceeded, continueToLogin);
			}
			else if (!logout && ssoEnabled == '1') {
				document.execCommand('ClearAuthenticationCache', 'false'); //IE authentication header fix
				return $http({ //do dummy http request to re-negotiate windows authentication
					method: 'GET',
					url: 'windowsLogin'
				}).then( function () {
					return login(userid, password || "").then(loginSucceeded, continueToLogin);
				}, continueToLogin);
			}
			else {
				savePath();
				continueToLogin();
			}

			function loginSucceeded() {
				isSSOUser = true;
				savePath();
			}

			function continueToLogin() {
				isSSOUser = false;
				common.$timeout(function () {
					common.$location.path('/login');
					common.$broadcast('UNAUTHORIZED');
				}, 1);
			}
		}




		//async function redirectToLoginReact(logout) {
		//	var userid = "SSO-user";
		//	var password = "";



		//	if (!logout && ssoEnabled == '1') {

		//		document.execCommand('ClearAuthenticationCache', 'false'); //IE authentication header fix


				
		//		await $http({ //do dummy http request to re-negotiate windows authentication
		//			method: 'GET',
		//			url: 'windowsLogin'
		//		})

		//		console.log(i--)


		//		await login(userid, password || "")
		

		//		await loginSucceeded()





		//	}


		//	function loginSucceeded() {
		//		alert("usersettings - loginsucceeded")
		//		isSSOUser = true;
		//		savePath();
		//	}

		//	function continueToLogin() {
		//		alert("usersettings - loginFAILED")
		//		isSSOUser = false;
		//		common.$timeout(function () {
		//			common.$location.path('/login');
		//			common.$broadcast('UNAUTHORIZED');
		//		}, 1);
		//	}
		//}










		//save the path we are trying to reach into pathInfo so that we can go there after succesful login
		function savePath() {
			if (common.$route.current && common.$route.current.portalId) {
				pathInfo.url = common.$location.url();
				pathInfo.portal = common.$route.current.portalId;
			}
		}

		function loginWithSessionId(company) {

			//In strict mode functions can't be declared inside statements.
			//This is why querySycceeded and queryFailed are unusually placed at the top.
			function querySucceeded(data) {
				spinner.spinnerHide(source, spinnerActivated);
			}
			function queryFailed(data) {
				spinner.spinnerHide(source, spinnerActivated);
			}
			var source = 'loginWithSessionId';
			var spinnerActivated = spinner.spinnerShow(source);
			var projObj;
			// Initial implementation for server based data
			projObj = EntityQuery
				.from('SessionToken')
				.using(commonManager)
				.noTracking()
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					document.execCommand('ClearAuthenticationCache', 'true'); //IE authentication header fix
					if (data.results[0] != null) {
						var tokenResponse = data.results[0];
						var userId = tokenResponse.userName;
						delete tokenResponse.userName;
						var oAuthData = tokenResponse;
						authorizeHeader(oAuthData);

						// We do not know what was the original way of logging in. It is safer to assume by default that user is not SSO user so that the user will be kicked out after session expiration.
						isSSOUser = false;
						// There might be a isSSOUser value in local storage. Before using the value we need to check if it is for the current user.
						var lui = getLastUserInfo();
						if (lui && lui.userId && lui.userId == userId && lui.isSSOUser != undefined) {
							isSSOUser = lui.isSSOUser;
						}
						// TODO: If you want to make this more robust, you can deliver isSSOUser value in url from the source system.

						return initUserInfo(userId, oAuthData, true).then(
							function (data) {
								companyStatus = true;
								common.$broadcast('SELECTCOMPANY', { company: company });
								return true;
							},
							function (data) {
								return false;
								// Something failed with user info retrieval
								errMsg(common.$translate.instant("STRCONST.PUBLIC.ERR_UNSUCCESSFUL_LOGIN") + ': ' + errorMessage);
							});
					}
					else {
						return false;;
					}
				}, function (err) {
					return err;
				});

			return $q.when(projObj);

		}

		function changeOPU(opudata) {
			var source = 'changeOPU';
			var spinnerActivated = spinner.spinnerShow(source);
			var opuName = opudata.OPU;
			return $http.post('api/Common/ChangeOPU', JSON.stringify(opuName))
				.then(({ data }) => {
					if (data != null) {
						const { userName, ...oAuthData } = data;
						authorizeHeader(oAuthData);
						return initUserInfo(userName, oAuthData, true)
							.then(() => {
								common.$broadcast('USERSETTINGS');
								common.$broadcast('COMPANYOPUCHANGES'); //TODO: This should be refactored into USERSETTINGS broadcast args.
								getPath();
								if (common.$route.current.portalId === 'TRAVELEXPENSES') { // React portal does not receive broadcasts now. // TODO Replace broadcasts with some other method which works both in AngularJs and in React.
									common.$window.location.reload();
								}
							})
					}
				})
				.then(() => spinner.spinnerHide(source, spinnerActivated))
				.catch((err) => {
					spinner.spinnerHide(source, spinnerActivated);
					errHandler("", err);
				})
		}

		function getChangeCompany(compId, portalId, tabId) {
			var lst = [];
			lst = getChangeCompanyFromQ(compId, portalId, tabId);
			return $q.when(lst);
		}

		function getChangeCompanyFromQ(compId, portalId, tabId) {
			return $http.post('api/Common/changeCompany', JSON.stringify(compId))
				.then((result) => {
					log(common.$translate.instant("STRCONST.LEANPORTAL.TXT_COMPANYCHANGE") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', result);
					const { userName, ...oAuthData } = result.data;
					authorizeHeader(oAuthData);
					return initUserInfo(userName, oAuthData, true)
				})
				.then(() => {
					common.$broadcast('USERSETTINGS');
					common.$broadcast('COMPANYOPUCHANGES'); //TODO: This should be refactored into USERSETTINGS broadcast args.
					if (!portalId) {
						savePath(); // used to return to the same place
					}
					getPath(portalId, true, tabId);
					if (common.$route.current.portalId === 'TRAVELEXPENSES') { // React portal does not receive broadcasts now. // TODO Replace broadcasts with some other method which works both in AngularJs and in React.
						common.$window.location.reload();
					}
				})
				.catch(({ data }) => {
					errHandler(common.$translate.instant("STRCONST.LEANPORTAL.TXT_COMPANYCHANGE") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data.ExceptionMessage || data.Message);
				})
		}

		function getDefCompany() {
			if (userDefCompany) {
				return $q.when(userDefCompany);
			}
			else {
				var company = [];
				company = getDefCompanyFromQ();
				return $q.when(company);
			}
		}

		function getGridFilterKey() {
			var user = getLastUserInfo();
			var userId = user ? user.userId : null;
			/*Find current portal*/
			var portalId = common.$route.current.portalId ? common.$route.current.portalId : null;
			if (portalId) {
				var portalTabs = common.getTabs(portalId);
				/*Find what tab is active for current portal*/
				var tabId = null;
				for (var i = 0; i < portalTabs.length; i++) {
					if (portalTabs[i].isActive) {
						tabId = portalTabs[i].tabId;
					}
				}
			}
			return userId + '_' + portalId + '_' + tabId;
		}

		function getLastUserInfo() {
			var lsKey = getLocStoKey('LastUserInfo', true);
			if (localStorage && localStorage.getItem(lsKey)) {
				return JSON.parse(localStorage.getItem(lsKey));
			}
		}

		function getDefCompanyFromQ() {
			var projObj;
			// Initial implementation for server based data
			projObj = EntityQuery
				.from('checkDefCompany')
				.using(commonManager)
				.noTracking()			// This is needed unless we want breeze to keep tracking of portal objects...
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						// The breeze query data is really in data.results member...
						// TODO: figure out if these services should return breeze query data or only the results...
						userDefCompany = data.results[0];
						return data.results[0];
					}
				});
			function querySucceeded(data) {
				//                log("getDefaultCompany success", data, true);
			}
			function queryFailed(data) {
				errHandler("getDefaultCompany fail", data);
			}
			return projObj;

		}

		function getPortalInfo() {
			return portalInfo;
		}

		function getPortalsFromQ(cacheKey) {
			var source = 'getPortals';
			var spinnerActivated = spinner.spinnerShow(source);
			var portalObj;
			// Initial implementation for server based data
			portalObj = EntityQuery
				.from('Portals')
				.using(commonManager)
				.noTracking()			// This is needed unless we want breeze to keep tracking of portal objects...
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						// The breeze query data is really in data.results member...
						// TODO: figure out if these services should return breeze query data or only the results...
						portalInfo = data.results;
						if (cacheKey) {
							common.setCachedData(cacheKey, data.results);
						}
						return data.results;
					}
				});
			function querySucceeded(data) {
				spinner.spinnerHide(source, spinnerActivated);
				log(common.$translate.instant('STRCONST.LEANPORTAL.TXT_PORTALQUERY') + " " + common.$translate.instant('STRCONST.LEANPORTAL.TXT_SUCCESS') + '.', data, true);

			}
			function queryFailed(data) {
				spinner.spinnerHide(source, spinnerActivated);
				errHandler(common.$translate.instant('STRCONST.LEANPORTAL.TXT_PORTALQUERY') + " " + common.$translate.instant('STRCONST.LEANPORTAL.TXT_FAILURE') + '.', data);
			}
			return portalObj;
		}

		function getPortals() {
			var lst = [];

			if (common.$rootScope.online && !common.$rootScope.isWorkingLocally) {
				// Caching mechanism to avoid mutiple identical DB requests
				var lang = common.$translate.use();
				var cacheKey = ['getPortals', lang];
				var cachePromise = common.getCachedData(cacheKey);
				if (cachePromise) {
					return $q.when(cachePromise);
				}

				if (userData && userData.info && userData.info.userId) {
					authorizeHeader();
					lst = getPortalsFromQ(cacheKey);
				}
				else {
					logoff(null, true);
					redirectToLogin("logoff");
				}
			}
			return $q.when(lst);
		}

		function getTabForPortalFromQ(portalId, tabId) {
			var source = 'tabForPortal';
			var spinnerActivated = spinner.spinnerShow(source);
			var tabObj;
			tabObj = EntityQuery
				.from('GetTabForPortal')
				.withParameters({ portalId: portalId, tabId: tabId })
				.using(commonManager)
				.noTracking()
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						return data.results;
					}
				});

			function querySucceeded(data) {
				log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_PORTAL_TAB_QUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
				spinner.queryFinally(source, spinnerActivated)
			}
			function queryFailed(data) {
				spinner.queryFinally(source, spinnerActivated)
				usersettings.errHandler(data.message, data.status);
			}
			return tabObj;
		}

		function changePassword(oldPassword, newPassword) {
			var lst = [];
			lst = changePasswordFromQ(oldPassword, newPassword);
			return $q.when(lst);
		}
		function changePasswordFromQ(oldPassword, newPassword) {
			return $http.post('api/Common/changePassword', JSON.stringify({ oldPassword, newPassword })).then(({ data }) => data);
		}

		/**
		 * Changes portal to portal whose id is given as parameters.
		 * @param {Id of portal to change to.} portalId
		 * @oaram {Id of portalTab to target.} portalTab
		 * @Param {Id of (parent)object.} objectRecId
		 * @Param {Add moduleRecId (sourceId) to portalUrl, boolean} includeModuleRecIdToUrl
		 */
		function changePortal(portalId, portalTab, objectRecId, includeModuleRecIdToUrl) {

			// Internal helper function to check whether given portal is in this app or not.
			function isPortalInThisApp(portalId) {
				var val = false;
				common.routes.forEach(function (r) {
					if (r.config && r.config.portalId === portalId) {
						val = true;
						return;
					}
				});
				return val;
			}

			getSessionId().then(function (data) {
				var portalUrl = null;
				for (var i = 0; i <= portalInfo.length - 1; i++) {
					if (portalInfo[i].portalId == portalId) {
						portalUrl = portalInfo[i].portalUrl;
						break;
					}
				}

				// Store new portal to local storage if it is from current application.
				if (portalId && isPortalInThisApp(portalId)) {
					var lst = {};
					var lsKey = getLocStoKey('PersonalSettings');
					if (localStorage && localStorage.getItem(lsKey)) {
						lst = JSON.parse(localStorage.getItem(lsKey));
						if (lst) {
							lst.lastPortalId = portalId;
							localStorage.setItem(lsKey, JSON.stringify(lst));
						}
					}
					else if (lsKey !== getLocStoKey('PersonalSettings', true)) {
						lst.lastPortalId = portalId;
						localStorage.setItem(lsKey, JSON.stringify(lst));
					}
				}

				var current = common.$window.location.pathname;
				if (current === portalUrl || current === portalUrl + "/" || portalUrl === null) {
					return;
				}
				else if (!isPortalInThisApp(portalId)) {
					if (portalTab) {
						getTabForPortalFromQ(portalId, portalTab).then(function (data) {
							/*Add tabRecId to portalUrl*/
							portalUrl = data.length > 0 ? portalUrl.concat(";" + data[0].tabRecId) : portalUrl;
							/*Concat portalUrl with moduleRecId (sourecId) if need*/
							if (includeModuleRecIdToUrl) {
								var modules = data[0].modules;
								if (modules) {
									for (var i = 0; i < modules.length; i++) {
										if (modules[i].id == portalTab) {
											var mod = modules[i];
											break;
										}
									}
								}
								if (mod) {
									portalUrl = portalUrl.concat("&id=" + objectRecId + "&srcid=" + mod.recId + ";" + objectRecId);
								}
							}
							/*Use full refresh for portals outside the app*/
							common.$window.location.href = portalUrl;
						});
					}
					else {
						/*Use full refresh for portals outside the app*/
						common.$window.location.href = portalUrl;
					}
				}
				// Use route instead of full refresh for portals in this app.
				if (isPortalInThisApp(portalId) && applicationPath) {
					portalUrl = portalUrl.replace(applicationPath, "");
					/*ToDo: SPA-portal tabs routing, pass targetTabs required params and do URL-parsing*/
					common.$location.url(portalUrl);
				}
			});
		}

		function getPath(setPortalId, replace, tabId) {
			if (userData && userData.info && userData.info.userId) {
				getPortals().then(function (data) {
					//Get current user's all available portals
					var portalsAvailableInApp = getAvailablePortals(data);
					var lsKey = getLocStoKey('PersonalSettings');
					var lastPortalId = common.getLastPortalId(lsKey);
					var currentPortalId = common.$route.current.portalId;
					// 1) portal given as parameter (e.g. tab change from UI / company change)
					// 2) portal info restored before logoff, company change or session expiration <=> return to same url
					// 3) current portal id <=> probably same as case 2)
					// 4) portal id saved in local storage <=> refresh
					// 5) default portal from web.config
					
					
					var defaultPortal = common.getAppSetting("DefaultPortal", "");
					
					var portalId = (setPortalId || pathInfo.portal || currentPortalId || lastPortalId || defaultPortal);

					if (!portalId) {
						selectFirstPortal(portalsAvailableInApp);
						return;
					}

					//If portalId is defined check if it is valid (available in web.config and user has access rights). Should return exactly one portal.
					var portals = portalsAvailableInApp.filter(function (portal) { return portalId.toLowerCase() == portal.portalId.toLowerCase(); })
					if (portals && portals.length) {
						var portal = portals[0];
						if (pathInfo && pathInfo.url) {
							// portal info restored before session expiration, company change or logoff 
							common.$location.url(pathInfo.url);
							pathInfo = {}; // reset the value
						}
						else {
							var path = getPortalRoute(portal.portalUrl);
							if (tabId && portal.tabs) {
								var tab = portal.tabs.filter(function (tab) { return tab.tabId == tabId.toUpperCase(); });
								if (tab && tab.length) {
									path = tab[0].url;
								}
							}
							common.$location.path(path);
						}
						// Back button doesn't return to login address, but the addres from which we arrived to login  
						replace ? common.$location.replace() : "";
						common.setLastPortalId(lsKey, lsKey !== getLocStoKey('PersonalSettings', true), portal.portalId);
						return;
					}

					//If the defined portalId wasn't found (user access rights or web.config might have changed from last time), use the first available portal.
					selectFirstPortal(portalsAvailableInApp);

					function getAvailablePortals(portalsAvailableAll) {
						var availablePortals = [];
						if (portalsAvailableAll) {
							angular.forEach(portalsAvailableAll, function (availablePortal) {
								var available = false;
								common.routes.forEach(function (r) {
									if (r.config && r.config.portalId == availablePortal.portalId) {
										available = true;
									}
								});
								if (available) {
									availablePortals.push(availablePortal);
								}
							});
						}
						return availablePortals;
					}

					function selectFirstPortal(portalsAvailableInApp) {
						if (!portalsAvailableInApp || portalsAvailableInApp.length == 0) {
							//No portals are available
							errHandler("No portals available! Check Web.config and user access rights");
							logoff(null, true);
							return;
						} else {
							var portal = portalsAvailableInApp[0];
							if (portal && portal.portalUrl) {
								common.$location.path(getPortalRoute(portal.portalUrl));
								replace ? common.$location.replace() : "";
								common.setLastPortalId(lsKey, lsKey !== getLocStoKey('PersonalSettings', true), portal.portalId);
								return;
							}
							else {
								errHandler("First portal url is not correct. Check Web.config.");
								logoff(null, true);
								return;
							}
						}
					}

				});

			} else {
				logoff(null, true);
				redirectToLogin("logoff");
			}
		}

		/**
		 * Returns route part from given portal url (same as portal url without application name).
		 * {portalUrl} Portal url.
		 */
		function getPortalRoute(portalUrl) {
			if (portalUrl && portalUrl.indexOf('/', 1) > 0) {
				return portalUrl.replace('/' + portalUrl.split('/')[1], '');
			}
			return portalUrl;
		}

		function checkAccessRight(route) {
			var allowPortal = false;
			var allowTab = false;
			return getLoginPromise().then(function () {
				return getPortals().then(function () {
					if (route.portalId) {
						for (var i = 0; i < portalInfo.length; i++) {
							if (portalInfo[i] && route.portalId == portalInfo[i].portalId) {
								var portal = portalInfo[i];
								if (!portal.tabs || portal.tabs.length < 1) {
									break; //portal has no tabs
								}
								else if ('/' + portal.portalId.toLowerCase() == route.originalPath) {
									allowPortal = true; //main level portal matches route
									allowTab = true;
									break;
								}
								else {
									allowPortal = true; //portal is allowed
									for (var j = 0; j < portal.tabs.length; j++) {
										if (route.originalPath == portal.tabs[j].url) {
											allowTab = true; //portal tab matches route
											break;
										}
										if (!route.reloadOnUrl && route.regexp.test(portal.tabs[j].url)) {
											allowTab = true;
											break;
										}
										// Check sub tabs.
										for (var k = 0; k < portal.tabs[j].subTabs.length; k++) {
											if (route.originalPath == portal.tabs[j].subTabs[k].url) {
												allowTab = true; //portal tab matches route
												break;
											}
										}
									}
									break;
								}
							}
						}
					}
					if (!allowPortal) {
						delete (portalInfo[i]); //remove not allowed portal from memory for getPath() 
					}
					return allowPortal && allowTab;
				});
			});
		}
		/**
		 * @description Verifies if the user has certain permission for the given form
		 * @param {number} formId Form id
		 * @param {string} permType permission type (see LeanPermission for possible values).
         * @param {string} [cacheKey] Key for storing the result to cache.
         * @return {Promise} Promise object representing permission.
		 */
		function checkPermissionToFormFromQ(formId, permType, cacheKey) {
			var queryObj = EntityQuery
				.from('checkPermissionToForm')
				.using(commonManager)
				.withParameters({ formId: formId, permType: permType })
				.noTracking()			// This is needed unless we want breeze to keep tracking of portal objects...
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data && cacheKey) {
						common.setCachedData(cacheKey, data.results[0]);
					}
					if (data) {
						return data.results[0];
					}
				}, function () {
					common.rejectCachedData(cacheKey);
					throw null;
				});
			function querySucceeded(data) {
				// log("getDefaultCompany success", data, true);
			}
			function queryFailed(data) {
				errHandler("checkPermissionToForm fail", data);
			}
			return queryObj;
        }

        function checkPermissionToForm(formId, permType) {
            var lst = null;
            var cacheKey = getLocStoKey('checkPermissionToForm_' + formId + '_' + permType);
            var cachePromise = common.getCachedData(cacheKey);
            if (cachePromise) {
				return $q.when(cachePromise);
            }
            lst = checkPermissionToFormFromQ(formId, permType, cacheKey);
            return $q.when(lst);
        }

		/**
		 * @description Verifies if the Top Banner is visible for defined portal. This functionality must be defined per portal to certain form&tool.
         * @param {string} portalId Portal id
         * @returns {Promise} Promise representing the visibility.
		 */
        function checkTopBannerVisibility(portalId) {

            function getDefVisibility() {
                return common.getAppSetting("TopBannerVisibleDefault", 1) == 1 ? true : false;
            }

			var lst = null;
			var formId = null;
			var permType = null;
			if (portalId === 'MANUFACTURING') {
				formId = 25102;
				permType = 'R_TOOL1';
			}
			if (formId && permType) {
				lst = getLoginPromise().then(function () {
					var cacheKey = getLocStoKey('TopBannerVisibility_' + portalId);
					var cachePromise = common.getCachedData(cacheKey);
					if (cachePromise) {
						return cachePromise;
                    }
                    return checkPermissionToFormFromQ(formId, permType, cacheKey)
                        .catch(getDefVisibility);
                }, getDefVisibility);
			} else if (portalId === 'STATUSDISPLAY') {
				lst = false;
			}
			else lst = true;
			return $q.when(lst);
		}

		function errHandler(msg, error) {
			if (error && (error === 401 || error.status === 401)) {
				//remove the old expired token
				$http.defaults.headers.common['Authorization'] = undefined;
				//remove possible uncaught spinners
				spinner.spinnerReset();
				if (!loggingOut) {
					if (!isSSOUser) {
						common.$translate.use("STRCONST.ERROR.10064", "", "", "en").then(function (data) {
							warnMsg(common.$translate.instant("STRCONST.ERROR.10064", "", "", "en"));
						})
					}
					loggingOut = true;
					logoff(null, true);
					redirectToLogin();
				}
			} else if (error && (error === 503 || error.status === 503)) {
				// Use cache to reduce unnecessary error messages
				var cacheKey = getLocStoKey('MaintenanceError');
				var cachePromise = common.getCachedData(cacheKey, 30);
				if (!cachePromise) {
					common.$translate("STRCONST.PUBLIC.TXT_MAINTENANCE_ACTIVE").then(function (translation) {
						common.setCachedData(cacheKey, translation);
						warnMsg(translation);
					});
				}
				spinner.spinnerReset();
			}
			else {
				errMsg(msg, error);
				//remove possible uncaught spinners
				common.$timeout(function () {
					spinner.spinnerReset();
				}, 60000, false);
			}
		}

		function handleSaveError(msg, error) {
			var saveErrMsg = "";
			angular.forEach(error.entityErrors, function (entityError) {
				saveErrMsg = saveErrMsg.concat("<p>" + entityError.errorMessage + "</p>");
			});
			if (!saveErrMsg) {
				saveErrMsg = breeze.saveErrorMessageService.getErrorMessage(error);
			}
			error.message = msg + ": " + saveErrMsg;
			if (!error.entityErrors && error.message.indexOf("An entity with this key is already in the cache: Favourite") != -1) { //This error is handled in hourinput.js, no toaster needed TODO: Refactor back end repository to account for this.
				console.log(error.message);
				throw error;
			}
			errHandler(error.message, error);
		}

		/**
		 * Refreshes token on the server. Current user and company is used in the server request.
		 */
		function refreshToken() {

			var compId;
			if (userData && userData.info && userData.info.company) {
				compId = userData.info.company;
			}

			var projObj;
			// Initial implementation for server based data
			projObj = EntityQuery
				.from('RefreshToken')
				.using(commonManager)
				.noTracking()			// This is needed unless we want breeze to keep tracking of portal objects...
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data.results[0] != null) {
						var tokenResponse = data.results[0];
						var userId = tokenResponse.userName;
						delete tokenResponse.userName;
						var oAuthData = tokenResponse;
						authorizeHeader(oAuthData);
						initUserInfo(userId, oAuthData, true).then(
							function (data) {
								// Here we do not want to refresh page so that user will not loose data on the screen. The assumption is that all other user data is the same as before.
							},
							function (data) {
							});

					}
				});
			function querySucceeded(data) {
				log(common.$translate.instant("STRCONST.PUBLIC.MSG_SESSION_REFRESHING") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.')
			}
			function queryFailed(data) {
				errHandler(common.$translate.instant("STRCONST.PUBLIC.MSG_SESSION_REFRESHING") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
			}
			return projObj;
		}

		/**
		 * Resets session time out, displays a warning message before timeout and kicks user out at timeout. Called after each query.
		 */
		function resetSessionTimeout() {

			// Reset last refresh time.
			sessionLastRefreshTime = moment.utc();
			sessionEndedMsgGiven = false;
			sessionWarningMsgGiven = false;

			var warningPeriodMinutes = 10; // Minutes before timeout a warning message is displayed.
			var pollMinutes = 1; // Client side polling cycle in minutes.
			var marginMinutes = 1.1; // Reaction time in minutes before expiration time.
			var modalInstance; // Warning message instance.

			// Internal helper function to refresh session when user clicked ok in warning message.
			var refSes = function refreshSession() {
				// Reset warning message flag so that user will get a new warning before new timeout.
				sessionWarningMsgGiven = false;
				// Refresh token on the server.
				refreshToken().then(
					function (data) {
						logSuccess(common.$translate.instant("STRCONST.PUBLIC.MSG_SESSION_REFRESHED"));
					},
					function (data) {
					});
			};

			// Internal recursive function to display warning message before timeout and kick user out just after timeout.
			function displaySessionTimeOut() {
					var authTokenExpireHours = common.getAppSetting("AuthTokenExpireHours", "8");
						// Due to polling cycle we want to act a little before the actual expiration time (which is not accureate anyway). Thus we subtract couple minutes from the token expiration time.
				var expirationTime = moment.utc(sessionLastRefreshTime).add(authTokenExpireHours, 'hours').subtract(marginMinutes, 'minutes'); // Token validity time is taken from web.config. 
				//console.log("Session expires " + expirationTime.toLocaleString());

				// If session ended message is already given, do not display it again. This is to make sure no multiple cycles run will not display extra messages.
				if (sessionEndedMsgGiven) {
					// Do nothing.
				}
				else if (moment.utc().isAfter(expirationTime)) { // Session expired.
					if (modalInstance) {
						modalInstance.close();
					}
					if (isSSOUser) { // Refresh token for SSO users without any special notification.
						common.$timeout(function () {
							displaySessionTimeOut();
						}, pollMinutes * 60000, false);
						refSes();
					}
					else { // Non SSO users are kicked out with warning message.
						sessionEndedMsgGiven = true;
						common.$translate.use("STRCONST.ERROR.10064", "", "", "en").then(function (data) {
							warnMsg(common.$translate.instant("STRCONST.ERROR.10064", "", "", "en"));
						})
						$uibModalStack.dismissAll();
						$('.modal') && $('.modal').modal ? $('.modal').modal('hide') : null;
						logoff(null, true);
						redirectToLogin(true);
					}
				}
				// 
				else if (!isSSOUser && !sessionWarningMsgGiven && moment.utc().isAfter((expirationTime).subtract(warningPeriodMinutes + marginMinutes, 'minutes'))) { // Session will expire soon. 
					sessionWarningMsgGiven = true;
					common.$timeout(function () {
						displaySessionTimeOut();
					}, pollMinutes * 60000, false);
					// Display a question for the user if he/she wants to continue session. 
					// This is done in order to get info if user is still active or just left the browser open. We do not want to refresh session eternally if user is not active.

					common.$translate.use("STRCONST.PUBLIC.MSG_SESSION_ENDS_TITLE").then(function (data) {
						var title = common.$translate.instant("STRCONST.PUBLIC.MSG_SESSION_ENDS_TITLE", "", "", "en");
						var message = common.$translate.instant("STRCONST.PUBLIC.MSG_SESSION_ENDS", "", "", "en").replace('%1', warningPeriodMinutes);
						modalInstance = common.openModalMessage(null, title, message, refSes, function () { }, common.$translate.instant("STRCONST.PUBLIC.BTN_OK", "", "", "en"), common.$translate.instant("STRCONST.PUBLIC.BTN_CANCEL", "", "", "en"));
					})
				}

				else { // Time before warning: call the function again after certain amount of time.
					common.$timeout(function () {
						displaySessionTimeOut();
					}, pollMinutes * 60000, false);
				}
			}

			// Start the polling. This should be done once only.
			if (!sessionPollingActivated) {
				sessionPollingActivated = true;
				displaySessionTimeOut();
			}
		}

		/**
		 * Set element size default according to web.config value 
		 * if user has not yet made the selection by him/herself (= value not found in user settings at all). 
		 */
		function setElementsSizeDefault() {
			if (localStorage) {
				var lsid = getLocStoKey('PersonalSettings');
				var paramName = 'elementSize';
				var updateNeeded = false;
				if (!localStorage.getItem(lsid)) {
					updateNeeded = true;
				}
				else {
					var personalSettings = JSON.parse(localStorage.getItem(lsid));
					if (!personalSettings.hasOwnProperty(paramName)) {
						updateNeeded = true;
					}
				}

				var defaultValue = common.getAppSetting("ElementSizeDefault", "");
				if (updateNeeded && defaultValue !== "") {
					setPersonalSettingValue(paramName, defaultValue);
				}
			}
		}

        /**
         * Sets a css class to root element (html) in order to alter elements sizes globally.
         * @param {string} [size] - New size to set. Can be small (dekstop) or large. Defaults to small.
         */
		function setElementsSize(size) {
			size = size || 'desktop';
			var rootElement = angular.element('html');
			if (size == 'desktop') {
				rootElement.addClass(size);
				common.triggerResizeEvent(0);
			}
			else {
				rootElement.removeClass('desktop');
				common.triggerResizeEvent(0);
			}
		}

		function setLastUserInfo(empId, empName) {
			var lst = {};
			var lsKey = getLocStoKey('LastUserInfo', true);
			if (empId && localStorage && localStorage.getItem(lsKey)) {
				lst = JSON.parse(localStorage.getItem(lsKey));
				if (lst) {
					lst.empId = empId;
					lst.empName = empName;
					localStorage.setItem(lsKey, JSON.stringify(lst));
				}
			}
		}

		/**
		 * Sets named property value to personal setting local storage variable. 
		 * @param {string} propertyName - Property name inside local storage person settings variable.
		 * @param {string} val - Value to be stored.
		 */
		function setPersonalSettingValue(propertyName, val) {
			var lsid = getLocStoKey('PersonalSettings');
			var personalSettings = new Object;
			if (localStorage && localStorage.getItem(lsid)) {
				personalSettings = JSON.parse(localStorage.getItem(lsid));
			}
			personalSettings[propertyName] = val;
			localStorage.setItem(lsid, JSON.stringify(personalSettings));
		}

        /**
		 * Sets named property value to personal setting local storage variable. Note: handles info variables only.
		 * @param {string} propertyName - Property name inside local storage person settings.info variable.
		 * @param {string} val - Value to be stored.
		 */
		function setUserSettingValue(propertyName, val) {
			var lsid = getLocStoKey('UserSettings');
			var uSettings = new Object;
			if (localStorage && localStorage.getItem(lsid)) {
				uSettings = JSON.parse(localStorage.getItem(lsid));
			}
			uSettings.info[propertyName] = val;
			localStorage.setItem(lsid, JSON.stringify(uSettings));
		}

	}
export default usersettingsModule;
