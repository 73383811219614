'use strict';
var timePopupModule = angular.module('dir.dfxTimePopup',[]);

/**
 * @class LeanPortalSPA.directives.dfxTimePopup
 * @memberOf LeanPortalSPA.directives
 * @description Popup for timepicker/calendar + timepicker used in dfxGrid.
 * 
 * @param {Date} dateValue - Date object used as a model.
 * @param {string} dateType - Type in which the date should shown. DATE, TIME or DATETIME.
 * @param {boolean} notEditable - Is given date value editable.
 * @param {function} onChangedCallback - Callback function for model change event.
 * @param {Object} onChangedCallback.value - Changed date object.
 * @example <dfx-time-popup date-value="ctrl.currentDay" date-type="DATE" on-changed-callback="ctrl.onChangedCallback(value)"></dfx-time-popup>
 * 
 */
timePopupModule.directive('dfxTimePopup', function () {
    return {
        restrict: 'EA', //E = element, A = attribute, C = class, M = comment 
        scope: {},
        bindToController: {
            dateValue: '=',
            dateType: '=',
            notEditable: '=?',
            onChangedCallback: '&'
        },
        controllerAs: 'ctrl',
        template: require('./dfxTimePopup.html'),
        controller: ['$scope', 'common', function ($scope, common) {
            var ctrl = this;
            ctrl.isOpen = false;

            ctrl.$onInit = function () {
                ctrl.tempDate = ctrl.dateValue;
                ctrl.tempTime = ctrl.dateValue;
                formatTime();
            };

            $scope.$watch('ctrl.dateValue', function () {
                formatTime();
            });

            ctrl.changed = function (value) {
                ctrl.tempDate = value;
            };

            ctrl.onSave = function () {
                var tempVal = moment(moment(ctrl.tempDate).format(common.longDateFormat()) + " " + moment(ctrl.tempTime).format(common.longTimeFormat()), common.longDateFormat() + ' ' + common.longTimeFormat()).utc().toDate();
                ctrl.onChangedCallback({ value: tempVal });
                ctrl.isOpen = false;
            };

            ctrl.onCancel = function () {
                ctrl.tempVal = ctrl.dateValue;
                ctrl.isOpen = false;
            };

            function formatTime() {
                if (ctrl.dateType === 'TIME') {
                    ctrl.formattedTime = moment(ctrl.dateValue).local().format(common.longTimeFormat());
                } else if (ctrl.dateType === 'DATETIME') {
                    ctrl.formattedTime = moment(ctrl.dateValue).local().format(common.longDateFormat() + ' ' + common.longTimeFormat());
                }
            }
        }]
    };
});
export default timePopupModule