'use strict';
var workSpaceModule = angular.module('dir.dfxMaximizeWorkSpaceButton',[]);
/**
 * Button to maximize works space by hidin some elements in the 
 */
workSpaceModule.directive('dfxMaximizeWorkSpaceButton', function () {
    // Provides  buttongroup to change layout
    return {
        restrict: 'E', //E = element, A = attribute, C = class, M = comment 
        scope: {},
        controllerAs: 'ctrl',
        bindToController: {
            source: '='
        },
		controller: ['$scope', 'common', function ($scope, common) {
            var ctrl = this;
			var maximized = false;

            ctrl.onToggleMaximise = function () {
				maximized = (maximized ? false : true); // negate
				common.$broadcast('MAXIMIZE_WORK_SPACE',{
						maximized: maximized, 
						data:
						{
							source: ctrl.source
						}
					});
            }
        }],
        template: require('./dfxMaxWorkSpace.html')
    };
});
export default workSpaceModule