
    'use strict';
    var controllerId = 'commentctrl';
	var app= angular.module('common');
   app.directive('dfxComment', [function () {
    	return {
    		restrict: 'E',
    		scope: {},
    		controller: controllerId,
    		controllerAs: 'ctrl',
    		bindToController: {
    		    // object with objectType, recId and name properties
                currentUser: '=',
    			parent: '=',
    			cardLayout: '=?',
    			onContentChangedCallback: '&',
    			onClickedCallback: '&',
    			onSaveCallback: '&',
    			onCancelCallback: '&',
				onDeleteCallback: '&',
				commentConfirmationEnabled: '=?',
				restrictionCmd: '=?'
    		},
    		template: function (elem, attrs) {
				return attrs.template == 'supplierChatTab' ? require('./chat.html') : require('./comment.html')
    		}
    	}
    }]);

	app.controller(controllerId, ['$scope', 'commonDatacontext','common', comment]);

	function comment($scope, commonDatacontext, common) {
		var ctrl = this;
		ctrl.count = 0;
		ctrl.adjustTime = common.adjustTime;
		ctrl.contentAvailable = false;
		ctrl.rowLayoutEnum = {
			TIGHT: 0,
			EDITABLE: 1
			/*, 
	    	INFO: 2, 
	    	INFOEDITABLE: 3 */
		};
		ctrl.rowLayout = ctrl.rowLayoutEnum.TIGHT;
        // objects: recId, name
        ctrl.objects = [];
        ctrl.comments = [];
        ctrl.reversedComments = [];
	    ctrl.isMainPanelOpen = true;
	    ctrl.isOpen = true;
		ctrl.isNewestOpen = true;
		// Determines whether the confirm read button is visible or not
		ctrl.confirmReadButtonVisible = false;
		ctrl.commentsConfirmed = false;

        ctrl.$onInit = function () {
			ctrl.cardLayout = typeof (ctrl.cardLayout) == 'undefined' ? true : ctrl.cardLayout;
			ctrl.commentConfirmationEnabled = typeof (ctrl.commentConfirmationEnabled) == 'undefined' ? false : ctrl.commentConfirmationEnabled;
			ctrl.restrictionCmd = typeof (ctrl.restrictionCmd) == 'undefined' ? "" : ctrl.restrictionCmd
            activate();
        };

        /**
         * ctrl.objects contains Array of objects with objectType, recId and name
         */
	    $scope.$watch('ctrl.parent', function (parent) {
            getComments();
        });

        /**
         * Return true if any data context operated from this controller has changes
         */
        ctrl.hasChanges = function () {
            return commonDatacontext.hasChanges();
        }

        $scope.$on('REJECTCHANGES', function (event, args) {
            if (args.param == true) {
                ctrl.reversedComments.forEach(function (comment, index) {
                    if (comment.rowLayout == 1) {
                        ctrl.cancelChanges(ctrl.reversedComments[index]);
                    }
                });
            }
        });

		/**
		* Saves changes made to the given element.
		* @param {string} comment - Comment element whose changes will be saved.
		* @memberOf LeanPortalSPA.dfxComment.
		*/
	    ctrl.saveChanges = function (comment) {
	    	commonDatacontext.saveChanges(comment).then(
				function (saveResult) {
					ctrl.toggleRowLayout(comment);
					ctrl.onContentChangedCallback({ count: ctrl.count });
				})
	    }

		/**
		* Cancels changes made to the given element.
		* @param {string} comment - Comment element whose changes will be cancelled.
		* @memberOf LeanPortalSPA.dfxComment.
		*/
	    ctrl.cancelChanges = function (comment) {
	    	commonDatacontext.rejectChanges(comment);
	    	ctrl.toggleRowLayout(comment);
	    }

		/**
		* Removes the given element.
		* @param {string} comment - Comment element whose changes will be cancelled.
		* @memberOf LeanPortalSPA.dfxComment.
		*/
	    ctrl.removeComment = function (comment) {
	    	var removed = false;
	    	if (comment && comment.recId) {
	    		commonDatacontext.removeComment(comment.recId).then(function () {
	    			common.logger.logSuccess(common.$translate.instant("STRCONST.LEANPORTAL.REMOVE_COMMENT"), false, false, true);
	    			for (var j = 0; j < ctrl.reversedComments.length; j++) {
	    				if (ctrl.reversedComments[j].recId == comment.recId) {
	    					ctrl.reversedComments.splice(j, 1);
	    					removed = true;
	    				}
	    			}
	    		});
	    	}
	    	if (removed)
	    		ctrl.toggleRowLayout(comment);
	    		ctrl.onContentChangedCallback({ count: ctrl.reversedComments.length });
	    }

				/**
		 * Toggles row layout to next layout.
		 * @param {string} text - Text element whose layout will be toggled.
		 * @memberOf LeanPortalSPA.dfxText
		 */
        ctrl.toggleRowLayout = function (comment) {
			// If not set before, set it to tight.
			if (!comment.rowLayout) {
				comment.rowLayout = ctrl.rowLayoutEnum.TIGHT;
			}

	        if (comment.rowLayout == ctrl.rowLayoutEnum.TIGHT) {
	        	comment.rowLayout = ctrl.rowLayoutEnum.EDITABLE;
	        }
	        else if (comment.rowLayout == ctrl.rowLayoutEnum.EDITABLE) {
	        	comment.rowLayout = ctrl.rowLayoutEnum.TIGHT;
	        }
        }

		/**
         * Add comment of data base. 
         */
	    ctrl.addComment = function (value) {
	    	if (value && value.length > 0) {
	    		var add = createEntity(value);
	    		if (add){
	    			commonDatacontext.saveChanges(add).then(function () {
	    			    ctrl.comments.unshift(add);
						ctrl.reversedComments.push(add);
						ctrl.confirmCommentsAsRead(add.recId);
						ctrl.newComment = "";
						ctrl.count++;
					},
					function (error) {
						// Remove entity from manager cache. We need to use entity that comes back from the server because it has the key.
						if (error && error.entityErrors && error.entityErrors[0] && error.entityErrors[0].entity) {
							commonDatacontext.rejectChanges(error.entityErrors[0].entity);
						}
					});
	    			return ctrl.onContentChangedCallback({ count: ctrl.count });
				}
	    	}
	    }

	    function createEntity(value) {
	    	return commonDatacontext.commonManager.createEntity('Comment', {
	    		commentText: value,
				objectType: ctrl.parent.objRecId().objectType,
	    		objectRecId: ctrl.parent.objRecId().recId
	    	});
	    }

        /**
         * Gets comments for objects. 
         */
        function getComments() {

	        if (!ctrl.parent || typeof ctrl.parent.objRecId != 'function') {
	            ctrl.contentAvailable = false;
	            ctrl.comments = [];
	            ctrl.count = 0;
	            ctrl.onContentChangedCallback({ count: ctrl.count });
	            return;
            }
            var dataParents = common.getCommonDataParents('Comment', ctrl.parent);
            commonDatacontext.getComments(dataParents, 0, 75, "", ctrl.restrictionCmd || '').then(function (data) {
                ctrl.comments = data.sort(function (cmp1, cmp2) {
                    // Comments for parent object first
                    var parent = ctrl.parent.objRecId();
                    if (parent.objectType === cmp1.objectType && parent.recId === cmp1.objectRecId) {
                        return -1;
                    }
                    else if (parent.objectType === cmp2.objectType && parent.recId === cmp2.objectRecId) {
                        return 1;
                    }
	                return cmp2.createStamp - cmp1.createStamp;
	            });
	            ctrl.contentAvailable = (data.length > 0);
	            ctrl.count = data.length;
	            ctrl.onContentChangedCallback({ count: ctrl.count });
	            }).then(function () {
	                reverseComments();
	            });
		}
		function resetConfirmValues() {
			ctrl.reversedComments.forEach(function (comment) {
				comment.firstUnreadComment = false;
				comment.lastUnreadComment = false;
			});
		}

	    function reverseComments() {
	        ctrl.reversedComments = ctrl.comments.slice();
			ctrl.reversedComments.reverse();
			// Here we check for the first unread comment to be able to draw the red 'unread comments'-separator on the right place. 
			// We also make the confirm read button visible if there are unread comments.
			resetConfirmValues();
			for (var i = 0; ctrl.reversedComments.length; i++) {
				if (ctrl.reversedComments[i].commentIsRead == 0) {
					ctrl.reversedComments[i].firstUnreadComment = true;
					ctrl.firstUnreadCommentIndex = i;
					ctrl.confirmReadButtonVisible = true;
					ctrl.reversedComments[ctrl.reversedComments.length - 1].lastUnreadComment = true;
					break;
				}
			}
		}

		//We confirm all the comments belonging to the purchaseorder as read.
		ctrl.confirmCommentsAsRead = function (recId) {
			var lastCommentRecId = recId == null ? ctrl.reversedComments[ctrl.reversedComments.length - 1].recId : recId;
			commonDatacontext.confirmCommentsAsRead(ctrl.parent.recId, lastCommentRecId).then(function () {
				ctrl.confirmReadButtonVisible = false;
				resetConfirmValues();
				ctrl.commentsConfirmed = true;
			});
		}

	    ctrl.latestComment = function (comment) {
	    	var lastComment = ctrl.reversedComments.length - 1;
	    	if (ctrl.reversedComments[lastComment].recId == comment.recId && comment.createdBy == ctrl.currentUser.userId)
	    		return true;
        }

        ctrl.getOwnerName = function (comment) {
            var ret = null;
            angular.forEach(common.getCommonDataParents('Comment', ctrl.parent), function (dataParent) {
                if (dataParent.objectType === comment.objectType && dataParent.recId == comment.objectRecId) {
                    ret = dataParent.name;
                }
            });
            return ret;
        }

        ctrl.showCommentOwner = function (comment) {
            // parent is not owner and name exist
            var parent = ctrl.parent.objRecId();
            if (parent.objectType === comment.objectType && parent.recId == comment.objectRecId) {
                return false;
            }
            return ctrl.getOwnerName(comment) != null;
        }

        $scope.$on('COMPANYOPUCHANGES', function () {
            activate();
        });

        function activate() {
        	ctrl.comments = [];
        	getComments();
        }
	}
	// Directive containing chats edit for one object
	app.directive('dfxChatEdit', [function () {
		return {
			restrict: 'E',
			scope: {},
            controller: function () {
                //This is a dummycontroller
			},
			controllerAs: 'ctrl',
			bindToController: {
				comment: '=',
				onSaveCallback: '&',
				onCancelCallback: '&',
				onDeleteCallback: '&',
                onAdjustTimeCallback: '&',
                hasChangesCallback: '&'
			},
			template: require('../directives/dfxChatEdit.html')
		}
	}]);
export default app


