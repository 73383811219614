
    'use strict';
    var controllerId = 'maintenance';

	var maintenanceModule= angular.module('app').controller(controllerId, ['$scope', 'spinner', 'common', '$location', maintenance]);
	
	var maintenanceBreakMode; 
	var maintenanceBreakStart;
	var maintenanceBreakDuration;
	angular.module('app').config(["getAppSettingsProvider", function (getAppSettingsProvider) {
		maintenanceBreakMode = getAppSettingsProvider.getAppSetting("MaintenanceBreakMode", "0");
		maintenanceBreakStart = getAppSettingsProvider.getAppSetting("MaintenanceBreakStart", "");
		maintenanceBreakDuration = getAppSettingsProvider.getAppSetting("MaintenanceBreakDuration", "0");
			
	}]);
    function maintenance($scope, spinner, common, $location) {
        var ctrl = this;
        ctrl.breakMode = maintenanceBreakMode;
        ctrl.lang = browserPreferredLanguage;
        $scope.strings = maintenanceStrings;

        if (maintenanceBreakMode == 0) {
            redirectToHome();
        } else if (maintenanceBreakMode > 0 && moment.utc(maintenanceBreakStart, 'YYYYMMDDHHmmss').isValid()) {
            var start = moment.utc(maintenanceBreakStart, 'YYYYMMDDHHmmss');
            var end = moment.utc(start).add(maintenanceBreakDuration, 'm');
            if (start.diff(moment().utc()) > 0 || !moment().utc().isBetween(start, end)) {
                redirectToHome();
            }
            ctrl.start = start.local().format(common.longDateFormat(false) + " " + common.longTimeFormat());
            ctrl.end = end.local().format(common.longDateFormat(false) + " " + common.longTimeFormat());
        }

        /* Redirect to default if no maintenance currently ongoing */
        function redirectToHome() {
            $location.path('/');
        }
    }
export default maintenanceModule