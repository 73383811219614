
	'use strict';
	var controllerId = 'portaltab';
	var portaltabModule= angular.module('common').controller(controllerId, ['$scope','$injector', 'common', 'commonDatacontext', 'usersettings', 'uiGridEditConstants', '$timeout', portaltab]);

	function portaltab($scope, $injector, common, commonDatacontext, usersettings, uiGridEditConstants, $timeout) {

	    var getLogFn = common.logger.getLogFn;
	    var log = getLogFn(controllerId);
	    var succMsg = getLogFn(controllerId, 'success');

		/* Properties */
		var ctrl = this;
		ctrl.modules = []; // All modules on the page.
		ctrl.selectedGridRows = []; 
		ctrl.selectedModuleObjects = [];
		ctrl.isReady = common.$q.defer();
		ctrl.isFormCommandsReady = common.$q.defer();
		ctrl.moduleWidth = null;
		ctrl.formIdOrder = 20751;
        //Use modelMap to specify which get-function to use. Defaults to 'get+modelName' and in last case getGenericDataSet.
		var modelMap = {
			PurchaseOrder: { dataService: 'commonDatacontext', commands: {'TOOL_FINISH_PURPROP': 'finishPurOrder'}},
			PurchaseOrderRow: { dataService: 'purDatacontext', getData: 'getOrderRows' },
			SubstituteUser: { dataService: 'commonDatacontext', getData: 'getSubstituteUsers' },
		};

		/**
		 * Event handler for changing restriction fields inside the grid.
		 * @param {Module whose grid's restriction field is changed.} module
		 * @param {Column where restriction field resides.} column
		 * TODO: Support for values in multiple columns simultaneously.
		 * TODO: Support for less than/more than/ranges in number columns.
		 * TODO: Support for less than/more than in date columns.
		 */
		ctrl.dfxGridFilterChanged = function (module, filterString) {
		        if (filterString) {
		            common.debouncedThrottle('GRIDFILTER', function () {
		                module.filterString = filterString;
		                getData(module);
		            }, ctrl.throttleAmount);
		        }		
		}

		/**
		 * Event handler for selecting a row in the grid, selecting form data via url or otherwise select an entity.
		 * @param {Module causing the event.} module
		 * @param {Selected entity.} entity
		 */
		ctrl.onSelection = function (module, entity) {
			refreshChildModules(module);
			var index = ctrl.selectedGridRows.indexOf(entity);
			if (index == -1) {				
				ctrl.selectedGridRows.push(entity);
			}
			else {
				ctrl.selectedGridRows.splice(index, 1);
			}
		}

	    /**
        * Event handler for deleting a row in the grid
        * @param {Module causing the event.} module
        * @param {Entity to be deleted.} entity
        */
		ctrl.onDelete = function (module, entity) {
		    if (module && modelMap[module.modelName]) {
		        var modelInfo = modelMap[module.modelName];
		        var service = $injector.get(modelInfo.dataService);
		        if (angular.isDefined(service.saveChanges)) {
		            entity.entityAspect.setDeleted();
		            service.saveChanges(entity);
		            common.$translate("STRCONST.PUBLIC.MSG_ROW_REMOVED").then(function (translation) {
		                succMsg(translation);
		            })
		        }
		    }
		}

		ctrl.hasChanges = function (module) {
		    if (module && modelMap[module.modelName]) {
		        var modelInfo = modelMap[module.modelName];
		        var service = $injector.get(modelInfo.dataService);
		        if (angular.isDefined(service.hasChanges)) {
		            return service.hasChanges();
		        }
		    }
		    return false;
		}

		/**
		 * Event handler for changing show fields above grid.
		 * @param {Module whose order by is changed.} module
		 * @param {Id of command to be switched to.} commandId
		 * @param {Index of restriction group inside the module.} groupIndex
		 */
		ctrl.dfxGridRstChanged = function (module, commandId, selectedRestrictionCmds, groupIndex) {
			ctrl.selectedGridRows = [];			
			module.selectedRestrictionCmds = selectedRestrictionCmds;
			getData(module);
		}

		/**
		 * Event handler for changing query order by command. Makes the query call.
		 * @param {Module whose order by is changed.} module
		 * @param {Id of command to be switched to.} commandId
		 */
		ctrl.dfxGridSortChanged = function (module, commandId) {
			module.selectedSortCmd = commandId;
			getData(module);
		}

		/**
		* Event handler for tool command.
		* @param {Module whose tool is called.} module
		* @param {Id of command to be executed.} commandId
		* @param {Method name of command to be executed.} method
		*/
		ctrl.dfxGridToolUsed = function (module, commandId, method, criteria, params) {
		    switch (commandId) {
		        // Save made changes
		        case "SAVE":
		            saveChanges(module);
		            break;
                // Reject made changes
		        case "CANCEL":
		            rejectChanges(module);
		            break;
                // Create new row to the grid
		        case "CREATE_NEW":
		            createRow(module);
		            break;
		        default:
		            var commandFunction = getCommandFunction(module.modelName, commandId, method);
		            if (commandFunction) {
		                commandFunction(ctrl.selectedGridRows, JSON.stringify(params));
		            }
		            break;
		    }
		}		

		/* Functions */

		function activate() {
			ctrl.isReady = common.$q.defer();
			usersettings.getUserSettings().then(function (data) {
				if (!data || !data.info || !data.info.userId) {
					usersettings.redirectToLogin();
				} else {
					usersettings.authorizeHeader();

					// Get modules for current tab.
					var portalinfo = usersettings.getPortals().then(function (data) {
						if (data) {
							ctrl.modules = common.getModulesForCurrentTab(data);

							// Loop through modules and fill in some necessary data from form settings.
							ctrl.modules.reduce(function (promise, module) {
								// Initial values.
								if (module.formId > 0) {
									module.dfxGridOptions = {
										columnDefs: [],
										data: []
									};
								}
								module.filterString = null;
								module.isPanelOpen = true;
								module.selectedSortCmd = null;
								module.selectedRestrictionCmds = null;
								module.isReady = common.$q.defer();
								// Get data from formsettings.
								return promise.then(function () {
									if (module.formId > 0) {
										return commonDatacontext.getFormSettings(module.formId, false).then(function (data) {
											if (data) {
												ctrl.isFormCommandsReady.promise.then(function () {
													module.formVersion = null;
													getData(module);
													common.triggerResizeEvent(100);
													module.isReady.resolve(data);
												});												
											}
										});
									}
								})
							}, common.$q.when(true)).then(function (finalResult) {
								ctrl.isReady.resolve(ctrl.modules);
							});
						}
					});
				}
			}, function () {
				usersettings.redirectToLogin();
			});
		}

		/**
		 * Gets data for given module.
		 * @param {Module} module
		 */
		function getData(module) {
            if (module && module.modelName && module.type == 'eMaster') {
                var serviceFunction = getGetDataFunction(module.modelName);
                if (serviceFunction) {
                    var logicalId = JSON.stringify(common.$routeParams);
                    if (logicalId == "{}") {
                        logicalId = null
                    }
                    var lookupParams = null; //TODO
                    serviceFunction(null, module.filterString, module.formId, module.formVersion, module.selectedSortCmd, module.selectedRestrictionCmds, logicalId, lookupParams, module.modelName, false).then(function (data) {
                        if (data && data.length > 0) {
							data = data && data[0] && data[0].datatable ? data[0].datatable : data ? data : [];
                            if (module.component == 'GRIDMODULECONTROL') {
                                module.dfxGridOptions.refreshData(data);
                            }
                            module.selectedObject = data[0];
                            module.rowTools = module.selectedObject.rowTools;
                            ctrl.selectedModuleObjects.push(data[0]);
                        }
                        else if (data && data.length == 0) {
                            if (module.component == 'GRIDMODULECONTROL') {
                                module.dfxGridOptions.refreshData([]);
                            }
                        }
                    });
                }
            }
            else if (module && module.modelName && module.type == 'eDetail') {
                // If parent logical id (eg. orderId) is given in url, use it. Otherwise use parent rec id.
                var parentRecId = 0;
                var parentLogicalId = JSON.stringify(common.$routeParams);
                if (parentLogicalId == "{}") {
                    parentLogicalId = null
                }
                var parentModule = getParentModule(module);
                if (parentLogicalId == null && parentModule && parentModule.selectedObject && parentModule.selectedObject.recId) {
                    parentRecId = parentModule.selectedObject.recId;
                }
                var serviceFunction = getGetDataFunction(module.modelName);
                if (serviceFunction) {
                    serviceFunction(parentRecId, parentLogicalId, module.formId, module.formVersion, module.filterString, module.selectedSortCmd, module.selectedRestrictionCmds).then(function (data) {
                        if (data.length > 0) {
                            module.dfxGridOptions.refreshData(data);
                            module.selectedObject = data[0];
                            module.rowTools = module.selectedObject.rowTools;
                            ctrl.selectedModuleObjects.push(data[0]);
                        }
                    });
                }
            }
            else if (module && module.modelName && module.type == 'eNormal') {
                var serviceFunction = getGetDataFunction(module.modelName);
                if (serviceFunction) { //TODO: Determine params
                    serviceFunction().then(function (data) {
                        if (data.length > 0) {
                            module.dfxGridOptions.refreshData(data);
                            module.selectedObject = data[0];
                            module.rowTools = module.selectedObject.rowTools;
                            ctrl.selectedModuleObjects.push(data[0]);
                        }
                    });
                }
            }
            else {
                common.logger.logError(common.$translate.instant("STRCONST.LEANPORTAL.ERR_IN_PORTAL_SETTINGS"), false, false, true);
            }

			if (module && module.width){
				ctrl.moduleWidth = module.width;
			}
			else ctrl.moduleWidth = null;
		}

		ctrl.gridParentElementWidth = function () {
			var elem = document.getElementById("grid-module");
			return elem ? (elem.clientWidth - 15) : undefined; // Some space for scroll bar.
		}

		/**
		 * Gets parent module for given module. If parent module is not found, returns null.
		 * @param {Module whose parent is requested.} childModule
		 * @returns {Parent module or null}
		 */
		function getParentModule(module) {
			for (var i = 0; i < ctrl.modules.length; i++) {
				if (ctrl.modules[i] && ctrl.modules[i].id == module.parentModuleId) {
					return ctrl.modules[i];
					break;
				}
			}
			return null;
		}

		function saveChanges(module) {
			if (module && modelMap[module.modelName]) {
				var modelInfo = modelMap[module.modelName];
				var service = $injector.get(modelInfo.dataService);
				if(angular.isDefined(service.saveChanges))
				{
					service.saveChanges();
				}
			}
		}

		function rejectChanges(module) {
		    if (module && modelMap[module.modelName]) {
		        var modelInfo = modelMap[module.modelName];
		        var service = $injector.get(modelInfo.dataService);
		        if (angular.isDefined(service.hasChanges) && angular.isDefined(service.rejectChanges)) {
		            $scope.$broadcast(uiGridEditConstants.events.CANCEL_CELL_EDIT);
		            if (service.hasChanges()) {
		                service.rejectChanges();
		                getData(module);
		            }
		        };
		    }
		}

		function createRow(module) {
		    if (module && modelMap[module.modelName]) {
		        var modelInfo = modelMap[module.modelName];
		        var service = $injector.get(modelInfo.dataService);
		        if (angular.isDefined(service.getEntityManager)) {
		            var entMan = service.getEntityManager();
		            var newEnt = entMan.createEntity(module.modelName);
		            module.dfxGridOptions.createRow(newEnt);
		        }
		    }
		}

		/**
		* Gets the getData function that maps to given model.
		* @param {modelName} model name.
		* @returns {getData function} example: purDatacontext.getOrderRows
		*/
		function getGetDataFunction(modelName) {
		    var modelInfo = modelMap[modelName];
		    if (modelInfo) {
		        var service = $injector.get(modelInfo.dataService);
		        var functionName = modelInfo.getData;
		        if (!functionName) {
		            functionName = 'get' + modelName + 's';
		        }
		        if (angular.isDefined(service[functionName])) {
		            return service[functionName];
		        }
		    }
		    else {
		        return commonDatacontext.getGenericDataSet;
		    }
		}

		/**
		* Gets the command function that maps to given model.
		* @param {modelName} model name.
		* @param {commandId} command id.
		* @param {method} method name.
		* @returns {command function} example: purDatacontext.finishPurOrderRow
		*/
		function getCommandFunction(modelName, commandId, method) {
			var modelInfo = modelMap[modelName];
			var service = $injector.get(modelInfo.dataService);
			var functionName = method;					
			if (angular.isDefined(service[functionName])) {
				return service[functionName];
			}
			else if(modelInfo.commands) {
				functionName = modelInfo.commands[commandId];
				if (angular.isDefined(service[functionName])) {
					return service[functionName];
				}
			}
		}

		function refreshChildModules(module) {
			 //Refresh child module data.
			for (var i = 0; i < ctrl.modules.length; i++) {
				if (ctrl.modules[i] && ctrl.modules[i].parentModuleId == module.id) {
					getData(ctrl.modules[i]);
				}
			}
		}

		activate();

		$scope.$on('COMPANYOPUCHANGES', function () {
			activate();
		});
		$scope.$on('REJECTCHANGES', function (event, args) {
		    if (args.param == true) {
		        for (var i = 0; i < ctrl.modules.length; i++) {
		            rejectChanges(ctrl.modules[i]);
		        }
		    }
		})
	}
export default portaltabModule
