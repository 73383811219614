'use strict';
var saveDocumentModule = angular.module('docdir.dfxSaveDocument',[]);
saveDocumentModule.directive('dfxSaveDocument', function () {
	// Provides implementation for uploading/saving documents.
	// Usage:
	// Is used both for creating and editing documents. Can display a creation button (opens up a modal) or show columns as a part of another modal.
	// Example:
	// <dfx-save-document parent="ctrl.selectedInvoice" custom-class="glyphicon glyphicon-plus red"></dfx-save-document>
	return {
		restrict: 'E', //E = element, A = attribute, C = class, M = comment 
		scope: {},
		controllerAs: 'ctrl',
		bindToController: {
			parent: '=', // Parent object entity.
			customText: '=',
			//defaultValues: '=?', // Deprecated. Use DOC_DEFAULTS enumeration instead.
			disableBtn: '=',
			displayMode: '=?', // Modal (default), Normal.
			docDefaultsEnumKey: '=?', // DOC_DEFAULTS enumeration key (value01). If not found, portal id is used as key. Enumeration is used for hiding columns, list values and default values. If not set, form is used for hiding.
			existingDocument: '=', // Document to change.
			existingDocumentArr: '=?',
			extraButtons: '=?',
			iconOnly: '=?',
			instantlyOpenRealModal: '=?',	//We instantly jump to the ctrl.open function when using modalAskDfxSaveDocument.html. Directly feeding dfxSaveDocumentModal.html to dfx-modal would've required an entire directive refactoring.
			customClass: '@?',
			customBtnClass: '@?',
			//hideSubject: '=?', // Deprecated. Use DOC_DEFAULTS enumeration instead.
			onBeforeSaveCallback: '&?',
			onContentChangedCallback: '&',
			onContentEditedCallback: '&',
			onClickedCallback: '&',
			onOpenInTabCallback: '&',
			supplierSecondTable: '=?', // Determines weather it is a document that currentUser has uploaded,
			modalTemplateUrl: '=?'
		},
		controller: ['$scope', 'common', 'commonDatacontext', '$uibModal', '$q', '$http', 'spinner', '$uibModalStack', 'usersettings', function ($scope, common, commonDatacontext, $uibModal, $q, $http, spinner, $uibModalStack, usersettings) {
			var ctrl = this;
			ctrl.enums = [];
			ctrl.files;
			ctrl.formId = 22010;
			ctrl.visibEnum = {
				HIDDEN: 0,
				READONLY: 1,
				EDITABLE: 2
			};

			ctrl.$onInit = function () {
				ctrl.displayMode = !ctrl.displayMode ? 'Modal' : ctrl.displayMode;
				ctrl.instantlyOpenRealModal ? ctrl.open() : null;
				usersettings.getLoginPromise().then(function () {
					init();
				})
			};

			function getDocValues(docLocationValue) {
				return {
					docLocation: docLocationValue,
					selApplication: ctrl.selApplication,
					selCopyClass: ctrl.selCopyClass,
					selCopyType: ctrl.selCopyType,
					selLanguage: ctrl.selLanguage,
					selSubject: ctrl.selSubject,
					selSubjectName: ctrl.selSubjectName,
					selDocStatus: ctrl.selDocStatus,
					selUsePrint: ctrl.selUsePrint,
					selWebFileLink: ctrl.selWebFileLink,
					selPos: ctrl.selPos
				};
			}

			$scope.$watchCollection('ctrl.files', function (value) {
				if (ctrl.displayMode === 'Normal' && value && ctrl.onContentChangedCallback) {
					ctrl.onContentChangedCallback({ fileList: value, doc: getDocValues(null) });
				}
			});

			$scope.$watch('ctrl.docLocation', function (value) {
				if (ctrl.displayMode === 'Normal' && value && ctrl.onContentChangedCallback) {
					ctrl.onContentChangedCallback({ fileList: null, doc: getDocValues(value) });
				}
			});

			$scope.$watch('ctrl.selSubject', function (value) {
				if (ctrl.displayMode === 'Normal' && value && ctrl.onContentChangedCallback) {
					ctrl.onContentChangedCallback({ fileList: ctrl.files, doc: getDocValues(ctrl.docLocation) });
				}
			});

            /**
             * Gets default value from DOC_DEFAULTS enumeration.
             * @param {string} key - Key or enumeration id. Used for finding correct enumeration extra select.
             * @return {string} Default value.
             */
			function getDefaultFromDocDefaults(key) {
				if (key && ctrl.enums['DOC_DEFAULTS'] && ctrl.enums['DOC_DEFAULTS'][0] && ctrl.enums['DOC_DEFAULTS'][0].extraSelect) {
					var e = ctrl.enums['DOC_DEFAULTS'][0].extraSelect;

					if (key === 'INFO1' && e[8]) {
						return e[8];
					}
					if (key === 'COPYTYPE' && e[9]) {
						return e[9];
					}
					if (key === 'APPLICATION' && e[10]) {
						return e[10];
					}
					if (key === 'DOCSUBJECT' && e[11]) {
						return e[11];
					}
					if (key === 'DOCSTATUS' && e[12]) {
						return e[12];
					}
					if (key === 'WEBFILELINK' && e[13]) {
						return e[13];
					}
					if (key === 'COPYCLASS' && e[14]) {
						return e[14];
					}
					if (key === 'USEPRINT' && e[15]) {
						return e[15];
					}
					if (key === 'LANGUAGE' && e[16]) {
						return e[16];
					}
				}
				return null;
			}

            /**
             * Gets list of enumerations from DOC_DEFAULTS enumeration.
             * @param {string} key - Key or enumeration id. Used for finding correct enumeration extra select.
             * @return {Object[]} List of strings.
             */
			function getDocDefaultsList(key) {
				if (key && ctrl.enums['DOC_DEFAULTS'] && ctrl.enums['DOC_DEFAULTS'][0] && ctrl.enums['DOC_DEFAULTS'][0].extraSelect) {
					var e = ctrl.enums['DOC_DEFAULTS'][0].extraSelect;

					if (key === 'COLUMN_VISIBILITY' && e[1]) {
						return e[1].split(';');
					}
					if (key === 'DOCSUBJECT' && e[2]) {
						return e[2].split(';');
					}
					if (key === 'DOCSTATUS' && e[3]) {
						return e[3].split(';');
					}
					if (key === 'WEBFILELINK' && e[4]) {
						return e[4].split(';');
					}
					if (key === 'COPYCLASS' && e[5]) {
						return e[5].split(';');
					}
					if (key === 'USEPRINT' && e[6]) {
						return e[6].split(';');
					}
					if (key === 'LANGUAGE' && e[7]) {
						return e[7].split(';');
					}
				}
				return [];
			}

            /**
             * Returns enumeration list either filtered by using DOC_DEFAULTS enumeration or as such.
             * @param {string} enumName - Enumeration id
             * @param {Object[]} origList - All enumeration rows for given enumeration.
             * @returns {Object[]} Enumeration rows.
             */
			function getEnumList(enumName, origList) {
				var lst = [];
				var docDefaultsList = getDocDefaultsList(enumName);
				if (docDefaultsList.length > 0) {
					lst = origList.filter(function (value) {
						return -1 !== docDefaultsList.indexOf(value.id);
					});
				}
				else {
					lst = origList;
				}
				return lst;
			}

			/*Function to get document saving enumerations, which are used when populating dialog dropdowns/select-boxes*/
			function getEnums() {
				var promise;
				if (!ctrl.enums || !ctrl.enums['WEBFILELINK']) {
					// If docDefaults key is given as parameter, use it when querying enumerations. If not, use portal id.
					promise = commonDatacontext.getDocSaveEnums(ctrl.docDefaultsEnumKey || common.$route.current.portalId).then(function (data) {
						var obj = data;
						ctrl.enums['DOC_DEFAULTS'] = obj['DOC_DEFAULTS'];
						for (var e in obj) {
							if (e !== 'DOC_DEFAULTS') {
								ctrl.enums[e] = getEnumList(e, obj[e]);
							}
						}
						return data;
					});
				}
				return $q.when(promise);
			}

            /**
             * Gets form settings from database if not queried before. Sets them to variable.
             * @returns {promise} Promise when done.
             */
			function getFormSettings() {
				var fs;
				if (!ctrl.formSettings) {
					fs = commonDatacontext.getFormSettings(ctrl.formId).then(function (data) {
						ctrl.formSettings = data;
						return data;
					});
				}
				return $q.when(fs);
			}

			// Initial actions.
			function init() {
				getEnums().then(function () {
					setDefaultValues();
					setVisibility();
				});
			}

			/* Gets default values from directive parameters or from DOC_DEFAULTS enumeration, if any. */
			function setDefaultValues() {
				// Clear document header and file information so that consequent uses will not have same header by default.
				ctrl.title = getDefaultFromDocDefaults('INFO1');
				ctrl.docLocation = null;
				ctrl.files = [];
				ctrl.selWebFileLink = getDefaultFromDocDefaults('WEBFILELINK');
				ctrl.selUsePrint = getDefaultFromDocDefaults('USEPRINT');
				ctrl.selDocStatus = getDefaultFromDocDefaults('DOCSTATUS');
				ctrl.selSubject = getDefaultFromDocDefaults('DOCSUBJECT');
				ctrl.selLanguage = getDefaultFromDocDefaults('LANGUAGE');
				ctrl.selCopyClass = getDefaultFromDocDefaults('COPYCLASS');
				ctrl.selCopyType = getDefaultFromDocDefaults('COPYTYPE');
				ctrl.selApplication = getDefaultFromDocDefaults('APPLICATION');
				ctrl.selPos = null;

				var docDefaultsList = getDocDefaultsList('POS'); // ?
				if (docDefaultsList.length > 0) {
					ctrl.selPos = docDefaultsList[0];
				}
			}

            /**
             * Sets column visibility. Order: 1. DOC_DEFAULTS enumeration, 2. form 22010.
             */
			function setVisibility() {
				var colNames = ['INFO1', 'SUBJECT', 'FILELINKTYPE', 'USEPRINT', 'COPY_CLASS', 'STATUS', 'LANGUAGE', 'POS'];
				ctrl.cols = [];
				if (ctrl.enums && ctrl.enums['DOC_DEFAULTS'] && ctrl.enums['DOC_DEFAULTS'][0]) {
					var cvl = getDocDefaultsList('COLUMN_VISIBILITY');

					angular.forEach(colNames, function (c) {
						ctrl.cols[c] = (cvl === [] || cvl.length === 0 || cvl.indexOf(c) > -1) ? ctrl.visibEnum.EDITABLE : ctrl.visibEnum.HIDDEN;
					});

				}
				else {
					getFormSettings().then(function () {
						if (ctrl.formSettings.columnSettings) {
							angular.forEach(colNames, function (c) {
								ctrl.cols[c] = ctrl.formSettings.columnSettings[c] && ctrl.formSettings.columnSettings[c].hidden ? ctrl.visibEnum.HIDDEN : ctrl.visibEnum.EDITABLE;
							});
						}
					});
				}
			}

			ctrl.open = function () {
				if (ctrl.onBeforeSaveCallback) {
					var beforeSaveCallbackPromise = common.$q.defer();
					ctrl.onBeforeSaveCallback({ parent: ctrl.parent, beforeSaveCallbackPromise: beforeSaveCallbackPromise });
					beforeSaveCallbackPromise.promise.then(function (continueSave) {
						if (continueSave) {
							openModal();
						}
					});
				}
				else {
					openModal();
				}
				function openModal() {
					/*Note function getEnums waits for promise/results before opening modal dialog*/
					getEnums().then(function (data) {
						setDefaultValues();
						var extraButtons = ctrl.existingDocument || ctrl.existingDocumentArr ? '[{id: \'Delete\', name: \'' + common.$translate.instant('STRCONST.PUBLIC.TXT_DELETE') + '\', before: false, isDefault: false }]' : '';
						var templateUrl = ctrl.modalTemplateUrl ? ctrl.modalTemplateUrl : './app/common/document/directives/dfxSaveDocumentModal.html';
						var modalInstance = $uibModal.open({
							animation: true,
							backdrop: false,
							scope: $scope,
							template: '<dfx-modal data-title="modalTitle" button-mask="7" extra-buttons="' + extraButtons + '" ok-btn-text="\'STRCONST.PUBLIC.TXT_SAVE\' | translate" cancel-btn-text="\'STRCONST.PUBLIC.TXT_CANCEL\' | translate"><div data-ng-include="\'' + templateUrl + '\'"></div></dfx-modal>',
							controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
								/*Initialize possible existing values (create doc versus edit)*/
								if ($scope.ctrl.existingDocumentArr && $scope.ctrl.existingDocumentArr.length > 0) {
									ctrl.documentList = $scope.ctrl.existingDocumentArr[0].docs;
									// Init first in array on modal open
									ctrl.selDoc = $scope.ctrl.existingDocumentArr[0].docs[0];
									init($scope.ctrl.existingDocumentArr[0].docs[0]);
								}
								else {
									$scope.existingDocument = $scope.ctrl.existingDocument;
									// Otherwise init existingDocument/new document if it's not defined
									init($scope.ctrl.existingDocument);
								}

								ctrl.docSelected = function (doc) {
									ctrl.selDoc = doc;
									init(doc);
								}

								function init(existingDocument) {
									$scope.existingDocument = existingDocument;
									/*Change modal header according to operation*/
									$scope.modalTitle = $scope.existingDocument ? common.$translate.instant("STRCONST.PUR.GRP_UPDATE") + ' ' + common.$translate.instant("STRCONST.PUBLIC.TXT_DOCUMENT") : common.$translate.instant("STRCONST.LEANPORTAL.HDR_ADDDOC");
									if ($scope.existingDocument) {
										/*Set current values for their respective fields */
										$scope.docRecId = $scope.existingDocument.recId;
										ctrl.docLocation = $scope.existingDocument.url;
										ctrl.selWebFileLink = $scope.existingDocument.webFileLink;
										ctrl.title = $scope.existingDocument.title;
										ctrl.selLanguage = $scope.existingDocument.language;
										ctrl.selDocStatus = $scope.existingDocument.status;
										ctrl.selCopyClass = $scope.existingDocument.copyClass;
										ctrl.selCopyType = $scope.existingDocument.copyType;
										ctrl.selApplication = $scope.existingDocument.application;
										ctrl.selUsePrint = $scope.existingDocument.usePrint;
										ctrl.selSubject = $scope.existingDocument.subject;
										ctrl.selSubjectName = $scope.existingDocument.subjectName;
										ctrl.selPos = $scope.existingDocument.pos;
									// TODO: What if enumeration value is not found in the list? Should there be a new query for enumeration without any restrictions and add the enumeration as disabled to the list? Or just show the ID as such?
									}
								}
								/*Change modal header according to operation*/
								$scope.modalTitle = $scope.existingDocument ? common.$translate.instant("STRCONST.PUR.GRP_UPDATE") + ' ' + common.$translate.instant("STRCONST.PUBLIC.TXT_DOCUMENT") : common.$translate.instant("STRCONST.LEANPORTAL.HDR_ADDDOC");

								/*Current functionality -> copying to DB and saving filelink*/
								var tmpArr = [];
								for (var i = 0; i < ctrl.enums['WEBFILELINK'].length; i++) {
									var item = ctrl.enums['WEBFILELINK'][i];
									if (item.id === 'FILEDB' || item.id === 'FILELINK') {
										tmpArr.push(item);
									}
								}
								$scope.webFileLink = tmpArr;

								/* Set selected webFileLink default value if found from enumeration (If not defined by existingDocument or DOC_DEFAULTS enumeration!), otherwise use first value in array. */
								// TODO: Fix this to support DOC_DEFAULTS enumeration. It does not set default value correctly now.
								if (!ctrl.selWebFileLink) {
									ctrl.selWebFileLink = $scope.webFileLink[0].id;
									for (var i = 0; i < $scope.webFileLink.length; i++) {
										if ($scope.webFileLink[i].isDefault) {
											ctrl.selWebFileLink = $scope.webFileLink[i].id;
										}
									}
								}

								$scope.onApply = function (btn) {
									if (btn && btn.id === 'Delete') {
										if (confirm(common.$translate.instant("STRCONST.LEANPORTAL.QRY_DELETE_DOCUMENT"))) {
											commonDatacontext.deleteDocument($scope.docRecId);
											ctrl.onContentChangedCallback();
											common.logger.logSuccess(common.$translate.instant("STRCONST.LEANPORTAL.MSG_DOC_ACTION") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + ".", false, false, true);
											$scope.onCancel();
										}
									}
									else {
										var doc = {
											docLocation: ctrl.docLocation,
											title: ctrl.title,
											selApplication: ctrl.selApplication,
											selCopyClass: ctrl.selCopyClass,
											selCopyType: ctrl.selCopyType,
											selLanguage: ctrl.selLanguage,
											selSubject: ctrl.selSubject,
											selSubjectName: ctrl.selSubjectName,
											selDocStatus: ctrl.selDocStatus,
											selUsePrint: ctrl.selUsePrint,
											selWebFileLink: ctrl.selWebFileLink,
											selPos: ctrl.selPos
										};
										// Callback if parent haven't been created yet
										if (ctrl.parent && (ctrl.parent.recId < 0 || !ctrl.parent.recId)) {
											ctrl.onContentChangedCallback({fileList: ctrl.files, doc: doc })
											$scope.onCancel()
										}
										else {
											commonDatacontext.saveDocumentMultiple(doc, $scope.existingDocument, ctrl.files, ctrl.parent, ctrl.onContentChangedCallback, ctrl.onContentEditedCallback, $scope.onCancel, ctrl.enums['DOCSUBJECT']);
										}
									}
								};

								$scope.onCancel = function () {
									ctrl.instantlyOpenRealModal ? $uibModalStack.dismissAll('cancel') : $uibModalInstance.dismiss('cancel');
								};
							}]
						});
					});
				};
			};
		}],
		template: function (elem, attrs) {
			return attrs.displayMode === "'Normal'" ? require('./dfxSaveDocumentModal.html') : require('./dfxOpenSaveDocument.html');
		}
	};
});
export default saveDocumentModule