
    'use strict';
    var controllerId = 'login';
    var app = angular.module('common');

    app.controller(controllerId, ['$scope', '$routeParams', 'common', 'commonDatacontext', 'usersettings', '$http', '$rootScope', 'spinner', login]);

    function login($scope, $routeParams, common, commonDatacontext, usersettings, $http, $rootScope, spinner) {
        var getLogFn = common.logger.getLogFn;
        var log = getLogFn(controllerId);
        var errMsg = getLogFn(controllerId, 'error');
        var companyinfo = [];
        var companySelect = false;
        var $q = common.$q;
        var lg = this;
        lg.userinfo = [];
        lg.companydefault = [];
        lg.companyinfo = [];
        lg._loggingIn = false;
        lg.routeParams = $routeParams;
        lg.hideLogin = true;
        lg.passwordExpired = false;
		lg.passwordExpiring = false;
		lg.tagLoginAllowed = false;

        lg.changeCompany = function (company) {
        	usersettings.getChangeCompany(company, lg.routeParams.portalId, lg.routeParams.tabId).then(function (data) {
        	    var lsKey = usersettings.getLocStoKey('NoCompSelected', true);
        	    if (localStorage && localStorage.getItem(lsKey)) {
        	        var lsInfo = JSON.parse(localStorage.getItem(lsKey));
        	        lsInfo = false;
        	        localStorage.setItem(lsKey, JSON.stringify(lsInfo));
        	    }
        	    log(common.$translate.instant("STRCONST.PUBLIC.MSG_SUCCESSFUL_LOGIN"));
        	});

        }

        lg.changePassword = function (userId, oldPassword, newPassword) {
        	lg.passwordExpiring = false;
        	companySelectInit();
        	if (!oldPassword) {
        		oldPassword = '';
        	}
        	if (userId && newPassword) {
        	    spinner.spinnerShow('login', true, null, 0);
        		usersettings.login(userId, oldPassword, false, newPassword).then(
		        function () {
		        	lg.passwordExpired = false;
		        },
		        function (errorMessage) {
		        	lg.loggingIn(false);
		        }).finally(function (data) {
		            spinner.spinnerHide();
		        });
        	}
        }

        lg.loggingIn = function (loggingIn) {
            lg._loggingIn = loggingIn;
        };

		lg.login = function () {
            spinner.spinnerShow('login', true, null, 0);
			lg.loggingIn(true);
			if (lg.tagId) { lg.readTagId(lg.tagId) }
			else {
				usersettings.login(lg.userid, lg.password || "").then(
					function () {
					},
					function (errorMessage) {
						lg.loggingIn(false);
					}).finally(function (data) {
						spinner.spinnerHide();
					});
			}
        }

        lg.isLoggingIn = function () {
            return lg._loggingIn;
		}

		lg.readTagId = function (tagId) {
			usersettings.loginWithTagId(tagId).then(
				function () {
				},
				function (errorMessage) {
					lg.loggingIn(false);
				}).finally(function (data) {
					spinner.spinnerHide();
				});
		};

        lg.showCompanySelect = function () {
            return companySelect && !lg.passwordExpiring;
        }

    	/**
		 * User selected to skip expiring password change. Login has been done already so just continue to application.
		 */
        lg.skipPasswordChange = function () {
        	lg.passwordExpiring = false;
        	companySelectInit();
        	if (!companySelect) {
        		lg.loggingIn(false);
        		usersettings.getPath(null, true);
        	}
        };
        
       $scope.$on('SELECTCOMPANY', function (event, args) {
       	var company = args ? (args.company ? args.company : undefined) : undefined;
       	companySelectInit(company);
       });

        $scope.$on('PASSWORDEXPIRED', function (event, args) {
            lg.passwordExpired = true;
        });

    	/**
		 * Password will expire soon. Get error message from argument (got from query).
		 */
        $scope.$on('PASSWORDEXPIRING', function (event, args) {
        	lg.passwordExpiringErrMsg = args;
        	lg.passwordExpiring = true;
        	companySelect = false;
        	lg.loggingIn(false);
        });

        activate();

        function companySearch() {
            commonDatacontext.getCompany().then(function (data) {
                lg.companyinfo = data ? data : [];
                return lg.companyinfo;
            },
            function (data) {
                return lg.companyinfo = [];
            });
        }

    	/*Determines if company selection prompt should be shown*/
        function companySelectInit(company) {
        	if (company) {
        		usersettings.getSetCompanyStatus(false);
        		selectCompany(company);
        	}
        	else if (usersettings.getSetCompanyStatus()) {
        	    usersettings.getSetCompanyStatus(false);
        	    usersettings.authorizeHeader();
        		usersettings.getDefCompany().then(function (data) {
        		    if (data === false) {
        		        var lsKey = usersettings.getLocStoKey('NoCompSelected', true);
        		        if (localStorage) {
        		            var lsInfo = JSON.parse(localStorage.getItem(lsKey));
        		            lsInfo = true;
        		            localStorage.setItem(lsKey, JSON.stringify(lsInfo));
        		        }
        		        companySearch();
        		        companySelect = true;
        		    }
        		});
        	}
        }

        function selectCompany(company) {
        	var company = company;
        	if (company) {
        		lg.changeCompany(company);
        	} else {
        		companySearch();
        		companySelect = true;
        	}
		};

		function tagIdLoginInit() {
			var portalsAvailable = common.getAppSetting("portalsAvailable", "");
			var portalsAvailableForTagLogin = common.getAppSetting("portalsAvailableForTagLogin", "");
			if (portalsAvailable !== '' && portalsAvailable === portalsAvailableForTagLogin) {
				lg.tagLoginAllowed = true;
			}
		}

        function activate(args) {
        	common.activateController(controllerId);
        	if (Object.keys(lg.routeParams).length > 0) {
        		usersettings.loginWithSessionId(lg.routeParams.company).then(function (data) {
        			if (!data) {
        				lg.hideLogin = false;
        			}
        		},
					function (data) {
						lg.hideLogin = false;
					});

        	}
        	else {
        		lg.hideLogin = false;
        	}
			companySelectInit();
			tagIdLoginInit();
        }

        //    function getUsersettings() {
        //     var usersettingsPromises =
        //	    [
        //		    usersettings.getUserSettings(lg.userid, true).then(function (data) {
        //	        return lg.usersettings = data;
        //	    })
        //    ];
        //    
        //    // Q&D Test for delayed enum promises...
        //    return common.$q.all(usersettingsPromises).then(function () {
        //        log('Usersettings promises executed...');
        //    });
        //}

    }
export default app