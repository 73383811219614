
	'use strict';

	var serviceId = 'hiDatacontext';
	var hiDatacontextmodule=angular.module('common').factory(serviceId, ['common', 'config', 'entityManagerFactory', 'spinner', 'usersettings', 'zStorage', 'zStorageWip', 'zStorageConfig', 'commonDatacontext', hiDatacontext]);

	function hiDatacontext(common, config, emFactory, spinner, usersettings, zStorage, zStorageWip, zStorageConfig, commonDatacontext) {
		var events = config.events;
		var getLogFn = common.logger.getLogFn;
		var log = getLogFn('DataContext');
		var logSuccess = getLogFn(serviceId, 'success')
		var warnMsg = getLogFn('DataContext', 'warning');
		var logErr = getLogFn('DataContext', 'error');
		var EntityQuery = breeze.EntityQuery;
		var hourInputManager = emFactory.newManager('breeze/HourInput');
		var commonManager = emFactory.newManager('breeze/Common');
		var $q = common.$q;
		var _hasChanges = false;
		var $http = common.$http;
		var workTimeBatchLocSKey = null;
        var service = {
			getApprovalRoles: getApprovalRoles,
			getApprovalUsers: getApprovalUsers,
			getEmployees: getEmployees,
			getEmpResRoles: getEmpResRoles,			
			getFlexHourCountFromDb: getFlexHourCountFromDb,
		   	getHourInput: getHourInput,
		   	getLookups: getLookups,		   
			getProjects: getProjects,
            getWorks: getWorks,
			getWorkTimeApprovals: getWorkTimeApprovals,
			getWorkTimeSummaries: getWorkTimeSummaries,
			hourInputManager: hourInputManager,
			zStorage: zStorage,
			zStorageWip: zStorageWip,
			zStorageRefresh: zStorageRefresh,
			createExtraSalaryCode: createExtraSalaryCode,
			createWorkTimeBatch: createWorkTimeBatch,
			createWorkTime: createWorkTime,
			deleteWorkTimeBatch: deleteWorkTimeBatch,
			deleteWorkTime: deleteWorkTime,
			getHourInputsFromWip: getHourInputsFromWip,
            saveHourInputsToWip: saveHourInputsToWip,
			clearLocalChanges: clearLocalChanges,
			saveChanges: saveChanges,
			cloneItem: cloneItem,
            hasChanges: hasChanges,
            rejectChanges: rejectChanges,
			getNextSubrowId: getNextSubrowId,
			setFavourite: setFavourite,
			setFavDescription: setFavDescription,
			clearFavourites: clearFavourites,
			
		};

		init();

		return service;

		function init() {
			zStorage.init(hourInputManager);
			zStorageWip.init(hourInputManager);
			setupEventForEntitiesChanged();
			setupEventForHasChangesChanged();
			workTimeBatchLocSKey = common.getAppSetting("HourInputLocalStorageKeyName");
			
		}

		// This function checks for namespace changes after vesrion upgrade and fixes enteties in localstorage. 
		//
		function checkLS()	{
			var check = false;
			var lskey = usersettings.getLocStoKey(workTimeBatchLocSKey);
			
			var lst = localStorage.getItem(lskey);
			// var n = lst.indexOf("Tieto.LeanSystem.Web.LeanPortalSPA.Models"); //for debugging purposes
			if (lst && lst.indexOf("Tieto.LeanSystem.Web.LeanPortalSPA.Models") !== -1)
			{
				lst = lst.replace(/Tieto.LeanSystem.Web.LeanPortalSPA.Models/g, "LeanSystem.Web.LeanPortalSPA.Models");
				lst = JSON.parse(lst);
				localStorage.setItem(lskey, JSON.stringify(lst));
			}						
		}

		// This is implemented inside the hiDatacontext so we can easily update its value
		// Note: This is bound to UI (e.g. enabling/disabling buttons), so we can not do a heavy operation like "resolveHasChanges" here
		// 
		function hasChanges() {
			return _hasChanges;
		}

		// Save the EntityManager changed entities to server
		// TODO: Currently we send all the modified entities, including empty template rows/cells.
		//       Should investigate if we can detach those rows already client side and thus minimize the
		//		 payload sent to the server processing (at least as long as we reset the client side cache after successful update)
		//		 => Note: need to handle also the error scenario!
		// @param {Optional: Entity/entities which is/are about to be saved. If not given, all entities.} savingTarget
		// @param {Optional: Whether the saving result is stored to local storage or not. Default = true.} saveToLocalStorage
		// @param {Optional: showSpinner if false, the spinner is not shown.} showSpinner
		function saveChanges(savingTarget, saveToLocalStorage, showSpinner) {
			var entityArray = common.asArray(savingTarget);

			saveToLocalStorage = (typeof saveToLocalStorage == 'undefined') ? true : saveToLocalStorage;

			if (common.$rootScope.isWorkingLocally) {
				log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_HOURINPUT_SAVING") + ": " + common.$translate.instant("STRCONST.LEANPORTAL.MSG_OPER_ALLOWED_ONLY_ONLINE"));
				return;
			}

			if (resolveHasChanges()) {
				var source = 'dataContext.saveChanges';
				var spinnerActivated = spinner.spinnerShow(source, showSpinner, hourInputManager.getChanges());
				return hourInputManager.saveChanges(entityArray)
					.then(saveSucceeded)
					.fail(saveFailed);
			} else {
				// Our change tracking has somehow failed (or we've enabled the save button also in unchanged state)
				log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_NOTHING_TO_SAVE"));
				return $q.when(null);
			};

			function saveSucceeded(result) {
				spinner.spinnerHide(source, spinnerActivated);
				if (saveToLocalStorage) {
					zStorage.save(usersettings.getLocStoKey('workTimeBatchLocSKey'));
				}
				logSuccess(common.$translate.instant("STRCONST.LEANPORTAL.MSG_HOURINPUT_SAVING") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + ".", result, true);
				

				// This is usable when "merge after update" is implemented. 
				// (until then, this not relevant as long as the local cache is reset after update)
				//resolveHasChanges();
			}

			function saveFailed(error) {
				spinner.spinnerHide(source, spinnerActivated);
				usersettings.handleSaveError(common.$translate.instant("STRCONST.LEANPORTAL.MSG_HOURINPUT_SAVING") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE"), error);
				resolveHasChanges();
				throw error;
			}
        }

        function rejectChanges(entity) {
            if (entity) {
                if (entity.entityAspect) entity.entityAspect.rejectChanges();
            } else {
                hourInputManager.rejectChanges();
            }
            _hasChanges = false;
            resolveHasChanges();
        }

		// Our own method for resolve and differentiate actual user made changes from the
		// changes created by virtual empty row/cell generation
		function resolveHasChanges() {
			_hasChanges = getRealChanges(true).length > 0;
			if (!_hasChanges) {
				// For now, check also modified favourites...
				_hasChanges = hourInputManager.getChanges('Favourite').length > 0;
            }
            if (!_hasChanges) {
                _hasChanges = hourInputManager.getChanges('WorkTimeApproval').length > 0;
            }
			return _hasChanges;
		}

		function getRealChanges(firstChangeOnly) {
			var realChangedEntities = [];

			if (hourInputManager.hasChanges()) {
				// We need to check the chances through to filter out rows which are only empty templates
				var changedEntities = hourInputManager.getChanges('WorkTime');

				// angular .forEach does not support break in the middle -> use std for-loop...
				for (var i = 0; i < changedEntities.length; i++) {
					var ent = changedEntities[i];
					if (!ent.initializing && (ent.projId || ent.workId || ent.modified)
						&&
						(ent.entityAspect.entityState != breeze.EntityState.Added || ent.modified)) {
						realChangedEntities.push(ent);

						if (firstChangeOnly) {
							break;
						}
					}
				}
			}
			return realChangedEntities;
		}
		
		// This method is not very usable in the current hourinput implementation because all new "virtual empty slot" 
		// WorkTime and WorkTimeBatch entities will trigger the EntityManager's "hasChanges".
		//
		// TODO: Investigate using a Detached entity state (or similar method) in a generic situation where
		//       "untouched empty new row" entities/templates are needed in UI before they actually materialize as
		//		 something that user has changed. Currently every "empty new row" is in EntityState.Added and will affect
		//       the state of the whole manager.
		//       Another scenario would be to use a secondary EntityManager just for handling the empty entities but
		//       this causes additional work when trying to merge entity collections to be used in UI.
		function setupEventForHasChangesChanged() {
		    hourInputManager.hasChangesChanged.subscribe(function (eventArgs) {
				var data = { hasChanges: eventArgs.hasChanges };
				// send the message (the ctrl receives it)
				common.$broadcast(events.hasChangesChanged, data);
			});
		}

		// Entity property change interceptor.
		// We use this for detecting changes of any kind so we can save them to local storage
		// and can also keep track on "real" changes (NOTE: setupEventForHasChangesChanged should do some of this, 
		// but it is not usable at the moment)
		function setupEventForEntitiesChanged() {
		    hourInputManager.entityChanged.subscribe(function (changeArgs) {
				if (changeArgs.entityAction === breeze.EntityAction.PropertyChange) {
					if (interceptPropertyChange(changeArgs)) {
						common.$broadcast(events.entitiesChanged, changeArgs);
					}
				}
			});
		}

		// Handle value propagation from batch to subrows and vice versa. Also handle our own "modified" state triggering
		// 
		function interceptPropertyChange(changeArgs) {
			// TODO: Figure out if we should handle the "missing subrows" as well as the "dummy new row" here,
			// using e.g. some decorating property which should be removed from the "change set" here...

			var changedProp = changeArgs.args.propertyName;
			var ent = changeArgs.entity;
			var entType = ent.entityType.shortName;
			var newVal = changeArgs.args.newValue;
			var oldVal = changeArgs.args.oldValue;

			// If the property is "initializing", skip the change tracking by removing the original value
			// This property is currently used to skip our own (not Breeze!) change tracking when initializing new empty rows
			if (changedProp === 'initializing') {
				delete changeArgs.entity.entityAspect.originalValues[changedProp];
				return false;
			}
			if (changedProp === 'modified') {
				// This needs to be change tracked/reset after server side fetch...but no need to broadcast because it is the modification flag itself.
				return false;
			}

			var broadcastChange = !(ent.initializing);
			if ((oldVal == null || oldVal == '') && (newVal == null || newVal == '')) {
				broadcastChange = false;
			}

			if (entType === 'WorkTimeBatch') {
				// Handle batch changes.
				switch (changedProp) {
// This is done in vm.populateDescription at the moment to prevent triggering after each key press
					case 'description':
						broadcastChange = false;
						break;
					case 'projId':
						changeWtProjId(ent, newVal);
						break;
					case 'projName':
						changeWtProjName(ent, newVal);
						break;
					case 'taskId':
						changeWtTaskId(ent, newVal);
						break;
					case 'taskName':
						changeWtTaskName(ent, newVal);
						break;
					case 'workId':
						changeWtWorkId(ent, newVal);
						break;
					case 'workName':
						changeWtWorkName(ent, newVal);
						break;
					case 'operatId':
						changeWtOperatId(ent, newVal);
						break;
					case 'operatName':
						changeWtOperatName(ent, newVal);
						break;
					case 'mon':
						changeWtHours(ent, 0, newVal);
						break;
					case 'tue':
						changeWtHours(ent, 1, newVal);
						break;
					case 'wed':
						changeWtHours(ent, 2, newVal);
						break;
					case 'thu':
						changeWtHours(ent, 3, newVal);
						break;
					case 'fri':
						changeWtHours(ent, 4, newVal);
						break;
					case 'sat':
						changeWtHours(ent, 5, newVal);
						break;
					case 'sun':
						changeWtHours(ent, 6, newVal);
						break;
					default:
						break;
				}
			} else if (entType === 'WorkTime') {
				switch (changedProp) {
					case 'description':
						if (ent.WorkTimeBatch && ent.WorkTimeBatch.subrows && ent.description != ent.WorkTimeBatch.description && (ent.description || ent.WorkTimeBatch.description)) {
							var lst = [];
							var desc;
							ent.WorkTimeBatch.subrows.sort(function(sr1, sr2) {
								return sr1.weekDayIndex - sr2.weekDayIndex;
							}).forEach(function (subrow) {
								desc = subrow.description;
								if (!desc) {
									desc = "";
								}
								if (lst.indexOf(desc) < 0) {
									lst.push(desc);
								}
							});
							ent.WorkTimeBatch.description = lst.join('; ');
						}
						break;
					default:
						break;
				}
				if (broadcastChange && ent.WorkTimeBatch) {
					// For now, this dummy modification is needed to make sure that the parent WorkTimeBatch is also sent to server...
					ent.WorkTimeBatch.modified = true;
				}
			}

			if (broadcastChange) {
				// TODO: type checking should be done here...this is fine as long as we have only WorkTime and WorkTimeBatch entities
				ent.modified = true;
				// Shortcut for activating change flag - should (atleast at the moment) always be true when we are about to broadcast a property change.
				_hasChanges = true;
			}

			return broadcastChange;
		}

		// TODO: The individual field change propagation methods are somewhat brutal, causing lots of entity change events.
		//       We should somehow throttle/combine all batch level changes and update them to children in single pass
		//		 or at least with more lightweight operation
		function changeWtHours(entity, weekDayIndex, hours) {
			if (entity && entity.subrows) {
				entity.subrows.forEach(function(subrow) {
					if (subrow.weekDayIndex === weekDayIndex && subrow.hours !== hours) {
						subrow.hours = hours;
					}
				});
			}
		}

		function changeWtProjId(entity, projId) {
			if (entity && entity.subrows) {
				entity.subrows.forEach(function (subrow) {
					subrow.projId = projId;
				});
			}
		}

		function changeWtProjName(entity, projName) {
			if (entity && entity.subrows) {
				entity.subrows.forEach(function (subrow) {
					subrow.projName = projName;
				});
			}
		}

		function changeWtTaskId(entity, taskId) {
			if (entity && entity.subrows) {
				entity.subrows.forEach(function (subrow) {
					subrow.taskId = taskId;
				});
			}
		}

		function changeWtTaskName(entity, taskName) {
			if (entity && entity.subrows) {
				entity.subrows.forEach(function (subrow) {
					subrow.taskName = taskName;
				});
			}
		}

		function changeWtWorkId(entity, workId) {
			if (entity && entity.subrows) {
				entity.subrows.forEach(function (subrow) {
					subrow.workId = workId;
				});
			}
		}

		function changeWtWorkName(entity, workName) {
			if (entity && entity.subrows) {
				entity.subrows.forEach(function (subrow) {
					subrow.workName = workName;
				});
			}
		}

		function changeWtOperatId(entity, operatId) {
			if (entity && entity.subrows) {
				entity.subrows.forEach(function (subrow) {
					subrow.operatId = operatId;
				});
			}
		}

		function changeWtOperatName(entity, operatName) {
			if (entity && entity.subrows) {
				entity.subrows.forEach(function (subrow) {
					subrow.operatName = operatName;
				});
			}
		}

		function getEnumFromQ(enumId, language) {
			var source = 'dataContext.getEnum';
			var spinnerActivated = spinner.spinnerShow(source);
			var enumObj;

			// Initial implementation for server based data
			enumObj = EntityQuery
				.from('EnumItems')
				.withParameters({ enumId: enumId })
				.using(hourInputManager)
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						// The breeze query data is really in data.results member...
						// TODO: figure out if these services should return breeze query data or only the results...
						return data.results;
					}
				});

			function querySucceeded(data) {
				spinner.queryFinally(source, spinnerActivated)
				log('[' + enumId + '] ' + common.$translate.instant("STRCONST.LEANPORTAL.MSG_ENUMERATION_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
			}

			function queryFailed(data) {
				spinner.queryFinally(source, spinnerActivated)
				usersettings.errHandler('[' + enumId + '] ' + common.$translate.instant("STRCONST.LEANPORTAL.MSG_ENUMERATION_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '. ' + data.status, data);
			}

			return enumObj;
		}

		// TODO: Move the specific object creation routines to the repository "classes"
		function createWorkTimeBatch(proj, activity, work, operat, force, selectedDate, isEmptyBatch, description, eventType) {

			// If no project and no work given and we already have empty row and creation is not forced, do nothing...
			if (hasEmptyWorkTimeRow(moment.utc(selectedDate).startOf('isoWeek'), proj ? proj.id : '', activity ? activity.id : '', work ? work.id : '', operat ? operat.id : '', description) && !force) {
				return null;
			}

			var wtb = hourInputManager.createEntity('WorkTimeBatch', { rowId: zStorageConfig.config.newGuid() });

			if (isEmptyBatch) {
				wtb.initializing = true;
			}

			if (selectedDate) {
				wtb.eventDate = moment.utc(selectedDate).startOf('isoWeek').toDate();
			}

			wtb.projId = (proj ? proj.id : (work ? work.projId : ''));
			wtb.projName = (proj ? proj.name : (work ? work.projName : ''));
			wtb.taskId = (activity ? activity.id : (work ? work.taskId : ''));
			wtb.taskName = (activity ? activity.name : (work ? work.taskName : ''));
			wtb.projAl = (proj ? proj.projAl : (work ? work.projAl : ''));
			wtb.workId = work ? work.id : '';
			wtb.workName = work ? work.name : '';
			wtb.operatId = operat ? operat.id : '';
			wtb.operatName = operat ? operat.name : '';
			wtb.description = description ? description : null;
			wtb.eventType = eventType ? eventType : null;

			if (proj) {
				proj.r = true;
			}
			if (activity) {
				activity.r = true;
			}
			if (work) {
				work.r = true;
			}
			if (operat) {
				operat.r = true;
			}

			wtb.initializing = false;

			return wtb;
		}

		function hasEmptyWorkTimeRow(dt, projId, activityId, workId, operatId, description) {
			var retVal = false;

			var hourInputs = hourInputManager.getEntities(['WorkTimeBatch']); // all workTimeBatches in cache
			// Todo: this should be a function of the Breeze EntityManager itself
			hourInputs.forEach(function (entity) {
				//UrlParam generated default values count as empty row.
				if (moment.utc(entity.eventDate).isSame(moment.utc(dt)) && entity.projId == projId && entity.taskId == activityId && entity.workId == workId && entity.operatId == operatId && entity.description == description) {
					retVal = true;
				}
			});
			return retVal;
		}

		// Create an empty WorkTime (single day cell) entity
		function createWorkTime(workTimeBatch, withoutBatch, proj, activity, work, operat, date, asResWorRep) {
			if (withoutBatch) {
				var wt = hourInputManager.createEntity('WorkTime', { rowId: zStorageConfig.config.newGuid() });

				if (date) {
					wt.date = date;
				}
				
				wt.projId = (proj ? proj.id : (work ? work.projId : ''));
				wt.projName = (proj ? proj.name : (work ? work.projName : ''));
				wt.taskId = (activity ? activity.id : (work ? work.taskId : ''));
				wt.taskName = (activity ? activity.name : (work ? work.taskName : ''));
				wt.projAl = (proj ? proj.projAl : (work ? work.projAl : ''));
				wt.workId = work ? work.id : '';
				wt.workName = work ? work.name : '';
				wt.operatId = operat ? operat.id : '';
				wt.operatName = operat ? operat.name : '';
				wt.withoutBatch = true;
				wt.asResWorRep = asResWorRep ? asResWorRep : false;
			}
			else {
				// Note that we currently use a single WorkTime class instead of WorkTime and WorkTimeDay separated
				var wt = hourInputManager.createEntity('WorkTime', workTimeBatch);
			}
			return wt;
		}

		//create an empty ExtraSalaryCode entity child for WorkTime parent entity.
		function createExtraSalaryCode(wt, hi, salaryCode) {
			if (wt && (hi || salaryCode)) {
				var sc = hourInputManager.createEntity('ExtraSalaryCode', { WorkTime: wt });
				sc.parentRecId = wt.rowId;
				sc.hours = hi ? hi : 0;
				sc.salaryCode = salaryCode ? salaryCode : '';
			}
				//shouldn't happen, but return an ampty entity in that case. Not tested! 
			else {
				var sc = hourInputManager.createEntity('ExtraSalaryCode', {});
			}
			return sc;
		}

		// Delete a complete WorkTimeBatch row, removes all it's child entities also
		// TODO: Move the specific object deletion routines to the repository "classes"
		function deleteWorkTimeBatch(workTimeBatch) {
			if (workTimeBatch) {

				// Can not use
				for (var i = workTimeBatch.subrows.length - 1; i >= 0; i--) {
					deleteWorkTime(workTimeBatch.subrows[i]);
				}
				workTimeBatch.entityAspect.setDeleted();
				saveHourInputsToWip();
			}
		}

		// Mark single work time slot as deleted in entity manager
		function deleteWorkTime(workTime) {
			if (workTime) {
				workTime.entityAspect.setDeleted();
			}
		}

		// Dummy flag - not really used yet
		function areEntitiesLoaded(entityName, value) {
			return zStorage.areItemsLoaded(entityName, value);
		}

		// Save the whole EntityManager to local storage.
		function exportEntities(entityName) {

			// Currently no support for restricting entities - should investigate using the BreezeJS getEntityGraph
			// (http://www.breezejs.com/breeze-labs/getentitygraph)
			// entityName only used for flagging the internal "isLoaded" property for simple entity bookkeepping

			// Old way, was using directly the entity manager
			//return manager.exportEntities(entities);

			// New way, use the storage mechanism
			// TODO: 
			areEntitiesLoaded(entityName, true);
			zStorage.save(usersettings.getLocStoKey(workTimeBatchLocSKey));
		}

		// Load all entities from localStorage to EntityManager 
		function importEntities() {
			// TODO: Find out way for more ligthweight manager cache + localStorage merge
			// For now, we clear all manager cache, which is heavy operation but prevents 
			// "Added" (especially empty) rows from being duplicated in some scenarios.
			checkLS();
			clearCachedHourInputs(null, null);
			
			zStorage.load(usersettings.getLocStoKey(workTimeBatchLocSKey));
		}

		function clearLocalChanges() {
		    clearCache();
			zStorage.clear(usersettings.getLocStoKey(workTimeBatchLocSKey));
		}

		// Wrapper for getting hourinput data from localStorage
		// TODO: Should use zStorageWip for more lightweight handling
		function getHourInputsFromWip(fromLocalStorage) {
			var hourinputs;

			if (fromLocalStorage) {
				importEntities();
			}
			//hourinputs = manager.getEntities('WorkTimeBatch'); //  [breeze.EntityState.Added, breeze.EntityState.Modified, breeze.EntityState.Unchanged]);
			hourinputs = hourInputManager.getEntities('WorkTimeBatch', [breeze.EntityState.Added, breeze.EntityState.Modified, breeze.EntityState.Unchanged]);
			// We need to do this manually because not all "Breeze" changes are actually changes from the application point of view.
			resolveHasChanges();
			return hourinputs;
		}

		// Wrapper for saving hourinput data to localStorage
		// TODO: This is heavy operation, should use the zStorageWip mechanism for more lightweight WIP handling...
		function saveHourInputsToWip() {
			exportEntities(workTimeBatchLocSKey);
		}

		// Create an empty WorkTime (single day cell) entity
		function setFavourite(favObj, objectType) {
			var favEnt;
			if (favObj)	{
				favEnt = getFavEnt(favObj, objectType);
				favEnt.isFavourite = favObj.f;
				favEnt.favouriteDescription = favObj.al;
			}
			return favEnt != null ? favEnt.id : null;
		}
		
		function setFavDescription(favObj, objectType)
		{
			var favEnt;
			if (favObj)	{
				favEnt = getFavEnt(favObj, objectType);
				favEnt.favouriteDescription = favObj.al;
			}
			return favEnt != null ? favEnt.id : null;
		}

		function getFavEnt(favObj, objectType)	{
			var favEnt, favEntKey;
			if (favObj) {
				favEntKey = favObj.fKey;
				if (favEntKey) {
					favEnt = hourInputManager.getEntityByKey('Favourite', favEntKey);
					if (favEnt && favEnt.entityState == breeze.EntityState.Detached) {
						favEnt = null;
					}
				}
				if (!favEnt) {
					favEnt = hourInputManager.createEntity('Favourite', { id: breeze.core.getUuid() });
					favEnt.objectType = objectType;
					favEnt.objectRecId = favObj.recId;
					favEnt.isFavourite = favObj.f;
				}
			}
			return favEnt;
		}

		function clearFavourites() {
		    var favs = hourInputManager.getEntities('Favourite');

			if (favs && favs.length > 0) {
				favs.forEach(function(fav) { fav.entityAspect.setDetached(); });
			}
			resolveHasChanges();
		}

		function getFlexHourCountFromDb(selEmpId) {
			var lst = getFlexHourCountFromDbQ(selEmpId);
			return $q.when(lst);
		};

		function getFlexHourCountFromDbQ(selEmpId) {
			var source = 'getFlexHourCount';
			var spinnerActivated = spinner.spinnerShow(source);
			var flexObj;
			flexObj = EntityQuery
				.from('FlexHourCount')
				.withParameters({selEmpId: selEmpId })
				.using(hourInputManager)
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						return data.results;
					}
				});
			function querySucceeded(data) {
				spinner.queryFinally(source, spinnerActivated)
				//
			}
			function queryFailed(data) {
				spinner.queryFinally(source, spinnerActivated)
				usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_HOURINPUT_FLEXHRS_QUERY_ERR"), data);
			}
			return flexObj;
		}

		/////////////////////////////////////////////////////////////////////
		// WIP PoC functions, not yet used...
		/*
		function getWorkTimeBatchesAndWipMerged(dateFrom, dateTo)
		{

		}

		function getWorkTimeBatchesFromWip() {
			var wip = zStorageWip.getWipSummary();
			var wipItems = wip.filter(function (item) {
				return item.entityName === 'WorkTimeBatch' && item.id === id;
			});
			return wipItem ? wipItem.key : null;
		}

		function getEntityByIdOrFromWip(rowId) {
			// val could be an ID or a wipKey
			var wipKey = val;

			if (common.isNumber(val)) {
				val = parseInt(val);
				wipEntityKey = this.zStorageWip.findWipKeyByEntityId(this.entityName, val);
				if (!wipEntityKey) {
					// Returns a promise with the entity because 
					// the entity may be in storagelocal or remote (async). 
					return this._getById(this.entityName, val);
				}
			}

			var importedEntity = this.zStorageWip.loadWipEntity(wipEntityKey);
			if (importedEntity) {
				// Need to re-validate the entity we are re-hydrating
				importedEntity.entityAspect.validateEntity();
				return $q.when({ entity: importedEntity, key: wipEntityKey });
			}
			return $q.reject({ error: 'Couldn\'t find entity for WIP key ' + wipEntityKey });
		}
		*/
		/////////////////////////////////////////////////////////////////////

		// Get the hour inputs from database using async Q promise
		// 
		function getHourInputQ(dateFrom, dateTo, overWriteLocalChanges, selEmpId) {

			//Remove timezone info to prevent row merge/duplication problems in some timezones.
			dateFrom = dateFrom.format('YYYY-MM-DD');
			dateTo = dateTo.format('YYYY-MM-DD');

			var source = 'getHourInput';
			var spinnerActivated = spinner.spinnerShow(source);
			var hiObj;
			var self = this;

			// TODO: Make sure we are not going to clear the cache in any scenario where there is legitimate but unsaved changes in there
			if (overWriteLocalChanges) {
				// At the moment, until the "merge after update" is implemented, we need to reset all the client cache regardless of the time span parameters!!!
				// TODO: Fix "merge after update"
				clearCachedHourInputs(null, null);
				//clearCachedHourInputs(dateFrom, dateTo);
			}
			
			// Initial implementation for server based data
			hiObj = EntityQuery
				.from('WorkTimeBatches')
				.withParameters({ dateFrom: dateFrom, dateTo: dateTo, selEmpId: selEmpId })
				.using(hourInputManager)
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						// Remove given period entities which were not found in the result.
						clearCachedHourInputs(dateFrom, dateTo, data.results);
						// TODO: Figure out when actually to call this (e.g. could there be pending changes which have not been saved to database yet???)
						//exportEntities('worktimebatches');
						saveHourInputsToWip();
						// The work time batches results now contains Breeze entities, not just plain JSON objects...
						return data.results;
					}
				});

			function querySucceeded(data) {
				spinner.queryFinally(source, spinnerActivated)
				log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_HOURINPUT_QUERY") + ' ' + moment.utc(dateFrom).local().format('l') + ' - ' + moment.utc(dateTo).local().format('l') + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
			}

			function queryFailed(data) {
				spinner.queryFinally(source, spinnerActivated)
				usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_HOURINPUT_QUERY") + ' ' + moment.utc(dateFrom).local().format('l') + ' - ' + moment.utc(dateTo).local().format('l') + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + ' ' + data.status, data);
			}

			return hiObj;
		}

		// Public wrapper for getting hour inputs
		function getHourInput(dateFrom, dateTo, overWriteLocalChanges, selEmpId) {
			var lst = getHourInputQ(dateFrom, dateTo, overWriteLocalChanges, selEmpId);
			return $q.when(lst);
		};
		
		/**
		 * Gets enumerations from the database.
		 * @param {Language used for enumeration names.} language 
		 * @param {Set to true if query from database is always used.} forceRefresh 
		 * @param {selected employee} selEmpId
		 * @returns {} 
		 */
		function getLookups(language, forceRefresh, selEmpId) {
			var localStoragePrefix = '';
			var lsid = usersettings.getLocStoKey(localStoragePrefix + 'Lookups_' + language, localStoragePrefix);
			var lst = [];
			if (localStorage && localStorage.getItem(lsid) && common.$rootScope.isWorkingLocally) {
				lst = JSON.parse(localStorage.getItem(lsid));
			}
			else {
				// Caching mechanism to avoid mutiple identical DB requests
				var cacheKey = ['getLookups', localStoragePrefix, language];
				if (!forceRefresh) {
					var cachePromise = common.getCachedData(cacheKey);
					if (cachePromise) {
						return cachePromise;
					}
				}
				lst = getLookupsFromQ(cacheKey, selEmpId);
			}
			return $q.when(lst);
		}

		/**
		 * Get enumerations etc. from the database.
		 * @param {Manager name (optional). If not specified common manager is used.} manager 
		 * @returns {} 
		 */
		function getLookupsFromQ(cacheKey, selEmpId) {
			var source = 'getLookups';
			var spinnerActivated = spinner.spinnerShow(source);
			var queryPromise = EntityQuery
				.from('Lookups')
				.withParameters({ selEmpId: selEmpId })
				.using(hourInputManager)
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						if (cacheKey) {
							common.setCachedData(cacheKey, data.results);
						}
						return data.results;
					}
				});

			function querySucceeded(data) {
				log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_ENUMERATION_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
				spinner.queryFinally(source, spinnerActivated)
			}

			function queryFailed(data) {
				spinner.queryFinally(source, spinnerActivated)
				usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_ENUMERATION_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '. ' + data.status, data);
			}

			return queryPromise;
		}

		function getProjectsFromQ(searchString, favAndRec, projOnly, activitySearchString, workSearchString, selEmpId, selDep) {
			var source = 'getProjects';
			var spinnerActivated = spinner.spinnerShow(source);
			var projObj;
		    var fromDate = moment.utc().subtract(4, 'weeks');

		    // Initial implementation for server based data
		    projObj = EntityQuery
				.from('Projects')
				.withParameters({ searchString: searchString, activitySearchString: activitySearchString, workSearchString: workSearchString ? workSearchString : '', favAndRec: favAndRec, projOnly: projOnly, fromDate: fromDate, rowCount: 1700, selEmpId: selEmpId, selDep: selDep })
				.using(hourInputManager)
				.noTracking()			// This is needed unless we want breeze to keep tracking of project objects...
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
				    if (data) {
				        // The breeze query data is really in data.results member...
				        // TODO: figure out if these services should return breeze query data or only the results...
				        return data.results;
				    }
				});

		    function querySucceeded(data) {
		    	spinner.queryFinally(source, spinnerActivated)
		    	log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_PROJ_QUERY") + (searchString == '' && activitySearchString == '' ? '' : ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_WITH_SEARCH_STRING") + ' \'' + searchString + ' ' + activitySearchString + '\'') + (favAndRec ? ' (' + (common.$translate.instant("STRCONST.LEANPORTAL.MSG_FAV_AND_RECENT_ONLY")).toLowerCase() + ') ' : ' ') + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
		    }

		    function queryFailed(data) {
		    	spinner.queryFinally(source, spinnerActivated)
		    	usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_PROJ_QUERY") + (searchString == '' && activitySearchString == '' ? '' : ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_WITH_SEARCH_STRING") + '\'' + searchString + ' ' + activitySearchString + '\'') + (favAndRec ? ' (' + (common.$translate.instant("STRCONST.LEANPORTAL.MSG_FAV_AND_RECENT_ONLY")).toLowerCase() + ') ' : ' ') + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '. ' + data.status, data);
		    }

		    return projObj;
		}

		function getProjects(searchString, favAndRec, projOnly, activitySearchString, workSearchString, empId, selDep) {
		    var luid = usersettings.getLocStoKey('LastUserInfo', true);
		    var lst = [];
		    if (common.$rootScope.isWorkingLocally && localStorage && localStorage.getItem(luid)) {
		        empId = empId ? empId : JSON.parse(localStorage.getItem(luid)).userId;
		        var company = JSON.parse(localStorage.getItem(luid)).company;
		        var opu = JSON.parse(localStorage.getItem(luid)).opu;
		        if (empId) {
		        	var lsid = usersettings.getLocStoKey('Projects', false, false, false, false, empId);
		        }
				else{
		        	var lsid = usersettings.getLocStoKey(company + "_" + opu + "_" + empId + '_Projects', true);
		        }
		        if (localStorage.getItem(lsid)) {
		            lst = JSON.parse(localStorage.getItem(lsid));
		        }
		    }
		    else {
		    	lst = getProjectsFromQ(searchString, favAndRec, projOnly, activitySearchString, workSearchString, empId, selDep);
			}
			return $q.when(lst);
		};

        function getWorksFromQ(searchString, favAndRec, workId, resId, searchSerialId) {
			workId = workId || '';
			resId = resId || '';
            searchSerialId = searchSerialId || false;
			var source = 'getWorks';
			var spinnerActivated = spinner.spinnerShow(source);
			var workObj;
			
			// Initial implementation for server based data
			workObj = EntityQuery
				.from('Works')
                .withParameters({ searchString: searchString, favAndRec: favAndRec, workId: workId, resId: resId, searchSerialId: searchSerialId })
				.using(hourInputManager)
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						// The breeze query data is really in data.results member...
						// TODO: figure out if these services should return breeze query data or only the results...
						return data.results;
					}
				});

			function querySucceeded(data) {
				spinner.queryFinally(source, spinnerActivated)
				log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_WORKS_QUERY") + (workId ? ' (' + workId + ') ' : '') + (searchString == '' ? '' : ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_WITH_SEARCH_STRING") + ' \'' + searchString + '\'') + (favAndRec ? ' (' + (common.$translate.instant("STRCONST.LEANPORTAL.MSG_FAV_AND_RECENT_ONLY")).toLowerCase() + ') ' : ' ') + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
			}

			function queryFailed(data) {
				spinner.queryFinally(source, spinnerActivated)
				usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_WORKS_QUERY") + (workId ? ' (' + workId + ') ' : '') + (searchString == '' ? '' : ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_WITH_SEARCH_STRING") + '\'' + searchString + '\'') + (favAndRec ? ' (' + (common.$translate.instant("STRCONST.LEANPORTAL.MSG_FAV_AND_RECENT_ONLY")).toLowerCase() + ') ' : ' ') + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '. ' + data.status, data)
			}

			return workObj;
		}
		
        function getWorks(userId, searchString, favAndRec, workId, resId, searchSerialId) {
			var luid = usersettings.getLocStoKey('LastUserInfo', true);
		    var lst = [];
			if (common.$rootScope.isWorkingLocally && localStorage && localStorage.getItem(luid)) {
			    userId = userId ? userId : JSON.parse(localStorage.getItem(luid)).userId;
			    var company = JSON.parse(localStorage.getItem(luid)).company;
			    var opu = JSON.parse(localStorage.getItem(luid)).opu;
			    if (userId) {
			    	var lsid = usersettings.getLocStoKey('Works', false, false, false, false, userId);
			    }
			    else {
			    	var lsid = usersettings.getLocStoKey(company + "_" + opu + "_" + userId + '_Works', true);
			    }
			    if (localStorage.getItem(lsid)) {
			        lst = JSON.parse(localStorage.getItem(lsid));
			    }
			}
			else {
                lst = getWorksFromQ(searchString, favAndRec, workId, resId, searchSerialId);
			}
			return $q.when(lst);
		}

		function getWorkTimeSummariesFromQ(paramsObj) {
			var source = "getWorkTimeSummaries";
			var spinnerActivated = spinner.spinnerShow(source);
			var wtsObj = EntityQuery
				.from("WorkTimeSummaries")
				.withParameters(paramsObj)
				.using(hourInputManager)
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						return data.results
					}
				});

			function querySucceeded(data) {
				spinner.queryFinally(source, spinnerActivated);
				//TODO: Add log
			}

			function queryFailed(data) {
				spinner.queryFinally(source, spinnerActivated);
				usersettings.errHandler("Query failed", data); //TODO: Add string constant for message
			}
			return wtsObj;
		}

		function getWorkTimeSummaries(paramsObj) {
			var result = [];
			result = getWorkTimeSummariesFromQ(paramsObj);
			return $q.when(result)
		}

        function getWorkTimeApprovalsFromQ(searchString, filterString, selUser, selRole, sortCmd, restrictionCmd, fromDate, toDate) {
            var source = 'getWorkTimeApprovals';
            var spinnerActivated = spinner.spinnerShow(source);
            var wtaObject;

            wtaObject = EntityQuery
                .from('WorkTimeApprovals')
                .withParameters({
                    searchString: searchString, filterString: filterString,
                    selectedPerson: selUser, selectedRole: selRole,
                    sortCmd: sortCmd, restrictionCmd: restrictionCmd,
                    fromDate: fromDate, toDate: toDate
                })
                .using(hourInputManager)
                .execute(querySucceeded, queryFailed)
                .then(function (data) {
                    if (data) {
                        return data.results;
                    }
                });

            function querySucceeded(data) {
                spinner.queryFinally(source, spinnerActivated);
                log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_HOURINPUT_QUERY") + ' ' + moment.utc(fromDate).local().format('l') + ' - ' + moment.utc(toDate).local().format('l') + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
            }
            function queryFailed(data) {
                spinner.queryFinally(source, spinnerActivated);
                usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_HOURINPUT_QUERY") + ' ' + moment.utc(fromDate).local().format('l') + ' - ' + moment.utc(toDate).local().format('l') + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
            }
            return wtaObject;
        }

        function getWorkTimeApprovals(searchString, filterString, selUser, selRole, sortCmd, restrictionCmd, fromDate, toDate) {
            var lst = [];
            lst = getWorkTimeApprovalsFromQ(searchString, filterString, selUser, selRole, sortCmd, restrictionCmd, fromDate, toDate);
            return $q.when(lst);
        }

        function getApprovalRolesFromQ() {
            var source = 'getApprovalRoles';
            var spinnerActivated = spinner.spinnerShow(source);
			var roleList;
			roleList = EntityQuery
                .from('ApprovalRoles')
                .using(hourInputManager)
                .execute(querySucceeded, queryFailed)
                .then(function (data) {
                    if (data) {
                        return data.results;
                    }
                });
            function querySucceeded(data) {
                spinner.queryFinally(source, spinnerActivated);
                log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_ACCEPTANCEROLES_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
            }
            function queryFailed(data) {
                spinner.queryFinally(source, spinnerActivated);
                usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_ACCEPTANCEROLES_QUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
			}
			return roleList;
        }

        function getApprovalRoles() {
            var lst = [];
            lst = getApprovalRolesFromQ();
            return $q.when(lst);
		}

		function getApprovalUsers(roleId) {
			var source = 'getApprovalUsers';
			var spinnerActivated = spinner.spinnerShow(source);
			var userList = EntityQuery
				.from('ApprovalUsers')
				.using(hourInputManager)
				.withParameters({ roleId: roleId })
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					if (data) {
						return data.results[0].list;
					}
				});

			function querySucceeded(data) {
				spinner.queryFinally(source, spinnerActivated);
				log(common.$translate.instant("STRCONST.LEANPORTAL.TXT_USERQUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
			}

			function queryFailed(data) {
				spinner.queryFinally(source, spinnerActivated);
				usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.TXT_USERQUERY") + ' ' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data);
			}

			return userList;
		}

		function clearCachedHourInputs(dateFrom, dateTo, data) {
			// For now, just clear the cache from the same time span it was queried from database
			// TODO: This might result in stale data so maybe a more brutal manager.clear() will be needed
		    // (accompanied with a foolproof logic to prevent loss of changes when e.g. changing from offline to online)
		    var cachedHourInputs = hourInputManager.getEntities(['WorkTime', 'WorkTimeBatch']); // all WorkTimeBatches and WorkTimes in cache
			// Todo: this should be a function of the Breeze EntityManager itself

			// Get worktime entity rowId's from data to the list for fast lookups. Here worktimes are subrows of worktimebatches.
		    var workTimeEntityIds = [];
		    if (data) {
		    	data.forEach(function (entity) {
		    		if (entity.entityType === hourInputManager.metadataStore.getEntityType('WorkTimeBatch') && entity.subrows) {
		    			entity.subrows.forEach(function (ent) {
		    				workTimeEntityIds.push(ent.rowId.replace(';', '-'));
		    			});
		    		}
		    	});
		    }

			cachedHourInputs.forEach(function (entity) {
			    var date = moment.utc(entity.date == undefined ? null : entity.date);
			    var eventDate = moment.utc(entity.eventDate == undefined ? null : entity.eventDate);
			    dateFrom = moment.utc(dateFrom == undefined ? null : dateFrom);
			    dateTo = moment.utc(dateTo == undefined ? null : dateTo);

			    if ((!dateFrom.isValid() && !dateTo.isValid())
					|| (date && date.isSameOrAfter(dateFrom) && date.isSameOrBefore(dateTo))
					|| (eventDate && eventDate.isSameOrAfter(dateFrom) && eventDate.isSameOrBefore(dateTo))) {
					if (data) {
						// Detach not new entities which are not found in data.
						// Also entities which are marked as deleted should not be detached as this causes them to be reloaded from db with entity state unchanged.
						if (workTimeEntityIds.length > 0 && entity.entityAspect.entityState != breeze.EntityState.Added && entity.entityAspect.entityState != breeze.EntityState.Deleted && workTimeEntityIds.indexOf(entity.rowId.replace(';', '-')) < 0) {
							hourInputManager.detachEntity(entity);
						}
					} else {
						hourInputManager.detachEntity(entity);
					}
				}
			});
		}

		function clearCache() {
		    var cachedEntities = hourInputManager.getEntities();
		    cachedEntities.forEach(function (entity) { hourInputManager.detachEntity(entity); });
		}

		// TODO: This is not yet used, needs testing! Cloning hour input rows is now done by hand (looping subrows/fields manually)
		function cloneItem(item, collectionNames) {
		    var hourInputManager = item.entityAspect.entityManager;
			// export w/o metadata and then parse the exported string.
		    var exported = JSON.parse(hourInputManager.exportEntities([item], false));
			// extract the entity from the export
			var type = item.entityType;
			var copy = exported.entityGroupMap[type.name].entities[0];
			// remove the entityAspect (todo: remove complexAspect from nested complex types)
			delete copy.entityAspect;
			// remove the key properties (assumes key is store-generated)
			type.keyProperties.forEach(function(p) { delete copy[p.name]; });

			// the "copy" provides the initial values for the create
			var newItem = hourInputManager.createEntity(type, copy);

			if (collectionNames && collectionNames.length) {
				// can only handle parent w/ single PK values
				var parentKeyValue = newItem.entityAspect.getKey().values[0];
				collectionNames.forEach(copyChildren);
			}
			return newItem;

			function copyChildren(navPropName) {
				// todo: add much more error handling
				var navProp = type.getNavigationProperty(navPropName);
				if (navProp.isScalar) return; // only copies collection navigations. Todo: should it throw?

				// This method only copies children (dependent entities), not a related parent
				// Child (dependent) navigations have inverse FK names, not FK names
				var fk = navProp.invForeignKeyNames[0]; // can only handle child w/ single FK value
				if (!fk) return;

				// Breeze `getProperty` gets values for all model libraries, e.g. both KO and Angular
				var children = item.getProperty(navPropName);
				if (children.length === 0) return;

				// Copy all children
				var childType = navProp.entityType;
				children = JSON.parse(hourInputManager.exportEntities(children, false));
				var copies = children.entityGroupMap[childType.name].entities;

				copies.forEach(function(c) {
					delete c.entityAspect;
					// remove key properties (assumes keys are store generated)
					childType.keyProperties.forEach(function(p) { delete c[p.name]; });
					// set the FK parent of the copy to the new item's PK               
					c[fk] = parentKeyValue;
					// merely creating them will cause Breeze to add them to the parent
					hourInputManager.createEntity(childType, c);
				});
			}
		}

		function getEmployees(supervisorId) {
			var source = 'getEmployees';
			var spinnerActivated = spinner.spinnerShow(source);
			var resObj = EntityQuery
				.from('GetEmployees')
				.withParameters({ supervisorId: supervisorId })
				.using(hourInputManager)
				.noTracking()
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					spinner.spinnerHide();
					if (data) {
						return data.results;
					}
				});

			function querySucceeded(data) {
				spinner.queryFinally(source, spinnerActivated);
				log(common.$translate.instant('STRCONST.LEANPORTAL.TXT_EMPLOYEEQUERY') + ' ' + common.$translate.instant('STRCONST.LEANPORTAL.TXT_SUCCESS') + '.', data, true);
			}

			function queryFailed(data) {
				spinner.queryFinally(source, spinnerActivated);
				usersettings.errHandler(common.$translate.instant('STRCONST.LEANPORTAL.TXT_EMPLOYEEQUERY') + ' ' + common.$translate.instant('STRCONST.LEANPORTAL.TXT_FAILURE') + '.', data.status);
			}

			return resObj;
		}

        function getEmpResRoles(formId, formVersion, resId, endOfWeek) {
			var lst = [];
            lst = getEmpResRolesFromQ(formId, formVersion, resId, endOfWeek);
			return $q.when(lst);
		}
        function getEmpResRolesFromQ(formId, formVersion, resId, endOfWeek) {
			var source = 'getEmpResRoles';
			var spinnerActivated = spinner.spinnerShow(source);
			var resObj;
			resObj = EntityQuery
				.from('GetEmpResRoles')
                .withParameters({ formId: formId, formVersion: formVersion, resId: resId, endOfWeek: endOfWeek })
				.using(hourInputManager)
                .noTracking()
				.execute(querySucceeded, queryFailed)
				.then(function (data) {
					spinner.spinnerHide();
					if (data) {
						return data.results;
					}
				});

			function querySucceeded(data) {
				spinner.queryFinally(source, spinnerActivated)
				log(common.$translate.instant("STRCONST.LEANPORTAL.MSG_EMP_RESROLES_QUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SUCCESS") + '.', data, true);
			}
			function queryFailed(data) {
				spinner.queryFinally(source, spinnerActivated)
				usersettings.errHandler(common.$translate.instant("STRCONST.LEANPORTAL.MSG_EMP_RESROLES_QUERY") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_FAILURE") + '.', data.status);
			}
			return resObj;
		}

		// A subrow id generator needed because WorkTime entities can be placed under a different week day parent
		// and depending on order of empty slot generation there can be generated multiple <ROWID>-<WEEKDAYINDEX> -keys with same values
		// TODO: replace with breeze custom autogenerate key...
		function getNextSubrowId(workTimeBatch, weekDayIndex) {
			var wtbRecId = workTimeBatch.rowId.split(';')[0];
			var idFound = false;
			var tmpId = null;
			var tmpIdx = weekDayIndex;
			
			while (!idFound) {
				tmpId = wtbRecId + "-" + tmpIdx;

				if (hourInputManager.getEntityByKey('WorkTime', tmpId)) {
					tmpIdx = tmpIdx + 10;
				} else {
					return tmpId;
				}
			}
		}

		function zStorageRefresh() { //This has to be called in controller activates to prevent multiple manager mixups in zStorage.
			zStorage.init(hourInputManager);
			zStorageWip.init(hourInputManager);
		}
	}
export default hiDatacontextmodule;
