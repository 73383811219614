
    'use strict';
    var app = angular.module('common');
    app.value('backendServerUrl', applicationPath);
    app.factory('signalRManager', ['$rootScope', '$q', 'usersettings', 'common', 'backendServerUrl',
      function ($rootScope, $q, usersettings, common, backendServerUrl) {
          var getLogFn = common.logger.getLogFn;
          var log = getLogFn('SignalR');
          var warnMsg = getLogFn('SignalR', 'warning');

          var callbackData = { counter: 0 };
          var connection;
          var services = {
              backEndFactory: backendFactory,
              getLastCallback: getLastCallback,
              getConnectionStatus: getConnectionStatus
          }
          return services;

          function backendFactory(serverUrl, hubName, stateParams) {
              var deferred = $q.defer();
              connection = $.hubConnection(backendServerUrl);
              var proxy = connection.createHubProxy(hubName);
              connection.logging = true;
              proxy.on('eventName', function () { });
              angular.forEach(stateParams, function (value, key) {
                  proxy.state[key] = value;
			  });
			  var useSignalR
			  common.appSettingReady().then(function (data) {
				  useSignalR = common.getAppSetting("UseSignalR", "0");
			  })
              usersettings.getLoginPromise().then(function () {
                  var oauthData = usersettings.getOAuthData();
                  if (oauthData) {
                      connection.qs = { 'access_token': oauthData.access_token };
                      if (useSignalR == '1') {
                          connection.start()
                              .done(function () {
                                  deferred.resolve();
                              })
                              .fail(function (e) {
                                  usersettings.errHandler("SignalR could not connect!", e.context);
                              });;
                      }
                  }
              });

              connection.error(function (error) {
              	warnMsg(common.$translate.instant("STRCONST.LEANPORTAL.AUTO_REFRESH_DISABLED"), error, "SignalR", true);
              });

              connection.disconnected(function (message) {
              	warnMsg(common.$translate.instant("STRCONST.LEANPORTAL.AUTO_REFRESH_DISABLED"), message, "SignalR", true);
              });

              return {
                  on: function (eventName, callback) {
                      proxy.on(eventName, function (result) {
                          $rootScope.$apply(function () {
                              if (callback) {
                                  common.debouncedThrottle("SIGNALR", setLastCallback(result), 2000); //TODO: Differentiate between different callbacks.
                                  callback(result);
                              }
                          });
                      });
                  },
                  invoke: function (methodName, params, callback) {
                      if (!connection.lastError && getConnectionStatus()) {
                          proxy.invoke(methodName, params)
                          .done(function (result) {
                              $rootScope.$apply(function () {
                                  if (callback) {
                                      callback(result);
                                  }
                              });
                          })
                          .fail(function (error) {
                              console.log(error);
                          });
                      }
                  },
                  connection: connection,
                  connectionReady: deferred.promise
              };

              function setLastCallback(result) { //TODO: Differentiate between different callbacks.
                  callbackData.result = result;
                  callbackData.counter = callbackData.counter + 1;
              }

          }

          function getLastCallback() {
              return callbackData;
          }

          function getConnectionStatus() {
              if (connection && connection.state == 1) { //0: 'connecting', 1: 'connected', 2: 'reconnecting', 4: 'disconnected'
                  return connection.state;
              }
              return 0;
          }
      }])
export default app