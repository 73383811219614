
	'use strict';
	var controllerId = 'feedctrl';

	var app= angular.module('app').directive('dfxFeed', [function () {
		return {
			restrict: 'E',
			scope: {},
			controller: controllerId,
			controllerAs: 'ctrl',
			bindToController: {
				// object with objectType, recId, id and name properties
				parent: '=',
				formId: '=',
				sortCmd: '=?',
				qltDataRows: '=?',
                feedType: '=?',
				onControllerCallback: '&'
			},
			template: require('./feed.html')
		}
	}]);

	angular.module('app').controller(controllerId, ['$scope', 'commonDatacontext', feed]);

	function feed($scope, commonDatacontext) {
		var ctrl = this;
		ctrl.contentAvailable = false;
		ctrl.count = 0;
		ctrl.docCount = 0;
		ctrl.feed = [];
		ctrl.objects = [];

		// Different UI modules 
		ctrl.ModuleEnum = {
			DOC: { id: 'DOC', controller: null }
		}

		// API offered for the client with onControllerCallback
		var service = {
			triggerLoading: getFeed,
			getChildModules: ctrl.ModuleEnum
		}
        ctrl.$onInit = function () {
            ctrl.onControllerCallback({ childCtrl: service });
        };

		/**
         * ctrl.objects contains Array of objects with objectType, recId, id and name
         */
		$scope.$watch('ctrl.parent', function (parent) {
			getFeed(true);
		});

		/**
          * Child directives inform that item count has been changed.
          */
		ctrl.docCountChanged = function (count) {
			ctrl.docCount =+ count;
		}

		/**
		 * Child module controller registers itself for easier manipulation
		 */
		ctrl.registerChildController = function (moduleEnum, childCtrl) {
			moduleEnum.controller = childCtrl;
		}

		/**
         * Gets feed for objects. 
		 * @param {Is the request coming from user (true) or from background process (false).} requestedByUi 
         */
		function getFeed(requestedByUi) {
			if (!ctrl.parent || typeof ctrl.parent.feedRestrictData != 'function' || !ctrl.formId) {
				ctrl.contentAvailable = false;
				return;
			}
            var feedRestrictData = ctrl.parent.feedRestrictData();
            if (ctrl.feedType) {
                feedRestrictData.feedType = ctrl.feedType;

            }
            commonDatacontext.getFeed(ctrl.formId, ctrl.sortCmd, feedRestrictData, requestedByUi).then(function (data) {
				ctrl.feed = data;
				ctrl.contentAvailable = (data.length > 0);
				ctrl.count = data.length;
			});
		}

		ctrl.getGraphTitle = function (feedItem) {
			return feedItem.text3 || feedItem.objectName2 ? feedItem.text3 + ' ' + feedItem.objectName2 + ' / ' : null;
		}

		activate();

		$scope.$on('COMPANYOPUCHANGES', function () {
			activate();
		});

		function activate() {
			ctrl.feed = [];
			ctrl.docCount = 0;
		}
	}
export default app


