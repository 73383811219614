
	'use strict';
	var controllerId = 'feedCtrl';

	var app= angular.module('app').controller(controllerId, ['$scope', '$controller', '$location', 'manDatacontext', 'common', feedCtrl]);

	function feedCtrl($scope, $controller, $location, manDatacontext, common) {
		angular.extend(this, $controller('baseController', { $scope: $scope }));
		var ctrl = this;
		var feedCtrl = null;
		ctrl.parent = null;
		ctrl.formId = null;

		activate();

		ctrl.registerChildController = function (childCtrl) {
			feedCtrl = childCtrl;
		}

		function activate() {
			common.$broadcast('FORCETOPBANNERHIDE');

			ctrl.base.activate().then(function (succeeded) {
				var workId = $location.search().workId;
				var operatId = $location.search().operatId;

				if (workId && operatId) {
					manDatacontext.getResourceTask(workId, operatId).then(function (data) {
						if (data && data.length > 0) {
							ctrl.parent = data[0];
							ctrl.formId = '25118';
						}
					});
				}
				else if (workId) {
					manDatacontext.getResourceWork(workId, true).then(function (data) {
						if (data && data.length > 0) {
							ctrl.parent = data[0];
							ctrl.formId = '25118';
						}
					});
				}
			});

		}
	}
export default app


