
	'use strict';

	// Must configure the common service and set its 
	// events via the commonConfigProvider

	var spinnerModule= angular.module('common')
		.factory('spinner', ['common', 'commonConfig', '$timeout', spinner]);

	function spinner(common, commonConfig, $timeout) {
		var count = 0;
		var spinnerActive = false;

		var service = {
			spinnerHide: spinnerHide,
			spinnerReset: spinnerReset,
			spinnerShow: spinnerShow,
			queryFinally: queryFinally
		};

		return service;


		function queryFinally(source, spinnerActivated) {
			return spinnerHide(source, spinnerActivated);
		}

		function spinnerHide(source, spinnerActivated) {
			if (spinnerActivated == undefined || spinnerActivated== true) {
				spinnerToggle(source, false);
			}
		}

		function spinnerReset() {
			count = 0;
			spinnerActive = false;
			common.$broadcast(commonConfig.config.spinnerToggleEvent, { show: false });
			//console.log('spinner reset');

		}

		/**
		 * 
		 * @param {showSpinner if false, no spinner is shown.} showSpinner 
		 * @returns {true if spinner is activated} 
		 */
		function spinnerShow(source, showSpinner, changedEntities, delay) {
			// If spinner explicitely not wanted, then return 
			if (showSpinner == false) {
				return false;
			}
			var spinnerDelay = !isNaN(delay) ? delay : 600;
			if (changedEntities) {
				// If there are added entities, then the spinner is shown without delay 
				for (var i = 0; i < changedEntities.length; i++) {
					if (changedEntities[i].entityAspect.entityState == breeze.EntityState.Added) {
					    spinnerDelay = 0;
						break;
					}
				}
			}
			spinnerToggle(source, true, spinnerDelay);
			return true;
		}

		function spinnerToggle(source, show, delay) {
			if (show) {
				count++;
				//console.log('++spinner counter=>' + count + ': ' + source);
				if (!spinnerActive) {
					$timeout(function() {
						// count might have changed after the delay!
						if (count > 0 && !spinnerActive) {
							common.$broadcast(commonConfig.config.spinnerToggleEvent, { show: show });
							spinnerActive = show;
						}
					}, delay);
				}
			} else {
				if (count == 0) {
					// Don't allow negative values!
					console.warn('Spinner counter subtracted when being already 0 ' + source + ' - Discarded!');
				}
				else {
					count--;
					//console.log('--spinner counter=>' + count + ': ' + source);
				}
				$timeout(function () {
					// count might have changed after the delay!
					if (count == 0 && spinnerActive) {
						common.$broadcast(commonConfig.config.spinnerToggleEvent, { show: show });
						spinnerActive = false;
					}
				}, 600);
			}
		}
	}
export default spinnerModule