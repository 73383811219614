'use strict';
var layoutSelectionModule = angular.module('dir.dfxLayoutSelection',[]);
/**
 * Page layout button group
 */
layoutSelectionModule.directive('dfxLayoutSelection', function () {
    // Provides  buttongroup to change layout
    return {
        restrict: 'E', //E = element, A = attribute, C = class, M = comment 
        scope: {},
        controllerAs: 'ctrl',
        bindToController: {
            layout: '=',
            onChangedCallback: '&'
        },
        controller: ['$scope', function ($scope) {
            var ctrl = this;

            ctrl.setLayout = function (value) {
                ctrl.onChangedCallback({ layout: value });
            }
        }],
        template: require('./dfxLayoutSelection.html')
    };
});
export default layoutSelectionModule