'use strict'
var serialNrCtrlId = 'serialnumbersctrl';
var serialNumbersModule=angular.module('manudir.dfxSerialNumbers',[])
serialNumbersModule.directive('dfxSerialNumbers', [function () {
	return {
		restrict: 'E',
		scope: {},
		controller: serialNrCtrlId,
		controllerAs: 'ctrl',
		bindToController: {
			maxItems: '=',
			serialNumbersOrig: '=serialNumbers',
			itemId: '=',
			onControllerCallback: '&',
			onRequestToggle: '&',
			onFillEntity: '&',
			onSavedCallback: '&',
			onRemovedCallback: '&',
			simplifiedLayout: '=?',
			hideExtSerialId: '=?',
			forceListSelection: '=?'
		},
		template: require('./dfxSerialNumbers.html')
	}
}]);
serialNumbersModule.controller(serialNrCtrlId, ['$scope', 'common', 'manDatacontext', serialnumbers])
function serialnumbers($scope, common, manDatacontext) {
	var ctrl = this;


	ctrl.getRepeatableArray = function (num) {
		return new Array(num);
	}

	ctrl.newSerialNumber = null;
	// editSerialNumber is null if the focus is in addition
	$scope.editSerialNumber = null;

	ctrl.freeSerialIds = [];

	$scope.focusTarget = 0;

	$scope.closeEnum = {
		ALWAYS: 0,
		NEVER: 1,
		IFALL: 2
	};


	// API offered for the client with onControllerCallback
	var service = {
		cancelChanges: cancelChanges,
		saveChanges: saveChanges
	};


	function cancelChanges() {
		ctrl.cancelClicked(ctrl.newSerialNumber);
	}

	function saveChanges() {
		ctrl.saveChanges(ctrl.newSerialNumber);
	}

	ctrl.$onInit = function () {
		// Make copy to avoid "temporary" entities to appear into UI
		ctrl.serialNumbers = ctrl.serialNumbersOrig.slice(0);
		ctrl.onControllerCallback({ childCtrl: service });
		init();
	}

	/**
	 * Cancel the changes 
	 */
	ctrl.cancelClicked = function (existingEntity) {
		// Editing entity
		if (existingEntity) {
			manDatacontext.rejectChanges(existingEntity);
		}
		ctrl.serialNumbers.forEach(function (nr) {
			if (nr.entityAspect.entityState === breeze.EntityState.Modified) {
				manDatacontext.rejectChanges(nr);
			}
		});
		ctrl.onRequestToggle();
	}

	/**
	 * Clear edit fields.
	 */
	ctrl.clearChanges = function (target, removeFocus) {
		manDatacontext.rejectChanges(target);
		if (removeFocus) {
			$scope.editSerialNumber = null;
		}
	}

	/**
	 * Takes care of entity state with serialNumberSelected API
	 * After focus is gained, we will always have SerialNumber-entity in ctrl.newSerialNumber
	 * If the focus is lost and no serial number is defined, we will detach the entity / reject changes
	 * depending if the entity is new or read from DB
	 */
	ctrl.createNewFocusChanged = function (serialId, focusGained) {
		$scope.editSerialNumber = null;
		if (!serialId) {
			return;
		}
		if (focusGained) {
			// create entity if not available
			if (!ctrl.newSerialNumber) {
				ctrl.newSerialNumber = manDatacontext.getEntityManager().createEntity('SerialNumber');
			}
			// and fill owner specific properties
			ctrl.onFillEntity({ entity: ctrl.newSerialNumber });
			// if newly created, then attch it to manager <=> state will become Added
			if (ctrl.newSerialNumber.entityAspect.entityState === breeze.EntityState.Detached) {
				manDatacontext.getEntityManager().addEntity(ctrl.newSerialNumber);
			}
			manDatacontext.resolveHasChanges();
		}
		// Focus lost
		else {
			var discard = false;
			if (ctrl.forceListSelection) {
				discard = ctrl.freeSerialIds.filter(function (sn) {
					return sn.serialId === ctrl.newSerialNumber.serialId;
				}).length !== 1;
			}
			// Check if valid entity (contains some text), if not...
			if ((ctrl.newSerialNumber.serialId && ctrl.newSerialNumber.serialId.replace(/\s+/g, '').length === 0) || discard) {
				manDatacontext.rejectChanges(ctrl.newSerialNumber);
				ctrl.newSerialNumber = null;
			}
		}
	}

	/**
	 * Filters ctrl.freeSerialIds with given string
	 * @param {searchFilter used in matching} searchFilter
	 */
	ctrl.filteredSerialNumberList = function (searchFilter) {
		//var searchFilter = typeof searchFilter_ === 'string' ? searchFilter_ : searchFilter_.
		return common.debouncedThrottle('serialIdsFetch',
			function () {
				return readSerialNumbers(searchFilter);
			}, 500);
	};

	/**
	 * Editing is possible if all fields serial numbers aren't added yet
	 */
	ctrl.isAddAvailable = function () {
		return ctrl.serialNumbers.length < ctrl.maxItems;
	}

	/**
	 * Read pre-defined serial numbers from the DB
	 */
	function readSerialNumbers(filterStr) {
		if (ctrl.itemId) {
			return manDatacontext.getFreeSerialNumbers(ctrl.itemId, 0, 1000, filterStr).then(function (data) {
				ctrl.freeSerialIds = data;
				return ctrl.freeSerialIds;
			});
		}
		return [];
	}

	/**
	 * Delete selected serial number
	 */
	ctrl.removeSerialNumber = function (target) {
		if (confirm(common.$translate.instant("STRCONST.WOR.QRY_DELETE_SERIALNUM_SELECTED"))) {
			target.entityAspect.setDeleted();
			manDatacontext.saveChanges(target).then(
				function (saveResult) {
					var i = ctrl.serialNumbers.indexOf(target);
					if (i != -1) {
						ctrl.serialNumbers.splice(i, 1);
					}
					readSerialNumbers();
					ctrl.onRemovedCallback({ succeeded: true });
				},
				function (error) {
					ctrl.onRemovedCallback({ succeeded: false });
				});
		}
	}

	/**
	 * Make edit controls become active with multiple serial number addition
	 */
	ctrl.serialNumberSelected = function (sn) {
		if (ctrl.newSerialNumber && ctrl.newSerialNumber.entityAspect) {
			// clear changes made to previous existing serial number
			if (ctrl.newSerialNumber.entityAspect.entityState === breeze.EntityState.Modified) {
				manDatacontext.rejectChanges(ctrl.newSerialNumber);
			}
			// or detach if newly added entity
			else if (ctrl.newSerialNumber.entityAspect.entityState === breeze.EntityState.Added) {
				ctrl.newSerialNumber.entityAspect.setDetached();
			}
		}
		ctrl.newSerialNumber = sn;
	}

	/**
	 * Save changes to DB (add/update)
	 * @param {closeRow defines if the edit mode should exited} closeRow
	 */
	ctrl.saveChanges = function (closeRow) {
		// Give the user possibility to cancel changes to the existing serial number
		if ($scope.editSerialNumber &&
			$scope.editSerialNumber.entityAspect.entityState == breeze.EntityState.Modified &&
			!confirm(common.$translate.instant("STRCONST.WOR.QRY_UPDATE_SERIALNUM_SELECTED"))) {
			manDatacontext.rejectChanges($scope.editSerialNumber);
		}

		// remove focus from editing
		$scope.editSerialNumber = null;
		var add = ctrl.newSerialNumber ? 1 : 0;
		var close = closeRow == $scope.closeEnum.ALWAYS ? true :
			closeRow == $scope.closeEnum.NEVER ? false :
				ctrl.serialNumbers.length + add == ctrl.maxItems;

		// Actual Saving
		var changed = [];
		if (ctrl.newSerialNumber) {
			// Check if serial number is found in pre-defined serial numbers.
			ctrl.freeSerialIds.forEach(function (entity) {
				if (entity.serialId == ctrl.newSerialNumber.serialId) {
					entity.entityAspect.setModified();
					ctrl.onFillEntity({ entity: entity });
					changed.push(entity);
					if (ctrl.newSerialNumber !== entity) {
						// Same ID typed, but not selected true list
						ctrl.newSerialNumber.entityAspect.setDetached();
					}
					ctrl.newSerialNumber = entity;
				}
			});
			// If serial number is not found, then it's new.
			if (changed.length == 0) {
				if (ctrl.forceListSelection) {
					// totally new serial numbers are not allowed if ctrl.forceListSelection
					ctrl.newSerialNumber.entityAspect.setDetached();
					ctrl.newSerialNumber = null;
					return;
				}
				changed.push(ctrl.newSerialNumber);
			}
		}
		ctrl.serialNumbers.forEach(function (nr) {
			if (nr.entityAspect.entityState !== breeze.EntityState.Unchanged) {
				// Verify taht paren data is correct
				ctrl.onFillEntity({ entity: nr });
				changed.push(nr);
			}
		});
		// And execute saving for the changed entities
		if (changed.length > 0) {
			manDatacontext.saveChanges(changed).then(
				function (saveResult) {
					if (close) {
						ctrl.onRequestToggle();
					}
					// add newwly added item to
					if (ctrl.newSerialNumber) {
						ctrl.serialNumbers.push(ctrl.newSerialNumber);
						ctrl.newSerialNumber = null;
					}
					ctrl.onSavedCallback({ succeeded: true });
					readSerialNumbers();
				},
				function (error) {
					ctrl.onSavedCallback({ succeeded: false });
				});
		}
		else if (close) {
			ctrl.onRequestToggle();
		}
	}

	ctrl.startEditing = function (target, ctrl) {
		$scope.editSerialNumber = target;
		$scope.focusTarget = ctrl;
	}

	ctrl.selectSerialNumber = function (item, index) {
		//Used with simplifiedLayout
		if (ctrl.simplifiedLayout) {
			if (!ctrl.serialNumbers[index]) {
				ctrl.serialNumbers[index] = manDatacontext.getEntityManager().createEntity('SerialNumber');
			}
			ctrl.serialNumbers[index] = item;
			ctrl.onFillEntity({ entity: ctrl.serialNumbers[index] });
		}
	}

	$scope.$watchCollection('ctrl.serialNumbersOrig', function () {
		//Used with simplifiedLayout
		if (ctrl.simplifiedLayout) {
			ctrl.serialNumbers = ctrl.serialNumbersOrig.slice(0);
		}
	});

	function init() {
		readSerialNumbers();
	}
}
export default serialNumbersModule