var taskCardModule=angular.module('manudir.dfxTaskCard',[])
taskCardModule.directive('dfxTaskCard', ['common', 'resourceTaskService', function (common, resourceTaskService) {
	return {
		restrict: 'E',
		scope: {},
		controllerAs: 'ctrl',
		bindToController: {
            task: '=',
            shownAmounts: '=?',
			selected: '=',
			template: '=',
			worStatusRows: '=',
			showResource: '=?',
			dateFormat: '=?'
		},
		controller: ['$scope', function ($scope) {
			var ctrl = this;
			ctrl.state = resourceTaskService.getState();
			ctrl.dateFormat = (ctrl.dateFormat ? ctrl.dateFormat : 'shortDate');

			$scope.getTemplateUrl = function () {
				return ctrl.template ? "app/manufacturing/directives/"+ctrl.template : 'app/manufacturing/directives/taskCard_Default.html';
			}
			ctrl.getTaskStatusColor = function () {
				var ret = 'task-status-color-value-undefined';
				if (ctrl.worStatusRows) {
					var statusEnum = common.getEnumRowById(ctrl.worStatusRows, ctrl.task.status);
					ret = 'task-status-color-value-' + (statusEnum && statusEnum.extraSelect[0] ? statusEnum.extraSelect[0] : 'undefined');
				}
				return ret;
			}
			ctrl.getQtyLimitExceededIndicatorTooltip = function () {
				return ctrl.task.qtyLimitExceededIndicator == '10' ? common.$translate.instant("STRCONST.WOR.ERR_HARD_LIMIT_EXCEEDED") :
						ctrl.task.qtyLimitExceededIndicator == '20' ? common.$translate.instant("STRCONST.WOR.ERR_SOFT_LIMIT_EXCEEDED") : '';
			}

			ctrl.getStatusCircleState = function () {
				return ctrl.task.statusCircles ? ctrl.task.statusCircles.split(';') : [];
			}

			ctrl.getWorklistIndicatorClass = function () {
				return 'work-list-indicator-' + ctrl.task.worklistIndicator;
			}

			ctrl.getQtyLimitExceededIndicatorClass = function () {
				return 'qty-limit-exceeded-indicator-' + ctrl.task.qtyLimitExceededIndicator;
			}

		}],
		template: '<ng-include src="getTemplateUrl()"/>'
	}
}]);
export default taskCardModule