'use strict';
var employeeModule = angular.module('dir.dfxEmployee',[]);
var empControllerId = 'employeectrl';
employeeModule.directive('dfxEmployee', function ($compile) {
    // Employee Selection
    // @param {Object} empId
    // @param {Function} onSelectionCallback - Function to be called after successful selection.
    // @param {boolean} [useUserDepRst=false] - If set to true uses current user's department for restricting employee list.
    // Usage:
    // <dfx-employee emp-id="ctrl.employeeId" on-selection-callback="ctrl.empSelected(value)"/>
    return {
        restrict: 'EA', //E = element, A = attribute, C = class, M = comment 
        scope: {},
        bindToController: {
            empId: '=',
            onSelectionCallback: '&',
			useUserDepRst: '=?',
			useTag: '=?',
			extraClass: '=?'
        },
        controller: empControllerId,
        controllerAs: 'ctrl',
        template: require('./dfxEmployee.html')
    }
});

employeeModule.controller(empControllerId, ['$scope', 'config', 'common', 'commonDatacontext', '$uibModal', '$location', employee])

function employee($scope, config, common, commonDatacontext, $uibModal, $location) {
    var ctrl = this;
    ctrl.limit = 14;
    ctrl.curSelection = null;
	ctrl.employeeList = [];
	ctrl.invalidTagId = false;

    ctrl.$onInit = function () {
		ctrl.useUserDepRst = ctrl.useUserDepRst || false;
		ctrl.useTag = ctrl.useTag || false;
        activate();
    };

	common.appSettingReady().then(function (data) {
		ctrl.employeeDataQueryType = common.getAppSetting("EmployeeDataQueryType", 1)
	});



    /**
     * ctrl.objects contains Array of parents with [objectType, recId]
     */
    $scope.$watchCollection('ctrl.empId', function (value) {
        if (value) {
            selectEmployee(value);
        }
    });

    /**
     * User was selected
     * @param item 
     */
    ctrl.empSelected = function (item) {
        ctrl.onSelectionCallback({ value: item });
    }

    /**
     * Filters ctrl.employeeList with given string
     * @param {searchFilter used in matching} searchFilter
     */
    ctrl.filteredEmployeeList = function (searchFilter) {
        if (searchFilter && searchFilter != '' && searchFilter != ' ') {
            var cmp = searchFilter.toLowerCase();
            var matching = [];
            for (var i = 0; i < ctrl.employeeList.length ; i++) {
                var e = ctrl.employeeList[i];
                if (e.empId.toLowerCase().indexOf(cmp) > -1 ||
                    e.name.toLowerCase().indexOf(cmp) > -1) {
                    matching.push(e);
                }
            }
            // If matching result size is less than limit then get employee list from DB using search filter. Used only when employeeDataQueryType is 1.
            if (ctrl.employeeDataQueryType == 1 && matching.length < ctrl.limit) {
                return commonDatacontext.getEmployeeList(ctrl.useUserDepRst, cmp).then(function (data) {
                    if (data) {
                        var idArr = matching.map(function (e) { return e.empId });
                        if (idArr.length > 0) {
                            data = data.filter(function (item) {
                                return idArr.indexOf(item.empId) === -1;
                            });
                        }
                        ctrl.employeeList = ctrl.employeeList.concat(data);
						matching = common.unique(data.concat(matching), 'empId');
                    }
					common.setTypeheadColumnData(matching, getTypeheadName, [true, false], 0, [8, 20], false);
                    return matching;
                });
            }
			common.setTypeheadColumnData(matching, getTypeheadName, [true, false], 0, [8, 20], false);
            return matching;
        }
        // return full list
        return ctrl.employeeList;
	}

	ctrl.onTagInput = function (tagId) {
		if (tagId) {
			return commonDatacontext.getEmployeeList(ctrl.useUserDepRst, null, tagId, true).then(function (data) {
				if (data) {
					var emp = data[0];
					if (emp) {
						ctrl.invalidTagId = false;
						ctrl.curSelection = emp;
						ctrl.onSelectionCallback({ value: emp });
					}
					else {
						ctrl.onSelectionCallback({ value: null })
						ctrl.invalidTagId = true;
					}
				}
			})
		}
		else {
			ctrl.onSelectionCallback({ value: null })
			ctrl.invalidTagId = true;
		}
	}

    /**
     * Gets employees from local database.
     */
    function getEmployees() {
        commonDatacontext.getEmployeeList(ctrl.useUserDepRst).then(function (data) {
            ctrl.employeeList = data ? data : [];
            if (ctrl.empId) {
				if (!selectEmployee(ctrl.empId)) {
					// Read exact employee
					commonDatacontext.getEmployeeList(ctrl.useUserDepRst, ctrl.empId).then(function (data) {
						if (data && data.length === 1) {
							ctrl.employeeList = ctrl.employeeList.concat(data);
							ctrl.curSelection = data[0];
						}
						common.setTypeheadColumnData(ctrl.employeeList, getTypeheadName, [true, false], 0, [8, 20], false);
					});
					return;
				}
            }
            common.setTypeheadColumnData(ctrl.employeeList, getTypeheadName, [true, false], 0, [8, 20], false);

        });
    }

    /**
     * Provides data for typehead columns
     */
    function getTypeheadName(column, emp) {
        var ret = null;
        if (column < 2) {
            if (column == 0) {
                ret = emp.empId;
            }
            else if (column == 1) {
                ret = emp.name;
            }
        }
        return ret;
    }
    /**
     * Clear emplyee selection
     */
    ctrl.removeSelection = function () {
		ctrl.curSelection = null;
		ctrl.curTagSelection = null;
        ctrl.onSelectionCallback({ value: null });
    }

    /**
     * Store previous emplyee selection
     */
    ctrl.storePrevSelection = function () {
        ctrl.lastEmployeeSelection = ctrl.curSelection ? ctrl.curSelection : null;
    }

    /**
     * return previous emplyee selection
     */
    ctrl.getPrevSelection = function () {
        if (!ctrl.curSelection && ctrl.lastEmployeeSelection) {
            ctrl.curSelection = ctrl.lastEmployeeSelection;
        }
    }

    /**
     * Initialize $scope.selEmp
     * @param empId 
     */
	function selectEmployee(empId) {
		var found = false;
		for (var i = 0; i < ctrl.employeeList.length; i++) {
			if (ctrl.employeeList[i].empId == empId) {
				ctrl.curSelection = ctrl.employeeList[i];
				found = true;
				break;
			}
		}
		return found;
	}

	function activate() {
		if (ctrl.useTag) return;
        getEmployees();
    }
}
export default employeeModule