import { parseFractionFormat, decimalFormat, formatByCultureId } from '../common/utils/numberUtils'
	'use strict';

	var app = angular.module('common');

    /**
     * @class LeanPortalSPA.directives.dfxGrid
     * @memberOf LeanPortalSPA.directives
     * @description Directive wrapper for generating dynamic grid with ui-grid library.
     * @param {Number} formId - ID of the form to be used
     * @param {string} formVersion - ID of the form version to be used
     * @param {boolean} [force] - Get form settings always from database, not cache.
     * @param {string} modelName - Name of the modal used in the grid.
     * @param {string} gridId - Grid id.
     * @param {Object} options - Options object containing the data and column definitions used in grid.
     * @param {boolean} [editableGrid] - Are grid rows editable.
     * @param {boolean} [enableFiltering] - Are filtering fields visible. If not set, form settings is used. If not set in form settings, defaults to true.
     * @param {boolean} [selectableGrid] - Are grid rows selectable.
     * @param {boolean} [selectFirst] - Select first after data is set. True, if no defined to maintain old functionality.
     * @param {boolean} [useColumnWidths] - Used width definitions from the form's column settings.
     * @param {boolean} [useParentElementHeight] - Follow parent element height
     * @param {boolean} [filterInvalidEnumRows=false] - Are invalid enum rows filtered.
     * @param {function} onFilterChangedCallback - Callback for filter change event.
     * @param {Object} onFilterChangedCallback.module - Module whose filter field is changed.
     * @param {string} onFilterChangedCallback.filterString - String(s) used in filtering.
     * @param {function} onSelectionCallback - Callback for row selection
     * @param {Object} onSelectionCallback.module - Module causing the event.
     * @param {Object} onSelectionCallback.entity - Selected row entity.
     * @param {function} onDeleteCallback - Callback for row deletion.
     * @param {Object} onDeleteCallback.module - Module causing the event.
     * @param {Object} onDeleteCallback.entity - Deleted row entity.
     * @param {function} onGridApi - Grid api.
	 * @param {number} [parentElementWidth] - Width of space reserved for the grid. If not set uses window width.
	 * @param {boolean} [showDeleteButton=true] - If grid row delete button is showed or not.
     * @example <dfx-grid form-id="@formId" model-name="'PurchaseInvoice'" grid-id="'grid1'" 
     *                    options="ctrl.dfxGridOptions" selectable-grid="true" 
     *                    on-filter-changed-callback="ctrl.dfxGridfilterChanged(column)" 
     *                    on-selection-callback="ctrl.dfxGridOnSelection(entity)"></dfx-grid>
     *
     */
	// Directive wrapper for generating dynamic grid with ui-grid library.
	// Usage example:
	// <dfx-grid form-id="@formId" model-name="'PurchaseInvoice'" grid-id="'grid1'" options="ctrl.dfxGridOptions" selectable-grid="true" on-filter-changed-callback="ctrl.dfxGridfilterChanged(column)" on-selection-callback="ctrl.dfxGridOnSelection(entity)"></dfx-grid>
app.directive('dfxGrid', ['$compile', 'uiGridConstants', 'uiGridEditConstants', 'usersettings', 'common', 'commonDatacontext', 'uiGridExporterConstants', '$templateCache', 'uiGridGroupingConstants',
	function ($compile, uiGridConstants, uiGridEditConstants, usersettings, common, commonDatacontext, uiGridExporterConstants, $templateCache, uiGridGroupingConstants) {
        return {
            restrict: 'E',
            scope: {
                formId: '=',
				formVersion: '=',
                force: '=?',
                modelName: '=',
                gridId: '=',
                options: '=',
				editableGrid: '=?',
				enableMultiSelect: '=?',
				expandableGrid: '=?',
                selectableGrid: '=?',
                selectFirst: '=?',
				useColumnWidths: '=?',
				useDynamicHeight: '=?',
                useParentElementHeight: '=?',
				useGrouping: '=?',
				usePinning: '=?',
				showPrevSelRow: '=?',
                filterInvalidEnumRows: '=?',
                onFilterChangedCallback: '&',
                onSelectionCallback: '&',
                onDeleteCallback: '&',
				onGridApi: '&',
				parentElementWidth: '=?',
				showDeleteButton: '=?'
            },
            link: linker,
            controller: dfxGridController
        };

        function linker(scope, element) {
			if (scope.editableGrid === false) {
				scope.forceNonEditable = true;
			}
			scope.internalGridDataControl = scope.options;
			scope.internalGridDataControl.disableMap = scope.internalGridDataControl.disableMap ? scope.internalGridDataControl.disableMap : {}; 
			$templateCache.put('ui-grid/selectionRowHeaderButtons', "<div class=\"ui-grid-selection-row-header-buttons a-input-checkbox__wrapper\" ng-click=\"selectButtonClick(row, $event);$event.preventDefault()\" ng-class=\"{'ui-grid-row-selected': row.isSelected}\">" +
                "<input type=\"checkbox\" class=\"a-input-checkbox\" id=\"{{row.uid}}\" ng-model=\"row.isSelected\"> " +
                " <label for=\"{{row.uid}}\" class=\"a-option-label\"></label> &nbsp; </div>");
            $templateCache.put('ui-grid/selectionHeaderCell', "<div class=\"ui-grid-cell-contents\" col-index=\"renderIndex\"><ui-grid-selection-select-all-buttons ng-if=\"grid.options.enableSelectAll\"></ui-grid-selection-select-all-buttons><div role=\"button\" class=\"ui-grid-filter-button\" style=\"padding-top: 6px\" ng-click=\"grid.clearAllFilters()\" ng-if=\"grid.options.enableFiltering && grid.appScope.hasFilters\"><i class=\"ui-grid-icon-cancel\" ui-grid-one-bind-aria-label=\"aria.removeFilter\" aria-label=\"Remove Filters\">&nbsp;</i></div></div>");
			$templateCache.put('ui-grid/selectionSelectAllButtons', "<div class=\"ui-grid-selection-row-header-buttons a-input-checkbox__wrapper\" ng-click=\"headerButtonClick($event);$event.preventDefault()\"><input type=\"checkbox\" id=\"multiselect\" class=\"a-input-checkbox\" ng-class=\"{ 'ui-grid-all-selected': grid.selection.selectAll }\" " +
                "ng-model=\"grid.selection.selectAll\"><label for=\"multiselect\" class=\"a-option-label\"></label></div>");

			scope.internalGridDataControl.refreshData = function (data) {  //Call directive function from controller to refresh data.
				common.$timeout(function () {
					scope.options.data = data;
					if (scope.gridApi && scope.gridApi.selection) {
						scope.gridApi.selection.clearSelectedRows();
						scope.gridApi.grid.modifyRows(scope.options.data);
						if (scope.selectFirst) {
							scope.gridApi.selection.selectRow(scope.options.data[0]);
                        }
                        scope.gridApi.grid.options.isRowSelectable = function (row) {
							return true;
                        };

                        scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.OPTIONS);
					}
					if (scope.gridApi) {
						scope.gridApi.grid.notifyDataChange(uiGridConstants.dataChange.ALL);
					}
					if (scope.useDynamicHeight) {
						scope.options.enableVerticalScrollbar = uiGridConstants.scrollbars.WHEN_NEEDED;
					}
					else {
						var manyRows = data.length > 10;
						scope.options.enableVerticalScrollbar = manyRows ? uiGridConstants.scrollbars.ALWAYS : uiGridConstants.scrollbars.NEVER;
					}
					scope.setTableHeight();
				}, 10)
			}
			scope.internalGridDataControl.createRow = function (entity) {
			    scope.options.data.push(entity);
			    scope.gridApi.grid.modifyRows(scope.options.data);

			    // If the grid contains selectable rows, select the created row.
			    if (scope.gridApi.selection && scope.selectFirst) {
			        scope.gridApi.selection.selectRow(scope.options.data[0]);
			    }

                scope.setTableHeight();
			    scope.gridApi.core.scrollTo(scope.options.data[scope.options.data.length - 1], scope.options.columnDefs[0]);
			  
            }
            scope.internalGridDataControl.export = function (exportFormat) {
                switch (exportFormat) {
                    case 'EXCEL':
                        scope.gridApi.exporter.excelExport(uiGridExporterConstants.ALL, uiGridExporterConstants.ALL);
                        break;
                    case 'PDF':
                        scope.gridApi.exporter.pdfExport(uiGridExporterConstants.ALL, uiGridExporterConstants.ALL);
                        break;
                    case 'CSV':
                        scope.gridApi.exporter.csvExport(uiGridExporterConstants.ALL, uiGridExporterConstants.ALL);
                        break;
                }
            }
            scope.internalGridDataControl.refreshView = function () {
                if (scope.gridApi) {
					common.$timeout(function () {
                        scope.gridApi.core.refresh();
                        scope.gridApi.grid.handleWindowResize();
                    });
                }
            }

            scope.internalGridDataControl.toggleFilters = function () {
                scope.options.enableFiltering = !scope.options.enableFiltering;
                scope.gridApi.grid.clearAllFilters();
                scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.ALL);
            }

            scope.internalGridDataControl.toggleColVisibility = function (columnName, visible) {
                var col = scope.gridApi.grid.getColumn(columnName.toLowerCase());
                if (col) {
                    if (!visible) {
                        col.hideColumn();
                    } else {
                        col.showColumn();
                    }
                    scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
                }
            }

            scope.internalGridDataControl.changeGrouping = function (columnName) {
                scope.gridApi.grouping.clearGrouping();
                var colName = columnName.toLowerCase();
                if (scope.gridApi.grid.getColumn(colName)) {
                    scope.gridApi.grouping.groupColumn(colName);
                    angular.forEach(scope.gridApi.grid.columns, function (column) {
                        if (column.colDef.treeAggregationType) {
                            scope.gridApi.grouping.aggregateColumn(column.name, column.colDef.treeAggregationType);
                        }
                    });
				}
				scope.gridApi.treeBase.collapseAllRows();
            }

			scope.initReadyPromise.promise.then(function () {
                scope.setTableHeight();
                var html = '<div id="' + scope.gridId + '" ui-grid="options" class="dfx-grid" ng-style="{height: desiredTableHeight}" ui-grid-auto-resize ';
                html += scope.selectableGrid ? ' ui-grid-selection' : '';
				html += scope.editableGrid || scope.formSettings.updateable && !scope.forceNonEditable ? ' ui-grid-edit' : '';
				html += scope.expandableGrid ? ' ui-grid-expandable' : '';
                html += scope.enableExport ? ' ui-grid-exporter' : '';
				html += scope.useGrouping ? ' ui-grid-grouping' : '';
				html += scope.usePinning ? ' ui-grid-pinning' : '';
                html += '><div class="dfx-grid-watermark" ng-show="!options.data.length"><span translate="STRCONST.LEANPORTAL.NO_ROWS"></span></div></div>';
				element.html(html);
				$compile(element.contents())(scope);
            });

            scope.getProperty = function (entity, propertyName) {
                return entity[propertyName] !== undefined ? entity[propertyName] : '!!' + propertyName + ' property not defined !!';
            };

            scope.setTableHeight = function() {
                var scope = this;
                if (!(scope.options && scope.options.data)) {
                    return;
				}
				var rootElement = angular.element('html');
				var hasDesktopClass = false;
				if (rootElement && rootElement[0] && rootElement[0].classList) {
					if (rootElement[0].classList.contains('desktop')) {
						scope.options.rowHeight = 30;
						hasDesktopClass = true;
					}
					else {
						scope.options.rowHeight = 50;
					}
				}
                if (scope.useParentElementHeight && element[0].parentNode) {
                    scope.desiredTableHeight = '100%';
				}
				else if (scope.useDynamicHeight) {
					var heightToBelow = window.innerHeight - element[0].getBoundingClientRect().top;
					var footerHeight = angular.element(document.querySelector('#dfx-footer'));
					heightToBelow -= footerHeight ? footerHeight.height() : 0; // remove footer from height
					scope.desiredTableHeight = heightToBelow + 'px';
				}
                else {
					var upperLimit = hasDesktopClass ? 15 : 10;
					var restrictionsHeight = (hasDesktopClass ? 60 : 100) + 30;
					var height = Math.min(scope.options.data.length, upperLimit) * scope.options.rowHeight + restrictionsHeight + 15 + (scope.options.showColumnFooter ? 30 : 0);
                    scope.desiredTableHeight = height + 'px';
                }
			}

			scope.setGridWidths = function (visibleColumns) {
				var parentElementWidth = (scope.parentElementWidth ? scope.parentElementWidth : $(window).width()) - 30; // Some margin/border taken away so that scroll does not appear. TODO: Find a better solution.
				var fixedColsTotalWidth = 0;
				scope.relativeColsDivider = 0;
				scope.relativeColsCount = 0;
				angular.forEach(visibleColumns, function (column) {
					if (column.unit == 'PX' || (!column.unit && scope.formSettings.unit === 'Pixel')) {
						fixedColsTotalWidth += column.width;
					}
					else {
						scope.relativeColsDivider += column.width ? column.width : 1;
						scope.relativeColsCount += 1;
					}
				});
				scope.relativeColsTotalWidth = parentElementWidth - fixedColsTotalWidth;
			}

			scope.setTableColumnWidth = function () {
				// Some columns might be hidden after resize so we need to re-calculate visible column widths
				if (scope.formSettings && scope && scope.options && scope.options.columnDefs && scope.options.columnDefs.length > 0) {

					var columnSettings = scope.formSettings.columnSettings;
					var visibleColumns = [];
					// Visible columns are in columnDefs
					angular.forEach(scope.options.columnDefs, function (colDef) {
						if (colDef.visible !== false) {
							visibleColumns.push(colDef);
						}
					});
					// Original widths are in columnsettings
					angular.forEach(visibleColumns, function (column) {
						if (columnSettings[column.dbColumnName] && columnSettings[column.dbColumnName].width > 0) {
							column.width = columnSettings[column.dbColumnName].width;
							column.unit = columnSettings[column.dbColumnName].unit;
						}
					});
					scope.setGridWidths(visibleColumns);
					angular.forEach(visibleColumns, function (column) {
						column.width = scope.getColumnWidth(column);
					})
					if (scope.gridApi) {
						scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.ALL);
					}
				}
			}

			scope.getColumnWidth = function (column) {
				var defaultWidth = 60;
				//Functionality for adjusting grid column widths according to the UNIT form- and columnattributes.
				switch (scope.formSettings.unit) {
					//Column widths are determined as a ratio (Percentage) calculated from the total width of all shown columns. 
					//Columns with individual column width set changes the calculation accordingly. 
					case 'Ratio':
					//If the column has an individual 'unit'-attribute set, then the value is retrieved from columnattributes, otherwise the formattribute 'Ratio' is used
					//The columns default to Ratio if no other 'unit'-attribute is set.
					default:
						return IndividualColumnWidth(column.unit ? column.unit : 'RATIO', column.width > 0 ? column.width : defaultWidth);
						break;

					//Column widths are in pixels aside from columns with individual column width set. 
					case 'Pixel':
						//If the column has an individual 'unit'-attribute set, then the value is retrieved from columnattributes, otherwise the formattribute 'Pixel' is used
						return IndividualColumnWidth(column.unit ? column.unit : 'PX', column.width > 0 ? column.width : defaultWidth);
						break;
					//Columns are evenly wide aside from columns with individual 'unit'-attribute set
					case 'Even':
						//If the column has an individual 'unit'-attribute set, then the value is retrieved from columnattributes, otherwise the formattribute 'Even' is used. 
						// Only PX is used, other values are regarded as even.
						return IndividualColumnWidth(column.unit && column.unit === 'PX' ? 'PX' : '', column.width > 0 ? column.width : defaultWidth);
						break;
				}
				//This function calculates the width for an individual column that has the 'unit'-columnattribute assigned.
				function IndividualColumnWidth(columnUnit, columnWidth) {
					switch (columnUnit) {
						case 'PX':
							return columnWidth;
						case 'RATIO':
							return scope.relativeColsTotalWidth < 0 ? defaultWidth : Math.round(columnWidth / scope.relativeColsDivider * scope.relativeColsTotalWidth);
						default:
							return scope.relativeColsTotalWidth < 0 ? defaultWidth : Math.round(scope.relativeColsTotalWidth / scope.relativeColsCount);
							break;
					}
				}
			}
		}

		function dfxGridController($scope) {
			//Default directive attribute settings (don't mix with default grid options).
			if ($scope.selectFirst === undefined) $scope.selectFirst = true;
			if ($scope.filterInvalidEnumRows === undefined) $scope.filterInvalidEnumRows = false;
			if ($scope.showPrevSelRow === undefined) $scope.showPrevSelRow = true;
			if ($scope.useColumnWidths === undefined) $scope.useColumnWidths = true;
			//User permissions to form
			if ($scope.userPermissionToForm_update === undefined) $scope.userPermissionToForm_update = false;
			if ($scope.userPermissionToForm_insert === undefined) $scope.userPermissionToForm_insert = false;
			if ($scope.userPermissionToForm_delete === undefined) $scope.userPermissionToForm_delete = false;

			$scope.$on('USERSETTINGS', function () {
				init();
			});

			function init() {
				if (!$scope.initReadyPromise) {
					$scope.initReadyPromise = common.$q.defer();
				}
				$scope.enumItems = {};
				var defOptions = initDefOptions();
				initColumnSettings(defOptions, true);
			}

			init();

			function getPermissionsToForm() {
				var permissionPromise = common.$q.defer();
					usersettings.checkPermissionToForm($scope.formId, "R_UPDATE").then(function (data) {
						$scope.userPermissionToForm_update = data;
					});
					usersettings.checkPermissionToForm($scope.formId, "R_INSERT").then(function (data) {
						$scope.userPermissionToForm_insert = data;
					});
					usersettings.checkPermissionToForm($scope.formId, "R_DELETE").then(function (data) {
						$scope.userPermissionToForm_delete = data;
						permissionPromise.resolve(true);
					});
				return permissionPromise.promise;
			}

			$scope.goToRoute = function (route) {
				common.$location.path(route);
			};
            $scope.getLastGridId = function () {
                return common.getLastGridId();
			};

			$(window).on('resize', function () {

				function hasHiddenXs(cssClass) {
					if (cssClass && typeof cssClass !== 'function' && (cssClass.indexOf('hidden-xs') > -1)) {
						return true;
					}
					return false;
				}

                if ($(window).width() < 768) {
					angular.forEach($scope.options.columnDefs, function (colDef) {
						colDef.visible = !(hasHiddenXs(colDef.cellClass) && hasHiddenXs(colDef.headerCellClass));
                    });
                } else {
					angular.forEach($scope.options.columnDefs, function (colDef) {
						// Only set visibility back for columns with hidden-xs class
						// That is because we might have hidden columns in controller's customCols which we don't want to appear
						if (colDef.cellClass && typeof colDef.cellClass === 'function') {
							// TODO How to check?
						}
						else if (hasHiddenXs(colDef.cellClass) && hasHiddenXs(colDef.headerCellClass)) {
							colDef.visible = true;
						}
					});
				}
				$scope.setTableHeight();
				$scope.setTableColumnWidth();
			});

			$scope.getEnumCssClass = function (enumGroup, id, cssClassType) {
				if ($scope.enumItems) {
					var enumRow = common.getEnumRowById($scope.enumItems[enumGroup], id);
					if (enumRow) {
						return cssClassType && cssClassType == 'icon' ? enumRow.iconCssClass : enumRow.cssClass;
					}
				}
				return '';
			}

			$scope.getEnumNameById = function (row, enumGroup, id) {
			    if ($scope.enumItems) {
			        var enumName = common.getEnumNameById($scope.enumItems[enumGroup], row[id], true);
			        if (enumName) {
			            return enumName;
			        }
			    }
			}

			$scope.cellValueHasChanged = function (rowEnt, field, value) {
				$scope.gridApi.edit.raise.afterCellEdit(rowEnt, field, value, null);
			}

			// Is row read only?
			$scope.isRO = function (row) {
				if (!row || !row.entity || row.entity.isReadOnly === undefined) return true;
				return row.entity.isReadOnly;
			}

		    /*
            * Change entity field value
            */
			$scope.onItemChange = function (rowEnt, id, item, dbCol) {
			    if (item) {
			        $scope.$broadcast(uiGridEditConstants.events.BEGIN_CELL_EDIT);
			        if ($scope.propertyMap[id]) {
			            angular.forEach($scope.propertyMap[id], function (p) {
			                if (p[2]) {
			                    if (item[p[1]]) {
			                        rowEnt[id] = item[p[1]];
			                    };
			                };
			            });
			        } else {
			            rowEnt[id] = item[dbCol];
			        };
					$scope.$broadcast(uiGridEditConstants.events.END_CELL_EDIT);
					$scope.cellValueHasChanged(rowEnt, id, item);
			    };
			}

			$scope.changeDate = function (entity, field, value) {
			    if (value) {
			        $scope.$broadcast(uiGridEditConstants.events.BEGIN_CELL_EDIT);
			        // Timeout so that earlier digest operation can be completed before the next one.
			        common.$timeout(function () {
			            entity[field] = value;
						$scope.$broadcast(uiGridEditConstants.events.END_CELL_EDIT);
						$scope.cellValueHasChanged(entity, field, value);
			        }, 100)
			    }
			}

			function getEnums() {
				return commonDatacontext.getLookupsFromForm("",false,"", $scope.formId).then(function(data){
					$scope.enumItems = (data && data[0] && data[0].Enums) ? data[0].Enums : [];
					if ($scope.filterInvalidEnumRows) {
						for (var enumGroup in $scope.enumItems) {
							$scope.enumItems[enumGroup] = $scope.enumItems[enumGroup].filter(function (filter) {
								return (filter.isValid === undefined || filter.isValid == 1);
							});
						}
					}
				});
			}

			$scope.formatDateString = function (dateString, dateFormat) {
				var result = null;
				switch (dateFormat) {
					case "TIME":
						break;
					case "DATETIME":
						result = moment(dateString, common.longDateFormat() + " " + common.longTimeFormat()).utc();
						result = result.isValid() ? result.format(common.longDateFormat() + " " + common.longTimeFormat()) : '';
						break;
					case "DATE":
						result = moment(dateString, common.longDateFormat());
						result = result.isValid() ? result.format(common.longDateFormat()) : '';
						break;
				}
				return result;
			};

		    /*
             * Replace possible entity references with proper function calls
             */
			function getTemplate(cellTemplate) {
				var parts = cellTemplate.split("[$");
				var template = '';
				if (parts.length === 1) {
					template = parts[0];
				}
				else {
					angular.forEach(parts, function (part) {
						if (part.indexOf("$]") !== -1) {
							var _parts = part.split("$]");
							template += '{{grid.appScope.getProperty(row.entity,\'' + _parts[0] + '\')}}';
							template += _parts[1];
						}
						else {
							template += part;
						}

					});
				}
				return template;
			}

			function getTabRoute(tab) {
				var route = null;
				var routes = common.$route.routes;
				angular.forEach(routes, function (rt) {
					if (rt.originalPath == tab.url) {
						var routeParams = rt.keys;
						route = "'" + rt.originalPath;
						angular.forEach(routeParams, function (param) {
							route = route.replace(/:([^/]*)/, "'+row.entity." + param.name + "+'");
						});
						if (route.slice(-2) === "+'") {
							route = route.slice(0, -2); //remove the last +'
						}
						else {
							route = route + "'"; //add closing mark for route.
						}
					}
				});
				return route;
            }

			$scope.deleteRow = function (row) {
			    common.$translate('STRCONST.PUBLIC.QRY_ROW_DESTROY_SELECTED').then(function (translation) {
			        if (confirm(translation + '?')) {
			            $scope.options.data = $scope.options.data.filter(function (entity) {
			                return entity.recId != row.entity.recId;
			            });
			            if (row.entity.recId > 0) {
			                $scope.onDeleteCallback({ entity: row.entity });
			            }
			        }
			    });
			};

			function initDefOptions() {				
				return {    			//Default options for the grid, can be overridden in controller.
					enableMultiSelect :  true,
					useExternalFiltering : true,
					enableHorizontalScrollbar: uiGridConstants.scrollbars.NEVER,
					enableSorting: true,
					enableColumnResize: true,
					rowHeight: 30, //rex desktop variant
					onRegisterApi: function (gridApi) {

						/*Function responsible to get filter fields search terms, construct them to stringified filterObject and trigger callback which controller then uses to fetch items to UI from DB*/
						function initGridFilterSearchTerms(grid) {
							var objArr = [];
							for (var i = 0; i < grid.columns.length; i++) {
								var firstFilterTerm = null;
								var secondFilterTerm = null;
								if (grid.columns[i].filters[0]) {
									firstFilterTerm = grid.columns[i].filters[0].term;
								}
								if (grid.columns[i].filters[1]) {
									secondFilterTerm = grid.columns[i].filters[1].term;
								}

								if (firstFilterTerm || secondFilterTerm) {

									var field = grid.columns[i].colDef.dbColumnName;
									var type = (grid.columns[i].colDef.type).toUpperCase();
									var dateFormat = type === "DATE" ? grid.columns[i].colDef.dateFormat : null;

									if (field && type) {

										/*Only first 'greater than' filter available*/
										var obj = {};
										if (firstFilterTerm && !secondFilterTerm) {
											var searchTerm = firstFilterTerm.toUpperCase();
											/*Don't start the search for NAN values if column type is number */
											if (type == 'NUMBER' && !common.isNumber(searchTerm)) {
												return;
											} else if (type === 'DATE' && dateFormat) {
												searchTerm = $scope.formatDateString(searchTerm, dateFormat);
											}
											searchTerm = (type == 'DATE' || type == 'NUMBER') ? searchTerm + ';' : searchTerm;
										}
										/*Only second 'less than' filter available*/
										else if (!firstFilterTerm && secondFilterTerm) {
											var searchTerm = secondFilterTerm.toUpperCase();
											if (type == 'NUMBER' && !common.isNumber(searchTerm)) {
												return;
											} else if (type === 'DATE' && dateFormat) {
												searchTerm = $scope.formatDateString(searchTerm, dateFormat);
											}
											searchTerm = (type == 'DATE' || type == 'NUMBER') ? ';' + searchTerm : searchTerm;
										}
										/*Both filters available (should be type number or date)*/
										else if (firstFilterTerm && secondFilterTerm) {
											if (type == 'NUMBER' && (!common.isNumber(firstFilterTerm) || !common.isNumber(secondFilterTerm))) {
												return;
											} else if (type === 'DATE' && dateFormat) {
												firstFilterTerm = $scope.formatDateString(firstFilterTerm, dateFormat);
												secondFilterTerm = $scope.formatDateString(secondFilterTerm, dateFormat);
											}
											var searchTerm = firstFilterTerm.toUpperCase() + ';' + secondFilterTerm.toUpperCase();
										}
										/*change decimal ceparator for numbers, to allow database math functions run smoothly*/
										if (type == 'NUMBER' && searchTerm) {
											searchTerm = common.replaceDotDecimals(searchTerm);
										}

										/*Constructor for one object of array*/
										obj[field] = searchTerm;
										obj["DATATYPE"] = type;
										obj.isEnumItemId = grid.columns[i].colDef.isEnumItemId;
										objArr.push(obj);
									}
								}
							}
							var stringifiedFilterObj = stringifyGridFilterSearchTerms(objArr);
							var gridFilterKey = usersettings.getGridFilterKey();
                            common.setLastFilterFields(gridFilterKey, objArr, stringifiedFilterObj);
                            $scope.hasFilters = objArr.length > 0 ? true : false;
							return $scope.onFilterChangedCallback({ filteredObj: stringifiedFilterObj });
						}

						/*Function which returns stringified searchTerms from given object array,
						 *Determines correct dataType (number,date...) and builds stringified object accordingly */
						function stringifyGridFilterSearchTerms(objArr) {
							if (objArr) {
								var stringifyObj = {};
								for (var i = 0; i < objArr.length; i++) {
									for (var obj in objArr[i]) {
										if (obj === 'DATATYPE') {
											var dataType = (objArr[i][obj]).toUpperCase();
										}
										else if (obj === 'isEnumItemId') {
											var isEnumItemId = objArr[i][obj];
										}
										else {
											var dataTerm = objArr[i][obj];
											var dataKey = obj;
										}
									}
									switch (dataType) {
										case 'NUMBER':
											stringifyObj[dataKey] = dataTerm;
											break;
										case 'DATE':
											stringifyObj[dataKey] = dataTerm;
											break;
										default:
											stringifyObj[dataKey] = isEnumItemId ? dataTerm : "%" + dataTerm + "%";
									}
								}
								return JSON.stringify(stringifyObj);
							}
							return null;
						}

						function updateGroupHeader(row) {
							if ($scope.useGrouping) {
								var parentRow = row.treeNode.parentRow;
								if (parentRow) {
									parentRow.isSelected = parentRow.treeNode.children.every(function (child) {
										return child.row.isSelected;
									});
								}
							}
						}

						$scope.gridApi = gridApi;
						$scope.onGridApi({ gridApi: gridApi });

						$scope.gridApi.core.on.filterChanged($scope, function () {
							var grid = this.grid;
							initGridFilterSearchTerms(grid);
						});
						if ($scope.gridApi.selection) {
							$scope.gridApi.selection.on.rowSelectionChanged($scope, function (row) {
								updateGroupHeader(row);
								if (!row.groupHeader) {
									$scope.onSelectionCallback({ entity: row.entity, isSelected: row.isSelected });
								}
							});
							$scope.gridApi.selection.on.rowSelectionChangedBatch($scope, function (rows) {
								angular.forEach(rows, function (row) {
									updateGroupHeader(row);
									$scope.onSelectionCallback({ entity: row.entity, isSelected: row.isSelected });
								})
							});
						}
						if ($scope.gridApi.edit) {
							$scope.gridApi.edit.on.afterCellEdit($scope, function (rowEntity, colDef, newValue, oldValue) {
								if (newValue != oldValue) {
									rowEntity.State = "changed";
								}
							})
						}
						$scope.gridApi.grid.modifyRows($scope.options.data);
						if ($scope.selectableGrid && $scope.options.selectFirst) {
							$scope.gridApi.selection.selectRow($scope.options.data[0]);
						}
						$scope.gridApi.core.on.rowsRendered($scope, function () {
							if ($scope.showPrevSelRow) {
								if ($scope.gridApi.grid.rows.length > 0) {
									var lastGridId = $scope.getLastGridId();
									for (var obj in lastGridId) {
										var key = obj;
										var value = lastGridId[key];
									}
									if (key && value) {
										for (var i = 0; i < $scope.gridApi.grid.rows.length; i++) {
											var rowEnt = $scope.gridApi.grid.rows[i].entity;
											if (rowEnt[key] == value) {
												$scope.gridApi.grid.rows[i].isSelected = true;
												break;
											}
										}
									}
								}
							}
						});
                    },
					enableRowSelection: true,
					enableSelectAll: true,
					multiSelect: ($scope.enableMultiSelect || $scope.enableMultiSelect == false) ? $scope.enableMultiSelect : true,
                    enableColumnMenus: false,
                    treeRowHeaderAlwaysVisible: false,
					exporterSuppressMenu: true,
					exporterPdfPageSize: 'A3',
					exporterPdfMaxGridWidth: 900,
					exporterPdfDefaultStyle: {
						fontSize: 11,
						widths: '*'
					},
					exporterPdfTableStyle: { margin: [30, 30, 30, 30] }
				}
			}

			function initColumnSettings(defOptions) {
				usersettings.getLoginPromise().then(function () {
					getPermissionsToForm().then(function () {
						commonDatacontext.getFormSettings($scope.formId, $scope.force, $scope.formVersion).then(function (formSettings) {
							if (formSettings) {
								if (formSettings.rowHeight)
									defOptions.rowHeight = formSettings.rowHeight;
								$scope.formSettings = formSettings;
								var columnSettings = formSettings.columnSettings;
								$scope.enableExport = formSettings.enableExport;
								var enableFilteringDef = formSettings && formSettings.GridRstFieldsVisible && formSettings.GridRstFieldsVisible === 'eHidden' ? false : true;
								$scope.options.enableFiltering = $scope.options && $scope.options.enableFiltering !== undefined && $scope.options.enableFiltering !== null ? $scope.options.enableFiltering : enableFilteringDef;
								$scope.options.enableHorizontalScrollbar = (formSettings.unit == 'Pixel' || formSettings.unit == 'Ratio') ? uiGridConstants.scrollbars.WHEN_NEEDED : uiGridConstants.scrollbars.NEVER;
								$scope.propertyMap = [];
								$scope.filterColumns = [];
								$scope.showInUi = [];
								getPermissionsToForm();

								//Added all of the user permissions here. We might want  to remove them from here or just use the $scope.userPermissionToForm_update.
								if (($scope.editableGrid || formSettings.updateable) && !$scope.forceNonEditable && ($scope.userPermissionToForm_update || $scope.userPermissionToForm_insert || $scope.userPermissionToForm_delete)) {
									defOptions.enableCellEdit = true;
									defOptions.cellEditableCondition = function ($scope) {
										var colDef = $scope.col.colDef;
										var entity = $scope.row.entity;
										if (colDef.insertEditable && !entity[colDef.propertyName] && entity.isReadOnly === false) {
											return true;
										}
										else if (colDef.updateEditable && entity[colDef.propertyName] && entity.isReadOnly === false) {
											return true;
										}
										else {
											return false;
										}
									}
								}
								angular.forEach(defOptions, function (value, key) {
									if (key && $scope.options && $scope.options[key] === undefined) {  //Controller specified options override default options.
										$scope.options[key] = value;
									}
								});
								getEnums().then(function () {
									commonDatacontext.getModelColumnMap($scope.modelName).then(function (columnMap) {
										/*Get filter field terms if they exist in common service*/
										var gridFilterKey = usersettings.getGridFilterKey();
										var filterFields = common.getLastFilterFields(gridFilterKey, 'OBJARR');
										var visibleColumns = [];
										angular.forEach(columnSettings, function (column) {
											if (!column.hidden) {
												visibleColumns.push(column);
											}
										});
										angular.forEach(visibleColumns, function (column) {
											//Map all visible columns to corresponding model values and push to columnDefs (columnDefs specify which columns are generated by ui-grid).
											var colName = column.headerText.toLowerCase();
											var keys = columnMap ? Object.keys(columnMap) : [];
											var propertyName = keys.length > 0 ? columnMap[column.columnName] : column.columnName;
											var dbColumn = column.columnName;
											var firstSearchTerm = null;
											var secondSearchTerm = null;
											if (hasControllerColumnDefs(dbColumn)) {
												function getCssClass() {
													var cssClasses = column.dataTypeOrig === "NUMBER" || column.displayDecimals > 0 ? "text-right" : ""
													if (column.align) { cssClasses = "text-" + column.align.toLowerCase() }
													return cssClasses;
												}
												function getHeaderTooltip() {
													return column.toolTip;
												}
												var newColumnDef = {
													name: colName,
													field: propertyName,
													dbColumnName: dbColumn,
													// uniquePos observes formVersion ordering
													pos: $scope.formVersion && column.uniquePos ? column.uniquePos : column.pos,
													updateEditable: column.updateEditable,
													insertEditable: column.insertEditable,
													propertyName: propertyName,
													cellClass: getCssClass() + " " + (column.cssClassColumnCell || ''),
													headerCellClass: getCssClass() + " " + (column.cssClassColumnHeader || ''),
													headerTooltip: getHeaderTooltip()
												};
												var basicTemplate = '<div class="ui-grid-cell-contents ' + newColumnDef.cellClass + '">{{COL_FIELD CUSTOM_FILTERS}}</div>';
												if (column.controlType === "eHtml") {
													if ($scope.gridId == 'supplier-grid') {
														column.cellTemplate = column.cellTemplate.replace(/'/g, "");
													}
													newColumnDef['cellTemplate'] = getTemplate(column.cellTemplate);
												}
												if ($(window).width() < 768 && column.cssClassColumnHeader && column.cssClassColumnHeader) {
													if ((column.cssClassColumnCell.indexOf('hidden-xs') > -1) && (column.cssClassColumnHeader.indexOf('hidden-xs') > -1)) {
														newColumnDef.visible = false;
													}
												}
												if (!column.updateEditable) {
													newColumnDef.cellClass += " locked";
												}
												if (filterFields) {
													for (var i = 0; i < filterFields.length; i++) {
														var dbColumnName = newColumnDef.dbColumnName;
														if (filterFields[i][dbColumnName]) {
															var searchTerm = filterFields[i][dbColumnName];
															firstSearchTerm = searchTerm.split(';')[0];
															secondSearchTerm = searchTerm.split(';')[1];
														}
													}
												}
												if (column.rstShow < 1) {
													newColumnDef.enableFiltering = false;
												}
												if (column.dataTypeOrig == 'VARCHAR2') {
													if (((column.insertEditable && $scope.userPermissionToForm_insert) || (column.updateEditable && $scope.userPermissionToForm_update)) && formSettings.updateable) {
														newColumnDef.enableCellEdit = true;
														newColumnDef.cellTemplate = '<div class="ui-grid-cell-contents ' + newColumnDef.cellClass + '" ng-class="{\'a-input-text\': !grid.appScope.isRO(row)}">' +
															'<input ng-if="!grid.appScope.isRO(row)" type="text" ng-model="row.entity[col.field]" ng-change="grid.appScope.cellValueHasChanged(row.entity,col.field, row.entity[col.field])" /><div ng-if="grid.appScope.isRO(row)">{{row.entity[col.field]}}</div>' +
															'</div>';
													} else {
														newColumnDef.cellTemplate = basicTemplate;
													}
												}
												if (column.dataTypeOrig == "DATE") {
													newColumnDef.type = "date";
													var dateTimeTemplate = '<dfx-time-popup date-value="row.entity[col.field]" not-editable="grid.appScope.isRO(row)" date-type="\'' + column.format + '\'" on-changed-callback="grid.appScope.changeDate(row.entity, col.field, value)"></dfx-time-popup>';
													switch (column.format) {
														case "TIME":
															newColumnDef.enableFiltering = false;
															newColumnDef.cellFilter = "date: '" + common.longTimeFormat() + "'";
															if (((column.insertEditable && $scope.userPermissionToForm_insert) || (column.updateEditable && $scope.userPermissionToForm_update)) && formSettings.updateable) {
																newColumnDef.cellTemplate = dateTimeTemplate;
															}
															break;
														case "DATETIME":
															newColumnDef.cellFilter = "date: '" + common.longDateFormat(true) + " " + common.longTimeFormat() + "'";
															if (((column.insertEditable && $scope.userPermissionToForm_insert) || (column.updateEditable && $scope.userPermissionToForm_update)) && formSettings.updateable) {
																newColumnDef.cellTemplate = dateTimeTemplate;
															}
															break;
														default:
															newColumnDef.cellFilter = "date: '" + common.longDateFormat(true) + "'";
															if (((column.insertEditable && $scope.userPermissionToForm_insert) || (column.updateEditable && $scope.userPermissionToForm_update)) && formSettings.updateable) {
																newColumnDef.cellTemplate = '<dfx-calendar-edit date-value="row.entity[col.field]" not-editable="grid.appScope.isRO(row)" append-body="true" on-changed-callback="grid.appScope.changeDate(row.entity, col.field, value)"></dfx-calendar-edit>';
															}
															break;
													}
													newColumnDef.dateFormat = column.format ? column.format : 'DATE';
													newColumnDef.editableCellTemplate = newColumnDef.cellTemplate;
													newColumnDef.filterHeaderTemplate = '<div ng-repeat="colFilter in col.filters" class=""><dfx-calendar-edit date-value="colFilter.term" append-body="true" on-changed-callback="colFilter.term = grid.appScope.formatDateString(value, col.colDef.dateFormat)" place-holder="colFilter.placeholder"></dfx-calendar-edit></div>';
													newColumnDef.filters = [{
														condition: uiGridConstants.filter.LESS_THAN,
														placeholder: common.$translate.instant("STRCONST.LEANPORTAL.GREATER_THAN"),
														term: firstSearchTerm
													},
													{
														condition: uiGridConstants.filter.LESS_THAN,
														placeholder: common.$translate.instant("STRCONST.LEANPORTAL.LESS_THAN"),
														term: secondSearchTerm
													}]
												}
												else if ((column.dataTypeOrig === "NUMBER" || column.displayDecimals > 0) && !hasCustomDataType(column)) {
													if (column.summaryInfo) {
														$scope.options.showColumnFooter = $scope.options.showColumnFooter === undefined ? true : $scope.options.showColumnFooter;
														if (column.summaryInfo == "SUM") {
															newColumnDef.aggregationType = uiGridConstants.aggregationTypes.sum;
															newColumnDef.treeAggregationType = uiGridGroupingConstants.aggregation.SUM;
														}
														else if (column.summaryInfo == 'AVG') {
															newColumnDef.aggregationType = uiGridConstants.aggregationTypes.avg;
															newColumnDef.treeAggregationType = uiGridGroupingConstants.aggregation.AVG;
														}
														else if (column.summaryInfo == 'MIN') {
															newColumnDef.aggregationType = uiGridConstants.aggregationTypes.min;
															newColumnDef.treeAggregationType = uiGridGroupingConstants.aggregation.MIN;
														}
														else if (column.summaryInfo == 'MAX') {
															newColumnDef.aggregationType = uiGridConstants.aggregationTypes.max;
															newColumnDef.treeAggregationType = uiGridGroupingConstants.aggregation.MAX;
														}
														else if (column.summaryInfo == 'COUNT') {
															newColumnDef.aggregationType = uiGridConstants.aggregationTypes.count;
															newColumnDef.treeAggregationType = uiGridGroupingConstants.aggregation.COUNT;
														}
														var style = column.align ? column.align.toLowerCase() : 'right';
														newColumnDef.footerCellTemplate = '<div class="ui-grid-bottom-panel" style="text-align: ' + style + '">{{col.getAggregationValue() | number:1 }}</div>';
													}
													newColumnDef.type = "number";
													var fractionDigits;
													if (column.format) {
														fractionDigits = parseFractionFormat(column.format);
													}
													if (fractionDigits) {
														newColumnDef.cellFilter = "decimalFilter:" + fractionDigits[0] + ":" + fractionDigits[1] + "";
													} else {
														newColumnDef.cellFilter = "number:" + column.displayDecimals + "";
													}
													newColumnDef.filters = [{
														condition: uiGridConstants.filter.LESS_THAN,
														placeholder: common.$translate.instant("STRCONST.LEANPORTAL.GREATER_THAN"),
														term: firstSearchTerm
													},
													{
														condition: uiGridConstants.filter.LESS_THAN,
														placeholder: common.$translate.instant("STRCONST.LEANPORTAL.LESS_THAN"),
														term: secondSearchTerm
													}];
													newColumnDef.filterHeaderTemplate = '<div ng-repeat="colFilter in col.filters"><input type="text" ng-attr-placeholder="{{colFilter.placeholder || \'\'}}" aria-label="Filter for column" class="a-input-text" ng-model="colFilter.term" /></div>';
													newColumnDef.customTreeAggregationFinalizerFn = function (aggregation) {
														aggregation.rendered = aggregation.value;
													};
													if (((column.insertEditable && $scope.userPermissionToForm_insert) || (column.updateEditable && $scope.userPermissionToForm_update)) && formSettings.updateable) {
														newColumnDef.enableCellEdit = true;
														newColumnDef.cellTemplate = '<div class="ui-grid-cell-contents ' + newColumnDef.cellClass + '" ng-class="{\'a-input-text\': !grid.appScope.isRO(row)}">' +
															'<input ng-if="!grid.appScope.isRO(row)" type="text" ng-class="{\'disabled\': grid.appScope.options.disableMap[col.colDef.dbColumnName](row)}" ' +
															'ng-disabled="grid.appScope.options.disableMap[col.colDef.dbColumnName](row)" smart-float data-z-float class="' + newColumnDef.cellClass + '"ng-model="row.entity[col.field]" format-number ' +
															'dfx-focus="grid.appScope.options.focus(row,col)" ng-change="grid.appScope.cellValueHasChanged(row.entity,col.field, row.entity[col.field])" />' +
															'<div ng-if="grid.appScope.isRO(row)">{{row.entity[col.field]}}</div>' +
															'</div>';
													}
													else {
														newColumnDef.cellTemplate = basicTemplate;
													}
												}
												else if (column.isEnumItemId && !column.valueListDataValue) {
													// TODO: Add support for enum's iconCssClass. Now shows enum's cssClass only.
													// TODO: Add support for enum's cssClass for editable fields? Now visible only in read only mode.
													var filterOptions = [];
													for (var enumItem in $scope.enumItems) {
														if (enumItem == column.domain) {
															var tmpArr = [];
															for (var i = 0; i < $scope.enumItems[enumItem].length; i++) {
																var obj = {};
																obj.value = $scope.enumItems[enumItem][i].id;
																obj.label = $scope.enumItems[enumItem][i].nabbr;
																tmpArr.push(obj);
															}
															filterOptions = tmpArr;
														}
													}
													var enumItemTemplateReadOnly = '<div class="ui-grid-cell-contents">{{grid.appScope.getEnumNameById(row.entity, "' + column.domain + '","' + propertyName + '")}}</div>';
													var enumItemTemplateEditable = '<select class="a-dropdown dfx-grid-dropdown" ng-model="row.entity[col.field]" data-ng-options="field.value as field.label for field in col.colDef.editDropdownOptionsArray" ng-change="grid.appScope.cellValueHasChanged(row.entity,col.field, row.entity[col.field])"><option value="" selected></select>';
													// Cell template when column is only editable on insert.
													if ((column.insertEditable && $scope.userPermissionToForm_insert) && !column.updateEditable && formSettings.updateable && !$scope.forceNonEditable) {
														newColumnDef.cellTemplate = '<div ng-if="!row.entity.entityAspect.entityState.isAdded() || grid.appScope.isRO(row)">' + enumItemTemplateReadOnly + '</div>' +
															'<div ng-if="row.entity.entityAspect.entityState.isAdded() && !grid.appScope.isRO(row)">' + enumItemTemplateEditable + '</div>';
													}
													// Cell template when column is editable on update.
													else if ((column.updateEditable && $scope.userPermissionToForm_update) && formSettings.updateable && !$scope.forceNonEditable) {
														newColumnDef.cellTemplate = '<div ng-if="grid.appScope.isRO(row)">' + enumItemTemplateReadOnly + '</div>' +
															'<div class="dfx-grid-dropdown-container" ng-if="!grid.appScope.isRO(row)">' + enumItemTemplateEditable + '</div>';
													}
													// Default cell template.
													else {
														newColumnDef.cellClass = function (grid, row, col) {
															var oldValues = column.align ? "text-" + column.align + " " + (column.cssClassColumnCell || '') : "text-left" + " " + (column.cssClassColumnCell || '');
															return oldValues + ' ' + grid.appScope.getEnumCssClass(column.domain, row.entity[propertyName]);
														};
														newColumnDef.cellTemplate = enumItemTemplateReadOnly;
													}
													newColumnDef.filter = { type: uiGridConstants.filter.SELECT, selectOptions: filterOptions, term: firstSearchTerm };
													newColumnDef.filterHeaderTemplate = '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters"><select class="a-dropdown" ng-model="colFilter.term" ng-options="option.value as option.label for option in colFilter.selectOptions"> <option value=""></option> </select></div>';
													newColumnDef.editDropdownOptionsArray = filterOptions;
													newColumnDef.editDropdownIdLabel = 'value';
													newColumnDef.editDropdownValueLabel = 'label';
													newColumnDef.isEnumItemId = true;
												}
												else {
													newColumnDef.filters = [{ term: firstSearchTerm }];
													newColumnDef.filterHeaderTemplate = '<div ng-repeat="colFilter in col.filters"><input type="text" class="a-input-text" ng-model="colFilter.term" /></div>';
												}
												if (column.columnType == "eLink") {
													if (column.typeParam1.indexOf("TABID;") != -1) {
														var tabId = column.typeParam1.replace("TABID;", "");
														var tabs = common.getTabs(common.$route.current.portalId);
														var route = "";
														angular.forEach(tabs, function (tab) {
															if (tab.tabId == tabId) {
																route = getTabRoute(tab);
															}
															if (tab.subTabs && tab.subTabs.length > 0) {
																for (var i = 0; i < tab.subTabs.length; i++) {
																	if (tab.subTabs[i] && tab.subTabs[i].tabId == tabId) {
																		route = getTabRoute(tab.subTabs[i]);
																		break;
																	}
																}
															}
														});
													}
													if (route) {
														newColumnDef.cellTemplate = '<a ng-click="grid.appScope.goToRoute(' + route + ')" class="a-link a-link__align-center">{{row.entity.' + propertyName + '}}</a>'; //use appScope to access directive controller scope ("normal" scope is bound to ui-grids scope).
													}
												}
												// Hard coded field to show document links
												if (column.columnName === 'DATA_PARENTS_DOCUMENT') {
													newColumnDef.cellTemplate = '<dfx-doc parent="row.entity" icon-and-title-only="true"></dfx-doc>';
												}
												if (column.isTableDropDown && (column.insertEditable && $scope.userPermissionToForm_insert) && formSettings.updateable && !$scope.forceNonEditable) {
													var dropdownFormId = columnSettings[dbColumn].selectFormId;
													var dropdownFormVersion = columnSettings[dbColumn].selectFormVersion;
													var lookupKey = columnSettings[dbColumn].lookupKey;

													// Create propertyMap, showInUi and filterColumns from lookups.
													$scope.propertyMap[propertyName] = columnSettings[dbColumn].lookupName.split(";")
														.filter(function (param) { return param.split("|")[1].indexOf("N") === -1; })   // Get the ones that are used as restriction fields. 
														.map(function (param) {
															var value = param.slice(0, param.indexOf("|"));
															return [dbColumn, value, true];
														});
													$scope.showInUi[propertyName] = columnSettings[dbColumn].lookupName.split(";")
														.filter(function (param) {
															return param.split("|")[1].indexOf("N") === -1;
														}).map(function (param) { return param.slice(0, param.indexOf("|")); });
													$scope.filterColumns[propertyName] = columnSettings[dbColumn].lookupName.split(";")
														.map(function (param) { return param.slice(0, param.indexOf("|")); });

													// Create dfxTypeahead using the created propertyMap and filterColumns.
													newColumnDef.cellTemplate = '<div><dfx-type-ahead append-body="true" placeholder="row.entity[col.field] || col.name.toUpperCase()" form-id=' + dropdownFormId + ' form-version="\'' + dropdownFormVersion +
														'\'" filter-columns="grid.appScope.filterColumns[col.field]" show-in-ui="grid.appScope.showInUi[col.field]" property-map="grid.appScope.propertyMap[col.field]" select-id="{ ' + lookupKey + ' : row.entity[col.field] }"' +
														' load-on-focus="true" on-selection-callback="grid.appScope.onItemChange(row.entity, col.field, value, col.colDef.dbColumnName)"></dfx-type-ahead></div>';
													newColumnDef.editableCellTemplate = newColumnDef.cellTemplate;
												}
												if ($scope.useGrouping) {
													newColumnDef.cellTemplate = '<div ng-if="!col.grouping || col.grouping.groupPriority === undefined || col.grouping.groupPriority === null || ( row.groupHeader && col.grouping.groupPriority === row.treeLevel )" class="ui-grid-cell-contents" title="TOOLTIP">{{COL_FIELD CUSTOM_FILTERS}}</div>';
												}
												if ($scope.useColumnWidths) {
													newColumnDef.width = $scope.getColumnWidth(column);
												}

												if ($scope.usePinning && column.fixedPosition) {
													setFixedColumn(newColumnDef, column.fixedPosition);
												}
												newColumnDef.minWidth = 60;
												newColumnDef = customControllerColDefs(newColumnDef);
												$scope.options.columnDefs.push(newColumnDef);
											}
										});

										function setFixedColumn(column, fixedPosition) {
											switch (fixedPosition) {
												case 'LEFT':
													column.pinnedLeft = true;
												case 'RIGHT':
													column.pinnedRight = true;
											}
										}

										function hasCustomDataType(column) {
											return $scope.options.customColDefs &&
												$scope.options.customColDefs.hasOwnProperty(column.columnName) &&
												$scope.options.customColDefs[column.columnName].hasOwnProperty('treeAggregationType');
										}

										function hasControllerColumnDefs(dbColumnName) {
											/*Loop through columnDefs and check that there isn't controller spesific columnDefs*/
											for (var i = 0; i < $scope.options.columnDefs.length; i++) {
												if ($scope.options.columnDefs[i].dbColumnName == dbColumnName) {
													return false
												}
											}
											return true;
										}

										/**
										 * Overwrite colDef values with ones specified in controller.
										 * @param {Object} colDef - Column to overwrite.
										 */
										function customControllerColDefs(colDef) {
											if ($scope.options.customColDefs && $scope.options.customColDefs[colDef.dbColumnName]) {
												angular.forEach($scope.options.customColDefs[colDef.dbColumnName], function (value, key) {
													colDef[key] = value;
												});
											}
											return colDef;
										}

										$scope.options.columnDefs.sort(function (a, b) {
											return parseFloat(a.pos) - parseFloat(b.pos);
										});
										// Create delete column as the last column.
										if (formSettings.updateable && $scope.userPermissionToForm_delete && !$scope.forceNonEditable && $scope.showDeleteButton != false) {
											var delCol = {
												headerCellTemplate: '<div></div>',
												width: 60,
												unit: 'PX',
												maxWidth: 100,
												cellTemplate: '<div class="ui-grid-cell-contents column-btn-container"><button class="a-button error deleteRow" ng-click="grid.appScope.deleteRow(row)"><i class="far fa-trash-alt"></i></button></div>',
												field: 'delete',
												name: 'delete',
												pos: 999
											}
											$scope.options.columnDefs.push(delCol);
										}									
										$scope.setTableColumnWidth();
										$scope.initReadyPromise.resolve();  //Render the directive template after all columns are determined.
									});
								});
							}
						});
					});
				});
			}
		}
	}]);

	app.filter('decimalFilter', function () {
		return decimalFormat;
	});

export default app