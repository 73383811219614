/**
      * @class LeanPortalSPA.dfxTasks
      * @memberOf LeanPortalSPA 
	  * @description
	  * Tasks Directive & Controller
	  * The directive capsulates the task table presentation. The directive takes care of
	  * resource task reading and listens 'resource'-parameter changes to keep up with .
	  * The visible colummns are controlled by 'statusCmd'-parameter. This can be changed
	  * to more generic solution when needed.
	  * The "owner" is responsiple for implementing all the actions
	  */
var tasksCtrlId = 'tasksctrl';
var tasksModule=angular.module('manudir.dfxTasks',[])
tasksModule.directive('dfxTasks', [function () {
    return {
        restrict: 'E',
        scope: {},
        controller: tasksCtrlId,
        controllerAs: 'ctrl',
        bindToController: {
            allowCollapse: '=?',
            cardLayout: '=?',
            resource: '=?',
			showResourceFilter: '=?',  
            resourceFilterValue: '=?', 
            resourceFilterRsrcId: '=?',
            resourceHighlight: '=?',
            work: '=?',
            taskList: '=',
            statusCmd: '=',
            orderCmd: '=',
            layout: '=?', // CARD, TABLE or TABLE_OPERATORVIEW
            noHeader: '=?',
            showLoginButton: '=?',
            specialTask: '=?',  // if available, then drawn as a first card
            autoSelectFirstTask: '<?',
            selectAfterReady: '=?',
            numPerPage: '=?',
			onBeforeStatusChangedCallback: '&',
            onStatusChangeCallback: '&',
            onSelectionChangedCallback: '&',
            onTryLayoutChangeCallback: '&?',
            onLoadingReadyCallback: '&',
            onControllerCallback: '&',
            onCloseCallback: '&?',
			parentView: '=?', // Name of parent view if acting as sub view. For example WORKS.
			noTasksResetOnWorkChange: '=?' ,// Do not reset tasks when work changes, but catenate instead. Default = false.
			webFormSortEnabled: '=?' // Determines whether the sorting of the web form is used or not. If not, the sorting of the web form is overridden by this directive's getDefaultSortCriteria();
        },
        template: require('./dfxTasks.html')
    };
}]);

tasksModule.controller(tasksCtrlId, ['$scope', 'manDatacontext', 'usersettings', 'common', '$uibModal', 'signalRManager', 'commonDatacontext', tasks]);
function tasks($scope, manDatacontext, usersettings, common, $uibModal, signalRManager, commonDatacontext) {
	var ctrl = this;

	ctrl.plannedStartDateFormat = 'shortDate';
    var current = null;
    var currentWork = null;
    // API offered for the client with onControllerCallback
    var service = {
        addTask: addTask,
        getTask: getTask,
        selectTask: selectTask,
        changeLayout: changeLayout,
        onStatusChangedCallback: ctrl.onStatusChangedCallback,
        triggerLoading: triggerLoading
    };

    ctrl.$onInit = function () {
        ctrl.formId = 25102;
        // Determines where and which amounts are shown in the resourceTaskTab
        ctrl.shownAmounts = [{
            amountsAreShown: false
        }];
		ctrl.autoSelectFirstTask = (ctrl.autoSelectFirstTask != undefined) ? ctrl.autoSelectFirstTask : true;
		ctrl.noTasksResetOnWorkChange = (ctrl.noTasksResetOnWorkChange !== undefined ? ctrl.noTasksResetOnWorkChange : false);
		ctrl.webFormSortEnabled = (ctrl.webFormSortEnabled !== undefined ? ctrl.webFormSortEnabled : false);
        // Active page
        ctrl.currentPage = 1;
        // numPerPage will define how many tasks are viewed
        if (typeof ctrl.numPerPage == 'undefined') {
            ctrl.numPerPage = 30;
        }
        // how many of these sets can be accesed directly 
        ctrl.maxPages = 5;
        ctrl.showWorkSearchBar = false;

        $scope.CardLayoutEnum = {
            DEFAULT: { id: 'DEFAULT', name: 'taskCard_Default.html' },
            SPACIOUS: { id: 'SPACIOUS', name: 'taskCard_Spacious.html' }
        };
        ctrl.cardLayoutEnum = (typeof ctrl.cardLayout == 'undefined' ? $scope.CardLayoutEnum.DEFAULT : $scope.CardLayoutEnum[ctrl.cardLayout]);

        ctrl.layout = (typeof ctrl.layout == 'undefined' ? (ctrl.cardLayoutEnum == $scope.CardLayoutEnum.SPACIOUS ? 'TABLE_OPERATORVIEW' : 'TABLE') : (ctrl.layout ? ctrl.layout : usersettings.getPersonalSettingValue('dfxTasksLayout', ctrl.layout)));

        //var timeStamsAvailable = null;
        // This will hold viewed tasks
        ctrl.tasks = [];
        ctrl.filteredTasks = [];
        ctrl.temporaryTaskArr = [];
        $scope.sortTypeDefault = getDefaultSortCriteria();
		$scope.sortType = ctrl.webFormSortEnabled ? null : ctrl.orderCmd == 'ORDER_POS' ? ['pos'] : $scope.sortTypeDefault.slice();
        // UI fetches the value from ctrl.visible associative array filled in init()
		ctrl.visible = [];

        if (typeof ctrl.allowCollapse === 'undefined') {
            ctrl.allowCollapse = true;
        }

        ctrl.onControllerCallback({ childCtrl: service });

        init();

    };


	$scope.$watch('ctrl.specialTask', function (value) {
		if (value) {
			// Remove element from the normal task list
			var index = ctrl.tasks.indexOf(value);
			if (index > -1) {
				ctrl.tasks.splice(index, 1);
			}
			selectTask(value, true);
		}
	});

	$scope.$watch('sortType', function (value) {
		// don't scroll to selected when sorting is changed
		ctrl.scrollTo = null;
	});

	$scope.$watchGroup(['ctrl.resource', function () {
	}], function (newValue) { //trigger on resource change
		//if we have task list pased to the directive, all checks will happen in parent controller
		if (!ctrl.taskList || ctrl.taskList.length == 0) {
			// Different resource selected.
			if (newValue[0] !== undefined && ((ctrl.resource && current != ctrl.resource) || (signalRManager && signalRManager.getLastCallback().result && current && current.resId == signalRManager.getLastCallback().result))) {
				ctrl.selected = null;
				ctrl.filteredTasks = [];
				getResourceTasks(ctrl.resource, 0, 1000, true);
			}
		}
	});

	$scope.$watch('ctrl.work', function (newValue, oldValue) {
		if (oldValue !== undefined && (ctrl.work && currentWork != ctrl.work)) {
			if (!ctrl.noTasksResetOnWorkChange) {
				ctrl.tasks = [];
				ctrl.filteredTasks = [];
			}
			ctrl.selected = null;
			getResourceTasks(null, 0, 1000, !ctrl.noTasksResetOnWorkChange, null, ctrl.work);
		}
	});

	/**
	 * Gets form settings from database if not queried before. Sets them to variable.
	 * @returns {promise} Promise when done.
	 */
	function getFormSettings() {
		if (!ctrl.formSettings) {
			commonDatacontext.getFormSettings(ctrl.formId).then(function (data) {
				ctrl.formSettings = data;
				setColumnDateTimeFormat();
			});
		}
	}

	function setColumnDateTimeFormat() {
		angular.forEach(ctrl.formSettings.columnSettings, function (column) {
			column.columnName == "START_CUR" ? (column.format == "DATETIME" ? ctrl.plannedStartDateFormat = "short" : ctrl.plannedStartDateFormat = "shortDate") : null; 
			column.columnName == "END_CUR" ? (column.format == "DATETIME" ? ctrl.plannedEndDateFormat = "short" : ctrl.plannedEndDateFormat = "shortDate") : null; 
		});
	}

	/**
	 * Changes the layout and takes care that the currently selected 
	 * task will be visible
	 */
	ctrl.changeLayout = function (layout) {
		if (ctrl.onTryLayoutChangeCallback) {
			ctrl.onTryLayoutChangeCallback({ layout: layout });
		}
		else {
			changeLayout(layout);
		}
		common.triggerResizeEvent(100);
    };

	//helper funciton
	function changeLayout(layout) {
		ctrl.layout = layout;
		usersettings.setPersonalSettingValue('dfxTasksLayout', ctrl.layout);
		if (ctrl.layout == 'TABLE') { ctrl.setColumnVisibility(); }
		if (ctrl.selected) {
			selectTask(ctrl.selected, true);
		}
	}

    ctrl.changeTaskView = function (commandId, groupIndex) {
        if (ctrl.resource) {
            if (commandId.indexOf("SHOW_RANGE_") >= 0) {  //restriction command relates to range
                ctrl.range = commandId;
            }
            else {
                ctrl.statusCmd = commandId;
            }
            // Execute new range selection to BLC
            getResourceTasks(ctrl.resource, 0, 1000, true);
        }
    };

    ctrl.executeToolCmd = function (commandId) {
        if (commandId === 'TOOL_START_MULTIPLE_TASKS') {
            manDatacontext.getLookups(lang, true).then(function (data) {
                if (data && data[0] && data[0].Enums) {
                    var enableTimeStamps = false;
                    var allowOperWithoutUserLogin = false;
                    var operStartEmpRequired = false;
                    // MANUFPORTAL_DEFAULTS value01 and value02 dictate how time stamp is used
                    var defaults = data[0].Enums["MANUFPORTAL_DEFAULTS"];
                    var enumItem_TIM = defaults ? common.getEnumRowById(defaults, 'TIM') : null;
                    if (enumItem_TIM && enumItem_TIM.extraSelect.length >= 2) {
                        enableTimeStamps = (enumItem_TIM.extraSelect[0] == 'OPER_TIMESTAMPS');
                        allowOperWithoutUserLogin = (enumItem_TIM.extraSelect[1] == 'NOT_MANDATORY');
                    }
                    // If time stamps are not used, but employee is required when starting the operation
                    var formDefaults = data[0].Enums["FORM_DEFAULTS"];
                    var worOperStart = formDefaults ? common.getEnumRowById(formDefaults, 'WOR_OPERSTART') : null;
                    if (worOperStart && worOperStart.extraSelect.length >= 1) {
                        operStartEmpRequired = !ctrl.enableTimeStamps && (worOperStart.extraSelect[0] == 'EMPID_REQ');
                    }
                    common.$translate(['STRCONST.PUBLIC.TXT_WORK', 'STRCONST.PUBLIC.TXT_WORK_NAME_L', 'STRCONST.WOR_OPERATION.COL_NAME', 'STRCONST.WOR.COL_START_CUR', 'STRCONST.PUBLIC.TXT_OPERAT_STATUS']).then(function (translations) {
                        startMultipleWorkOperations(data[0].Enums, enableTimeStamps, allowOperWithoutUserLogin, operStartEmpRequired, translations);
                    });
                }
            });
        }
        function startMultipleWorkOperations(lookupEnums, enableTimeStamps, allowOperWithoutUserLogin, operStartEmpRequired, translations) {
            var columnDefs = [
                { name: translations['STRCONST.PUBLIC.TXT_WORK'], field: "workId" },
                { name: translations['STRCONST.PUBLIC.TXT_WORK_NAME_L'], field: "workName" },
                { name: translations['STRCONST.PUBLIC.COL_NAME'], field: "operatName" },
                { name: translations['STRCONST.PUBLIC.COL_START_CUR'], field: "plannedStartStr" },
                //{ name: strConst.WOR$COL_START_CUR, field: "plannedStart", type: "date", cellFilter: "date:'shortDate' : 'UTC'" },
                { name: translations['STRCONST.PUBLIC.TXT_OPERAT_STATUS'], field: "statusName" },
            ];
            var data = ctrl.tasks.filter(function (task) { return task.canStart; });
            data.forEach(function (task) {
                task.plannedStartStr = task.plannedStart ? task.plannedStart.toLocaleDateString().replace(/‎/g, '') : '';
            });
            var statusChangeGridOptions = {
                data: data,
                columnDefs: columnDefs,
                enableRowSelection: true,
                enableRowHeaderSelection: true,
                enableFullRowSelection: true,   // allow both clicking on the row, and also clicking on the rowHeader
                multiSelect: true,
                //modifierKeysToMultiSelect: true, // allow selecting multiple rows only if the Ctrl-Key is used when selecting
                enableSorting: true,
                enableFiltering: true,
                rowHeight: 30,
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                },
                selectionRowHeaderWidth: 30, //?
                //rowTemplate: "<div ng-dblclick=\"grid.appScope.onDblClick(row)\" ng-click=\"grid.appScope.fnOne(row)\" ng-repeat=\"col in colContainer.renderedColumns track by col.colDef.name\" class=\"ui-grid-cell\" ui-grid-cell></div>"
            }
            var modalInstance = $uibModal.open({
                animation: true,
                backdrop: false,
                windowClass: 'app-modal-window',
                template: require('./startMultipleWorOperationsModal.html'),
                controller: ['$scope', '$uibModalInstance', 'usersettings', function ($scope, $uibModalInstance, usersettings) {
                    var ctrl = this;
                    var lastUserInfo = usersettings.getLastUserInfo();
                    $scope.salaryCodes = lookupEnums['SALARYCODE'].filter(function (e) {
                        return e && e.extraSelect && e.extraSelect.length >= 3 && e.extraSelect[3] === 'RES_SELECT';
                    });
                    $scope.empValidityMessage = null;
                    $scope.lookupEnums = lookupEnums;
                    $scope.enableTimeStamps = enableTimeStamps;
                    $scope.allowOperWithoutUserLogin = allowOperWithoutUserLogin;
                    $scope.operStartEmpRequired = operStartEmpRequired;

                    $scope.empId = lastUserInfo ? lastUserInfo.empId : '';
                    if ($scope.empId) {
                        initStampValues($scope.empId);
                    }
                    $scope.shift = null;
                    $scope.salaryCode = null;
                    $scope.empIsMandatory = operStartEmpRequired || enableTimeStamps;

                    $scope.empSelected = function (value) {
                        $scope.empId = value ? value.empId : '';
                        if ($scope.empId) {
                            usersettings.setLastUserInfo(value.empId, value.name);
                        }
                        initStampValues($scope.empId);
                    }
                    $scope.statusChangeGridOptions = statusChangeGridOptions;
                    $scope.isRowsSelected = function () {
                        return ($scope.gridApi && $scope.gridApi.selection && $scope.gridApi.selection.getSelectedRows().length);
                    };
                    $scope.onApply = function () {
                        manDatacontext.startMultipleWorOperations($scope.gridApi.selection.getSelectedRows(), $scope.empId, $scope.shift, $scope.salaryCode).then(function (wos) {
                            $uibModalInstance.close();
                        })
                    };
                    $scope.onCancel = function () {
                        $uibModalInstance.close();
                    };
                    function initStampValues(empId) {

                        if (empId) {
                            // We'll need to fetch some defaults from users last login
                            manDatacontext.getWorkReportDefaults(empId).then(function (data) {
                                if (data && data.length == 1) {
                                    setEmpLoggedIn(empId, true);
                                    if (data[0].shift) {
                                        $scope.shift = data[0].shift;
                                    }
                                    if (data[0].salaryCode) {
                                        var sel = common.getEnumRowById(lookupEnums['SALARYCODE'], data[0].salaryCode);
                                        if (!common.getEnumNameById($scope.salaryCodes, data[0].salaryCode)) {
                                            $scope.salaryCodes.push(sel);
                                        }
                                        $scope.salaryCode = data[0].salaryCode;
                                    }
                                }
                                else {
                                    setEmpLoggedIn(empId, false);
                                }
                            });
                        }
                        else {
                            setEmpLoggedIn(empId, false);
                        }
                    }

                    function setEmpLoggedIn(emp, stampExists) {
                        if (!emp && $scope.empIsMandatory) {
                            $scope.empValidityMessage = common.$translate.instant("STRCONST.TIM.ERR_EMP_REQUIRED");
                        }
                        else if (emp && !stampExists && $scope.empIsMandatory && !$scope.allowOperWithoutUserLogin) {
                            $scope.empValidityMessage = common.$translate.instant("STRCONST.TIM.ERR_EMP_NOT_LOGGED_IN");
                        }
                        else {
                            $scope.empValidityMessage = null;
                        }
                    }

                }],
                scope: $scope
            });
        }
    };

	/**
	 * Format the number to fixed amount of decimals
	 * @param {number to be formatted} i 
	 * @param {number of decimals shown} decimals
	 */
    $scope.formatNumber = function (i, decimals) {
        if (i === null) return null;
        decimals = (decimals === undefined ? 1 : decimals);
        return i.toFixed(decimals);
    };

	/**
	 * Add new task to task list.
	 * @param {Object} newTask - Task to be added to the list.
	 * @param {boolean} removeSpecialTask - If special task is to be removed.
	 */
	function addTask(newTask, removeSpecialTask) {
		if (newTask && ctrl.tasks) {
			ctrl.tasks.push(newTask);
			ctrl.taskSelected(newTask, true, removeSpecialTask);
		}
	}

	ctrl.filterChanged = function (filterValue) {
		ctrl.resourceFilterValue = filterValue;
	}

	ctrl.filterResourceTasks = function (resourceTasks) {
		if (ctrl.resourceFilterValue == 'CurrentResource' && ctrl.resourceFilterRsrcId) {
			return resourceTasks.filter(function (item) {
				return item.resId == ctrl.resourceFilterRsrcId;
			});
		}
		return resourceTasks;
	};

	/**
	 *  Returns sorting criteria for given configuration.
     *  @return {string} - Sorting criteria.
	 */
	function getDefaultSortCriteria() {
		return ctrl.orderCmd == 'ORDER_WORKID_POS' ? ['pos'] :
				ctrl.orderCmd == 'ORDER_POS' ? ['pos'] :
				ctrl.orderCmd == 'ORDER_ACTUALEND' ? ['-actualEnd'] :
				ctrl.orderCmd == 'ORDER_PLANNED_START' ? ['-isStarted', 'plannedStart','workId'] :
				ctrl.orderCmd == 'ORDER_PRIORITY_PLANNED_START' ? ['worklistIndicator', '-isStarted', 'plannedStart','serialId'] :
				['-isStarted', 'plannedStart','workId'];
	}

	/**
	 * Return the matching task if ctrl.tasks contains it
	 */
	function getTask(recId, workId, operatId, serialId) {
		for (var i = 0; i < ctrl.tasks.length; i++) {
			var t = ctrl.tasks[i];
			if (recId && recId == t.recId) {
				return t;
			}
			if (workId && workId == t.workId && operatId && operatId == t.operatId && (!serialId || serialId == t.serialId)) {
				return t;
			}
		}
		return null;
	}


	/**
	 * Gets tasks for a resource from local database. 
	 * First check if the tasks are already read. If they are, then discard the resource.
	 * The tasks are added to resources 'tasks'- member variable.
	 * @param {Object} [resource] - Resource tasks are fetched for.
	 * @param {number} [offset] - Starting point of incremental reading.
	 * @param {number} [size] - Size of incremental reading.
	 * @param {boolean} [requestedByUi] - loading indication and GUI blocking
	 * @param {boolean} [recursion] - recursion indication that API is called recursively
     * @param {Object} [work] - Work whose tasks are fetched for.
	 */
	function getResourceTasks(resource, offset, size, requestedByUi, recursion, work) {
		if (resource || work) {
			// Reset arrays unless getResourceTasks is calling itself recursively
			if (!recursion) {
				ctrl.temporaryTaskArr = [];
				if (requestedByUi) {
					ctrl.tasks = [];
				}
			}
			current = resource;
			currentWork = work;
			var resId = resource ? resource.resId : null;
			var workId = work ? work.workId : null;
			manDatacontext.getResourceTasks(resId, workId, null, null, offset, size, requestedByUi, ctrl.statusCmd, ctrl.range, ctrl.orderCmd).then(function (data) {
				if (requestedByUi) {
					ctrl.tasks = common.unique(ctrl.tasks.concat(data), 'recId');
				}
				else {
					// loading done in background and the result is merged to viewed ctrl.tasks array
					ctrl.temporaryTaskArr = common.unique(ctrl.temporaryTaskArr.concat(data), 'recId');
					applyFilter();
				}
				if (!ctrl.selected && ctrl.tasks.length > 0 && ctrl.autoSelectFirstTask) {
					ctrl.selected = ctrl.tasks[0];
					ctrl.onSelectionChangedCallback({ task: ctrl.selected });
				}
				if (!ctrl.autoSelectFirstTask && ctrl.selectAfterReady && work) {
					data.forEach(function (task) {
						if (ctrl.selectAfterReady && task.ResourceWork && task.ResourceWork.recId == work.recId && task.operatId == ctrl.selectAfterReady) {
							ctrl.selected = task;
							ctrl.onSelectionChangedCallback({ task: ctrl.selected });
						}
					});
				}
				applyFilter();
				// Recursion: continue reading, if not all tasks are read
				if (data.length == size) {
					getResourceTasks(resource, offset + size, size, requestedByUi, true, work);
				}
				else {
					if (requestedByUi) {
						ctrl.onLoadingReadyCallback({ tasks: ctrl.tasks, requestedByUi: requestedByUi });
					}
					else {
						// replace viewed array with refreshed one
						ctrl.tasks = ctrl.temporaryTaskArr.slice();
						applyFilter();
						if (ctrl.selected) {
							selectTask(ctrl.selected, true);
						}
					}
				}
			});
		}
	}
	function applyFilter() {
		ctrl.search(null, ctrl.rstBtn);
	}

    ctrl.getSpecialTaskTitle = function (task) {
        var ret = common.$translate.instant("STRCONST.PUBLIC.TXT_SEARCHED");
        if (task.resId !== ctrl.resource.resId) {
            ret = common.$translate.instant("STRCONST.PUBLIC.TXT_SEARCHED");
        }
        else if (task.status === 'FIN') {
            ret = common.$translate.instant("STRCONST.PUBLIC.TXT_READY");
        }
        return ret + ':';
    };


    ctrl.search = function ($event, rstBtnVal, forceRefresh) {
        if (rstBtnVal) {
            ctrl.rstBtn = rstBtnVal;
        }
        var tasksToFilter = [];
        if (rstBtnVal == 'CurrentResource') {
            for (var i = 0; i < ctrl.tasks.length; i++) {
                if (ctrl.tasks[i].resId == ctrl.resource.resId) {
                    tasksToFilter.push(ctrl.tasks[i]);
                    break;
                }
            }
        }
        else {
            tasksToFilter = ctrl.tasks;
        }
        if (ctrl.searchText) {
            ctrl.filteredTasks = tasksToFilter.filter(function (item) {
                return common.textContains(item.workName, ctrl.searchText) || common.textContains(item.workId, ctrl.searchText);
            });
        }
        else {
            ctrl.filteredTasks = tasksToFilter;
        }
        if (ctrl.selected && ctrl.filteredTasks.length >= ctrl.numPerPage) {
            var sortedTasks = common.$filter('orderBy')(ctrl.filteredTasks, $scope.sortType);
            ctrl.currentPage = Math.floor(sortedTasks.indexOf(ctrl.selected) / ctrl.numPerPage) + 1;
            return true;
        }
    };

	function init() {
		getFormSettings();
		setColumnVisibility();
		ctrl.tasks = [];
		if (typeof ctrl.showLoginButton === 'undefined') {
			ctrl.showLoginButton = true;
		}
		//if there are tasks fetched for the work, use them.
		if (ctrl.taskList && ctrl.taskList.length > 0) {
			ctrl.tasks = ctrl.taskList;
		}
		else {
			getResourceTasks(ctrl.resource, 0, 1000, true);
		}
		manDatacontext.getLookups(lang, true).then(function (data) {
            if (data && data[0] && data[0].Enums) {
                if (data[0].Enums['WORSTATUS']) {
                    ctrl.worStatusRows = data[0].Enums['WORSTATUS'];
                }
                //This determines where and which amounts are shown in the resourcetasktab
                var enumItem_RESOURCETASKLIST = common.getEnumRowById(data[0].Enums["MANUFPORTAL_DEFAULTS"], 'RESOURCETASKLIST');
                //== 1 amounst are shown in the topnav, == 2 amounts are shown in the topnav and in the left tasklist cards.
                if (enumItem_RESOURCETASKLIST && enumItem_RESOURCETASKLIST.columns && enumItem_RESOURCETASKLIST.columns.VALUE11 == 2) {
                    ctrl.shownAmounts.amountsAreShown = true;
                }
                else {
                    ctrl.shownAmounts.amountsAreShown = false;
                }    
            }

		});
	}

    ctrl.getTaskStatusColor = function (task) {
        var ret = 'task-status-color-value-undefined';
        if (ctrl.worStatusRows && task) {
            var statusEnum = common.getEnumRowById(ctrl.worStatusRows, task.status);
            ret = 'task-status-color-value-' + (statusEnum && statusEnum.extraSelect[0] ? statusEnum.extraSelect[0] : 'undefined');
        }
        return ret;
    };

    ctrl.isScrolledTo = function (task) {
        if (!task || !ctrl.scrollTo) {
            return false;
        }
        return task.recId == ctrl.scrollTo.recId && task.serialId == ctrl.scrollTo.serialId;
    };

	/**
	 * Returns true if given task is selected, otherwise false.
	 */
    ctrl.isSelected = function (task) {
        return this.selected == task;
    };

    ctrl.onRangeChanged = function (range) {
        // Execute new range selection to BLC
        if (ctrl.resource) {
            ctrl.range = range;
            getResourceTasks(ctrl.resource, 0, 1000, true);
        }
    };

	/**
	 * Occurs before status change - veto possibility.
	 */
	ctrl.onBeforeStatusChanged = function (task, status) {
		return ctrl.onBeforeStatusChangedCallback({ task: task, status: status });
	}

	/**
	 * Occurs after status change.
     * @param {Object} task - Task
     * @memberOf LeanPortalSPA.dfxTasks
	 */
    ctrl.onStatusChanged = function (task) {
        ctrl.selected = null;
        ctrl.autoSelectFirstTask = ctrl.layout == 'TABLE' ? false : true;
        getResourceTasks(ctrl.resource, 0, 1000, true);
        ctrl.onStatusChangeCallback({ task: task });
    };

	/**
	 * Opens Dialog to view completed tasks
	 */
	ctrl.openCompletedTasksModal = function () {
		$scope.modalTitle = ctrl.resource.resId + '  ' + ctrl.resource.name + ' - ' + common.$translate.instant("STRCONST.PUBLIC.TXT_COMPLETED");
		var modalInstance = $uibModal.open({
			animation: true,
			backdrop: false,
			windowClass: 'app-modal-window',
			template: require('./modalCompletedTasks.html'),
			controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
				$scope.onApply = function () {
					$uibModalInstance.close();
				};
				$scope.onCancel = function () {
					$uibModalInstance.close();
				};
			}],
			scope: $scope
		});
	};

	/**
	 * Opens Status change dialog for the selected task
	 * @param {Object} task - Task opened for.
	 * @param {string} status - Current status.
     * @memberOf LeanPortalSPA.dfxTasks
	 */
    ctrl.openStatusChangeModal = function (task, status) {
        ctrl.onStatusChangeCallback({ task: task, status: status });
    };

	function selectTask(task, scrollTo) {
        if (ctrl.filteredTasks.indexOf(task) === -1 && ctrl.tasks.indexOf(task) >= 0) {
            // Task is found but is filtered out <=> clear filter
            ctrl.searchText = '';
            applyFilter();
        }
        if (ctrl.filteredTasks.length >= ctrl.numPerPage && ctrl.filteredTasks.indexOf(task) > 0) {
			// Correct page must be selected respect to sorted array
            var sortedTasks = common.$filter('orderBy')(ctrl.filteredTasks, $scope.sortType);
			ctrl.currentPage = Math.floor(sortedTasks.indexOf(task) / ctrl.numPerPage) + 1;
		}
		if (scrollTo) {
			ctrl.scrollTo = task;
		}
		ctrl.selected = task;
	}

	/**
	 * Sets column visibility in table depending on status and/or layout.
	 */
	function setColumnVisibility() {
		// common columns for all task table configurations
		var commonColumns = ['operatId', 'status'];
		// columns for given configuration
		var statusColumns = commonColumns.concat(
			ctrl.statusCmd == 'SHOW_COMPLETED' ? ['workName', 'operatName', 'itemId', 'completedQty', 'actualStart', 'actualEnd', 'completedLoad'] :
			(ctrl.statusCmd == 'SHOW_OPEN' || ctrl.statusCmd == 'SHOW_ALL') && ctrl.layout == 'TABLE' ? ['workName', 'operatName', 'itemId', 'qty', 'plannedStart', 'plannedEnd', 'load', 'canStart'] :
			ctrl.layout == 'TABLE_OPERATORVIEW' ? ['worklistIndicator', 'serialId', 'itemName', 'drawing', 'drawingRevision', 'techInfo1', 'techInfo2', 'plannedEnd', 'workPlannedEnd'] :
			[]);

		//column depending on timeStamps availability 
		//	var loggedEmpCol = statusColumns.concat(ctrl.isTimeStampsAvailable ? ['loggedEmp'] : []);

		// columns depending on the resourceTask/Works view
		var visibleColumns = statusColumns.concat(
			ctrl.resource ? ['workId', 'workName'] : ctrl.taskList ? ['resId'] : []);

		visibleColumns.forEach(function (colName) {
			ctrl.visible[colName] = true;
		});
	}

	ctrl.setColumnVisibility = function () {
		setColumnVisibility();
	}

	/**
	 * Propagate selection to "owner"
	 * @param {Object} task - Task selected.
	 * @param {boolean} scrollTo - Scroll to selected task.
	 * @param {boolean} removeSpecialTask - If given task is special task set this parameter to true if you want to remove special task. For normal tasks this is not needed, special task is removed in any case.
	 */
    ctrl.taskSelected = function (task, scrollTo, removeSpecialTask) {
        if ((task && task != ctrl.specialTask) || removeSpecialTask) {
            ctrl.specialTask = null;
        }
        selectTask(task, scrollTo);
        ctrl.onSelectionChangedCallback({ task: task });
    };

	/**
	 * Launch update outside of directive.
     * @param {string} [work] - Work if query is limited for certain work only.
	 */
	function triggerLoading(work) {
		getResourceTasks(ctrl.resource, 0, 1000, false, false, work ? work : ctrl.work);
	}
}
export default tasksModule