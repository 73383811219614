/**
  * Search ResourceTask Directive & Controller
  */
var searchTaskCtrlId = 'searchtaskctrl';
var taskButtonModule=angular.module('manudir.manSearchTaskButton',[])
taskButtonModule.directive('manSearchTaskButton', [function () {
	return {
		restrict: 'E',
		scope: {},
		controller: searchTaskCtrlId,
		controllerAs: 'ctrl',
		bindToController: {
			showMask: '=',  // maskBits: 1: work ID; 2: serial ID: 4 work and serial ID (on control)
			onPhaseSelectedCallback: '&?',
			onWorkSelectedCallback: '&?'
		},
		template: require('./manSearchTaskButton.html')
	}
}]);

taskButtonModule.controller(searchTaskCtrlId, ['$scope', 'manDatacontext', 'common', 'usersettings', '$uibModal', searchtask]);
function searchtask($scope, manDatacontext, common, usersettings, $uibModal) {
    var ctrl = this;

    // Phases: 1) request parent 2) select serial number 3) select phase
    $scope.showWorkId = true;
    $scope.showSerialId = true;

    ctrl.$onInit = function () {
        if (typeof ctrl.showMask !== 'undefined') {
            $scope.showWorkId = (ctrl.showMask & 1) > 0;
            $scope.showSerialId = (ctrl.showMask & 2) > 0;
            $scope.showWorkSerialId = (ctrl.showMask & 4) > 0;
        }
    };
	// Search button clicked <=> start point for all operations
	ctrl.clicked = function () {
		ctrl.phase = 'REQUEST_PARENT';
        ctrl.allResourceTasks = ctrl.workDatas = ctrl.snPhases = ctrl.selected = ctrl.message = null;
        ctrl.serialId = ctrl.workId = ctrl.workOrSerialId = '';

		ctrl.modalInstance = $uibModal.open({
			animation: true,
			backdrop: false,
			template: require('./manSearchTask.html'),
			controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
				$scope.onCancel = function () {
					$uibModalInstance.close();
				};
			}],
            scope: $scope,
            size: ctrl.onPhaseSelectedCallback ? 'lg' : null
		});
	}

    ctrl.getSearchItems = function () {
        ctrl.allResourceTasks = ctrl.workDatas = ctrl.snPhases = ctrl.selected = ctrl.message = null;
		if (ctrl.onPhaseSelectedCallback) {
			getResourceTasks();
		}
		else if (ctrl.onWorkSelectedCallback) {
			getResourceWork();
		}
	}

	function getResourceWork() {
		if (ctrl.workId || ctrl.serialId || ctrl.workOrSerialId) {
			manDatacontext.getResourceWork(ctrl.workId, true, ctrl.serialId, ctrl.workOrSerialId).then(function (data) {
				if (!data || data.length == 0) {
					// No works found
                    ctrl.message = common.$translate.instant("STRCONST.WOR.TXT_CRITERIA_DONT_MATCH_WORKS");
					return;
				}
				if (data.length == 1) {
					ctrl.onWorkSelectedCallback({ work: data[0] });
					ctrl.modalInstance.close();
				}
            },
            function (data) {
                ctrl.message = common.$translate.instant("STRCONST.WOR.TXT_CRITERIA_DONT_MATCH_WORKS");
            });
    	}
	}

	function getResourceTasks() {
		if (ctrl.workId || ctrl.serialId || ctrl.workOrSerialId) {
			manDatacontext.getResourceTasks(null, ctrl.workId, ctrl.serialId, ctrl.workOrSerialId, 0, 999, true, 'SHOW_ALL', null, 'ORDER_WORKID_POS', null, null, true).then(function (data) {
				if (!data || data.length == 0) {
					// No phases found
					ctrl.message = common.$translate.instant("STRCONST.WOR.TXT_CRITERIA_DONT_MATCH_PHASES");
					return;
				}
                ctrl.allResourceTasks = data;
                // One work operation per work, serial id for the use of work table + sorting
                ctrl.workDatas = common.unique(data, ['workId', 'serialId']).sort(function (cmp1, cmp2) {
                    // 1. criteria: workIsOpen
                    if (cmp1.workIsOpen != cmp2.workIsOpen) {
                        return cmp1.workIsOpen ? -1 : 1;
                    }
                    // 2. criteria: workId
                    else if (cmp1.workId != cmp2.workId) {
                        return cmp1.workId < cmp2.workId ? -1 : 1;
                    }
                    // 3. criteria: serialId
                    else if (cmp1.serialId != cmp2.serialId) {
                        return cmp1.serialId < cmp2.serialId ? -1 : 1;
                    }
                    return 0;
                });

                // if all returned phases are from the same work show the phases
                if (ctrl.workDatas.length === 1) {
                    setPhases(data);
                }
                ctrl.phase = 'SELECT_PHASE';
            },
            function (data) {
                ctrl.message = common.$translate.instant("STRCONST.WOR.TXT_CRITERIA_DONT_MATCH_PHASES");
            });
		}
    }

    function setPhases(phases) {
        ctrl.snPhases = phases;
        ctrl.selected = null;
        if (phases) {
            if (phases.length === 1) {
                ctrl.selected = phases[0];
            }
            else {
                var canStart = phases.filter(function (phase) { return phase.canStart; })
                if (canStart && canStart.length > 0) {
                    ctrl.selected = canStart[0];
                }
            }
        }
    }

    ctrl.onParentSelected = function (workData) {
        if (workData) {
            setPhases(ctrl.allResourceTasks.filter(function (resourceTask) {
                ctrl.selected = null;
                var serId1 = workData.serialId ? workData.serialId : '---';
                var serId2 = resourceTask.serialId ? resourceTask.serialId : '---';
                return serId1 === serId2 && workData.workId === resourceTask.workId;
            }));
        }
        else {
            setPhases(null);
        }
    }

	ctrl.onSelect = function () {
		if (ctrl.phase == 'SELECT_PHASE') {
			ctrl.onPhaseSelectedCallback({ task: ctrl.selected });
			ctrl.modalInstance.close();
		}
	}
	ctrl.isStartedOrStartable = function (phase) {
		// Aloituskelpoiset / aloitetut
		return phase.canStart || phase.canComplete;
	}
	ctrl.selectRow = function (row) {
        ctrl.selected = (ctrl.selected == row ? null : row);
	}
}
export default taskButtonModule