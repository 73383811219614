var elementMaterialsModule= angular.module('manudir.dfxWorkElementMaterials',[])
elementMaterialsModule.directive('dfxWorkElementMaterials', ['$injector', 'common', 'workelements_service', 'manDatacontext', 'qualityfailureservice',
	function ($injector, common, workelements_service, manDatacontext, qualityfailureservice) {
	return {
		restrict: 'E',
		scope: {},
		controllerAs: 'ctrl',
		bindToController: {
            workElement: '=',
            workOperation: '=',
			editDisabled: '=',
			resource: '=?',
			layout: '=?',
		},
		controller: ['$scope', function ($scope) {
			var ctrl = this;
			ctrl.dynamicColumns = [];
			ctrl.itemsInTypeAhead = 30;
			ctrl.formId = 25135;
            $scope.txtReasonOfChange = common.$translate.instant("STRCONST.PUBLIC.TXT_REASON_OF_CHANGE");
			var showStockInTypeahead = false;

            $scope.getTemplateUrl = function () {
                if (ctrl.layout) {
                    if (ctrl.layout === 'DYNAMIC') {
                        return 'app/manufacturing/directives/dfxWorkElementMaterials.html';
                    }
                    if (ctrl.layout === 'TABLE') {
						return 'app/manufacturing/directives/dfxWorkElementMaterialsTable.html';
                    }
                }
				return 'app/manufacturing/directives/dfxWorkElementMaterialsTable.html';
            };

            ctrl.checkChanges = function (wm, e, propertyName) {
				var valuesProperty = propertyName === 'serialId' ? 'itemSerialNumbers' :
					propertyName === 'itemSpec' ? 'itemStockBatches' : '';
				var elem = e.target;

                // reject changes if the value is not found from the selectable list
                if (wm[propertyName]) {
					// some value typed by user, verify that it is selectable. If not, then reject changes.
					if (wm[propertyName] !== ' ') {
						var match = false;
						angular.forEach(wm[valuesProperty], function (sn) {
							if (wm[propertyName] === sn[propertyName]) {
								match = true;
							}
						});
						// restore original value
						if (!match) {
							wm.setProperty(propertyName, wm.entityAspect.originalValues[propertyName]);
						}
						delete wm[valuesProperty];
					}
                    common.elementValueHasRealChanges(wm, [propertyName], elem, function () { workelements_service.resolveHasChanges(); });
                }
                // If the user tried to change or remove serial id after value has been saved earlier, raise a warning.
                if (workelements_service.getState().warningWhenSerialChangedInTaskMaterial) {
                    if (propertyName === 'serialId' && wm.entityAspect.originalValues[propertyName] && wm.entityAspect.originalValues[propertyName] != wm[propertyName]) {
                        var choice;
                        if (wm[propertyName]) {
                            choice = confirm(common.$translate.instant("STRCONST.WOR.QRY_UPDATE_SERIALNUM_SELECTED"));
                        }
                        else {
                            choice = confirm(common.$translate.instant("STRCONST.WOR.QRY_DELETE_SERIALNUM_SELECTED"));
                        }
                        if (!choice) {
                            wm.setProperty(propertyName, wm.entityAspect.originalValues[propertyName]);
                        }
                        common.elementValueHasRealChanges(wm, [propertyName], elem, function () { workelements_service.resolveHasChanges(); });
                    }
                }
			};

			ctrl.checkDynaDataChange = function (wm, dynamicData) {
				if (typeof workelements_service.custAfterWeMatRowChanged === 'function') {
					workelements_service.custAfterWeMatRowChanged($scope, wm, null, dynamicData);
				}
			}
			ctrl.isAggregareInputAvailable = function (wm) {
				if (typeof workelements_service.custIsAggregareInputAvailable === 'function') {
					return workelements_service.custIsAggregareInputAvailable(wm);
				}
				return false;
			}

			/**
			* Quality failure creation button title/tooltip.
			* @param {number} qty - Number of quality failures for selected material row.
			* @return {string} Title/tooltip for the button.
			*/
			ctrl.qualityFailureBtnTitle = function (qty) {
				return common.$translate.instant("STRCONST.PUBLIC.TXT_CREATE_QF_SHOW_QTY").replace('%1', qty);
			};

            ctrl.getElementMaterChange = function () {
                return workelements_service.getState().enumItems['ELEMENTMATERCHANGE'];
            };
            ctrl.setElementMaterChange = function (wm, enumItem) {
                wm.elementMaterChange = enumItem.id;
                wm.elementMaterChangeName = enumItem.name;
            };

            ctrl.setEmployee = function (wm, emp) {
                wm.empId = emp ? emp.empId : null;
                wm.empName = emp ? emp.name : null;
            };

			ctrl.showCountInNeutrino = function (wm) {
				if (typeof workelements_service.custShowCountInWeMaterialNeutrino === 'function') {
					return workelements_service.custShowCountInWeMaterialNeutrino(wm);
				}
				return wm.qualityFeedbackQty > 0;
			}

			ctrl.getMaterials = function () {
				var arr = []
				if (ctrl.workElement) {
					/*Get all WorkElement materials*/
					if (ctrl.workElement.childElements && ctrl.workElement.childElements.length > 0) {
						angular.forEach(ctrl.workElement.childElements, function (wm) {
							arr = arr.concat(workelements_service.getWorkElementData('MATERIAL', wm));
						});
					}
						/*Get one WorkElement materials*/
					else {
						arr = workelements_service.getWorkElementData('MATERIAL', ctrl.workElement);
					}
					if (arr.length > 0) {
						ctrl.dynamicColumns = arr[0].dynamicData;
					}
					var showSerialId = false;
					var showTrace = false;
					angular.forEach(arr, function (matRow) {
						if (matRow.allowSerialId) {
							showSerialId = true;
						}
						if (matRow.allowTrace) {
							showTrace = true;
						}
					});
					ctrl.showSerialIdCol = showSerialId;
					ctrl.showTraceCol = showTrace;
				}
				return arr;
			}

			ctrl.filteredSerialIdList = function (matRow, searchString) {
				ctrl.matchingSerialIds = [];
				if (matRow) {
					// execute db query if filtering causes less items than is shown
					if (doFilter(matRow.itemSerialNumbers, searchString)) {
						doDbQuery(matRow, matRow.itemId, searchString);
					}
				}
				return ctrl.matchingSerialIds;
			};

			function doDbQuery(matRow, itemId, searchString) {
				if (matRow.itemSerialNumbers === undefined)
					matRow.itemSerialNumbers = [];
				manDatacontext.getFreeSerialNumbers(itemId, 0, 100, searchString).then(function (data) {
					// Add existing value to the selectable list
					if (matRow.itemSerialNumbers.length === 0 && matRow.serialId && matRow.entityAspect.originalValues && matRow.entityAspect.originalValues['serialId']) {
						matRow.itemSerialNumbers.push({ serialId: matRow.entityAspect.originalValues['serialId'] });
					}
					matRow.itemSerialNumbers = common.unique(matRow.itemSerialNumbers.concat(data), 'serialId');
					doFilter(matRow.itemSerialNumbers, searchString);
				});
			}

            /**
             * Filters given data and returns true if DB query should be done
             * @param {any} data
             */
			function doFilter(data, searchString) {
				if (data === undefined)
					return true;

				var filtered = data;

				if (searchString && searchString.length > 0 && searchString !== '' && searchString !== ' ') {
					filtered = data.filter(function (e) {
						return common.textContains(e.serialId, searchString);
					});
				}
				ctrl.matchingSerialIds = filtered.slice(0, ctrl.itemsInTypeAhead);
				common.setTypeheadColumnData(ctrl.matchingSerialIds, getTypeAheadText, null, null, null, false);
				function getTypeAheadText(column, object) {
					return column === 0 ? object.serialId : null;
				}
				return ctrl.matchingSerialIds.length < ctrl.itemsInTypeAhead;
			}

			ctrl.filteredStockBatchList = function (matRow, searchString) {
				ctrl.matchingRows = [];
				var itemId = (matRow ? matRow.itemId : null);
				if (itemId) {
					if (matRow.itemStockBatches === undefined) {
						matRow.itemStockBatches = [];
						manDatacontext.getStockBatches(itemId).then(function (data) {
							if (ctrl.resource && (ctrl.resource.stockId || ctrl.resource.stockLoc)) {
								data = data.filter(function (sb) {
									if (ctrl.resource.stockId && ctrl.resource.stockId !== sb.stockId) {
										return false;
									}
									if (ctrl.resource.stockLoc && ctrl.resource.stockLoc !== sb.stockLoc) {
										return false;
									}
									return true;
								});
							}
							// If more that one stock contains same itemSpec, show stock and stockLoc
							var arr = [];
							angular.forEach(data, function (stockBatch) {
								if (arr[stockBatch.itemSpec]){
									showStockInTypeahead = true;
								}
								arr[stockBatch.itemSpec] = true;
							});
							matRow.itemStockBatches = data;
							doFilter(matRow.itemStockBatches);
						});
					}
					else {
						doFilter(matRow.itemStockBatches);
					}
					function doFilter(data) {
						var filtered = data;

						if (searchString && searchString.length > 0 && searchString != '' && searchString != ' ') {
							filtered = data.filter(function (e) {
								return common.textContains(e.itemSpec, searchString);
							});
						}
						ctrl.matchingRows = filtered.slice(0, ctrl.itemsInTypeAhead);
						//  setTypeheadColumnData(objects, nameCallback, emphasize, margin, maxWidth, appendMoreTag)
						var appendMoreTag = filtered.length > ctrl.itemsInTypeAhead;
						common.setTypeheadColumnData(ctrl.matchingRows, getTypeAheadText, null, null, null, appendMoreTag);
						function getTypeAheadText(col, object) {
							if (showStockInTypeahead) {
								return col == 0 ? object.itemSpec :
									col == 1 ? object.stockId :
									col == 2 ? object.stockLoc :
									col == 3 ? object.qty :
									col == 4 ? object.unit : null;
							}
							return col == 0 ? object.itemSpec :
								col == 1 ? object.qty :
								col == 2 ? object.unit : null;
						}
					}
				}
				return ctrl.matchingRows;
			}

            /**
             * Open quality failure modal.
             * @param {object} wm - Work Element Material row.
             */
			ctrl.openQualityFailureModal = function (wm) {
				// Function to increase quality feedback amount by one if quality feedback was created successfully.
				var addQualityFailureCountFunc = function (params) {
					if (params && params.qualityFailure && params.qualityFailure.recId > 10) {
						wm.qualityFeedbackQty += 1;
					}
				};
				var openQualityFailureModal = function () {
					

					var objectDefaults = {
						resource: ctrl.resource,
						resourceTask: ctrl.workOperation,
						itemId: wm.itemId,
						itemName: wm.name,
						serialId: wm.serialId,
						parentRecId: wm.recId,
						materialOrigQty: 0,
						itemSpec: wm.itemSpec,
						componentId: typeof workelements_service.getComponentId === 'function' ? workelements_service.getComponentId(wm) : null,
						workElement: wm
					}
					var optParams = {
						onSavedCallback: addQualityFailureCountFunc
					};

					qualityfailureservice.openQualityFailureModal2('ELEMENT_MATERIAL', objectDefaults, null, optParams);
				};
                if (wm) {
                    
                    // Customer specific initialization - if not functions are not defined, the creation modal will open 
                    var notAllowedErrMessage = typeof workelements_service.isQualityFailureCreationAllowed === 'function' ? workelements_service.isQualityFailureCreationAllowed(wm) : "";
					if (!notAllowedErrMessage) {
						// Try to open custom modal first.
						var serviceName = 'customqualityfailureservice';
						
						if (wm) {
							// If there are existing quality failures/feedbacks, show them first.
							if (wm.qualityFeedbackQty && wm.qualityFeedbackQty > 0) {
								var optParams = {
									onOk: openQualityFailureModal,
									qualityFailuresCount: wm.qualityFeedbackQty,
									parentFormId: ctrl.formId,
									parentFormVersion:'SELECT_WE_MATERIAL_DETAILS'
								};
								qualityfailureservice.showQualityFailuresOnModal(optParams, 'WOR_WORKELEMENT_MATERIAL', wm.recId, wm);
							}
							else { // No existing quality failures/feedbacks: open up modal straight away.
								openQualityFailureModal();
							}
						}
                    }
                    else {
                        common.openModalMessage($scope, common.$translate.instant("STRCONST.LEANPORTAL.HDR_QUALITYFEEDBACKENTRY"), notAllowedErrMessage,
                            function () { }, null, common.$translate.instant("STRCONST.PUBLIC.BTN_OK"));
                    }
                }
            };

			ctrl.selectStockBatch = function (itemSpec, wm) {
				if (wm) {
					wm.itemSpec = itemSpec;
				}
			}

			ctrl.selectSerialId = function (serialId, wm) {
				if (wm) {
					wm.serialId = serialId;
				}
			};

			ctrl.showLocks = function (row) {
				var ret = false;
				if (row) {
					ret = (row.allowUnlock && row.isLockedRow() !== null);
				}
				else {
					angular.forEach(ctrl.getMaterials(), function (row) {
						if (row.allowUnlock && row.isLockedRow() !== null) {
							ret = true;
						}
					});
				}
				return ret;
			}

			ctrl.getEnumItemMap = function () {
				return workelements_service.getState().enumItems;
			}

			ctrl.getEnumItems = function (dynamicData) {
				return workelements_service.getState().enumItems[dynamicData.enumeration];
			}
			ctrl.setEnumSelection = function (dynamicData, enumItem) {
				dynamicData.enumValue = enumItem.id;
				dynamicData.enumNameValue = enumItem.nabbr;
			}
		}],
		template: '<ng-include src="getTemplateUrl()"/>'
	}
}]);
export default elementMaterialsModule
