import jquery from "jquery"
var rxhtmlTag = /<(?!area|br|col|embed|hr|img|input|link|meta|param)(([a-z][^\/\0>\x20\t\r\n\f]*)[^>]*)\/>/gi;
jquery.htmlPrefilter = function (html) {
	return html.replace(rxhtmlTag, "<$1></$2>");
};
import "signalr"
import "angular"
import '../Content/custom-bootstrap-variables.less'
import 'bootstrap'
import 'angular-translate';
import 'angular-translate-storage-cookie';
import 'angular-translate-storage-local';
import '../Scripts/q';
import 'breeze-client/breeze.debug';
import 'breeze-client/breeze.bridge.angular';
import 'breeze-client-labs/breeze.directives';
import 'breeze-client-labs/breeze.saveErrorExtensions';
import '../Scripts/angular.breeze.storagewip';
import 'toastr';
import 'moment';
import 'moment-duration-format';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import 'excel-builder-webpacker';
import 'ekko-lightbox';
import '../node_modules/angular-ui-grid/ui-grid.min.css'
import '@fortawesome/fontawesome-pro/css/all.css';
import '@roima/rex/fonts.css'
import '@roima/rex/rex.css'
import '../node_modules/toastr/build/toastr.min.css'
import '../node_modules/ekko-lightbox/dist/ekko-lightbox.css'
import '../Content/login.css'
import '../Content/dfx-theme-default.css'
import '../Content/ie10mobile.css'

if (CUSTOMER_CSS) {
	const customerCssThemes = CUSTOMER_CSS.split(';');
	for (const cssTheme of customerCssThemes) {
		import(`../Content/Customer/${cssTheme}.css`);
	}
}