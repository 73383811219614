var qualityDataModule=angular.module('quadir.dfxQualityData',[])
qualityDataModule.directive('dfxQualityData', [function () {
    return {
        restrict: 'E',
        scope: {},
        controller: 'qualitydata',
        controllerAs: 'ctrl',
        bindToController: {
            qltData: '=?', // OBS.qltData/qltDataId/qltDataRows are optional!
            qltDataId: '=?',
			qltDataRows: '=?',
			commentMandatoryWhenOutsideOfRange: '=?', // When true requires comment when saving if value is outside of range limits.
            editMode: '=', // 0 = read only, 1 = edit with row click, 2 = edit with inputs visible by default
            allowSave: '=?',
            allowCopy: '=?',
            rowBasedUnlocking: '=?',
            allowConfirm: '=?',
            parent: '=?',
            formId: '=?',
            graphTitle: '=?', // Use this to set alternative graph title.
            inputElemClass: '=?', // Css classes to be added to input, textarea, select etc.
            replacingGraphParams: '=?', // Use this to set alternative graph parameters.
			showEmpId: '=?',
			showEmpTag: '=?',
			extraButtons: '=?',
            onLoadingReadyCallback: '&?',
            onSaveCallback: '&?',
            onCopyCallback: '&?',
            onControllerCallback: '&?'
        },
        template: require('./qualitydata.html')
    };
}]);
var controllerId = 'qualitydata';
qualityDataModule.controller(controllerId, ['$scope', '$uibModal', 'common', 'qualityDatacontext', 'commonDatacontext', 'usersettings', qualitydata]);

function qualitydata($scope, $uibModal, common, qualityDatacontext, commonDatacontext, usersettings) {
    var ctrl = this;
    ctrl.contentAvailable = false;
    ctrl.empId = null;
    ctrl.enumIdToName = [];

    ctrl.graphFormId = 18522;
    // Toaster
    var errMsg = common.logger.getLogFn(controllerId, 'error');
    // Quality data rows for the group
    ctrl.qtyData = [];
    // enum items indexed by enumeration
    ctrl.enumerations = [];
    ctrl.selectedRow = null;
    ctrl.docParent = null;
	ctrl.showLimitCols = false; // Variable for showing/hiding limit columns if no limit columns exist in any of the rows in the current quality datas group.
	ctrl.extraButtons = null;

    // API offered for the client with onControllerCallback
    var service = {
		getMissingMandatoryDataMsg: getMissingMandatoryDataMsg,
		hasRowsOutsideOfRange: hasRowsOutsideOfRange
    }

    ctrl.$onInit = function () {
        if (!ctrl.formId) {
            ctrl.formId = 25108;
        }

        if (ctrl.onControllerCallback) {
            ctrl.onControllerCallback({ childCtrl: service });
		}
		ctrl.commentMandatoryWhenOutsideOfRange == undefined ? false : ctrl.commentMandatoryWhenOutsideOfRange;
    };

    ctrl.avgValueChanged = function (qualityDataRow) {
        var count = 0;
        var sum = 0;
        angular.forEach(qualityDataRow.numValues, function (numValue) {
            if (typeof numValue.value !== 'undefined' && numValue.value !== null) {
                sum += numValue.value;
                count++;
            }
        });
        if (count > 0) {
            qualityDataRow.numValue = (sum / count).toFixed(5);
        }
        else if (qualityDataRow.numValue) {
            qualityDataRow.numValue = null;
        }
    }


    /**
     * Return changed rows handled by this directive. 
     * @returns 
     */
    function getChanges() {
        var ret = [];
        angular.forEach(ctrl.qtyData, function (row) {
            if (row.entityAspect.entityState === breeze.EntityState.Modified) {
                ret.push(row);
                if (row.numValues && row.numValues.length) {
                    ret = ret.concat(row.numValues);
                }
            }
        });
        return ret;
    }

    /**
     * Gets enum items for combo selection. 
     * @returns 
     */
    ctrl.getEnumItems = function (qualityData) {
        var items = ctrl.enumerations[qualityData.enumeration];
        if (items == null) {
            // This will cause only one getEnumFrom call per controller
            ctrl.enumerations[qualityData.enumeration] = [];
            commonDatacontext.getEnumFrom(qualityData.enumeration).then(function (data) {
                if (data) {
                    ctrl.enumerations[qualityData.enumeration] = data;
                    var enumIdToName = [];
                    angular.forEach(data, function (enumItem) {
                        enumIdToName[enumItem.id] = enumItem.name;
                    });
                    ctrl.enumIdToName[qualityData.enumeration] = enumIdToName;
                }
            });
        }
        return items;
    }

    /**
     * Loops through quality data rows and checks if mandatory data or comments are missing. 
     * @param {boolean} showGroupName - Should group name be showed before message.
     * @param {boolean} returnCountOnly - Set to true if count of missing mandatory elements is wanted instead of string list.
     * @returns {(string|number)} Message listing or number of missing mandatory data and comments.
     */
    function getMissingMandatoryDataMsg(showGroupName, returnCountOnly) {
        return qualityDatacontext.getMissingMandatoryDataMsg(ctrl.qltData, ctrl.qtyData, showGroupName, returnCountOnly);
    }

    /**
     * Gets quality data rows from DB / entity. 
     * @returns 
     */
    function getQualityDataRows(forceRead) {
        if (!ctrl.qltData && !ctrl.qltDataId) {
            ctrl.qtyData.slice(0);
            ctrl.contentAvailable = false;
            if (ctrl.onLoadingReadyCallback) {
                ctrl.onLoadingReadyCallback({ count: 0 });
            }
            return;
        }
        // If the rows are already read, then just use them <=> no onLoadingReadyCallback needed
		if (ctrl.qltData && ctrl.qltData.qualityDataRows.length > 0 && !forceRead) {
            ctrl.qtyData = ctrl.qltData.qualityDataRows;
            ctrl.contentAvailable = true;
            ctrl.showLimitCols = false;
            angular.forEach(ctrl.qtyData, function (row) {
                if (ctrl.showLimitCols || row.hasLimit()) {
                    ctrl.showLimitCols = true;
                }
            });
        }
        // otherwise read rows with dataId and inform with onLoadingReadyCallback when ready
        else {
            var qltDataId = ctrl.qltData ? ctrl.qltData.dataId : ctrl.qltDataId;
            qualityDatacontext.getQualityDataRows(qltDataId, true, ctrl.formId).then(function (data) {
                ctrl.qtyData = data;
                ctrl.contentAvailable = (ctrl.qtyData.length > 0);
                // Inform the owner with callback
                if (ctrl.onLoadingReadyCallback) {
                    var itemCount = 0;
                    var readyCount = 0;
                    ctrl.showLimitCols = false;
                    angular.forEach(ctrl.qtyData, function (row) {
                        if (row.dataType != 'HEADER') {
                            itemCount++;
                            if (row.getValue() != null) {
                                readyCount++;
                            }
                            if (ctrl.showLimitCols || row.hasLimit()) {
                                ctrl.showLimitCols = true;
                            }
                        }
                    });
                    ctrl.onLoadingReadyCallback({ count: itemCount, readyCount: readyCount });
                }
            });
        }
    }

	/**
	 * Checks if any of the quality data row values are outside of the limit ranges.
	 * @returns {boolean} True if any row value is outside of range.
	 */
	function hasRowsOutsideOfRange() {
		var retVal = false;
		if (ctrl.qltDataRows && ctrl.qltDataRows.length > 0) {
			angular.forEach(ctrl.qltDataRows, function (row) {
				if (row.isLimitRange(1)) {
					retVal = true;
				}
			});
		}
		return retVal;
	}

	/**
	 * Checks if any of the quality data row values are outside of the limit ranges without comment.
	 * @returns {boolean} True if any row value is outside of range.
	 */
	function hasRowsOutsideOfRangeAndCommentMissing() {
		var retVal = false;
		if (ctrl.qltDataRows && ctrl.qltDataRows.length > 0) {
			angular.forEach(ctrl.qltDataRows, function (row) {
				if (row.isLimitRange(1) && (!row.commentText || row.commentText === '')) {
					retVal = true;
				}
			});
		}
		return retVal;
	}

    ctrl.getResId = function (dataRow) {
        return dataRow.resId ? dataRow.resId : ctrl.parent.resId;
    }

    /**
     * Return correct value for the quality data row
     * This API is needed for proper enumeration handling
     * @returns 
     */
    ctrl.getValue = function (row) {
        var value = row.getValue()
        if (row.dataType == 'ENUM') {
            value = ctrl.getEnumItems(row) && ctrl.enumIdToName[row.enumeration] ? ctrl.enumIdToName[row.enumeration][value] : '';
        }
        return value;
    }

    ctrl.hasChanges = function () {
        // Must be called, while otherwise <get/set>BeforeUnloadValue functionality won't be called
        qualityDatacontext.hasChanges();
        return getChanges().length > 0;
    }

    function initLookups() {
        return qualityDatacontext.getLookups(lang, false).then(function (data) {
            if (data && data[0] && data[0].Enums) {
                if (data[0].Enums["QLTDATASTATUS"]) {
                    ctrl.qltDataStatusList = data[0].Enums["QLTDATASTATUS"];
                    ctrl.qltDataStatusList.forEach(function (e) {
                        // VALUE08 == 'READY'
                        if (e.extraSelect && e.extraSelect.length > 0 && e.extraSelect[0] == 'READY') {
                            ctrl.confirmStatus = e.id;
                        }
                    });
                }
                if (data[0].Enums["QLTDATAROWSTATUS"]) {
                    var rowStatusList = data[0].Enums["QLTDATAROWSTATUS"];
                    if (rowStatusList) {
                        rowStatusList.forEach(function (e) {
                            // VALUE20 == 'REMEASURED'
                            // Comment is reset when measurement is remeasured
                            if (e.extraSelect && e.extraSelect.length > 0 && e.extraSelect[0] == 'REMEASURED') {
                                ctrl.reMeasurementStatus = e.id;
                            }
                        });
                    }
                }
            }
            return data;
        })
	}

	/**
	 * Is document adding button disabled or not.
	 * @param {object} row - Quality data row
	 * @returns True if button is disabled.
	 */
	ctrl.isAddDocBtnDisabled = function (row) {
		if (row && row.docPermissions) {
			return (row.docPermissions & 1) === 0;
		}
		return true;
	};

    /**
     * Return true if the value cell can be edited
     */
    ctrl.isEditableCell = function (row, valueCell) {
        var ret = true;
        // read only or not currently edited row
        if (ctrl.editMode == 0 || (ctrl.editMode == 1 && data !== ctrl.selectedRow)) {
            ret = false;
        }
        // formulas value cell is not editable
        else if (row.formula && valueCell) {
            ret = false;
        }
        // if notEditable, then neither value nor comment is editable, unless unlocked
        else if (row.notEditable && row.isLocked() !== false) {
            ret = false;
        }
        // if value cell, then valueReadOnly defines editability
        else if (valueCell) {
            ret = !row.valueReadOnly || row.isLocked() === false;
        }
        return ret;
    }

    ctrl.isGraphAvailable = function (row) {
        if (typeof ctrl.graphAvailable === 'undefined') {
            if (row && ctrl.parent && ctrl.parent.graphParams) {
                ctrl.graphAvailable = ctrl.parent.graphParams(ctrl.graphFormId) != null && row.graphParams(ctrl.graphFormId) != null;
            }
            else {
                return false;
            }
        }
        return ctrl.graphAvailable;
    }

    ctrl.isDocumentAvailable = function (row) {
        if (row && row.infoLink) return true;
        else return false;
    }

    ctrl.openCommentText = function (qltDataRow) {
        qltDataRow['getCommonDataTitle'] = function () {
            return qltDataRow.name;
        }
        $scope.parent = qltDataRow;
        var modalInstance = $uibModal.open({
            animation: true,
            backdrop: false,
			size: 'md',
			template: require('../../common/directives/dfxAddCommentModal.html'),
            controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                var ctrl = this;
                ctrl.isModifyOperation = qltDataRow.commentText && qltDataRow.commentText.length;
                ctrl.commentAreaHeight = 7;
                ctrl.okCancelButtons = true;
                ctrl.newComment = qltDataRow.commentText;
                $scope.onCancel = function () {
                    $uibModalInstance.dismiss('cancel');
                };
                $scope.onApply = function () {
                    qltDataRow.commentText = ctrl.newComment;
                    $uibModalInstance.close();
                }
            }],
            controllerAs: 'ctrl',
            bindToController: {
                parent: '='
            },
            scope: $scope
        });
    };


    /**
    * Fired after request to open modal list of Documents
    * @param {document's parent)}
    */
    ctrl.openDocumentList = function (docParent) {
        if (docParent) {
            ctrl.docListParent = docParent;
            common.openModal($scope, require('../../common/document/directives/documentListModal.html'), function () { }, function () { }, 'md');
        }
    }

    /**
     * Marks QualityData input completed
     */
    ctrl.confirmQltData = function () {
        if (ctrl.qltData) {
            var msg = getMissingMandatoryDataMsg();
            if (msg.length > 0) {
                usersettings.errHandler(msg);
            }
            else {
                ctrl.qltData.status = ctrl.confirmStatus;
				if (ctrl.showEmpTag) {
					launchEmpModal([ctrl.qltData]);
					return;
				}
				else {
					ctrl.qltData.empId = ctrl.empId;
				}
                ctrl.saveChanges([ctrl.qltData], true)
            }
        }
    }

    /**
     * Copies current QualityData entity and informs the owner
     */
    ctrl.copyQltData = function () {
        var commentMissing = [];
        angular.forEach(ctrl.qtyData, function (dataRow) {
            if (dataRow.commentMandatory && !dataRow.commentText) {
                commentMissing.push(dataRow.name);
            }
        });
        if (commentMissing.length > 0) {
            usersettings.errHandler(common.$translate.instant("STRCONST.PUBLIC.TXT_REQCOMMENTMISSING") + ": " + commentMissing.join(', ') + '.');
        }
        else {
            ctrl.copyInProgress = true;
			qualityDatacontext.copyQualityData(ctrl.qltData.recId, ctrl.qltData.copyValues, null, false).then(function (data) {
                if (data && data.length == 1) {
                    ctrl.onCopyCallback({ qltDataCopy: data[0], qltDataFrom: ctrl.qltData });
                }
                // Add some delay to avoid accidental copy operations
                common.$timeout(function () { ctrl.copyInProgress = false; }, 1000);
            }, function (error) {
                common.$timeout(function () { ctrl.copyInProgress = false; }, 1000);
            });
        }
    }

    ctrl.showLocks = function (row) {
        // 1) read only mode 2) copy is not allowed (e.g. work is ready) 3) row based unlocking not used
        if (!ctrl.editMode || ctrl.allowCopy === false || !ctrl.rowBasedUnlocking) {
            return false;
        }
        var ret = false;
        if (row) {
            ret = (row.isLocked() !== null) && !row.formula;
        }
        else {
            angular.forEach(ctrl.qtyData, function (row) {
                if (row.isLocked() !== null) {
                    ret = true;
                }
            });
        }
        return ret;
    }

    ctrl.onNumberValueFocus = function (row) {
        if (row.numValue && row.entityAspect.entityState === breeze.EntityState.Unchanged && ctrl.reMeasurementStatus && row.status == ctrl.reMeasurementStatus) {
            row.commentText = '';
        }
    }

    /**
     * Opens up chart in a modal window.
     * @param{dataRowIndex} Currently selected data row index.
     */
    ctrl.openChart = function (dataRowIndex) {
        if ((dataRowIndex || dataRowIndex >= 0) && ctrl.parent && this.qtyData && this.qtyData[dataRowIndex] && this.qtyData[dataRowIndex].groupId) {
            // Currently selected data row.
            var dataRow = this.qtyData[dataRowIndex];

            var graphTitle = (this.parent.graphTitle(ctrl.graphFormId) ? this.parent.graphTitle(ctrl.graphFormId) : ''); // Take graph title from parent if exist.
            graphTitle = ctrl.graphTitle ? ctrl.graphTitle : graphTitle; // If graph title was given as parameter for the directive, it will be used instead of parent graph title.
            ctrl.chartTitle = graphTitle + dataRow.dataField + ' ' + dataRow.name;
            ctrl.formVersion = this.parent.graphFormVersion(ctrl.graphFormId);

            // Set chart ranges by using limits from currently selected data row. If current value is not within range, use current value for range.
            // Add 10 % extra space. 
            /* var rangeMin = (dataRow.numValue > dataRow.lowerLimit2 ? dataRow.lowerLimit2 : dataRow.numValue);
            ctrl.rangeMin = rangeMin - (rangeMin * 0.1);
            var rangeMax = (dataRow.numValue < dataRow.upperLimit2 ? dataRow.upperLimit2 : dataRow.numValue);
            ctrl.rangeMax = rangeMax + (rangeMax * 0.1); */
            // ... or use autoRange. Ranges do not have effect if autoRange is set to true.
            ctrl.autoRange = true;

            // Parameters for query.
            var parameters = [];
            parameters.push({ "name": 'PARAM1', "value": ctrl.getResId(dataRow) });
            angular.forEach(ctrl.parent.graphParams(ctrl.graphFormId), function (value, name) {
                parameters.push({ "name": name, "value": value });
            });
            angular.forEach(dataRow.graphParams(ctrl.graphFormId), function (value, name) {
                parameters.push({ "name": name, "value": value });
            });
            // Replace parameters with values given as parameter for the directive.
            if (ctrl.replacingGraphParams) {
                angular.forEach(Object.keys(parameters), function (i) {
                    angular.forEach(Object.keys(ctrl.replacingGraphParams), function (key) {
                        if (parameters[i].name == key) {
                            parameters[i].value = ctrl.replacingGraphParams[key];
                        }
                    });
                });
            }
            ctrl.parameters = JSON.stringify(parameters);

            // OK/Apply button is not needed.
            var onOk = null;

            // Cancel button does nothing special.
            function onCancel() {
                ;
            }

            // Open modal window with chart directive inside.
            common.openModal($scope, require('./qualityDataChartModal.html'), onOk, onCancel, 'xl');
        }
    };

    /**
     * Refect changes for this quality data
     */
    ctrl.rejectChanges = function () {
        angular.forEach(getChanges(), function (row) {
            if (typeof row.resetTempProperties === 'function') {
                row.resetTempProperties();
            }
        });
        qualityDatacontext.rejectChanges()
    };

    /**
     * Row changed <=> Update empId/resId if employee selection is visible
     */
    ctrl.rowChanged = function (row) {
        if (ctrl.showEmpId) {
            // Employee is not filled if separate selection exists
            if (!row.showReviewEmpId) {
                row.empId = ctrl.empId;
            }
            if (ctrl.parent && ctrl.parent.elementId) {
                row.elementId = ctrl.parent.elementId;
            }
            if (ctrl.parent && ctrl.parent.resId) {
                row.resId = ctrl.parent.resId;
            }
        }
    };

    /**
     * Saves changes made to rows in this quality data group
     * @returns {} 
     */
    ctrl.saveChanges = function (data, fetchDataRows) {
        var target = data ? data : getChanges();
        if (target.length === 0) {
            return;
        }
        if (ctrl.showEmpId && !ctrl.empId) {
            common.openModalMessage($scope,
                common.$translate.instant("STRCONST.LEANPORTAL.TXT_TOOLTIP_SAVE"),
                common.$translate.instant("STRCONST.LEANPORTAL.VALIDATE_REQUIRED").replace('%VALUE%', common.$translate.instant("STRCONST.PUBLIC.TXT_EMPID")) + '!',
                null, function () { });
            return;
		}
		if (ctrl.commentMandatoryWhenOutsideOfRange && hasRowsOutsideOfRangeAndCommentMissing()) {
			common.openModalMessage($scope,
				common.$translate.instant("STRCONST.LEANPORTAL.TXT_TOOLTIP_SAVE"),
				common.$translate.instant("STRCONST.LEANPORTAL.VALIDATE_REQUIRED").replace('%VALUE%', common.$translate.instant("STRCONST.PUBLIC.TXT_COMMENT")) + '!',
				null, function () { });
			return;
		}
		else if (ctrl.showEmpTag) {
			launchEmpModal(target);
			return;
		}
        qualityDatacontext.saveChanges(target).then(
			function (saveResult) {
				if (fetchDataRows) {
					getQualityDataRows(true);
				}
                if (ctrl.onSaveCallback) {
                    ctrl.onSaveCallback();
                }
                if (target) {
                    angular.forEach(target, function (row) {
                        if (typeof row.resetTempProperties === 'function') {
                            row.resetTempProperties();
                        }
                    });
                }
            },
            function (error) { });
    };

    /**
     * Employee selection changed - row defined if selection done in row <=> review employee
     */
    ctrl.selectEmployee = function (value, row) {
        if (value) {
            if (row) {
                row.empId = value.empId;
            }
            else {
                usersettings.setLastUserInfo(value.empId, value.name);
                ctrl.empId = value.empId;
                angular.forEach(getChanges(), function (qty) {
                    // Review employee ID is not overwritten - it must be defined 
                    if (!qty.showReviewEmpId) {
                        qty.empId = ctrl.empId;
                    }
                    if (ctrl.parent) {
                        qty.resId = ctrl.parent.resId;
                    }
                });
            }
        }
        else {
            if (row) {
                row.empId = null;
            }
            else {
                ctrl.empId = null;
            }
        }
    }

    /**
     * Toggles row selection.
     * @param {Quality Data element whose selection will be toggled.} 
     * @returns {} 
     */
    ctrl.toggleRowSelection = function (row) {
        if (ctrl.selectedRow === row) {
            ctrl.selectedRow = null;
        }
        else {
            ctrl.selectedRow = row;
        }
	}

	function launchEmpModal(target) {
		var templateUrl = 'confirmQltRow.html';
		var modalInstance = $uibModal.open({
			animation: true,
			backdrop: true,
			template: '<dfx-modal data-title="\'' + common.$translate.instant("STRCONST.PUBLIC.TXT_SELECT_EMPLOYEE") + '\'" button-mask="7" disable-apply-button="!ctrl.empId"><div data-ng-include="\'' + templateUrl + '\'"></div></dfx-modal>',
			scope: $scope,
			controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
				/*Callback from employee selection*/
				$scope.empSelected = function (value) {
					if (value && value.empId) {
						target.forEach(function (data) {
							data.empId = value.empId;
						})
						ctrl.empId = value.empId;
					}
					else {
						ctrl.empId = null;
					}
				}

				/**
				 * All closing events go through this function so that we can check if user wants to leave dirty modal.
				 */
				$scope.$on('modal.closing', function (event, reason, closed) {
					if (reason === 'cancel' || reason === 'backdrop click' || reason === 'escape key press') {
						//qualityDatacontext.rejectChanges(row);
					}
				});

				$scope.onApply = function () {
					// Save all changes, including the status change
					qualityDatacontext.saveChanges(target).then(function () {
						$uibModalInstance.close();
						if (ctrl.onSaveCallback) {
							ctrl.onSaveCallback();
						}
					});
				}

				$scope.onCancel = function () {
					//qualityDatacontext.rejectChanges(row);
					$uibModalInstance.dismiss('cancel');
				};
			}]
		});
	}

    activate();

    $scope.$on('COMPANYOPUCHANGES', function () {
        activate();
    });

    $scope.$watch('ctrl.qltData', function (newValue) {
        // If ID or rows is defined, then discard the entity changes
        if (!ctrl.qltDataRows && !ctrl.qltDataId && newValue !== ctrl.prev) {
            ctrl.prev = newValue;
            getQualityDataRows();
        }
    });
    $scope.$watch('ctrl.qltDataId', function (newValue) {
        // If entity or rows is defined, then discard the ID changes
        if (!ctrl.qltDataRows && !ctrl.qltData && newValue !== ctrl.prev) {
            ctrl.prev = newValue;
            getQualityDataRows();
        }
    });
    $scope.$watch('ctrl.qltDataRows', function (newValue) {
        // If entity or ID is defined, then discard the row changes
        if (newValue !== ctrl.prev && newValue) {
            ctrl.prev = newValue;
            ctrl.qtyData = ctrl.qltDataRows;
            ctrl.showLimitCols = false;
            angular.forEach(ctrl.qtyData, function (row) {
                if (ctrl.showLimitCols || row.hasLimit()) {
                    ctrl.showLimitCols = true;
                }
            });
            ctrl.contentAvailable = ctrl.qltDataRows && ctrl.qltDataRows.length > 0;
        }
    });


    function activate() {
        var lastUserInfo = usersettings.getLastUserInfo();
        ctrl.empId = lastUserInfo ? lastUserInfo.empId : null;
        initLookups();
    }
}
export default qualityDataModule