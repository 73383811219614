'use strict';
var moduleLayoutModule = angular.module('dir.dfxModuleLayout',[]);
moduleLayoutModule.directive('dfxModuleLayout', function () {
    return {
        restrict: 'E',
        scope: {},
        controllerAs: 'ctrl',
        transclude: true,
        replace: true,
        bindToController: {
            layout: '=?',
            showLayoutSelection: '=?',
        },
        controller: ['$scope', function ($scope) {
            var ctrl = this;         
            ctrl.items = [];
            ctrl.columns = ['COL1', 'COL2'];

            ctrl.$onInit = function () {
                if (ctrl.layout === undefined) {
                    ctrl.layout = 'TwoCols';
                }
            };

            ctrl.getItems = function (col) {
                var half = Math.floor(ctrl.items.length / 2) + ctrl.items.length % 2;
                return col === 'COL1' ? ctrl.items.slice(0, half) : ctrl.items.slice(half, ctrl.items.length);
            }

            ctrl.addItem = function (item) {
                ctrl.items.push(item);
                ctrl.items.sort(function (t1, t2) {
                    return t1.index > t2.index ? 1 : t1.index < t2.index ? -1 : 0;
                });
            }
            ctrl.onLayoutChanged = function (layout) {
                ctrl.layout = layout;
            }
        }],
        template: require('./dfxModuleLayout.html')
    };
});
export default moduleLayoutModule