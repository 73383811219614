
	'use strict';
	var controllerId = 'resourceworktime';
	var resourceWorkTimeModule = angular.module('rwt.resourceWorkTime', [])

	resourceWorkTimeModule.directive('dfxResourceWorkTime', [function () {
		return {
			restrict: 'E',
			scope: {
				control: '='
			},
			controller: controllerId,
			controllerAs: 'ctrl',
			bindToController: {
				onCancelCallback: '&',
				onSaveCallback: '&',
				onPreventClosing: '&?',
				resource: '=',
				work: '=',
				operat: '='  // Id of work which operations are displayed in the typeahead field.
			},
			template: require('./directives/ResourceWorkTime.html')
		};
	}]);

	resourceWorkTimeModule.controller(controllerId, ['$scope', 'common', 'commonDatacontext', 'hiDatacontext', 'usersettings', 'resourceTaskService', resourceworktime]);

	function resourceworktime($scope, common, commonDatacontext, hiDatacontext, usersettings, resourceTaskService) {
		var ctrl = this;		
		ctrl.userId = null;
		ctrl.hasChanges = false;		
		ctrl.lang = lang;		
		ctrl.works = [];
		ctrl.salaryCodes = [];	
		ctrl.typeAheadWaitMs = 500;		
		ctrl.itemsInTypeAhead = 50;	
		var typedString;
        var worksPromise = null;

		//selections
		ctrl.empSel = null;
		ctrl.workSel = null;
		ctrl.operatSel = null;		
		ctrl.description = null;
		ctrl.date = moment().toDate();
		ctrl.hours = null;
		ctrl.resSalaryCode = null;
		ctrl.extraHourRows = [];
		ctrl.rwtSalaryCodesDisabled = resourceTaskService.getState().rwtSalaryCodesDisabled;
        var searchSerialId = resourceTaskService.getState().useSerialIdInResourceWorkTime;

		// Currently Typehead names are generated from same properties
		ctrl.TypeheadColEnum = {			
			WORKS: { callback: getTypeaheadName, emphasize: [true, false, false], margin: 1, maxWidth: [10, 15, 15] },
			OPERATIONS: { callback: getTypeaheadName, emphasize: [true, false, false], margin: 1, maxWidth: [10, 15, 15] }
		}

		var enums = common.$q.defer();

		/* Properties */

		/**
		  * Cancel editing.
		  */
		ctrl.onCancel = function () {
			if (ctrl.onPreventClosing) {
				ctrl.hasChanges = false;
				ctrl.onPreventClosing({ hasChanges: false });
			}
			ctrl.onCancelCallback();
		}

		/**
		  * Occurs when employee selection has been done.
		  * @param{Selected item} item
		  */
		ctrl.onEmpSelected = function (item) {
			monitoreChanges(item);
			if (item) {
				ctrl.empSel = item;
			}
			else {
				ctrl.empSel = null;
			}
			if (resourceTaskService.getState().useCustomRwtSalaryCodeDefault) {
				handleDefSalaryCode();
			}
		}

		/**
		 * Occurs when work selection has been done. Sets selected Work 
		 *  * @param{Selected item} item
		 */
		ctrl.changeWork = function (item) {
			monitoreChanges(item);
			if (item) {
				ctrl.workSel = item;
				ctrl.operatSel = null;
				getOperations();
			}
			else {
				ctrl.workSel = null;
				ctrl.changeOperat(null);
			}
			if (resourceTaskService.getState().useCustomRwtSalaryCodeDefault) {
				handleDefSalaryCode();
			}
		}

		/**
		 * Occurs when operation selection has been done. Sets selected Operat
		 * @param{Selected item} item
		 */
		ctrl.changeOperat = function (item) {
			monitoreChanges(item);
			if (item) {				
				ctrl.operatSel = item;				
			}
			else {				
				ctrl.operatSel = null;
			}
		}

		ctrl.isWorkUnchanged = function (work) {
			if (work) {
				ctrl.workSel = work;
			}
		}
		ctrl.isOperatUnchanged = function (oper) {
			if (oper) {
				ctrl.operatSel = oper;
			}			
		}

		ctrl.removeWorkSel = function() {
			ctrl.workSel = null;
			//empty operation list
			ctrl.operations = null;
			ctrl.removeOperatSel();			
		};
		ctrl.removeOperatSel = function(){
			ctrl.operatSel = null;
		}
		
		/**
		 * Occurs when salaryCode selection has been done. Sets selected code for ceratin row object
		 * @param{Selected salaryCode, row where selection is made} salaryCode, row
		 */
		ctrl.setSalaryCode = function (salaryCode, row) {			
			if (row) {
				row.salaryCode = salaryCode;
				row.required = true;
				if ((!salaryCode || salaryCode == '') && !row.hi) {
					row.required = false;
				}
			}			
			monitoreChanges(salaryCode);
		}
		/**
		 * Occurs when HourInput selection has been done. Sets selected hi for ceratin row object.
		 * @param{Selected hi, row where selection is made} hi, row
		 */
		ctrl.setHi = function (hi, row) {			
			if (row) {
				row.hi = hi;
				row.required = true;
				if (!hi && !row.salaryCode) {
					row.required = false;
				}
			}		
			monitoreChanges(hi);
		}

		ctrl.removeHours = function () {
			/*ToDo: dfx-float directive seems to prevent updating new value to UI without ng-blur event*/
			ctrl.hours = null;
		}

		ctrl.filteredList = function (lst, s, typeheadColEnum, lstName) {
			var matchingRows = [];
			if (lst) {
				if (lstName == 'works') {
					return getWorkList(s, false).then(function(data){
						return ctrl.filteredList(ctrl.works, s, typeheadColEnum, '')
					});
				}
				else {
					for (var k = 0; k < lst.length; k++) {
						if (s == ' '
							|| common.textContains(lst[k].id, s)
							|| common.textContains((lst[k].al ? lst[k].al : lst[k].name), s)
                            || common.textContains(lst[k].info, s)
                            || (searchSerialId && common.textContains(lst[k].firstSerialId, s))) {
							matchingRows.push(lst[k]);
						}
					}
				}

				matchingRows = common.initializeTypeaheadData(matchingRows, 'id', s, true, true);

				var appendMoreTag = false;
				// Generate data needed by dfxTypeheadColumns.html typehead template
				if (typeheadColEnum) {
					var emphasize = typeheadColEnum.emphasize;
					var maxWidth = typeheadColEnum.maxWidth;
					if (matchingRows.length > ctrl.itemsInTypeAhead) {
						matchingRows = matchingRows.splice(0, ctrl.itemsInTypeAhead);
						appendMoreTag = true;
					}
					common.setTypeheadColumnData(matchingRows, typeheadColEnum.callback, emphasize,
						typeheadColEnum.margin, maxWidth, appendMoreTag);
				}
			}
			return matchingRows;
		};

		ctrl.formatWorkData = function (work) {
			return work ? (work.workId ? work.workId : work.id) + ' ' + (work.workName ? work.workName : work.name) : '';
		}
		ctrl.formatOperatData = function (operat) {
			return operat ? (operat.operatId ? operat.operatId : operat.id) + ' ' + (operat.operatName ? operat.operatName : operat.name) : '';
		}

		/**
		* Saving new entity.
		*/
		ctrl.saveChanges = function () {

			// Check for data validity and required fields.
			var missingRequiredFieldNames = [];		
			(ctrl.empSel && ctrl.empSel != '' ? '' : missingRequiredFieldNames.push(common.$translate.instant("STRCONST.PUBLIC.TXT_EMPID")));
			(ctrl.date && ctrl.date != '' ? '' : missingRequiredFieldNames.push(common.$translate.instant("STRCONST.REP.COL_EVENT_DATE")));
			(ctrl.workSel && ctrl.workSel != '' ? '' : missingRequiredFieldNames.push(common.$translate.instant("STRCONST.LEANPORTAL.COL_WORKID")));
			(ctrl.operatSel && ctrl.operatSel != '' ? '' : missingRequiredFieldNames.push(common.$translate.instant("STRCONST.PUBLIC.TXT_OPERATID")));
            if (!ctrl.hours) {
                var valueExists = false;
                // Verify that some field has hours defined
                angular.forEach(ctrl.extraHourRows, function (extraHourRow) {
                    valueExists |= extraHourRow.hi > 0;
                });
                if (!valueExists) {
                    missingRequiredFieldNames.push(common.$translate.instant("STRCONST.WOR.TXT_EMPHOUR"));
                }
            }
			(ctrl.resSalaryCode && ctrl.resSalaryCode != '' ? '' : missingRequiredFieldNames.push(common.$translate.instant("STRCONST.REP.COL_SALARY_CODE")));
			
			if (missingRequiredFieldNames.length > 0) {
				usersettings.errHandler(common.$translate.instant("STRCONST.DLG.TXT_REQDATAMISSING") + ": " + missingRequiredFieldNames.join(', ') + '.');
			}
			else { // All required fields filled in.

				var entities = [];
				// Create new entity with work and date values.
				var workTimeEntity = hiDatacontext.createWorkTime(null, true, null, null, ctrl.workSel, null, ctrl.date, true)
				
				// Collect data for the entity.
				workTimeEntity.employeeId = ctrl.empSel.empId;
				workTimeEntity.employeeName = ctrl.empSel.userName;
				workTimeEntity.workId = ctrl.workSel.id;
				workTimeEntity.workName = ctrl.workSel.name;
				workTimeEntity.operatId = ctrl.operatSel.id;
				workTimeEntity.operatName = ctrl.operatSel.name;
				workTimeEntity.description = ctrl.description;
				workTimeEntity.userId = ctrl.userId;
                workTimeEntity.hours = !ctrl.hours ? 0 : ctrl.hours;
				workTimeEntity.salaryCode = ctrl.resSalaryCode;
				//create a child salaryCodeEntity for each code row
				angular.forEach(ctrl.extraHourRows, function (row) {
					if (row.hi && row.salaryCode) {
						var extraSalaryCodeEntity = hiDatacontext.createExtraSalaryCode(workTimeEntity, row.hi, row.salaryCode)
						entities.push(extraSalaryCodeEntity);
						hiDatacontext.hourInputManager.addEntity(extraSalaryCodeEntity);
					}
				});
				
				if (ctrl.resource) {
					workTimeEntity.resId = ctrl.resource.resId;
				}

				hiDatacontext.hourInputManager.addEntity(workTimeEntity);
				entities.push(workTimeEntity);
				// Save
				hiDatacontext.saveChanges(entities, false).then(function (saveResult) {					
					angular.forEach(entities, function (entity) {
						hiDatacontext.hourInputManager.detachEntity(entity);
					});
					ctrl.onSaveCallback();
				},
				function (error) {					
					angular.forEach(entities, function (entity) {
						hiDatacontext.hourInputManager.detachEntity(entity);
					});
				}
				);
			}
		};

		activate();

		$scope.$on('COMPANYOPUCHANGES', function () {
			activate();
		});

		ctrl.setHasChanges = function (newValue) {
			monitoreChanges(newValue);
		};

		function monitoreChanges(newValue) {
			if (newValue && newValue !== '') {
				ctrl.hasChanges = true;
				if (ctrl.onPreventClosing) {
					ctrl.onPreventClosing({ hasChanges: true });
				}
			}
			else {
				if (ctrl.onPreventClosing) {
					ctrl.onPreventClosing({ hasChanges: false });
				}
				ctrl.hasChanges = false;
			}
		}

		/* Functions */

		function activate() {
			usersettings.getUserSettings().then(function (data) {
				if (!data || !data.info || !data.info.userId) {
					usersettings.redirectToLogin();
				} else {
					usersettings.authorizeHeader();
					ctrl.userId = data.info.userId;					
					ctrl.empSel = data.info;

					var promises = [];
					promises.push(getWorkList(null, true));
					promises.push(getOperations());
					promises.push(getSalaryCodes(true).then(function (data) {
						enums.resolve(data);
						getExtraRows();
					}));

					common.activateController(promises, controllerId).then(function () {
						if (resourceTaskService.getState().useCustomRwtSalaryCodeDefault) {
							handleDefSalaryCode();
						}
					});
				}
			}, function () {
				usersettings.redirectToLogin();
			});
		}

		/**
		 * Provides data for type ahead columns
		 */
		function getTypeaheadName(column, object) {
			var ret = null;
            // Work has 3 fields operation 2
            var columns = searchSerialId && object.op ? 3 : 2;
            if (column < columns) {
				if (column == 0) {
					ret = object.id;
				}
				else if (column == 1) {
					ret = object.name;
				}
                else if (column == 2) {
                    ret = object.firstSerialId ? object.firstSerialId : '';
                }
			}
			return ret;
		}

		function handleDefSalaryCode() {
			var scDef = null;
			scDef = getDefaultSalaryCodeFromWork();
			if (scDef) {
				ctrl.resSalaryCode = scDef;
			}
			else {
				handleEmpDefaultSalaryCode().then(function (data) {
					if (data) {
						ctrl.resSalaryCode = data;
					}
					else {
						for (var i = 0; i < ctrl.salaryCodes.length; i++) {
							var scTemp = ctrl.salaryCodes[i];
							if (scTemp && scTemp.extraSelect && scTemp.extraSelect[1] && scTemp.extraSelect[1] == 'DEF') {
								ctrl.resSalaryCode = scTemp.id;
								break;
							}
						}
					}
				});
			}
		}

		function getDefaultSalaryCodeFromWork() {
			var workType = null;
			/*Get work type*/
			if (ctrl.workSel && ctrl.workSel.id) {
				for (var i = 0; i < ctrl.works.length; i++) {
					if (ctrl.works[i].id == ctrl.workSel.id) {
						workType = ctrl.works[i].workType;
						break;
					}
				}
				if (workType) {
					var wtEnumValue = null
					/*Get WORKTYPE-enum row with selected work(type)*/
					var wtRow = common.getEnumRowById(ctrl.workTypeCodes, workType);
					if (wtRow && wtRow.extraSelect && wtRow.extraSelect[0]) {
						wtEnumValue = wtRow.extraSelect[0];
					}
					if (wtEnumValue) {
						/*Get SALARYCODE-enum row from WORKTYPE-enum*/
						var salRow = common.getEnumRowById(ctrl.salaryCodes, wtEnumValue);
						if (salRow) {
							return salRow.id;
						}
					}
				}
			}
			return null;
		}

		function handleEmpDefaultSalaryCode() {
			return (getEmpDefaultSalaryCode().then(function (data) {
				if (data) {
					return data;
				}
				else {
					ctrl.removeHours();
					return null;
				}
			}));
		}

		function getEmpDefaultSalaryCode() {
			return (commonDatacontext.getEmployeeList().then(function(data){
				if (data) {
					for (var i = 0; i < data.length; i++) {
						if (ctrl.empSel && (data[i].empId == ctrl.empSel.empId)) {
							return data[i].scDef;
						}
					}
				}
			}));
		}

		/**
		 * Gets salary codes from dataBase
		 */
		function getSalaryCodes(forceRefresh) {
            var Enums = [{ id: 'SALARYCODE_001' }];
            if (resourceTaskService.getState().useCustomRwtSalaryCodeDefault) {
                Enums.push(resourceTaskService.getState().rwtSalaryCodesDefault);
            }

            var enumsJson = JSON.stringify(Enums);
            return commonDatacontext.getLookups(lang, forceRefresh, null, null, enumsJson).then(function (data) {
                if (data && data[0] && data[0].Enums) {
                    ctrl.salaryCodes = data[0].Enums["SALARYCODE"];
                    // sortOrder: 'value11'
                    ctrl.salaryCodes.sort(function (cmp1, cmp2) {
                        if (cmp1.columns) {
                            var c1 = cmp1.columns.VALUE11 ? cmp1.columns.VALUE11 : 0;
                            var c2 = cmp2.columns.VALUE11 ? cmp2.columns.VALUE11 : 0;
                            return c1 < c2 ? -1 : c1 > c2 ? 1 : 0;
                        }
                        return 0;
                    });
                    if (resourceTaskService.getState().useCustomRwtSalaryCodeDefault) {
                        ctrl.workTypeCodes = data[0].Enums['WORKTYPE'];
                    }
                    else {
                        ctrl.resSalaryCode = ctrl.salaryCodes[0].id;
                    }
                }
                return data;
            });
		}
		ctrl.getDefaultSelection = function(salModel, index){
			salModel = ctrl.extraHourRows[index].salaryCode;
			return salModel;
		}
		/**
		 * Creates a list with row Objects to bind hi and salary codes. 
		 */
		function getExtraRows() {
			//SALARYCODE value11 -> values 3-8 reserved for extras.
			for (var i = 0; i < ctrl.salaryCodes.length; i++) {
				var sc = ctrl.salaryCodes[i].extraSelect;
				if (sc && sc[0] && sc[0] >=3 && sc[0] <=8) {
					var rowObj = { hi: null, salaryCode: ctrl.salaryCodes[i].id, nabbr:ctrl.salaryCodes[i].nabbr, required: false };
					ctrl.extraHourRows.push(rowObj);
				}
			}
		}
		/**
		 * Gets WorkList 
		 */
		function getWorkList(searchString, queryExact) {
			var workId = null;
			if(queryExact){
                workId = ctrl.workSelId = ctrl.workSel ? ctrl.workSel.id : ctrl.work ? ctrl.work.workId : ctrl.operat ? ctrl.operat.workId : null; //use default work, if none was selected yet
			}
            if (worksPromise) {
                if (worksPromise.searchString === searchString) {
                    return worksPromise.promise;
                }
                worksPromise.reject();
            }
            worksPromise = common.$q.defer();
            worksPromise.searchString = searchString;
            hiDatacontext.getWorks(null, searchString, false, workId, ctrl.resource.resId, searchSerialId).then(function (data) {
                // Verify that the returned data is returned for the last request
				if (data && data[0] && worksPromise && worksPromise.searchString === searchString) {
					ctrl.works = data;
					common.setTypeheadColumnData(ctrl.works, getTypeaheadName, [true, false, false], 0, [10, 15, 10], false);
					worksPromise.resolve(ctrl.works);
				}
				else {
					worksPromise.resolve();
				}
				worksPromise = null;
            });
            return worksPromise.promise;
		}

		/**
		  * Gets operats for specific work from database and set default work and operat if available.
		  */
		function getOperations() {
            ctrl.workSelId = ctrl.workSel ? ctrl.workSel.id : ctrl.work ? ctrl.work.workId : ctrl.operat ? ctrl.operat.workId : null; //use default work, if none was selected yet
			if (ctrl.workSelId) {
				return hiDatacontext.getWorks(null, '', false, ctrl.workSelId).then(function (data) {
					// If any works were found, get theKirsi first one. There should be only one work as we are querying with exact work id.
					if (data && data[0]) {
						// Get work name, project data, task data and operations.
						ctrl.workSel = data[0];
						ctrl.workName = data[0].name;
						ctrl.operations = data[0].op;
						//for deafult selection set operatSel
                        if ((ctrl.work && ctrl.work.workId == ctrl.workSel.id) || (ctrl.operat && ctrl.operat.workId == ctrl.workSel.id)) {
							angular.forEach(ctrl.operations, function (op) {
								if (op.id == ctrl.operat.operatId) {
									ctrl.operatSel = op;
								}
							});
						}
						// Initialize typeahead column data.
						common.setTypeheadColumnData(ctrl.operations, getTypeaheadName, [true, false], 0, [10, 15], false);
					}
					else { // No work found with given work id. Raise an error.
						//usersettings.errHandler(strConst.ERROR$50003.replace('%1', ctrl.workId));
						//ctrl.onCancelCallback();
						ctrl.errMsg = common.$translate.instant("STRCONST.ERROR.50003").replace('%1', ctrl.work.workId);
					}
				});
			}
			return true;
		}
	}

export default resourceWorkTimeModule