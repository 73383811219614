/**
* ResourceWork Directive & Controller
* The directive capsulates the work table presentation. The directive takes care of
* resource work reading.
* The visible colummns are controlled by 'statusCmd'-parameter. This can be changed
* to more generic solution when needed.
* The "owner" is responsiple for implementing all the actions
*/
var worksCtrlId = 'worksctrl';
var worksModule=angular.module('manudir.dfxWorks',[])
worksModule.directive('dfxWorks', [function () {
	return {
		restrict: 'E',
		scope: {},
		controller: worksCtrlId,
		controllerAs: 'ctrl',
		bindToController: {
			resource: '=',
			range: '=?',
			layout: '=?', // For now just table layout
			showLoginButton: '=?',
			selectAfterReady: '=?',
			singleViewState: '=?',
			onLoadingReadyCallback: '&',
			onControllerCallback: '&',
			onSelectionChangedCallback: '&',
			onCloseCallback: '&?',
			formVersion: '=?', // defaults to SELECT_WORK_FOR_RESOURCE
			includeTasks: '=?',
			allowAllWorks: '=?',
			requireResource: "=?", // If set to true, does not start works query if resource not given.
			rstBtn: '=?',
			searchDb: '=?',
            showActiveOperatEventCreatedBy: '=?',   // Obsolete, if listFormId is defined
            showActiveOperatId: '=?',               // Obsolete, if listFormId is defined
			showCommand: '=?',
            showWorkListIndicator: '=?',            // Obsolete, if listFormId is defined
			sortCommand: '=?',
			taskCommand: '=?',
			tasksSort: '=?',
            listFormId: '=?', // If defined, then dfxGrid used in the list
            showSerialId: '=?',                     // Obsolete, if listFormId is defined 
            showActiveOperatStartCur: '=?',         // Obsolete, if listFormId is defined 
            visible: '=?'                           // Track directive visibility. Used to refresh grid.

		},
		template: require('./dfxWorks.html')
	}
}]);
worksModule.controller(worksCtrlId, ['$scope', 'manDatacontext', 'usersettings', 'common', '$uibModal', '$location', works]);
function works($scope, manDatacontext, usersettings, common, $uibModal, $location) {
    var ctrl = this;

    // API offered for the client with onControllerCallback
    var service = {
        triggerLoading: triggerLoading,
        selectWork: selectWork
    }
    ctrl.$onInit = function () {
        // Default functinalit/visibility for list if dfxGrid is used
        ctrl.dfxListGridOptions = ctrl.listFormId ? {
            multiSelect: false,
            enableRowHeaderSelection: false,
            showHeader: false,
            columnDefs: []
		} : null;
		// Works table grid or single work.
		ctrl.workGridOptions = {
			autoSelectFirstRow: false,
			columnDefs: [],
			enableFiltering: false,
			data: [],
			multiSelect: false,
			enableRowSelection: true,
			enableRowHeaderSelection: false,
			enableSelectAll: false,
			useParentElementHeight: true
		};
		ctrl.gridStyle = function () {
			return ctrl.singleMode ? 'height: 100px' : '';
		}
		ctrl.gridApi;
		setColumnCellTemplates();
		ctrl.layout = (typeof ctrl.layout === 'undefined' ? 'TABLE' : ctrl.layout);
        ctrl.rstBtn = ctrl.rstBtn ? ctrl.rstBtn : null;

        // Sorting for given configuration
        ctrl.showCommand = (typeof ctrl.showCommand === 'undefined' ? 'SHOW_OPEN' : ctrl.showCommand);
        ctrl.taskCommand = ctrl.taskCommand;
        $scope.sortTypeDefault = ctrl.showCommand == 'SHOW_COMPLETED' ? ['-actualEnd'] :
            ['-isStarted', 'plannedStart'];
        $scope.sortType = $scope.sortTypeDefault.slice();
        if (typeof ctrl.requireResource == 'undefined') {
            ctrl.requireResource = false;
        }
        ctrl.onControllerCallback({ object: null, childCtrl: service });
	};

	ctrl.formId = 25124;
	ctrl.currentPage = 1;
	ctrl.maxPages = 5;
	ctrl.numPerPage = 12;
	// Two separate arrays (works/workList) exist while when toggling with singleMode we show one or all works and want to avoid db access
	ctrl.works = [];
	ctrl.workList = [];
	ctrl.filteredWorks = [];
	ctrl.tasks = [];
	ctrl.selectedWork = null;
	ctrl.selectedTask = null;
	var current = null;
	ctrl.scrollTo = null;
	ctrl.singleMode = false;

	// UI fetches the value from ctrl.visible associative array filled in init()
	ctrl.visible = [];

	init();

	$scope.$watchGroup(['ctrl.resource'], function (newValue) { //trigger on resource change and signalR event
		if (newValue[0] !== undefined) {
			// Initial loading is triggered from outside with triggerLoading api (L21588)
			if (ctrl.resource && current && current.resId != ctrl.resource.resId) {
				ctrl.works = [];
				ctrl.filteredWorks = [];
				ctrl.selected = null;
				ctrl.singleMode = false;
				getResourceWorks(ctrl.resource, 0, 1000, true);
			}
		}
	});

	ctrl.search = function ($event, rstBtnVal, forceRefresh) {
		usersettings.setPersonalSettingValue('lastWorkSearchText', ctrl.searchText);
		if (rstBtnVal) {
			ctrl.rstBtn = rstBtnVal;
		}

		// return if exact match was found
		if (doFilter()) {
			return;
		}
		// otherwise fetch works by throttling 
		else if (ctrl.searchDb){
			common.debouncedThrottle('WORKSEARCH', function () {
				//console.log('WORKSEARCH: ' + ctrl.searchText);
				var resourceId = ctrl.resource ? ctrl.resource.resId : null;
				var requestedByUi = false;
				var forceRefresh = false;
				manDatacontext.getResourceWorks(resourceId, 0, 1000, requestedByUi, ctrl.showCommand, ctrl.range, ctrl.includeTasks, forceRefresh, ctrl.sortCommand, ctrl.tasksSort, ctrl.searchText, ctrl.formVersion).then(function (data) {
					if (data) {
						ctrl.works = common.unique(ctrl.works.concat(data), 'recId');
						doFilter();
					}
				});
			});
		}

		// return true if exact match was found
		function doFilter() {
			var lastUserInfo = usersettings.getLastUserInfo();
			var worksToFilter = [];
			if (rstBtnVal == 'CurrentResource') {
				for (var i = 0; i < ctrl.works.length; i++) {
					for (var j = 0; j < ctrl.works[i].resourceTasks.length; j++) {
						if (ctrl.works[i].resourceTasks[j].resId == ctrl.resource.resId) {
							worksToFilter.push(ctrl.works[i]);
							break;
						}
					}
				}
			}
			else {
				worksToFilter = ctrl.works;
			}
			if (ctrl.searchText || (ctrl.showActiveOperatEventCreatedBy && !ctrl.showFromAllCreators)) {
				ctrl.filteredWorks = worksToFilter.filter(function (item) {
					var textCondition = false;
					if (ctrl.searchText) { // Input field
						textCondition = common.textContains(item.workName, ctrl.searchText) || common.textContains(item.workId, ctrl.searchText);
						if (ctrl.showActiveOperatId) {
							textCondition = textCondition || common.textContains(item.activeOperatId, ctrl.searchText);
						}
						if (ctrl.showActiveOperatEventCreatedBy) {
							textCondition = textCondition || common.textContains(item.activeOperatEventCreatedBy, ctrl.searchText);
                        }
                        if (ctrl.showSerialId) {
                            textCondition = textCondition || common.textContains(item.firstSerialId, ctrl.searchText);
                        }
                        if (ctrl.showActiveOperatStartCur && item.activeOperatStartCur) {
                            // compare as pure numbers
                            var str = item.activeOperatStartCur.toLocaleDateString().replace(/\D/g, '');
                            var txt = ctrl.searchText.replace(/\./g, '');
                            textCondition = textCondition || str.indexOf(txt) !== -1;
                        }

					}
					else {
						textCondition = true;
					}
					var creatorCondition = true; // Checkbox
					if (ctrl.showActiveOperatEventCreatedBy) {
						if (ctrl.showFromAllCreators) {
							creatorCondition = true;
						}
						else {
							creatorCondition = (item.activeOperatEventCreatedBy == null || item.activeOperatEventCreatedBy == lastUserInfo.userId);
						}
					}
					return textCondition && creatorCondition;
                });
			}
			else {
				ctrl.filteredWorks = worksToFilter;
            }
            if (ctrl.dfxListGridOptions) {
                ctrl.dfxListGridOptions.refreshData(ctrl.filteredWorks);
            }
			if (ctrl.selectedWork && ctrl.filteredWorks.length >= ctrl.numPerPage) {
				var sortedWorks = common.$filter('orderBy')(ctrl.filteredWorks, $scope.sortType);
				ctrl.currentPage = Math.floor(sortedWorks.indexOf(ctrl.selectedWork) / ctrl.numPerPage) + 1;
			}
			var exactMatch = false;
			if (ctrl.filteredWorks.length === 1 && ctrl.searchText && ctrl.searchText.length) {
				var work = ctrl.filteredWorks[0];
				exactMatch = work.workId.length === ctrl.searchText.length && ctrl.searchText.toUpperCase().indexOf(work.workId) === 0;
			}
			return exactMatch;
		}
	}

	ctrl.filterResourceTasks = function (resourceTasks) {
		if (ctrl.rstBtn == 'CurrentResource' && ctrl.resource) {
			return resourceTasks.filter(function (item) {
				return item.resId == ctrl.resource.resId;
			});
		}
		return resourceTasks;
	};

	ctrl.getTaskStatusColor = function (status, workStatus) {
		var ret = 'task-status-color-value-undefined';
		if (ctrl.worStatusRows && status) {
			var statusEnum = common.getEnumRowById(ctrl.worStatusRows, status);
			ret = 'task-status-color-value-' + (statusEnum && statusEnum.extraSelect[0] ? statusEnum.extraSelect[0] : 'undefined');
		}
		else if (workStatus) {
			return ctrl.getTaskStatusColor(workStatus);
		}
		return ret;
	}

	/**
	 * Format the number to fixed amount of decimals
	 * @param {number to be formatted} i 
	 * @param {number of decimals shown} decimals
	 */
	$scope.formatNumber = function (i, decimals) {
		if (i === null) return null;
		decimals = (decimals === undefined ? 1 : decimals);
		return parseFloat(i).toFixed(decimals);
	}


	ctrl.selectWork = function (work, requestedByUi) {
		if (work.expanded && ctrl.includeTasks) {
			work.expanded = false;
		}
		else {
			work.expanded = true;
            ctrl.selectedWork = work;
            if (ctrl.listFormId) {
                common.setLastGridId({ 'workId': work.workId });
            }
			ctrl.onSelectionChangedCallback({ selection: { work: ctrl.selectedWork, requestedByUi: requestedByUi } });
		}
	}

	ctrl.selectTask = function (task, event) {
		if (event) {
			event.preventDefault();
			event.stopImmediatePropagation();
		}
		angular.forEach(ctrl.filteredWorks, function (work) {
			if (work.recId == task.ResourceWork.recId) {
				work.expanded = true;
			}
			else {
				work.expanded = false;
			}
		});

		ctrl.selectedTask = task;
		ctrl.onSelectionChangedCallback({ selection: { work: task.ResourceWork, task: task, requestedByUi: true } });
	}

	/**
	 * Gets works for a resource from local database. 
	 * The works are added to resources 'works'- member variable.
	 * @param {resources works are fetched for.} resources 
	 * @param {incremental reading.} offset 
	 * @param {incremental reading.} size 
	 * @param {loading indication and GUI blocking} requestedByUi
	 * @param {resIds indication that API is called recursively} resIds
	 */
	function getResourceWorks(resource, offset, size, requestedByUi, resIds) {
		current = resource ? resource : null;
		var resourceId = null;
		if (!ctrl.allowAllWorks) {
			resourceId = ctrl.resource ? ctrl.resource.resId : null;
		}
		if (ctrl.requireResource && resourceId == null) {
			ctrl.onLoadingReadyCallback({ works: ctrl.works });
			return;
		}
		var forceRefresh = false;
		var searchText = null;
		manDatacontext.getResourceWorks(resourceId, offset, size, requestedByUi, ctrl.showCommand, ctrl.range, ctrl.includeTasks, forceRefresh, ctrl.sortCommand, ctrl.tasksSort, searchText, ctrl.formVersion).then(function (data) {
            if (data) {
                ctrl.works = offset === 0 ? data : common.unique(ctrl.works.concat(data), 'recId'); 

                ctrl.workList = ctrl.works;
				data.forEach(function (work) {
					if (ctrl.selectAfterReady && work.workId == ctrl.selectAfterReady.workId) {
						ctrl.selectWork(work, false);
						if (ctrl.includeTasks) {
							work.expanded = true;
							for (var i = 0; i < work.resourceTasks.length; i++) {
								if (work.resourceTasks[i].operatId == ctrl.selectAfterReady.operatId) {
									ctrl.selectTask(work.resourceTasks[i]);
								}
							}
						}
					}
				});
				applyFilter();
				// Recursion: continue reading, if not all works are read
				if (data.length == size) {
					getResourceWorks(resource, offset + size, size, requestedByUi);
				}
				else {
					if (ctrl.workGridOptions) {
						if (ctrl.workGridOptions.refreshData) {
							ctrl.workGridOptions.refreshData(ctrl.workList);
						}
						else {
							ctrl.workGridOptions.data = ctrl.workList;
						}
					}
					ctrl.onLoadingReadyCallback({ works: ctrl.works });
				}
			}
		});
	}

	/**
	 * Gets works for the view depending if the work object is stored in localStorage.
	*/
	function selectWork(work, scrollTo) {
		if (ctrl.singleMode == false) {
			if (work) {
				manDatacontext.getResourceWork(work.workId, true).then(function (data) {
					work = data[0];
					ctrl.selected = work;
					if (ctrl.workList.indexOf(work) === -1) {
						ctrl.workList.push(work);
					}
					updateWorkGrid();
					ctrl.onSelectionChangedCallback({ work: work });
				})
				ctrl.workList = [];
				ctrl.singleMode = true;
				ctrl.scrollTo = null;
				// updateWorkGrid();
			}
		}
		else {
			ctrl.singleMode = false;
			if (!ctrl.selected && ctrl.workList) {
				ctrl.selected = ctrl.workList[0];
			}
			if (scrollTo) {
				ctrl.scrollTo = work ? work.workId : (ctrl.selected ? ctrl.selected.workId : null);
			}
			//if the page is loaded directly from localStorage (ctrl.works is empty), then we need to get works from ds.
			if (ctrl.works && ctrl.works.length > 0) {
				ctrl.workList = ctrl.works;
				updateWorkGrid();
			}
			else {
				ctrl.workList = [];
				getResourceWorks(ctrl.resource, 0, 1000, true);
			}
			ctrl.onSelectionChangedCallback({ work: null });
		}
	}

	function updateWorkGrid() {
		if (ctrl.workGridOptions) {
			if (ctrl.workGridOptions.refreshData) {
				ctrl.workGridOptions.refreshData(ctrl.workList);
			}
			else {
				ctrl.workGridOptions.data = ctrl.workList;
			}
			if (ctrl.scrollTo && ctrl.gridApi && ctrl.gridApi.grid) {
				var i = 0;
				var rowNum = -1;
				angular.forEach(ctrl.workList, function (row) {
					row.workId === ctrl.scrollTo ? rowNum = i : i++;
				});
				if (rowNum >= 0) {
					ctrl.gridApi.core.scrollTo(ctrl.workGridOptions.data[rowNum], ctrl.workGridOptions.columnDefs[0]);
					ctrl.gridApi.core.scrollTop = rowNum * ctrl.workGridOptions.rowHeight;
					if (ctrl.gridApi.grid.rows.length >= rowNum) {
						ctrl.gridApi.grid.rows[rowNum].isSelected = true;
					}
				}
			}
		}
	}

	function init() {
		usersettings.getLoginPromise().then(function () {
			ctrl.searchText = usersettings.getPersonalSettingValue('lastWorkSearchText');
			// Initial loading is triggered from outside with triggerLoading api (L21588)
			manDatacontext.getLookups(lang, true).then(function (data) {
				if (data && data[0] && data[0].Enums) {
					ctrl.worStatusRows = data[0].Enums["WORSTATUS"] ? data[0].Enums['WORSTATUS'] : null;
					ctrl.workPriorities = data[0].Enums["WORKPRIORITY"] ? data[0].Enums['WORKPRIORITY'] : null;
				}
			});
		});
	}

	function applyFilter() {
		ctrl.search(null, ctrl.rstBtn);
	}

	ctrl.setStatusTooltip = function (indicator) {
		return indicator == 0 ? common.$translate.instant("STRCONST.LEANPORTAL.TXT_NOT_REQUIRED") : (indicator == 20 ? common.$translate.instant("STRCONST.LEANPORTAL.TXT_PARTIALLY_ENTERED") : (indicator == 10 ? common.$translate.instant("STRCONST.PUBLIC.TXT_ALL_MISSING") : common.$translate.instant("STRCONST.LEANPORTAL.TXT_ALL_ENTERED")));
	}
	ctrl.workSelected = function (work, scrollTo) {
		if (!ctrl.selected || (work && work.workId !== ctrl.selected.workId)) {
			selectWork(work, scrollTo);
		}
	}
	ctrl.getEnumNameFromId = function (enumName, id, showNabbr) {
		return common.getEnumNameById(enumName, id, showNabbr);
	}

	ctrl.onGridApi = function (gridApi) {
		ctrl.gridApi = gridApi;
	}

	function setColumnCellTemplates() {

		function getCellTemplateForStatusCircles() {
			var notRequired = common.$translate.instant("STRCONST.LEANPORTAL.TXT_NOT_REQUIRED");
			var partiallyEntered = common.$translate.instant("STRCONST.LEANPORTAL.TXT_PARTIALLY_ENTERED");
			var allMissing = common.$translate.instant("STRCONST.PUBLIC.TXT_ALL_MISSING");
			var allEntered = common.$translate.instant("STRCONST.LEANPORTAL.TXT_ALL_ENTERED");

			var traceCircleTitle = 'title="' + common.$translate.instant("STRCONST.LEANPORTAL.TXT_TRACEIDS") + ': {{row.entity.traceIndicator == 0 ? \'' + notRequired + '\' : (row.entity.traceIndicator == 20 ? \'' + partiallyEntered + '\' : (row.entity.traceIndicator == 10 ? \'' + allMissing + '\' : \'' + allEntered + '\'))}}"';
			var traceCircleNgClass = 'ng-class="{\'task-status-color-value-undefined\': row.entity.traceIndicator==0,\'dfx-partially-missing\': row.entity.traceIndicator==20, \'dfx-missing-all\': row.entity.traceIndicator==10, \'dfx-required-items\' : row.entity.traceIndicator==30}"';
			var traceCircle = '<i ' + traceCircleTitle + ' class="fa fa-circle ng-scope" ' + traceCircleNgClass + '></i>';

			var serialIdCircleTitle = 'title="' + common.$translate.instant("STRCONST.LEANPORTAL.COL_SERIALIDS") + ': {{row.entity.serialIdIndicator == 0 ? \'' + notRequired + '\' : (row.entity.serialIdIndicator == 20 ? \'' + partiallyEntered + '\' : (row.entity.serialIdIndicator == 10 ? \'' + allMissing + '\' : \'' + allEntered + '\'))}}"';
			var serialIdCircleNgClass = 'ng-class="{\'task-status-color-value-undefined\': row.entity.serialIdIndicator==0,\'dfx-partially-missing\': row.entity.serialIdIndicator==20, \'dfx-missing-all\': row.entity.serialIdIndicator==10, \'dfx-required-items\' : row.entity.serialIdIndicator==30}"';
			var serialIdCircle = '<i ' + serialIdCircleTitle + ' class="fa fa-circle ng-scope" ' + serialIdCircleNgClass + '></i>';

			var qualityDataCircleTitle = 'title="' + common.$translate.instant("STRCONST.WOR.HDR_MONITOR") + ': {{row.entity.qualityDataIndicator == 0 ? \'' + notRequired + '\' : (row.entity.qualityDataIndicator == 20 ? \'' + partiallyEntered + '\' : (row.entity.qualityDataIndicator == 10 ? \'' + allMissing + '\' : \'' + allEntered + '\'))}}"';
			var qualityDataCircleNgClass = 'ng-class="{\'task-status-color-value-undefined\': row.entity.qualityDataIndicator==0,\'dfx-partially-missing\': row.entity.qualityDataIndicator==20, \'dfx-missing-all\': row.entity.qualityDataIndicator==10, \'dfx-required-items\' : row.entity.qualityDataIndicator==30}"';
			var qualityDataCircle = '<i ' + qualityDataCircleTitle + ' class="fa fa-circle ng-scope" ' + qualityDataCircleNgClass + '></i>';

			return traceCircle + serialIdCircle + qualityDataCircle;
		}

		ctrl.workGridOptions.customColDefs = {
			'STATUS_CIRCLES': {
				name: ' ',
				cellTemplate: getCellTemplateForStatusCircles()
			}
		}
	}

	/**
	 * Trigger loading for given workId or for selected resource if workId is null
	 * @param {workId work ID} workId 
	 */
	function triggerLoading(workId) {
		// selectWork will set to true if work is selected
		ctrl.singleMode = false;
		if (workId) {
			// actual work is read in selectWork
			selectWork({ 'workId': workId });
		}
		else {
			getResourceWorks(ctrl.resource, 0, 1000, true);
		}
    }

    /*
     * When directive comes visible, refresh grid view.
     */
    $scope.$watch('ctrl.visible', function (newVal, oldVal) {
        if (newVal == true && ctrl.listFormId) {
            refreshGrid();
        }
    })
    /**
     * Refresh grid view.
     */
    function refreshGrid() {
        if (typeof ctrl.dfxListGridOptions.refreshView === 'function') {
            ctrl.dfxListGridOptions.refreshView();
        }
    }
}

export default worksModule