'use strict'
var module = angular.module('manudir.createRepairOperModal', [])

module.controller('createRepairOperModalCtrl', function ($scope, $uibModalInstance, common, task, manDatacontext, manService, afterRepairTaskAdding, uiGridConstants) {
    /* Basic variables */
    var ctrl = this;
    var workOperations = [];
    var editButtonTooltipText;
    var deleteButtonTooltipText;
    ctrl.task = task;
    ctrl.extraButtons = [{ id: 'saveAndClose', name: 'OK', before: true, isDefault: true }];
    ctrl.resourceMissing = false;

	ctrl.$onInit = function () {
		init();
	}


    function initGrid() {
        ctrl.gridOptions = {
            rowTemplate: 
                '<div ng-class="{\'dfx-emphasize-background\': !row.entity.isCreateRepairOperAllowed}" > \
                    <div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader}"  ui-grid-cell></div> \
                </div>',
            enableRowSelection: true,
            enableCellEdit: true,
            enableCellEditOnFocus: true,
            enableRowHeaderSelection: false,
            //enableFullRowSelection: true,   // allow both clicking on the row, and also clicking on the rowHeader
            multiSelect: false,
            modifierKeysToMultiSelect: false, // allow selecting multiple rows only if the Ctrl-Key is used when selecting
            enableVerticalScrollbar: 2,
            enableHorizontalScrollbar: 2,
            enableColumnMenus: false,
            enableGridMenu: false,
            enableSorting: false,
            enableFiltering: false,
            rowHeight: 40,
            selectionRowHeaderWidth: 30, //?
            showExpandAllButton: false,
            onRegisterApi: function (gridApi) {
                ctrl.gridApi = gridApi;
                ctrl.gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                    ctrl.editedRow = row;
                    $scope.selResource = null
                    angular.forEach(ctrl.allResources, function (res) {
                        if (res.resId === ctrl.editedRow.entity.resId) {
                            $scope.selResource = res;
                        }
                    });
                });
            }
        };


        var buttonsTemplate = {
            enableFiltering: false,
            width: '3%',
            name: 'buttons',
            displayName: '',
            field: 'foo',
            enableSorting: false,
            headerCellClass: 'disable-pointerevent',
            pos: 5,
            headerCellTemplate: null,
            cellTemplate: getButtonTemplate(['edit', 'delete'], false)
        };
        var moveButtonTemplate = angular.copy(buttonsTemplate);
        moveButtonTemplate.name = 'move-buttons';
        moveButtonTemplate.pos = 1;
        moveButtonTemplate.cellTemplate = getButtonTemplate(['move-up', 'move-down'], true);
        moveButtonTemplate.width = '3%';

        common.$translate(['STRCONST.PUBLIC.BTN_MODIFY', 'STRCONST.PUBLIC.BTN_DELETE', 'STRCONST.PUBLIC.TXT_ID', 'STRCONST.PUBLIC.TXT_NAME', 'STRCONST.PUBLIC.TXT_RESOURCE']).then(function (translations) {
            editButtonTooltipText = translations['STRCONST.PUBLIC.BTN_MODIFY'];
            deleteButtonTooltipText = translations['STRCONST.PUBLIC.BTN_DELETE'];
            ctrl.gridOptions.columnDefs = [
                moveButtonTemplate,
                { name: translations['STRCONST.PUBLIC.TXT_ID'], field: "decoratedOperatId", width: '16%', pos: 2 },
                { name: translations['STRCONST.PUBLIC.TXT_NAME'], field: "operatName", width: '31%', pos: 3 },
                { name: translations['STRCONST.PUBLIC.TXT_RESOURCE'], width: '47%', pos: 4, cellTemplate: getResourceEditTemplate() },
                buttonsTemplate
            ];
        });

        function getButtonTemplate(buttons, move) {
            var specifier = move ? 'vertically-aligned ' : '';
            var str = '<div segregated-click class=\"' + specifier + 'ui-grid-row-icons"><div class="dfx-flex-item-flexible"></div>';
            angular.forEach(buttons, function (button) {
                str += '<div class="ui-grid-row-icon ui-grid-row-' + button + '-icon" title="{{grid.appScope.getTooltip(\'' + button + '\')}}" ng-click="grid.appScope.buttonClicked(row.entity,\'' + button + '\')" ng-if="grid.appScope.isVisible(row.entity,\'' + button + '\')"></div>';
            });
            str += '</div>';
            return str;
        }

        function getResourceEditTemplate() {
            return '<div id="repair-oper-resource-selection" class="dfx-full-height dfx-flex-row dfx-flex-row--align-center"> \
                        <div ng-if="!grid.appScope.showCombo(row)"><span>{{row.entity.resId + \' \' + row.entity.resName}}</span></div> \
                        <div ng-if="grid.appScope.showCombo(row)" class="dfx-full-width dfx-flex-row" isolate-click> \
                            <input  type="text" class="a-dropdown" \
                                data-ng-model="grid.appScope.selResource" \
                                typeahead-template-url="dfxTypeheadColumns.html" \
                                typeahead-min-length="1" \
                                typeahead-on-select="grid.appScope.resourceSelected($item)" \
                                uib-typeahead="r as r.resId + \' \' + r.name for r in grid.appScope.filteredResourceList($viewValue)" \
                                typeahead-editable="false" hi-typeahead typeahead-append-to-body="true" /> \
                            <i ng-if="grid.appScope.showCombo(row)" isolate-click  class="dfx-typeahead-clear-rex dfx-close-link" ng-click="grid.appScope.resourceSelected(null); grid.appScope.selResource = null;"></i> \
                        </div> \
                    </div>';
        }
    }
    $scope.showCombo = function (row) {
        return row === ctrl.editedRow && row.entity.entityAspect.entityState.isAdded();
    };

    $scope.resourceSelected = function (resource) {
        $scope.selResource = resource;
        if (ctrl.editedRow) {
            ctrl.editedRow.entity.resId = resource ? resource.resId : '';
            ctrl.editedRow.entity.resName = resource ? resource.name : '';
        }
        if (ctrl.resourceMissing) {
            ctrl.resourceMissing = false;
            angular.forEach(workOperations, function (t) {
                if (!t.resId)
                    ctrl.resourceMissing = true;
            });
        }
    };

    $scope.filteredResourceList = function (searchFilter) {
        var matching = [];
        var useInSearch = searchFilter && searchFilter !== '' && searchFilter !== ' ' ? searchFilter : '';
        for (var i = 0; i < ctrl.allResources.length; i++) {
            var resource = ctrl.allResources[i];
            if (useInSearch.length) {
                if (common.textContains(resource.resId, useInSearch) || common.textContains(resource.name, useInSearch)) {
                    matching.push(resource);
                }
            }
            else {
                matching.push(resource);
            }
            // Enough matching items were found => no need to continue
            if (matching.length === 13) {
                break;
            }
        }

        var appendMoreTag = matching.length === 13;
        common.setTypeheadColumnData(matching, getTypeheadName, [true, false], 0, [8, 20], appendMoreTag);

        if (matching.length === 1) {
            $scope.resourceSelected(matching[0]);
        }

        return matching;

        function getTypeheadName(column, resource) {
            var ret = null;
            if (column < 2) {
                ret = column === 0 ? resource.resId : resource.name;
            }
            return ret;
        }
    };

    $scope.getTooltip = function (button, entity) {
        // 'edit', 'delete', 'move-up', 'move-down'
        var ret = '';
        if (button === 'edit') {
            ret = editButtonTooltipText;
        }
        else if (button === 'delete') {
            ret = deleteButtonTooltipText;
        }
        return ret;
    };


    $scope.isVisible = function (entity, button) {
        var newlyAdded = entity.entityAspect.entityState.isAdded();
        var ret = newlyAdded;
        if (button === 'delete') {
            // only newly created tasks can be deleted or moved
            return ret;
        }
        else if (button === 'move-up' || button === 'move-down')
        {
            if (button === 'move-up' && workOperations.indexOf(entity) === 0) {
                ret = false;
            }
            if (button === 'move-down' && workOperations.indexOf(entity) === workOperations.length -1) {
                ret = false;
            }
            return ret;
        }
        else if (button === 'edit') {
            ret = entity.isCreateRepairOperAllowed;
            return ret;
        }
        return false;
    };

    $scope.buttonClicked = function (entity, button) {
        var index = 0;
        var select;
        if (button === 'move-up' || button === 'move-down') {
            ctrl.gridApi.selection.clearSelectedRows();
            index = workOperations.indexOf(entity);
            var removed = workOperations.splice(index, 1);
            index += button === 'move-up' ? -1 : 1;
            workOperations.splice(index, 0, removed[0]);
            select = entity;
        }
        else if (button === 'delete') {
            // should always be the case!
            if (entity.entityAspect.entityState.isAdded()) {
                entity.entityAspect.setDetached();
                index = workOperations.indexOf(entity);
                workOperations.splice(index, 1);
                select = workOperations[Math.max(index-1, 0)];
            }
        }
        else if (button === 'edit') {
            // Create new entity and add it to the list after the selected
            var manager = manDatacontext.getEntityManager();
            var exported = JSON.parse(manager.exportEntities([entity], false));
            var type = entity.entityType;
            var copy = exported.entityGroupMap[type.name].entities[0];
            delete copy.entityAspect;
            copy.recId *= -1;
            var newEntity = manager.createEntity(type, copy);
            newEntity.isCreateRepairOperAllowed = false;
            index = workOperations.indexOf(entity) + 1;
            newEntity.decoratedOperatId = '* ' + newEntity.operatId + ' *';

            workOperations.splice(index, 0, newEntity);
            select = newEntity;
        }
        if (select) {
            ctrl.gridApi.selection.clearSelectedRows();
            ctrl.gridApi.grid.modifyRows(workOperations);
            ctrl.gridApi.selection.selectRow(select);
        }
    };

    function isDirty() {
        var ret = false;
        angular.forEach(workOperations, function (task) {
            if (task.entityAspect.entityState.isAdded()) {
                ret = true;
            }
        });
        return ret;
    }

    $scope.$on('modal.closing', function (event, reason, closed) {
        if (reason === 'cancel' || reason === 'backdrop click' || reason === 'escape key press') {
            var onOk = function () {
                angular.forEach(workOperations, function (task) {
                    if (task.entityAspect.entityState.isAdded()) {
                        task.entityAspect.setDetached();
                    }
                });
                $uibModalInstance.close();
            };
            var onCancel = function () {};
            event.preventDefault();
            if (!isDirty()) {
                onOk();
            }
            else {
                common.openModalMessage($scope, common.$translate.instant("STRCONST.INV.GRP_WARNING"), common.$translate.instant("STRCONST.LEANPORTAL.QRY_SUBMIT_UNSAVED"), onOk, onCancel);
            }
        }
    });

    $scope.onCancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
    $scope.onApply = function (btn) {
        var saveObjects = [];
        var prevTask = null;
        var closeTheDialog = btn && btn.id === 'saveAndClose';
        var taskAddedToSameRsrc = false;
        ctrl.resourceMissing = false;
        angular.forEach(workOperations, function (t) {
            if (t.entityAspect.entityState.isAdded()) {
                t.prevOperatId = prevTask ? prevTask.operatId : 0;
                saveObjects.push(t);
                if (t.resId === task.resId) {
                    taskAddedToSameRsrc = true;
                }
                else if (!t.resId) {
                    ctrl.resourceMissing = true;
                }
            }
            prevTask = t;
        });
        if (!ctrl.resourceMissing && saveObjects) {
            manDatacontext.saveChanges(saveObjects).then(function (saveResult) {
                loadWorkOperations(false);
                if (closeTheDialog) {
                    $uibModalInstance.close();
                }
                if (typeof afterRepairTaskAdding === 'function') {
                    afterRepairTaskAdding(taskAddedToSameRsrc);
                }
            });
        }
    };

	function loadWorkOperations(select) {
        manDatacontext.getResourceTasks(null, task.workId, task.serialId !== '---' ? task.serialId : null, null, 0, 1000, true, 'SHOW_ALL', null, 'ORDER_WORKID_POS').then(function (data) {
            workOperations = [];
            if (data && data.length) {
                angular.forEach(data, function (add) {
                    add.decoratedOperatId = add.operatId;
                    workOperations.push(add);
                });
                ctrl.gridOptions.data = workOperations;
                ctrl.gridApi.grid.modifyRows(workOperations);
                if (select) {
                    ctrl.gridApi.selection.selectRow(task);
                }
            }
        });
    };

    /**
      * Initial operations.
      */
    function init() {
        initGrid();
        manService.getResources('SELECT_MAN_RESOURCES', false, false, 0).then(function (data) {
            ctrl.allResources = data;
        });
        loadWorkOperations(true);
    }

});
