
    'use strict';
    var controllerId = 'substitutes';
    var app= angular.module('common').controller(controllerId, ['$scope', 'common', 'commonDatacontext', '$uibModal', substitutes])

    function substitutes($scope, common, commonDatacontext, uiGridConstants, $uibModal) {

        var ctrl = this;
        ctrl.isFormCommandsReady = common.$q.defer();
		ctrl.dfxGridOptions = { columnDefs: [] };
		ctrl.formId = 20315;

        ctrl.dfxGridFilterChanged = function (filterString) {
            if (filterString) {
                common.debouncedThrottle('GRIDFILTER', function () {
                    ctrl.filterString = filterString;
                    getSubstitutes();
                }, ctrl.throttleAmount);
            }
        }

        ctrl.dfxGridOnDelete = function (entity) {
            entity.entityAspect.setDeleted();
            commonDatacontext.saveChanges(entity);
		}

		ctrl.dfxGridParentElementWidth = function () {
			var elem = document.getElementById("subsitutes-table-container");
			return elem ? elem.clientWidth : undefined;
		}

        ctrl.dfxGridRstChanged = function (commandId, index, selectedRestrictionCmds) {
            ctrl.selectedRestrictionCmds = selectedRestrictionCmds;
            getSubstitutes();
        }

        ctrl.dfxGridSortChanged = function (commandId) {
            ctrl.selectedSortCmd = commandId;
        }

        ctrl.dfxGridToolUsed = function (commandId, method, criteria, params) {
            if (commandId === "CREATE_NEW") {
                createRow();
            }
        }

        function createRow() {
            var entMan = commonDatacontext.getEntityManager();
            var newEnt = entMan.createEntity("SubstituteUser");
            ctrl.dfxGridOptions.createRow(newEnt);
        }

        function getSubstitutes() {
            commonDatacontext.getSubstituteUsers(null, ctrl.filterString, 20315, null, ctrl.selectedSortCmd, ctrl.selectedRestrictionCmds, null, null, "SubstituteUser", false).then(function (data) {
                if (data && data.length > 0) {
                    var parsedData = [];
                    try {
                        parsedData = JSON.parse(data).datatable;
                    }
                    catch (e) {
                        parsedData = [];
                    }
                    data = parsedData.length > 0 ? parsedData : data;
                    ctrl.dfxGridOptions.refreshData(data);
                    ctrl.selectedObject = data[0];
                    ctrl.rowTools = ctrl.selectedObject.rowTools;
                    ctrl.selectedObjects.push(data[0]);
                }
                else if (data && data.length == 0) {
                    ctrl.dfxGridOptions.refreshData([]);
                }
            });
        };
        

        activate();

        function activate() {
            commonDatacontext.getFormSettings(20315, false).then(function (data) {
                if (data) {
                    ctrl.isFormCommandsReady.promise.then(function () {
                        getSubstitutes();
                    });
                }
			})
			common.triggerResizeEvent(100);
        }

        $scope.$on('COMPANYOPUCHANGES', function () {
        	activate();
        });
    }
export default app
