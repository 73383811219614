/**
	  * WorkReport Directive & Controller
	  */
var workReportCtrlId = 'workreportctrl';
var workReportModule=angular.module('manudir.dfxWorkReport',[])
workReportModule.directive('dfxWorkReport', [function () {
	return {
		restrict: 'E',
		scope: {},
		controller: workReportCtrlId,
		controllerAs: 'ctrl',
		bindToController: {
			workReport: '=',
			layoutName: '=',
			onValidityChangedCallback: '&'
		},
		template: require('./dfxWorkReport.html')
	}
}]);
workReportModule.controller(workReportCtrlId, ['$scope', 'manDatacontext', 'common', 'usersettings', 'commonDatacontext', workreport]);
function workreport($scope, manDatacontext, common, usersettings, commonDatacontext) {
    var ctrl = this;

    ctrl.$onInit = function () {
        init();
    };
	// Filter definitions for SALARYCODE and ALLOWANCETYPE
	var EnumFilter = {
		// SALARYCODE: extraSelect fields: value13,value14,value30
		// value13 ==1: Ko palkkalaji voidaan valita töihin tulo –tapahtumalle (aikaleima-kirjaukset)
		SALARYCODE1: { index: 0, filter: '1' },
		// value14 ==1: Ko palkkalaji voidaan valita töistä lähtö –tapahtumalle (aikaleima-kirjaukset).
		SALARYCODE2: { index: 1, filter: '1' },
		// value30 =='OPER_SELECT': Palkkalaji on valittavissa aikaleiman vaiheen aloittamisessa tai lopettamisessa 
		SALARYCODE3: { index: 2, filter: 'OPER_SELECT' },
        // value37 =='RES_SELECT': 	Palkkalaji on valittavissa aikaleiman resurssikirjauksessa. 
        SALARYCODE4: { index: 3, filter: 'RES_SELECT' },
		//ALLOWANCETYPE: extraSelect fields: value01,value02
		// value01 == 'OPER' Lisäkirjaus on mahdollista tehdä aikaleimojen vaihekirjauksen yhteydessä, esim. olosuhdelisä. Kirjaus on aina vaiheleiman mittainen. 
		ALLOWANCETYPE1: { index: 0, filter: 'OPER' },
		// value02 == 'EXTRA' Lisäkirjaus on mahdollista tehdä erillisellä aikaleimojen toiminnolla, esim. hälytyslisä. 
		ALLOWANCETYPE2: { index: 1, filter: 'EXTRA' }
	}
	// deprecated, replaced with select from formId 25231
	// selected enum will define which fields are visible + enum filters for salaryCode and allowanceType selects
	// stampType: EMP_RES:  Hlö Resurssi kuittaus; EMP_OPER: Hlö Vaihe kuittaus
	var LayoutEnum = {
		START: { name: 'START', start: 0, emp: 1, work: 0, operat: 0, res: 0, stampType: 'EMP_OPER', salaryCodeFilter: EnumFilter.SALARYCODE3, allowanceTypeFilter: EnumFilter.ALLOWANCETYPE1 },
		//ABORT:		{ name: 'ABORT',	start: 0, emp: 1, shift: 0, work: 1, operat: 1, res: 0, salaryCode: 0, allowanceType: 0, stampType: 'EMP_OPER', salaryCodeFilter: EnumFilter.SALARYCODE3, allowanceTypeFilter: EnumFilter.ALLOWANCETYPE1},
		//CONTINUE:	{ name: 'CONTINUE', start: 0, emp: 1, shift: 1, work: 1, operat: 1, res: 1, salaryCode: 0, allowanceType: 1, stampType: 'EMP_OPER', salaryCodeFilter: EnumFilter.SALARYCODE3, allowanceTypeFilter: EnumFilter.ALLOWANCETYPE1},
		//FINNISH:	{ name: 'FINNISH',	start: 0, emp: 1, shift: 0, work: 1, operat: 1, res: 0, salaryCode: 1, allowanceType: 1, stampType: 'EMP_OPER', salaryCodeFilter: EnumFilter.SALARYCODE3, allowanceTypeFilter: EnumFilter.ALLOWANCETYPE1},
		LOG_RES: { name: 'LOG_RES', start: 0, emp: 1, work: 0, operat: 0, res: 1, stampType: 'EMP_RES', salaryCodeFilter: EnumFilter.SALARYCODE4, allowanceTypeFilter: EnumFilter.ALLOWANCETYPE2 },
		LOG_OPER: { name: 'LOG_OPER', shift: 1, work: 1, operat: 1, res: 0, salaryCode: 1, allowanceType: 1, stampType: 'EMP_OPER', emp: 1, salaryCodeFilter: EnumFilter.SALARYCODE3, allowanceTypeFilter: EnumFilter.ALLOWANCETYPE1 },
		LOG_OPER_OUT: { name: 'LOG_OPER_OUT', shift: 1, work: 1, operat: 1, res: 0, salaryCode: 1, allowanceType: 1, stampType: 'EMP_OPER', emp: 1, salaryCodeFilter: EnumFilter.SALARYCODE3, allowanceTypeFilter: EnumFilter.ALLOWANCETYPE1 },
		DEFAULT: { name: 'DEFAULT', start: 0, emp: 1, work: 1, operat: 1, res: 0, stampType: 'EMP_OPER', salaryCodeFilter: EnumFilter.SALARYCODE3, allowanceTypeFilter: EnumFilter.ALLOWANCETYPE1 }
	}
	ctrl.columns;
	ctrl.startColumns;
	var formsettingPromises = []
	formsettingPromises.push(commonDatacontext.getFormSettings(25231, false, 'SELECT_RESTASK_START').then(function (data) {
		ctrl.startColumns= data;
	}));
	ctrl.suspendColumns;
	formsettingPromises.push(commonDatacontext.getFormSettings(25231, false, 'SELECT_RESTASK_SUSPEND').then(function (data) {
		ctrl.suspendColumns = data;
	}));
	ctrl.resColumns;
	formsettingPromises.push(commonDatacontext.getFormSettings(25231, false, 'SELECT_LOG_RES').then(function (data) {
		ctrl.resColumns = data;
	}));
	ctrl.finishColumns;
	formsettingPromises.push(commonDatacontext.getFormSettings(25231, false, 'SELECT_RESTASK_FINISH').then(function (data) {
		ctrl.finishColumns = data;
	}));
	ctrl.defaultColumns;
	formsettingPromises.push(commonDatacontext.getFormSettings(25231, false, 'SELECT_RESTASK_DEFAULT').then(function (data) {
		ctrl.defaultColumns = data;
	}));

	// newSerialNumber is either string or serial number object
	ctrl.actualStart = new Date();
	ctrl.actualStartTime = null;
	ctrl.allResources = [];
	ctrl.enums = [];
	ctrl.lo = LayoutEnum.DEFAULT;
	ctrl.validEmployee = false;

	/**
	 * Adjusts given date with given time.
	 * @param {Date to be adjusted.} dt 
	 * @param {Time to set to date.} tm 
	 * @returns {} 
	 */
	ctrl.changeTime = function (dt, tm) {
		if (dt && tm) {
			dt.setHours(tm.getHours(), tm.getMinutes());
		}
	}

	/**
	 * Employee selection changed
	 * @param value 
	 */
	ctrl.empSelected = function (value) {
		ctrl.workReport.empId = value ? value.empId : null;
		ctrl.workReport.empName = value ? value.name : null;
		verifyEmployeeValidity();
	}

	/**
	 * Filter for filtering enumerations with value field values.
	 * @param {Index for value field in extra select list.} valueFldIndex 
	 * @param {Value field value used in comparison.} valueFldValue 
	 * @returns {}  
	 */
	ctrl.filterEnumsByValueFld = function (enumFilter) {
		return common.filterEnumsByValueFld(enumFilter.index, enumFilter.filter);
	};

	function getEnumItemName(enumName, itemId) {
		var ret = '';
		ctrl.enums[enumName].forEach(function (item) {
			if (item.id === itemId) {
				ret = item.nabbr;
			}
		});
		return ret;
	}

	ctrl.selectAllowanceType = function (itemId) {
		ctrl.workReport.allowanceType = itemId;
		ctrl.workReport.allowanceTypeName = getEnumItemName('ALLOWANCETYPE', itemId);
	}

	ctrl.selectResource = function (item) {
		ctrl.workReport.resId = item.resId;
		ctrl.workReport.resName = item.name;
	}

	ctrl.selectSalaryCode = function (itemId) {
		ctrl.workReport.salaryCode = itemId;
		ctrl.workReport.salaryCodeName = getEnumItemName('SALARYCODE', itemId);
	}
	ctrl.selectShift = function (itemId) {
		ctrl.workReport.shift = itemId;
		ctrl.workReport.shiftName = getEnumItemName('SHIFTS', itemId);
	}

	/**
	 * Sets default values for dropdowns.
	 * @returns {} 
	 */
	function setDefaultValues() {
		if (ctrl.layout && ctrl.layout.shift) {
			var shiftDefault = common.getEnumDefValue(ctrl.enums['SHIFTS'], true);
			if (shiftDefault) {
				ctrl.selectShift(shiftDefault);
			}
		}
		if (ctrl.layout && ctrl.layout.salaryCode) {
			var salaryCodeDefault = common.getEnumDefValue(ctrl.enums['SALARYCODE'], false);
			var filteredSalaryCodes = ctrl.enums['SALARYCODE'].slice().filter(ctrl.filterEnumsByValueFld(ctrl.layout.salaryCodeFilter));
			// If field is visible and default value is a member of filtered list.
			if (salaryCodeDefault && common.getEnumRowById(filteredSalaryCodes, salaryCodeDefault)) {
				ctrl.selectSalaryCode(salaryCodeDefault);
			}
		}
		if (ctrl.layout && ctrl.layout.allowanceType) {
			var allowanceTypeDefault = common.getEnumDefValue(ctrl.enums['ALLOWANCETYPE'], false);
			var filteredAllowanceTypes = ctrl.enums['ALLOWANCETYPE'].slice().filter(ctrl.filterEnumsByValueFld(ctrl.layout.allowanceTypeFilter));
			// If field is visible and default value is a member of filtered list.
			if (allowanceTypeDefault && common.getEnumRowById(filteredAllowanceTypes, allowanceTypeDefault)) {
				ctrl.selectAllowanceType(allowanceTypeDefault);
			}
		}
	}

	function setEmpLoggedIn(emp, stampExists) {
		ctrl.empLoggedIn = stampExists;
		var isValid = true;
		//Check for enabling/disabling Apply button and assigning an error message if needed. 
		if (!emp) {
			ctrl.empValidityMessage = common.$translate.instant("STRCONST.TIM.ERR_EMP_REQUIRED");
			isValid = false;
		}
		else if (!stampExists && !ctrl.allowOperWithoutUserLogin) { // With certain options it is OK that user has not logged in
			ctrl.empValidityMessage = common.$translate.instant("STRCONST.TIM.ERR_EMP_NOT_LOGGED_IN");	
			isValid = false;
		}
		else {
			ctrl.empValidityMessage = null;
		}
		ctrl.onValidityChangedCallback({ isValid: isValid });
	}

	function verifyEmployeeValidity() {

		if (ctrl.workReport.empId) {
			// We'll need to fetch some defaults from users last login
			manDatacontext.getWorkReportDefaults(ctrl.workReport.empId).then(function (data) {
				setEmpLoggedIn(ctrl.workReport.empId, data && data.length == 1);
				if (ctrl.empLoggedIn) {
					setWrDefaultValues(ctrl.workReport, data[0])
					if (ctrl.layoutName == 'LOG_OPER_OUT') {
						commonDatacontext.getWorkReport(ctrl.workReport.empId, null, 75, 'SELECT_EMP_OPERAT', null, null, null, ctrl.workReport.resId, ctrl.workReport.workId, ctrl.workReport.operatId).then(function (existingWr) {
							if (existingWr && existingWr.length > 0 && existingWr[0].empId == ctrl.workReport.empId) {
								ctrl.workReport.recId = existingWr[0].recId;
								setWrDefaultValues(ctrl.workReport, existingWr[0])
								ctrl.workReport.entityAspect.setEntityState(breeze.EntityState.Unchanged);
								ctrl.workReport.entityAspect.setEntityState(breeze.EntityState.Deleted);
								ctrl.onValidityChangedCallback({ isValid: true });
							}
							else {
								ctrl.onValidityChangedCallback({ isValid: false });
							}
						}, function () {
							ctrl.onValidityChangedCallback({ isValid: false });
						})
					}

				}
			});
		}
		else {
			setEmpLoggedIn(null, false);
		}
	}

	function setWrDefaultValues(target, source) {
		if (source.shift) {
			target.shift = source.shift;
		}
		if (source.salaryCode) {
			target.salaryCode = source.salaryCode;
		}
		if (source.specCode) {
			target.specCode = source.specCode;
		}
		if (source.workCode) {
			target.workCode = source.workCode;
		}
	}

	async function init() {
		await common.$q.all(formsettingPromises)		
		// Verify that layout has some value
		ctrl.layout = ctrl.layoutName && ctrl.layoutName in LayoutEnum ? LayoutEnum[ctrl.layoutName] : LayoutEnum.DEFAULT;
		if (ctrl.layoutName == "START") {
			ctrl.columns = ctrl.startColumns;
		}
		else if (ctrl.layoutName == "ABORT") {
			ctrl.columns = ctrl.suspendColumns;
		}
		else if (ctrl.layoutName == "LOG_RES") {
			ctrl.columns = ctrl.resColumns;
		}
		else if (ctrl.layoutName == "FINISH") {
			ctrl.columns = ctrl.finishColumns;
		}
		else if (ctrl.layoutName == "DEFAULT") {
			ctrl.columns = ctrl.defaultColumns;
		}
		ctrl.actualStart = (ctrl.workReport.startDate ? ctrl.workReport.startDate : new Date());
		ctrl.actualStartTime = ctrl.actualStart;
		ctrl.workReport.stampType = ctrl.layout.stampType;
		usersettings.getLoginPromise().then(function () {
			manDatacontext.getResources(0, 1000).then(function (data) {
				ctrl.allResources = data;
				// Initialize the resouce combo selection
				data.forEach(function (res) {
					if (res.resId === ctrl.workReport.resId) {
						ctrl.selResource = res;
						ctrl.workReport.resName = res.name;
					}
				});

			});
			manDatacontext.getLookups(ctrl.lang, false).then(function (data) {
				if (data && data[0] && data[0].Enums) {
					ctrl.enums = data[0].Enums;
					setDefaultValues();
					// Apply button is available, even when the use employee is not logged in
					if (data && data[0] && data[0].Enums && data[0].Enums["MANUFPORTAL_DEFAULTS"]) {
						var defaults = data[0].Enums["MANUFPORTAL_DEFAULTS"];
						var enumItem_TIM = defaults ? common.getEnumRowById(defaults, 'TIM') : null;
						if (enumItem_TIM && enumItem_TIM.columns) {
							ctrl.allowOperWithoutUserLogin = (enumItem_TIM.columns['VALUE02'] == 'NOT_MANDATORY')
							ctrl.useTagId = (enumItem_TIM.columns['VALUE04'] == 'TAGID');
						}
						var enumItem_TASKLIST = common.getEnumRowById(data[0].Enums["MANUFPORTAL_DEFAULTS"], 'RESOURCETASKLIST');
						if (enumItem_TASKLIST && enumItem_TASKLIST.extraSelect && enumItem_TASKLIST.extraSelect[3] && enumItem_TASKLIST.extraSelect[3] == "EMP_QUERY_ALL_DEP") {
							ctrl.useUserDepRst = false;
						}
						else {
							ctrl.useUserDepRst = true;
						}
					}
				}
				return data;
			});
			verifyEmployeeValidity();
		});
	}
}
export default workReportModule