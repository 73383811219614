var elementTools=angular.module('manudir.dfxWorkElementTools',[])
elementTools.directive('dfxWorkElementTools', ['common', 'workelements_service', function (common, workelements_service) {
	return {
		restrict: 'E',
		scope: {},
		controllerAs: 'ctrl',
		bindToController: {
			workElement: '=',
			editDisabled: '='
		},
		controller: ['$scope', function ($scope) {
			var ctrl = this;
			ctrl.itemsInTypeAhead = 50;
			ctrl.formId = 25136;

			/*Typeahead ColEnums*/
			ctrl.TypeheadColEnum = {
				DEVICES: { callback: getDevices, emphasize: [false], margin: 0, maxWidth: [5] }
			}

			/**
			 * Provides text shown in Input control
			 */
			ctrl.formatDeviceData = function (deviceId) {
				var device = getDevice(deviceId);
				return device ?  device.deviceId + ' ' + device.serialId : '';
			}

			/**
			 * Return Device for given ID
			 */
			function getDevice(deviceId) {
				var ret = null;
				angular.forEach(workelements_service.getState().workElementDeviceDataList, function (d) {
					if (d.deviceId === deviceId) {
						ret = d;
					}
				});
				return ret;
			}

			/**
			 * Updates WorkElementTool according to tool selection
			 */
			ctrl.setDeviceValues = function (wt, deviceId) {
				var device = getDevice(deviceId);
				if (wt && device) {
					wt.deviceId = device.deviceId;
					wt.serialId = device.serialId;
				}
			}

			function getDevices(column, device) {
				var ret = null;
				if (column, device) {
					var ret = null;
					if (column < 2) {
						if (column == 0) {
							ret = device.deviceId;
						}
						if (column == 1) {
							ret = device.serialId;
						}
						if (ret === null || typeof ret === 'undefined') {
							ret = '';
						}
					}
					return ret;
				}
			}

			ctrl.checkChanges = function (wt, e) {
				var elem = e.target;				
				common.elementValueHasRealChanges(wt, ['deviceId', 'serialId'], elem, function () { workelements_service.resolveHasChanges() });					
			}

			ctrl.getTools = function () {
				return workelements_service.getWorkElementData('TOOL', ctrl.workElement);
			}
			ctrl.filteredList = function (itemId, searchString, typeheadColEnum, objName, rowObj) {
				var matchingRows = [];
				if (itemId) {
					matchingRows = workelements_service.getState().workElementDeviceDataList;
					var appendMoreTag = false;

					if (typeheadColEnum) {
						var emphasize = typeheadColEnum.emphasize;
						var maxWidth = typeheadColEnum.maxWidth;

						if (searchString && searchString.length > 0 && searchString != '' && searchString != ' ') {
							matchingRows = matchingRows.filter(function (e) {
								/*Test against deviceId*/
								var match = common.textContains(e.deviceId, searchString);
								/*Test against serialId if deviceId didn't match*/
								return match ? match : common.textContains(e.serialId, searchString);
							});
						}
						if (matchingRows.length > ctrl.itemsInTypeAhead) {
							matchingRows = matchingRows.slice(0, ctrl.itemsInTypeAhead);
							appendMoreTag = true;
						}
						common.setTypeheadColumnData(matchingRows, typeheadColEnum.callback, emphasize,
							typeheadColEnum.margin, maxWidth, appendMoreTag);
						}
					}
				
				return matchingRows;
			}
		}],
		template: require('./dfxWorkElementTools.html')
	}
}]);
export default elementTools