'use strict';
var favouriteSearchModule = angular.module('dir.dfxFavouriteAndSearchFilter',[]);
/**
	 * Possibility to filter with 
	 */
favouriteSearchModule.directive('dfxFavouriteAndSearchFilter', function () {
    // Provides Favourites selection to model object.
    // Usage:
    // <dfx-favourite-and-search-filter model=""></dfx-favourite-and-search-filter>
    return {
        restrict: 'E', //E = element, A = attribute, C = class, M = comment 
        scope: {},
        controllerAs: 'ctrl',
        bindToController: {
            modelList: '=*',
			useFavouriteFilter: '=',
			localStorageKey: '=?',	// If defined, the selection change is saved to the local storage
            onFilterElementCallback: '&',
            onFilteringDoneCallback: '&',
            onControllerCallback: '&'
        },
		controller: ['$scope', 'common', 'usersettings', function ($scope, common, usersettings) {
            var ctrl = this;

            // API offered for the client with onControllerCallback
            var service = {
                favStatusChanged: favStatusChanged
            };

            ctrl.$onInit = function () {
                ctrl.favouriteCount = 0;
                ctrl.rstBtn = ctrl.useFavouriteFilter ? 'Fav' : 'All';
                ctrl.onControllerCallback({ childCtrl: service });
            };
            

            $scope.$watchCollection('ctrl.modelList', function (modelList) {
                init();
                if (!ctrl.modelList) {
                    ctrl.modelList = [];
                }
                applyFilter();
            });
            $scope.$watch('ctrl.useFavouriteFilter', function (value) {
                ctrl.rstBtn = value ? 'Fav' : 'All';
                applyFilter();
			});
			// Communicate selection outside
			$scope.$watch('ctrl.rstBtn', function (value) {
				ctrl.useFavouriteFilter = value === 'Fav';
			});
            $scope.$watch('ctrl.searchText', function (value) {
                applyFilter();
            });


            function applyFilter() {
                ctrl.onFilteringDoneCallback({ filteredObjects: ctrl.modelList.filter(searchFilter) });
            }

            function favStatusChanged(model) {
                ctrl.favouriteCount += (model.f ? 1 : -1);
            }

            function init() {
                ctrl.favouriteCount = 0;
                if (ctrl.modelList) {
                    ctrl.modelList.forEach(function (model) {
                        if (model.f) {
                            ctrl.favouriteCount++;
                        }
                    });
                }
            }
            ctrl.search = function ($event, rstBtnVal, forceRefresh) {
                if (rstBtnVal) {
                    ctrl.rstBtn = rstBtnVal;
                    ctrl.useFavouriteFilter = (rstBtnVal === 'Fav');
					if (ctrl.localStorageKey) {
						usersettings.setPersonalSettingValue(ctrl.localStorageKey, ctrl.useFavouriteFilter);
					}
                }
            }

            /**
             * Check if the favourite / filtering string matches
             */
            function searchFilter(item) {
                var isMatch = ctrl.searchText ? ctrl.onFilterElementCallback({ item: item, searchText: ctrl.searchText }) : true;
                var isFav = ctrl.rstBtn == 'Fav' ? item.f : true;
                return isMatch && isFav;
            }

        }],
        template: require('./dfxFavouriteAndSearchFilter.html')
    };
});
export default favouriteSearchModule