'use strict';
import { react2angular } from 'react2angular';
import { Spinner } from '@roima/rex';

    var controllerId = 'shell';
    var shellModule= angular.module('common').controller(controllerId,
        ['$rootScope', '$scope', 'common', 'config', 'usersettings', 'spinner', shell]);
	
	var maintenanceBreakMode;
	var maintenanceBreakStart;
	var maintenanceBreakDuration;
	angular.module('app').config(["getAppSettingsProvider", function (getAppSettingsProvider) {
		maintenanceBreakMode = getAppSettingsProvider.getAppSetting("MaintenanceBreakMode", "0");
		maintenanceBreakStart = getAppSettingsProvider.getAppSetting("MaintenanceBreakStart", "");
		maintenanceBreakDuration = getAppSettingsProvider.getAppSetting("MaintenanceBreakDuration", "0");
			
			
	}]);
    function shell($rootScope, $scope, common, config, usersettings, spinner) {
    	var vm = this;
    	//var log = common.logger.getLogFn(controllerId);
        var warnMsg = common.logger.getLogFn(controllerId, 'warning');
        var events = config.events;
        vm.hideTopBanner = false;
		var forceTopBannerHide = false;
		var routeChangeSpinnerVisible = 0;
		vm.topBannerVisibleDefault = common.getAppSetting("TopBannerVisibleDefault", 1) == 1 ? "true" : "false";
		

        vm.isBusy = false;
        vm.spinnerOptions = {
            radius: 16, // Inner circle radius
            lines: 8, // Number of bullets
            length: 0, // Bullet length
            width: 10, // Bullet width (size)
            speed: 1.2, // Rounds per second
            corners: 1.0,
            trail: 100,
            color: '#2196F3'
		};

		$scope.$on('MAXIMIZE_WORK_SPACE', function (event, args) {
			if (!forceTopBannerHide) {
				vm.hideTopBanner = args.maximized;
			}
		});


		function checkTopBannerVisibility(portalId) {
			vm.hideTopBanner = !vm.topBannerVisibleDefault;
			usersettings.checkTopBannerVisibility(portalId).then(function (data) {
				if (!forceTopBannerHide) {
					vm.hideTopBanner = !data;
				}
			});
		} 

        $rootScope.$on('$routeChangeStart',
            function (event, next, current) {
				routeChangeSpinnerVisible += (spinner.spinnerShow('routeChange') ? 1 : 0);
            	var path = common.$location.path();
				if (next && current) {
					var nextUrl = getLocation(next.originalPath);
					nextUrl.pathParams = next.pathParams
					var curUrl = getLocation(current.originalPath);
					curUrl.pathParams = current.pathParams
					if (common.getBeforeUnloadValue()) {
						hideRouteChangeSpinner();
						common.openModalMessage($scope, common.$translate.instant("STRCONST.INV.GRP_WARNING"), common.$translate.instant("STRCONST.LEANPORTAL.QRY_SUBMIT_UNSAVED"),
							//onOk
							function () {
								common.$broadcast('REJECTCHANGES', { param: true });
								if ((curUrl.pathname != nextUrl.pathname) || !angular.equals(curUrl.pathParams, nextUrl.pathParams) ) {
									common.$location.path(path);
								}
							},
							//onCancel
							function () { }
						);
						event.preventDefault();
					}
				}
            }
        );
    	//Helper function to get location compatible with IE
        function getLocation(href) {
        	var location = document.createElement("a");
        	location.href = href;
        	// IE doesn't populate all link properties when setting .href with a relative URL,
        	// however .href will return an absolute URL which then can be used on itself
        	// to populate these additional fields.
        	if (location.host == "") {
        		location.href = location.href;
        	}
        	return location;
        };

		function hideRouteChangeSpinner() {
			if (routeChangeSpinnerVisible > 0) {
				spinner.spinnerHide('routeChange');
				routeChangeSpinnerVisible--;
			}
		}

        $rootScope.$on('$routeChangeSuccess',
            function (event, current, previous) {
				if (current.portalId) {
					checkTopBannerVisibility(current.portalId);
            	}
            	hideRouteChangeSpinner();
            }
        );

        $rootScope.$on('$routeChangeError',
			function (event, next, current) {
				if (current && current.portalId) {
					checkTopBannerVisibility(current.portalId);
				}
				hideRouteChangeSpinner();
            }
		);

		$rootScope.$on('$routeUpdate',
			function (current, routes) {
				if (current.portalId) {
					checkTopBannerVisibility(current.portalId);
				}
				hideRouteChangeSpinner();
			}
		);

        $rootScope.$on(events.controllerActivateStarted,
            function (data) {
            	spinner.spinnerShow('controllerActivate');
            }
        );

        $rootScope.$on(events.controllerActivateSuccess,
            function (data) {
            	spinner.spinnerHide('controllerActivate');
            }
        );

        $rootScope.$on(events.spinnerToggle,
            function (event, data) {
            	vm.isBusy = data.show;
            }
        );

        $scope.$on('FORCETOPBANNERHIDE', function (event, args) {
        	forceTopBannerHide = true;
        	vm.hideTopBanner = true;
        });


        function activate() {
        	//log('Perusrunko ladattu.', null, true);
			common.activateController([], controllerId).then(function () {
				if (common.$route.current && common.$route.current.portalId) {
					checkTopBannerVisibility(common.$route.current.portalId);
				}
            });
            /* Check if there is a maintenance scheduled. */
            if (maintenanceBreakMode == 1) {
                var time = moment.utc(maintenanceBreakStart, 'YYYYMMDDHHmmss');
                if (time.diff(moment().utc()) > 0) {
                    var timeFormat = common.longDateFormat(false) + " " + common.longTimeFormat();
                    var endTime = moment.utc(time).add(maintenanceBreakDuration, 'm');
                    common.$translate("STRCONST.LEANPORTAL.HDR_MAINTENANCE_BREAK").then(function (translation) {
                        var message = translation + " " + time.local().format(timeFormat) + ' - ' + endTime.local().format(timeFormat);
                        warnMsg(message);
                        common.logger.getLogList().push(message);
                    });

                    var maintenanceTimeout = common.$timeout(function () {
                        var activeMsg = common.$translate.instant("STRCONST.PUBLIC.TXT_MAINTENANCE_ACTIVE");
                        warnMsg(activeMsg);
                        common.logger.getLogList().push(activeMsg);
                        common.$timeout.cancel(maintenanceTimeout);
                    }, time.diff(moment().utc()));
                }
            }
        }

        activate();
    };
    shellModule.controller('ShowErrorsCtrl', function ($scope, common) {
        $scope.logList = [];
        $scope.isCollapsed = true;
        $scope.$watch(function () { return common.logger.getLogList().length; }, function () {
            $scope.logList = common.logger.getLogList();
        });
        $scope.clearList = function () {
            common.logger.clearLogList();
        }
	});

shellModule.component('spinner', react2angular(Spinner, [], []))

export default shellModule