'use strict';
var formCommandsModule = angular.module('dir.dfxFormCommands',[]);
formCommandsModule.directive('dfxFormCommands', function () {
    return {
        restrict: 'E',
        scope: {},
        controllerAs: 'ctrl',
		replace: true,
		transclude: true,
        bindToController: {
            formId: '=',
            selectedRestrictionCmds: '=?',
            selectedSortCmd: '=?',
            hideSortCmd: '=?', // Set to true if no sort commands wanted.
            hideToolCmd: '=?', // Set to true if no tool commands wanted.
            filterToolCmd: '=?', // currently supported: SINGLEROWSELECTED, NON_SINGLEROWSELECTED 
            hideRestrictionCmd: '=?', // Set to true if no tool commands wanted.
            selectedRows: '=?',
            onRstCallback: '&',
			onSortCallback: '&',
			onBeforeToolCallback: '&?',
            onToolCallback: '&',
            onExportCallback: '&?',
            isReady: '=',
			modelName: '=',
			displayMode: '=?',
			menuTitle: '=?',
			extSearch: '=?', // Show search bar.
            extSearchCallback: '&?',
            extSearchToggle: '&?', // Toggle event callback. Used with dfxGrid to show/hide column filters.
            useGrouping: '=?', // Set to true if grouping enabled. Used with dfx-grid.
            onGroupCallback: '&?',
            selectedGrouping: '=?',
			useDropdowns: '=?', // Change layout to use dropdowns instead of buttons.
			cmdDropUp: '=?'		// Command menu is opened upward

        },
		controller: ['$scope', '$timeout', 'common', 'commonDatacontext', 'usersettings', function ($scope, $timeout, common, commonDatacontext, usersettings) {
            // Variables and properties.
            var ctrl = this;
            ctrl.sortCommands = [];
            ctrl.restrictionCommands = [];
            ctrl.filteredToolCommands = [];
            ctrl.toolCommands = [];
            ctrl.groupColumns = [];
            ctrl.selectedSubCmd = "SHOW_ALL_OPEN";
            ctrl.selectedSubCmdTitle = common.$translate.instant("STRCONST.PUBLIC.TXT_ALL");
            ctrl.selectedRestrictionTitles = [];
            ctrl.showExport = false;
            ctrl.searchString = '';
            ctrl.extSearchDisabled = false;

			function collectParamValues(params) {
				var promise;
				if (ctrl.modelName && params && params.length > 0 && params[0]) {
					promise = commonDatacontext.getModelColumnMap(ctrl.modelName).then(function (columnMap) {
						var param1 = params[0].split(";");
						var param3 = params[2].split(";");
						for (var i = 0; i < param1.length; i++) {
							param3[i] = getValueForTool(param3[i], columnMap);
						}
						params[2] = param3.join(';');
						return params;
					});
				}
				else {
					promise = params;
				}
				return common.$q.when(promise);
			}

			function enableOrDisableCommands(rows) {
				angular.forEach(ctrl.toolCommands, function (toolCmd) {
					var selRows = 0;
					var permission = null;
					if (rows) {
						if (Array.isArray(rows)) {
							selRows = rows.length;
						}
						else {
							selRows = 1;
						}
					}

					// Row is not selected but the tool requires row selection then make tool disabled.
					// Or multiple rows selected but the tool requires one row only, then make tool disabled.
					if ((selRows === 0 && (toolCmd.criteria == "ROWSELECTED" || toolCmd.criteria == "SINGLEROWSELECTED")) ||
						(selRows > 1 && toolCmd.criteria == "SINGLEROWSELECTED")) {
						permission = 0;
					}
					else {
						// One row selected, determine form it which tools are active.
						if (selRows === 1) {
							permission = rows[0] !== undefined && rows[0].rowTools ? rows[0].rowTools : 65535;
						}
						// Multiple selected rows. All selected rows determine if command is active or not.
						else if (selRows > 1) {
							permission = 65535;
							if (toolCmd.criteria === "ROWSELECTED") {
								angular.forEach(rows, (row) => {
									var curRowPermission = row.rowTools;
									if (!curRowPermission) {
										curRowPermission = 65535;
									}
									permission = permission & curRowPermission;
								});
							}
						}
						// No selected rows and no criteria on command -> make command active
						else if (selRows === 0 && (!toolCmd.criteria || toolCmd.criteria === '')) {
							permission = 65535;
						}
					}

					toolCmd.isDisabled = !common.hasPermission(toolCmd.permissionType, permission);
				});
			}

			function getValueForTool(param3, columnMap) {
				if (param3.charAt(0) == '$') {
					if (!ctrl.selectedRows || !columnMap) return null;
					var propertyName = columnMap[param3.split('$').join('')];
					return Array.isArray(ctrl.selectedRows) ? ctrl.selectedRows[0][propertyName] : ctrl.selectedRows[propertyName];
				}
				return param3;
			}

            ctrl.$onInit = function () {
				usersettings.getLoginPromise().then(function () {
					activate();
				});
            };

            ctrl.toggleExtSearch = function () {
                ctrl.extSearchDisabled = !ctrl.extSearchDisabled;
                ctrl.searchString = null;
                ctrl.extSearchToggle();
            }

            ctrl.searchChanged = function (searchString) {
                ctrl.extSearchCallback({ searchString: searchString });
            }

            ctrl.removeSearch = function () {
                ctrl.searchString = null;
                ctrl.extSearchCallback({ searchString: null });
            }

            // Event handler for restiction button click.
            ctrl.rstChanged = function (commandId, index, cmdTitle, parentId) {
                ctrl.selectedSubCmdTitle = common.$translate.instant("STRCONST.PUBLIC.TXT_ALL");
                if (parentId) {
                    ctrl.selectedSubCmdTitle = cmdTitle;
                    ctrl.selectedSubCmd = commandId;
                }
                if (ctrl.activeRestrictionCmds) {
                    var arr = ctrl.activeRestrictionCmds.split(',');
                    arr[index] = commandId;
                    ctrl.selectedRestrictionCmds = arr.join(',');
                    ctrl.selectedRestrictionTitles[index] = cmdTitle;
                }

                ctrl.activeRestrictionCmds = ctrl.selectedRestrictionCmds ? ctrl.selectedRestrictionCmds : ctrl.activeRestrictionCmds;
                var test1 = ctrl.activeRestrictionCmds.split(',').indexOf(commandId) >= 0;
                if (parentId) {
                    var test2 = ctrl.activeRestrictionCmds.split(',').indexOf(parentId) >= 0;
                }

                ctrl.onRstCallback({ commandId: commandId, index: index, selectedRestrictionCmds: ctrl.selectedRestrictionCmds });
            }

            // Event handler for sort button click.
            ctrl.sortChanged = function (commandId, title) {
                ctrl.selectedSortCmd = commandId;
                if (title) {
                    ctrl.selectedSortCmdTitle = title;
                }
                ctrl.onSortCallback({ commandId: commandId });
            }

            // Event handler for group button click.
            ctrl.groupingChanged = function (col) {
                if (ctrl.selectedGrouping != col) {
                    ctrl.onGroupCallback({ column: col });
                }
            }

            ctrl.setActive = function (command, parentCmd) {
                var isActive = false;
                if (ctrl.activeRestrictionCmds && command) {
                    //check if command itself is in the restriction list
                    if (!parentCmd) isActive = ctrl.activeRestrictionCmds.split(',').indexOf(command.commandId) >= 0;
                    //if parent is given, then the command itself is passed as id. 
                    if (!isActive && parentCmd) {
                        isActive = ctrl.activeRestrictionCmds.split(',').indexOf(command) >= 0;
                        if (!isActive && parentCmd) {
                            //check if a parent command is in the restriction list
                            isActive = ctrl.activeRestrictionCmds.split(',').indexOf(parentCmd.commandId) >= 0;
                        }
                    }
                    //check if any of subcommands are in the restriction list					
                    if (!isActive && !parentCmd && command.subCommands) {
                        for (var cm = 0; cm < command.subCommands.length; cm++) {
                            isActive = ctrl.activeRestrictionCmds.split(',').indexOf(command.subCommands[cm].commandId) >= 0;
                            if (isActive) break; //break the loop, if subCommand is found in the list.								
                        }
                    }


                }
                return isActive ? 'm-selector__label--active' : '';
            };


            // Event handler for tool button click.
			ctrl.toolUsed = function (commandId, method, criteria, params, cmd) {
				var parameters = params.slice();
                if (cmd.type == "MENU_TOOL" && cmd.commandGroup == "TOOLPANEL") {
					ctrl.toolPanelUsed(commandId, method, criteria, parameters, cmd)
                }
				else if (cmd.type == "MENU_TOOL" && cmd.commandGroup == "TOOL") {
					if (ctrl.onBeforeToolCallback && !ctrl.onBeforeToolCallback({ commandId: commandId, method: method, criteria: criteria, params: parameters, formId: ctrl.formId, cmd: cmd })) {
						return;
					}
					collectParamValues(parameters).then(function (changedParams) {
						ctrl.onToolCallback({ commandId: commandId, method: method, criteria: criteria, params: changedParams, formId: ctrl.formId, cmd: cmd });
					});
				}
            }

            ctrl.toolPanelUsed = function (commandId, method, criteria, params, cmd) {
                getEnums().then(function (enumItems) {
                    commonDatacontext.getModelColumnMap(ctrl.modelName).then(function (columnMap) {
                        var param1 = params[0].split(";");
                        var param2 = params[1].split(";");
                        var param3 = params[2].split(";");
                        var toolPanelObject = {}
                        var toolParams = [];
                        for (var i = 0; i < param1.length; i++) {
                            var type = "eNormal";
							var enums = [];
							var val = getValueForTool(param3[i], columnMap);
                            if (param2[i] == "normalfield" || param2[i] == "combobox") {
                                var column = ctrl.formSettings.columnSettings[param1[i]];

								if (column && column.isEnumItemId) {
									if (column.typeParam1) {
										enums = enumItems[column.domain].filter(function (enumItem) { return enumItem.isValid == 1 });
									}
									else {
										enums = enumItems[column.domain];
									}
									if (!val) {
										var defValue = enums.filter(function (enumItem) { return enumItem.isDefault });
										if (defValue[0]) {
											val = defValue[0].id;
										}
									}
                                    
                                    type = 'eDropdown';
                                }
								if (column && column.lookupKey) {
                                    type = 'eTypeahead';
                                    var typeAheadData = [];
                                    ctrl.getTypeAheadData = function (searchFilter) { //TODO: Filter by param3 mapping.
                                        if (searchFilter && searchFilter != '' && searchFilter != ' ') {
                                            var cmp = searchFilter.toLowerCase();
                                            var matching = [];
                                            for (var i = 0; i < typeAheadData.length ; i++) {
                                                var e = typeAheadData[i];
                                                if (e.NAME.toLowerCase().indexOf(cmp) > -1 ||
                                                    e.NAME.toLowerCase().indexOf(cmp) > -1) {
                                                    matching.push(e);
                                                }
                                            }
                                            return matching;
                                        }
                                        // return full list
                                        return typeAheadData;
                                    }
                                    var lookupName = column.lookupName.split(';');
                                    var lookupNameSubArray = [];
                                    var lookupParams = [];
                                    for (var j = 0; j < lookupName.length; j++) {
                                        lookupNameSubArray[j] = lookupName[j].split('|');
                                    }
                                    for (var k = 0; k < lookupNameSubArray.length; k++) {
                                        var lookupParam1 = lookupNameSubArray[k][1];
                                        if (lookupParam1) {
                                            var search = lookupParam1.search(/\d/);
                                            if (search != -1) {
                                                var key = "PARAM" + lookupParam1[search];
                                                var paramValueName = lookupNameSubArray[k][0];
                                                var propertyName = columnMap[paramValueName];
                                                var paramValue = Array.isArray(ctrl.selectedRows) ? ctrl.selectedRows[0][propertyName] : ctrl.selectedRows[propertyName];
                                                var paramObj = {};
                                                paramObj[key] = paramValue;
                                                lookupParams.push(paramObj);
                                            }
                                        }
                                    }
                                    commonDatacontext.getGenericDataSet(column.lookupRst, null, column.selectFormId, column.selectFormVersion, null, null, null, JSON.stringify(lookupParams), ctrl.formSettings.formName).then(function (data) {
										typeAheadData = data && data[0] && data[0].datatable ? data[0].datatable : []; //TODO: Map returning data to column param3.

                                    });

                                }
								if (column && column.isTableAttrId) {

                                }

								toolParams.push({ normalfield: true, name: column && column.headerText ? column.headerText : param1[i], enums: enums, type: type, value: val, index: i, typeAheadData: typeAheadData });
                            }
							else if (param2[i] == "readonlyfield" || param2[i] == "readonly") {
								toolParams.push({ normalfield: false, name: param1[i], value: val, type: 'eReadonlyfield', index: i });
							}
							else if (param2[i] == "hidden") {
								toolParams.push({ normalfield: false, name: param1[i], value: val, type: 'eHidden', index: i });
							}
                        }
                        toolPanelObject.toolParams = toolParams;
						toolPanelObject.commandId = commandId;
						toolPanelObject.modalTitle = cmd.title;
                        toolPanelObject.method = method;
                        toolPanelObject.criteria = criteria;
                        $scope.toolPanelObject = toolPanelObject;
                        $scope.params = params;
						$scope.param3 = param3;
						if (ctrl.onBeforeToolCallback && !ctrl.onBeforeToolCallback({ commandId: commandId, method: method, criteria: criteria, params: params, formId: ctrl.formId, cmd: cmd, toolPanelObject: toolPanelObject })) {
							return;
						}
                        common.openModal($scope, require('./toolPanel.html'), onOk, onCancel);
                    })
                });
            }

            ctrl.exportUsed = function (exportFormat) {
                if (['EXCEL', 'PDF', 'CSV'].indexOf(exportFormat) > -1) {
                    ctrl.onExportCallback({ exportFormat: exportFormat });
                }
            };

            function onOk() {
                for (var i = 0; i < $scope.toolPanelObject.toolParams.length; i++) {
					var changedParam = $scope.toolPanelObject.toolParams[i];
					if (typeof changedParam.index !== 'undefined') {
                        $scope.param3[changedParam.index] = changedParam.value;
                    }
                }
                $scope.params[2] = $scope.param3.join(';');
                ctrl.onToolCallback({ commandId: $scope.toolPanelObject.commandId, method: $scope.toolPanelObject.method, criteria: $scope.toolPanelObject.criteria, params: $scope.params, formId: ctrl.formId });
            }
            function onCancel() {
                //NOTHING?
            }

            function getEnums() {
                return commonDatacontext.getLookupsFromForm("", false, "", ctrl.formId).then(function (data) {
                    var enumItems = (data && data[0] && data[0].Enums) ? data[0].Enums : [];
                    return enumItems;
                });
            }

            $scope.$watch('ctrl.selectedRestrictionCmds', function () {
                ctrl.activeRestrictionCmds = ctrl.selectedRestrictionCmds;
            });

            $scope.$watchCollection('ctrl.selectedRows', function () {
				angular.forEach(ctrl.toolCommands, function (toolCmd) {
					toolCmd.isDisabled = true;
				});
				if (ctrl.selectedRows) {
					enableOrDisableCommands(ctrl.selectedRows);
				}
			});

			$scope.$watchCollection('ctrl.toolCommands', function () {
				enableOrDisableCommands(ctrl.selectedRows);
			});

            // Functions.

            function activate() {
                var sortByPos = function (c1, c2) {
                    return c1.pos - c2.pos;
                };
                if (ctrl.formId > 0) {
                    commonDatacontext.getFormSettings(ctrl.formId, false).then(function (data) {
                        ctrl.formSettings = data;
                        if (ctrl.useGrouping && ctrl.formSettings && ctrl.formSettings.columnSettings) {
                            var defGrouping = { headerText: '' };
                            ctrl.groupColumns.push(defGrouping);
                            ctrl.selectedGrouping = ctrl.selectedGrouping || defGrouping;
                            angular.forEach(ctrl.formSettings.columnSettings, function (col) {
                                if (col.groupBy > 0) {
                                    ctrl.groupColumns.push(col);
                                }
                            });
                        }

                        if (ctrl.formSettings && ctrl.formSettings.commands) {
                            var commands = [];
                            var subCommandsList = [];
                            angular.forEach(ctrl.formSettings.commands, function (command) {
                                commands.push(command);
                            });

                            if (commands && commands.length > 0) {
                                // Get sort commands and set first sort command as default.
                                ctrl.sortCommands = commands.filter(function (cmd) { return cmd.type == 'MENU_ORDER' && cmd.noShow == false; });
                                if (ctrl.sortCommands.length > 0) {
                                    ctrl.selectedSortCmd = ctrl.sortCommands[0].commandId;
                                    ctrl.selectedSortCmdTitle = ctrl.sortCommands[0].title;
                                }

                                // Get restriction commands (grouped by command group) and set first restriction commands from each group as default.
                                var restrictionCommands = commands.filter(function (cmd) { return cmd.type == 'MENU_SHOW' && cmd.noShow == false; });
                                restrictionCommands = restrictionCommands.sort(sortByPos);
                                if (restrictionCommands.length > 0) {
                                    var tempArr = [];
                                    var index = -1;

                                    angular.forEach(restrictionCommands, function (cmd) {
                                        var cmdInSubGroup = false;
                                        //create a list of objects for further filtering based on subGroups.
                                        if (cmd.subCommands.length > 0) {
                                            subCommandsList.push({ subGroup: cmd.subCommandGroup, type: cmd.type });
                                        }
                                        //check if command belongs to subgroup. If it does, don't add this command to restriction group
                                        if (subCommandsList.length > 0) {
                                            for (var i = 0; i < subCommandsList.length; i++) {
                                                if (subCommandsList[i].type == cmd.type && subCommandsList[i].subGroup == cmd.commandGroup) {
                                                    cmdInSubGroup = true;
                                                }
                                            }
                                        }
                                        if (!cmdInSubGroup) {
                                            if (!tempArr[cmd.commandGroup]) {
                                                tempArr[cmd.commandGroup] = [];
                                                tempArr[cmd.commandGroup].push(++index);
                                                ctrl.restrictionCommands[index] = [];
                                                //array of arrays needed to display groups of buttons properly
                                                ctrl.restrictionCommands[index].push(cmd);
                                                //ctrl.selectedRestrictionCmds = (ctrl.selectedRestrictionCmds ? ctrl.selectedRestrictionCmds + ',' : '') + cmd.commandId;
                                                ctrl.activeRestrictionCmds = ctrl.selectedRestrictionCmds ? ctrl.selectedRestrictionCmds : ((ctrl.activeRestrictionCmds ? ctrl.activeRestrictionCmds + ',' : '') + cmd.commandId);
                                                if (!ctrl.selectedRestrictionCmds) {
                                                    ctrl.selectedRestrictionTitles[index] = cmd.title;
                                                }
                                            }
                                            else {
                                                ctrl.restrictionCommands[tempArr[cmd.commandGroup]].push(cmd);
                                            }
                                        }
                                        // select a subcommand, if it's among active
                                        if (cmdInSubGroup) {
                                            if (ctrl.activeRestrictionCmds.split(',').indexOf(cmd.commandId) >= 0) {
                                                ctrl.selectedSubCmd = cmd.commandId;
                                                ctrl.selectedSubCmdTitle = cmd.title;
                                            }
                                        }
                                        if (ctrl.selectedRestrictionCmds && ctrl.selectedRestrictionCmds.split(',').indexOf(cmd.commandId) > -1) {
                                            ctrl.selectedRestrictionTitles[index] = cmd.title;
                                        }
                                    });

                                    ctrl.selectedRestrictionCmds = ctrl.activeRestrictionCmds; //allows parent to use the value, otherwise some queries will fail due to null in restricitons;
                                }
                                ctrl.toolCommands = commands.filter(function (cmd) { return cmd.type === 'MENU_TOOL' && cmd.noShow === false; }).sort(sortByPos);
                                if (ctrl.filterToolCmd) {
                                    ctrl.filteredToolCommands = ctrl.toolCommands.filter(function (toolCmd) {
                                        if (ctrl.filterToolCmd === 'SINGLEROWSELECTED') {
                                            return toolCmd.criteria === 'SINGLEROWSELECTED';
                                        }
                                        else if (ctrl.filterToolCmd === 'NON_SINGLEROWSELECTED') {
                                            return toolCmd.criteria !== 'SINGLEROWSELECTED';
                                        }
                                        return false;
                                    });
                                }
                                else {
                                    ctrl.filteredToolCommands = ctrl.toolCommands;
                                }

								enableOrDisableCommands();
                            }
							ctrl.showExport = ctrl.formSettings.enableExport;

							ctrl.dropdownAmount = ((ctrl.sortCommands.length > 1 ? 1 : 0) + ctrl.restrictionCommands.length + (ctrl.groupColumns.length > 0 ? 1 : 0)) * 10;

                            $timeout(function () {
                                if (ctrl.isReady) {
                                    ctrl.isReady.resolve();
                                }
                            }, 0);
                        }
                    });
                }
            }
        }],
		template: require('./dfxFormCommands.html')
    };
});
export default formCommandsModule