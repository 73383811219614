'use strict';
var saveCancelModule = angular.module('dir.dfxDeleteSaveCancel',[]);
/**
	 * Delete, Save and Cancel buttons. Visibility can be controlled by showMask.
	 */
saveCancelModule.directive('dfxDeleteSaveCancel', function () {
	// Provides Save and Close Buttons.
	// Usage:
	// <dfx-delete-save-cancel on-save-callback="ctrl.saveChanges(text)" on-cancel-callback ="ctrl.cancelChanges(text)"/>
	return {
		restrict: 'E', //E = element, A = attribute, C = class, M = comment 
		scope: {},
		controllerAs: 'ctrl',
		bindToController: {
			onSaveCallback: '&',
			onCancelCallback: '&',
			onDeleteCallback: '&',
			showMask: '=',  // maskBits: 1: save; 2: cancel; 4: delete
			showTitles: '=?',
			hasChanges: '=?',
            disableApply: '=?',
            disableCancel: '=?'
		},
		controller: ['$scope', function ($scope) {
            var ctrl = this;

			$scope.showDelete = true;
			$scope.showSave = true;
            $scope.showCancel = true;

            ctrl.$onInit = function () {
                if (ctrl.showMask) {
                    $scope.showSave = (ctrl.showMask & 1) > 0;
                    $scope.showCancel = (ctrl.showMask & 2) > 0;
                    $scope.showDelete = (ctrl.showMask & 4) > 0;
                }
                $scope.showTitles = typeof ctrl.showTitles === 'undefined' ? false : ctrl.showTitles;
            };
			
            ctrl.isChangeInfoAvailable = function () {
                return typeof ctrl.hasChanges !== 'undefined';
            };
		}],
		template: require('./dfxDeleteSaveCancel.html')
	};
});
export default saveCancelModule