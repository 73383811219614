'use strict';
/**
 * @class LeanPortalSPA.directives.dfxResources
 * @memberOf LeanPortalSPA.directives
 * @description Resources Directive & Controller
 **/
var resourcesCtrlId = 'resourcectrl';
var resourcesModule=angular.module('manudir.dfxResources',[])
resourcesModule.directive('dfxResources', [function () {
    return {
        restrict: 'E',
        scope: {},
        controller: resourcesCtrlId,
        controllerAs: 'ctrl',
        bindToController: {
            numPerPage: '=?itemsPerPage',
            selectAfterReady: '=',
            useCollapsablePanel: '=?',
            showFavouriteFilter: '=?',		// defines if the favourite selection is shown when favourites exist
            neverShowFavouriteFilter: '=?',	// Set this to true if you do not show favourite filter at all.
			favouriteFilterValue: '=?',		// defines the favourite selection value (favourite vs all resources are shown)
			formId: '=?', // Id of form used in the resource query.
			waitInitUntilReadyPromise: '=?',
			deselectBtn: '=?',
            onSelectionChangedCallback: '&',
            onLoadingReadyCallback: '&',
            onControllerCallback: '&',
            onCloseCallback: '&?'
        },
        template: require('./dfxResources.html')
    };
}]);

resourcesModule.controller(resourcesCtrlId, ['$scope', 'common', 'usersettings', 'manService', resources]);

/**
 * Controller for dfxResources directive.
 * @function Controller
 * @param {object} $scope - Current scope.
 * @param {object} common - Common routines
 * @param {object} usersettings - Usersettings service.
 * @param {object} manService - Manufacturing service.
 * @memberOf LeanPortalSPA.directives.dfxResources
 */
function resources($scope, common, usersettings, manService) {
    var ctrl = this;

    // API offered for the client with onControllerCallback
    var service = {
        triggerLoading: triggerLoading,
        selectResource: selectResource
    };

    ctrl.$onInit = function () {
        ctrl.expanded = false;
        // Used from emphasizing the row selection
        $scope.selectedResource = null;

        ctrl.allResources = [];
        ctrl.currentPage = 1;
		ctrl.filteredResources = [];
		ctrl.formId = ctrl.formId === undefined ? null : ctrl.formId;
        // numPerPage will define how many resources are viewed in table and
        if (typeof ctrl.numPerPage === 'undefined') {
            ctrl.numPerPage = 15;
        }
        // how many of these sets can be accesed directly 
        ctrl.maxPages = 5;

        ctrl.onControllerCallback({ childCtrl: service });

        ctrl.statusCmd = 'SHOW_OPEN';

    };

    ctrl.close = function () {
        ctrl.onCloseCallback();
    };

	ctrl.deselectRsrc = function () {
		ctrl.selectResource(null)
	}

    ctrl.filteringReady = function (filteredObjects) {
        ctrl.filteredResources = filteredObjects;
    };

    ctrl.searchFilterApi = function (item, searchText) {
        return common.textContains(item.name, searchText) || common.textContains(item.resId, searchText);
    };


	/**
     * Selects the resource.
	 * @param {object} resource - resource selected entity.
	 * @param {boolean} selectedFromUi -  selection done by user from UI.
     * @memberOf LeanPortalSPA.directives.dfxResources
	 */
	function selectResource(resource, selectedFromUi) {
		var prevResource;
		if ($scope.selectedResource) {
			prevResource = $scope.selectedResource;
		}
		$scope.selectedResource = resource;
		ctrl.onSelectionChangedCallback({ resource: resource, prevResource: prevResource, selectedFromUi: selectedFromUi })
	}

	ctrl.selectResource = function (resource, selectedFromUi) {
		selectResource(resource, selectedFromUi);
	};

	function triggerLoading(reset) {
		if (reset) {
			ctrl.allResources = [];
			ctrl.filteredResources = [];
			$scope.selectedResource = null;
		}
		// verify that user has logged in
		usersettings.getLoginPromise().then(function () {
			var resourceFromLs = null;
			// If resource is selected either in url or in local storage and resource data found in local storage, run resource query with delay in background.
			if (!$scope.selectedResource) {
				// Resource is given in url.
                var resIdFromUrl = common.$location.search().resId;
                if (!resIdFromUrl) resIdFromUrl = common.$routeParams.resId;
				// Resource is found in localStorage.
				var resIdFromLs = usersettings.getPersonalSettingValue('lastResId', '');
				// If resource is not given in url OR url resource and local storage resource are the same, then we need to check if other resource data is found in local storage.
				if (!resIdFromUrl || resIdFromUrl == resIdFromLs) {
					resourceFromLs = usersettings.getPersonalSettingValue('lastResource', '');
					// Check that resource id match
					if (resourceFromLs && resourceFromLs.resId && resourceFromLs.resId == resIdFromLs) {
						ctrl.selectResource(resourceFromLs);
					}
					else {
						resourceFromLs = null;
					}
				}
			}
			// Set delay if resource is found.
			var delay = $scope.selectedResource || resourceFromLs ? 8000 : 0;
            getResources(reset, delay, delay === 0); // Last parameter: requestedByUi
		});
	}

	ctrl.favStatusChanged = function (resource) {
		ctrl.favouriteCount += (resource.f ? 1 : -1);
		if (ctrl.filterController) {
			ctrl.filterController.favStatusChanged(resource);
		}
	};

	/**
     * Get resources from database.
	 * @param {boolean} [forceRefresh] - Do not use cache.
     * @param {number} delay - Reading is done after given delay. Useful if viewed/needed resource is stored in local storage and no resource selection is needed
     * @param {boolean} [requestedByUi=true] - Was query launched from ui or should the query be run in background? True if not specified.
     * @memberOf LeanPortalSPA.directives.dfxResources
	 */
	function getResources(forceRefresh, delay, requestedByUi) {
		manService.getResources('SELECT_MAN_RESOURCES', forceRefresh, requestedByUi, delay, ctrl.formId).then(function (data) {
            ctrl.allResources = data;
            if (data) {
                var favCount = 0;
                angular.forEach(data, function (rsrc) {
                    favCount += (rsrc.f ? 1 : 0);
                    if (requestedByUi && ctrl.selectAfterReady && rsrc.resId == ctrl.selectAfterReady) {
                        ctrl.selectResource(rsrc);
                    }
                });
                // If no favourites exist, show all 
                if (!ctrl.showFavouriteFilter)
                    ctrl.showFavouriteFilter = (favCount === 0);
                if (ctrl.neverShowFavouriteFilter) {
                    ctrl.showFavouriteFilter = false;
                }
            }
            if (ctrl.onLoadingReadyCallback) {
                ctrl.onLoadingReadyCallback();
            }
        });
	}

	$scope.$on('USERSETTINGS', function () { //the directive needs to reload after usersettings change (for example session timeout).
		init();
	});

	if (ctrl.waitInitUntilReadyPromise) {
		ctrl.waitInitUntilReadyPromise.promise.then(function () {
			init();
		});
	}
	else {
		init();
	}

	function init() {
		triggerLoading();
		if (typeof ctrl.useCollapsablePanel === 'undefined') {
			ctrl.useCollapsablePanel = true;
		}
	}

}
export default resourcesModule