
    'use strict';

    var app = angular.module('common');

    app.directive('focusInput', function ($timeout) {
        return {
            link: function (scope, element, attrs) {
                element.bind('click', function () {
                    $timeout(function () {
                        var e = element.parent().find('input')[0];
                        e.focus();
                        e.click();
                    },0);
                });
            }
        };
    });

    app.filter('startFrom', function () {
        return function (input, start) {
        	start = +start; //parse to int
        	if (input) {
        		return input.slice(start);
        	}
        	else {
        		return input;
        	}
        }
    });

    app.directive('smartFloat', function () {
    	var FLOAT_REGEXP = /^\-?\d+((\.|\,)\d*)?$/;
    	var FLOAT_REGEXP_2 = /^\-?\.+(\d*)?$/;
        return {
            require: 'ngModel',
            link: function (scope, elm, attrs, ctrl) {
				ctrl.$parsers.unshift(function (viewValue) {
					if (FLOAT_REGEXP.test(viewValue)) {
						ctrl.$setValidity('float', true);
						
						if (typeof viewValue === "number")
							return viewValue;
						else
							return parseFloat(viewValue.replace(',', '.'));
					} else if (FLOAT_REGEXP_2.test(viewValue)) {
						ctrl.$setValidity('float', true);
						return parseFloat(viewValue.replace('.', '0.'));
					} else if (viewValue === '') {
						ctrl.$setValidity('float', true);
						return null;
					} else if (viewValue === '-') {
						ctrl.$setValidity('float', false);
						return null;
					} else if (typeof ctrl.$modelValue === "number") { // Invalid value: if previous model value is number use it and set field valid.
						ctrl.$viewValue = ctrl.$modelValue;
						ctrl.$render();
						ctrl.$setValidity('float', true);
						return ctrl.$modelValue;
					}
					else { // Other invalid values are just throwed away.
						ctrl.$setValidity('float', true);
						return undefined;
					}
				});
            }
        };
	});

/**
 * Formats number by rounding to given decimal count. Can be used if decimal count is taken from a variable. For fixed numbers you can use basic number:2 formatter.
 * @todo Now 0 decimals is not supported - would require to have QualityDataRow model to have decimalCount to be nullable int instead of int. Otherwise all null decimalCounts would be rounded to integer.
 * @example <input type="text" ng-model="foo" smart-format-decimals="2">
 */
app.directive('smartFormatDecimals', function () {
	return {
		require: 'ngModel',
		link: function (scope, elm, attrs, ctrl) {

			// Round to given decimals.
			function round(value, decimalCount) {
				var factor = Math.pow(10, decimalCount);
				return Math.round((value + Number.EPSILON) * factor) / factor;
			}

			var formatter = function (value) {
				if (attrs.smartFormatDecimals && attrs.smartFormatDecimals > 0) {
					return round(value, attrs.smartFormatDecimals);
				}
				return value;
			};

			ctrl.$formatters.push(formatter);

			elm.bind('blur', function () {
				elm.val(formatter(ctrl.$modelValue))
			});

			elm.bind('focus', function () {
				elm.val(ctrl.$modelValue);
			});
		}
	};
});

    app.directive('draggable', function () {
        return function (scope, element) {
            var el = element[0];

            el.draggable = true;

            el.addEventListener(
                'dragstart',
                function (e) {
                    e.dataTransfer.effectAllowed = 'copy';
                    e.dataTransfer.setData('Text', this.id);
                    this.classList.add('drag');
                    return false;
                },
                false
            );

            el.addEventListener(
                'dragend',
                function (e) {
                    this.classList.remove('drag');
                    return false;
                },
                false
            );
        }
    });


    app.directive('droppable', function() {
        return {
            scope: {
                drop: '&'
            },
            link: function (scope, element) {
                var el = element[0];

                el.addEventListener(
                    'dragover',
                    function (e) {
                        if (e.preventDefault) e.preventDefault();

                        if (this.attributes.isreadonly && this.attributes.isreadonly.value != 'true') {
                            e.dataTransfer.dropEffect = 'copy';
                            this.classList.add('dragOver');
                        }
                        return false;
                    },
                    false
                );

                el.addEventListener(
                    'dragenter',
                    function (e) {
                        if (this.attributes.isreadonly && this.attributes.isreadonly.value != 'true') {
                            this.classList.add('dragOver');
                        }
                        return false;
                    },
                    false
                );

                el.addEventListener(
                    'dragleave',
                    function (e) {
                        this.classList.remove('dragOver');
                        return false;
                    },
                    false
                );

                el.addEventListener(
                    'drop',
                    function (e) {
                        // Stops some browsers from redirecting.
                        if (e.preventDefault) e.preventDefault();
                        if (e.stopPropagation) e.stopPropagation();

                        this.classList.remove('dragOver');
                        var sourceItem = document.getElementById(e.dataTransfer.getData('Text'));
                        var dragType = e.dataTransfer.getData('Text').split('/')[0];
                        var workId = null;
                        var operatId = null;
                        var projId = null;
                        var taskId = null;

                        if (sourceItem.hasAttribute('workid') && sourceItem.hasAttribute('operatid')) {
                            workId = sourceItem.attributes.workid.value;
                            operatId = sourceItem.attributes.operatid.value;
                        }
                        if (sourceItem.hasAttribute('projid') && sourceItem.hasAttribute('taskid')) {
                            projId = sourceItem.attributes.projid.value;
                            taskId = sourceItem.attributes.taskid.value;
                        }
                        var targetHiRowIdx = this.id;

                        scope.$apply(function(scope) {
                            var fn = scope.drop();
                            if ('undefined' !== typeof fn) {
                                fn(dragType, workId, operatId, projId, taskId, targetHiRowIdx);
                            }
                        });
                        
                        return false;
                    },
                    false
                );
            }
        }
    });

	// Adds element height (from starting position to footer).
	// Usage:
	// <div dfx-fill-height id="task-view-guide"/>
	// Note: Add overflow-y:auto css class if content is scrollable
	app.directive("dfxFillHeight", ['$window', function ($window) {
			return {
				restrict: 'A',
				scope: {
					spaceBelow: "=?",
					requestMaxHeight: "=?"
				},
				link: function (scope, e, attr) {
					var fill = function () {
						var heightToBelow = $window.innerHeight - e[0].getBoundingClientRect().top;
						var footerHeight = angular.element(document.querySelector('#dfx-footer'));
						heightToBelow -= footerHeight.height() ? footerHeight.height() : 0; // remove footer from height
						var secondaryFooter = angular.element(document.querySelector('#dfx-footer-secondary'));
						heightToBelow -= secondaryFooter && secondaryFooter.height() ? secondaryFooter.height() : 0; // remove secondary footer from height
						// remove optional height, leaves space for summary row
						if (scope && scope.spaceBelow > 0) {
							heightToBelow -= scope.spaceBelow;
						}
						if (scope && scope.requestMaxHeight) {
							angular.element(e).css('max-height', heightToBelow + 'px');
						}
						else {
							angular.element(e).css('height', heightToBelow + 'px');
						}
					};

					scope.$watch(function () {
						return e[0].getBoundingClientRect().top;
					}, function () {
						fill();
					});

					angular.element($window).bind('resize', function () {
						fill();
					});
				}
			};
		}
	]);

    

    var commonDataViewCtrlId = 'commonDataViewCtrl';
    app.controller(commonDataViewCtrlId, ['$scope', 'common', 'usersettings', function ($scope, common, usersettings) {
    	var ctrl = this;
    	ctrl.imageHeight = 40;
    	ctrl.maskEnum = common.commonDataEnum;
    	$scope.objectCount = [];
		// Currrently all are visible
    	ctrl.visibleMask = 0xffff;//common.getCommonDataTypeMask(usersettings, ctrl.owner);
    	$scope.activeTabIndex = getFirstVisibleTabIndex();
    	common.getCommonDataTypes().forEach(function (e) {
    		$scope.objectCount[e.bit] = -1;
    	});
		
    	function getFirstVisibleTabIndex() {
    		var enums = common.getCommonDataTypes();
    		for (var i = 1; i <= enums.length; i++){
    			if (ctrl.visibleMask & enums[i].bit) {
    				return i;
    			}
    		}
    		return 1;
    	}
		common.appSettingReady().then(function (data) {
			ctrl.employeeDataQueryType = common.getAppSetting("EmployeeDataQueryType",1)
		})
    	ctrl.width = usersettings.getPersonalSettingValue(getParam('Width'), 50);
		// In case some test time garbage exists <=>  tests that the value is a number
    	if (/^[0-9]+$/.test('' + ctrl.width) == false) {
    		ctrl.width = 50;
    	}
    	ctrl.imageHeight = usersettings.getPersonalSettingValue(getParam('ImageHeight'), ctrl.imageHeight);
    	ctrl.layout = !ctrl.layout ? 'SIDEBAR' : ctrl.layout;

    	ctrl.buttonClicked = function (maskEnum) {
			// Open always if closed, close if the same is clicked again
    		if (ctrl.currentSelection == maskEnum || !ctrl.isOpen) {
    			ctrl.isOpen = !ctrl.isOpen;
    		}
    		ctrl.currentSelection = maskEnum;
    	}

    	ctrl.contentChanged = function (maskEnum, count) {
    		$scope.objectCount[maskEnum.bit] = count;

    		var allReady = true;
    		common.getCommonDataTypes().forEach(function (e) {
    			if (ctrl.visibleMask & e.bit && $scope.objectCount[e.bit] < 0) {
    				allReady = false;
    			};
    		});

    		if (allReady && ctrl.layout == 'TAB') {
    			var set = false;
    			var tabIndex = 1;
    			common.getCommonDataTypes().forEach(function (e) {
    				if (!set && $scope.objectCount[e.bit] > 0) {
    					$scope.activeTabIndex = tabIndex;
    					set = true;
    				}
    				tabIndex += 1;
    			});
    		}
    	}

    	ctrl.changeWidth = function (add) {
    		if (add && ctrl.width < 90) {
    			ctrl.width += 10;
    			usersettings.setPersonalSettingValue(getParam('Width'), ctrl.width);
    		}
    		if (!add && ctrl.width > 10) {
    			ctrl.width -= 10;
    			usersettings.setPersonalSettingValue(getParam('Width'), ctrl.width);
    			}
    	}
    	ctrl.getWidthClass = function () {
    		return 'dfx-width-percent-'+ctrl.width;
    	}
    	ctrl.getWidthTitle = function (add) {
    		return common.$translate.instant("STRCONST.PUBLIC.TXT_CURRENT") + ' ' + common.$translate.instant("STRCONST.PUBLIC.TXT_VALUE").toLowerCase() + ': ' + ctrl.width + '% (' +
	    			(add ? common.$translate.instant("STRCONST.PUBLIC.TXT_MAX") + ' 90%)' : common.$translate.instant("STRCONST.PUBLIC.TXT_MIN") + ' 10%)');
    	}
    	ctrl.getName = function (maskEnum) {
    		return maskEnum.name.slice(0, 1);
    	}
    	ctrl.getObjectTitle = function () {
    		return ctrl.selectedObj && typeof ctrl.selectedObj.getCommonDataTitle != 'undefined' ?
				ctrl.selectedObj.getCommonDataTitle() : '';
    	}
    	ctrl.getCount = function (e) {
    		return $scope.objectCount[e.bit] && $scope.objectCount[e.bit] > 0 ? ' (' + $scope.objectCount[e.bit] + ')' : '';
    	}
    	ctrl.imageHeightChanged = function (value) {
    		usersettings.setPersonalSettingValue(getParam('ImageHeight'), value);
    	}
    	ctrl.isActive = function (maskEnum) {
    		return ctrl.currentSelection == maskEnum;
    	}
    	ctrl.isAvailable = function (maskEnum) {
    		return ctrl.visibleMask & maskEnum.bit;
    	}
    	ctrl.openSettingsDialog = function (sidebar) {
    		var settings = ['dfx-settings-common-data'];
    		// class used if more info needs to propagated
    		$scope.commonData = {
    			owner: ctrl.owner
    		}
    		common.openPortalSettings($scope, settings);
    	}
    	function getParam(param){
    		return common.getCommonDataParamName(ctrl.owner, param);
    	}

    	$scope.$on('PORTALSETTINGSCHANGED', function (event, args) {
    		if (args.model && (args.value != undefined)) {
				// width
    			if (args.model == getParam('Width')) {
    				ctrl.width = args.value;
    			}
				// data visibility
    			common.getCommonDataTypes().forEach(function (e) {
    				if (args.model == getParam(e.paramName)) {
    					if (args.value) {
    						ctrl.visibleMask = ctrl.visibleMask | e.bit;
    					} else {
    						ctrl.visibleMask = ctrl.visibleMask ^ e.bit;
    					}
    				}
    			});
    		}
    	});
    }]);

    app.directive('dfxPortalSettings',['common', 'usersettings', function (common, usersettings) {
        // PortalSettings directive
        // Usage:
        //  portalId: Current portal id, used to generate matching template
        // Example:
        // <dfx-portal-settings portalid="'PORTALID-VARIABLE'"></dfx-portal-settings>
        return {
            restrict: 'E', //E = element, A = attribute, C = class, M = comment 
            scope: {},
            bindToController: {
                portalid: '='
            },
            controller: ['$scope', function ($scope) {
                var ctrl = this;

                /*Init radio button values, if no LS-value then show both id and name*/
                $scope.projectListVal = usersettings.getPersonalSettingValue('projectListVal', 'IdName');
                $scope.inputFieldVal = usersettings.getPersonalSettingValue('inputFieldVal', 'IdName');

                ctrl.initSettingValue = function (model) {
                    return (usersettings.getPersonalSettingValue(model, false));
                }

                ctrl.toggleSettingsChange = function (model, value) {
                    if (model && (value != undefined)) {
                        usersettings.setPersonalSettingValue(model, value);
                        /*Broadcast the change*/
                        common.$broadcast('PORTALSETTINGSCHANGED', { model: model, value: value });
                    }
                }

                $scope.getTemplateUrl = function () {
                    var portalId = $scope.ctrl.portalid;
                    if (portalId) {
                        switch (portalId) {
                            case 'TIMEREPORTING':
                                return 'app/services/PortalSettings/portalSettings_HI.html';
                                /*TODO
    						case 'CONFIGURATOR':
    							return 'app/services/portalSettings_CFG.cshtml';
								*/
                            default:
                                return;
                        }
                    }
                }
            }],
            controllerAs: 'ctrl',
            template: '<ng-include src="getTemplateUrl()"/>'
        }
    }]);

    app.directive('isolateClick', function () {
    	return {
    		link: function (scope, elem) {
    			elem.on('click', function (e) {
    				e.stopPropagation();
    			});
    		}
    	};
    });

	/**
	 * isolateClick might cause whole page to refresh.
	 * Use this directive for parent html element instead.
	 */
    app.directive('segregatedClick', function ()    {
    	return {
    		link: function (scope, elem)	{
    			elem.on('click', function(e){
    				e.preventDefault();
    				e.stopImmediatePropagation();
				});
    		}
    	};
    });
	
	

    

	/**
	 * Directive that places focus on the element it is applied to when the
	 * expression it binds to evaluates to true
	 */
    app.directive('dfxFocus', ['$timeout', function dfxFocus($timeout) {
		return function (scope, elem, attrs) {
			scope.$watch(attrs.dfxFocus, function (newVal, oldVal) {
				if (newVal) {
					$timeout(function () {
						if (attrs.title && !attrs.title.replace(/\s/g, '').length) { //we only want to click empty elements on focus.
							elem[0].click();
						}
						elem[0].focus();
					}, 300, false);
				}
			});
		};
    }]);


	/**
	 * Attaches operation to Esc-key click
	 */
    app.directive('dfxEscape', function () {
		var ESCAPE_KEY = 27;
		return function (scope, elem, attrs) {
			elem.bind('keydown', function (event) {
				if (event.keyCode === ESCAPE_KEY) {
					scope.$apply(attrs.dfxEscape);
				}
			});
			scope.$on('$destroy', function () {
				elem.unbind('keydown');
			});
		};
	});

	/**
	 * Attaches operation to Enter-key click
	 */
    app.directive('dfxEnter', function () {
    	var ENTER_KEY = 13;
    	return function (scope, elem, attrs) {
    		elem.bind('keydown', function (event) {
    			if (event.keyCode === ENTER_KEY) {
    				scope.$apply(attrs.dfxEnter);
    				event.preventDefault();

    			}
    		});
    		scope.$on('$destroy', function () {
    			elem.unbind('keydown');
    		});
    	};
    });



	/**
	 * Convert to uppercase
	 * http://stackoverflow.com/questions/16388562/angularjs-force-uppercase-in-textbox
	 */
    app.directive('uppercased', function() {
    	return {
    		require: 'ngModel',        
    		link: function(scope, element, attrs, modelCtrl) {
    			modelCtrl.$parsers.push(function(input) {
    				return input ? input.toUpperCase() : "";
    			});
    			element.css("text-transform","uppercase");
    		}
    	};
    });

    app.directive('dfxPopup', function () {    	
    	return {
    		restrict: 'E', //E = element, A = attribute, C = class, M = comment 
    		scope: {},
    		controllerAs: 'ctrl',
    		bindToController: {
    			btnId: '=?', //for future checks, might not be necessary
    			popupName: '=',     			
				isOpen: '=', //popup window status from parent
    			header: '=', //string constant for button text
    			onStatusChangedCallback: '&',
    			onControllerCallback: '&',
    			onBeforeStatusChangedCallback: '&?', // If defined the callback must return true for status change to take place
    			onTogglePopupCallback: '&'
    		},

    		controller: ['$scope', '$window', function ($scope, $window) {
    			var ctrl = this;
    			ctrl.showCaret = false;
    			var test = ctrl.status;
    			//TODO: Implement register controller for storing popUps as objects 			
    			// API offered for the client with onControllerCallback
    			//var service = {
    				
    			//}
    			//ctrl.onControllerCallback({childCtrl: service });
    							
				ctrl.togglePopup = function () {
    				//set $scope.open back to true to prevent conflicts with watch reseting the value.
					$scope.open = true;
					if (ctrl.isOpen) {
						ctrl.onTogglePopupCallback({ popupName: ctrl.popupName }); //popUp wasn't activated (still opened), report to parent about changes and close. 
					}
     				togglePopup(true);    			
    		    };

				function togglePopup(needApply) {
					//If onBeforeStatusChangedCallback is defined, the callback must return true for status change to take place
					if (ctrl.onBeforeStatusChangedCallback && ctrl.onBeforeStatusChangedCallback()) {
						return;
					}
    		    	$scope.open = !$scope.open;    		    	
    		    	ctrl.showCaret = $scope.open;

    		    	//report to parent, that status has changed. 
    		    	ctrl.onStatusChangedCallback({ status: $scope.open });
    		    	if ($scope.open) {
    		    		$window.onclick = function () {
    		    			closePopup(event, ctrl.togglePopup);
    		    		};
    		    	} else {
    		    		$scope.open = false;    		    		
    		    		$window.onclick = null;    		    		
    		    		if (needApply) {    		    			
    		    			$scope.$apply(); //--> trigger digest cycle. 
    		    		}
    		    	}
    		    };

    		  /**
			  * Button functionality for opening and closing popup dialog.
			  * @param {Popup name.} popupName
			  */
    		   ctrl.activatePopup = function () {    		    	
    		    	ctrl.onTogglePopupCallback({ popupName: ctrl.popupName });    		    	    		    			    		
    		    	togglePopup(false);    	   		
    		    }
				
				/**
				 * Closes popup if clicked on any other element than own popup button
				 * To use any element as a popup, use isolated-click directive
				 */
			    function closePopup(event, callbackOnClose) {
    		    	var clickedElement = event.target;
    		    	if (!clickedElement) return;
    		    	
    		    	var clickedOnPopUp = clickedElement.parentElement !== null && clickedElement.hasAttribute('popup');

    		    	if (!clickedOnPopUp) {
    		    		callbackOnClose();
    		    		return;
    		    	}
			    }

    			/**
    			 * Resets the values when another popup is opened. 
    			 */
			    $scope.$watch('ctrl.isOpen', function () {
			    	if ($scope.open) {
			    		$scope.open = ctrl.isOpen;
			    		ctrl.showCaret = ctrl.isOpen;
			    	}
			    });

    		}],
			template: '<a class="a-dropdown dark" popup id="ctrl.btnId" ng-click="ctrl.activatePopup()" ng-class="{\'dfx-popup-active\': ctrl.showCaret}">{{ctrl.header}}</a>'
    	}
    });

    angular.module('app',[]).config(['zDirectivesConfigProvider', function configDirective(cfg) {
    	// Custom template with warning icon before the error message
    	cfg.zValidateTemplate =
           '<span class="invalid"><i class="glyphicon glyphicon-warning-sign icon-col"></i><br>' +
           '%error%</span>';
    }]);


    

    

    /**
	 * Directive for mimic ngDisabled with the exception that this does not override disabled=true attribute.
	 * If either given parameter value evaluates true or html disabled is true, element gets disabled. You should determine case by case which you should use: ng-disabled or this directive.
	 * Usage example: 
	 * <input dfx-disabled="ctrl.valuexxx > 0">
	 */
    app.directive('dfxDisabled', function () {
        return {
            restrict: 'EA',
            link: function (scope, element, attrs) {
                scope.$watch(attrs.dfxDisabled, function (value) {
                    if (scope.$parent.$eval(attrs.dfxDisabled) || element[0].disabled) {
                        element.attr('disabled', 'disabled');
                    } else {
                        element.removeAttr('disabled');
                    }
                });
            }
        }
    });

	// Directive for displaying form buttons
	// Usage examples:
	// <button dfx-button form-id="@formId" command-id="'RELEASE_ROWS'" type="button" class="btn btn-success" data-ng-click="vm.releaseRows()"></button>
	// <button dfx-button form-id="@formId" command-id="'RELEASE_ROWS'" type="button" class="btn btn-success" data-ng-click="vm.releaseRows()" string-constants="{ title: 'STRCONST.PMW.TOOL_RELEASE_WORKREP_ROWS', tooltip: 'STRCONST.PMW.TOOL_RELEASE_WORKREP_ROWS_TOOLTIP' }"></button>
    app.directive('dfxButton', ['$timeout', 'usersettings', 'commonDatacontext', function dfxButton($timeout, usersettings, commonDatacontext) {
    	function handleSettings(scope, element) {
    		var titleText = null;
    		if (!scope.formSettings.commands[scope.commandId]) {
    			element[0].outerHTML = "";
    		} else if (scope.stringConstants) {
    			$translate([scope.stringConstants.title, scope.stringConstants.tooltip]).then(function (translations) {
					titleText = document.createTextNode(translations[scope.stringConstants.title]);
					element[0].innerHTML = titleText;
					if (translations[scope.stringConstants.tooltip]) {
						element[0].setAttribute("title", translations[scope.stringConstants.tooltip]);
					}
    			});
    		} else {
				titleText = scope.formSettings.commands[scope.commandId].title;
				if (titleText) {
					element[0].innerHTML = titleText;
					if (scope.formSettings.commands[scope.commandId].tooltip) {
						element[0].setAttribute("title", scope.formSettings.commands[scope.commandId].tooltip);
					}
				}
    		}
    	}
    	return {
    		restrict: 'A',
    		scope: {
    			force: '=?',
    			formId: '=?', // Id of the form where button data is taken from.
    			commandId: '=?', // Id of the button. E.g. 'RELEASE_ROWS'
    			stringConstants: '=?' // Object that overrides default title and tooltip values of the button. E.g. {title: 'STRCONST.PMW.TOOL_RELEASE_WORKREP_ROWS', tooltip: 'STRCONST.PMW.TOOL_RELEASE_WORKREP_ROWS_TOOLTIP'}
    		},
			link: function (scope, element, attributes) {
				scope.$on('USERSETTINGS', function () { //the directive needs to reload after usersettings change (for example session timeout).
					$timeout(executeDirective, 0);
				});
                var executeDirective = function () {
                    if (scope.formId && scope.commandId) {
                        usersettings.getLoginPromise().then(function () {
                            commonDatacontext.getFormSettings(scope.formId, scope.force).then(function (data) {
                                if (data) {
                                    scope.formSettings = data;
                                    handleSettings(scope, element);
                                }
                            },
                                function (data) {
                                    scope.formSettings = null;
                                });
                        });
                    }
    			}
    			$timeout(executeDirective, 0); //Moves the directive execution after DOM rendering in event queue
    			}    			
    	}
    }]);

	/*Directive to read selected file, currently doesn't support multiple files*/
	app.directive("fileread", ['$window', function ($window) {
	    return {
	        scope: {
	            fileread: "="
	        },
	        link: function (scope, element, attributes) {
	            element.bind("change", function (changeEvent) {
	                scope.$apply(function () {
	                    scope.fileread = changeEvent.target.files;
	                    var document = $window.document;
	                    var preview = document.getElementsByClassName('fileread-preview')[0];
						if (preview && scope.fileread) {
							/*Clear preview if any previous selections*/
							if (preview.childNodes.length > 0) {
								preview.innerHTML = '';
							}
							for (var i = 0; i < scope.fileread.length; i++) {
								var imageNameEl = document.createElement('div');
								imageNameEl.innerHTML += scope.fileread[i].name;
								var imageEl = document.createElement('img');
								imageEl.className += 'img-preview';
								imageEl.src = window.URL.createObjectURL(scope.fileread[i]);
								preview.appendChild(imageNameEl);
								imageNameEl.appendChild(imageEl);
							}
						}
					});
				});
			}
		}
	}]);

	
	/* Scroll to the element when class dfx-scroll-to is set true (the container must be marked with dfx-scrolling-container class)*/
	app.directive('dfxScrollTo', ['$timeout', function ($timeout) {
		return {
			restrict: 'A', //E = element, A = attribute, C = class, M = comment 
			scope: {},
			controllerAs: 'ctrl',
			controller: function () {},
			link: function (scope, element, attrs, controller) {
				scope.$watch(function () { return element.attr('dfx-scroll-to'); }, function (newValue) {
					if (newValue == 'true') {
						// Hack by KKo: 07.12.2016
						// IE causes problems with table scrolling, so an we need to call the same functionality
						// two times. One can test this by changing between dfxTasks card and table views.
						scrollRecursively(0);
						function scrollRecursively(cnt) {
							if (cnt > 1) {
								return;
							}
							cnt++;
							$timeout(function () {
								var myContainer = $('.' + attrs['scrollContainer'])
								if (myContainer.offset()) {
									myContainer.animate({ scrollTop: element.offset().top - myContainer.offset().top + myContainer.scrollTop() }, "slow");
								}
								scrollRecursively(cnt)
							}, 50);
						}
					}
				});
			}
		};
	}]);



	/**
	 * Scrolls to element and highlights it for 3 seconds
	 * tried to make with 2 different directives, but 2 isolated scopes aren't allowed for 1 element.
	 */
	app.directive('dfxScrollWithHighlight', ['$timeout', function ($timeout) {
		return {
			restrict: 'A', //E = element, A = attribute, C = class, M = comment 
			scope: {},
			controllerAs: 'ctrl',
			controller: function () { },
			link: function (scope, element, attrs, controller) {
				scope.$watch(function () { return element.attr('dfx-scroll-with-highlight'); }, function (newValue) {
					if (newValue == 'true') {
						$timeout(function () {
							var myContainer = $('.' + attrs['scrollContainer'])
							if (myContainer.offset()) {
								myContainer.animate({ scrollTop: element.offset().top - myContainer.offset().top + myContainer.scrollTop() - 5 }, "slow");
							}
							element.addClass('hightlight');
							$timeout(function () {
								element.removeClass('hightlight');
							}, 3000);
						}, 50);
					}
				});
			}
		};
	}]);	


	

	

	/*
	 * Accepts only numeric input. Formats viewValue from double model to localised string. Works with text input fields only.
	 */		
	app.directive('dfxFloat', ['common', function dfxParseFloat(common) {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, elm, attr, ngModelCtrl) {
				var viewValue;
				var returnValue;
				if (attr.type !== 'text') return; //works only for text input.
				//formatter: modelValue to view
				ngModelCtrl.$formatters.push(floatFormatter);
				//parser: viewValue to model
				ngModelCtrl.$parsers.push(floatParser);
				//separates view and model values, prevents input of invalid characters
				function floatParser(modelValue) {					
					var regex1 = /^\-?\d+((\.|\,)\d+)?$/;
					var regex2 = /^\-?\.+(\d+)?$/;
					if (common.getThousandSeparator() === ',') { //allows several commas if thousand sep. is ','.
						regex1 = /^-?\d+(,\d+)*((\.|\,)\d+(\d+)?)?$/;
					}
					var testValue = (modelValue + "0").replace(/\s+/g, ''); //without 0 won't allow decimal separators (e.g. '1.' doesn't pass regex).
					if (!regex1.test(testValue) && !regex2.test(testValue)) { //character not allowed if doesn't pass both expressions, otherwise will allow several commas
						var regex3 = /^[a-zA-Z\+].*$/; // First character is a letter or plus sign. Without this check we end up into eternal loop and/or get previous model value into view value.
						if (regex3.test(testValue)) { //if there was a previous viewValue, use it (assumed to be correct). 
							if (viewValue) {
								ngModelCtrl.$setViewValue(viewValue);
							}
							else if (!viewValue && ngModelCtrl.$modelValue) { //view Value null might mean changes are done to input saved to model already. Check model.
								ngModelCtrl.$setViewValue(common.dblToLocalStr(ngModelCtrl.$modelValue));
							}
							else ngModelCtrl.$setViewValue(null); //none of the above, input is empty
						}
						else {
							ngModelCtrl.$setViewValue(viewValue);							
						}						 
						modelValue = viewValue; //modelValue(input) contains invalid characters. Use previous viewValue or null. 
					} else {						
						viewValue = modelValue; //assigning modelValue(userInput) to view.
					}
						modelValue = common.strToDbl(modelValue); //formats the string value so model has proper decimal number
						ngModelCtrl.$render();						
						elm.value = viewValue; // assigning view value in to the element				
						return (+viewValue === +modelValue) ? viewValue : modelValue; //Compares view and Model values and saves either one into model. 	
					
				}				
				
				//We want to allow localFormatting and pass only viewValue, modelValue is never shown in UI (except if viewValue isn't available for somereason).
				function floatFormatter(modelValue) {					
					viewValue = ngModelCtrl.$viewValue;					
					var returnValue = viewValue ? viewValue : common.dblToLocalStr(modelValue); //not saved input values are displayed in localised format
					return returnValue; //returns value to the view.
				}
				//formats the viewValue when element looses focus
				elm.on('blur', function () {
					if (ngModelCtrl.$valid) {
						ngModelCtrl.$viewValue = common.dblToLocalStr(ngModelCtrl.$modelValue);
						ngModelCtrl.$render();
					}
				});
			}
		
		};
	}]);

	



	


	app.directive('dfxModuleLayoutItem', function () {
		return {
			restrict: 'E',
			require: '^dfxModuleLayout',
			transclude: true,
			scope: {
				moduleData: '=?',
				tools: '=?', // id, name, tooltip
				onToolClickedCallback: '&'
			},
			controller: function () {
				//Empty controller so other directives can require being 'under' a tab
			},
			controllerAs: 'item',
			link: function (scope, elm, attrs, pageCtrl, transclude) {
				if (angular.isUndefined(attrs.index)) {
					if (pageCtrl.items && pageCtrl.items.length) {
						scope.index = Math.max.apply(null, pageCtrl.items.map(function (t) { return t.index; })) + 1;
					} else {
						scope.index = 0;
					}
				}
				pageCtrl.addItem(scope);
				//We need to transclude later, once the content container is ready.
				//when this link happens, we're inside a tab heading.
				scope.$transcludeFn = transclude;
			}
		};
	});

	app.directive('dfxModuleLayoutItemTransclude', function () {
		return {
			restrict: 'A',
			link: function (scope, elm, attrs) {
				// item is the scope
				var item = scope.$eval(attrs.dfxModuleLayoutItemTransclude);

				//Now our item is ready to be transcluded: both the item heading area
				//and the item content area are loaded.  Transclude 'em both.
				item.$transcludeFn(item.$parent, function (contents) {
					angular.forEach(contents, function (node) {
						elm.append(node);
					});
				});
			}
		};
	});

	

	

	app.directive('dfxFooter', function () {
		return {
			restrict: 'EA', //E = element, A = attribute, C = class, M = comment 
			transclude: true,
			template: '<div id="dfx-footer" class="dfx-footer" ng-transclude></div>'
		}
	});
	app.directive('dfxContent', function () {
		return {
			restrict: 'EA', //E = element, A = attribute, C = class, M = comment 
			transclude: true,
			template: '<div class="dfx-content" ng-transclude></div>'
		}
	});

    /*
     * Use with m-prioritycontainer REX-style to hide extra tabs/buttons etc. when width runs out.
     */
	app.directive('dfxPrioritycontainer', ['$timeout', '$window', function ($timeout, $window) {
	    return function (scope, element, attrs) {
	        var timer;
	        var container = element;
	        var content = container.find(".m-prioritycontainer__content");
	        var open = container.find(".m-prioritycontainer__button.open");
	        var close = container.find(".m-prioritycontainer__button.close");
	        // Enclose the container and content in the largest height requirement of
	        // either party
	        var height = Math.max(container.height(), content.height());

	        container.css("height", height);
	        // Perform the opening and closing of the content when the button is pressed
	        open.click(function () {
	        	container.addClass("expanded");
				/*Find elements which has no overflowing-class and place them into placeholder*/
	        	var children = angular.element(content.find('> *'));
	        	angular.forEach(children, function (value, key) {
	        		var el = angular.element(value);
	        		if (!el.hasClass("overflowing")) {
						var elementToPlaceHolder = angular.copy(angular.element(value));
						var placeholder = document.getElementById('m-prioritycontainer__placeholder');
	        			placeholder.appendChild(elementToPlaceHolder[0]);
	        		}
	        	});
	        });
	        close.click(function () {
	        	container.removeClass("expanded");
				/*Clear elements in prioritycontainer__placeholder*/
	        	var placeholder = document.getElementById('m-prioritycontainer__placeholder');
	        	placeholder.innerHTML = "";

	            calculateOverflow(container, content, open, close);
	        });
	        // Re-calculate the size requirement every time the window is resized
	        // This function uses simple debouncing to reduce the amount of
	        // recalculations and rerenderings during a resize operation
	        angular.element($window).bind('resize', function () {
	            // start timing for event "completion"
	            if (timer) {
	                $timeout.cancel(timer);
	            }
	            timer = $timeout(calculateOverflow(container, content, open, close), 100);
	        });

	        scope.$on('LastElemInPCR', function (event) {
	            $timeout(calculateOverflow(container, content, open, close), 0);
	        });

	        scope.$watch(function () {
	            return angular.element(content.find('> *')).length;
	        }, function (event) {
	            $timeout(calculateOverflow(container, content, open, close), 100);
	        });
	        // Perform the calculation the first the the page is loaded
	        $timeout(calculateOverflow(container, content, open, close), 0);

	        function calculateOverflow(container, content, open, close) {
	            // Reset the open and close buttons and each item in content
	            open.removeClass("expandable");
	            close.removeClass("expandable");

	            var children = angular.element(content.find('> *'));
	            angular.forEach(children, function (value, key) {
	                var el = angular.element(value);
	                el.removeClass("overflowing");
	            });
	            // If the container is overflowing, display expand buttons
	            if (content[0].offsetWidth < content[0].scrollWidth) {
	                open.addClass("expandable");
	                close.addClass("expandable");
	                var overflowing = false;
	                var parentRect = content[0].getBoundingClientRect();
	                angular.forEach(children, function (value, key) {
	                    var el = angular.element(value);
	                    var childRect = el[0].getBoundingClientRect();
	                    // Find out the first element that is overflowing
	                    if (!overflowing && parentRect.right < childRect.right) {
	                        overflowing = true;
	                    }
	                    // Everything after that overflowing element gets hidden
	                    if (overflowing) {
	                        el.addClass("overflowing");
	                    }
	                });
	            }	            
	        }
	    };
	}]);

	app.directive('dfxOverflowChecker', ['$timeout', '$window', function ($timeout, $window) {
		return function (scope, element, attrs) {
			var lastWidthBeforeOverflow;
			var timer;
			var container = element;
			var content = container.find(".overflow-checker__content");
			container.addClass("not_overflowing");

			angular.element($window).bind('resize', function () {
				// start timing for event "completion"
				if (timer) {
					$timeout.cancel(timer);
				}
				timer = $timeout(calculateOverflow(container, content), 100);
			});

			scope.$watch(function () {
				return angular.element(content.find('> *')).length > 1;
			}, function (event) {
				$timeout(calculateOverflow(container, content), 100);
			});
			// Perform the calculation the first the the page is loaded
			$timeout(calculateOverflow(container), 0);

			function calculateOverflow(container, content) {
				content = container.find(".overflow-checker__content");
				if (!content)
					return;
				var children = angular.element(content.find('> *'));
				var e = container[0];
				//console.log("scrollWidth: " + e.scrollWidth + " offsetWidth: " + e.offsetWidth + " lastWidthBeforeOverflow: " + lastWidthBeforeOverflow);
				if (children.length > 1) {
					var childWidth = getChildWidth(children);
					if (e.offsetWidth < childWidth+10 && !lastWidthBeforeOverflow) {

						lastWidthBeforeOverflow = e.scrollWidth;
						container.removeClass("not_overflowing");
						container.addClass("overflowing");
					}
					else if (lastWidthBeforeOverflow + 10 <= e.scrollWidth) {
						lastWidthBeforeOverflow = 0;
						container.removeClass("overflowing");
						container.addClass("not_overflowing");
					}
				}
			}
			function getChildWidth(children) {
				var first = angular.element(children[0]);
				var last = angular.element(children[children.length - 1]);
				var rc1 = first[0].getBoundingClientRect();
				var rc2 = last[0].getBoundingClientRect();
				//console.log("getChildWidth: " + (rc2.right - rc1.left));

				return rc2.right - rc1.left;
			}
		};
	}]);

    /**
     * Use with dfxPrioritycontainer to trigger calculation after ng-repeat finishes.
     */
	app.directive('dfxPrioritycontainerRepeat', function () {
	    return function (scope, element, attrs) {
	        if (scope.$last) {
	            scope.$emit('LastElemInPCR');
	        }
	    };
	})

	

	/**
	 * Simplified from Angular uibBtnRadio directive. The difference is that with dfxBtnRadioWithUndefined all buttons can be unselected (then undefinedValue/null is set to model).
	 */
	app.directive('dfxBtnRadioWithUndefined', ['$parse', function ($parse) {
		return {
			require: ['dfxBtnRadioWithUndefined', 'ngModel'],
			controller: 'UibButtonsController',
			controllerAs: 'buttons',
			link: function(scope, element, attrs, ctrls) {
				var buttonsCtrl = ctrls[0], ngModelCtrl = ctrls[1];

				//model -> UI
				ngModelCtrl.$render = function() {
					element.toggleClass(buttonsCtrl.activeClass, angular.equals(ngModelCtrl.$modelValue, scope.$eval(attrs.dfxBtnRadioWithUndefined)));
				};

				//ui->model
				element.on(buttonsCtrl.toggleEvent, function() {
					var isActive = element.hasClass(buttonsCtrl.activeClass);

					scope.$apply(function () {
						ngModelCtrl.$setViewValue(isActive ? (attrs.undefinedValue === undefined ? null : scope.$eval(attrs.undefinedValue)) : scope.$eval(attrs.dfxBtnRadioWithUndefined));
						ngModelCtrl.$render();
					});
				});
			}
		};
	}])

	app.directive("draggableItem", function () {
		return {
			scope: {
				dragItem: '=?',
				notAllowed: '=?',
				onDragStartCallback: '&?'
			},
			link: function (scope, elem, attr) {
				elem[0].ondragstart = function (event) {
					if (!scope.notAllowed) {
						elem[0].classList.add('dfx-drag');
						scope.onDragStartCallback({ dragItem: scope.dragItem });
					}
				};
				elem[0].ondragend = function (event) {
					if (!scope.notAllowed) {
						elem[0].classList.remove('dfx-drag');
					}
				};
			}
		};
	});

	app.directive("draggableContainer", function () {
		return {
			scope: {
				list: '=?',
				dropItem: '=?',
				onDragEndCallback: '&?'
			},
			link: function (scope, elem, attr) {
				elem[0].ondrop = function (event) {
					this.classList.remove('dfx-drag-over');
					scope.onDragEndCallback({ dropItem: scope.dropItem, list: scope.list });
				};
				elem[0].ondragover = function (event) {
					this.classList.add('dfx-drag-over');
					event.preventDefault();
				};
				elem[0].ondragleave = function (event) {
					this.classList.remove('dfx-drag-over');
					event.preventDefault();
				};
			}
		};
	});

	app.directive('ngIndeterminate', function ($compile) {
		return {
			restrict: 'A',
			link: function (scope, element, attributes) {
				scope.$watch(attributes['ngIndeterminate'], function (value) {
					element.prop('indeterminate', !!value);
				});
			}
		};
	});

export default app
