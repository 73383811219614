
    'use strict';
    var controllerId = 'textctrl';

	/**
	 * @class LeanPortalSPA.directives.dfxText
     * @memberOf LeanPortalSPA.directives
	 * @description Lean text object directive.
	 *
	 * @param {Object[]} textParent - Array of objects with objectType, recId and name properties
	 * @param {string} textParent[].objectType - Object type
	 * @param {number} textParent[].recId - Object recid
	 * @param {string} textParent[].name - Object name
	 * @param {Object} parent - Single object with objectType, recId and name properties <=> no title header shown
	 * @param {string} parent.objectType - Object type
	 * @param {number} parent.recId - Object recid
	 * @param {string} parent.name - Object name
	 * @param {function} onContentChangedCallback
	 * @param {object} onContentChangedCallback.object
	 * @param onContentChangedCallback.childCtrl
	 * @param {function} onControllerCallback
	 * @param {number} onControllerCallback.count
	 * @param {function} onTextDeletedCallback
	 * @param {object} onTextDeletedCallback.object
	 * @param {string} textDefaultsEnumKey - TEXT_DEFAULTS enumeration key (value01). If not found, portal id is used as key. Enumeration is used for form id (access rights, column visibility, restrictions) and sort order.
	 */
	var textModule = angular.module('textdir.dfxText',[])
	textModule.directive('dfxText', [function () {
    	return {
    		restrict: 'E',
    		scope: {},
    		controller: controllerId,
    		controllerAs: 'ctrl',
    		bindToController: {
    			// array of objects with objectType, recId and name properties
    			textParents: '=',
    			// single object with objectType, recId and name properties <=> no title heaser shown
    			parent: '=',
    			onContentChangedCallback: '&',
				onControllerCallback: '&',
				onTextDeletedCallback: '&', //Callback that occurs when dfx-save-text directive deletes text.
    			plainText: '=?',
				externalTexts: '=?',
				filterByTextLanguage: '=?',
				textDefaultsEnumKey: '=?',
				textMode: '=?'
    		},
    		template: require('../texttable.html')
    	}
    }]);

	
	textModule.controller(controllerId, ['$scope', 'commonDatacontext','common', text]);

	/**
	 * Controller for dfxText directive.
	 * @function Controller
	 * @param $scope
	 * @param commonDatacontext
	 * @memberOf LeanPortalSPA.directives.dfxText
	 */
    function text($scope, commonDatacontext, common) {
    	var ctrl = this;
        ctrl.contentAvailable = false;
        // objects: recId, name
        // toKey: objectType, recid => texts
        ctrl.objects = [];
	    ctrl.textObjects = [];
	    ctrl.recIdToTexts = [];
	    ctrl.rowLayoutEnum = { 
	    	TIGHT: 0, 
	    	EDITABLE: 1
	    	/*, 
	    	INFO: 2, 
	    	INFOEDITABLE: 3 */
	    };
	    ctrl.rowLayout = ctrl.rowLayoutEnum.TIGHT;
		ctrl.formId = 25109;
        // API offered for the client with onControllerCallback
        var service = {
			exitEditMode: exitEditMode, // Obs. doesn't change (save/discard) anything!
			activate: activate
        };

        ctrl.$onInit = function () {
			ctrl.filterByTextLanguage = ($scope.ctrl.filterByTextLanguage || $scope.filterByTextLanguage == false) ? $scope.ctrl.filterByTextLanguage : false;
			ctrl.textDefaultsEnumKey === undefined ? ctrl.textDefaultsEnumKey = common.$route.current.portalId : null;
            ctrl.onControllerCallback({ object: ctrl.parent, childCtrl: service });
            activate();
        };

        function toKey(objectType, recId) {
            return objectType + recId;
        }

        /**
         * ctrl.objects contains Array of objects with objectType, recId and name
		 * @function 'WatchCollection for ctrl.textParents'
		 * @memberOf LeanPortalSPA.directives.dfxText
         */
        $scope.$watchCollection('ctrl.textParents', function (parents) {
        	// duplicate original array
        	if (ctrl.textParents) {
        		ctrl.objects = ctrl.textParents.slice(0);
        		ctrl.textObjects = [];
        		getTexts();
        	}
        });
    	/**
		 * @function 'Watch for ctrl.Parent'
		 * @memberOf LeanPortalSPA.dfxText
         */
        $scope.$watch('ctrl.parent', function (parent) {
        	if (ctrl.parent){
        		ctrl.objects = [ctrl.parent];
        		ctrl.textObjects = [];
        		getTexts();
        	}
        });

    	/**
         * Checks if given object is contained in the ctrl.textObjects array.
		 * @param {string} objectType
		 * @param {number} objectRecid
		 * @return {boolean}
		 * @memberOf LeanPortalSPA.directives.dfxText
         */
        function isTextListMember(objectType, objectRecid) {
	        var retVal = false;
        	ctrl.textObjects.forEach(function (listItem) {
        		if (listItem.objectType == objectType && listItem.objectRecid == objectRecid) {
        			retVal = true;
        		}
        	});
        	return retVal;
        };

        /**
         * Array of names indexed by recid
		 * @memberOf LeanPortalSPA.directives.dfxText
         */
        function collectTexts() {
            angular.forEach(ctrl.objects, function (object) {
                var dataParents = common.getCommonDataParents('Text', object);
                angular.forEach(dataParents, function (textParent) {
                    var arr = getArray(textParent.objectType, textParent.recId);
                    if (arr.length > 0 && !isTextListMember(textParent.objectType, textParent.recId)) {
                        if (ctrl.externalTexts != true) {
                            ctrl.textObjects.push({
                                objectType: textParent.objectType,
                                objectRecid: textParent.recId,
                                name: textParent.name,
                                texts: arr
                            });
                        }
                        else if (ctrl.externalTexts == true) {
                            var extArr = [];
                            arr.forEach(function (object, index) {
                                if (object.usePrint == "ALL" || object.usePrint == "EXT") {
                                    extArr.push(arr[index]);
                                }
                            });
                            ctrl.textObjects.push({
                                objectType: textParent.objectType,
                                objectRecid: textParent.recId,
                                name: textParent.name,
                                texts: extArr
                            });
                        }
                    }
                });
            });
            if (ctrl.parent && ctrl.textObjects) {
                // Make parent texts appear first, then by owners name?
                ctrl.textObjects.sort(function (cmp1, cmp2) {
                    if (ctrl.parent.objRecId().objectType === cmp1.objectType && ctrl.parent.objRecId().recId === cmp1.recId) {
                        return -1;
                    }
                    else if (ctrl.parent.objRecId().objectType === cmp2.objectType && ctrl.parent.objRecId().recId === cmp2.recId) {
                        return 1;
                    }
                    return cmp1.name < cmp2.name ? -1 : cmp1.name > cmp2.name ? 1 : 0;
                });
            }
            ctrl.contentAvailable = getTextCount() > 0;
	        ctrl.onContentChangedCallback({ count: getTextCount() });
        }

    	/**
		 * API to close all rows from outside the directive UI
		 * @memberOf LeanPortalSPA.directives.dfxText
		 */
        function exitEditMode() {
        	ctrl.textObjects.forEach(function (obj) {
        		if (obj.texts){
        			obj.texts.forEach(function (text) {
        				if (text.rowLayout == ctrl.rowLayoutEnum.EDITABLE) {
        					ctrl.toggleRowLayout(text);
        				}
        			});
        		}
        	});
        }
        
        /**
         * Verifies that recId => Text array is valid
		 * @memberOf LeanPortalSPA.directives.dfxText
         */
        function getArray(objectType, recId) {
            var key = toKey(objectType, recId);
            var arr = ctrl.recIdToTexts[key];
            if (arr == null) {
                arr = [];
                ctrl.recIdToTexts[key] = arr;
            }
            return arr;
        }

        /**
         * Gets text rows for objects. 
		 * @memberOf LeanPortalSPA.directives.dfxText
         */
        function getTexts() {
            // collect recids tahat are not read yet
            var missingTexts = [];
            angular.forEach(ctrl.objects, function (object) {
                var dataParents = common.getCommonDataParents('Text', object);
                angular.forEach(dataParents, function (textParent) {
                    var key = toKey(textParent.objectType, textParent.recId);
                    var texts = ctrl.recIdToTexts[key];
                    if (texts == null) {
                        missingTexts.push(textParent);
                        ctrl.recIdToTexts[key] = [];
                    }
                });
            });
            // All are already in memory <=> just collect them
            if (missingTexts.length == 0) {
                collectTexts();
                return;
            }

			commonDatacontext.getTexts(missingTexts, null, null, null, null, ctrl.filterByTextLanguage, ctrl.textDefaultsEnumKey).then(function (data) {
                // set texts to the recid indexed array
                angular.forEach(data, function (text) {
                    getArray(text.objectType, text.objectRecId).push(text);
                });
                collectTexts();
            });
        }

		/**
		 * Cancels changes made to given element.
		 * @param {string} text - Text element whose changes will be canceled.
		 * @memberOf LeanPortalSPA.directives.dfxText
		 */
        ctrl.cancelChanges = function (text) {
        	commonDatacontext.rejectChanges(text);
	        text.isFaulty = false;
		    ctrl.toggleRowLayout(text);
	    }

	    /**
		 * Return how many text rows exist in the text array.
		 * @returns {number} Number of text rows.
		 * @memberOf LeanPortalSPA.directives.dfxText
		 */
        function getTextCount() {
        	var retVal = 0;
        	if (ctrl.textObjects.length > 0) {
        		ctrl.textObjects.forEach(function (obj) {
        			retVal += (obj.texts ? obj.texts.length : 0);
        		});
	        }
	        return retVal;
        }

    	/**
		 * Saves changes made to given element.
		 * @param {string} text - Text element whose changes will be saved.
		 * @memberOf LeanPortalSPA.directives.dfxText
		 */
        ctrl.saveChanges = function (text) {
	        commonDatacontext.saveChanges(text).then(
		        function(saveResult) {
		        	text.isFaulty = false;
		        	ctrl.toggleRowLayout(text);
		        },
		        function (error) {
			        text.isFaulty = true;
		        });
        }

    	/**
		 * Defines if the sub headers are shown. 
		 * Currently defined by checking if single parent is given as input
		 * @memberOf LeanPortalSPA.directives.dfxText
		 */
        ctrl.showObjHeaders = function () {
        	return !ctrl.parent;
        }

		/**
		 * Toggles row layout to next layout.
		 * @param {string} text - Text element whose layout will be toggled.
		 * @memberOf LeanPortalSPA.directives.dfxText
		 */
        ctrl.toggleRowLayout = function (text) {
			// If not set before, set it to tight.
			if (!text.rowLayout) {
				text.rowLayout = ctrl.rowLayoutEnum.TIGHT;
			}

	        if (text.rowLayout == ctrl.rowLayoutEnum.TIGHT) {
	        	text.rowLayout = ctrl.rowLayoutEnum.EDITABLE;
	        }
	        else if (text.rowLayout == ctrl.rowLayoutEnum.EDITABLE) {
	        	text.rowLayout = ctrl.rowLayoutEnum.TIGHT;
	        }
        }

        $scope.$on('COMPANYOPUCHANGES', function () {
            activate();
        });

    	/**
		 * Occurs while file loaded.
		 * @memberOf LeanPortalSPA.directives.dfxText
		 */
        function activate() {
            // reset when UI
        	ctrl.recIdToTexts = [];
			ctrl.textObjects = [];
			
			var paramArray = [];
			paramArray.push(ctrl.textDefaultsEnumKey);
			commonDatacontext.getEnumFrom('TEXT_DEFAULTS_001', JSON.stringify(paramArray)).then(function (data) {
				if (data && data.length > 0 && data[0].columns && data[0].columns.VALUE30) {
					ctrl.formId = data[0].columns.VALUE30;
				}
				getTexts();
			});
		}
    }

	// Directive containing texts for one object
	// If texts are shown for multiple objects, then the UI contains collapsable panel as separator 
    textModule.directive('dfxObjectTexts', [function () {
    	return {
    		restrict: 'E',
    		scope: {},
    		controller: function () {
                /* dummy controller <=> all operations are handled as callbacks*/
			}, 
    		controllerAs: 'ctrl',
    		bindToController: {
                textParent: '=',
                formId: '=',
    			onClickedCallback: '&',
    			onSaveCallback: '&',
    			onCancelCallback: '&',
    			onDeleteCallback: '&'
    		},
    		template: require('./dfxObjectTexts.html')
    	}
    }]);

	// Directive containing texts for one object
	// If texts are shown for multiple objects, then the UI contains collapsable panel as separator 
    textModule.directive('dfxPlainText', [function () {
    	return {
    		restrict: 'E',
    		scope: {},
    		controllerAs: 'ctrl',
    		bindToController: {
				textParent: '=',
				textMode: '=?',
				onTextDeletedCallback: '&',
			}, controller: ['usersettings', 'common', function (usersettings, common) {
				var ctrl = this;
				ctrl.canEdit = false;
				ctrl.$onInit = function () {
					usersettings.getPortals().then(function (data) {
						if (data) {
							var modules = common.getModulesForCurrentTab(data);
							angular.forEach(modules, function (module) {
								if (module.component === 'TEXTS') {
									ctrl.canEdit = module.canEdit;
								}
							});
						}
					});
				};
			}],
    		template: require('./dfxPlainText.html')
    	}
}]);
export default textModule


