
    'use strict';

    var loggerModule= angular.module('common').factory('logger', ['$log', logger]);

    function logger($log) {
        var service = {
            getLogFn: getLogFn,
            log: log,
            logError: logError,
            logSuccess: logSuccess,
            logWarning: logWarning,
            setDebugMode: setDebugMode,
            getDebugMode: getDebugMode,
            getLogList: getLogList,
            clearLogList: clearLogList
        };
        var debug = false;
        var logList = [];
        return service;

        function getLogFn(moduleId, fnName) {
            fnName = fnName || 'log';
            switch (fnName.toLowerCase()) { // convert aliases
                case 'success':
                    fnName = 'logSuccess'; break;
                case 'error':
                    fnName = 'logError'; break;
                case 'warn':
                    fnName = 'logWarning'; break;
                case 'warning':
                    fnName = 'logWarning'; break;
            }

            var logFn = service[fnName] || service.log;
            return function (msg, data, showToast) {
                logFn(msg, data, moduleId, (showToast === undefined) ? true : showToast);
            };
        }

        function log(message, data, source, showToast) {
            logIt(message, data, source, showToast, 'info');
        }

        function logWarning(message, data, source, showToast) {
            logIt(message, data, source, showToast, 'warning');
        }

        function logSuccess(message, data, source, showToast) {
            logIt(message, data, source, showToast, 'success');
        }

        function logError(message, data, source, showToast) {
            logIt(message, data, source, showToast, 'error');
        }

        function logIt(message, data, source, showToast, toastType) {
            var write = (toastType === 'error') ? $log.error : $log.log;
            source = source ? '[' + source + '] ' : '';
			if (debug || toastType === 'error' || toastType === 'warning') write(source, message, data);
			if (showToast) {
				var toasterPosition = 'toast-without-footer';
				var footerHeightId = angular.element(document.querySelector('#dfx-footer'));
				if (footerHeightId && footerHeightId.height() > 0) {
					toasterPosition = 'toast-over-footer';
				}
            	if (toastType === 'error') {
					toastr.error(message, "", { timeOut: 10000, closeButton: true, closeHtml: '<div class="m-alert__close"><i class="fas fa-times m-alert__button"></i></div>', containerId: 'toast', positionClass: toasterPosition, toastClass: 'm-alert m-alert__content-flex', iconClass: 'toast-icon', messageClass: 'm-alert__content' });
            		logList.push(message);
            	} else if (toastType === 'warning') {
					toastr.warning(message, "", { timeOut: 10000, closeButton: true, closeHtml: '<div class="m-alert__close"><i class="fas fa-times m-alert__button"></i></div>', containerId: 'toast', positionClass: toasterPosition, toastClass: 'm-alert warning m-alert__content-flex', iconClass: 'toast-icon', messageClass: 'm-alert__content' });
            	} else if (toastType === 'success') {
					toastr.success(message, "", { closeButton: true, closeHtml: '<div class="m-alert__close"><i class="fas fa-times m-alert__button"></i></div>', containerId: 'toast', positionClass: toasterPosition, toastClass: 'm-alert info m-alert__content-flex', iconClass: 'toast-icon', messageClass: 'm-alert__content' });
            	} else if (debug) {
					toastr.info(message, "", { closeButton: true, closeHtml: '<div class="m-alert__close"><i class="fas fa-times m-alert__button"></i></div>', containerId: 'toast', positionClass: toasterPosition, toastClass: 'm-alert notice m-alert__content-flex', iconClass: 'toast-icon', messageClass: 'm-alert__content' });
            	}
            }
        }
        function setDebugMode(mode) {
            if (mode) {
                debug = true;
            }
            else {
                debug = false;
            }
        }
        function getDebugMode() {
            if (debug) {
                return true;
            }
            else {
                return false;
            }
        }
        function getLogList() {
            return logList;
        }
        function clearLogList() {
            logList = [];
        }
    }
export default loggerModule