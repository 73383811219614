/**
 * Directive providing status change buttons for ResourceTask
 */
var statusChangeCtrlId = 'statuschangectrl';
var statusChangeModule=angular.module('manudir.dfxTaskStatusChange',[])
statusChangeModule.directive('dfxTaskStatusChange', function () {
	// Provides buttons for status change
	// Usage:
	// <td class="col-md-2" dfx-task-status-change task="ctrl.currentTask" on-status-change-callback="ctrl.taskStatusChanged(task)" />
	return {
		restrict: 'EA', //E = element, A = attribute, C = class, M = comment 
		scope: {},
		bindToController: {
            task: '=',
            layout: '=?', // BUTTON (default) or LIST.
			stacked: '=?',
			showTitles: '=?',
			resource: '=?', // Makes resource change possible
			onStatusChangedCallback: '&',
			onBeforeStatusChangedCallback: '&?', // If defined the callback must return true for status change to take place
            showMask: '=?'  // maskBits: 1: start; 2: finish; 4: abort; 8: continue; 16: packing
		},
		controller: statusChangeCtrlId,
		controllerAs: 'ctrl',
		template: require('./dfxTaskStatusChange.html')
	}
});
statusChangeModule.controller(statusChangeCtrlId, ['$scope', 'manDatacontext', 'commonDatacontext', 'common', 'packing_service', 'usersettings', '$uibModal', statuschange]);
function statuschange($scope, manDatacontext, commonDatacontext, common, packing_service, usersettings, $uibModal) {
    var ctrl = this;

    ctrl.$onInit = function () {
        //FormId of the TaskStatusChange form
        ctrl.formId = 25230;
		getFormSettings();
		ctrl.wrFormId = 25116;
		ctrl.dfxGridOptionsWr = { columnDefs: [], enableFiltering: false };
		ctrl.onWrGridApi = function (gridApi) {
			ctrl.dfxGridOptionsWr.data = ctrl.wrGridData;
		}
		ctrl.wrGridData = null;
        ctrl.dfxLabelPrintingParams = {
            application: 'MANUF_PORTAL',
            function: 'OPER_COMPLETE',
            subFunction: '',
            labelId: '',
            objectId1: ctrl.resource ? ctrl.resource.resId : '',
            objectId2: ctrl.task ? ctrl.task.workId : '',
            objectId3: ctrl.task ? ctrl.task.operatId : '',
            objectId4: ''
        };
        if (typeof ctrl.stacked === 'undefined' || ctrl.stacked === null) {
            ctrl.stacked = true;
        }
        // Some text constants
		common.$translate(['STRCONST.PUBLIC.BTN_ABORT', 'STRCONST.PUBLIC.BTN_CONTINUE', 'STRCONST.LEANPORTAL.BTN_WIZFINISH', 'STRCONST.LEANPORTAL.BTN_WIZSTART', 'STRCONST.WOR.TXT_MOVE_TO_RSRC_AND_START', 'STRCONST.LEANPORTAL.TXT_PACKING']).then(function (translations) {
            $scope.ctrl.abortText = translations['STRCONST.PUBLIC.BTN_ABORT'];
            $scope.ctrl.continueText = translations['STRCONST.PUBLIC.BTN_CONTINUE'];
            $scope.ctrl.finishText = translations['STRCONST.LEANPORTAL.BTN_WIZFINISH'];
			$scope.ctrl.packingText = translations['STRCONST.LEANPORTAL.TXT_PACKING'];
            $scope.ctrl.startText = ctrl.task && ctrl.resource && ctrl.resource.resId != ctrl.task.resId ? translations['STRCONST.WOR.TXT_MOVE_TO_RSRC_AND_START'] : translations['STRCONST.LEANPORTAL.BTN_WIZSTART'];
        });

        $scope.showTitles = typeof ctrl.showTitles === 'undefined' ? false : ctrl.showTitles;
        $scope.$watchGroup(['ctrl.resource', 'ctrl.task'], function (newValue) {
            ctrl.startText = ctrl.task && ctrl.resource && ctrl.resource.resId != ctrl.task.resId ? common.$translate.instant("STRCONST.WOR.TXT_MOVE_TO_RSRC_AND_START") : common.$translate.instant("STRCONST.LEANPORTAL.BTN_WIZSTART");
            ctrl.dfxLabelPrintingParams.objectId1 = ctrl.resource ? ctrl.resource.resId : ctrl.task ? ctrl.task.resId : '';
            ctrl.dfxLabelPrintingParams.objectId2 = ctrl.task ? ctrl.task.workId : '';
            ctrl.dfxLabelPrintingParams.objectId3 = ctrl.task ? ctrl.task.operatId : '';
        });
    };

	// Meaningful only when time stamps are used: Apply is enabled even when user has not logged in
	ctrl.allowOperWithoutUserLogin = true;
	ctrl.disableApplyButton = false;
	ctrl.enableTimeStamps = true;

	ctrl.reasonTypes = null;
	ctrl.operTypes = [];

    // variables storing information about the operation completion, all retrieved from MANUFPORTAL_DEFAULTS.RESOURCETASKLIST value08, value11-14 enumerations.
	// value08
	ctrl.useOperTypeInWorkCompletion = false;
	//value11
    ctrl.amountsAreShown = false;
    //value13
    ctrl.dialogRejectedAmountShown = false;
    //value12
    ctrl.partlyCompletionEnabled = false;
    //value12 == 2
    ctrl.partlyCompletionAsDefault = false
    //value14
    ctrl.partlyCompletionInteruptEnabled = false;
    //value15
    ctrl.dialogAmountsAreShown = false;
    //value15 == 2
    ctrl.plannedAmountAsDefaultCompletedAmount = false;
	//value25 == COMPLETED  <=> false
	ctrl.useDiscardAmountInAutoComplSel = true;

	// Button visibility. Default = all visible.
	$scope.showStart = true;
	$scope.showFinish = true;
	$scope.showAbort = true;
	$scope.showContinue = true;
	$scope.showPacking = true;

	$scope.$watch('ctrl.showMask', function(value){
		checkMask();
	});

	function calculateChosenCompletion() {
		var amount = ctrl.task.qtyActual + (ctrl.useDiscardAmountInAutoComplSel ? ctrl.task.qtyDiscard : 0);
		return (amount >= ctrl.task.qty) ? "fullCompletion" : (amount < ctrl.task.qty && ctrl.partlyCompletionEnabled) ? "partlyCompletion" : "fullCompletion";
	}

	function checkMask() {
		if (ctrl.showMask) {
			$scope.showStart = (ctrl.showMask & 1) > 0;
			$scope.showFinish = (ctrl.showMask & 2) > 0;
			$scope.showAbort = (ctrl.showMask & 4) > 0;
			$scope.showContinue = (ctrl.showMask & 8) > 0;
			$scope.showPacking = (ctrl.showMask & 16) > 0;
		}
    }
    function getFormSettings() {
        var promises = [];
        ctrl.form = null;
        ctrl.formMode = 'insertEdit';
        ctrl.modelColumnMap = [];
        promises.push(commonDatacontext.getFormSettings(ctrl.formId).then(function (data) {
            if (data) {
                ctrl.dialogFormVersions = data.formVersions;
            }
            return data;
        }));
        promises.push(commonDatacontext.getModelColumnMap('ResourceTask').then(function (columnMap) {
            if (columnMap) {
                ctrl.modelColumnMap = columnMap;
            }
            return columnMap;
        }));

        return promises;
    }

    //We sort the DialogFormVersions so the columns are ordered according to index (In which order they are added to the formVersion)
    function sortFormVersionColumns(formVersion) {
        ctrl.selectedDialogFormVersion = [];
        angular.forEach(formVersion, function (col) {
            ctrl.selectedDialogFormVersion.push(col);
        });
        ctrl.selectedDialogFormVersion.sort(function (a, b) {
            var index1 = a.Index ? a.Index : 0;
            var index2 = b.Index ? b.Index : 0;
            return index1 < index2 ? -1 : index1 > index2 ? 1 : 0;
        });
    }

    //This function keeps track of the values added to the amount. It calculates the new qtyActual and qtyDiscard according to the added value.
    ctrl.changeAmounts = function (column) {
        ctrl.partlyCompletionAsDefault = false;
        switch (column) {
            case 'QTY_ACTUAL_ADDED':
                ctrl.task[ctrl.modelColumnMap['QTY_ACTUAL']] = ctrl.task[ctrl.modelColumnMap['QTY_ACTUAL']] - ctrl.tempActualAddedValue + ctrl.task[ctrl.modelColumnMap[column]];
                //we don't want the actual value to go under 0
                ctrl.task[ctrl.modelColumnMap['QTY_ACTUAL']] < 0 ? ctrl.task[ctrl.modelColumnMap['QTY_ACTUAL']] = 0 : null;
                ctrl.tempActualAddedValue = ctrl.task[ctrl.modelColumnMap[column]];
                ctrl.amountsAltered = true;
                break;
            case 'QTY_DISCARD_ADDED':
                ctrl.task[ctrl.modelColumnMap['QTY_DISCARD']] = ctrl.task[ctrl.modelColumnMap['QTY_DISCARD']] - ctrl.tempDiscardAddedValue + ctrl.task[ctrl.modelColumnMap[column]];
                //we don't want the actual value to go under 0
                ctrl.task[ctrl.modelColumnMap['QTY_DISCARD']] < 0 ? ctrl.task[ctrl.modelColumnMap['QTY_DISCARD']] = 0 : null;
                ctrl.tempDiscardAddedValue = ctrl.task[ctrl.modelColumnMap[column]];
                ctrl.amountsAltered = true;
                break;
		}
		ctrl.chosenCompletion = calculateChosenCompletion();
    }

    //This function checks and calculates which completion checkbox is checked at a specific time.
    ctrl.checkBoxChecker = function () {
        if (ctrl.amountsAltered) {
			var amount = ctrl.task.qtyActual + (ctrl.useDiscardAmountInAutoComplSel ? ctrl.task.qtyDiscard : 0);
			if (amount >= ctrl.task.qty) {
                if (ctrl.partlyCompletionAsDefault && ctrl.defaultCheckboxValuesApplied) {
                    ctrl.chosenCompletion = 'partlyCompletion';
                    return 'partlyCompletion';
                }
                else {
                    if (ctrl.interuptedCompletionChecked) {
                        ctrl.chosenCompletion = 'partlyCompletionInterupt';
                        return null;
                    }
                    else {
                        ctrl.chosenCompletion = 'fullCompletion';
                        return 'fullCompletion';
                    }
                }
            }
			else if (amount < ctrl.task.qty) {
                if (ctrl.interuptedCompletionChecked) {
                    ctrl.chosenCompletion = 'partlyCompletionInterupt';
                    return null;
                }
                else {
                    ctrl.chosenCompletion = 'partlyCompletion';
                    return 'partlyCompletion';
                }
            }
            ctrl.chosenCompletion = 'fullCompletion';
            return 'fullCompletion';
        }
        return null;
    }

    //Assigns the completion that is chosen
    ctrl.chooseCompletion = function (chosenCompletion) {
        ctrl.interuptedCompletionChecked = chosenCompletion == 'partlyCompletionInterupt' ? true : false;
        ctrl.defaultCheckboxValuesApplied = false;
        ctrl.chosenCompletion = chosenCompletion;
        ctrl.amountsAltered = false;
    }

	/**
	 * Initialize lookups if needed and open dialog
	 */
    ctrl.clicked = function (status) {
        //We add defaul values to the freshly opened dialog.
        ctrl.chosenCompletion = null;
        ctrl.tempActualAddedValue = 0;
        ctrl.tempDiscardAddedValue = 0;
        ctrl.task.qtyActualAdded = 0;
        ctrl.task.qtyDiscardAdded = 0;
        ctrl.interuptedCompletionChecked = false;
        //Checks if the task amounts have been altered. This variable keeps track on whether to use automatic checkbox altering or not.
        ctrl.amountsAltered = true;
        //default value for the checkboxes are retrieved when the completion dialog is opened. 
        //When amount values are altered and the completion checkboxes check / uncheck themselves, this value is turned to false.
        ctrl.defaultCheckboxValuesApplied = true;
        // Choosing the right formVersion for the clicked dialog.
        switch (status) {
            case 'ACT':
                ctrl.dialogFormVersions.SELECT_START_DIALOG ? sortFormVersionColumns(ctrl.dialogFormVersions.SELECT_START_DIALOG) : null;
                break;
            case 'INTER':
                ctrl.dialogFormVersions.SELECT_SUSPEND_DIALOG ? sortFormVersionColumns(ctrl.dialogFormVersions.SELECT_SUSPEND_DIALOG) : null;
                break;
            case 'FIN':
                ctrl.dialogFormVersions.SELECT_FINISH_DIALOG ? sortFormVersionColumns(ctrl.dialogFormVersions.SELECT_FINISH_DIALOG) : null;
                break;
		}

		// If onBeforeStatusChangedCallback is defined, the callback must return undefine or true for status change to take place. 
		if (ctrl.onBeforeStatusChangedCallback) {
			common.$q.when(ctrl.onBeforeStatusChangedCallback({ task: ctrl.task, status: status })).then(function (canProceed) {
				if (typeof canProceed === 'undefined' || canProceed) {
                    changeStatus(status);
				}
			});
		}
		else {
            changeStatus(status);
		}
	}

	/**
	 * Initialize lookups if needed and open dialog
	 */
	var changeStatus = function (status) {
		initLookups(status).then(function () { openDialog(); });

		function openDialog() {
			// TIM options are available only when starting the phase
			ctrl.workReportLayout = status === 'ACT' ? 'START' : null;
			if (status === 'ACT') {
				ctrl.nextStatus = common.$translate.instant("STRCONST.LEANPORTAL.BTN_WIZSTARTED");
			} else if (status === 'FIN') {
                ctrl.nextStatus = common.$translate.instant("STRCONST.LEANPORTAL.BTN_WIZFINISH");
			} else if (status === 'INTER') {
				ctrl.nextStatus = common.$translate.instant("STRCONST.PUBLIC.TXT_ABORTED");
			}
            ctrl.isStarted = status === 'ACT';
            ctrl.isFinnish = status === 'FIN';
            // Some data is shown only when task is interrupted
            ctrl.isInterrupt = status === 'INTER';
            ctrl.isContinue = ctrl.task.status === 'INTER';
			var lastUserInfo = usersettings.getLastUserInfo();
			// Create WorkReport only if it is needed
            ctrl.workReport = ctrl.workReportLayout && ctrl.enableTimeStamps ? createWorkReportEntity() : null;
            setDefaultValues();
            ctrl.form = new commonDatacontext.DfxForm(ctrl.task, ctrl.selectedDialogFormVersion, ctrl.modelColumnMap, ctrl.formMode);

			var modalInstance = $uibModal.open({
				animation: false,
				backdrop: false,
				template: require('./modalUserControl.html'),
                controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
					$scope.onApply = function () {
						if (ctrl.workReport) {
							usersettings.setLastUserInfo(ctrl.workReport.empId, ctrl.workReport.empName);
							ctrl.task.empId = ctrl.workReport.empId;
							ctrl.task.empName = ctrl.workReport.empName;
						}
						// Fill employee data to task only if it is shown
						else if (!ctrl.isSeparateEmployeeSelectionVisible()) {
							ctrl.task.empId = null;
						}

                        ctrl.task.prevStatus = ctrl.task.status;
                        if (status == 'FIN') {
                            //We choose the correct anOption for the completion
                            if (!ctrl.chosenCompletion) {
								ctrl.task.chosenCompletion = calculateChosenCompletion();
                            }
                            else {
                                switch (ctrl.chosenCompletion) {
                                    case 'fullCompletion':
                                        ctrl.task.chosenCompletion = "fullCompletion";
                                        break;
                                    case 'partlyCompletion':
                                        ctrl.task.chosenCompletion = "partlyCompletion";
                                        break;
                                    case 'partlyCompletionInterupt':
                                        ctrl.task.chosenCompletion = "partlyCompletionInterupt";
                                        break;
                                    default:
                                        ctrl.task.chosenCompletion = "fullCompletion";
                                }
                            }
                            //If partly completion isn't enabled, the completion is always considered as full.
                            if (!ctrl.partlyCompletionEnabled) {
                                ctrl.task.chosenCompletion = "fullCompletion";
                                //if the completed and planned amounts are not visible we expect that the amount that is finished is the difference between the planned amount and the discarded amount.
                                //We always substract the discarded amount whether it is visible or not (value13) since we can't know if there has been amounts discarded through lean client.
                                //Note: If the completed amount is greater than the planned amount, the completed amount won't be altered.
                                if (!ctrl.dialogAmountsAreShown && ctrl.task.qtyActual < ctrl.task.qty) {
									if (ctrl.useDiscardAmountInAutoComplSel)
										ctrl.task.qtyActual = ctrl.task.qty - ctrl.task.qtyDiscard < 0 ? 0 : ctrl.task.qty - ctrl.task.qtyDiscard;
									else
										ctrl.task.qtyActual = ctrl.task.qty;
                                }
                            }
                        }
                        ctrl.task.status = status;

						if (ctrl.resource && ctrl.resource.resId != ctrl.task.resId) {
							ctrl.task.resId = ctrl.resource.resId;
						}
						// First save state change <=> resourceTask
						manDatacontext.saveChanges(ctrl.task).then(function (saveResult) {
							// If succeeded and TIM is available(start), then save workReport
							if (ctrl.enableTimeStamps && ctrl.workReportLayout) {
								// Fix datetime so that database understands it correctly.
								ctrl.workReport.startDate = common.adjustTime(ctrl.workReport.startDate, true);
                                manDatacontext.saveChanges(ctrl.workReport).then(commonDatacontext.saveChanges().then(allSavingDone, onFailure));
							}
                            else {
                                allSavingDone(saveResult);
							}
						}, onFailure);
                    };
                    function onFailure(error) {
                        ctrl.task.status = ctrl.task.prevStatus;
                        // no implementation yet
                    }

                    function allSavingDone(saveResult) {
                        $uibModalInstance.close();
                        ctrl.onStatusChangedCallback({ task: ctrl.task });
                        if (ctrl.labelPrinterCtrl) {
                            ctrl.labelPrinterCtrl.printLabelToFile();
                        }
                    }
					$scope.onCancel = function () {
						manDatacontext.rejectChanges(ctrl.workReport);
						// Reject possible changes made to ResourceTask
						ctrl.task.entityAspect.rejectChanges();
						$uibModalInstance.dismiss('cancel');
					};
				}],
				scope: $scope
			});
		};
	}

	/**
	 * Creates WorkReport entity, with initialization from task
	 */
	function createWorkReportEntity() {
		// Create new entity
		var wrEnt = commonDatacontext.commonManager.createEntity('WorkReport', {
			id: breeze.core.getUuid()
		});
		// Lets fill the field we know
		wrEnt.startDate = ctrl.task.startDate ? ctrl.task.startDate : new Date();
		var lastUserInfo = usersettings.getLastUserInfo();
		wrEnt.empId = lastUserInfo ? lastUserInfo.empId : '';
		wrEnt.empName = lastUserInfo ? lastUserInfo.empName : '';
		wrEnt.resId = ctrl.task.resId;
		wrEnt.workId = ctrl.task.workId;
		wrEnt.operatId = ctrl.task.operatId;
		return wrEnt;
    }

	/**
	 * Employee selection changed
	 * @param value 
	 */
	ctrl.empSelected = function (value, skipDisabling) {
		if (value) {
			ctrl.task.empId = value.empId;
			ctrl.task.empName = value.name;
			usersettings.setLastUserInfo(value.empId, value.name);
		}
		else {
			ctrl.task.empId = null;
			ctrl.task.empName = null;
		}
		if (skipDisabling)
			return;
		ctrl.setDisableApplyButton(value ? false : true);
	}

	ctrl.refreshTask = function () {
		manDatacontext.getResourceTask(ctrl.task.workId, ctrl.task.operatId).then(function (data) {
			if (data && data[0]) {
				ctrl.task = data[0];
			}
		});
	};

	ctrl.openPackingModal = function () {
		initLookups().then(function () {
			var empIdRequired = !ctrl.enableTimeStamps && ctrl.operStartEmpRequired;
			packing_service.openPackingModal(ctrl.task, ctrl.refreshTask, ctrl.refreshTask, empIdRequired);
		});
	};

	/**
	 * Disable Apply button if Time Stamps are used
	 */
	ctrl.setDisableApplyButton = function (value) {
		ctrl.disableApplyButton = value;
	}

	function initLookups(status) {
		if (parseInt(common.getAppSetting("showOperTimestampsControl", "0"))) {
			commonDatacontext.getWorkReport(null, null, 75, 'SELECT_EMP_OPERAT', null, null, null, ctrl.task.resId, ctrl.task.workId, ctrl.task.operatId).then(function (data) {
				ctrl.dfxGridOptionsWr.data = data;
				ctrl.wrGridData = data;
			});
		}
		return manDatacontext.getLookups(lang, true).then(function (data) {
			if (data && data[0] && data[0].Enums) {
				ctrl.reasonTypes = data[0].Enums["REASONTYPE"];
				// MANUFPORTAL_DEFAULTS value01 and value02 dictate how time stamp is used
				var defaults = data[0].Enums["MANUFPORTAL_DEFAULTS"];
				var enumItem_TIM = defaults ? common.getEnumRowById(defaults, 'TIM') : null;
				if (enumItem_TIM && enumItem_TIM.extraSelect.length >= 2) {
					ctrl.enableTimeStamps = (enumItem_TIM.extraSelect[0] == 'OPER_TIMESTAMPS');
					ctrl.allowOperWithoutUserLogin = (enumItem_TIM.extraSelect[1] == 'NOT_MANDATORY');
				}
				// If time stamps are not used, but employee is required when starting the operation
				var formDefaults = data[0].Enums["FORM_DEFAULTS"];
				var worOperStart = formDefaults ? common.getEnumRowById(formDefaults, 'WOR_OPERSTART') : null;
				if (worOperStart && worOperStart.extraSelect.length >= 1) {
					ctrl.operStartEmpRequired = !ctrl.enableTimeStamps && (worOperStart.extraSelect[0] == 'EMPID_REQ');
                }
                //These values determine which completions are available and which quantities are enabled. Default values are also retrieved here.
                var enumItem_RESOURCETASKLIST = common.getEnumRowById(data[0].Enums["MANUFPORTAL_DEFAULTS"], 'RESOURCETASKLIST');
                if (enumItem_RESOURCETASKLIST && enumItem_RESOURCETASKLIST.columns && status == 'FIN') {
                    ctrl.dialogRejectedAmountShown = enumItem_RESOURCETASKLIST.columns.VALUE13 == 1 ? true : false;
                    ctrl.partlyCompletionEnabled = enumItem_RESOURCETASKLIST.columns.VALUE12 == 1 || enumItem_RESOURCETASKLIST.columns.VALUE12 == 2 ? true : false;
                    ctrl.partlyCompletionAsDefault = enumItem_RESOURCETASKLIST.columns.VALUE12 == 2 ? true : false;
                    ctrl.partlyCompletionInteruptEnabled = enumItem_RESOURCETASKLIST.columns.VALUE14 == 1 ? true : false;
                    ctrl.useOperTypeInWorkCompletion = enumItem_RESOURCETASKLIST.columns.VALUE08 === 'WORK_COMPLETION_OPERTYPE' ? true : false;
                    ctrl.dialogAmountsAreShown = enumItem_RESOURCETASKLIST.columns.VALUE15 == 1 || enumItem_RESOURCETASKLIST.columns.VALUE15 == 2 ? true : false;
                    ctrl.plannedAmountAsDefaultCompletedAmount = enumItem_RESOURCETASKLIST.columns.VALUE15 == 2 ? true : false;
                    ctrl.task.qtyActualAdded = ctrl.plannedAmountAsDefaultCompletedAmount ? Math.max(0, (ctrl.task.qty - ctrl.task.qtyActual)) : 0;
                    ctrl.task.qtyActual = ctrl.plannedAmountAsDefaultCompletedAmount ? ctrl.task.qtyActual + ctrl.task.qtyActualAdded : ctrl.task.qtyActual;
                    ctrl.tempActualAddedValue = ctrl.plannedAmountAsDefaultCompletedAmount ? ctrl.task.qtyActualAdded : 0;
					ctrl.useDiscardAmountInAutoComplSel = enumItem_RESOURCETASKLIST.columns.VALUE25 === 'COMPLETED' ? false : true;
					if (enumItem_RESOURCETASKLIST.columns.VALUE26 === 'RESET' && ctrl.task) {
						ctrl.task[ctrl.modelColumnMap['QTY_ACTUAL_ADDED']] = null
						ctrl.task[ctrl.modelColumnMap['QTY_DISCARD_ADDED']] = null
					}
                }
				// Get oper types and their completion values.
				ctrl.operTypes = data[0].Enums["OPERTYPE"];
			}
			return data;
		})
	}

	/**
	 * Employee selection can be visible in three ways
	 * 1) Time stamps are in use (dfxWorkReport)
	 * 2) Time stamps are not in use, but employee is required for start oper (FORM_DEFAULTS.WOR_OPERSTART.VALUE01=EMPID_REQ)
	 * 3) Defined visible in form version (implemented for other cases e.g. finish operation)
	 */
	ctrl.isSeparateEmployeeSelectionVisible = function () {
		var isVisible = ctrl.showSeparateEmployeeSelection();
		if (!isVisible) {
			angular.forEach(ctrl.selectedDialogFormVersion, function (col) {
				if (col.ColumnName === 'EMP_ID') {
					isVisible = true;
				}
			});
		}
		return isVisible;
	}

    ctrl.showSeparateEmployeeSelection = function () {
        return (ctrl.isStarted || ctrl.isInterrupt) && !ctrl.enableTimeStamps && ctrl.operStartEmpRequired;
    }

    // Define if label printing is available for status change
    ctrl.showLabelPrinting = function () {
        return ctrl.isFinnish && ctrl.task && ctrl.task.printAvailableInFinish;
    }

	/**
	 * Sets default values for dropdowns.
	 * @returns {} 
	 */
	function setDefaultValues() {
		if (typeof manDatacontext.setDefaultValuesInTaskStatusChange === 'function') manDatacontext.setDefaultValuesInTaskStatusChange(ctrl.task);
		// Initialize task's emplyee data if separate employee selection is shown
		var lastUserInfo = usersettings.getLastUserInfo();
		if (ctrl.showSeparateEmployeeSelection() && lastUserInfo) {
			ctrl.task.empId = lastUserInfo.empId;
			ctrl.task.empName = lastUserInfo.empName;
		}
		if (ctrl.isInterrupt) {
			var reasonTypeDefault = common.getEnumDefValue(ctrl.reasonTypes, false);
			if (reasonTypeDefault) {
				ctrl.task.reasonType = reasonTypeDefault;
			}
		}

		// Show or hide "complete work" check box. Four options:
		// 1) hidden = hide, 2) fullCompletion = show for full completion only, 3) partlyCompletion = show for partial completion and 4) both = show for both full and partial completion.
		ctrl.completeWorkSelectionVisible = 'hidden';
		ctrl.chosenCompletion = calculateChosenCompletion();

		/* Options 2, 3 and 4 are available only when
		 1) new status is finished 
		 2) operation is the last or only one in work (CHAINPLACE column) 
		 3) MANUFPORTAL_DEFAULTS.RESOURCETASKLIST.value08 = WORK_COMPLETION_OPERTYPE and
		 4) OPERTYPE.value21 or value22 = AUTO_ASK. This is used for selecting options 2, 3 and 4 above.
		*/
		if (ctrl.isFinnish && (ctrl.task.chainPlace === 'LAST' || ctrl.task.chainPlace === 'ONLY') && ctrl.useOperTypeInWorkCompletion) {
			var enumRow = common.getEnumRowById(ctrl.operTypes, ctrl.task.operatType);
			if (enumRow && enumRow.columns && (enumRow.columns.VALUE21 === 'AUTO_ASK' || enumRow.columns.VALUE22 === 'AUTO_ASK')) {
				if (enumRow.columns.VALUE21 === 'AUTO_ASK' && enumRow.columns.VALUE22 === 'AUTO_ASK') {
					ctrl.completeWorkSelectionVisible = 'both';
				}
				else if (enumRow.columns.VALUE21 === 'AUTO_ASK') {
					ctrl.completeWorkSelectionVisible = 'fullCompletion';
				}
				else {
					ctrl.completeWorkSelectionVisible = 'partlyCompletion';
				}
			}
		}
	}
}
export default statusChangeModule