'use strict';
var formFooterModule = angular.module('dir.dfxFormFooter',[]);
formFooterModule.directive('dfxFormFooter', function () {
    return {
        restrict: 'E',
        scope: {},
        controllerAs: 'ctrl',
        replace: true,
        bindToController: {
            isReady: '=?', // Promise that should return list of modules that contain formsettings.
            formSettings: '=?', // Formsettings data to be used.
            hasChanges: '&?',
            onCmdCallback: '&'
        },
        controller: ['$scope', 'commonDatacontext', function ($scope, commonDatacontext) {
            // Variables and properties.
            var ctrl = this;
            ctrl.footerCommands = [];

            // Event handler for tool button click.
            ctrl.commandUsed = function (module, commandId) {
                ctrl.onCmdCallback({ module: module, commandId: commandId });
            }

            ctrl.canSave = function (cmd) {
                if (ctrl.hasChanges && cmd.module) {
                    return ctrl.hasChanges({ module: cmd.module });
                } else if (ctrl.hasChanges && cmd.criteria && (cmd.criteria === 'ROWSELECTED' || cmd.criteria === 'SINGLEROWSELECTED')) {
                    return ctrl.hasChanges({ commandId: cmd.commandId });
                } else {
                    // If button has no module / criteria, assume it should be always usable.
                    return true;
                }
            }

            function getButtons(formSettings, module) {
                angular.forEach(formSettings.commands, function (command) {
                    if (command.type == 'BUTTON') {
                        if (module) {
                            ctrl.footerCommands.push({ commandId: command.commandId, title: command.title, module: module });
                        } else {
                            ctrl.footerCommands.push({ commandId: command.commandId, title: command.title, image: command.image, criteria: command.criteria });
                        }
                    }
                });
            }

            ctrl.$onInit = function () {
                if (ctrl.isReady) {
                    ctrl.isReady.promise.then(function (modules) {
                        if (modules.length > 0) {
                            modules.reduce(function (p, module) {
                                return module.isReady.promise.then(function (formSettings) {
                                    getButtons(formSettings, module);
                                });
                            }, 0);
                        }
                    });
                } else if (ctrl.formSettings) {
                    getButtons(ctrl.formSettings);
                }
            };


        }
        ],
        template: require('./dfxFormFooter.html')
    };
});
export default formFooterModule