'use strict';
var collapsablePanelModule = angular.module('dir.dfxCollapsablePanel',[]);
collapsablePanelModule.directive('dfxCollapsablePanel', function () {
    // Collapsable panel (accordion)
    // Usage:
    //  isOpen defines the initial open/close status
    //  panelTitle defines the title shown in the accordion
    // Optional:
    //  count item count
    //  readyCount ready item count
    //  Style classes can be overwritten by
    //  defining customClass
	//  checkBoxValue: Adds checkbox, true/false values.
	//  checkBoxDisabled: is checkbox disabled.
	//  addCommentIcon: Adds icon to add comments from dialog, requires entity
	//  entity: Entity for comments
	// Example:
    // <dfx-collapsable-panel is-open="ctrl.isOpen" panel-title="'@StrConst.GetString("XXX")'">
    // payload here
    // </dfx-collapsable-panel>
    return {
        restrict: 'EA', //E = element, A = attribute, C = class, M = comment 
        scope: {},
        controllerAs: 'ctrl',
        bindToController: {
            isOpen: '=',
            panelTitle: '=',
            count: '=?',
            newCount: '=?',
            readyCount: '=?',
            customClass: '@?',
            checkBoxValue: '=?',
            checkBoxDisabled: '=?',
            addCommentIcon: '=?',
            entity: '=?',
            customHeaderClass: '@?',
			tools: '=?', // id, name, tooltip, location (bottom (default), header)
            onToolClickedCallback: '&',
            onCommentAddedCallback: '&'
        },
        controller: ['$scope', 'common', function ($scope, common) {
			var ctrl = this;
			ctrl.headerTools = [];
			ctrl.bottomTools = [];

            $scope.$watch('ctrl.isOpen', function (value) {
                if (!$scope.showContent && value) {
                    $scope.showContent = true;
                }
			});

			ctrl.$onInit = function () {
				angular.forEach(ctrl.tools, function (tool) {
					if (tool.location && tool.location.toLowerCase() === 'header') {
						ctrl.headerTools.push(tool);
					}
					else {
						ctrl.bottomTools.push(tool);
					}
				});
			};

            ctrl.isCountDefined = function () {
                return typeof ctrl.count !== 'undefined';
            }
            ctrl.isReadyCountDefined = function () {
                return typeof ctrl.readyCount !== 'undefined' && ctrl.readyCount > 0;
            }
            ctrl.checkBoxClicked = function () {
            	if (ctrl.checkBoxDisabled) { return; }
            	ctrl.onToolClickedCallback({ value: !ctrl.checkBoxValue });
            }
            ctrl.commentAdded = function () {
            	ctrl.onCommentAddedCallback();
            }
            ctrl.newIconTitle = function () {
                return common.$translate.instant("STRCONST.PUBLIC.TXT_NO_OF_NEW").replace('%1', ctrl.newCount);
            }
        }],
        link: function ($scope, el, attrs, ctrl, transclude) {
            $scope.showContent = typeof attrs["delayLoading"] !== 'undefined' ? false : true;
        },
        transclude: true,
        template: require('./dfxCollapsablePanel.html')
    }
});
export default collapsablePanelModule