'use strict';
var timeStampsModule = angular.module('dir.dfxTimeStamps',[]);
var timeStampControllerId = 'timeStampController';
timeStampsModule.controller(timeStampControllerId, ['$scope', 'commonDatacontext', timeStamps])
function timeStamps($scope, commonDatacontext) {
    var ctrl = this;
    var oldEmpId = null;
    ctrl.isTsOpen = false;

    $scope.$watch('ctrl.selectedDate', function (newVal, oldVal) {
        if (ctrl.selectedDate && newVal != oldVal) {
            getWorkReports();
        }
    });

    $scope.$watch('ctrl.emp', function (newVal) {
        if (ctrl.emp && newVal.empId != oldEmpId) {
            getWorkReports();
        }
    });

    $scope.$watch('ctrl.isTsOpen', function (newVal) {
        $scope.$emit('TIMESTAMPS_OPEN', newVal);
    });

    $scope.maxRows = function () {
        return new Array(ctrl.max);
    }

    $scope.rowElems = new Array(7);

    // Calculate the work sum of a certain day.
    ctrl.workSum = function (index) {
        var sum = 0;
        angular.forEach(ctrl.workReports, function (wr) {
            if (!wr) return 0;
            if (index == 0) sum += moment.utc(wr.startDate).isoWeekday() == 1 && wr.hoursTotal ? wr.hoursTotal : 0;
            if (index == 1) sum += moment.utc(wr.startDate).isoWeekday() == 2 && wr.hoursTotal ? wr.hoursTotal : 0;
            if (index == 2) sum += moment.utc(wr.startDate).isoWeekday() == 3 && wr.hoursTotal ? wr.hoursTotal : 0;
            if (index == 3) sum += moment.utc(wr.startDate).isoWeekday() == 4 && wr.hoursTotal ? wr.hoursTotal : 0;
            if (index == 4) sum += moment.utc(wr.startDate).isoWeekday() == 5 && wr.hoursTotal ? wr.hoursTotal : 0;
            if (index == 5) sum += moment.utc(wr.startDate).isoWeekday() == 6 && wr.hoursTotal ? wr.hoursTotal : 0;
            if (index == 6) sum += moment.utc(wr.startDate).isoWeekday() == 7 && wr.hoursTotal ? wr.hoursTotal : 0;
        });
        return sum;
    };
    // Create array that contains timestamp arrays. Set the max rows value.
    function timeStampArr() {
        for (var i = 0; i < 7; i++) {
            ctrl.timeStamps[i] = ctrl.workReports.filter(function (wr) {
                return moment.utc(wr.startDate).isoWeekday() == i + 1;	// Adding one since ISO day of week starts with 1
            }).map(function (wr) {
                return { start: moment.utc(wr.startDate).format('HH:mm'), out: wr.endDate ? moment.utc(wr.endDate).format('HH:mm') : null };
            });
            ctrl.max = ctrl.timeStamps[i].length > ctrl.max ? ctrl.timeStamps[i].length : ctrl.max;
        };
    };

    // Get employee work reports for the seleceted week.
    function getWorkReports() {
        initValues();
        if (ctrl.emp.showTimestamps) {
            var begin_stamp = date(0);
            var end_stamp = date(7);
            commonDatacontext.getWorkReport(ctrl.emp.empId, null, 75, 'SELECT_PERIOD', begin_stamp, end_stamp, 'ORDER_BEGINSTAMP_ASC').then(function (data) {
                ctrl.workReports = data ? data : [];
                oldEmpId = ctrl.emp.empId;
                timeStampArr();
                ctrl.loaded = true;
            });
        }
    }

    function initValues() {
        oldEmpId = null;
        ctrl.loaded = false;
        ctrl.max = 0;
        ctrl.timeStamps = []
        ctrl.isTsOpen = false;
    }

    function date(index) {
        var weekStart = moment.utc(ctrl.selectedDate).startOf('isoWeek');
        return moment.utc(weekStart).add(index, 'days').format('YYYY-MM-DD');
    }
};
// Time stamps view.
// Usage:
//	emp: Selected employee. Used to get selected employees time stamps.
//	selectedDate: used to determine the selected week.
// Example: 
//	<dfx-time-stamps emp="ctrl.emp" selected-date="ctrl.selectedDate"></dfx-time-stamps>		
// Note: directive is currently styled to fit in hourinput view.
timeStampsModule.directive('dfxTimeStamps', function () {
    return {
        restrict: 'E',
        scope: {},
        bindToController: {
            emp: '=',		// Current employee.
            selectedDate: '='	// Current selected date.
        },
        controller: timeStampControllerId,
        controllerAs: 'ctrl',
        template: require('./dfxTimeStamps.html')
    };
});
export default timeStampsModule