import './app.js';
(function () {
    'use strict';

    var app = angular.module('app');

    // Collect the routes
    app.constant('routes', getRoutes());

    // Configure the routes and route resolvers
	app.config(['$routeProvider', '$locationProvider', 'routes','getAppSettingsProvider', routeConfigurator]);
	function routeConfigurator($routeProvider, $locationProvider, routes, getAppSettingsProvider) {
		var portalsAvailable = getAppSettingsProvider.getAppSetting("PortalsAvailable", "");
        var portalsAvailableArr = [];
        if (portalsAvailable) {
            portalsAvailableArr = portalsAvailable.split(';');
        }
        routes.forEach(function (r) {
            if (portalsAvailableArr.length > 0) {
                for (var i = 0; i < portalsAvailableArr.length; i++) {
                    if (portalsAvailableArr[i] == r.config.portalId || r.config.portalId == null) {
                        $routeProvider.when(r.url, r.config);
                        break;
                    }
                }
            }
            else {
                $routeProvider.when(r.url, r.config);
            }
        });
        $routeProvider.otherwise({ redirectTo: '/' });

        $locationProvider.html5Mode(true);
    }

    // Define the routes 
    function getRoutes() {
		return [
			{
				url: '/',
				config: {
					portalId: null,
					templateUrl: 'app/layout/startup.html',
					title: 'Info',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./layout/startup").then(module => $ocLazyLoad.load(module.default))]
					}
				}
			},
			{
				url: '/login/:portalId?/:tabId?/:company?',
				config: {
					portalId: null,
					templateUrl: 'app/layout/login.html',
					title: 'Sisäänkirjautuminen / Login',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.TXT_LOGIN_PAGE")',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./layout/login").then(module => $ocLazyLoad.load(module.default))]
					}
				}
			},
			{
				url: '/windowsLogin',
				config: {
				}
			},
			{
				url: '/home',
				config: {
					portalId: 'HOME',
					templateUrl: 'app/layout/home.html',
					title: 'Päävalikko / Main menu',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_HOME")',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./layout/home").then(module => $ocLazyLoad.load(module.default))]
					},
					reloadOnSearch: false
				}
			},
			{
                url: '/timereporting/report',
                config: {
                    portalId: 'TIMEREPORTING',
                    portalNameFunc: 'common.$translate.instant("STRCONST.WOR.HDR_HOURS_INPUT")',
                    templateUrl: 'app/layout/portaltab.html',
                    title: 'Tuntiraportti / Hour report',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.HOUR_REPORT")',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./layout/portaltab").then(module => $ocLazyLoad.load(module.default))]
					}
                }
            },
            {
                url: '/timereporting/flex',
                config: {
                    portalId: 'TIMEREPORTING',
                    portalNameFunc: 'common.$translate.instant("STRCONST.WOR.HDR_HOURS_INPUT")',
                    templateUrl: 'app/layout/portaltab.html',
                    title: 'Liukumatiedot / Flex Hours',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.TXT_FLEX_HOURS_INFO")',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./layout/portaltab").then(module => $ocLazyLoad.load(module.default))]
					}
                }
            },
            {
               url: '/timereporting/wip',
               config: {
                   portalId: 'TIMEREPORTING',
                   portalNameFunc: 'common.$translate.instant("STRCONST.WOR.HDR_HOURS_INPUT")',
                   title: 'Work in progress / Keskeneräiset työt',
                   titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.TXT_WIP")',
                   templateUrl: 'app/hourinput/wip.cshtml',
                   reloadOnSearch: false
               }
            },
			{
				url: '/timereporting/acceptance/summary',
				config: {
					portalId: 'TIMEREPORTING',
					portalNameFunc: 'common.$translate.instant("STRCONST.WOR.HDR_HOURS_INPUT")',
					title: 'Tuntien hyväksyntä',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.HDR_HOURINPUT_ACCEPTANCE")',
					templateUrl: 'app/hourinput/hour_summary.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./hourinput/components/HourSummary").then(module => $ocLazyLoad.load(module.default))]
					},
					reloadOnSearch: false
				}
			},
			{
				url: '/timereporting/acceptance/details',
				config: {
					portalId: 'TIMEREPORTING',
					portalNameFunc: 'common.$translate.instant("STRCONST.WOR.HDR_HOURS_INPUT")',
					title: 'Tuntien hyväksyntä',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.HDR_HOURINPUT_ACCEPTANCE")',
					templateUrl: 'app/hourinput/approval.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./hourinput/approval").then(module => $ocLazyLoad.load(module.default))]
					},
					reloadOnSearch: false
				}
			},
			{
				url: '/timereporting/:eventDate?',
				config: {
					portalId: 'TIMEREPORTING',
					templateUrl: 'app/hourinput/hourinput.html',
					//template: () => import("./hourinput/hourinput.html").then(template => template.default),
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./hourinput/hourinputModule").then(module => $ocLazyLoad.load(module.default))]
					},
					title: 'Tuntisyöttö / Hour Input',
					titleFunc: 'common.$translate.instant("STRCONST.WOR.HDR_HOURS_INPUT")'
				}
			},
			{
				url: '/timestamps',
				config: {
					portalId: 'TIMESTAMPS',
					portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_TIMESTAMPS")',
					title: 'Aikaleimat / Time stamps',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_TIMESTAMPS")',
					templateUrl: 'app/timestamps/timestamps_entry_tab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./timestamps/components/TimeStampsEntryTab.tsx").then(module => $ocLazyLoad.load(module.timestampsModule))]
					},
					reloadOnSearch: false
				}
			},
            {
                url: '/admin',
                config: {
                    portalId: null,
                    title: 'admin',
                    templateUrl: 'app/admin/admin.html'
                }
            },
            {
                url: '/sales',
                config: {
                    portalId: 'SALES',
                    title: 'Myynti / Sales',
                    titleFunc: 'common.$translate.instant("STRCONST.PUBLIC.TXT_SALES")',
					templateUrl: 'app/sales/saleslead.cshtml',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./sales/salesModule.js").then(module => $ocLazyLoad.load(module.default))]
					},
                    reloadOnSearch: false
                }
            },
            {
                url: '/manufacturing/resourcetasks',
                config: {
                    portalId: 'MANUFACTURING',
                    portalNameFunc: 'common.$translate.instant("STRCONST.PUBLIC.TXT_MANUFACTURING")',
                    title: 'Valmistus / Manufacturing',
                    titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.TAB_RESTASKLIST")',
					templateUrl: 'app/manufacturing/resourcetaskstab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./manufacturing/manufacturingModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
                    reloadOnSearch: false
                }
            },
            {
                url: '/manufacturing/resourcetasks/task/:workId/:operatId/:serialId?',
                config: {
                    portalId: 'MANUFACTURING',
                    portalNameFunc: 'common.$translate.instant("STRCONST.PUBLIC.TXT_MANUFACTURING")',
                    title: 'Valmistus / Manufacturing',
                    titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.TAB_RESOURCETASK")',
					templateUrl: 'app/manufacturing/resourcetasktab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./manufacturing/manufacturingModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
                    reloadOnSearch: false
                }
            },
            {
                url: '/manufacturing/resourceworks',
                config: {
                    portalId: 'MANUFACTURING',
                    portalNameFunc: 'common.$translate.instant("STRCONST.PUBLIC.TXT_MANUFACTURING")',
                    title: 'Valmistus / Manufacturing',
                    titleFunc: 'common.$translate.instant("STRCONST.PUBLIC.TXT_WORKS")',
					templateUrl: 'app/manufacturing/resourceworkstab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./manufacturing/manufacturingModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
                    reloadOnSearch: false
                }
            },
            {
                url: '/manufacturing/resourceworks/task/:workId/:operatId/:serialId?',
                config: {
                    portalId: 'MANUFACTURING',
                    portalNameFunc: 'common.$translate.instant("STRCONST.PUBLIC.TXT_MANUFACTURING")',
                    title: 'Valmistus / Manufacturing',
                    titleFunc: 'common.$translate.instant("STRCONST.PUBLIC.TXT_WORK")',
					templateUrl: 'app/manufacturing/resourcetasktab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./manufacturing/manufacturingModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
                    reloadOnSearch: false
                }
            },
            {
				url: '/manufacturing/qualityfailure',
				config: {
                    portalId: 'MANUFACTURING',
                    portalNameFunc: 'common.$translate.instant("STRCONST.PUBLIC.TXT_MANUFACTURING")',
					title: 'Valmistus / Manufacturing',
                    titleFunc: 'common.$translate.instant("STRCONST.QLT_QUALITY_FAILURE.BTN_ENTER_FEEDBACK")',
					templateUrl: 'app/quality/qualityfailuretab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./manufacturing/manufacturingModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
					reloadOnSearch: false
				}
			},
			{
				url: '/manufacturing/operatorview',
				config: {
                    portalId: 'MANUFACTURING',
                    portalNameFunc: 'common.$translate.instant("STRCONST.PUBLIC.TXT_MANUFACTURING")',
					title: 'Valmistus / Manufacturing',
                    titleFunc: 'common.$translate.instant("STRCONST.PUBLIC.TXT_WORKLIST")',
					templateUrl: 'app/manufacturing/operator/resourcetaskstab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./manufacturing/manufacturingModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
					reloadOnSearch: false
				}
			},
			{
				url: '/manufacturing/operbundling',
				config: {
					portalId: 'MANUFACTURING',
					title: 'Valmistus / Manufacturing',
					titleFunc: 'common.$translate.instant("STRCONST.PUBLIC.TXT_MANUFACTURING")',
					templateUrl: 'app/manufacturing/operBundling/operBundleTab.html',
					reloadOnSearch: false,
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./manufacturing/manufacturingModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
				}
			},
            {
                url: '/manufacturing/workelements',
                config: {
                    portalId: 'MANUFACTURING',
                    portalNameFunc: 'common.$translate.instant("STRCONST.PUBLIC.TXT_MANUFACTURING")',
                    title: 'Valmistus / Manufacturing',
                    titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.TAB_WORKELEMENTS")',
					templateUrl: 'app/manufacturing/workelements_tab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./manufacturing/manufacturingModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
                    reloadOnSearch: false
                }
            },
            {
                url: '/manufacturing/workelements/documents',
                config: {
                    portalId: 'MANUFACTURING',
                    portalNameFunc: 'common.$translate.instant("STRCONST.PUBLIC.TXT_MANUFACTURING")',
                    title: 'Valmistus / Manufacturing',
                    titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.TAB_WORKELEMENTDOCS")',
					templateUrl: 'app/manufacturing/workelementdocs_tab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./manufacturing/manufacturingModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
                    reloadOnSearch: false
                }
            },
			{
				url: '/manufacturing/assemblyview',
				config: {
                    portalId: 'MANUFACTURING',
                    portalNameFunc: 'common.$translate.instant("STRCONST.PUBLIC.TXT_MANUFACTURING")',
					title: 'Valmistus / Manufacturing',
                    titleFunc: 'common.$translate.instant("STRCONST.PUBLIC.TXT_ASSEMBLY")',
					templateUrl: 'app/manufacturing/operator/assemblytab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./manufacturing/manufacturingModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
					reloadOnSearch: false
				}
            },
			{
				url: '/manufacturing/qualitydatas',
				config: {
					portalId: 'MANUFACTURING',
					portalNameFunc: 'common.$translate.instant("STRCONST.PUBLIC.TXT_MANUFACTURING")',
					title: 'Seurantatiedot / Quality Datas',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.TXT_QLTDATA_REPORT_TITLE")',
					templateUrl: 'app/quality/qualitydata_tab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./quality/qualityModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
					reloadOnSearch: false
				}
			},
            {
                url: '/configurator',
                config: {
                    portalId: 'CONFIGURATOR',
                    portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.TXT_CONFIGURATOR")',
                    title: 'Konfiguraattori / Configurator',
                    titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.TXT_CONFIGURATOR")',
					templateUrl: 'app/configurator/configurator.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./configurator/configuratorModule.js").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
                    reloadOnSearch: false
                }
            },
			{
				url: '/purchase',
				config: {
					portalId: 'PURCHASE',
					portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_EBILL")',
					title: 'Ostolaskut / Purchase Invoices',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_EBILL")',
					templateUrl: 'app/purchase/invoice.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./purchase/invoiceModule.js").then(module => $ocLazyLoad.load(module.default))]
					},
					reloadOnSearch: false
				}
			},
			{
				url: '/purchase/invoice/:recId',
				config: {
					portalId: 'PURCHASE',
					portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_EBILL")',
					title: 'Ostolaskut / Purchase Invoices',
                    titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.HDR_PINROWS")',
					templateUrl: 'app/purchase/invoicetab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./purchase/invoiceModule.js").then(module => $ocLazyLoad.load(module.default))]
					},
					controller: 'invoiceTab',
					controllerAs: 'ctrl',
					reloadOnSearch: false
				}
			},
			{
				url: '/purchase/requests',
				config: {
					portalId: 'PURCHASE',
					portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_EBILL")',
					title: 'Hankintaehdotukset / Purchase Requests',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.TAB_PROCUREMENTREQUESTS")',
					templateUrl: 'app/layout/portaltab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./layout/portaltab").then(module => $ocLazyLoad.load(module.default))]
					},
					reloadOnSearch: false
				}
			},
            {
                url: '/purchase/usersubstitutes',
                config: {
                    portalId: 'PURCHASE',
                    portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_EBILL")',
                    title: 'Varahenkilöt / User Substitutes',
                    titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.TAB_SUBSTITUTEUSER")',
					templateUrl: 'app/layout/portaltab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./layout/portaltab").then(module => $ocLazyLoad.load(module.default))]
					},
                    reloadOnSearch: false
                }
            },
            {
                url: '/quality',
                config: {
                    portalId: 'QUALITY',
                    portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_QUALITY")',
                    title: 'Palauteportaali / Quality Feedbacks',
                    titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_QUALITY")',
					templateUrl: 'app/quality/feedbacks_tab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./quality/qualityModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
                    reloadOnSearch: false
                }
            },
            {
                url: '/quality/feedbacks',
                config: {
                    portalId: 'QUALITY',
                    portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_QUALITY")',
                    title: 'Palautteet / Feedbacks',
                    titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.TAB_FEEDBACKS")',
					templateUrl: 'app/quality/feedbacks_tab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./quality/qualityModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
                    reloadOnSearch: false
                }
            },
            {
                url: '/quality/qualityfeedback/:failureId',
                config: {
                    portalId: 'QUALITY',
                    portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_QUALITY")',
                    title: 'Laatupalaute / Quality Feedback',
                    titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.TAB_QUALITYFEEDBACK")',
					templateUrl: 'app/quality/qualityfeedback_tab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./quality/qualityModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
                    reloadOnSearch: false
                }
            },
            {
                url: '/quality/customerfeedback/:reclamationId',
                config: {
                    portalId: 'QUALITY',
                    portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_QUALITY")',
                    title: 'Asiakaspalaute / Customer Feedback',
                    titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.TAB_CUSTOMERFEEDBACK")',
					templateUrl: 'app/quality/customerfeedback_tab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./quality/qualityModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
                    reloadOnSearch: false
                }
            },
            {
                url: '/quality/supplierfeedback/:reclamationId',
                config: {
                    portalId: 'QUALITY',
                    portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_QUALITY")',
                    title: 'Toimittajapalaute / Supplier Feedback',
                    titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.TAB_SUPPLIERFEEDBACK")',
					templateUrl: 'app/quality/supplierfeedback_tab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./quality/qualityModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
                    reloadOnSearch: false
                }
            },
            {
            	url: '/manufacturing/balancerfeed',
            	config: {
            		portalId: 'MANUFACTURING',
            		title: 'Valmistus / Manufacturing',
            		titleFunc: 'common.$translate.instant("STRCONST.PUBLIC.TXT_MANUFACTURING")',
					templateUrl: 'app/common/feed/feedtab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./common/feed/feedtab").then(module => $ocLazyLoad.load(module.default))]
					},
            		reloadOnSearch: false
            	}
            },
			{
				url: '/purchase/request/:orderId',
				config: {
					portalId: 'PURCHASE',
					portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_EBILL")',
					title: 'Hankintaehdotus / Purchase Request',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.TAB_PROCUREMENTREQUESTDATA")',
					templateUrl: 'app/layout/portaltab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./layout/portaltab").then(module => $ocLazyLoad.load(module.default))]
					},
					reloadOnSearch: false
				}
			},
            {
                url: '/supplier',
                config: {
                    portalId: 'SUPPLIER',
                    portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_SUPPLIER")',
                    title: 'Toimittaja / Supplier',
                    titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_SUPPLIER")',
					templateUrl: 'app/supplier/supplier.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./supplier/supplierModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
                    reloadOnSearch: false
                }
            },
            {
                url: '/supplier/order/:orderId*/details',
                config: {
                    portalId: 'SUPPLIER',
                    portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_SUPPLIER")',
                    title: 'Toimittaja / Supplier',
                    titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.BASIC_INFO")',
					templateUrl: 'app/supplier/suppliertab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./supplier/supplierModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
                    reloadOnSearch: false
                }
            },
            {
                url: '/supplier/order/:orderId*/content',
                config: {
                    portalId: 'SUPPLIER',
                    portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_SUPPLIER")',
                    title: 'Toimittaja / Supplier',
                    titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.CONTENTS")',
					templateUrl: 'app/supplier/supcontenttab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./supplier/supplierModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
                    reloadOnSearch: false
                }
            },
            {
                url: '/supplier/order/:orderId*/projectposition',
                config: {
                    portalId: 'SUPPLIER',
                    portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_SUPPLIER")',
                    title: 'Toimittaja / Supplier',
                    titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PROJECT_POSITION")',
					templateUrl: 'app/supplier/supprojpostab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./supplier/supplierModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
                    reloadOnSearch: false
                }
            },
            {
                url: '/supplier/order/:orderId*/files',
                config: {
                    portalId: 'SUPPLIER',
                    portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_SUPPLIER")',
                    title: 'Toimittaja / Supplier',
                    titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.FILES")',
					templateUrl: 'app/supplier/supfilestab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./supplier/supplierModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
                    reloadOnSearch: false
                }
            },
            {
                url: '/supplier/order/:orderId*/chat',
                config: {
                    portalId: 'SUPPLIER',
                    portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_SUPPLIER")',
                    title: 'Toimittaja / Supplier',
                    titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.CHAT")',
					templateUrl: 'app/supplier/supchattab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./supplier/supplierModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
                    reloadOnSearch: false
                }
            },
            {
                url: '/supplier/feedbacks',
                config: {
                    portalId: 'SUPPLIER',
                    portalNameFunc: 'getStrConst(strConst.LEANPORTAL$PORTAL_NAME_SUPPLIER)',
                    title: 'Toimittaja / Supplier',
                    titleFunc: 'getStrConst(strConst.LEANPORTAL$PORTAL_NAME_SUPPLIER)',
					templateUrl: 'app/supplier/supplierfeedback/supplierfeedbacks_tab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./supplier/supplierModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
                    reloadOnSearch: false
                }
            },
            {
                url: '/supplier/feedback/:reclamationId*',
                config: {
                    portalId: 'SUPPLIER',
                    portalNameFunc: 'getStrConst(strConst.LEANPORTAL$PORTAL_NAME_SUPPLIER)',
                    title: 'Toimittaja / Supplier',
                    titleFunc: 'getStrConst(strConst.LEANPORTAL$PORTAL_NAME_SUPPLIER)',
					templateUrl: 'app/supplier/supplierfeedback/supplierfeedback_edit_tab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./supplier/supplierModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
                    reloadOnSearch: false
                }
			},
			{
				url: '/supplier/backlog',
				config: {
					portalId: 'SUPPLIER',
					portalNameFunc: 'getStrConst(strConst.LEANPORTAL$PORTAL_NAME_SUPPLIER)',
					title: 'Toimittaja / Supplier',
					titleFunc: 'getStrConst(strConst.LEANPORTAL$PORTAL_NAME_SUPPLIER)',
					templateUrl: 'app/supplier/backlog.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./supplier/supplierModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
					reloadOnSearch: false
				}
            },
            {
                url: '/supplier/offerrequests',
                config: {
                    portalId: 'SUPPLIER',
                    portalNameFunc: 'getStrConst(strConst.LEANPORTAL$PORTAL_NAME_SUPPLIER)',
                    title: 'Toimittaja / Supplier',
                    titleFunc: 'getStrConst(strConst.LEANPORTAL$PORTAL_NAME_SUPPLIER)',
					templateUrl: 'app/supplier/offerrequests/offerrequests_tab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./supplier/supplierModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
                    reloadOnSearch: false
                }
            },
            {
                url: '/supplier/offerrequest/:orderId*',
                config: {
                    portalId: 'SUPPLIER',
                    portalNameFunc: 'getStrConst(strConst.LEANPORTAL$PORTAL_NAME_SUPPLIER)',
                    title: 'Toimittaja / Supplier',
                    titleFunc: 'getStrConst(strConst.LEANPORTAL$PORTAL_NAME_SUPPLIER)',
					templateUrl: 'app/supplier/offerrequests/offerrequest_tab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./supplier/supplierModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
                    reloadOnSearch: false
                }
			},
			{
				url: '/supplier/stock/:stockId',
				config: {
					portalId: 'SUPPLIER',
					portalNameFunc: 'getStrConst(strConst.LEANPORTAL$PORTAL_NAME_SUPPLIER)',
					title: 'Toimittaja / Supplier',
					titleFunc: 'getStrConst(strConst.LEANPORTAL$PORTAL_NAME_SUPPLIER)',
					templateUrl: 'app/supplier/stock.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./supplier/supplierModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
					reloadOnSearch: false
				}
			},
			{
				url: '/supplier/delivery',
				config: {
					portalId: 'SUPPLIER',
					portalNameFunc: 'getStrConst(strConst.LEANPORTAL$PORTAL_NAME_SUPPLIER)',
					title: 'Toimittaja / Supplier',
					titleFunc: 'getStrConst(strConst.LEANPORTAL$PORTAL_NAME_SUPPLIER)',
					templateUrl: 'app/supplier/deliverytab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./supplier/supplierModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
					reloadOnSearch: false
				}
			},
			{
				url: '/supplier/items',
				config: {
					portalId: 'SUPPLIER',
					portalNameFunc: 'getStrConst(strConst.LEANPORTAL$PORTAL_NAME_SUPPLIER)',
					title: 'Toimittaja / Supplier',
					titleFunc: 'getStrConst(strConst.LEANPORTAL$PORTAL_NAME_SUPPLIER)',
					templateUrl: 'app/supplier/supplieritems/supplieritems_tab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./supplier/supplierModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
					reloadOnSearch: false
				}
			},
			{
				url: '/supplier/item/:itemId*/files',
				config: {
					portalId: 'SUPPLIER',
					portalNameFunc: 'getStrConst(strConst.LEANPORTAL$PORTAL_NAME_SUPPLIER)',
					title: 'Toimittaja / Supplier',
					titleFunc: 'getStrConst(strConst.LEANPORTAL$PORTAL_NAME_SUPPLIER)',
					templateUrl: 'app/supplier/supplieritems/supplieritem_files_tab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./supplier/supplierModule").then(
							module => $ocLazyLoad.load(module.default)
						)]
					},
					reloadOnSearch: false
				}
			},
            {
                url: '/statusdisplay',
                config: {
                    portalId: 'STATUSDISPLAY',
                    portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_STATUSDISPLAY")',
                    title: 'Statusnäyttö / Status display',
                    titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_STATUSDISPLAY")',
					templateUrl: 'app/statusdisplay/statusdisplay.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./statusdisplay/statusModule.js").then(module => $ocLazyLoad.load(module.default))]
					},
                    reloadOnSearch: false
                }
            },
			{
                url: '/statusdisplay/progresstracker',
                config: {
                    portalId: 'STATUSDISPLAY',
                    portalNameFunc: 'getStrConst(strConst.LEANPORTAL$PORTAL_NAME_STATUSDISPLAY)',
                    title: 'Statusnäyttö / Status display',
                    titleFunc: 'getStrConst(strConst.LEANPORTAL$PORTAL_NAME_STATUSDISPLAY)',
					templateUrl: 'app/statusdisplay/progresstracker.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./statusdisplay/statusModule.js").then(module => $ocLazyLoad.load(module.default))]
					},
                    reloadOnSearch: false,
                    settings: {
                        nav: 9,
                        content: '<i class="far fa-clock"></i> Statusnäyttö / Status display'
                    }
                }
			},
			{
				url: '/service',
				config: {
					portalId: 'SERVICESPA',
					portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_SUPPLIER")',
					title: 'Huoltotyöt / Service',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_SUPPLIER")',
					templateUrl: 'app/service/frontpage.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./service/serviceModule.js").then(module => $ocLazyLoad.load(module.default))] 		
					},
					reloadOnSearch: false
				}
			},
			{
				url: '/service/map',
				config: {
					portalId: 'SERVICESPA',
					portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_SUPPLIER")',
					title: 'Huoltotyöt / Service',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_SUPPLIER")',
					templateUrl: 'app/service/map.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./service/components/Map.tsx").then(module => $ocLazyLoad.load(module.reactMapModule))]					
					},
					reloadOnSearch: false
				}
            },
            {
                url: '/maintenance',
                config: {
                    portalId: null,
					templateUrl: 'app/layout/maintenance.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./layout/maintenance").then(module => $ocLazyLoad.load(module.default))]
					},
                    reloadOnSearch: false
                }
			},
			{
				url: '/travelexpenses/invoices',
				config: {
					portalId: 'TRAVELEXPENSES',
					portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_TRAVEL")',
					title: 'Matkalaskulista / Travelexpenseslist',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_TRAVEL")',
					templateUrl: 'app/travel/invoices.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./travel/TravelPage.tsx").then(module => $ocLazyLoad.load(module.travelPageModule))]
					},
					reloadOnSearch: false
				}
			},
			{
				url: '/travelexpenses/invoice/new',
				config: {
					portalId: 'TRAVELEXPENSES',
					portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_TRAVEL")',
					title: 'Matkalaskun tarkastelu / Muokkaus',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_TRAVEL")',
					templateUrl: 'app/travel/invoices.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./travel/TravelPage.tsx").then(module => $ocLazyLoad.load(module.travelPageModule))]
					},
					reloadOnSearch: false
				}
			},
			{
				url: '/travelexpenses/invoice/:recId?',
				config: {
					portalId: 'TRAVELEXPENSES',
					portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_TRAVEL")',
					title: 'Matkalaskun tarkastelu / Muokkaus',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_TRAVEL")',
					templateUrl: 'app/travel/invoices.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./travel/TravelPage.tsx").then(module => $ocLazyLoad.load(module.travelPageModule))]
					},
					reloadOnSearch: false
				}
			},
			{
				url: '/travelexpenses/approval',
				config: {
					portalId: 'TRAVELEXPENSES',
					portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_TRAVEL")',
					title: 'Hyväksyntä / Approval',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_TRAVEL")',
					templateUrl: 'app/travel/approval.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./travel/TravelApprovalPage.tsx").then(module => $ocLazyLoad.load(module.default))]
					},
					reloadOnSearch: false,
					reloadOnUrl: false
				}
			},
			{
				url: '/travelexpenses/approval/:recId?',
				config: {
					portalId: 'TRAVELEXPENSES',
					portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_TRAVEL")',
					title: 'Matkalaskun hyväksyntä / Travel invoice approval',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_TRAVEL")',
					templateUrl: 'app/travel/approval.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./travel/TravelApprovalPage.tsx").then(module => $ocLazyLoad.load(module.default))]
					},
					reloadOnSearch: false,
					reloadOnUrl: false
				}
			},
			{
				url: '/approval',
				config: {
					portalId: 'APPROVAL',
					portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_APPROVAL")',
					title: 'Hyväksyntä / Approval',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_APPROVAL")',
					templateUrl: 'app/approval/mainpage.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import('./approval/approval.tsx').then(module => $ocLazyLoad.load(module.approvalPageModule))]
					},
					reloadOnSearch: false
				}
			},
			{
				url: '/approval/summary',
				config: {
					portalId: 'APPROVAL',
					portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_APPROVAL")',
					title: 'Hyväksyntä / Approval',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_APPROVAL")',
					templateUrl: 'app/hourinput/hour_summary.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./hourinput/components/HourSummary").then(module => $ocLazyLoad.load(module.default))]
					},
					reloadOnSearch: false
				}
			},
			{
				url: '/approval/details',
				config: {
					portalId: 'APPROVAL',
					portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_APPROVAL")',
					title: 'Hyväksyntä / Approval',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_APPROVAL")',
					templateUrl: 'app/hourinput/approval.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import('./hourinput/approval').then(module => $ocLazyLoad.load(module.default))]
					},
					reloadOnSearch: false
				}
			},
			{
				url: '/approval/purchase',
				config: {
					portalId: 'APPROVAL',
					portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_APPROVAL")',
					title: 'Hyväksyntä / Approval',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_APPROVAL")',
					templateUrl: 'app/purchase/invoice.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./purchase/invoiceModule.js").then(module => $ocLazyLoad.load(module.default))]
					},
					reloadOnSearch: false
				}
			},
			{
				url: '/approval/purchase/invoice/:recId',
				config: {
					portalId: 'APPROVAL',
					portalNameFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_APPROVAL")',
					title: 'Hyväksyntä / Approval',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NAME_APPROVAL")',
					templateUrl: 'app/purchase/invoicetab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./purchase/invoicetab").then(module => $ocLazyLoad.load(module.default))]
					},
					controller: 'invoiceTab',
					controllerAs: 'ctrl',
					reloadOnSearch: false
				}
			},
			{
				url: '/returndeliveries',
				config: {
					portalId: 'RETURNDELIVERY',
					portalNameFunc: 'common.$translate.instant(STRCONST.LEANPORTAL.PORTAL_NAME_RETURNDELIVERY)',
					title: 'Palautukset / Return Delivery',
					titleFunc: 'common.$translate.instant(STRCONST.LEANPORTAL.PORTAL_NAME_RETURNDELIVERY)',
					templateUrl: 'app/returndelivery/returndelivery.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./returndelivery/returndeliveryModule.js").then(module => $ocLazyLoad.load(module.default))]
					},
					reloadOnSearch: false
				}
			},
			{
				url: '/returndeliveries/returndelivery',
				config: {
					portalId: 'RETURNDELIVERY',
					portalNameFunc: 'common.$translate.instant(STRCONST.LEANPORTAL.PORTAL_NAME_RETURNDELIVERY)',
					title: 'Palautukset / Return Delivery',
					titleFunc: 'common.$translate.instant(STRCONST.LEANPORTAL.PORTAL_NAME_RETURNDELIVERY)',
					templateUrl: 'app/returndelivery/returndeliverytab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./returndelivery/returndeliveryModule.js").then(module => $ocLazyLoad.load(module.default))]
					},
					reloadOnSearch: false
				}
			},
			{
				url: '/returndeliveries/returndelivery/:deliveryId',
				config: {
					portalId: 'RETURNDELIVERY',
					portalNameFunc: 'common.$translate.instant(STRCONST.LEANPORTAL.PORTAL_NAME_RETURNDELIVERY)',
					title: 'Palautukset / Return Delivery',
					titleFunc: 'common.$translate.instant(STRCONST.LEANPORTAL.PORTAL_NAME_RETURNDELIVERY)',
					templateUrl: 'app/returndelivery/returndeliverytab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./returndelivery/returndeliveryModule.js").then(module => $ocLazyLoad.load(module.default))]
					},
					reloadOnSearch: false
				}
			},
			{
				url: '/workbench/approval',
				config: {
					portalId: 'WORKBENCH',
					portalNameFunc: 'common.$translate.instant(STRCONST.LEANPORTAL.PORTAL_NAME_WORKBENCH)',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import('./workbench/components/MyTasks.tsx').then(module => $ocLazyLoad.load(module.myTasksModule))] /* TODO Replace with real component */
					},
					templateUrl: 'app/workbench/wb_approval_tab.html',
					title: 'Hyväksyntä / Approval',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.TAB_WB_APPROVAL")'
				}
			},
			{
				url: '/workbench/report',
				config: {
					portalId: 'WORKBENCH',
					portalNameFunc: 'common.$translate.instant("STRCONST.WOR.HDR_HOURS_INPUT")',
					templateUrl: 'app/layout/portaltab.html',
					title: 'Tuntiraportti / Hour report',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.HOUR_REPORT")'
				}
			},
			{
				url: '/workbench/flex',
				config: {
					portalId: 'WORKBENCH',
					portalNameFunc: 'common.$translate.instant("STRCONST.WOR.HDR_HOURS_INPUT")',
					templateUrl: 'app/layout/portaltab.html',
					title: 'Liukumatiedot / Flex Hours',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.TXT_FLEX_HOURS_INFO")'
				}
			},
			{
				url: '/workbench/acceptance/summary',
				config: {
					portalId: 'WORKBENCH',
					portalNameFunc: 'common.$translate.instant("STRCONST.WOR.HDR_HOURS_INPUT")',
					title: 'Tuntien hyväksyntä',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.HDR_HOURINPUT_ACCEPTANCE")',
					templateUrl: 'app/hourinput/hour_summary.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./hourinput/components/HourSummary").then(module => $ocLazyLoad.load(module.default))]
					},
					reloadOnSearch: false
				}
			},
			{
				url: '/workbench/acceptance/details',
				config: {
					portalId: 'WORKBENCH',
					portalNameFunc: 'common.$translate.instant("STRCONST.WOR.HDR_HOURS_INPUT")',
					title: 'Tuntien hyväksyntä',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.HDR_HOURINPUT_ACCEPTANCE")',
					templateUrl: 'app/hourinput/approval.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => import("./hourinput/approval").then(module => $ocLazyLoad.load(module.default))]
					},
					reloadOnSearch: false
				}
			},
			{
				url: '/workbench/:eventDate?',
				config: {
					portalId: 'WORKBENCH',
					portalNameFunc: 'common.$translate.instant(STRCONST.LEANPORTAL.PORTAL_NAME_WORKBENCH)',
					templateUrl: 'app/workbench/wb_timereporting_tab.html',
					resolve: {
						loadModule: ['$ocLazyLoad', $ocLazyLoad => Promise.all([
							import('./hourinput/hourinputModule.js'),
							import('./workbench/components/MyTasks.tsx')
						]).then(modules => $ocLazyLoad.load([modules[0].default, modules[1].default]))
						]
					},
					title: 'Tuntisyöttö / Time reporting',
					titleFunc: 'common.$translate.instant("STRCONST.LEANPORTAL.TAB_WB_TIMEREPORTING")'
				}
			}
        ];
	}

	// Bootstrap the app
	$.ajax({
		method: "GET",
		url: "Breeze/Common/GetAppSettings",
	})
		.done(function (result) {
			configObject = result;
			angular.element(function () {
				angular.bootstrap(document, ['app']);
				lang = configObject['cultureid'];
				browserPreferredLanguage = lang;
				portalsAvailable = configObject['portalsAvailable']
			});
		});
})();
