'use strict';
var workElementsModule = angular.module('manudir.dfxSettingsWorkElements',[]);
workElementsModule.directive('dfxSettingsWorkElements', function () {
    // Used to tag 'Portal Settings for Task View' html
    return {
        restrict: 'E', //E = element, A = attribute, C = class, M = comment 
        template: require('./dfxSettingsWorkElements.html'),
        controllerAs: 'ctrl',
        controller: ['common', 'workelements_service', function (common, workelements_service) {
            var ctrl = this;

            ctrl.showExpandImageSel = function () {
                // If enumeration value exists, then this properties selection is hidden
                return !workelements_service.getState().collapseElementPicturesDefault;
            };

            ctrl.expandWorkElemImageSelListChoices = [
                { id: true, name: common.$translate.instant("STRCONST.PUBLIC.TXT_ROWS_OPEN") },
                { id: false, name: common.$translate.instant("STRCONST.PUBLIC.TXT_ROWS_CLOSED") }
            ];
        }]
    };
});
export default workElementsModule