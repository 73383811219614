/**
	  * Select stock and stock location with typehead
	  */
var manSelectStockCtrlId = 'manselectstockctrl';
var selectStockModule=angular.module('manudir.manSelectStock',[])
selectStockModule.directive('manSelectStock', [function () {
	return {
		restrict: 'E',
		scope: {},
		controller: manSelectStockCtrlId,
		controllerAs: 'ctrl',
		bindToController: {
			stockId: '=',
			stockLocId: '='
		},
		template: require('./manSelectStock.html')
	}
}]);
selectStockModule.controller(manSelectStockCtrlId, ['$scope', 'manDatacontext', 'common', manselectstock]);
function manselectstock($scope, manDatacontext, common) {
	var ctrl = this;
	ctrl.itemsInTypeAhead = 14;
	ctrl.stockList = [];

    ctrl.$onInit = function () {
        init();
    };
	/**
	 * Filters ctrl.stockList or ctrl.stockLocList with given string
	 * @param {searchFilter used in matching} searchFilter
	 */
	ctrl.filteredList = function (stock, searchFilter) {
		var matching = [];
		var cmp = searchFilter.toLowerCase();
		// Both Stock and StockLoc are filtered with ID/name <=> same logic can be used
		var list = stock ? ctrl.stockList : ctrl.selectedStock.stockLocations;
		list.forEach(function (s) {
			s['showMoreRows'] = false;
			if (searchFilter == ' ' || searchFilter == '' ||
				common.textContains(s.id, searchFilter) ||
				common.textContains(s.name, searchFilter)) {
				matching.push(s);
			}
		});
		if (matching.length > ctrl.itemsInTypeAhead) {
			matching = matching.splice(0, ctrl.itemsInTypeAhead);
			matching[ctrl.itemsInTypeAhead - 1]['showMoreRows'] = true;
		}
		return matching;
	}

	/**
	 * Provides data for typehead columns
	 */
	function getStockTypeheadName(column, stock) {
		var ret = null;
		if (column < 2) {
			if (column == 0) {
				ret = stock.id;
			}
			else if (column == 1) {
				ret = stock.name;
			}
		}
		return ret;
	}

	function getStockLocTypeheadName(column, stockLoc) {
		var ret = null;
		if (column < 3) {
			if (column == 0) {
				ret = stockLoc.stockId;
			}
			if (column == 1) {
				ret = stockLoc.id;
			}
			else if (column == 2) {
				ret = stockLoc.name;
			}
		}
		return ret;
	}

	ctrl.stockSelected = function (stock) {
		ctrl.selectedStock = stock;
		ctrl.stockId = stock ? stock.id : '';
		ctrl.stockLocId = '';
		if (stock && stock.stockLocations.length == 0) {
			getStockLocs(stock);
		}
	}

	ctrl.stockLocSelected = function (stockLoc) {
		ctrl.stockLocId = stockLoc ? stockLoc.id : '';
	}

	/**
	 * Read all stock locations (recursively if needed)
	 */
	function getStockLocs(stock, offset, size) {
		manDatacontext.getStockLocs(stock.id, offset, size).then(function (data) {
			stock.stockLocations = stock.stockLocations.concat(data);
			if (data.length == size) {
				getStockLocs(stock, offset + size, size)
			}
			// Fill typehead specific data
			common.setTypeheadColumnData(data, getStockLocTypeheadName, [false, true, false], 0, [8, 8, 20], false);
		});
	}

	/**
	 * Read all stocks (recursively if needed)
	 */
	function getStocks(offset, size) {
		if (offset == 0) {
			ctrl.stockList = [];
		}
		manDatacontext.getStocks(offset, size).then(function (data) {
			ctrl.stockList = ctrl.stockList.concat(data)
			if (data.length == size) {
				getStocks(offset + size, size)
			}
			// Fill typehead specific data
			common.setTypeheadColumnData(data, getStockTypeheadName, [true, false], 0, [8, 20], false);
			if (ctrl.stockId && !ctrl.selectedStock) {
				data.forEach(function (stock) {
					if (ctrl.stockId == stock.id) {
						ctrl.selectedStock = stock;
						if (stock.stockLocations.length == 0) {
							getStockLocs(stock, 0, 1000);
						}
					}
				});
			}
		});
	}


	function init() {
		getStocks(0, 100);
	}
}
export default selectStockModule