'use strict';
var app = angular.module('dir.dfxCalendarEdit',[]);
app.directive('dfxCalendarEdit', function () {
    // Input field + Calendar day selection as a control
    // Usage:
    //  dateValue 
    //  disabled
    // Example:
    // <dfx-calendar-edit date-value="data.dateValue" disabled="data.notEditable"/>
    return {
        restrict: 'EA', //E = element, A = attribute, C = class, M = comment 
        scope: {},
        bindToController: {
            dateValue: '=',
            inputElemClass: '=?',
            mandatory: '=?',
            notEditable: '=?',
			appendBody: '=?',
			overrideFormat: '=?',
			onChangedCallback: '&',
			placeHolder: '=?' // Text on the input. If not set format is shown.
        },
        controller: 'datepickerCtrl',
        controllerAs: 'ctrl',
        template: require('./dfxCalendarEdit.html')
    }
});
export default app