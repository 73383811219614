import './dfxFavourite.js'
import './dfxCalendarEdit'
import './dfxAddCommentButton'
import './dfxCharts'
import './dfxCollapsablePanel'
import './dfxCommonDataIcon'
import './dfxCommonDataView'
import './dfxDeleteSaveCancel'
import './dfxEnumDropDown'
import './dfxEmployee'
import './dfxFavouriteAndSearchFilter'
import './dfxFormCommands'
import './dfxFormFooter'
import './dfxHideableSideBar'
import './dfxLabelPrinting'
import './dfxLayoutSelection'
import './dfxMaxWorkSpace'
import './dfxModal'
import './dfxModuleLayout'
import './dfxNumTouch'
import './dfxSettingsLaunch'
import './dfxSortCol'
import './dfxTechDatas'
import './dfxTimePopup'
import './dfxTimeRange'
import './dfxTimeStamps'
import './dfxTypeAhead'
import './hiTypeahead'
import './modalMovable'
import './dfxColumn/dfxColumn'
import './dfxDataForm'
import './dfxAggregateInput'
import './formatNumber.js'

const dirModule = angular.module('dir', ['dir.dfxFavourite', 'dir.dfxCalendarEdit', 'dir.dfxAddCommentButton', 'dir.dfxCharts', 'dir.dfxCollapsablePanel', 'dir.dfxCommonDataIcon',
'dir.dfxDeleteSaveCancel', 'dir.dfxEnumDropDown', 'dir.dfxEmployee', 'dir.dfxFavouriteAndSearchFilter', 'dir.dfxCommonDataView', 'dir.dfxFormCommands', 'dir.dfxFormFooter',
'dir.dfxHideableSideBar','dir.dfxLabelPrinting','dir.dfxLayoutSelection','dir.dfxMaximizeWorkSpaceButton','dir.dfxModal','dir.dfxModuleLayout','dir.dfxNumTouch',
'dir.dfxSettingsLaunch','dir.dfxSortCol','dir.dfxTechDatas','dir.dfxTimePopup','dir.dfxTimeRange','dir.dfxTimeStamps','dir.dfxTypeAhead','dir.hiTypeahead',
	'dir.modalMovable', 'dir.dfxColumn', 'dir.dfxDataForm', 'dir.dfxAggregateInput', 'dir.formatNumber'])
export default dirModule