'use strict';
var saveTextModule = angular.module('textdir.dfxSaveText',[]);
saveTextModule.directive('dfxSaveText', function () {
	// Provides implementation for uploading/saving texts.
	// Usage:
	// Is used both for creating and editing texts. Can display a creation button (opens up a modal) or show columns as a part of another modal.
	// Example:
	// <dfx-save-text parent="ctrl.selectedInvoice"></dfx-save-text>
	return {
		restrict: 'E', //E = element, A = attribute, C = class, M = comment 
		scope: {},
		controllerAs: 'ctrl',
		bindToController: {
			parent: '=', // Parent object entity.
			textDefaultsEnumKey: '=?', // TEXT_DEFAULTS enumeration key (value01). If not found, portal id is used as key. Enumeration is used for hiding columns, list values and default values. If not set, form is used for hiding.
			existingText: '=?',
			isDisabled: '=?',
			customBtnText: '=?',
			textMode: '=?',
			onTextAddedCallback: '&',
			onTextDeletedCallback: '&',
			onTextEditedCallback: '&'
		},
		controller: ['$scope', 'common', 'commonDatacontext', '$uibModal', '$q', '$http', 'spinner', function ($scope, common, commonDatacontext, $uibModal, $q, $http, spinner) {
			var ctrl = this;
			ctrl.enums = [];
			ctrl.files;
			ctrl.formId = 25109;

			ctrl.operEnum = {
				ADD: 'ADD',
				EDIT: 'EDIT',
				DELETE: 'DELETE'
			};

			ctrl.visibEnum = {
				HIDDEN: 0,
				READONLY: 1,
				EDITABLE: 2
			};

			ctrl.$onInit = function () {
				init();
			};

            /**
             * Gets list of enumerations from TEXT_DEFAULTS enumeration.
             * @param {string} key - Key or enumeration id. Used for finding correct enumeration extra select.
             * @return {Object[]} List of strings.
             */
			function getTextDefaultsList(key) {
				if (key && ctrl.enums['TEXT_DEFAULTS'] && ctrl.enums['TEXT_DEFAULTS'][0] && ctrl.enums['TEXT_DEFAULTS'][0].extraSelect) {
					var e = ctrl.enums['TEXT_DEFAULTS'][0].extraSelect;

					if (key === 'COLUMN_VISIBILITY' && e[1]) {
						return e[1].split(';');
					}
					if (key === 'TEXTSUBJECT' && e[2]) {
						return e[2].split(';');
					}
					if (key === 'TEXTSTATUS' && e[3]) {
						return e[3].split(';');
					}
					if (key === 'COPYCLASS' && e[5]) {
						return e[5].split(';');
					}
					if (key === 'USEPRINT' && e[6]) {
						return e[6].split(';');
					}
					if (key === 'LANGUAGE' && e[7]) {
						return e[7].split(';');
					}
					if (key === 'NAME' && e[8]) {
						return e[8].split(';');
					}
				}
				return [];
			}

            /**
             * Gets default value from TEXT_DEFAULTS enumeration.
             * @param {string} key - Key or enumeration id. Used for finding correct enumeration extra select.
             * @return {string} Default value.
             */
			function getDefaultFromTextDefaults(key) {
				if (key && ctrl.enums['TEXT_DEFAULTS'] && ctrl.enums['TEXT_DEFAULTS'][0] && ctrl.enums['TEXT_DEFAULTS'][0].extraSelect) {
					var e = ctrl.enums['TEXT_DEFAULTS'][0].extraSelect;
					if (key === 'NAME' && e[8]) {
						return e[8];
					}
					if (key === 'TEXTSUBJECT' && e[11]) {
						return e[11];
					}
					if (key === 'TEXTSTATUS' && e[12]) {
						return e[12];
					}
					if (key === 'COPYCLASS' && e[14]) {
						return e[14];
					}
					if (key === 'USEPRINT' && e[15]) {
						return e[15];
					}
					if (key === 'LANGUAGE' && e[16]) {
						return e[16];
					}
				}
				return [];
			}
            /**
             * Returns enumeration list either filtered by using TEXT_DEFAULTS enuemration or as such.
             * @param {string} enumName - Enumeration id
             * @param {Object[]} origList - All enumeration rows for given enumeration.
             * @returns {Object[]} Enumeration rows.
             */
			function getEnumList(enumName, origList) {
				var lst = [];
				var textDefaultsList = getTextDefaultsList(enumName);
				if (textDefaultsList.length > 0) {
					lst = origList.filter(function (value) {
						return -1 !== textDefaultsList.indexOf(value.id);
					});
				}
				else {
					lst = origList;
				}
				return lst;
			}

			/*Function to get text saving enumerations, which are used when populating dialog dropdowns/select-boxes*/
			function getEnums() {
				var promise;
				if (!ctrl.enums || !ctrl.enums['TEXT_DEFAULTS']) {
					// If textDefaults key is given as parameter, use it when querying enumerations. If not, use portal id.
					promise = commonDatacontext.getTextSaveEnums(ctrl.textDefaultsEnumKey || common.$route.current.portalId).then(function (data) {
						var obj = data;
						ctrl.enums['TEXT_DEFAULTS'] = obj['TEXT_DEFAULTS'];
						for (var e in obj) {
							if (e !== 'TEXT_DEFAULTS') {
								ctrl.enums[e] = getEnumList(e, obj[e]);
							}
						}
						return data;
					});
				}
				return $q.when(promise);
			}

            /**
             * Gets form settings from database if not queried before. Sets them to variable.
             * @returns {promise} Promise when done.
             */
			function getFormSettings() {
				var fs;
				if (!ctrl.formSettings) {
					fs = commonDatacontext.getFormSettings(ctrl.formId).then(function (data) {
						ctrl.formSettings = data;
						return data;
					});
				}
				return $q.when(fs);
			}

			// Initial actions.
			function init() {
				ctrl.saveBtnText = ctrl.customBtnText ? ctrl.customBtnText : common.$translate.instant("STRCONST.PUBLIC.BTN_ADD");
				getEnums().then(function () {
					setDefaultValues();
					setVisibility();
				});
			}

			/* Gets default values from directive parameters or from TEXT_DEFAULTS enumeration, if any. */
			function setDefaultValues() {
				// Clear text header and other information so that consequent uses will not have same header by default.
				ctrl.selName = getDefaultFromTextDefaults('NAME');
				ctrl.selSubject = getDefaultFromTextDefaults('TEXTSUBJECT');
				ctrl.selTextStatus = getDefaultFromTextDefaults('TEXTSTATUS');
				ctrl.selCopyClass = getDefaultFromTextDefaults('COPYCLASS');
				ctrl.selUsePrint = getDefaultFromTextDefaults('USEPRINT');
				ctrl.selLanguage = getDefaultFromTextDefaults('LANGUAGE');

				ctrl.textContent = '';
			}

            /**
             * Sets column visibility. Order: 1. TEXT_DEFAULTS enumeration, 2. form 25109.
             */
			function setVisibility() {
				var colNames = ['NAME', 'TEXTSUBJECT', 'TEXTSTATUS', 'COPYCLASS', 'USEPRINT', 'LANGUAGE'];
				ctrl.cols = [];
				if (ctrl.enums && ctrl.enums['TEXT_DEFAULTS'] && ctrl.enums['TEXT_DEFAULTS'][0]) {
					var cvl = getTextDefaultsList('COLUMN_VISIBILITY');

					angular.forEach(colNames, function (c) {
						ctrl.cols[c] = (cvl === [] || cvl.length === 0 || cvl.indexOf(c) > -1) ? ctrl.visibEnum.EDITABLE : ctrl.visibEnum.HIDDEN;
					});

				}
				else {
					getFormSettings().then(function () {
						if (ctrl.formSettings.columnSettings) {
							angular.forEach(colNames, function (c) {
								ctrl.cols[c] = ctrl.formSettings.columnSettings[c] && ctrl.formSettings.columnSettings[c].hidden ? ctrl.visibEnum.HIDDEN : ctrl.visibEnum.EDITABLE;
							});
						}
					});
				}
			}

			ctrl.open = function () {
				/*Note function getEnums waits for promise/results before opening modal dialog*/
				getEnums().then(function (data) {
					setDefaultValues();
					var extraButtons = ctrl.existingText ? '[{id: \'Delete\', name: \'' + common.$translate.instant('STRCONST.PUBLIC.TXT_DELETE') + '\', before: false, isDefault: false }]' : '';
					var templateUrl = './app/common/text/directives/dfxSaveTextModal.html';
					var modalInstance = $uibModal.open({
						animation: true,
						backdrop: false,
						scope: $scope,
						template: '<dfx-modal data-title="modalTitle" button-mask="7" extra-buttons="' + extraButtons + '" ok-btn-text="\'STRCONST.PUBLIC.TXT_SAVE\' | translate" cancel-btn-text="\'STRCONST.PUBLIC.TXT_CANCEL\' | translate"><div data-ng-include="\'' + templateUrl + '\'"></div></dfx-modal>',
						controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
							/*Initialize possible existing values (create text versus edit)*/
							$scope.existingText = $scope.ctrl.existingText;
							/*Change modal header according to operation*/
							$scope.modalTitle = $scope.existingText ? common.$translate.instant("STRCONST.PUR.GRP_UPDATE") + ' ' + common.$translate.instant("STRCONST.PUBLIC.TXT_TEXT") : common.$translate.instant("STRCONST.LEANPORTAL.HDR_ADDTEXT");
							if ($scope.existingText) {
								/*Set current values for their respective fields */
								ctrl.textContent = $scope.existingText.textContent;
								ctrl.selName = $scope.existingText.name;
								ctrl.selLanguage = $scope.existingText.language;
								ctrl.selDocStatus = $scope.existingText.status;
								ctrl.selCopyClass = $scope.existingText.copyClass;
								ctrl.selUsePrint = $scope.existingText.usePrint;
								ctrl.selSubject = $scope.existingText.subject;
								ctrl.selSubjectName = $scope.existingText.subjectName;
							}

							$scope.onApply = function (btn) {
								/*DELETE*/
								if (btn && btn.id === 'Delete') {
									if (confirm(common.$translate.instant("STRCONST.LEANPORTAL.QRY_DELETE_TEXT"))) {
										ctrl.existingText.entityAspect.setDeleted();
										$scope.saveChanges(ctrl.existingText, ctrl.operEnum.DELETE);
									}
								}
								/*EDIT*/
								else if (ctrl.existingText && ctrl.existingText.recId > 0) {
									ctrl.existingText.name = ctrl.selName;
									ctrl.existingText.copyClass = ctrl.selCopyClass;
									ctrl.existingText.language = ctrl.selLanguage;
									ctrl.existingText.subject = ctrl.selSubject;
									ctrl.existingText.status = ctrl.selTextStatus;
									ctrl.existingText.usePrint = ctrl.selUsePrint;
									ctrl.existingText.textContent = ctrl.textContent;
									$scope.saveChanges(ctrl.existingText, ctrl.operEnum.EDIT);
								}
								/*ADD*/
								else if (ctrl.parent && ctrl.parent.commonDataParents()) {
									var parent = ctrl.parent.commonDataParents();
									ctrl.textEntity = commonDatacontext.commonManager.createEntity('Text', {
										objectType: parent[0].objectType,
										objectRecId: parent[0].recId,
										name: ctrl.selName,
										copyClass: ctrl.selCopyClass,
										language: ctrl.selLanguage,
										subject: ctrl.selSubject,
										status: ctrl.selTextStatus,
										usePrint: ctrl.selUsePrint,
										textContent: ctrl.textContent
									});
									$scope.saveChanges(ctrl.textEntity, ctrl.operEnum.ADD);
								}
							};

							$scope.saveChanges = function (entity, oper) {
								commonDatacontext.saveChanges(entity).then(function (data) {
									switch (oper) {
										case ctrl.operEnum.ADD:
											ctrl.onTextAddedCallback({ text: data });
											break;
										case ctrl.operEnum.EDIT:
											//Is Callback from EDIT needed?
											ctrl.onTextEditedCallback({ text: data });
											break;
										case ctrl.operEnum.DELETE:
											ctrl.onTextDeletedCallback({ text: data });
											break;
										default:
											break;
									}
									$scope.onCancel();
								});
							}

							$scope.onCancel = function () {
								commonDatacontext.rejectChanges();
								$uibModalInstance.dismiss('cancel');
							};
						}]
					});
				});
			};
			$scope.$on('REJECTCHANGES', function (event, args) {
				if (args.param == true) {
					commonDatacontext.rejectChanges();
				}
			});
		}],
		template: function (elem, attrs) {
			return require('./dfxOpenSaveText.html');
		}
	};
});
export default saveTextModule