'use strict';
var dataViewModule = angular.module('dir.dfxCommonDataView',[]);
dataViewModule.directive('dfxCommonDataView', function () {
    return {
        restrict: 'EA', //E = element, A = attribute, C = class, M = comment 
        scope: {},
        controllerAs: 'ctrl',
        bindToController: {
            isOpen: '=',
            selectedObj: '=',
            owner: '=',
            showTitle: '=?',
            layout: '=?' // 'SIDEBAR' / 'TAB'
        },
        controller: 'commonDataViewCtrl',
        template: require('./dfxCommonDataView.html')
    }
});
export default dataViewModule