import * as metadata from './metadata.js';
    'use strict';
    var serviceId = 'entityManagerFactory';
    var entityManager = angular.module('entityManager',[]).factory(serviceId, ['config', 'model', '$http', emFactory]);

    function emFactory(config, model, $http) {
    	// backingStore works for Angular (ES5 property 'ready')
        breeze.config.initializeAdapterInstance('modelLibrary', 'backingStore', true);

		// We should not make any conversions
    	//breeze.NamingConvention.camelCase.setAsDefault();

    	// Tell breeze not to validate when we attach a newly created entity to any manager.
    	// We could also set this per entityManager
        new breeze.ValidationOptions({ validateOnAttach: false }).setAsDefault();

        //initBreezeLanguage(); Not currently in use. Requires refactoring in order to work with new language constants.

    	// use the current module's $http for ajax calls
    	var ajax = breeze.config.initializeAdapterInstance('ajax', 'angular');
    	ajax.setHttp($http);
        
        var serviceName = config.remoteServiceName;
        var dataService = createDataService();
        var metadataStore = createMetadataStore();
	    metadataStore.dataServices.push(dataService);
        metadataStore.importMetadata(metadata.hourInputMetadata, true);
        
        /** Call forceUpdate() on the component when an entity property or state changes */
        function subscribeComponent(manager, forceUpdate) {
            return manager.entityChanged.subscribe(function (data) {
                if (data.entityAction === breeze.EntityAction.PropertyChange || data.entityAction === breeze.EntityAction.EntityStateChange) {
                    forceUpdate();
                }
            });
        }

         /** Remove subscription created with subscribeComponent() */
        function unsubscribeComponent(manager, handler) {
            if (handler) {
                manager.entityChanged.unsubscribe(handler);
            }
        }
        
	    var provider = {
			dataService: dataService,
            metadataStore: metadataStore,
            newManager: newManager,
            subscribeComponent: subscribeComponent,
            unsubscribeComponent: unsubscribeComponent
        };
        
        return provider;

        function createMetadataStore() {
            var store = new breeze.MetadataStore();
            model.configureMetaDataStore(store);
            return store;
        };
		
        function createDataService() {
            var dataService = new breeze.DataService({
                serviceName: serviceName,
                hasServerMetadata: false
            });
            return dataService;
        }

        function newManager(service) {
            var manager = (service ? service : serviceName);
            var dataService = new breeze.DataService({
                serviceName: manager,
                hasServerMetadata: false
            });
            var store = new breeze.MetadataStore();
            model.configureMetaDataStore(store);
            store.dataServices.push(dataService);
            if (manager == "breeze/Common") {
            	store.importMetadata(metadata.commonMetadata, true);
            }
            else if(manager == "breeze/HourInput"){
                store.importMetadata(metadata.hourInputMetadata, true);
            }
            else if (manager == "breeze/Sales") {
                store.importMetadata(metadata.salesMetadata, true);
            }
            else if (manager == "breeze/Manufacturing") {
                store.importMetadata(metadata.manufacturingMetadata, true);
            }
            else if (manager == "breeze/Configurator") {
            	store.importMetadata(metadata.configuratorMetadata, true);
            }
            else if (manager == "breeze/Quality") {
                store.importMetadata(metadata.qualityMetadata, true);
            }
            else if (manager == "breeze/Purchase") {
            	store.importMetadata(metadata.purchaseMetadata, true);
            }
            else if (manager == "breeze/Supplier") {
            	store.importMetadata(metadata.supplierMetadata, true);
            }
            else if (manager == "breeze/StatusDisplay") {
                store.importMetadata(metadata.statusDisplayMetadata, true);
			}
			else if (manager == "breeze/Service") {
				store.importMetadata(metadata.serviceMetadata, true);
			}
            else if (manager == "breeze/Travel") {
				store.importMetadata(metadata.travelInvoiceMetadata, true);
			}
			else if (manager == "breeze/ReturnDelivery") {
				store.importMetadata(metadata.returnDeliveryMetadata, true);
			}
			else if (manager == "breeze/WorkBench") {
				store.importMetadata(metadata.workBenchMetadata, true);
			}
			else if (manager == "breeze/TimeStamps") {
				store.importMetadata(metadata.timeStampsMetadata, true);
			}

            var mgr = new breeze.EntityManager({
                dataService: dataService,
                serviceName: manager,
                metadataStore: store
            });
            return mgr;
        }

    	// Following in-build validations exist with following warning messages
    	// Make sure that the used validation contains language support!
    	//bool				"'%displayName%' must be a 'true' or 'false' value"
    	//creditCard		"The %displayName% is not a valid credit card number"
    	//date				"'%displayName%' must be a date"
    	//duration			"'%displayName%' must be a ISO8601 duration string, such as 'P3H24M60S'"
    	//emailAddress		"The %displayName% '%value%' is not a valid email address"
    	//guid				"'%displayName%' must be a GUID"
    	//int32				"'%displayName%' must be an integer between the values of -2147483648 and 2147483647"
    	//integer			"'%displayName%' must be an integer"
    	//*integerRange		"'%displayName%' must be an integer between the values of %minValue% and %maxValue%"
    	//maxLength			"'%displayName%' must be a string with %maxLength% characters or less"
    	//number			"'%displayName%' must be a number"
    	//phone				"The %displayName% '%value%' is not a valid phone number"
    	//regularExpression	"The %displayName% '%value%' does not match '%expression%'"
    	//*required			"'%displayName%' is required"
    	//string			"'%displayName%' must be a string"
    	//stringLength		"'%displayName%' must be a string with between %minLength% and %maxLength% characters"	
    	//url				"The %displayName% '%value%' is not a valid url"
        function initBreezeLanguage() {
			// Indicates the 
        	var dFrom = "%VALUE%";
        	var dTo = "<b>%displayName%</b>";
			breeze.Validator.messageTemplates['required'] = common.$translate.instant("STRCONST.LEANPORTAL.VALIDATE_REQUIRED").replace(dFrom, dTo);
			breeze.Validator.messageTemplates['integerRange'] = common.$translate.instant("STRCONST.LEANPORTAL.VALIDATE_INTEGER_RANGE").replace(dFrom, dTo);
        	// "'%displayName%' must be an integer between the values of -2147483648 and 2147483647"
			breeze.Validator.messageTemplates['int32'] = common.$translate.instant("STRCONST.LEANPORTAL.VALIDATE_INTEGER_RANGE")
																 .replace(dFrom, dTo)
																 .replace("%minValue%", "-2147483648")
																 .replace("%maxValue%", "2147483647");
        };

}

export { entityManager };