'use strict';
var app = angular.module('dir.dfxDataForm', []);
app.directive('dfxDataForm',function (){
	return {
		restrict: 'E',
		scope: {},
		controllerAs: 'ctrl',
		bindToController: {
			formId: '=',
			formVersion: '=?',
			columnType: '=?', // modalCell (default), dataFormCell
			modelColumnMap: '=?',
			modelName: '=',
			parentObject: '='
		},
		controller: ['$scope', '$timeout', 'common', 'commonDatacontext', 'usersettings', function ($scope, $timeout, common, commonDatacontext, usersettings) {
			var ctrl = this;
			
			ctrl.contentCols = [];
			ctrl.modelColumnMap = [];
			ctrl.formSettings = null;
			ctrl.$onInit = function () {
				init();
			}

			function init() {
				ctrl.columnType = ctrl.columnType === undefined ? 'modalCell' : ctrl.columnType;
				var promises = [];
				// Construct two dimensional array (ctrl.contentCols) containing column definitions
				promises.push(commonDatacontext.getFormSettings(ctrl.formId,false,ctrl.formVersion).then(function (data) {
					if (data) {
						ctrl.formSettings = data;
						var columns = [];
						angular.forEach(data.columnSettings, function (col) {
							columns.push(col);
						});

						columns.sort(function (s1, s2) {
							return s1.UniquePos > s2.UniquePos ? 1 : s1.UniquePos < s2.UniquePos ? -1 : 0;
						});
						var subCols = [];
						angular.forEach(columns, function (col) {
							if (col.columnType == "ePageColumnSeparator") {
								if (subCols.length) {
									ctrl.contentCols.push(subCols)
									subCols = [];
								}
							}
							else if (!col.hidden) {
								subCols.push(col)
							}
						});
						if (subCols.length) {
							ctrl.contentCols.push(subCols)
						}
					}
						
					return data;
				}));
				if (!ctrl.modelColumnMap) {
					promises.push(commonDatacontext.getModelColumnMap(ctrl.modelName).then(function (columnMap) {
						if (columnMap) {
							ctrl.modelColumnMap = columnMap;
						}
						return columnMap;
					}));
				}
				return promises;
			}
			
		}],
		template: require('./dfxDataForm.html')
		};
})
export default app