
	'use strict';
	var controllerId = 'singleworktime';
	var app= angular.module('app')
	app.directive('dfxSingleWorkTime', [function () {
		return {
			restrict: 'E',
			scope: {
				control: '='
			},
			controller: controllerId,
			controllerAs: 'ctrl',
			bindToController: {
				onCancelCallback: '&',
				onSaveCallback: '&',
				onPreventClosing: '&?',
				workId: '=' // Id of work which operations are displayed in the typeahead field.
			},
			templateUrl: 'SingleWorkTime.html'
		}
	}]);

	app.controller(controllerId, ['$scope', 'common', 'commonDatacontext', 'hiDatacontext', 'usersettings', singleworktime]);

	function singleworktime($scope, common, commonDatacontext, hiDatacontext, usersettings) {
		var ctrl = this;
		ctrl.curSelection = null;
		ctrl.date = moment.utc().toDate();
		ctrl.userId = null;
		ctrl.hasChanges = false;

		/* Properties */

		/**
		  * Cancel editing.
		  */
		ctrl.onCancel = function () {
			if (ctrl.onPreventClosing) {
				ctrl.hasChanges = false;
				ctrl.onPreventClosing({ hasChanges: false });
			}
			ctrl.onCancelCallback();
		}

		/**
		  * Occurs when employee selection has been done.
		  * @param{Selected item} item
		  */
		ctrl.onEmpSelected = function (item) {
			monitoreChanges(item);
			if (item) {				
				ctrl.employeeId = item.empId;
				ctrl.employeeName = item.name;
			}
			else {
				ctrl.employeeId = null;
				ctrl.employeeName = null;
			}
		}

		/**
		 * Occurs when operation selection has been done. Sets operation id and name.
		 * @param{Selected item} item
		 */
		ctrl.onOperationSelected = function (item) {
			monitoreChanges(item);
			if (item) {				
				ctrl.operatId = item.id;
				ctrl.operatName = item.name;
			}
			else {
				ctrl.operatId = null;
				ctrl.operatName = null;
			}
		}

		/**
		  * Saving new entity.
		  */
		ctrl.saveChanges = function () {

			// Check for data validity and required fields.
			var missingRequiredFieldNames = [];
			(ctrl.operatId && ctrl.operatId != '' ? '' : missingRequiredFieldNames.push(common.$translate.instant("STRCONST.PUBLIC.COL_TYPE")));
			(ctrl.date && ctrl.date != '' ? '' : missingRequiredFieldNames.push(common.$translate.instant("STRCONST.REP.COL_EVENT_DATE")));
			(ctrl.employeeId && ctrl.employeeId != '' ? '' : missingRequiredFieldNames.push(common.$translate.instant("STRCONST.PUBLIC.TXT_EMPID")));
			(ctrl.hours && ctrl.hours != '' ? '' : missingRequiredFieldNames.push(common.$translate.instant("STRCONST.WOR.TXT_EMPHOUR")));

			if (missingRequiredFieldNames.length > 0) {
				usersettings.errHandler(common.$translate.instant("STRCONST.DLG.TXT_REQDATAMISSING") + ": " + missingRequiredFieldNames.join(', ') + '.');
			}
			else { // All required fields filled in.

				// Create new entity with work and date values.
				var workTimeEntity = hiDatacontext.createWorkTime(null, true, null, null, ctrl.work, null, ctrl.date)

				// Collect data for the entity.
				workTimeEntity.employeeId = ctrl.employeeId;
				workTimeEntity.employeeName = ctrl.employeeName;
				workTimeEntity.description = ctrl.description;
				workTimeEntity.hours = ctrl.hours;
				workTimeEntity.operatId = ctrl.operatId;
				workTimeEntity.operatName = ctrl.operatName;
				workTimeEntity.userId = ctrl.userId;
				hiDatacontext.hourInputManager.addEntity(workTimeEntity);

				// Save
				hiDatacontext.saveChanges(workTimeEntity, false).then(function (saveResult) {
					hiDatacontext.hourInputManager.detachEntity(workTimeEntity);
					ctrl.onSaveCallback();
				},
				function (error) {
					hiDatacontext.hourInputManager.detachEntity(workTimeEntity);
				}
				);
			}
		};
		
		activate();

		$scope.$on('COMPANYOPUCHANGES', function () {
			activate();
		});

		ctrl.setHasChages = function (newValue) {
			monitoreChanges(newValue);
		};

		function monitoreChanges(newValue) {
			if (newValue && newValue !== '') {
				ctrl.hasChanges = true;
				if (ctrl.onPreventClosing) {
					ctrl.onPreventClosing({ hasChanges: true });
				}
			}
			else {
				if (ctrl.onPreventClosing) {
					ctrl.onPreventClosing({ hasChanges: false });
				}
				ctrl.hasChanges = false;
			}
		}

		/* Functions */

		function activate() {
			usersettings.getUserSettings().then(function (data) {
				if (!data || !data.info || !data.info.userId) {
					usersettings.redirectToLogin();
				} else {
					usersettings.authorizeHeader();
					ctrl.userId = data.info.userId;

					var promises = [];
					common.activateController(promises, controllerId).then(function () {
						getWork();
					});
				}
			}, function () {
				usersettings.redirectToLogin();
			});
		}

		/**
		 * Provides data for type ahead columns
		 */
		function getTypeaheadName(column, emp) {
			var ret = null;
			if (column < 2) {
				if (column == 0) {
					ret = emp.id;
				}
				else if (column == 1) {
					ret = emp.name;
				}
			}
			return ret;
		}

		/**
		  * Gets specific work from database.
		  */
		function getWork() {
			return hiDatacontext.getWorks(null, '', false, ctrl.workId).then(function (data) {
				// If any works were found, get the first one. There should be only one work as we are querying with exact work id.
				if (data && data[0]) {
					// Get work name, project data, task data and operations.
					ctrl.work = data[0];
					ctrl.workName = data[0].name;
					ctrl.operations = data[0].op;

					// Initialize typeahead column data.
					common.setTypeheadColumnData(ctrl.operations, getTypeaheadName, [true, false], 0, [10, 15], false);
				}
				else { // No work found with given work id. Raise an error.
					//usersettings.errHandler(strConst.ERROR$50003.replace('%1', ctrl.workId));
					//ctrl.onCancelCallback();
					ctrl.errMsg = common.$translate.instant("STRCONST.ERROR.50003").replace('%1', ctrl.workId);
				}
			});
		}
	}

export default app