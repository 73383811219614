'use strict';
var numTouchModule = angular.module('dir.dfxNumTouch',[]);
/**
	 * @class LeanPortalSPA.directives.dfxNumTouch
     * @memberOf LeanPortalSPA.directives
	 * @description Numeric field for touch screens.
     * 
     * @param {number} maxValue - Maximum value.
     * @param {number} [minValue=0] - Minimum value.
     * @param {function} [onValueChangedCallback] - Occurs after value is changed.
     * @param {number} [outerTotal] - Total value of the whole set.
     * @param {number} [step=5] - Number to add or subtract.
     * @param {number} valueModel - The actual value.
     * @example
     * <dfx-num-touch max-value="100" value-model="ctrl.qty" />
     * @example
     * <dfx-num-touch max-value="ctrl.state.selectedTask.qty"
     *                min-value="-10"
     *                on-value-changed-callback="ctrl.countTotal()"
     *                outer-total="ctrl.total"
     *                step="10"
     *                value-model="ctrl.qty" />
     */
numTouchModule.directive('dfxNumTouch', function () {
    return {
        restrict: 'E', //E = element, A = attribute, C = class, M = comment 
        scope: {},
        controllerAs: 'ctrl',
        bindToController: {
            // See parameter descriptions above.
            maxValue: '=', 
            minValue: '=?',
            onValueChangedCallback: '&', 
            outerTotal: '=?', 
            step: '=?', 
            valueModel: '='
        },
        controller: [function () {
            var ctrl = this;
            ctrl.$onInit = function () {
                ctrl.minValue = ctrl.minValue === undefined ? 0 : ctrl.minValue;
                ctrl.step = ctrl.step === undefined ? 5 : ctrl.step;
            };
            
            /**
             * Adds or subtracts quantity of given entity.
             * The result cannot be less than minimum or greater than maximum.
             * @param {number} qty - Amount to be added or subtracted.
             */
            ctrl.alterQty = function (qty) {
                if (ctrl.outerTotal + qty > ctrl.maxValue) {
                    ctrl.valueModel += ctrl.maxValue - ctrl.outerTotal;
                }
                else if (ctrl.valueModel + qty > ctrl.maxValue) {
                    ctrl.valueModel = ctrl.maxValue;
                }
                else if (ctrl.valueModel + qty < ctrl.minValue) {
                    ctrl.valueModel = ctrl.minValue;
                }
                else {
                    ctrl.valueModel += qty;
                }
                if (ctrl.onValueChangedCallback) ctrl.onValueChangedCallback();
            };

        }],
        transclude: false,
        template: require('./dfxNumTouch.html')
    };
});
export default numTouchModule