'use strict';

var module = angular.module('common');

// Configure Toastr
toastr.options.timeOut = 4000;
toastr.options.positionClass = 'toast-bottom-right';

// For use with the HotTowel-Angular-Breeze add-on that uses Breeze
// Currently we use one composite controller 'HourInputController' which provides access to all server resources
// TODO: Split the controller to domain/data specific services
var remoteServiceName = 'breeze/HourInput';

var events = {
	controllerActivateStarted: 'controller.activateStarted',
	controllerActivateSuccess: 'controller.activateSuccess',
	entitiesChanged: 'datacontext.entitiesChanged',
	hasChangesChanged: 'datacontext.hasChangesChanged',
	spinnerToggle: 'spinner.toggle',
	storage: {
		error: 'store.error',
		storeChanged: 'store.changed',
		wipChanged: 'wip.changed'
	}
};

var config = {
	appErrorPrefix: '[LeanPortal Error] ', //Configure the exceptionHandler decorator
	docTitle: 'LeanPortal: ',
	events: events,
	remoteServiceName: remoteServiceName,
	version: '2.1.0'
};

module.value('config', config);

module.config(['$logProvider', function ($logProvider) {
	// turn debugging off/on (no info or warn)
	if ($logProvider.debugEnabled) {
		$logProvider.debugEnabled(true);
	}
}]);

//#region Configure the common services via commonConfig
module.config(['commonConfigProvider', function (cfg) {
	cfg.config.controllerActivateStartedEvent = config.events.controllerActivateStarted;
	cfg.config.controllerActivateSuccessEvent = config.events.controllerActivateSuccess;
	cfg.config.spinnerToggleEvent = config.events.spinnerToggle;
}]);
//#endregion

export { config, events, remoteServiceName }
export default module
