
    'use strict';
    var controllerId = 'topnav';
	var app = angular.module('app')

	angular.module('common').controller(controllerId, ['$scope', 'common', 'usersettings', 'hiDatacontext', 'commonDatacontext', '$uibModal','tmhDynamicLocale', topnav]);

	function topnav($scope, common, usersettings, hiDatacontext, commonDatacontext, $uibModal, tmhDynamicLocale) {
        var getLogFn = common.logger.getLogFn;
        var log = getLogFn(controllerId);
        var tn = this;
		tn.TestEnvironment = common.getAppSetting("TestEnvironment", "");
		tn.languageSelectorEnabled = common.getAppSetting("LanguageSelectorEnabled", "1");
		tn.profilingEnabled = common.getAppSetting("ProfilingEnabled", "0");
		tn.profilingUser = common.getAppSetting("ProfilingUser", "LEANADM");
		tn.defaultPortal = common.getAppSetting("DefaultPortal", "");
        tn.currentPortalTabs = [];
        tn.userinfo = [];
        tn.portalinfo = [];
        tn.opuinfo = [];
        tn.companyinfo = [];
        tn.profilingActivated = false;
        tn.selectedPortal = null;
        tn.isSaving = false;
        tn.portalTitle = '';
        tn.passwordWarning = false;
        tn.lockPasswordBtn = true;    
		tn.docPath = '';
		tn.isSubstituteModalAvailable = false;
        tn.hideMainMenu = true;

        /**  
         * Show "Main menu" -portal link (HOME) when enough portals and its available
         */
        // Check that the user has logged in
        usersettings.getLoginPromise().then(() => {
            // When logged in, check how many portals are available
            usersettings.getPortals().then((portals) => {
                 // Check that HOME portal is on the list of all available portals
                 if (portals.length > 2) {
                    portals.forEach(p => {
                        if (p.portalId === 'HOME') tn.hideMainMenu = false;
                    });
                }
            });
        });


         /**
         * Listen to window resize event for toggling three dots (desktop) & hamburger menu (mobile) icon
         */
        window.addEventListener('resize', () => { tn.menuIcon = tn.calcMenuIcon(); });
        
        tn.calcMenuIcon = () => {
            let icon = "fa-ellipsis-h";
            if (window.innerWidth <= 768) icon = "fa-bars";
            return icon;
        }
  
        tn.menuIcon = tn.calcMenuIcon();
  

		$scope.$on('MAXIMIZE_WORK_SPACE', function (event, args) {
			args.maximized ? requestFullScreen(document.body) : normalizeFullScreen(document.body);
		});


	    tn.getCurrentCompany = function () {
	        if (tn.companyinfo != [] && tn.userinfo != [] && tn.companyinfo != null && tn.userinfo !=null) {
	            for (var i = 0; i <= tn.companyinfo.length - 1; i++) {
	                if (tn.companyinfo[i].compId == tn.userinfo.company) {
	                    return tn.companyinfo[i].compName;
	                }
	            }
	        }
	    }

        function initLanguages() {
                tn.language = common.$translate.use(); // Get used language.
                if (tn.languageSelectorEnabled == '1') {
                    //tn.language = window.localStorage.getItem('NG_TRANSLATE_LANG_KEY'); //get used language
                    tn.languages = common.$translate.getAvailableLanguageKeys();
                    var enums = [{ id: 'LANGUAGE_001' }];
                    //var lang = localStorage.getItem("NG_TRANSLATE_LANG_KEY");
                    commonDatacontext.getLookups(tn.language ? tn.language.toUpperCase() : null, null, null, localStorage, JSON.stringify(enums)).then(function (data) {
                        tn.nameLanguages = [];
                        var languageEnumArr = data[0].Enums.LANGUAGE;
                        for (var i = 0; i < tn.languages.length; i++) {
                            var idToMatch = tn.languages[i].toUpperCase();
                            for (var j = 0; j < languageEnumArr.length; j++) {
                                if (languageEnumArr[j].id == idToMatch) {
                                    tn.nameLanguages.push(languageEnumArr[j]);
                                }
                            }
                        }
                    });
                }
            
		}

        tn.changeLanguage = function (langKey) {
            usersettings.changeLanguage(langKey.toUpperCase()).then(function () { //change user $LANGUAGE$ param.
                common.$translate.use(langKey).then(function () { //set used language
                    usersettings.setUserSettingValue('paramLanguage', langKey.toUpperCase());
                    common.$window.location.reload();
                });
            });
        };

    	/**
		 * Changes portal to portal whose id is given as parameters.
		 * @param {Id of portal to change to.} portalId
		 */
	    tn.changePortal = function (portalId, tabId) {	    	
	    	changePortal(portalId, tabId);
	    }

	    function changePortal(portalId, tabId) {
        	usersettings.changePortal(portalId, tabId);
            tn.closeMenu();
        }

        tn.selectOPU = function (opudata) {
        	if (hiDatacontext.hasChanges()) {
        		var ans = confirm(common.$translate.instant("STRCONST.LEANPORTAL.TXT_UNSAVED_CHANGES_EXISTS") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SAVE_CHANGES_TO_SERVER"));
                if (ans && !tn.isSaving) {
                    tn.saveChanges();
                }
            }
            if(opudata != null){
            usersettings.changeOPU(opudata).then(function (data) {
            },
                function () {
                });
            }
        }

        tn.selectCompany = function (companyId) {
        	if (common.getBeforeUnloadValue() == false) {
        		if (hiDatacontext.hasChanges()) {
        			var ans = confirm(common.$translate.instant("STRCONST.LEANPORTAL.TXT_UNSAVED_CHANGES_EXISTS") + " " + common.$translate.instant("STRCONST.LEANPORTAL.TXT_SAVE_CHANGES_TO_SERVER"));
        			if (ans && !tn.isSaving) {
        				tn.saveChanges();
        			}
        		}
        		if (companyId != null) {
        			usersettings.getChangeCompany(companyId.compId).then(function (data) {
        			},
						function () {
						});
        		}
        	}
        	else {
        		preventMovementPopup(
				//onOk
				function () {						
					//remove all changes.
					common.$broadcast('REJECTCHANGES', { param: true });
					tn.selectCompany(companyId)
				 },
				 //onCancel
				 function () { });
        	}
        }
        tn.saveChanges = function () {
            tn.isSaving = true;

            try {
            	hiDatacontext.saveChanges().then(
							function (saveResult) {
							   
								hiDatacontext.clearFavourites();
							},
							function (error) {
							    errMsg(error);
							});
            } finally {
                tn.isSaving = false;
            }
        }

        tn.disableCurrentOPU = function (opu) {
            if (opu == tn.userinfo.opu) {
                return true;
            }
            else {
                return false;
            }
        }
		
        tn.helpFileExists = function () {
            return common.helpFileExists(tn.docPath);
        };

        function getManuals(language) {
            return usersettings.getManuals(language);
        }

        tn.isTabActive = function (tabUrl) {
        	return tabUrl && common.$route.current && tabUrl == common.$route.current.originalPath;
        }

        tn.openHelpFile = function () {
            common.openHelpFile(tn.docPath);
        };

        tn.logoff = function () {
        	if (common.getBeforeUnloadValue()) {
            	preventMovementPopup(
				 //onOk
					 function () {					 
					 	//remove all changes.
					 	common.$broadcast('REJECTCHANGES', { param: true });
					 	usersettings.logoff(tn.language).then(function () {
					 		common.$location.search('leadId', null);
					 		tn.userinfo = [];
					 		usersettings.redirectToLogin("logout")
					 	});
					 },
					  //onCancel
					  function () { });
            }
            else {
            	tn.profilingActivated = false;
                usersettings.logoff(tn.language).then(function () {
                	common.$location.search({});
                    tn.userinfo = [];
                    usersettings.redirectToLogin("logout")
                });
            }

        };

		/**
		 * checks if the profiling is enabled and available for the user, then checks its status
		 */
        tn.getLeanProfilingStatus = function () {
        	if (tn.profilingEnabled === '1' && (tn.profilingUser === tn.userinfo.userId || tn.profilingUser === '')) getLeanProfilingStatus(); //if enabled and available, get status
        }

        function getLeanProfilingStatus () { 
        	commonDatacontext.getLeanProfilingStatus().then(function (data) {
        		//sets or removes the spinning cog depending on data returned (true or false)
        		tn.profilingActivated = data;
        	});
        }

        tn.refreshUserInfo = function () {
            usersettings.getLoginPromise().then(function () {
                usersettings.getUserSettings().then(function (data) {
                    tn.userinfo = data ? data.info : [];
                    initLanguages();
                    usersettings.authorizeHeader();
                    tn.getLeanProfilingStatus();
                    portalSearch();
                    opuSearch();
                    companySearch();
                    tn.checkDebugMode();
					setSelectedLocale();
					tn.isSubstituteModalAvailable = tn.userinfo.canUseSubstituteDialog;
                },
                    function (data) {
                        tn.userinfo = [];
                    });
            });

        };

        /**
         * Sets locale, based on locale from global variable lang (which is taken from browser content language).
         */
        function setSelectedLocale() {
            var locale = lang;
            if (locale === "se") {
                locale = "sv";
            }
            if (["sv", "fi", "de", "en-GB"].indexOf(locale) > -1) {
                tmhDynamicLocale.set(locale);
            }
		}

    	/**
		 * Redirects user to given path.
		 * @param {Portal tab type.} tabType
		 * @param {Portal tab url.} tabUrl
		 */
        tn.redirect = function (tabType, tabUrl) {
        	if (common.getBeforeUnloadValue()) {
        		preventMovementPopup(
					//onOk
					function () {						
						//remove all changes.
						common.$broadcast('REJECTCHANGES', { param: true });
						common.redirectFromTabUrl(tabType, tabUrl);
					},
					//onCancel do nothign
					function () { }
					);
        	}
        	else if (tabUrl && tabUrl[0] == '$') {
        		var tabUrlSub = tabUrl.substring(1, tabUrl.length - 1);
        		var tabUrlParams = tabUrlSub.split('.');
        		var portalId = tabUrlParams[0];
        		var tabId = tabUrlParams.length > 1 ? tabUrlParams[1] : null;
        		if (portalId) {
        			changePortal(portalId, tabId);
        		}
        	}
			else{
        		common.redirectFromTabUrl(tabType, tabUrl);
                tn.closeMenu();
        	}
        };

        /** 
         * Close the menuburger so it won't stay open after tab/portal is clicked and switched from the menu 
         * */
        tn.closeMenu = () => {
            document.querySelector("#topnav-view .m-prioritycontainer__button.close").click();
        }
        

        /**
         * Toggles browser fullscreen mode.
         */
        tn.toggleFullScreen = function (element) {
            var isInFullScreen = (document.fullscreenElement && document.fullscreenElement !== null) ||
                                 (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
                                 (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
                                 (document.msFullscreenElement && document.msFullscreenElement !== null);
            if (!element) {
                element = document.documentElement;
            }
			if (isInFullScreen) {
				normalizeFullScreen(element);
            }
			else {
				requestFullScreen(element);
            }
		}
		function normalizeFullScreen(element) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
		}

		function requestFullScreen(element) {
			// Supports most browsers and their versions.
			var requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullscreen;

			if (requestMethod) { // Native full screen.
				requestMethod.call(element);
			} else if (typeof window.ActiveXObject !== "undefined") { // Older IE.
				var wscript = new ActiveXObject("WScript.Shell");
				if (wscript !== null) {
					wscript.SendKeys("{F11}");
				}
			}
		}

        $scope.$on('USERSETTINGS', function () {
        	tn.refreshUserInfo();
        	tn.getLeanProfilingStatus();
        });

		/**
		 * watches for changes in profiling activity and checks the status, if changes were broadcasted.
		 */
        $scope.$on('PROFILINGSTATUSCHANGED', function () {
        	tn.getLeanProfilingStatus();
        });

        $scope.$on('PORTALCHANGE', function (event, args) {
	        var lastPortalId;
        	var lsKey = usersettings.getLocStoKey('PersonalSettings');
        	if (localStorage && localStorage.getItem(lsKey)) {
        		var lst = JSON.parse(localStorage.getItem(lsKey));
        		if (lst && lst.lastPortalId) {
			        lastPortalId = lst.lastPortalId;
		        }
        	}
            if(args && args.portalId){
                var portalId = (args && args.portalId ? args.portalId : (lastPortalId || tn.defaultPortal));
                tn.selectedPortal = portalId.toUpperCase();
            }
			else if (common.$route.current) {
				tn.selectedPortal = common.$route.current.portalId;
            }
            else {
                tn.selectedPortal = (lastPortalId || tn.defaultPortal);
            }
        	
            tn.currentPortalTabs = common.getTabs(tn.selectedPortal);
            setPortalHelpFile();
        });

        $scope.$on('PREVENTMOVEMENT', function (event, args) {
            if (args.param == true) {
                preventMovement = true;
            }
            else {
                preventMovement = false;
            }
        });

        $scope.$on('UNAUTHORIZED', function () {
        	tn.userinfo = [];
        });

        $scope.$on('MAINTENANCE', function () {
            tn.portalinfo = [];
        });

        activate();

        function activate() {
            common.activateController(controllerId).then(function () {                
            	tn.refreshUserInfo();
            	setPortalHelpFile();
            });
        }

        function preventMovementPopup(onOk, onCancel) {
        	common.openModalMessage($scope, common.$translate.instant("STRCONST.INV.GRP_WARNING"), common.$translate.instant("STRCONST.LEANPORTAL.QRY_SUBMIT_UNSAVED"), onOk, onCancel);
        }
        
        function portalSearch() {
        	if (!common.$rootScope.isWorkingLocally) {
        		tn.portalinfo = usersettings.getPortals().then(function (data) {
					var portalsAvailableArr = [];				
					var portalsAvailable = common.getAppSetting("PortalsAvailable", "");
					
        			if (portalsAvailable) {
        				portalsAvailableArr = portalsAvailable.split(';');
        			}
        			/*User roles might limit portal count*/
        			if (data.length != portalsAvailableArr.length) {
        				var pId = common.$route.current.portalId;
        				if (pId) {
        					for (var i = 0; i < data.length; i++) {
        						if (data[i].portalId == pId) {
        							tn.portalinfo = data;
        							common.setPortalInfo(data);
        							common.$broadcast('PORTALCHANGE');
        							return tn.portalinfo = data ? data : [];
        						}
        					}
        					common.logger.logWarning(common.$translate.instant("STRCONST.LEANPORTAL.PORTAL_NOT_AVAILABLE"), false, false, true);
        					tn.logoff();
        					return;
        				}
        				else if (!pId && data.length > 0) {
        					tn.portalinfo = data;
        					common.setPortalInfo(data);
        					common.$broadcast('PORTALCHANGE');
        					return tn.portalinfo = data ? data : [];
        				}
        				else {
        					tn.logoff();
        					return;
        				}
        			}
        			tn.portalinfo = data;
        			common.setPortalInfo(data);
        			common.$broadcast('PORTALCHANGE');
        			return tn.portalinfo = data ? data : [];
        		},
                function (data) {
                	tn.portalinfo = [];
                	common.$broadcast('PORTALCHANGE');
                	return tn.portalinfo = [];
                });
        	}
        	else if (common.$rootScope.isWorkingLocally) {
				/*Show portal name in offline-mode*/
        		var portal = {};
        		portal.name = tn.currentPortalTitle();
        		tn.portalinfo.push(portal);
        	}
        }

        function opuSearch() {
            tn.opuinfo = commonDatacontext.getOPUs().then(function (data) {
                var lsKey = usersettings.getLocStoKey('AvailableOPUs', false, true);
                localStorage.setItem(lsKey, JSON.stringify(data));
                if (common.$rootScope.isWorkingLocally) {
                    var temp = [];
                    var luiKey = usersettings.getLocStoKey('LastUserInfo', true);
                    var userId = JSON.parse(localStorage.getItem(luiKey)).userId;
                    var company = JSON.parse(localStorage.getItem(luiKey)).company;
                    if (data.length == 0) {
                        return tn.opuinfo = [];
                    }
                    else {
                        for (var i = 0; i <= data.length - 1; i++) {
                            var opu = data[i];
                            lsKey = usersettings.getLocStoKey(company + '_' + opu.OPU + '_' + userId + '_UserSettings', true);
                            if (localStorage.getItem(lsKey)) {
                                temp.push(opu);
                            }
                        }
                        tn.opuinfo = temp;
                        return tn.opuinfo;
                    }
                } else {
                    return tn.opuinfo = data ? data : [];
                }
            },
                function (data) {
                    return tn.opuinfo = [];
                });
        }
        
        function companySearch() {
            tn.companyinfo = commonDatacontext.getCompany().then(function (data) {
                var lsKey = usersettings.getLocStoKey('AvailableCompanies', false, false, true);
                localStorage.setItem(lsKey, JSON.stringify(data));
                if (common.$rootScope.isWorkingLocally) {
                    var temp = [];
                    var luiKey = usersettings.getLocStoKey('LastUserInfo', true);
                    var userId = JSON.parse(localStorage.getItem(luiKey)).userId;
                    for (var i = 0; i <= data.length - 1; i++) {
                        var company = data[i];
                        var lsopuKey = usersettings.getLocStoKey(company.compId + '_' + userId +'_'+ 'AvailableOPUs', true);
                        var opulist = JSON.parse(localStorage.getItem(lsopuKey));
                        if (opulist == null || opulist.length == 0) {
                            lsKey = usersettings.getLocStoKey(company.compId + '__' + userId + '_UserSettings', true);
                            if (localStorage.getItem(lsKey)) {
                                temp.push(company);
                            }
                        } else {
                            for (var j = 0; j <= opulist.length - 1; j++) {
                                var opu = opulist[j].OPU;
                                lsKey = usersettings.getLocStoKey(company.compId + '_' + opu + '_' + userId + '_UserSettings', true);
                                if (localStorage.getItem(lsKey)) {
                                    temp.push(company);
                                    break;
                                }
                            }
                        }
                    }
                    tn.companyinfo = temp;
                    return tn.companyinfo;
                } else {
                    return tn.companyinfo = data ? data : [];
                }
            },
            function (data) {
                return tn.companyinfo = [];
            });
        }

        function getStrConst(strConst) {
            return strConst;
        }

        function setPortalHelpFile() {
            var docPathList = '';
            var docPaths = [];
            tn.docPath = '';
            getManuals(tn.language).then(function (data) {
                docPathList = data[0];
                if (docPathList) docPaths = docPathList.split(";");
                /*check if selected portal exists in the list of document paths*/
                var pId = common.$route.current.portalId;
                for (var i = 0; i < docPaths.length; i++) {
                    if (docPaths[i].indexOf(pId) > -1) {			 //document found. Assign url and break.
                        tn.docPath = docPaths[i];
                        break;
                    }
                    else tn.docPath = '';
                }
            });
        }

		/**
		 * Sets current portal title. Taken in the order
		 * 1. From config.route portalNameFunc
		 * 2. From strConst with portal name (using fixed prefix LEANPORTAL$PORTAL_NAME_)
		 * 3. From config.route titleFunc
		 * 4. From config.route title
		 * @returns {Portal title} 
		 */
        tn.currentPortalTitle = function () {
            tn.portalTitle = '';
            if (common.$route.current && common.$route.current) {
            	if (common.$route.current.portalNameFunc) {
            		for (var i = 0; i < tn.portalinfo.length; i++) {
            			if (tn.portalinfo[i].portalId == common.$route.current.portalId) {
            				tn.portalTitle = tn.portalinfo[i].name;
            				break;
            			}
            		}
            	} else if (common.$route.current.portalId) {
            		for (var i = 0; i < tn.portalinfo.length; i++) {
            			if (tn.portalinfo[i].portalId == common.$route.current.portalId) {
            				tn.portalTitle = tn.portalinfo[i].name;
            				break;
            			}
            		}
            		
            	} else if (common.$route.current.titleFunc) {
            		tn.portalTitle = eval(common.$route.current.titleFunc);
            	} else if (common.$route.current.title) {
            		tn.portalTitle = common.$translate.instant(common.$route.current.title);
            	}
            }
	        return tn.portalTitle;
        }

        tn.checkDebugMode = function () {
            var lsid = usersettings.getLocStoKey('PersonalSettings');
            if (localStorage && localStorage.getItem(lsid)) {
                var lst = JSON.parse(localStorage.getItem(lsid));
                if (lst) {
                    var isValue = !((typeof (lst.debug)) == 'undefined');
                    var mode = isValue ? lst.debug : false;
                    common.logger.setDebugMode(mode);
                }
            }
        }

        window.onbeforeunload = function () {
        	if (common.getBeforeUnloadValue()) {
        		return common.$translate.instant("STRCONST.LEANPORTAL.QRY_SUBMIT_UNSAVED");
            }
        }

        tn.openOptionsModal = function () {
            var settings = [];
            /*Add general settings*/
            settings.push('dfx-settings-all');
            /*Add current portal settings*/
            switch (tn.selectedPortal) {
                case 'TIMEREPORTING':
                    settings.push('dfx-settings-time-reporting');
                    break;
                case 'CONFIGURATOR':
                    settings.push('dfx-settings-configurator');
                    break;
            	case 'PURCHASE':
            		settings.push('dfx-settings-purchase');
            		break;
            	case 'MANUFACTURING':
            		settings.push('dfx-settings-manufacturing');
					break;
				case 'WORKBENCH':
					settings.push('dfx-settings-work-bench');
					break;
            	default:
                    //
            }
            common.openPortalSettings($scope, settings);
        };

        tn.openPasswordModal = function () {
            var modalInstance = $uibModal.open({
                animation: false,
                backdrop: false,
                template: require('./directives/passwordModal.html'),
                controller: 'PasswordModalCtrl'
            });

            modalInstance.result.then(function () {
                clearPasswordValues()
            }, function () {
                clearPasswordValues()
            });
        };
        
        function clearPasswordValues() {
        	delete $scope.oldPassword;
        	delete $scope.newPassword;
        	delete $scope.newPasswordConfirm;
        	$scope.passwordWarning = false;
        }

        tn.openSubstitutesModal = function () {
            function onOk() {
                commonDatacontext.saveChanges();
                return false;
            }

            function onCancel() {
                if (commonDatacontext.hasChanges()) {
                    commonDatacontext.rejectChanges();
                }
            }

            common.openModal($scope, require('../common/user/directives/substitutesDialog.html'), onOk, onCancel, 'xxl');
        }

    }

    angular.module('common').controller('PasswordModalCtrl', function ($scope, $uibModalInstance, usersettings, common) {
    	$scope.changeUserPassword = function (oldPassword, newPassword) {
    		var oldPswd = oldPassword;
    		if (!oldPassword) {
    			oldPswd = '';
    		}
    		usersettings.changePassword(oldPswd, $scope.newPassword).then(function (data) {
    			if (data) {
    				var msg = common.$translate.instant("STRCONST.PUBLIC.MSG_PASSWORD_CHANGED");
    				common.logger.logSuccess(msg, false, false, true);
    				$uibModalInstance.close();
    			}
    			else {
    				var msg = common.$translate.instant("STRCONST.PUBLIC.ERR_PASSWORD_CHANGE_FAILED");
    				$scope.passwordWarning = true;
    				$scope.lockPasswordBtn = true;
    				common.logger.logWarning(msg, false, false, true);
    			}
    		});
    	}
    	$scope.comparePasswords = function () {
    		if ($scope.newPassword && $scope.newPasswordConfirm) {
    			if ($scope.newPassword.length > 0 && $scope.newPasswordConfirm.length > 0 && ($scope.newPasswordConfirm != $scope.newPassword)) {
    				return true;
    			}
    		}
    		return false;
    	}
    	$scope.allowPasswordBtn = function () {
    		if ($scope.lockPasswordBtn = true) {
    			$scope.lockPasswordBtn = false;
    			$scope.passwordWarning = false;
    		}
    	}
    	$scope.ok = function () {
    		$uibModalInstance.close();
    	};
    	$scope.cancel = function () {
    		$uibModalInstance.dismiss('cancel');
    	};
    });
export default app
