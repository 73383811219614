'use strict';
var labelPrintingModule = angular.module('dir.dfxLabelPrinting',[]);
labelPrintingModule.directive('dfxLabelPrinting', function ()
    // Can be added with objectRecId by using the following code, where the XXXXX represents the objectRecId: <dfx-label-printing object-recid="XXXXX" object-table="WOR_WORK"></dfx-label-printing>
{
    return {
        restrict: 'E', //E = element, A = attribute, C = class, M = comment 
        scope: {},
        bindToController: {
            displayMode: '=?', // Are commands displayed and how fields are displayed. Possible options NORMAL (default) or MODAL (button not shown, fields in modal layout).
            labelId: '=?', // Label id. Enumeration id from LABEL_PRINTING enumeration.
            labelPrinterId: '=?', // Label printer id. Enumeration id from LABEL_PRINTERS enumeration.
            noPrintingOption: '=?', // If user can select to not print at all, set this option to true. Default is false.
            objectRecid: '=', // Recid of the object which is printed.
            objectTable: '=?', // Table name of object which is printed.
            defaultParams: '=?', // Parameters for default label (GetDefaultLabel) and printer (GetDefaultLabelPrinter) fetch 
            onControllerCallback: '&?' // Call back function for e.g. registering child controller.
        },
        controllerAs: 'ctrl',
        template: function (elem, attr) {
            if (attr && attr.displayMode && attr.displayMode.toUpperCase() === "'MODAL'") {
                return require('./dfxLabelPrintingModal.html');
            }
            else {
                return require('./dfxLabelPrinting.html');
            }
        },
        controller: ['$scope', 'common', 'commonDatacontext', function dfxlabelprinting($scope, common, commonDatacontext) {

            /**** Properties ****/

            var ctrl = this;
            var getLogFn = common.logger.getLogFn;
            var errMsg = getLogFn('dfxLabelPrinting', 'error');

            var showPrinterSelection = 1; // disable printer selection, while label selection will define the visibility/editability

            $scope.$watch('ctrl.selectedLabelId', function (value, oldvalue) {
                if (!value && value === oldvalue) {
                    return;
                }
                if (ctrl.defaultParams) {
                    selectDefaultPrinter(ctrl.selectedLabelId);
                }
                else {
                    setPrinterVisibilityFromLabel(ctrl.selectedLabelId);
                }
            });

            // Functions offered for the caller with onControllerCallback.
            var service = {
                printLabelToFile: printLabelToFile
            };

            ctrl.labelIdList = []; // List of possible label id's.
            ctrl.labelPrinterIdList = []; // List of label printer id's.

            ctrl.$onInit = function () {
                if (typeof ctrl.displayMode === 'undefined') {
                    ctrl.displayMode = 'NORMAL';
                }
                if (typeof ctrl.labelId === 'undefined') {
                    ctrl.labelId = '';
                }
                if (typeof ctrl.labelPrinterId === 'undefined') {
                    ctrl.labelPrinterId = '';
                }
                if (typeof ctrl.noPrintingOption === 'undefined') {
                    ctrl.noPrintingOption = false;
                }
                if (typeof ctrl.objectTable === 'undefined') {
                    ctrl.objectTable = '';
                }

                if (ctrl.onControllerCallback) {
                    ctrl.onControllerCallback({ childCtrl: service });
                }

                init();
            };

            ctrl.selectedLabelId = null; // In the end this is sent as parameter to printing command.
            ctrl.selectedLabelPrinterId = null;

            /**
             * Define if printer is visible/editable from label enumeration
             */
            function setPrinterVisibilityFromLabel(labelId) {
                var enumRow = common.getEnumRowById(ctrl.labelIdList, labelId);
                if (enumRow && enumRow.extraSelect && enumRow.extraSelect[0]) {
                    // If the value is SELECT then user may select printer from the list.
                    if (enumRow.extraSelect[0] == 'SELECT') { // TODO: Support for value LOCATION_DEF_SELECT
                        showPrinterSelection = 2;
                    }
                    else { // Printer cannot be selected. It is defined by label template or user location.
                        showPrinterSelection = 0;
                    }
                }
            }

            /**
             * Select default printer and define if printer is visible/editable
             */
            function selectDefaultPrinter(labelId) {
                ctrl.defaultParams.labelId = labelId;
                commonDatacontext.getDefaultLabelPrinter(ctrl.defaultParams).then(function (data) {
                    if (data && data.length) {
                        ctrl.selectedLabelPrinterId = data[0].defaultPrinterId ? data[0].defaultPrinterId : null;
                        // LIB_LABELPRINT_001.GetDefaultLabelPrinter anPrinterSelection values  
                        // 0 = do not show printer selection to user, 
                        // 1 = show printer selection and let user select a printer from it, 
                        // 2 = show printer selection, but do not let user to select / change printer, 
                        // NULL = cannot tell because not enough information given(= label id missing) 
                        if (data[0].printerSelection == 0) {
                            showPrinterSelection = 0;
                        }
                        else if (data[0].printerSelection == 1) {
                            showPrinterSelection = 2;
                        }
                        else if (data[0].printerSelection == 2) {
                            showPrinterSelection = 1;
                        }
                    }
                    else if (labelId) {
                        setPrinterVisibilityFromLabel(labelId);
                    }
                });
            }


            /**
			 * Is label selection showed or not? 
			 * @returns {number} 
			 * 0 = don't show label selection.
			 * 1 = show selected label, but user cannot change it.
			 * 2 = show label selection.
			 */
            ctrl.showLabelSelection = function () {
                // If label id is given as parameter, do not show label selection.
                if (ctrl.labelId && !ctrl.noPrintingOption) {
                    return 0;
                }
                // If there is only one value in label selection list, no need to select it but we can display it.
                if (ctrl.labelIdList && ctrl.labelIdList.length === 1) {
                    return 1;
                }
                // Otherwise display label selection list.
                return 2;
            };

            /**
             * Is printer selection showed or not? 
             * @returns {number}
             * 0 = don't show printer selection.
             * 1 = show selected printer, but user cannot change it.
             * 2 = show printer selection.
             */
            ctrl.showPrinterSelection = function () {
                // If no printing option given and selected, printer cannot be selected but we want to show the field a disabled. If user changes the label printer needs to be selected.
                if (ctrl.noPrintingOption && ctrl.selectedLabelId == null) {
                    return 1;
                }
                // If label printer id is given as parameter or printer list exists and contains only one value, no need to select printer but we can display it.
                if (ctrl.labelPrinterId || (ctrl.labelPrinterIdList && ctrl.labelPrinterIdList.length == 1)) {
                    return 1; // Show given or the only printer, but not as selection list.
                }
                // If label id exists we need to check its enumeration if it requires printer selection.
                if (ctrl.selectedLabelId && ctrl.labelIdList && ctrl.labelIdList.length >= 0) {
                    return showPrinterSelection;
                }
                return 2; // Show printer selection
            };

            /**
             * Prints current task to labels.
             */
            ctrl.printLabelToFile = function () {
                printLabelToFile();
            };

            /**** Functions ****/

            /**
             * Gets label id list from enumerations and selects it if only one result found.
             */
            function getLabelIdList() {
                if (ctrl.objectTable) {
                    var paramArray = [];
                    paramArray.push(ctrl.objectTable);
                    commonDatacontext.getEnumFrom('LABEL_PRINTING_001', JSON.stringify(paramArray)).then(function (data) {
                        if (data) {
                            ctrl.labelIdList = data;
                        }
                        handleLabelListAndSelect();
                    });
                }
            }

            /**
             * Gets label printer id list from enumerations and selects it if only one result found.
             */
            function getLabelPrinterIdList() {
                return commonDatacontext.getEnumFrom('LABEL_PRINTERS').then(function (data) {
                    if (data) {
                        ctrl.labelPrinterIdList = data;

                        // If only one labelPrinterId found then use it.
                        if (ctrl.labelPrinterIdList.length === 1) {
                            ctrl.selectedLabelPrinterId = ctrl.labelPrinterIdList[0].id;
                        }
                    }
                });
            }

            /**
             * Gets enumeration for given label id. If it is not found, then get list of label id enumerations. If only one results found, select it.
             * @param {string} labelId - Id of label whose enumeration is searched.
             */
            function getSelectedLabelIdEnum(labelId) {
                var paramArray = [];
                paramArray.push(labelId);
                commonDatacontext.getEnumFrom('LABEL_PRINTING_002', JSON.stringify(paramArray)).then(function (data) {
                    if (data && data.length > 0) {
                        ctrl.labelIdList = data;
                        handleLabelListAndSelect();
                    }
                    else { // No results found, get whole list.
                        ctrl.labelId = null;
                        getLabelIdList();
                    }
                });
            }

            /**
             * If no printing option is selected adds a new row to the beginning of the list to select if printing is wanted or not.
             * Selects the first item if no printing option is set or there is one item only.
             */
            function handleLabelListAndSelect() {
                if (ctrl.noPrintingOption) {
                    var noPrintingText = common.$translate.instant("STRCONST.DEL.TXT_DPO_RB_NOPRINTING");
                    var noPrinting = [{
                        id: null, name: noPrintingText, nabbr: noPrintingText, isDefault: true
                    }];
                    ctrl.labelIdList = noPrinting.concat(ctrl.labelIdList);
                    ctrl.selectedLabelId = ctrl.labelIdList[0].id;
                    ctrl.selectedLabelName = ctrl.labelIdList[0].name;
                }
                else if (ctrl.labelIdList.length === 1) { // If only one item in the list then use it.
                    ctrl.selectedLabelId = ctrl.labelIdList[0].id;
                    ctrl.selectedLabelName = ctrl.labelIdList[0].name;
                }
            }

            /**
             * Run at directive startup.
             */
            function init() {
                // If both labelId and labelPrinterId are given, we will use them.
                if (ctrl.labelId && ctrl.labelPrinterId) {
                    ctrl.selectedLabelId = ctrl.labelId;
                    ctrl.selectedLabelPrinterId = ctrl.labelPrinterId;
                }
                // If labelId is given as parameter but printer is not given as parameter then we need to get labelId enumeration in order to get printer requirement.
                else if (ctrl.labelId && !ctrl.labelPrinterId) {
                    getSelectedLabelIdEnum(ctrl.labelId);
                }
                // If labelId or labelPrinterId does not exist, get list of labelId's.
                else if ((!ctrl.labelId || !ctrl.labelPrinterId)) {
                    getLabelIdList();
                }

                // If labelPrinterId does not exist, get list of printers.
                if (!ctrl.labelPrinterId) {
                    getLabelPrinterIdList().then(function () {
                        if (ctrl.defaultParams && !ctrl.noPrintingOption) {
                            selectDefaultPrinter(ctrl.selectedLabelId);
                        }
                    });
                }

                if (ctrl.defaultParams) {
                    commonDatacontext.getDefaultLabel(ctrl.defaultParams).then(function (data) {
                        if (data && data.length && data[0]) {
                            ctrl.selectedLabelId = data[0];
                        }
                    });

                }
            }

            /**
			 * Prints selected label to selected printer.
			 */
            function printLabelToFile() {
                // If selected label id and selected label printer id exist or no printer selection needed, it is safe to print at once.
                if (ctrl.selectedLabelId && (ctrl.selectedLabelPrinterId || ctrl.showPrinterSelection() === 0)) {
                    return commonDatacontext.printLabelToFile(ctrl.selectedLabelId, ctrl.objectRecid, (ctrl.showPrinterSelection() === 0 ? null : ctrl.selectedLabelPrinterId));
                }
                else if (ctrl.noPrintingOption && ctrl.selectedLabelId == null) {
                    // Do nothing.
                    return common.$q.when(true);

                }
                else { // Raise error
                    errMsg(common.$translate.instant("STRCONST.PUBLIC.ERR_LABEL_OR_PRINTER_MISSING"));
                    return common.$q.when(false);
                }
            }
        }
        ]
    };

});
export default labelPrintingModule