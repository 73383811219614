'use strict';
var modalModule = angular.module('dir.dfxModal',[]);
modalModule.directive('dfxModal', function () {
	// Provides skeleton implementation for very basic modal dialog.
	// Idea is to have one point accces for this generic functionality.
	// Usage:
	//	Provide title and button mask. The parent scope should implement
	//	API for closing the dialog
	// Example:
	// HTML:
	// <dfx-modal title="'@StrConst.GetString("XXX")'" button-mask="3" ok-btn-text="'@StrConst.GetString("XXX")'" cancel-btn-text="'@StrConst.GetString("XXX")'">
	// Content here
	// </dfx-modal>
	// $uibModal.controller
	//	controller: function ($scope, $uibModalInstance) {
	//		$scope.onApply = function () {
	//			$uibModalInstance.close();
	//		};
	//		$scope.onCancel = function () {
	//			$uibModalInstance.dismiss('cancel');
	//		};
	//	},
	return {
		restrict: 'EA', //E = element, A = attribute, C = class, M = comment 
		scope: true,
		controller: ['$scope', function ($scope) {
			var ctrl = this;
			$scope.showApply = true;
			$scope.showCancel = true;
            $scope.showClose = true;

            ctrl.$onInit = function () {
                ctrl.extraBtnIsDefault = ctrl.extraButtons ? ctrl.extraButtons.filter(function (item) {
                    return item.isDefault;
                }).length : false;

                if (typeof ctrl.buttonMask !== 'undefined') {
                    $scope.showApply = (ctrl.buttonMask & 1) > 0;
                    $scope.showCancel = (ctrl.buttonMask & 2) > 0;
                    $scope.showClose = (ctrl.buttonMask & 4) > 0;
                }
                if (typeof ctrl.disableApplyButton == 'undefined') {
                    $scope.disableApply = false;
                }
                $scope.$watch('ctrl.disableApplyButton', function (value) {
                    $scope.disableApply = value;
                });
                if (ctrl.okBtnText == undefined || ctrl.okBtnText == null) {
                    ctrl.okBtnText = '';
                }
                if (ctrl.cancelBtnText == undefined || ctrl.cancelBtnText == null) {
                    ctrl.cancelBtnText = '';
                }
            };

			ctrl.getExtraButtons = function (before) {
				return ctrl.extraButtons ? ctrl.extraButtons.filter(function (item) {
					return item.before === before;
				}) : [];
			}
		}],
		controllerAs: 'ctrl',
		bindToController: {
			title: '=',
			disableApplyButton: '=',// possibility control 'Apply' disabling
			buttonMask: '=',		// maskBits: 1: apply; 2: cancel; 4: close;
			okBtnText: '=?',		// Text appearing on ok button.
			cancelBtnText: '=?',	// Text appearing on cancel button.
			extraButtons: '=?'		// buttons to be added list {id, name, before (or after normal buttons), isDefault, isDisabled}
		},
		transclude: true,
		template: require('./dfxModal.html')
	};
});
export default modalModule