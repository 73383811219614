
    'use strict';

    var app = angular.module('common');
    
    app.directive('dfxCheckBoxSetting', ['common', 'usersettings', function (common, usersettings) {
    	// Check Box setting directive
    	// Usage:
    	//  paramName: Parameter Name
    	//  defaultValue: If not defined, then this is used
    	//  helpText: If this is defined, then separate info button is showed for which
    	//			  this text is shown as tooltip
		//
		// The text for check box control is transcluded
    	// Example:
    	// <dfx-check-box-setting param-name="'param'" default-value="false" help-text="@StrConst.GetString("X")">
    	return {
    		restrict: 'E', //E = element, A = attribute, C = class, M = comment 
    		scope: {},
    		controllerAs: 'ctrl',
    		transclude: true,
    		bindToController: {
    			paramName: '=',
    			defaultValue: '=',
    			helpText: '=',
    		},
    		controller: ['$scope', function ($scope) {
    			var ctrl = this;

				// Initialize
                ctrl.$onInit = function () {
                    ctrl.value = usersettings.getPersonalSettingValue(ctrl.paramName, ctrl.defaultValue);
                };
    			// This toggles the current selection
    			ctrl.checkBoxClicked = function () {
    				ctrl.value = !ctrl.value;
    				usersettings.setPersonalSettingValue(ctrl.paramName, ctrl.value);
    				/*Broadcast the change*/
    				common.$broadcast('PORTALSETTINGSCHANGED', { model: ctrl.paramName, value: ctrl.value });
    			}
    		}],
    		template: require('./directives/dfxCheckBoxSetting.html')
		}
    }]);
    app.directive('dfxInputBoxSetting', ['common', 'usersettings', function (common, usersettings) {
    	// Check Box setting directive
    	// Usage:
    	//  paramName: Parameter Name
    	//  defaultValue: If not defined, then this is used
    	//  helpText: If this is defined, then separate info button is showed for which
    	//			  this text is shown as tooltip
    	//
    	// The text for check box control is transcluded
    	// Example:
    	// <dfx-check-box-setting param-name="'param'" default-value="false" help-text="@StrConst.GetString("X")">
    	return {
    		restrict: 'E', //E = element, A = attribute, C = class, M = comment 
    		scope: {},
    		controllerAs: 'ctrl',
    		transclude: true,
    		bindToController: {
    			paramName: '=',
    			defaultValue: '=',
    			helpText: '=',
    		},
    		controller: ['$scope', function ($scope) {
    			var ctrl = this;

    			// Initialize
                ctrl.$onInit = function () {
                    ctrl.value = usersettings.getPersonalSettingValue(ctrl.paramName, ctrl.defaultValue);
                };
    			// This toggles the current selection
    			ctrl.inputBoxTyped = function () {
    				
    				usersettings.setPersonalSettingValue(ctrl.paramName, ctrl.value);
    				/*Broadcast the change*/
    				common.$broadcast('PORTALSETTINGSCHANGED', { model: ctrl.paramName, value: ctrl.value });
    			}
    		}],
    		template: require('./directives/dfxInputBoxSetting.html')
    	}
    }]);

    app.directive('dfxGroupSetting', ['common', 'usersettings', function (common, usersettings) {
    	// Group Box for Settings dialog <=> initializes the value and broadcasts change to all interested parties
    	// Usage:
		//  name: the name of thr control
    	//  paramName: Parameter Name
    	//  defaultValue: If not defined, then this is used
    	//  choices: possible choices <=> containing id and name 
    	//
    	// Example:
    	// <dfx-group-setting name="'@StrConst.GetString("X")'" param-name="'param'" default-value="'id'" choices="ctrl.values"></dfx-group-setting>
    	return {
    		restrict: 'E', //E = element, A = attribute, C = class, M = comment 
    		scope: {},
    		controllerAs: 'ctrl',
    		bindToController: {
    			name: '=',
    			alignName: '=',
    			paramName: '=',
    			defaultValue: '=',
    			choices: '='
    		},
    		controller: ['$scope', function ($scope) {
    			var ctrl = this;

    			// Initiaize
                ctrl.$onInit = function () {
                    ctrl.value = usersettings.getPersonalSettingValue(ctrl.paramName, ctrl.defaultValue);
                };
				// Change selection 
    			ctrl.clicked = function (choice) {
    				ctrl.value = choice.id;
    				usersettings.setPersonalSettingValue(ctrl.paramName, ctrl.value);
    				/*Broadcast the change*/
    				common.$broadcast('PORTALSETTINGSCHANGED', { model: ctrl.paramName, value: ctrl.value });
    			}
    		}],
    		template: require('./directives/dfxGroupSetting.html')
    	}
    }]);

    app.directive('dfxSettingTitle', function () {
    	// Simple directive to encapsulate Title in 
    	return {
    		restrict: 'E', //E = element, A = attribute, C = class, M = comment 
    		scope: {},
    		transclude: true,
    		template: require('./directives/dfxSettingTitle.html')
    	}
    });

    app.directive('dfxSettingsAll', ['common', 'commonDatacontext', 'usersettings', function (common, commonDatacontext, usersettings) {
    	// Used to tag 'Portal Settings for Task View' html
    	return {
    		restrict: 'E', //E = element, A = attribute, C = class, M = comment 
    		scope: {},
    		template: require('./directives/dfxSettingsAll.html'),
    		controllerAs: 'ctrl',
    		controller: ['$scope', function ($scope)
    		{
    			var ctrl = this;
    			ctrl.debug = common.logger.getDebugMode();
    			ctrl.commonSettingsChange = function () {
    				common.logger.setDebugMode(!ctrl.debug);
    				/*Broadcast the change*/
    				common.$broadcast('DEBUGMODECHANGED');
                };
				ctrl.elemSizeSelListChoices = [
					{ id: 'desktop', name: common.$translate.instant("STRCONST.PUBLIC.TXT_SMALL") },
					{ id: 'large', name: common.$translate.instant("STRCONST.LEANPORTAL.TXT_LARGE") }
                   
                ];
    			ctrl.profilingEnabled = false;
    			ctrl.profilingStarted = false; /*keeps 'StartProfiling' button disabled when settings modal is opened till status from the server is returned. Data comes back faster for false status*/
				ctrl.topBannerVisible = true; // Usersettings will initialize this when route is changed.
				ctrl.profilingUser = common.getAppSetting("ProfilingUser", "LEANADM");
				ctrl.profilingEnabledZero = common.getAppSetting("ProfilingEnabled", "0");

                $scope.$on('PORTALSETTINGSCHANGED', function (event, args) {
                    if (args.model && (args.value != undefined)) {
                        // Set a css class to root body element according to user selection.
                        if (args.model == 'elementSize') {
                            usersettings.setElementsSize(args.value);
                        }
                    }
                });

				usersettings.getUserSettings().then(function (userData) {
    				if (userData && userData.info && userData.info.roles && userData.info.roles.length > 0) {
						var userInfo = userData.info;
						var currentUser = userInfo.userId
						ctrl.currentUser = currentUser;	
    					for (var i = 0; i <= userInfo.roles.length; i++) {
    						if (userInfo.roles[i] == "SYSTEM") {
    							ctrl.systemRole = true;
    							checkProfilingOption(userInfo.userId);
    						}    					
    					}

    					usersettings.checkTopBannerVisibility(common.$route.current.portalId).then(function (data) {
    						ctrl.topBannerVisible = data;
    					});
    				}
				});
					
    			/*if User has system role, check if user also has profiling option available*/
				function checkProfilingOption(userId) {
					/*check if profiling set in webconfig and available for user. If profiling is available, check its status*/
					if (ctrl.profilingEnabledZero === '1' && (ctrl.profilingUser === userId || ctrl.profilingUser === '')) {						
						ctrl.profilingEnabled = true;
						ctrl.profilingStarted = true;
						getProfilingStatus();
					}
					else ctrl.profilingStarted = false; //make sure profiling is off.
				}

    			/*gets the status for profiling button controls*/
				function getProfilingStatus() {					
					if (ctrl.profilingEnabled) {
							commonDatacontext.getLeanProfilingStatus().then(function (data) {
							ctrl.profilingStarted = data;
						});
					}				
				}				
				
    			ctrl.clearCache = function () {
    				commonDatacontext.clearCache();
    			};

    			ctrl.activateProfiling = function () {
    				if (ctrl.profilingEnabled) {
    					commonDatacontext.activateLeanProfiling().then(function (data) {
    						/*Broadcast the change*/
    						common.$broadcast('PROFILINGSTATUSCHANGED');
    						getProfilingStatus();
    					});
    				}
    			};

    			/**
				 * Makes logoff for the current user.
				 * @param {Language whose lookups (enumerations) are cleared during logoff.} language
				 */
    			ctrl.logoff = function () {
					usersettings.logoff(common.$translate.use(), false).then(function () {
    					common.$location.search({});
    					usersettings.redirectToLogin("logout");
    				});
    			};
    
    			ctrl.openProfilingReportModal = function () {
    				if (!ctrl.formsReportData) {
    						commonDatacontext.getLeanProfilingReport().then(function (data) {
    							ctrl.formsReportData = data;
    							/*Broadcast the change*/
    							common.$broadcast('PROFILINGSTATUSCHANGED');
    						common.openModal($scope, require('./directives/profilingReportModal.html'), null, function () { }, 'lg');
    					});
    				}
					//if profiling has data already, just open modal.  
					else common.openModal($scope, require('./directives/profilingReportModal.html'), null, function () { }, 'lg');

    				getProfilingStatus();
    			}
    		}]
    	}
    }]);

    app.directive('dfxSettingsTask', function () {
    	// Used to tag 'Portal Settings for Task View' html
    	return {
    		restrict: 'E', //E = element, A = attribute, C = class, M = comment 
    		template: require('./directives/dfxSettingsTask.html'),
    		controllerAs: 'ctrl',
    		controller: function () {
    			var ctrl = this;
    		}
    	}
    });


    app.directive('dfxSettingsTimeReporting',['common', function (common) {
    	// Used to tag 'Portal Settings for Hour Input' html
    	return {
    		restrict: 'E', //E = element, A = attribute, C = class, M = comment 
    		template: require('./directives/dfxSettingsTimeReporting.html'),
    		controllerAs: 'ctrl',
    		controller: function () {
    			var ctrl = this;
    			ctrl.selListChoices = [{ id: 'Id', name: common.$translate.instant("STRCONST.PUBLIC.TXT_ID") },
									   { id: 'Name', name: common.$translate.instant("STRCONST.PUBLIC.TXT_NAME") },
									   { id: 'IdName', name: common.$translate.instant("STRCONST.LEANPORTAL.TXT_IDANDNAME") }];
    			ctrl.inputFieldChoices = ctrl.selListChoices;
    		}
    	}
    }]);

    app.directive('dfxSettingsWorkBench', ['common', function (common) {
		// Used to tag 'Portal Settings for WorkBench' html
		return {
			restrict: 'E', //E = element, A = attribute, C = class, M = comment 
			template: require('./directives/dfxSettingsWorkBench.html'),
			controllerAs: 'ctrl',
			controller: function () {
				var ctrl = this;
				ctrl.selListChoices = [{ id: 'Id', name: common.$translate.instant("STRCONST.PUBLIC.TXT_ID") },
				{ id: 'Name', name: common.$translate.instant("STRCONST.PUBLIC.TXT_NAME") },
				{ id: 'IdName', name: common.$translate.instant("STRCONST.LEANPORTAL.TXT_IDANDNAME") }];
				ctrl.inputFieldChoices = ctrl.selListChoices;
			}
		}
	}]);
	app.directive('dfxSettingsCommonData', function () {
    	// Used to tag 'Portal Settings for Common Data' html
    	return {
    		restrict: 'E', //E = element, A = attribute, C = class, M = comment 
			template: require('./directives/dfxSettingsCommonData.html'),
    		controllerAs: 'ctrl',
    		bindToController: {
    			showWidth: '=?'
    		},
    		scope: false, // scope is used to know from where the directive is used
    		controller: ['$scope', function ($scope){
    			var ctrl = this;
    			ctrl.commonData = $scope.commonData;
    			ctrl.paramName = function (name) {
    				return 'commonDataView_' + ctrl.commonData.owner + '_' + name;
    			}
    		}]
    	}
    });

    app.directive('dfxSettingsConfigurator', function ()
    {
    	// Used to tag 'Portal Settings for Task View' html
    	return {
    		restrict: 'E', //E = element, A = attribute, C = class, M = comment 
    		template: require('./directives/dfxSettingsConfigurator.html'),
    	}
    });

    app.directive('dfxSettingsPurchase',['common', function (common) {
    	// Used to tag 'Portal Settings for Task View' html
    	return {
    		restrict: 'E', //E = element, A = attribute, C = class, M = comment 
    		template: require('./directives/dfxSettingsPurchase.html'),
    		controllerAs: 'ctrl',
    		controller: function () {
    			var ctrl = this;
    			ctrl.selListChoices = [{ id: true, name: common.$translate.instant("STRCONST.LEANPORTAL.TXT_OPENED") },
									   { id: false, name: common.$translate.instant("STRCONST.LEANPORTAL.TXT_CLOSED") }];
    		}
    	}
    }]);

    app.directive('dfxSettings', ['$compile', function ($compile) {
    	// Portal Settings directive is used by Settings dialog ("settingsDialog.html)
    	// launched from common service. This directive relies that the viewed settings 
    	// (<=> directives) are added into $scope.portalSettings as an array
    	return {
    		restrict: 'E', //E = element, A = attribute, C = class, M = comment 
    		scope: false,
    		controller: ['$scope', function ($scope) {
    			var ctrl = this;
    			$scope.getDirectives = function () {
    				if ($scope.portalSettings) {
    					var ret = [];
    					$scope.portalSettings.forEach(function (directive) {
    						ret.push('<' + directive + '>' + '<' + directive + '/>');
    						if (directive != $scope.portalSettings[$scope.portalSettings.length - 1]) {
    							ret.push('<hr>');
    						}
    					});
    					return ret;
    				}
    				return [];
    			}
    		}],
    		controllerAs: 'ctrl',
    		link: function(scope, element){
    			scope.getDirectives().forEach(function (template) {
    				var linkFn = $compile(template);
    				var content = linkFn(scope);
    				element.append(content);
    			});
    		}
    	}
    }]);

export default app
