'use strict';
var app = angular.module('dir.dfxAggregateInput', []);
app.directive('dfxAggregateInput', function () {
    return {
        restrict: 'E',
        scope: {},
        controllerAs: 'ctrl',
        bindToController: {
            parentObject: '=',
            enumItems: '=',
            onDataReadyCallback: '&'
        },
        controller: ['$scope', '$timeout', 'common', 'commonDatacontext', 'usersettings', function ($scope, $timeout, common, commonDatacontext, usersettings) {
            var ctrl = this;

            ctrl.modelColumnMap = [];
            ctrl.$onInit = function () {
                init();
            }

            function init() {
                var promises = [];
                promises.push(commonDatacontext.getModelColumnMap(ctrl.parentObject.entityType.shortName).then(function (columnMap) {
                    if (columnMap) {
                        ctrl.modelColumnMap = columnMap;
                    }
                    return columnMap;
                }));
                return promises;
            }

            ctrl.propagateToObject = function () {
                if (ctrl.parentObject) {
                    var fields = ctrl.parentObject.AggregatePropertyfields;
                    var separator = ctrl.parentObject.AggregatePropertySeparator;
                    var values = ctrl.inputData ? ctrl.inputData.split(separator) : [];
                    if (separator && values) {
                        for (var i = 0; i < Math.min(values.length, fields.length); i++) {
                            var col = ctrl.modelColumnMap[fields[i]];
                            if (col) {
                                ctrl.parentObject[col] = values[i];
                            }
                            else if (ctrl.parentObject.entityType.shortName === 'WorkElementMaterial') {
                                // WorkElementMaterial has some data in separate dynamic data structures
                                angular.forEach(ctrl.parentObject.dynamicData, function (data) {
                                    if (data.dataField === fields[i]) {
                                        data.setValue(values[i]);
                                        if (data.dataType == 'ENUM' && ctrl.enumItems[data.enumeration]) {
                                            var enumRow = common.getEnumRowById(ctrl.enumItems[data.enumeration], values[i]);
                                            if (enumRow) {
                                                data.enumNameValue = enumRow.nabbr;
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    }
                    ctrl.onDataReadyCallback();
                }
            }
		}],
		template: require('./dfxAggregateInput.html')
    };
});
export default app