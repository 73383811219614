'use strict';
var chartsModule = angular.module('dir.dfxCharts',[]);
chartsModule.directive('dfxCharts', function ()
	// Can be added with a formId with the following code, where the XXXXX represents the formId: <dfx-charts form-id="XXXXX" range-min="0" range-max="500" container-width="'900px'" container-height="'450px'"></dfx-charts>
	// Full example:
	// <dfx-charts form-id="18522" auto-launch="true" auto-range="true" axis-x-title="'Year'" axis-y-title="'Sales MEUR'" chart-id="'Chart1'" chart-title="'Sales'" 
	// container-height="'80vh'" container-width="'90vw'" extra-restrictions="'qdr.resid=\'XYZ\''" 
	// filterString="{"STATUS":"%CLAR%","DUEDATE":"1.10.2016;31.10.2016","OPERATID":"HAHI"}" 
	// form-version="'SELECT_RESOURCETASK'" 
	// margin-bottom="130" margin-left="50" margin-right="300" margin-top="80" 
	// parameters="[{"name":"PARAM1","value":"123"}]" range-min="0" range-max="500" 
	// sort="'ORDERBY_UPDATESTAMP'"></dfx-charts>
{
	return {
		restrict: 'EA', //E = element, A = attribute, C = class, M = comment 
		scope: {},
		bindToController: {
			autoLaunch: '=?', // If set to true, chart is launched without button click.
			autoRange: '=?', // If set to true, rangeMin and rangeMax do not have effect.
			axisXColumn: '=?', // Dataset column name whose values are presented in x axis. Used in box plot.
			axisXTitle: '=?',
			axisYTitle: '=?',
			chartId: '=?', // Element id in html where the chart is created into. If missing, 'container' is used.
			chartTitle: '=?', // If string, then use plotly's title, otherwise array expected: odd elements are titles and even elements are names underneath
			containerHeight: '=?', // Can be at least pixels, percentages or vh. If containerHeight and containerWidth are not set, the chart is created according to window size.
			containerWidth: '=?',
			extraRestrictions: '=?', // Special restrictions for database query. SQL statement that can be added to form query.
			filterString: '=?', // JSON object of strings and fields used for dynamically filter the query.
			formId: '=', // Id of form where data is taken from.
			formVersion: '=?', // Possible form version.
			marginBottom: '=?', // Area between graph and window bottom edge where x axis title is shown (affects x axis title visibility). In pixels.
			marginLeft: '=?', // Area between graph and window left edge where y axis title is shown. In pixels.
			marginRight: '=?', // Area between graph and window right edge where legend is shown. In pixels.
			marginTop: '=?', // Area between graph and window top edge where graph title is shown. In pixels.
			onRemoveCallback: '&',
			parameters: '=?', // Parameters for database query / mappings between browser client and form. Json string, for example [{ "name": "PARAM1", "value": "1234"}, { "name": "PARAM2", "value": "ABC"}].
			rangeMin: '=?', // Chart y-axis scale min. No effect if autoRange is set to true.
			rangeMax: '=?', // Chart y-axis scale min. No effect if autoRange is set to true.
			removeChart: '=?', // Removes the chart through the removeChart function (Measurementgraphtab)
			showLegend: '=?', // Defines if the legend is shown
			sort: '=?', // Sort command name in form setting functions.
			staticIndicatorRules: '=?', // Defines static indicator calculation rules
		},
		controllerAs: 'ctrl',
		template: require('./dfxCharts.html'),
		controller: ['$scope', '$timeout', 'config', 'common', 'commonDatacontext', function charts($scope, $timeout, config, common, commonDatacontext) {
			var ctrl = this;
			ctrl.data = [];
			
			var filterString;
			var formId;
			var formVersion;
			var extraRestrictions;
			ctrl.indicators = [];
			var parameters;
			var sort;
			var xAxisColumn;
			var library;
            var graphWidth, graphHeight;

            ctrl.$onInit = function () {
                ctrl.chartId = ctrl.chartId ? ctrl.chartId : 'container';
                filterString = ctrl.filterString;
                formId = ctrl.formId;
                formVersion = ctrl.formVersion;
                extraRestrictions = ctrl.extraRestrictions;
                parameters = ctrl.parameters;
                sort = ctrl.sort;
                xAxisColumn = ctrl.axisXColumn;
                ctrl.removeChart = ctrl.removeChart ? ctrl.removeChart : false;
                library = ctrl.library;

                // If auto launch parameter exists and is set to true, call draw data chart function.
                if (ctrl.autoLaunch) {
                    ctrl.drawDataChart();
                    ctrl.createChartLayout();
                }
            };

			// Listen window resizing and ajust graph size
			common.$window.addEventListener('resize', function () {
				calcGraphSize();
				Plotly.relayout(document.getElementById(ctrl.chartId), { 'height': graphHeight, 'width': graphWidth });
			});

			function calcGraphSize() {
				// reserve some space for statistical check table
				var wrapper = document.getElementsByClassName(ctrl.getIdClass());
				if (wrapper && wrapper.length === 1) {
					graphHeight = (ctrl.indicators.length ? 0.6 : 1) * wrapper[0].offsetHeight;
					graphWidth = wrapper[0].offsetWidth;
				}
			}

			ctrl.createChartLayout = function () {
				if (ctrl.showLegend === undefined) {
					ctrl.showLegend = true;
				}
			}
			ctrl.getIdClass = function () {
				return 'dfx-charts-id-class-' + ctrl.chartId;
			}
			ctrl.showTitleAsTable = function () {
				return ctrl.chartTitle && typeof ctrl.chartTitle !== 'string';
			}

			// Gets data from database and launches chart.
			ctrl.drawDataChart = function drawChart() {
				commonDatacontext.getCharts(formId, formVersion, sort, extraRestrictions, parameters, filterString, xAxisColumn).then(function (data) {
					var chartData = [];
					ctrl.data = data;

					// If we got data, proceed.
					if (ctrl.data && ctrl.data[0] && ctrl.data[0].seriesList && ctrl.data[0].seriesList[0]) {
						chartData = generateData(ctrl.data);
						var type = ctrl.data[0].seriesList[0].type;

						function getName(chartsSeries) {
							var ret = chartsSeries.name ? chartsSeries.name : '';
							var legendCount = 0;
							if (chartsSeries.legend) {
								for (var key in chartsSeries.legend) {
									var item = JSON.parse(chartsSeries.legend[key]);
									ret += (ret.length ? '<br>' : '') + item.name + ': ' + item.value;
									legendCount++;
									//Start: The calculus of how much margin the bottom legend needs
									//increasing of bottom margin depending on the amount of Legend values
									if (legendCount > 6) {
										ctrl.marginBottom += 10;
									}
								}
								//If boxplot, reduce the bottom margin when the amount of series increase due to the smaller graph that is generated by plotly
								//If the serieslist.length is greater than 3 the maginBottom value gets reduced too much(cutting off text). 
								if (ctrl.data[0].seriesList[0].boxmean) {
									for (var i = 1; i < ctrl.data[0].seriesList.length && i < 2; i++) {
										ctrl.marginBottom -= 5;
									}
								}
								//End:
							}
							return ret;
						}
						function generateData(Data) {
							for (var i = 0; i < ctrl.data[0].seriesList.length; i++) {
								var chartsSeries = ctrl.data[0].seriesList[i];
								// Execute statistical checks for the graph if asked and defined for the serie
								if (ctrl.staticIndicatorRules && ctrl.staticIndicatorRules.length && chartsSeries.statCheck) {
									generateIndicators(chartsSeries);
								}
								// If there are a lot of points, then remove possible newlines from x-axis while plotly isn't handling them nicely
                                if (chartsSeries.xaxis && chartsSeries.xaxis.length > 10) {
									for (var ind = 0; ind < chartsSeries.xaxis.length; ind++) {
										chartsSeries.xaxis[ind] = chartsSeries.xaxis[ind].replace(/<br>/g, ' ');
									}
								}
								chartData.push({
									x: chartsSeries.xaxis,
									y: chartsSeries.data,
									boxmean: chartsSeries.boxmean,
									name: getName(chartsSeries),
									marker: { color: chartsSeries.seriesColor },
									type: chartsSeries.type,
									mode: chartsSeries.mode,
									extendLine: chartsSeries.chartExtendLine
								});
							}
							for (var i = 0; i < chartData.length; i++) {
								if (chartData[i].extendLine) {
									for (var k = 0; k < chartData[i].y.length; k++) {
										var temp1 = chartData[i].y[k];
										var temp2 = chartData[i].y[k + 1]

										if (temp1 == null && temp2 != null) {
											chartData[i].y[k] = temp2
										}
										else if (temp1 != null && temp2 == null) {
											chartData[i].y[k + 1] = temp1;
											k++;
										}
									}
								}
							}
							return chartData;
						}

						function generateIndicators(chartsSeries) {
							ctrl.staticIndicatorRules.forEach(function (rule) {
								rule.func(chartsSeries.data, rule.enumeration, rule.k).forEach(function (index) {
									ctrl.indicators.push({
										name: rule.name.replace('K', rule.k),
										measurement: chartsSeries.xaxis[index].replace(/<br>/g, ' '),
										x: index / (chartsSeries.data.length - 1)
									})
								});
							});
						}

						calcGraphSize();
						// Chart layout settings. TODO: Move as much as you can to form settings and/or directive parameters.
						ctrl.layout = {
							barmode: 'relative',
							enumerated: "outside",
							font: {
								family: '"Open Sans CondBold", sans-serif'
							},
							height: graphHeight,
							width: graphWidth,
							margin: {
								b: ctrl.marginBottom - (ctrl.indicators.length ? 50 : 0),
								l: ctrl.marginLeft,
								r: ctrl.marginRight,
								t: ctrl.indicators.length ? 10 : ctrl.marginTop
							},
							showlegend: ctrl.showLegend,
							title: ctrl.showTitleAsTable() ? null : ctrl.chartTitle,
							xaxis: {
								title: ctrl.axisXTitle,
								showline: true,
								ticks: 'outside',
								//showticklabels: true,
								tickcolor: "#ddd",
								//gridcolor: "#ddd",
								//showgrid: true,
								linecolor: "rgb(221, 221, 221)",
							},
							yaxis: {
								autorange: ctrl.autoRange,
								title: ctrl.axisYTitle,
								range: [ctrl.rangeMin, ctrl.rangeMax],
								showline: true,
								ticks: 'inside',
								tickcolor: "#ddd",
								gridcolor: "#ddd",
								linecolor: "rgb(221, 221, 221)"
							},
						};

						Plotly.newPlot(ctrl.chartId, chartData, ctrl.layout, { showLink: false });
					}
					else { // No data found.
						ctrl.msg = common.$translate.instant("STRCONST.PUBLIC.ERR_GRAPH_DATA_NOT_FOUND");
					}

				});
			}

			ctrl.selectIndicator = function (x) {
				var plotDiv = document.getElementById(ctrl.chartId);
				var x = (ctrl.layout['xaxis']['range'][1] + ctrl.layout['xaxis']['range'][0]) * x;
				var update = {
					'shapes': [{
						type: 'line',
						x0: x,
						y0: ctrl.layout['yaxis']['range'][0],
						x1: x,
						y1: ctrl.layout['yaxis']['range'][1],
						line: {
							color: "rgb(55, 128, 191)",
							width: 3,
							dash: 'dashdot'
						}
					}]
				};
				Plotly.relayout(plotDiv, update);
			}
		}

		]

	}

});
export default chartsModule