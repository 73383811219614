'use strict';
var hiTypeaheadModule = angular.module('dir.hiTypeahead',[]);
hiTypeaheadModule.directive("hiTypeahead", ['$window', function ($window) {
    var directive = {
        link: link,
        scope: true,
		restrict: 'A',
		require: ["ngModel"]
    };
    return directive;

    function link(scope, element, attrs, ctrls) {
		if (attrs.openDropdownOnFocus != "false") {
			element.bind('click', function () {
				if (ctrls[0].$viewValue && ctrls[0].$viewValue == ' ') {
					ctrls[0].$setViewValue('');
				}
				ctrls[0].$setViewValue(' ');
			});
			var scope = scope;
			var appendBody = scope.$eval(attrs.typeaheadAppendToBody);

			element.bind('focus', function ($event) {
				var element = angular.element($event.target);

				if (element) {
					var diff = window.innerHeight - element[0].getBoundingClientRect().bottom;
					// Open typeahead above the input element.
					if (diff < 265) {
						// Typeahead is appended to body so we need to calculate the correct position.
						if (appendBody) {
							scope.topPos = 'auto';
							scope.bottomPos = diff + element[0].getBoundingClientRect().height;
						} else {
							element.parent().addClass('dropup');
							scope.maxHeight = Math.min(diff, 500);
						}
					} else {
						if (element.parent().hasClass('dropup')) {
							element.parent().removeClass('dropup')
						}
						scope.maxHeight = Math.min(diff - 15, 500);
					}

					// Check if typeahead is too close to the right.
					var diffX = window.innerWidth - element[0].getBoundingClientRect().right;
					if (diffX < 200) {
						scope.leftPos = 'auto';
						scope.rightPos = diffX;
					}
				}
			});
		}
    }
}]);
export default hiTypeaheadModule