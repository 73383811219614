/**
  * Work table used when serial id or phase is selected
  */
var workTable=angular.module('manudir.manSearchTaskWorkTable',[])
workTable.directive('manSearchTaskWorkTable', [function () {
    return {
        restrict: 'E',
        scope: {},
        controllerAs: 'ctrl',
        bindToController: {
            workDatas: '=',  // These are ResourceTask objects - one per work
            onRowSelectedCallback: '&',
            onReturnCallback: '&?'
        },
        controller: ['$scope', function ($scope) {
            var ctrl = this;
            ctrl.statusFilter = 'ALL';
            ctrl.openWorks = [];

            $scope.$watch('ctrl.statusFilter', function () {
                if (ctrl.statusFilter === 'OPEN' && ctrl.selected && !ctrl.selected.workIsOpen) {
                    ctrl.selectRow(null);
                }
            });

            ctrl.statusFilterClicked = function (value) {
                ctrl.statusFilter = value;
            }

            ctrl.hasOpenAndClosedWorks = function (workData) {
                if (!ctrl.workDatas) return false;
                ctrl.openWorks = ctrl.workDatas.filter(function (phase) { return phase.workIsOpen; })
                return ctrl.openWorks.length > 0 && ctrl.openWorks.length != ctrl.workDatas.length;
            }

            ctrl.getFilteredData = function () {
                if (ctrl.statusFilter === 'ALL') {
                    return ctrl.workDatas;
                }
                // Select if only one viewed open work
                if (ctrl.openWorks.length === 1 && ctrl.openWorks[0] != ctrl.selected) {
                    ctrl.selectRow(ctrl.openWorks[0]);
                }

                return ctrl.openWorks;
            }

            ctrl.selectRow = function (workData) {
                if (ctrl.workDatas.length > 1) {
                    ctrl.selected = workData;
                    ctrl.onRowSelectedCallback({ workData: workData });
                }
            }
        }],
        template: require('./manSearchTaskWorkTable.html')
    }
}]);
export default workTable
