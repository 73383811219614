
    'use strict';
    var controllerId = 'docCtrl';
	var docModule = angular.module('docdir.dfxDoc',[])
	docModule.directive('dfxDoc', [function () {
        return {
            restrict: 'E',
            scope: {},
            controller: controllerId,
            controllerAs: 'ctrl',
            bindToController: {
                formId: '=?',
                dynDocs: '=', // Ready document objects given as parameter. Now dynDocs replace parent object documents. Can be changed if needed.
                // array of objects with objectType, recId and name properties
                docParents: '=',
                // single object with objectType, recId and name properties <=> no title heaser shown
                parent: '=',
                alignIconsVertical: '=?',
				contentLoadingPhase: '=?', // 0 = load at startup and wait, 1 = load at startup but do not wait, 2 = load and wait when clicked. Default = 2.
				docDefaultsEnumKey: '=?', // DOC_DEFAULTS enumeration key(value01). If not found, portal id is used as key. Enumeration is used for form id and version (access rights, column visibility, restrictions) and sort order.
				docListOption: '=?', // Options for document query. For example 1 = exclude images, 2 = images only, 4 = active only (in MFE and Manufacturing portal).
				filterByLanguage: '=?', //Filter documents by language if given
                filterValue: '=?', // documents are filtered by this value
                iconAndTitleOnly: '=?', // true or false
                //How many pictures are in the view, null -> default. Documents are being shown normally.
                //									 2, or more -> documents are shown beside each other before row hop.
				editInModalIcon: '=?',
                limitTo: '=?',
                newIconCutoff: '=?', // How many days 'New' icon will be displayed.
                onClickOpenModal: '=?', // true or false
				onOpenInTab: '=?', // true or false
				onContentAddedCallback: '&', // When document added.
                onContentChangedCallback: '&',
                onContentEditedCallback: '&',
                onControllerCallback: '&',
				showAddDocBtn: '=?', // Show button for adding new documents. Default = false. Note: caller is responsible for checking if this is allowed or not (user rights, parent status etc.) Module canEdit property is one possible factor.
				showObjectHeaders: '=?', // true or false. Default = true.
				textAttachment: '=?',
                template: '=?'
            },
            template: function (tElement, tAttrs) {
                return tAttrs.template ? require(`./${tAttrs.template.replace('.html', '')}.html`) : require('./dfxDocs.html') || require('./dfxDocs.html');
            }
        };
    }]);

    docModule.controller(controllerId, ['$scope', 'common', 'commonDatacontext', 'usersettings', doc]);

    function doc($scope, common, commonDatacontext, usersettings) {

        /****** Properties / Initialize ******/
        var ctrl = this;
        // API offered for the client with onControllerCallback
        var service = {
            triggerLoading: activate
        };
        ctrl.isReady = false;

        ctrl.$onInit = function () {

            ctrl.currentUser = null;
			ctrl.contentAvailable = false;
			ctrl.docDefaultsEnumKey === undefined ? ctrl.docDefaultsEnumKey = common.$route.current.portalId : null;
            ctrl.docObjects = []; // Hierarchical list of objects and their documents. Used in repeat.
            ctrl.newCount = [];
            ctrl.objects = []; // Parent list, given as parameter at startup
            ctrl.parentToDocs = []; // Flat list of documents with object type and recid as key.
            if (typeof ctrl.contentLoadingPhase === 'undefined') {
                ctrl.contentLoadingPhase = 2;
            }
            if (typeof ctrl.iconAndTitleOnly === 'undefined') {
                ctrl.iconAndTitleOnly = false;
            }
            if (typeof ctrl.onClickOpenModal === 'undefined') {
                ctrl.onClickOpenModal = false;
            }
            if (typeof ctrl.onOpenInTab === 'undefined') {
                ctrl.onOpenInTab = false;
			}
			if (typeof ctrl.showAddDocBtn === 'undefined') {
				ctrl.showAddDocBtn = false;
			}
            if (typeof ctrl.showObjectHeaders === 'undefined') {
                ctrl.showObjectHeaders = true;
            }
			if (typeof ctrl.textAttachment === 'undefined') {
				ctrl.textAttachment = false;
			}
			if (typeof ctrl.filterByLanguage === 'undefined') {
				ctrl.filterByLanguage = null;
			}
            ctrl.onControllerCallback({ childCtrl: service });
        };

        /****** Properties / Based on functions ******/

		/**
		 * Creates downloadable object.
		 * @param {object} doc - Document
		 */
        ctrl.downloadFile = function (doc) {
            if (doc) {
                // If content does not exist yet, try to get it.
                if (!doc.content) {
                    commonDatacontext.getDocumentData(null, doc.recId, 'CONTENT', true, true).then(function (data) {
                        if (!data || !data.length || !data[0]) {
                            var msg = common.$translate.instant("STRCONST.PUBLIC.MSG_LOADING_FILECONTENT_FAILED") + ' ' + doc.fileName;
                            common.logger.logError(msg, false, false, true);
                        }
                        else {
                            doc.content = data[0];
                            common.downloadFile(doc.content, doc.contentType, doc.fileName, doc.url, doc.hasFile);
                        }
                    });
                }
                else { // Content exists, just download file.
                    common.downloadFile(doc.content, doc.contentType, doc.fileName, doc.url, doc.hasFile);
                }
            }
        };

		/**
		 * Opens modal for pictures, text and pdf files. For other files ctrl.downloadFile is called.
		 * @param {object} doc - Document
		 */
		ctrl.openModal = function (doc) {
			if (!doc.content) {
				commonDatacontext.getDocumentData(null, doc.recId, 'CONTENT', true, true).then(function (data) {
					if (!data || !data.length || !data[0]) {
						var msg = common.$translate.instant("STRCONST.PUBLIC.MSG_LOADING_FILECONTENT_FAILED") + ' ' + doc.fileName;
						common.logger.logError(msg, false, false, true);
					}
					else {
						doc.content = data[0];
						openFile(doc, 'MODAL')
					}
				});
			}
			else {
				openFile(doc, 'MODAL')
			} 
        };

		ctrl.openInTab = function (doc) {
			if (!doc.content) {
				commonDatacontext.getDocumentData(null, doc.recId, 'CONTENT', true, true).then(function (data) {
					if (!data || !data.length || !data[0]) {
						var msg = common.$translate.instant("STRCONST.PUBLIC.MSG_LOADING_FILECONTENT_FAILED") + ' ' + doc.fileName;
						common.logger.logError(msg, false, false, true);
					}
					else {
						doc.content = data[0];
						openFile(doc, 'TAB')
					}
				});
			}
			else {
				openFile(doc,'TAB')
			}           
		};

        function openPdfInTab(pdfData) {
            const pdfFile = common.b64toBlob(pdfData, "application/pdf");
            if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(pdfFile);
            } else {
                const fileUrl = URL.createObjectURL(pdfFile);
                window.open(fileUrl);
            }
        }

		function openFile(doc, pdfTarget){
			if (doc.contentType === 'text/plain') {
				$scope.textContent = common.b64DecodeUnicode(doc.content);
				$scope.textTitle = doc.title;
				common.openModal($scope, require('./dfxTextModal.html'), null, null, 'lg', true);
			}
			/*Images (opened via EkkoLightBox)*/
			else if (doc.contentType === "image/png" || doc.contentType === "image/jpeg" || doc.contentType === "image/jpg") {
				var prefix = 'data:' + doc.contentType + ';base64,';
				$('#leandoc').ekkoLightbox({ remote: prefix + doc.content, title: (doc.title ? doc.title : doc.fileName) });
			}
			else if (doc.contentType === "application/pdf") {
				if (pdfTarget == 'TAB') {
					openPdfInTab(doc.content);
				}
				else if (pdfTarget == 'MODAL') {
					common.executePDFJS(doc, "doc", commonDatacontext);
					common.openModal($scope, require('./dfxDocCanvas.html'), null, null, null, true);
				}
				else {
					ctrl.downloadFile(doc);
				}
			}
			else {
				ctrl.downloadFile(doc);
			}
		}
		/**
		 * Returns how many document rows exist in the document array.
         * @returns {number} Document row count
		 */
        function getDocCount() {
            var retVal = 0;
            ctrl.newCount = [];
            ctrl.newCount['Total'] = 0;
            if (ctrl.docObjects.length > 0) {
                ctrl.docObjects.forEach(function (obj) {
                    retVal += (obj.docs ? obj.docs.length : 0);
                    if (obj.docs) {
                        angular.forEach(obj.docs, function (d) {
                            if (!(ctrl.newCount[d.objectType + '-' + d.objectRecId])) {
                                ctrl.newCount[d.objectType + '-' + d.objectRecId] = 0;
                            }
                            var c = moment.utc(d.updateStamp || d.createStamp).add(ctrl.newIconCutoff, 'days').isAfter(moment.utc()) ? 1 : 0;
                            ctrl.newCount[d.objectType + '-' + d.objectRecId] += c;
                            ctrl.newCount['Total'] += c;
                        });
                    };
                });
            }
            return retVal;
        }

		/**
		 * Helper function for returning document array key.
		 * @param {string} objectType - Object type name, like 'WOR_WORK'
		 * @param {number} recId - Record id
		 * @returns {string} Key
		 */
		function toKey(objectType, recId) {
			var key = objectType + '|' + recId
			key = key.replace(/\s/g, '');
			return key;
		}

		/**
		 * Helper function which stored given document to document array.
		 * @param {string} objectType - Object type name, like 'WOR_WORK'
		 * @param {number} recId - Object record id
		 * @param {object} doc - Document
		 */
        function addToDocs(objectType, recId, doc) {
            var key = toKey(objectType, recId);
            var docArr = ctrl.parentToDocs[key];
            if (docArr == null) {
                docArr = [];
                ctrl.parentToDocs[key] = docArr;
            }
            if (!isMember(docArr, "recId", doc.recId)) {
                docArr.push(doc);
            }
        }

        /**
         * Helper function which updates given document in the document array.
         * @param {object} doc - Document
         */
        ctrl.updateDoc = function (doc) {
            if (doc && doc.recId) {
                for (var i = 0; i < ctrl.docObjects[0].docs.length; i++) {
                    if (ctrl.docObjects[0].docs[i].recId === doc.recId) {
                        ctrl.docObjects[0].docs[i] = doc;
                        break;
                    }
                }
            }
        };

        /**
         * Helper function which updates given document in the document array.
         * @param {object} doc - Document
         */
        ctrl.removeDoc = function (doc) {
            var removed = false;
            if (doc && doc.recId) {
                for (var i = 0; i < ctrl.docObjects[0].docs.length; i++) {
                    if (ctrl.docObjects[0].docs[i].recId === doc.recId) {
                        ctrl.docObjects[0].docs.splice(i, 1);
                        removed = true;
                        break;
                    }
                }
            }
            if (removed) {
                ctrl.contentAvailable = getDocCount() > 0;
                ctrl.onContentChangedCallback({ count: getDocCount(), newCount: ctrl.newCount });
            }
        };

        function isDocListMember(objectType, objectRecid) {
            var retVal = false;
            ctrl.docObjects.forEach(function (listItem) {
                if (listItem.objectType === objectType && listItem.objectRecid === objectRecid) {
                    retVal = true;
                }
            });
            return retVal;
        }

        /**
         * Checks if a list/array contains a member where given key has given value.
         * @param {object[]} lst - Array or list to be looped through.
         * @param {string} key - Attribute name used in comparison.
         * @param {any} value - Attribute value used in comparison.
         * @returns {boolean} True if member is found, false otherwise.
         */
        function isMember(lst, key, value) {
            var retVal = false;
            angular.forEach(lst, function (listItem) {
                if (listItem[key] && listItem[key] === value) {
                    retVal = true;
                }
            });
            return retVal;
        }

		/**
         * Collects documents for each object.
         */
        function collectDocs() {
            angular.forEach(ctrl.objects, function (obj) {
                angular.forEach(common.getCommonDataParents('Document', obj), function (docParent) {
                    var arr = ctrl.parentToDocs[toKey(docParent.objectType, docParent.recId)];
                    if (arr && arr.length > 0 && !isDocListMember(docParent.objectType, docParent.recId)) {
                        ctrl.docObjects.push({
                            objectType: docParent.objectType,
                            objectRecid: docParent.recId,
                            name: docParent.name,
                            docs: arr
                        });
                    }
                });
            });
            if (ctrl.parent && ctrl.docObjects) {
                // Make parent documents appear first, then by owners name?
                ctrl.docObjects.sort(function (cmp1, cmp2) {
                    if (ctrl.parent.objRecId().objectType === cmp1.objectType && ctrl.parent.objRecId().recId === cmp1.recId) {
                        return -1;
                    }
                    else if (ctrl.parent.objRecId().objectType === cmp2.objectType && ctrl.parent.objRecId().recId === cmp2.recId) {
                        return 1;
                    }
                    return cmp1.name < cmp2.name ? -1 : cmp1.name > cmp2.name ? 1 : 0;
                });
            }
            ctrl.contentAvailable = getDocCount() > 0;
            ctrl.onContentChangedCallback({ count: getDocCount(), newCount: ctrl.newCount });
        }

		/**
         * Gets documents for docParents. 
         */
        function getDocs() {
            var promises = [];
            angular.forEach(ctrl.objects, function (object) {
                angular.forEach(common.getCommonDataParents('Document', object), function (docParent) {
                    var key = toKey(docParent.objectType, docParent.recId);
                    if (ctrl.parentToDocs[key] == null) {
						ctrl.parentToDocs[key] = [];
						promises.push(commonDatacontext.getDocuments(null, [docParent], 0, 100, ctrl.docListOption, ctrl.docDefaultsEnumKey, ctrl.filterByLanguage).then(function (documents) {
                            if (documents.length === 0) {
                                collectDocs(); // for callback <=> object has 0 documents
                            }

                            var subPromises = documents.map(function(docum)  {
                                var currDoc = docum;
                                var loadDataPromise = common.$q.defer();

                                // If document does not have attachment, try to get external file.
                                if (!(docum.hasFile && docum.fileName && docum.contentType)) {
                                    commonDatacontext.getExternalFileData(docum, function (data) {
                                        if (currDoc.content == null) {
                                            //currDoc.content = 'file:' + data.url;
                                            currDoc.content = data.url;
                                        }
                                        loadDataPromise.resolve(currDoc);
                                    });
                                } else {
                                    if (ctrl.contentLoadingPhase === 2) {
                                        // Just add to documents, don't get the content.
                                        loadDataPromise.resolve(currDoc);
                                    }
                                    else {
                                        commonDatacontext.getDocumentData(null, docum.recId, 'CONTENT', true, ctrl.contentLoadingPhase === 0).then(function (data) {
                                            if (!data || !data.length || !data[0]) {
                                                var msg = common.$translate.instant("STRCONST.PUBLIC.MSG_LOADING_FILECONTENT_FAILED") + ' ' + docum.fileName;
                                                common.logger.logError(msg, false, false, true);
                                            }
                                            else {
                                                currDoc.content = data[0];
                                                loadDataPromise.resolve(currDoc);
                                            }
                                        });
                                    }
                                }

                                return loadDataPromise.promise.then(function (doc) {
                                    return doc;
                                });
                            });
                            common.$q.all(subPromises).then(function (documents) {
                                angular.forEach(documents, function (doc) {
                                    addToDocs(doc.objectType, doc.objectRecId, doc);
                                });
                            });

                            return common.$q.all(subPromises);
                        }));
                    } else if (ctrl.parentToDocs[key] != null) {
                        promises.push(common.$q.when(true));
                    }
                });
            });

            if (promises && promises.length) {
                common.$q.all(promises).then(function () {
                    collectDocs();
                    ctrl.isReady = true;
                });
            }
        }

		/**
		 * Occurs when save document informs that content has changed. 
		 */
		ctrl.onSaveDocContentChanged = function () {
			if (!ctrl.dynDocs) {
				getDocs();
			}
			else {
				ctrl.onContentAddedCallback();
			}
		};

		/**
         * Defines if the sub headers are shown. 
         * @returns {boolean} If show object headers parameter is set as false, return false.
         * Otherwise defined by checking if single parent is given as input.
         */
        ctrl.showObjHeaders = function () {
            if (!ctrl.showObjectHeaders) return false;
            return !ctrl.parent;
        };

		/**
		 * Run on startup.
		 */
        function activate() {
            ctrl.parentToDocs = [];
            ctrl.docObjects = [];
            usersettings.getUserSettings().then(function (data) {
                if (!data || !data.info || !data.info.userId) {
                    usersettings.redirectToLogin();
                } else {
                    usersettings.authorizeHeader();
					ctrl.currentUser = data.info;
					var paramArray = [];
					paramArray.push(ctrl.docDefaultsEnumKey);
					commonDatacontext.getEnumFrom('DOC_DEFAULTS_001', JSON.stringify(paramArray)).then(function (data) {
						if (data && data.length > 0 && data[0].columns) {
							if (data[0].columns.VALUE30) {
								ctrl.formId = data[0].columns.VALUE30;
							}
							if (data[0].columns.VALUE32) {
								ctrl.formVersion = data[0].columns.VALUE32;
							}
						}
						ctrl.formId = ctrl.formId || 22009;
						if (!ctrl.dynDocs) {
							getDocs();
						}
					});
                }
            });
        }

        activate();

        $scope.$on('COMPANYOPUCHANGES', function () {
            activate();
        });

        $scope.$watchCollection('ctrl.dynDocs', function () {
            if (ctrl.dynDocs) {
                // The directive collects documents per parent, so if parent is available
                // lets add documents with addToDocs
                if (ctrl.parent) {
                    ctrl.objects = [ctrl.parent];
                    ctrl.docObjects = [];
                    ctrl.parentToDocs = [];
                    angular.forEach(ctrl.dynDocs, function (doc) {
                        addToDocs(doc.objectType, doc.objectRecId, doc);
                    });
					collectDocs();
					ctrl.isReady = true;
                }
                else {
                    ctrl.docObjects = []; // Now dynDocs replace other docs. You cannot have both at the same time. Can be changed if needed.
                    angular.forEach(ctrl.dynDocs, function (dynDocObj) {
                        ctrl.docObjects.push(dynDocObj);
                    });
					ctrl.contentAvailable = getDocCount() > 0;
					ctrl.isReady = true;
                    ctrl.onContentChangedCallback({ count: getDocCount(), newCount: ctrl.newCount });
                }

            }
        });

		/**
		* ctrl.objects contains Array of objects with objectType, recId and name
		* ctrl.docParents is used when multipe parents exist
		* ctrl.parent is used when a single parent exist
		*/
        $scope.$watchCollection('ctrl.docParents', function (parents) {
            // duplicate original array
            if (ctrl.docParents) {
                ctrl.objects = ctrl.docParents.slice(0);
                ctrl.docObjects = [];
                ctrl.parentToDocs = [];
                getDocs();
            }
        });
        $scope.$watch('ctrl.parent', function (parent) {
            // if documents are provided from outside, the paren data is used only for 
            // data structure containing actual documents <=> no reading done
            if (ctrl.parent && !ctrl.dynDocs) {
                ctrl.objects = [ctrl.parent];
                ctrl.docObjects = [];
                ctrl.parentToDocs = [];
                getDocs();
            }
        });
    }
export default docModule
