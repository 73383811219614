'use strict';
var timeRangeModule = angular.module('dir.dfxTimeRange',[]);
/**
 * Time Range selection. Visibility can be controlled by showMask.
 */
timeRangeModule.directive('dfxTimeRange', function () {
	// Provides time range.
	// Usage:
	// <dfx-time-range range-selection="'WEEK'" size-class="'fa-3x'" show-mask="3" on-range-selected-callback="ctrl.onRangeChanged(range, start, end)"></dfx-time-range>
	return {
		restrict: 'E', //E = element, A = attribute, C = class, M = comment 
		scope: {},
		controllerAs: 'ctrl',
		bindToController: {
			sizeClass: '=',			// 'fa-1x','fa-2x','fa-3x','fa-4x'
			rangeSelection: '=',	// 'SHOW_RANGE_DAY','SHOW_RANGE_WEEK','SHOW_RANGE_MONTH'
			showMask: '=',			// maskBits: 1: day; 2: week; 4: month; 8: free selection
			onRangeSelectedCallback: '&'
		},
		controller: ['$scope', function ($scope) {
			var ctrl = this;
			$scope.showDay = true;
			$scope.showWeek = true;
            $scope.showMonth = true;
            var prevRangeSelection;

            ctrl.$onInit = function () {
                if (ctrl.showMask) {
                    $scope.showDay = (ctrl.showMask & 1) > 0;
                    $scope.showWeek = (ctrl.showMask & 2) > 0;
                    $scope.showMonth = (ctrl.showMask & 4) > 0;
                }
                $scope.sizeClass = ctrl.sizeClass ? ctrl.sizeClass : 'fa-2x';
                $scope.rangeSelection = ctrl.rangeSelection ? ctrl.rangeSelection : 'SHOW_RANGE_DAY';
                prevRangeSelection = $scope.rangeSelection;
            };

			ctrl.clicked = function (selection) {
				if (selection === prevRangeSelection) {
					$scope.rangeSelection = null;
				}
				ctrl.onRangeSelectedCallback({ range: $scope.rangeSelection });
				prevRangeSelection = $scope.rangeSelection;
			}
		}],
		template: require('./dfxTimeRange.html')
	};
});
export default timeRangeModule